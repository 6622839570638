import React,{useState,useEffect} from 'react';
import './CreateUserForm.css';
import Message from '../../../Message/Message';
import UserService from '../../../../services/user.service';
import { history } from '../../../../helpers/history';
import AuthService from '../../../../services/auth.service';
import {message} from 'antd';
import {useSelector} from 'react-redux';
var passwordValidator = require('password-validator');
var schema = new passwordValidator();

//password validation rules

schema.is().min(8)                                    // Minimum length 8
schema.is().max(100)                                  // Maximum length 100
schema.has().uppercase()                              // Must have uppercase letters
schema.has().lowercase()                              // Must have lowercase letters
schema.has().digits(2)                                // Must have at least 2 digits
schema.has().symbols(1)                                //Must have atleast 1 symbol
schema.has().not().spaces()                           // Should not have spaces


const CreateUserForm = ()=>{
    const [userName, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [gender, setGender] = useState("male");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [contact, setContact] = useState("");
    const [role, setRole] = useState("user");
    const [managers, setManagers] = useState("");
    const [allRoles,setAllRoles] = useState("");
    const [assignedManager,setAssignedManager] = useState("");
    const [initialManager,setInitialManager]= useState("");
    const [successMessage,setSuccessMessage] = useState(false);
    // const [errorMessage,setErrorMessage] = useState(false);
    const [userNamePlaceholder,setUserNamePlaceholder] = useState("");
    const { user: currentUser } = useSelector((state) => state.auth);


    const findManagerId = (username)=>{
        const foundManager = managers.find((manager)=>{
            return manager.username === username;
        })
        const Id = foundManager._id;
       
        return Id;
    }

    useEffect(()=>{
        if(successMessage){
          setSuccessMessage(false);
        }
      })

    useEffect(async ()=>{
        await UserService.getAllRoles().then((response)=>{
                setAllRoles(response.data);  
        }).catch((err)=>{
            console.log(err);
        })
    },[])

    useEffect( async ()=>{
        UserService.getAllManagers().then(response=>{
            // console.log(response.data);
            setManagers(response.data);
            setInitialManager(response.data[0].username);
        }).catch((err)=>{
            console.log(err);
        })
    },[])



    const handleManagerChange = (managerUsername)=>{
        const managerId = findManagerId(managerUsername);
        setAssignedManager(managerId);
    }

    const handleRoleChange = (role)=>{
        if(role.toLowerCase()==='employee'){
            setAssignedManager(null);
        }
        setRole(role);
    }


 const checkValidPassword = ()=>{
    const isValid = schema.validate(password)
    if (!isValid) {
      message.error("Password Must Contain Digits,Uppercase or Lowercase Letters, Symbols and Minimum 8 Password Length")
      return false;
    }
    return true;
  }


const handleUsernameChange = (e)=>{
    const re = /^\S*$/;
    if(re.test(e.target.value)){
        setUsername(e.target.value);
        setUserNamePlaceholder("");
    }else{
        setUsername("");
        setUserNamePlaceholder("Spaces aren't allowed!");
    }
}
const createUser = (e)=>{
    e.preventDefault();
    const valid = checkValidPassword();
    if(valid){
        let manager = null
        e.preventDefault();
        if(role.toLowerCase()==="employee"){
            if(assignedManager==="" || assignedManager === null){
                manager = findManagerId(initialManager);
            }else{
                manager = assignedManager;
            }
            
        }
        
        const data = {
            "username":userName,
            "email":email,
            "password":password,
            "roles":role.toLowerCase()==="super admin" ? ['admin']: [role.toLowerCase()],
            "name":name,
            "dob":dob,
            "address":address,
            "gender":gender.toLowerCase(),
            "contact":contact,
            "manager": manager,
            isSuperAdmin:role.toLowerCase()==="super admin" ? true:false
        }

        // console.log("FINAL DATA:",data);
        AuthService.register(data).then((response)=>{
            // console.log(response.data);
            setSuccessMessage(true);
            
        }).catch((error)=>{
            console.log(error);
             const msg =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
            message.error(msg ? msg : 'Unable to Create User!');
        })
    }
        
       
    }

   


    return(
        <div className="createUserForm">
        <form>
          <div className="form-inline">
              <div className="form-group">
                  <label>Name : </label>
                  <input type="text" className="form-control" value={name}
                   onChange={(e)=>{setName(e.target.value)}}/>
              </div>
  
              <div className="form-group">
                  <label>Email : </label>
                  <input type="email" required className="form-control" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
              </div>
             
          </div>
          <div className="form-inline">
              <div className="form-group">
                  <label>Username : </label>
                  <input type="text" required id="username" 
                  className="form-control" placeholder={userNamePlaceholder} 
                  value={userName} onChange={handleUsernameChange}/>
              </div>
  
             
              <div className="form-group">
                  <label>Password : </label>
                  <input type="password" placeholder="Must Contain Uppercase,lowercase letters,Numbers,and symbols" required minLength="8" className="form-control" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
              </div>
          </div>
  
          <div className="form-inline">
              <div className="form-group">
                  <label>DOB : </label>
                  <input type="date" style={{width:'30rem'}} id="row3" className="form-control" value={dob} onChange={(e)=>{setDob(e.target.value)}}/>
              </div>
  
              
              
              <div className="form-group">
                  <label>Contact : </label>
                  <input type="text"  style={{width:'20rem'}} id="row3" className="form-control" value={contact} onChange={(e)=>{setContact(e.target.value)}}/>
              </div>

              <div className="form-group">
                  <label>Gender : </label>
                  <select value={gender} style={{width:'8rem',marginTop:'2%'}} id="genderInput" className="form-control" onChange={(e)=>{setGender(e.target.value)}}>
                      <option>Male</option>
                      <option>Female</option>
                      <option>Other</option>
                  </select>
              </div>
          </div>
          {currentUser.roles.includes('ROLE_SALES') ? null : 
          <div className="form-inline">

          <div className="form-group">
                  <label>Role : </label>
                  <select className="form-control" onChange={(e)=>{handleRoleChange(e.target.value)}}>
                     {allRoles && allRoles.map((role)=>{
                        return(<option key={role._id}>{role.name.toUpperCase()}</option>)
                     })
                     }
                     {currentUser.isSuperAdmin && <option>Super Admin</option>}
                  </select>
              </div>
                    {role.toLowerCase()==="employee" ? (
                        <div className="form-group">
                        <label>Assign Manager : </label>
                            <select required className="form-control" onChange={(e)=>{handleManagerChange(e.target.value)}}>
                        { managers.map((manager)=>{
                                return(
                                    <option key={manager._id}>{manager.username}</option>
                                )
                            })}
                            </select>
                        </div>
                    ):null}

          </div>
          }
  
          <div className="form-group">
              <label>Address : </label>
              <textarea value={address} className="form-control" onChange={(e)=>{setAddress(e.target.value)}}>
              </textarea>
          </div>
  
         <div className="form-group">
         <button style={{width:'fit-content'}} className="pay-btn"
         disabled = {userName === "" || email === "" || password === ""} 
         onClick={(e)=>{createUser(e)}}>Create User</button>
         </div>
  
      </form>
          {successMessage ? <Message type="success" message="User Created Successfully" /> : null} 
        </div>
    )

}

export default CreateUserForm;