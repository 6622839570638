import React,{useState,useEffect} from 'react';
import { Table,Button,message,Modal,Tooltip} from 'antd';
import orderService from '../../../services/order.service';
import GoogleServices from '../../../services/google.services';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';
import plus from '../../../assets/plus.png';
import './MailRecords.css';
import {Link} from 'react-router-dom'








const MailRecords = ()=>{
    const [allRecords,setAllRecords] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [filteredArr,setFilteredArr] = useState([]);
    const [receiverFilter,setReceiverFilter] = useState([]);
    const [toMail,setToMail] = useState("");
    const [subject,setSubject] = useState("");
    const [selectedfile,setselectedfile] = useState("");
    const [isAttachment,setIsAttachment] = useState(false);
    const [write,setWrite] = useState("");
    const [profile,setProfile] = useState(false);
    const [attachedfileName,setAttachedFileName] = useState(null);
    const [loading,setLoading] = useState(false);
    const [isNewMail,setIsNewMail] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [currentViewMoreMsg,setCurrentViewMoreMsg] = useState("");
    const [viewMoreMsgModal,setViewMoreMsgModal] = useState(false);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const handleAttachmentClick = (e)=>{
        // console.log(e);
        hiddenFileInput.current.click();
    }

    const handleChange = (event) => {
        //  console.log(event.target.files[0])
          setselectedfile(event.target.files[0]);
          setAttachedFileName(event.target.files[0].name);
          const reader = new FileReader()
          reader.onload = () => {
            if(reader.readyState === 2){
              
              setProfile(reader.result)
            }
          }
          reader.readAsBinaryString(event.target.files[0])
          setIsAttachment(true);
      }



    const getMailRecords = async ()=>{
        try{
           const response = await orderService.getAllMailRecords()
            // console.log(response.data);
           
            let data = [];
            response.data.map((d)=>{
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
               
                let modifiedData = {
                  id:d._id,
                  sortDate:d.createdAt,
                  createdAt:finalDate,
                  sender:d.sender,
                  receiver:d.receiver,
                  subject:d.subject,
                  message:d.message,
                  isAttachment:d.isAttachment=='true' ? 'Yes':'No'
                }

                data.push(modifiedData);
            })
            
            setAllRecords(data);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(()=>{
        getMailRecords();
    },[]);


    useEffect(()=>{
        let mailFilteredArr = [];
         allRecords.map((p)=>{
           if(p.receiver){
            mailFilteredArr.push({text:p.receiver ,value:p.receiver })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        mailFilteredArr = mailFilteredArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setReceiverFilter(mailFilteredArr);
    },[allRecords])

    const handleViewMoreMsg = (message)=>{
        setCurrentViewMoreMsg(message);
        setViewMoreMsgModal(true);
    }


    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
            width:'20%'
        },
        {
            title: 'Sender',
            dataIndex: 'sender',
            key: 'sender',
        },
        {
            title: 'Receiver',
            dataIndex: 'receiver',
            key: 'receiver',
            filters:receiverFilter,
            onFilter:(value, record) => record.receiver.indexOf(value) === 0,
        },
        {
            title: 'Subject',
            key: 'subject',
            dataIndex:'subject',
           
        },
        {
            title: 'Message',
            key: 'message',
            width:'25%',
            render:(record)=>{
                return(
                    <div>
                        {record.message.length > 30 ?
                            <>{record.message.slice(0,50)}...<span onClick={()=>{handleViewMoreMsg(record.message)}} className='viewMoreMsg'>View More</span></>
                        :
                        record.message
                        }
                    </div>
                )
            }
        },

        {
            title: 'Attachment',
            dataIndex: 'isAttachment',
            key: 'isAttachment',
        },


    ]
   


    const handleSearchChange = (item)=>{
        let filteredData = []
        if(item.length > 0){
            if(allRecords.length >0){
                filteredData= allRecords.filter((mail)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return mail.receiver && mail.receiver.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }


const removeAttachment = ()=>{
        setselectedfile(null);
        setIsAttachment(false);
        setAttachedFileName(null);
    }


const  createMailRecordInDB = async ()=>{
        try{
            const data = {
                sender:currentUser.email,
                receiver:toMail,
                subject:subject,
                message:write,
                isAttachment:isAttachment
            }
    
            const response = await orderService.createMailRecord(data);
            if(response){
                message.success(`Mail Record Created Successfully!`)
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to create mail record in DB');
        }
    };

const handleMailSend = async ()=>{
        setLoading(true);
// console.log(selectedfile);
try{
        if(isAttachment){
        const data = {
            To:toMail,
            Subject:subject,
            Write:write,
            attachment:isAttachment,
            name:selectedfile.name,
            size:selectedfile.size,
            type:selectedfile.type,
            lastmodified:selectedfile.lastmodified,
            filedata:profile
        }
        
        const response = await GoogleServices.sendmail(data)
       
           if(response){
            createMailRecordInDB();
            setToMail("");
            setWrite("");
            setSubject("");
            setLoading(false);
            setselectedfile(null);
            setAttachedFileName(null);
            setProfile(null);
            setIsNewMail(false);
            message.success("Successfully Sent the Mail !");
            getMailRecords();
           }
    }
    else{
        const data ={
            To:toMail,
            Subject:subject,
            Write:write,
            attachment:isAttachment,

        }
        const response = await GoogleServices.sendmail(data)
            if(response){
                createMailRecordInDB();
                setToMail("");
                setWrite("");
                setSubject("");
                setLoading(false);
                setIsNewMail(false);
                message.success("Successfully Sent the Mail !");
                getMailRecords();
            }
       
    }
    }catch(err){
        console.log(err);
        setLoading(false);
        setIsNewMail(false);
        message.error(err.message ? err.message : "Something Went Wrong !");
    }
    }


    return(
        <div className="mailRecords">
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Mails</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}} /> 
                </div>

                <div className='composeMailWrapper'>
                    <Link to="/profile/sendMailToUser" className='composeMailBtn'>

                     <img src={plus} id="composePlus" alt="Add" /> New Mail
                     </Link>
                </div>
            </div>

            <Table dataSource={filteredArr.length > 0 ?  filteredArr : allRecords} columns={columns} />
       
       

            <Modal 
            title={"Compose Mail"}
            visible={isNewMail} 
            onOk={handleMailSend} 
            okText={'Send'}
            onCancel={()=>{setIsNewMail(false)}}
            confirmLoading={loading}
         >
             <form className="mailForm">
             <div>
               <input 
               type="text" 
               className="form-control mb-3"
               placeholder="To: " 
               value={toMail}
               onChange={(e)=>{setToMail(e.target.value)}}/>
             </div>
             <div>
               <input 
               type="text" 
                value={subject}
               className="form-control mb-3"
               placeholder="Subject: " 
               onChange={(e)=>{setSubject(e.target.value)}}/>
             </div>

             <textarea value={write} 
             onChange={(e)=>{setWrite(e.target.value)}} 
             placeholder="Write Something..." className="form-control mb-3">
            </textarea>

            <div className="d-flex">
                <Tooltip title="Add Attachment ? " color={'cyan'}>
                <span className="attachment-icon" onClick={handleAttachmentClick}>
                <i className="fa fa-paperclip" style={{fontSize:'1.5rem'}} aria-hidden="true"></i></span>
                </Tooltip>
               {attachedfileName &&  <span className="attached-file-name">
               <b>{attachedfileName}</b>
               <Tooltip title="Remove Attachment ? " color={'red'}>
               <span onClick={removeAttachment}> 
               <i className="fa fa-times" style={{fontSize:'1.3rem',color:'red'}} aria-hidden="true"></i></span>
               </Tooltip>
               </span>}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e)=>handleChange(e)}
                    style={{display: 'none'}} 
                />
            </div>
             </form>
        </Modal>

        <Modal 
            title={"Complete Message"}
            visible={viewMoreMsgModal} 
            okText={'Ok'}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={()=>{setViewMoreMsgModal(false)}}
            onOk={()=>{setViewMoreMsgModal(false)}}
         >
         <div className='viewMoreMsgText'>
             {currentViewMoreMsg}
         </div>
            
        </Modal>
       
       
        </div>
    )
}


export default MailRecords;