import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import OrderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import {Tag, Modal, Menu, Dropdown, message, Table, Space, Tabs} from 'antd';
import {Link} from 'react-router-dom';
import {history} from '../../../helpers/history';
import {
  EyeOutlined,
  ReloadOutlined,
  UserOutlined,
  UploadOutlined,
  MoreOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  StopOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {io} from 'socket.io-client';
const {TabPane} = Tabs;

let socketConnection = 'https://dashboard.codersarts.com';
// let socketConnection =  "http://localhost:3000";
const AssignedOrders = () => {
  const [allAssignedOrders, setAllAssignedOrders] = useState ([]);
  const [allOrders, setAllOrders] = useState ([]);
  const [confirmLoading, setConfirmLoading] = useState (false);
  const [visible, setVisible] = useState (false);
  const [order, setOrder] = useState (null);
  const [files, setFiles] = useState (null);
  const [isProgress, setIsProgress] = useState (false);
  const [currentOrderId, setCurrentOrderId] = useState (null);
  const [confirmProgressUpload, setConfirmProgressUpload] = useState (false);
  const [progressPercentage, setProgressPercentage] = useState (0);
  const [screenShots, setScreenShots] = useState ('');
  const [currentOrderToAssign, setCurrentOrderToAssign] = useState (null);
  const [assignEmployee, setAssignEmployee] = useState (null);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState (false);
  const [finalAssignEmployeeId, setFinalAssignEmployeeId] = useState (null);
  const [confirmAssignLoading, setConfirmAssignLoading] = useState (false);
  const [allEmployees, setAllEmployees] = useState ([]);
  const [managerNote, setManagerNote] = useState ('');
  const [reportingDeadline, setReportingDeadline] = useState ('');
  const [filteredArr, setFilteredArr] = useState ([]);
  const [filteredCategoryArr, setFilteredCategoryArr] = useState ([]);
  const [filteredStatusArr, setFilteredStatusArr] = useState ([]);
  const [currentOrderName, setCurrentOrderName] = useState ('');
  const [activeOrders, setActiveOrders] = useState ([]);
  const [deliveredOrders, setDeliveredOrders] = useState ([]);
  const [cancelledOrders, setCancellledOrders] = useState ([]);
  const [completedOrders, setCompletedOrders] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [tableLoading, setTableLoading] = useState (true);
  const socket = useRef ();

  useEffect (
    () => {
      if (
        !currentUser ||
        (!currentUser.roles.includes ('ROLE_EMPLOYEE') &&
          !currentUser.roles.includes ('ROLE_MANAGER'))
      ) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  useEffect (() => {
    socket.current = io (socketConnection);
  }, []);

  useEffect (
    () => {
      if (currentUser) {
        socket.current.emit ('addNotificationUser', currentUser.id);
      }
    },
    [currentUser]
  );

  const handleProgressClick = (id, progress) => {
    setCurrentOrderId (id);
    setProgressPercentage (progress);
    setIsProgress (true);
  };

  const handleProgressSubmit = async () => {
    setConfirmProgressUpload (true);
    // console.log(progressPercentage);
    // console.log(currentOrderId);

    try {
      const response = await OrderService.updateOrder ({
        _id: currentOrderId,
        progress: progressPercentage,
      });
      // console.log(response);
      setConfirmProgressUpload (false);
      setIsProgress (false);
      setProgressPercentage (0);
      message.success ('Successfully updated the progress!');
    } catch (err) {
      console.log (err);
      setConfirmProgressUpload (false);
      setIsProgress (false);
      setProgressPercentage (0);
      message.error (err.message ? err.message : 'Unable to update progress!');
    }
  };

  const handleProgressClose = () => {
    setIsProgress (false);
  };

  const uploadScreenShots = async () => {
    var data = new FormData ();
    data.append ('order', order);
    data.append ('category', 'deliverables');
    // screenshotsData.append('file',screenShots);
    let selectedFiles = screenShots;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append ('multiples', selectedFiles[i], selectedFiles[i].name);
      }
    }
    // console.log(data);
    try {
      const response = await OrderService.postMultipleDoc (data);
      // console.log(response);
      if (response.data) {
        message.success ('Screenshots Uploaded Successfully!');
      }
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to upload screenshots!'
      );
    }
  };

  const handleConfirmUpload = () => {
    setConfirmLoading (true);
    if (order && files) {
      var formData = new FormData ();
      formData.append ('order', order);
      formData.append ('category', 'source code');
      // screenshotsData.append('file',screenShots);
      let selectedFiles = files;

      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          formData.append (
            'multiples',
            selectedFiles[i],
            selectedFiles[i].name
          );
        }
      }
      // for (var key of formData.entries()) {
      //     console.log(key[0] + ', ' + key[1]);
      // }
      OrderService.postMultipleDoc (formData)
        .then (response => {
          uploadScreenShots ();
          setConfirmLoading (false);
          setVisible (false);
          message.success ('Successfully Uploaded!');
          // console.log(response.data);
        })
        .catch (err => {
          setConfirmLoading (false);
          setVisible (false);
          console.log (err);
          message.error (err.message ? err.message : 'Something  went wrong!');
        });
    } else {
      setConfirmLoading (false);
      setVisible (false);
    }
  };

  const handleUpload = id => {
    allOrders.map (o => {
      if (o._id === id) {
        setOrder (o._id);
      }
    });

    showModal ();
  };

  const showModal = () => {
    setVisible (true);
  };
  const handleCancel = () => {
    // console.log('Clicked cancel button');
    setVisible (false);
  };

  const handleFileChange = e => {
    // console.log(e.target.files[0]);
    setFiles (e.target.files);
  };

  const handleScreenShotChange = e => {
    // console.log(e.target.files);
    setScreenShots (e.target.files);
  };

  const handleAssignToChange = uname => {
    var foundUser = null;
    if (uname.toLowerCase () !== 'select employee') {
      foundUser = allEmployees.find (user => {
        return user.username === uname;
      });
      if (foundUser !== null) {
        setFinalAssignEmployeeId (foundUser._id);
      }
    }
    setAssignEmployee (uname);
  };

  const handleAssignTo = (id, a, name) => {
    // console.log(id,a);
    setCurrentOrderName (name);
    setCurrentOrderToAssign (id);
    setAssignEmployee (a);
    setIsAssignModalVisible (true);
  };

  const getOrderForUser = id => {
    const data = [];
    OrderService.getSpecificOrder (id)
      .then (response => {
        // console.log(response.data);
        setAllOrders (response.data);
        if (response.data) {
          response.data.forEach ((order, index) => {
            let finalDate = '';
            if (order.assignedByAdminAt) {
              const date = new Date (order.assignedByAdminAt);
              let dd = date.getDate ();
              let mm = date.getMonth () + 1;
              let yyyy = date.getFullYear ();
              let hh = date.getHours ();
              let minutes = date.getMinutes ();
              let ss = date.getSeconds ();
              finalDate =
                dd +
                '-' +
                mm +
                '-' +
                yyyy +
                ' at ' +
                hh +
                ':' +
                minutes +
                ':' +
                ss;
            }
            // console.log("date : ",finalDate);

            let updatedField = {
              key: index,
              title: order.name,
              id: order._id,
              assignedAtDate: finalDate,
              assignedAt: order.assignedByAdminAt,
              category: order.category,
              deadline: order.deadline,
              booking: order.bookingSession === true ? 'Yes' : 'No',
              documentation: order.documentation === true ? 'Yes' : 'No',
              details: order.details,
              status: order.status ? order.status : '---',
              assignedTo: order.assignedTo &&
                order.assignedTo[0] &&
                order.assignedTo[0].username,
              progress: order.progress ? order.progress : 0,
              assignedEmployee: order.assignedEmployee &&
                order.assignedEmployee[0] &&
                order.assignedEmployee[0].username,
              assignedToId: order.assignedTo &&
                order.assignedTo[0] &&
                order.assignedTo[0]._id,
              assignedEmployeeId: order.assignedEmployee &&
                order.assignedEmployee[0] &&
                order.assignedEmployee[0]._id,
            };

            data.push (updatedField);
          });
          // console.log("Data : ",data);
          setAllAssignedOrders (assignAllOrderId (data));
          categorizeOrders (data);
        }
      })
      .catch (err => {
        console.log (err);
      });
  };

  const getAllAssignedEmployee = async () => {
    try {
      const response = await UserService.getAllEmployees (currentUser.id);
      // console.log(response.data);
      setAllEmployees (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something Went Wrong !');
    }
  };

  useEffect (() => {
    if (currentUser) {
      getOrderForUser (currentUser.id);
      getAllAssignedEmployee ();
    }
  }, []);

  const handleAssignOk = async () => {
    setConfirmAssignLoading (true);
    let now = new Date ();
    // console.log("final data : ",currentOrderId,finalAssignToId);
    try {
      const response = await OrderService.updateOrder ({
        _id: currentOrderToAssign,
        assignedEmployee: finalAssignEmployeeId,
        employeeReporting: reportingDeadline,
        managerNote: managerNote,
        assignedAt: now,
      });

      const notificationRes = await UserService.createNewNotification ({
        notification: `New Order: ${currentOrderName} is Assigned to you by ${currentUser.username}`,
        user: finalAssignEmployeeId,
      });
      console.log ('Notification : ', notificationRes.data);
      socket.current.emit ({
        notification: `New Order: ${currentOrderName} is Assigned to you by ${currentUser.username}`,
        user: finalAssignEmployeeId,
      });
      // console.log(response);
      getOrderForUser (currentUser.id);
      setConfirmAssignLoading (false);
      setIsAssignModalVisible (false);
      message.success (`Successfully Assigned Order to ${assignEmployee}`);
    } catch (err) {
      console.log (err);
      setConfirmAssignLoading (false);
      setIsAssignModalVisible (false);
      message.error (
        err.message
          ? err.message
          : `Unable to assign order to ${assignEmployee}!`
      );
    }
  };

  const handleAssignCancel = () => {
    setIsAssignModalVisible (false);
  };

  useEffect (
    () => {
      let categoryFilterArr = [];
      let statusFilterArr = [];

      allAssignedOrders.map (o => {
        if (o.category) {
          categoryFilterArr.push ({text: o.category, value: o.category});
        }

        if (o.status) {
          statusFilterArr.push ({text: o.status, value: o.status});
        }
      });

      // console.log("NAME FILTER : ",assignToArr);
      categoryFilterArr = categoryFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      statusFilterArr = statusFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      setFilteredCategoryArr (categoryFilterArr);
      setFilteredStatusArr (statusFilterArr);
    },
    [allAssignedOrders]
  );

  const columns = [
    {
      title: 'Assigned At',
      key: 'assignedAt',
      dataIndex: 'assignedAtDate',
      width: '20%',
      sorter: (a, b) =>
        moment (a.assignedAt).unix () - moment (b.assignedAt).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      key: 'title',
      width: '20%',
      render: record => {
        return (
          <Link
            to={{
              pathname: `/profile/view-order-details/${record.id}`,
              state: {orderIds: record.orderIds},
            }}
          >
            <b>{record.title}</b>
          </Link>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: filteredCategoryArr,
      onFilter: (value, record) => record.category.indexOf (value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
    },
    {
      title: 'Booking',
      dataIndex: 'booking',
      key: 'booking',
    },
    {
      title: 'Documentation',
      dataIndex: 'documentation',
      key: 'documentation',
    },
    {
      title: 'Assigned To',
      key: 'assignedTo',
      render: record => {
        return record.assignedToId
          ? <Link to={`/profile/manage-users/view/${record.assignedToId}`}>
              <b>{record.assignedTo}</b>
            </Link>
          : <p>{record.assignedTo}</p>;
      },
    },
    {
      title: 'Employee Assigned',
      key: 'assignedEmployee',
      render: record => {
        return record.assignedEmployeeId
          ? <Link
              to={`/profile/manage-users/view/${record.assignedEmployeeId}`}
            >
              <b>{record.assignedEmployee}</b>
            </Link>
          : <p>{record.assignedEmployee}</p>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: filteredStatusArr,
      render: text => {
        return <Tag color={'geekblue'}>{text}</Tag>;
      },
      onFilter: (value, record) =>
        record.status && record.status.indexOf (value) === 0,
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link
                    to={{
                      pathname: `/profile/view-order-details/${record.id}`,
                      state: {orderIds: record.orderIds},
                    }}
                  >
                    <EyeOutlined /> View Details
                  </Link>
                </Menu.Item>

                <Menu.Item
                  key="2"
                  onClick={() => {
                    handleUpload (record.id);
                  }}
                >
                  <UploadOutlined /> Deliver Code
                </Menu.Item>

                <Menu.Item
                  key="3"
                  onClick={() => {
                    handleProgressClick (record.id, record.progress);
                  }}
                >
                  <ReloadOutlined /> Add Progress
                </Menu.Item>

                {currentUser.roles.includes ('ROLE_MANAGER')
                  ? <Menu.Item
                      key="4"
                      onClick={() => {
                        handleAssignTo (
                          record.id,
                          record.assignedEmployee,
                          record.title
                        );
                      }}
                    >
                      <UserOutlined /> Assign To{' '}
                    </Menu.Item>
                  : null}
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allAssignedOrders.length > 0) {
        filteredData = allAssignedOrders.filter (order => {
          // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
          return (
            order.title &&
            order.title.toLowerCase ().match (item.toLowerCase ())
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  const categorizeOrders = async data => {
    // console.log("Data in Categorization : ",data);
    // console.log("data length : ",data.length);
    const active = data.filter (o => {
      if (o.status !== undefined) {
        return (
          o.status.toLowerCase () === 'development' ||
          o.status.toLowerCase () === 'testing' ||
          o.status.toLowerCase () === 'deal confirmed'
        );
      }
    });
    // console.log("Active Orders : ",active);

    const delivered = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'delivered';
      }
    });
    const completed = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'completed';
      }
    });
    const cancelled = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'cancel order';
      }
    });
    // console.log("FILTERED ARRAY : ",newFilteredArr);

    //  console.log("After Duplicate remove FILTERED ARRAY : ",newFilteredArr);
    setActiveOrders (assignAllOrderId (active));
    setDeliveredOrders (assignAllOrderId (delivered));
    setCancellledOrders (assignAllOrderId (cancelled));
    setCompletedOrders (assignAllOrderId (completed));
    // console.log (assignAllOrderId (active));
    // console.log("ACTIVE ORDERS : ",active);
    // console.log("DELIVERED ORDERS : ",delivered);
    // console.log("CANCELLED ORDERS : ",cancelled);
    // console.log("NEW ORDERS : ",newOrders);
    setTableLoading (false);
  };

  function assignAllOrderId (arr) {
    let orderId = [];

    arr = arr.sort (function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return moment (b.assignedAt).unix () - moment (a.assignedAt).unix ();
    });

    arr.forEach (item => {
      orderId.push (item.id);
    });
    let updatedActive = [];

    arr.forEach (item => {
      let updatedData = {
        ...item,
        orderIds: orderId,
      };
      updatedActive.push (updatedData);
    });
    return updatedActive;
  }

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h3>Assigned Orders</h3>
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            style={{width: '30vw', marginBottom: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>
      </div>

      <Tabs defaultActiveKey="1" type="card">

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <BarChartOutlined style={{fontSize: '20px'}} />
              Active Orders
            </span>
          }
          key="1"
          onChange={() => getOrderForUser (currentUser.id)}
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : activeOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <CheckCircleOutlined style={{fontSize: '20px'}} />
              Completed Orders
            </span>
          }
          key="2"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : completedOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <i
                className="fa fa-truck"
                aria-hidden="true"
                style={{fontSize: '20px', marginRight: '6%'}}
              />
              Delivered Orders
            </span>
          }
          key="3"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : deliveredOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <StopOutlined style={{fontSize: '20px'}} />
              Cancelled Orders
            </span>
          }
          key="4"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : cancelledOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <ShoppingCartOutlined style={{fontSize: '20px'}} />
              All Orders
            </span>
          }
          key="6"
          onChange={() => getOrderForUser (currentUser.id)}
        >
          <Table
            loading={tableLoading}
            dataSource={
              filteredArr.length > 0 ? filteredArr : allAssignedOrders
            }
            columns={columns}
          />
        </TabPane>
      </Tabs>

      <Modal
        title="Deliver Code"
        visible={visible}
        onOk={handleConfirmUpload}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        closable={true}
        okText="Upload"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div><b><label>Upload Source Code</label></b></div>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="form-control mt-2"
            />
            <div className="mt-5"><b><label>Upload Screenshots</label></b></div>
            <input
              type="file"
              multiple
              onChange={handleScreenShotChange}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>

      <Modal
        title={'Add Progress'}
        visible={isProgress}
        onOk={handleProgressSubmit}
        onCancel={handleProgressClose}
        confirmLoading={confirmProgressUpload}
      >
        <form>
          <div className="form-group">
            <label><h6>Enter Progress Percentage :</h6></label>
            <input
              type="Number"
              className="form-control"
              value={progressPercentage}
              onChange={e => {
                setProgressPercentage (e.target.value);
              }}
            />
          </div>
        </form>
      </Modal>

      <Modal
        title={'Assign Order'}
        visible={isAssignModalVisible}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        confirmLoading={confirmAssignLoading}
      >
        <form>
          <div className="form-group mb-2">
            <label className="mb-2">
              <h6><b>Select Employee To Assign : </b></h6>
            </label>
            <select
              required
              className="form-control"
              onChange={e => {
                handleAssignToChange (e.target.value);
              }}
            >
              <option
                hidden
                selected={
                  assignEmployee === null ||
                    assignEmployee === undefined ||
                    assignEmployee === ''
                }
              >
                Select Employee
              </option>
              {allEmployees &&
                allEmployees.map (user => {
                  return (
                    <option
                      key={user._id}
                      value={user.username}
                      selected={user.username === assignEmployee}
                    >
                      {user.username}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="form-group mb-2">
            <label className="mb-2">
              <h6><b>Reporting Deadline : </b></h6>
            </label>
            <input
              type="date"
              className="form-control"
              value={reportingDeadline}
              onChange={e => {
                setReportingDeadline (e.target.value);
              }}
            />
          </div>

          <div className="form-group mb-2">
            <label className="mb-2"><h6><b>Manager Note: </b></h6></label>
            <textarea
              className="form-control"
              value={managerNote}
              onChange={e => {
                setManagerNote (e.target.value);
              }}
            />
          </div>

        </form>
      </Modal>

    </div>
  );
};

export default AssignedOrders;
