import React, {useEffect,useRef, useState} from 'react';

import logo from '../../../assets/codersartsLogo.png';
import chat from '../../../assets/chat.png';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  HomeFilled,
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  RedoOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import {Tooltip, Input, Modal, Button,Dropdown,Menu, Select, message} from 'antd';
import { format } from "timeago.js";
import avatar from '../../../assets/user.png';
import WhatsappServices from '../../../services/whatsapp.service';
import '../../../containers/CodersartsChat/CodersartsChat.css';
import './WhatsappCloud.css';

const {Option} = Select;
function WhatsappCloud () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [allTemplates, setAllTemplates] = useState ([]);
  const [allChatUser, setAllChatUser] = useState ([]);
  const [currentChatUser, setCurrentChatUser] = useState ('');
  const [currentTemplate, setCurrentTemplate] = useState ('');
  const [isAddChatUser, setIsAddChatUser] = useState (false);
  const [loading, setLoading] = useState (false);
  const [phoneNumber, setPhoneNumber] = useState ('');
  const [name, setName] = useState ('');
  const [updatedName, setUpdatedName] = useState ('');
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState ('');
  const [isUpdatingChatUser, setIsUpdatingChatUser] = useState (false);
  const [currentTextMsg, setCurrentTextMsg] = useState ('');
  const [sendingText, setSendingText] = useState (false);
  const [currentChatMessages, setCurrentChatMessages] = useState ([]);
  const scrollRef = useRef();



  useEffect (() => {
    let isMounted = true;

    const getAllTemplates = async () => {
      try {
        const response = await WhatsappServices.getAllTemplates ();
        console.log (response.data);
        if (response.data && response.data?.length > 0) {
          let filteredData = response.data.data.filter((item)=>{
            return !item.name.toLowerCase().includes("sample_")
          })
          setAllTemplates (filteredData);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    const getAllChatUser = async () => {
      try {
        const response = await WhatsappServices.getAllWhatsappUsers ();
        // console.log (response.data);
        setAllChatUser (response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    if (isMounted) {
      getAllTemplates ();
      getAllChatUser ();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect (
    () => {
      if (currentChatUser) {
        const getCurrentChatAllMessages = async () => {
          try {
            const result = await WhatsappServices.getWhatsappUserMessage ({
              id: currentChatUser._id,
            });
            console.log (result);
            setCurrentChatMessages (result.data);
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'something went wrong!');
          }
        };

        getCurrentChatAllMessages ();
      }
    },
    [currentChatUser]
  );


  useEffect(()=>{
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  },[currentChatMessages])

  const getCurrentChatAllMessages = async () => {
    try {
      const result = await WhatsappServices.getWhatsappUserMessage ({
        id: currentChatUser._id,
      });
      console.log (result);
      setCurrentChatMessages (result.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const getAllChatUser = async () => {
    try {
      const response = await WhatsappServices.getAllWhatsappUsers ();
      // console.log (response.data);
      setAllChatUser (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const handleAddChatUser = async () => {
    setLoading (true);
    let finalPhoneNumber = phoneNumber.replace (' ', '');
    let data = {
      name: name,
      phoneNumber: finalPhoneNumber,
    };
    try {
      const response = await WhatsappServices.createWhatsappUser (data);
      if (response.data) {
        console.log (response.data);
        message.success (name + ' Added Successfully!');
        getAllChatUser ();
        setName ('');
        setPhoneNumber ('');
        setLoading (false);
        setIsAddChatUser (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setLoading (false);
    }
  };

  const handleCurrentChatClick = item => {
    setCurrentChatUser (item);
  };

  const editChatUser = () => {
    setUpdatedName (currentChatUser.name);
    setUpdatedPhoneNumber (currentChatUser.phoneNumber);
    setIsUpdatingChatUser (true);
  };

  const handleUpdatingChatUser = async () => {
    setLoading (false);
    let finalPhoneNumber = updatedPhoneNumber.replace (' ', '');
    let data = {
      ...currentChatUser,
      name: updatedName,
      phoneNumber: finalPhoneNumber,
    };

    try {
      const response = await WhatsappServices.updateWhatsappUser (data);
      if (response.data) {
        message.success ('Updated Successfully!');
        getAllChatUser ();
        setCurrentChatUser ('');
        setIsUpdatingChatUser (false);
        setLoading (false);
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const handleDeleteUser = async () => {
    let data = {
      ...currentChatUser,
    };

    try {
      const response = await WhatsappServices.deleteWhatsappUser (data);
      if (response.data) {
        message.success ('Deleted Successfully!');
        getAllChatUser ();
        setCurrentChatUser ('');
        setIsUpdatingChatUser (false);
      }
    } catch (err) {
      console.log (err);

      message.error (err.message ? err.message : 'something went wrong!');
    }
  };


  const handleSendIndividualMsg = async () => {
    let tempStr = "Template: " + currentTemplate
    if(currentTemplate && currentTextMsg === tempStr){
      handleSendMsg();
    }else{
      setSendingText (true);
      let data = {
        to: currentChatUser.phoneNumber,
        msg_body: currentTextMsg,
      };
      try {
        const response = await WhatsappServices.sendIndidualWhatsappMessage (
          data
        );
        if (response.data) {
          getCurrentChatAllMessages ();
          setCurrentTextMsg ();
          message.success ('Message Sent Successfully!');
          setSendingText (false);
        }
      } catch (err) {
        console.log (err);
        setSendingText (false);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    }
   
  };


  
  const handleSendMsg = async () => {
    setSendingText (true);
    let data = {
      to: currentChatUser.phoneNumber,
      template: {
        name: currentTemplate,
        language: {
          code: 'en-US',
        },
      },
      type: 'template',
    };
    try {
      const response = await WhatsappServices.sendWhatsappMessage (data);
      if (response.data) {
        getCurrentChatAllMessages ();
        message.success ('Template Message Sent Successfully!');
        setSendingText (false);
      }
    } catch (err) {
      console.log (err);
      setSendingText (false);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };



  const handleSelectTemplate = (value)=>{
    setCurrentTemplate (value);
    setCurrentTextMsg("Template: "+value)
  }
  return (
    <div className={'whatsappWrapper codersarts-chat'}>
      <div className="chatBanner">
        <div className="chatLogo">
          <div className="ml-2 mr-2 mt-1">
            <Link to="/">
              <HomeFilled className="homeIcon" />
            </Link>
          </div>
          <div style={{marginLeft: '5%'}}>
            <Link to="/" target={'_blank'}>
              <img src={logo} alt="codersarts" />
            </Link>
          </div>
          <div className="codersartsChatLogo">
            <Link to="/" target={'_blank'}>
              <h5>Whatsapp Chat</h5>
            </Link>
          </div>

        </div>

        <div className="profileArea">
          <div className="chatUserInfo">
            <h6>{currentUser.username && currentUser.username}</h6>
            <div className="chatUserBadge">
              {currentUser.roles && currentUser.roles[0].split ('_')[1]}
            </div>
          </div>

          <Link to="/profile" target={'_blank'}>
            <Tooltip color={'blue'} title="Profile">
              <img
                className="chatDp"
                src={
                  currentUser.profilePicture
                    ? currentUser.profilePicture
                    : avatar
                }
                alt="img"
              />
            </Tooltip>
          </Link>
        </div>
      </div>
      <div className={'whatsappChatAreaWrapper'}>
        <div className={'whatsappChats'}>
          <h4>Chats</h4>
          <div className="whatsappChat__userWrapper">
            {allChatUser.map (user => {
              return (
                <div
                  className={`whatsappChat__user ${user._id === currentChatUser._id ? 'activeChatUser' : ''}`}
                  onClick={() => handleCurrentChatClick (user)}
                >
                  <b>{user.name}</b>
                  <small>{user.phoneNumber}</small>
                </div>
              );
            })}

          </div>
        </div>
        <div className={'whatsappMsgArea'}>
          {currentChatUser
            ? <div className="whatsappChatBannerWrapper">
                <div className="whatsappChatBanner">
                  <div>
                    <h4>{currentChatUser.name}</h4>
                    <p>{currentChatUser.phoneNumber}</p>
                  </div>
                  <div>
                    <Tooltip title="Edit Chat User">
                      <EditOutlined
                        onClick={editChatUser}
                        className="whatsappMsgAreaIcon"
                      />
                    </Tooltip>
                    <Tooltip title="Delete Chat User">
                      <DeleteOutlined
                        onClick={handleDeleteUser}
                        style={{color: 'red'}}
                        className="whatsappMsgAreaIcon"
                      />
                    </Tooltip>
                  </div>

                </div>

                <div className="whatsapp__msgArea" >
                  {currentChatMessages.map (item => {
                    return item.type === 'text'
                      ? 
                      <div className={`${item.isReceived ?  "whatsapp__singleChatLeft" : "whatsapp__singleChatRight"}`} ref={scrollRef}>
                        <div className={`${item.isReceived ?  "whatsapp__chatText" : "whatsapp__chatTextReceived"}`} >
                         {item.msg_body}
                        </div>
                        <small>
                        {item.isReceived ? `${currentChatUser.name} ` : "You "} sent {format(item.createdAt)}
                        </small>
                      </div>
                     

                      :  <div className={"whatsapp__singleChatRight"} ref={scrollRef}>
                        <div className={"whatsapp__chatTemplate"} >
                         Template: {item.msg_body.name}
                        </div>
                        <small>
                        You sent {format(item.createdAt)}
                        </small>
                      </div>
                  })}
                </div>

                <div className="whatsappSendArea">
                  {/* <div>
                    <Select
                      style={{width: '80%'}}
                      onChange={value => setCurrentTemplate (value)}
                    >
                      {allTemplates.map ((item, i) => {
                        return (
                          <Option key={i} value={item.name}>{item.name}</Option>
                        );
                      })}
                    </Select>
                    <Button
                      onClick={handleSendMsg}
                      loading={loading}
                      type="primary"
                      disabled={!currentTemplate}
                    >
                      Send Template
                    </Button>
                  </div> */}
                  <div style={{marginTop: '10px'}}>
                    <Input.TextArea
                      style={{width: '80%',height:'fit-content'}}
                      value={currentTextMsg}
                      onChange={e => setCurrentTextMsg (e.target.value)}
                      placeholder="Send Message..."
                    />
                    <Dropdown placement={"topRight"} overlay={(
                      <Menu >
                        {allTemplates.map ((item, i) => {
                        return (
                          <Menu.Item  onClick={()=>handleSelectTemplate(item.name)} key={i}>{item.name}</Menu.Item>
                        );
                      })}
                      </Menu>
                    )}>
                      <Tooltip title="Add Templates" placement={"bottom"}>
                        <SnippetsOutlined style={{fontSize:'1.4rem',marginLeft:'5px',marginRight:'5px'}} />
                      </Tooltip>
                    </Dropdown>

                    <Tooltip title="Refresh Chat" placement={"bottom"}>
                        <RedoOutlined onClick={getCurrentChatAllMessages} style={{fontSize:'1.4rem',marginLeft:'5px',marginRight:'5px'}} />
                    </Tooltip>


                    <Button
                      onClick={handleSendIndividualMsg}
                      loading={sendingText}
                      type="primary"
                      disabled={!currentTextMsg}
                    >
                      Send
                    </Button>
                    
                  </div>
                 
                </div>
              </div>
            : <div className="whatsappChatImgWrapper">
                <img src={chat} alt="chat" />
                <h4>Start Chat By Clicking Chat Users</h4>
              </div>}
        </div>
        <div className="whatsappMsgControls">
          <Tooltip title="Add Chat User">
            <UserAddOutlined
              className="whatsappControlIcon"
              onClick={() => setIsAddChatUser (true)}
            />
          </Tooltip>
        </div>
      </div>

      <Modal
        title="Add Chat User"
        visible={isAddChatUser}
        onOk={handleAddChatUser}
        okText="Add"
        confirmLoading={loading}
        onCancel={() => setIsAddChatUser (false)}
      >
        <Input
          value={name}
          style={{margin: '10px'}}
          onChange={e => setName (e.target.value)}
          placeholder="Enter Chat User Name e.g. John Doe"
        />
        <Input
          value={phoneNumber}
          style={{margin: '10px'}}
          onChange={e => setPhoneNumber (e.target.value)}
          placeholder="Enter Phone Number e.g. +91 9871761641"
        />
      </Modal>

      <Modal
        title="Update Chat User"
        visible={isUpdatingChatUser}
        onOk={handleUpdatingChatUser}
        okText="Update"
        confirmLoading={loading}
        onCancel={() => setIsUpdatingChatUser (false)}
      >
        <Input
          value={updatedName}
          style={{margin: '10px'}}
          onChange={e => setUpdatedName (e.target.value)}
          placeholder="Enter Updated Chat User Name e.g. John Doe"
        />
        <Input
          value={updatedPhoneNumber}
          style={{margin: '10px'}}
          onChange={e => setUpdatedPhoneNumber (e.target.value)}
          placeholder="Enter Updated Phone Number e.g. +91 9871761641"
        />
      </Modal>
    </div>
  );
}

export default WhatsappCloud;
