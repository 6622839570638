import React,{useState,useEffect} from 'react'
import './SessionsForBooking.css';
import {Spin,message,Select,Breadcrumb} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import S3 from 'react-aws-s3';
import OrderService from '../../../services/order.service';
import userService from '../../../services/user.service';
import {Link} from 'react-router-dom';

const {Option} = Select;

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;

function SessionsForBooking() {
    const [name,setName] = useState("");
    const [details,setDetails] = useState("");
    const [readMoreLink,setReadMoreLink] = useState("");
    const [fileUrl,setFileUrl] = useState("");
    const [uploading,setUploading] = useState(false);
    const [awsData,setAwsData] = useState(null);
    const [loading,setLoading] = useState("");
    const [price,setPrice] = useState("");
    const [duration,setDuration] = useState("");
    const [technologies,setTechnologies] = useState([]);
    const [techonologiesArr,setTechnologiesArr] = useState([]);


    const handleFileChange = async (file)=>{
        const date = new Date();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        let yyyy = date.getFullYear()
        let hh = date.getHours();
        let minutes = date.getMinutes();
        let ss = date.getSeconds();
        let finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
        let newFileName = file.name + " " +  finalDate

        if(awsData){
            setUploading(true);
         

            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
              

            const ReactS3Client = new S3(config);
            ReactS3Client.uploadFile(file,newFileName).then(data=>{
                // console.log(data);
                setFileUrl(data.location);
                setUploading(false);
            }).catch(err=>{
                console.log(err);
                setUploading(false);
            })
        }
    }

    const getAwsInfo = async ()=>{
        try{
         const res = await OrderService.getAwsInfo();
         
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
 
     }

     useEffect(()=>{
        getAwsInfo();
        let isMount = true
        const getAllCategory = async ()=>{
            try{
              const response = await userService.getAllSkillsList();
              // console.log(response.data);
              if(isMount && response){
                setTechnologiesArr(response.data);
              }
            }catch(err){
              console.log(err);
              message.error(err.message ? err.message : 'Something went wrong!');
            }
          }

          getAllCategory();

          return ()=>{
            isMount = false
          }


     },[])

    useEffect(()=>{
        if(fileUrl){
            setUploading(false);
            // console.log(fileUrl);
        }
    },[fileUrl]);



    const handleSubmit = async (e)=>{
        e.preventDefault();
        let data = {
            name: name,
            details:details,
            readMoreLink:readMoreLink,
            duration:duration,
            price:price,
            image:fileUrl,
            technologies:technologies
        }
        setLoading(true);
        try{
            const response = await OrderService.createNewSessions(data);
            // console.log(response.data);
           if(response){
            setLoading(false);
            message.success("Created New Session Successfully!");
            setName("");
            setDetails("");
            setDuration("");
            setPrice("");
            setReadMoreLink("");
            setFileUrl("");
           }
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Unable to create new session!');
        }
    }


    const handleChange = (values)=>{
        setTechnologies(values);
    }


    return (
        <div className='sessionsForBooking'>
        <Spin tip="Uploading..." spinning={uploading}>
            <Breadcrumb>
            <Breadcrumb.Item> <Link to="/profile/all-sessions">All Sessions </Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/create-new-session`}>Create Session</Link></Breadcrumb.Item>
            </Breadcrumb>
             <div>
                <h2>Create New Session</h2>
            </div>
            <hr />
            <div>
                <form>
                    <div className="form-group">
                        <label>
                            Enter Session Name : 
                        </label>
                        <input  type="text" value={name} onChange={(e)=>{setName(e.target.value)}}  className="form-control" />
                    </div>
                    
                    <div className="form-group">
                        <label>
                            Enter Session Description : 
                        </label>
                       <textarea  value={details} onChange={(e)=>{setDetails(e.target.value)}} className="form-control"></textarea>
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Session Duration:
                        </label>
                        <input  type="text" value={duration} onChange={(e)=>{setDuration(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Price:
                        </label>
                        <input  type="text" value={price} onChange={(e)=>{setPrice(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Read More Link:
                        </label>
                        <input  type="text" placeholder='Leave Empty if not present' value={readMoreLink} onChange={(e)=>{setReadMoreLink(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Select Technologies:
                        </label>
                        <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        onChange={handleChange}
                        >
                        {techonologiesArr.map((item)=>{
                        return  <Option value={item.name} key={item._id}>{item.name}</Option>
                        })}
                    </Select>
                    </div>

                    <div className="form-group">
                        <label> Upload Image for Session: </label>
                        <input type="file" className="form-control file" onChange={(e)=>{handleFileChange(e.target.files[0])}}/>  
                    </div>
                    
                    <button type="submit" 
                    onClick={(e)=>{handleSubmit(e)}} 
                    disabled = {name === "" || details === "" || duration ==="" || price === "" || fileUrl===""} 
                    className="sessionsForBooking-btn pay-btn"
                    
                    >
                   {loading ? <Spin indicator={spinIcon} /> : 'Create Session'}
                    </button>
                </form>

            </div>

            </Spin>
        </div>
    )
}

export default SessionsForBooking
