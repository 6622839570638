import React, {useState, useEffect} from 'react';
import {Chart} from '../../Chart/Chart';
import './AttendanceChart.css';
import {Select,Button,Tooltip,Modal,message} from 'antd';
import OrderService from '../../../services/order.service';
import {useSelector} from 'react-redux';
import moment from 'moment';
import Confetti from 'react-confetti';
import {
    LoginOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    CalendarOutlined
  
  } from '@ant-design/icons';

const {Option} = Select;

function AttendanceChart () {
  const [chartData, setChartData] = useState ({});
  const [receivedData, setReceivedData] = useState ([]);
  const [filteredData, setFilteredData] = useState (null);
  const {user: currentUser} = useSelector (state => state.auth);
  const [enableCheckIn, setEnableCheckIn] = useState (false);
  const [enableCheckOut, setEnableCheckOut] = useState (false);
  const [totalWorkHours, setTotalWorkHours] = useState (0);
  const [isUserCheckedOut, setIsUserCheckedOut] = useState (false);
  const [isAlreadyCheckedOut,setIsAlreadyCheckedOut] = useState(false);
  const [currentCheckinData,setCurrentCheckinData] = useState("");

  let now = new Date ();
  let dd = now.getDate ();
  let mm = now.getMonth () + 1;
  let yyyy = now.getFullYear ();
  let currentDate = yyyy + '-' + mm + '-' + dd;


  let today = new Date ();
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [currentMonth, setCurrentMonth] = useState (
    today.toLocaleString ('default', {month: 'long'})
  );


  const fetchAttendance = async () => {
    const response = await OrderService.getUserAttendance ({
      userId: currentUser.id,
    });
    // console.log (response.data);
    setReceivedData (response.data);
    let data = [];
    let now = new Date ();
    response.data.forEach (item => {
      let attendanceDate = new Date (item.checkInDate);
      if ((item.checkout || item.isLeave) && now.getMonth () === attendanceDate.getMonth ()) {
        let modifiedData = {
          ...item,
        };

        data.push (modifiedData);
      }
    });

    data = data.sort (function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        moment (a.checkInDate, 'YYYY/MM/DD').unix () -
        moment (b.checkInDate, 'YYYY/MM/DD').unix ()
      );
    });

    const customColors = [];

    data.forEach (item => {
      if (item.workedHours > 9) {
        customColors.push ('#19b34c');
      } else if (item.workedHours < 4) {
        customColors.push ('#cc0202');
      }else if(item.isLeave){
        customColors.push('#6ffc03');
      } else {
        customColors.push ('#ffbb11');
      }
    });

    setChartData ({
      labels: data.map (item =>{
        if(item.isLeave){
        return  item.checkInDate + '( Leave )'
      }
      return item.checkInDate
    }),
      datasets: [
        {
          label: `Work Hours  ( Checkin and Checkout )`,
          data: data.map (item => {
            if(item.isLeave){
              return 8
            }
          return item.workedHours
        }),
          backgroundColor: customColors,
        },
      ],
    });
  };


  useEffect (
    () => {
      const fetchAttendance = async () => {
        const response = await OrderService.getUserAttendance ({
          userId: currentUser.id,
        });
        // console.log (response.data);
        setReceivedData (response.data);
        let data = [];
        let now = new Date ();
        response.data.forEach (item => {
          let attendanceDate = new Date (item.checkInDate);
          if ((item.checkout || item.isLeave) && now.getMonth () === attendanceDate.getMonth ()) {
            let modifiedData = {
              ...item,
            };

            data.push (modifiedData);
          }
        });

        data = data.sort (function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            moment (a.checkInDate, 'YYYY/MM/DD').unix () -
            moment (b.checkInDate, 'YYYY/MM/DD').unix ()
          );
        });

        const customColors = [];

        data.forEach (item => {
          if (item.workedHours > 9) {
            customColors.push ('#19b34c');
          } else if (item.workedHours < 4) {
            customColors.push ('#cc0202');
          }else if(item.isLeave){
            customColors.push('#6ffc03');
          } else {
            customColors.push ('#ffbb11');
          }
        });

        setChartData ({
          labels: data.map (item =>{
            if(item.isLeave){
            return  item.checkInDate + '( Leave )'
          }
          return item.checkInDate
        }),
          datasets: [
            {
              label: `Work Hours  ( Checkin and Checkout )`,
              data: data.map (item => {
                if(item.isLeave){
                  return 8
                }
              return item.workedHours
            }),
              backgroundColor: customColors,
            },
          ],
        });
      };
      fetchAttendance ();
    },
    [currentUser]
  );


  
  const getTodaysAttendance = async () => {
    try {
      const response = await OrderService.getTodaysAttendance ({
        checkInDate: currentDate,
        attendanceUser: currentUser.id,
      });
      // console.log (response.data);
      if (response) {
        setCurrentCheckinData(response.data[0])
        if (response.data.length > 0) {
          setEnableCheckIn (false);
          if (!response.data[0].checkout && !response.data[0].isLeave) {
            // console.log ('checkout enable');
            setEnableCheckOut (true);
           
            
          }

          if(response.data[0].checkout && !response.data[0].isLeave){
            setIsAlreadyCheckedOut(true);
            
          }


        } else {
          setEnableCheckIn (true);
        }
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (
    () => {
      const getTodaysAttendance = async () => {
        try {
          const response = await OrderService.getTodaysAttendance ({
            checkInDate: currentDate,
            attendanceUser: currentUser.id,
          });
          // console.log (response.data);
          if (response) {
            setCurrentCheckinData(response.data[0])
            if (response.data.length > 0) {
              setEnableCheckIn (false);
              
              if (!response.data[0].checkout && !response.data[0].isLeave) {
                // console.log ('checkout enable');
                setEnableCheckOut (true);
               
                
              }
    
              if(response.data[0].checkout && !response.data[0].isLeave){
                setIsAlreadyCheckedOut(true);
                
              }


            } else {
              setEnableCheckIn (true);
            }
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      getTodaysAttendance ();
    },
    [currentUser, currentDate]
  );




  const handleWeeklyAttendance = value => {
    let date = new Date ();
    let dd = date.getDate ();
    let mm = date.getMonth () + 1;
    let yyyy = date.getFullYear ();
    let today = yyyy + '-' + mm + '-' + dd;

    if (value === 'thisWeek') {
      setCurrentMonth ('This Week');
      let thisWeek = receivedData.filter (item => {
        let deadlineDate = moment (item.checkInDate, 'YYYY/MM/DD');
        let updatedToday = moment (today, 'YYYY/MM/DD');
        return updatedToday.isoWeek () === deadlineDate.isoWeek ();
      });
      //   console.log (value);
      //   console.log (thisWeek);
      thisWeek = thisWeek.sort (function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          moment (a.checkInDate, 'YYYY/MM/DD').unix () -
          moment (b.checkInDate, 'YYYY/MM/DD').unix ()
        );
      });

       const customColors = [];

        thisWeek.forEach (item => {
          if (item.workedHours > 9) {
            customColors.push ('#19b34c');
          } else if (item.workedHours < 4) {
            customColors.push ('#cc0202');
          }else if(item.isLeave){
            customColors.push('#6ffc03');
          } else {
            customColors.push ('#ffbb11');
          }
        });

        setChartData ({
          labels: thisWeek.map (item =>{
            if(item.isLeave){
            return  item.checkInDate + '( Leave )'
          }
          return item.checkInDate
        }),
          datasets: [
            {
              label: `Work Hours  ( Checkin and Checkout )`,
              data: thisWeek.map (item => {
                if(item.isLeave){
                  return 8
                }
              return item.workedHours
            }),
              backgroundColor: customColors,
            },
          ],
        });
    } else if ('lastWeek') {
      //   console.log (value);
      setCurrentMonth ('Last Week');
      let lastWeek = receivedData.filter (item => {
        let deadlineDate = moment (item.checkInDate, 'YYYY/MM/DD');
        let updatedToday = moment (today, 'YYYY/MM/DD');
        return updatedToday.isoWeek () - 1 === deadlineDate.isoWeek ();
      });

      lastWeek = lastWeek.sort (function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          moment (a.checkInDate, 'YYYY/MM/DD').unix () -
          moment (b.checkInDate, 'YYYY/MM/DD').unix ()
        );
      });

      const customColors = [];

        lastWeek.forEach (item => {
          if (item.workedHours > 9) {
            customColors.push ('#19b34c');
          } else if (item.workedHours < 4) {
            customColors.push ('#cc0202');
          }else if(item.isLeave){
            customColors.push('#6ffc03');
          } else {
            customColors.push ('#ffbb11');
          }
        });

        setChartData ({
          labels: lastWeek.map (item =>{
            if(item.isLeave){
            return  item.checkInDate + '( Leave )'
          }
          return item.checkInDate
        }),
          datasets: [
            {
              label: `Work Hours  ( Checkin and Checkout )`,
              data: lastWeek.map (item => {
                if(item.isLeave){
                  return 8
                }
              return item.workedHours
            }),
              backgroundColor: customColors,
            },
          ],
        });
    
      //   console.log (lastWeek);
    }
  };

  const handleMonthChange = selectedMonth => {
    setCurrentMonth (months[selectedMonth]);
    let filteredItems = receivedData.filter (item => {
      let itemDate = new Date (item.checkInDate);
      return itemDate.getMonth () === selectedMonth;
    });
    filteredItems = filteredItems.sort (function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return (
        moment (a.checkInDate, 'YYYY/MM/DD').unix () -
        moment (b.checkInDate, 'YYYY/MM/DD').unix ()
      );
    });
    const customColors = [];

    filteredItems.forEach (item => {
          if (item.workedHours > 9) {
            customColors.push ('#19b34c');
          } else if (item.workedHours < 4) {
            customColors.push ('#cc0202');
          }else if(item.isLeave){
            customColors.push('#6ffc03');
          } else {
            customColors.push ('#ffbb11');
          }
        });

        setFilteredData ({
          labels: filteredItems.map (item =>{
            if(item.isLeave){
            return  item.checkInDate + '( Leave )'
          }
          return item.checkInDate
        }),
          datasets: [
            {
              label: `Work Hours  ( Checkin and Checkout )`,
              data: filteredItems.map (item => {
                if(item.isLeave){
                  return 8
                }
              return item.workedHours
            }),
              backgroundColor: customColors,
            },
          ],
        });

    // console.log (filteredItems);
  };



  
  const handleCheckin = async () => {
    let today = new Date ();
    // console.log (today);
    try {
      let data = {
        attendanceUser: currentUser.id,
        checkin: today.toISOString (),
        checkInDate: currentDate,
      };
      // console.log (data);
      const response = await OrderService.createNewAttendance (data);
      // console.log (response);
      if (response) {
        message.success ('Checked in Successfully!');
        getTodaysAttendance ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleCheckout = async () => {
    // console.log ('checkout');
    let today = new Date ();

    // console.log (today.getHours ());
    let due = new Date (currentCheckinData.checkin);
    // console.log (due.getHours ());
    var diffMs = today - due;

    var diffHrs = Math.floor (diffMs % 86400000 / 3600000);
    // console.log (diffHrs);

    setTotalWorkHours (diffHrs);

    try {
      const response = await OrderService.updateAttendance ({
        userId: currentUser.id,
        checkInDate: currentDate,
        updatedData: {
          checkout: today.toISOString (),
          workedHours:diffHrs
        },
      });
      if (response) {
        // console.log (response.data);
        message.success ('Checked Out successfully!');
        getTodaysAttendance ();
        setIsUserCheckedOut (true);
        setEnableCheckOut (false);
        fetchAttendance();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };





  return (
    <div className="AttendanceChart">
      <div className="attendanceChart__header">
        <h2>Attendance Chart</h2>
        <div style={{display:'flex',alignItems:'center'}}>
          <Select
            placeholder="Select By Week"
            style={{width: 'fit-content',marginLeft:'10px',marginRight:'10px'}}
            onChange={handleWeeklyAttendance}
          >
            <Option value={'thisWeek'} key="1">This Week</Option>
            <Option value={'lastWeek'} key="2">Last Week</Option>
          </Select>

          <Select
            defaultValue={currentMonth}
            style={{width: 120,marginLeft:'10px',marginRight:'10px'}}
            onChange={handleMonthChange}
          >
            {months.map ((item, index) => {
              return <Option value={index} key={index}>{item}</Option>;
            })}
          </Select>

          {currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') && 
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              enableCheckIn &&
              <>
              <Button
                type={'primary'}
                onClick={handleCheckin}
                style={{marginLeft: '1%'}}
              >
                <LoginOutlined /> Check-In
              </Button>

            
              </>

              
              }

            {currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') &&
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              !enableCheckIn &&
              <Button
                type={'primary'}
                onClick={handleCheckout}
                disabled={!enableCheckOut}
                style={{marginLeft: '1%'}}
              >
              <LogoutOutlined />Check-Out
              </Button>
              }
              { currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') &&
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              !enableCheckIn && 
              <>
             { enableCheckOut ? 
                <>
                </>
                :
                <Tooltip  placement="bottomLeft" title={isAlreadyCheckedOut ? "You have already checked-out!" : "Can't check-out now!"}>
                <QuestionCircleOutlined style={{marginLeft:'10px',cursor:'pointer'}} />
                </Tooltip>}
              </>
              }


        </div>
      </div>
      <hr />
      <div className="chartArea">
        <Chart
          chartData={filteredData ? filteredData : chartData}
          month={currentMonth}
        />
      </div>

      <Modal
        title="Checkout Summary"
        visible={isUserCheckedOut}
        onCancel={() => setIsUserCheckedOut (false)}
        footer={false}
      >
        <div style={{fontStyle: 'normal'}}>

          <h3 style={{color: 'green', marginBottom: '5px'}}>
            You Spent {totalWorkHours} Hours Today!
          </h3>
          <div style={{fontSize: '14px', color: 'gray', textAlign: 'justify'}}>
            This is the duration from your checkin and checkout timing.
          </div>

        </div>
      </Modal>

      {isUserCheckedOut &&
        <Confetti
          recycle={isUserCheckedOut}
          numberOfPieces={300}
          width={1200}
          height={1200}
        />}

    </div>
  );
}

export default AttendanceChart;
