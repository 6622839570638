import React, { useState,useEffect } from 'react';
import { Layout,Tooltip, Menu} from 'antd';
import { Route, Router } from 'react-router';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Orders from '../Orders/Orders';
import EditSingleUser from '../EditSingleUser/EditSingleUser';
import UserOrders from '../UserOrders/UserOrders';
import EditOrder from '../ManageOrders/EditOrder/EditOrder';
import MyEmployee from '../MyEmployees/MyEmployee';
import CreateOrderForUser from '../CreateOrderForUser/CreateOrderForUser';
import AssignedOrders from '../AssignedOrders/AssignedOrders';
import FeedbackOrders from '../FeedbackOrders/Feedback';
import RecycleBin from '../RecycleBin/RecycleBin';
import SessionDetails from '../SessionDetails/SessionDetails';
import AdminSubscription from '../AdminSubscription/AdminSubscription';
import chatService from '../../../services/chat.service';
import userService from '../../../services/user.service';
import YoutubeInfoPanel from '../YoutubeInfoPanel/YoutubeInfoPanel.jsx';
import MobileBanner from '../MobileBanner/MobileBanner';

import {
  DesktopOutlined,
  PlayCircleOutlined,
  ShareAltOutlined,
  ScheduleOutlined,
  AuditOutlined,
  UsergroupAddOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined,
  SettingOutlined,
  SnippetsOutlined,
  BookOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  EditOutlined,
  DollarOutlined,
  DollarCircleOutlined,
  GlobalOutlined,
  MailOutlined,
  DeleteOutlined,
  CreditCardOutlined,
  AmazonOutlined,
  GoogleOutlined,
  ControlOutlined,
  UploadOutlined,
  ReconciliationOutlined,
  BarChartOutlined,
  RedEnvelopeOutlined,
  WechatOutlined,
  NotificationOutlined,
  DashboardOutlined,
  CalendarOutlined ,
  LoginOutlined,
  MobileOutlined,
  WhatsAppOutlined,
  ContainerOutlined
} from '@ant-design/icons';


import './SideNav.css';
import Profile from '../Profile';
import ManageUsers from '../ManageUsers/ManageUsers';
import CreateUsers from '../CreateUsers/CreateUsers';
import CreateOrderForm from '../ManageOrders/CreateOrderForm/CreateOrderForm';
import ViewOrders from '../ManageOrders/ViewOrders/ViewOrders';
import ViewOrderDetails from '../ViewOrderDetails/ViewOrderDetails';
import AssignOrderToEmployee from '../AssignOrderToEmployee/AssignOrderToEmployee';
import MyAssignment from '../../MyAssignment/MyAssignment';
import AllFeedback from '../AllFeedback/AllFeedback';
import FileShare from '../../FileShare/FileShare';
import UserFileShare from '../UserFileShare/UserFileShare';
import PublicOrders from '../../PublicOrders/PublicOrders';
import ManageLanding from '../ManageLanding/ManageLanding';
import SinglePublicOrder from '../SinglePublicOrder/SinglePublicOrder';
import ViewSingleUser from '../ManageUsers/ViewSingleUser/ViewSingleUser';
import CheckoutForm from '../stripe/CheckoutForm/CheckoutForm';
import AllPayments from '../AllPayments/AllPayments';
import SinglePaymentDetails from '../../SinglePaymentDetails/SinglePaymentDetails';
import CreateManualPayment from '../../CreateManualPayment/CreateManualPayment';
import PurchasedOrders from '../../PurchasedOrders/PurchasedOrders';
import AllPublicPayments from '../AllPublicPayments/AllPublicPayments';
import SinglePublicPaymentDetails from '../../SinglePublicPaymentDetails/SinglePublicPaymentDetails';
import DriveFiles from '../DriveFiles/DriveFiles';
import AdminLinks from '../AdminLinks/AdminLinks';
import SendMailToUser from '../../SendMailToUser/SendMailToUser';
import AllLogs from '../../AllLogs/AllLogs';
import ExportedDataList from '../ExportedDataList/ExportedDataList';
import InvoiceMaker from '../InvoiceMaker/InvoiceMaker';
import UsersTableForSales from '../ManageUsersForSales/UsersTableForSales/UsersTableForSales';
import ManageOrdersForSales from '../ManageOrdersForSales/ManageOrdersForSales';
import PublicLinks from '../PublicLinks/PublicLinks';
import MailRecords from '../MailRecords/MailRecords';
import PurchasedHistory from '../../PurchasedHistory/PurchasedHistory';
import DashboardHome from '../DashboardHome/DashboardHome.jsx';
import ContactUsData from '../ContactUsData/ContactUsData';
import SessionsForBooking from '../SessionsForBooking/SessionsForBooking';
import AllBookings from '../AllBookings/AllBookings';
import MyBookings from '../MyBookings/MyBookings';
import SingleBookingDetails from '../SingleBookingDetails/SingleBookingDetails';
import CreateNewService from '../../CreateNewService/CreateNewService';
import AllServicesForm from '../AllServicesForHome/AllServicesForHome';
import SingleServiceDetails from '../../SingleServiceDetails/SIngleServiceDetails';
import AllSessions from '../AllSessions/AllSessions';
import CreateManualBooking from '../CreateManualBooking/CreateManualBooking';
import EditSessions from '../EditSessions/EditSessions';
import EditServices from '../EditServices/EditServices';
import UserSubscription from '../UserSubscription/UserSubscription';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionRecords from '../SubscriptionRecords/SubscriptionRecords';
import SingleSubscriptionDetails from '../SingleSubscriptionDetails/SingleSubscriptionDetails';
import Invoices from '../Invoices/Invoices';
import MyNotifications from '../MyNotifications/MyNotifications';
import DevRequest from '../DevRequest/DevRequest';
import SingleDevRequest from '../DevRequest/SingleDevRequest';
import BookASession from '../BookASession/BookASession';
import NewAssignmentHelp from '../NewAssignmentHelp/NewAssignmentHelp';
import AllAssignmentRequest from '../AllAssignmentRequest/AllAssignmentRequest';
import SkillsCategoryList from '../SkillsCategoryList/SkillsCategoryList';
import ToDoList from '../ToDoList/ToDoList';
import { history } from '../../../helpers/history';
import AllAttendance from '../AllAttendance/AllAttendance';
import AttendanceChart from '../AttendanceChart/AttendanceChart';
import UserLeave from '../UserLeave/UserLeave';
import YoutubePlaylistComponent from '../YoutubePlaylistComponent/YoutubePlaylistComponent'
import EmployeeResume from '../EmployeeResume/EmployeeResume';
import AdminEvents from '../AdminEvents/AdminEvents';
import CreateEvent from '../AdminEvents/CreateEvent';
import ViewEvent from '../AdminEvents/ViewEvent';
import UpdateEvent from '../AdminEvents/UpdateEvent';
import MyEvents from '../MyEvents/MyEvents';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const stripePromise = loadStripe("pk_live_51JQbRNSB5a1UKLbSiZ51EixOqSnJdt5vtPdpj39w5JeJVvIKWFZDP9MSPlloPE3K5ONwDz1N4ztTVzcWgsziZrsR00mpCLICR7");

// const stripePromise = loadStripe("pk_test_51IrIEHSDzoY8POJmATvNCcGFdek4IIddH9JLd0WLWY2gjRUeiLgGG5aBwuKqZe3lyt6PfKykwi8Vxl0l2gtaPLV700F3HbbrX6");


const SiderDemo = ()=>{
  const [collapsed,setCollapsed] = useState(false);
  const [chatInfo,setChatInfo] = useState("");
  // const [isGroupMember,setIsGroupMember] = useState(false);
  // const [isGroupTabShown,setIsGroupTabShown] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);


  useEffect(()=>{
    if(currentUser.roles[0].toLowerCase()==='role_admin' 
    || currentUser.roles[0].toLowerCase()==='role_sales'){
      setChatInfo("This is for chatting with the admin,managers,sales team and employee and users!");

    }else{
      setChatInfo("This is for chatting with the admin and sales team for your help!");

    }
  },[currentUser])
  const onCollapse = (collapsed) => {
    // console.log(collapsed);
    setCollapsed(collapsed );
  };

  useEffect(async ()=>{
   
    if(currentUser.roles[0].toLowerCase()==='role_admin' || currentUser.roles[0].toLowerCase()==='role_sales'){
      try{
        const res =  await userService.getAllUsers();

        // console.log("ALL USERS",res.data);
        res.data.map(async (d)=>{
          if(d.roles[0].name==="user"){
            // console.log("Fell Into");
            try{
              const response = await chatService.getQueryGroupName(d.username);
              // console.log(response1.data);
              // console.log("Query Group Count : ",response);
              if(response.data.length===0 || response.data===null){
                const response2 = await chatService.createBackendGroup(d._id,d.username,d.profilePicture);
                // console.log(response2.data);
              }
             
          }catch(err){
            console.log(err);
          }
          }else{
            // console.log("fell into 2");
            try{
              const response = await chatService.getConversationOnline(currentUser.id,d._id);
              if(response.data===null){
                const result = await chatService.createConversation(currentUser.id,d._id);
                // console.log(result);
              }
            }catch(err){
              console.log(err);
            }
          }
        })
      
      }catch(err){
        console.log(err);
      }
      
    }else if(currentUser.roles[0].toLowerCase()==='role_employee'){
      let manager = []
      userService.getUserById(currentUser.id).then(response=>{
        manager = response.data.manager;
        
        manager && manager.map((m)=>{

          chatService.getConversationOnline(currentUser.id,m._id).then((response)=>{
              // console.log(response.data);
              if(response.data===null){
                 chatService.createConversation(currentUser.id,m._id).then((res=>{
                    // console.log("CHAT B/W EMPLOYEE AND MANAGER",res.data);
                  })).catch(err=>{
                    console.log(err);
                  })
              }
            }).catch(err=>{
              console.log(err);
            })
        })
      }).catch(err=>{
        console.log(err);
      })
    }else if(currentUser.roles[0].toLowerCase()==='role_manager'){
      let employees = []
      userService.getAllEmployees(currentUser.id).then(response=>{
        employees = response.data;
        // console.log("EMPLOYEES : ",employees)
        // setAllEmployees(employees);
        employees.map(e=>{
          chatService.getConversationOnline(currentUser.id,e._id).then(response=>{
            if(!response.data){
              chatService.createConversation(currentUser.id,e._id).then(response=>{
                // console.log("CREATED CONV : ",response);
              }).catch(err=>{
                console.log(err);
              })
            }
          }).catch(err=>{
            if(err.response.status==404){
              console.log("NOT FOUND");
            }
          })
        })

        }).catch(err=>{
          console.log(err);
        })
    }else if(currentUser.roles[0].toLowerCase()==='role_user'){
      try{
        const response = await chatService.getQueryGroupName(currentUser.username);
        // console.log(response1.data);
        // console.log("Query Group Count : ",response);
        if(response.data.length===0 || response.data===null){
          const response2 = await chatService.createBackendGroup(currentUser.id,currentUser.username,currentUser.profilePicture);
          // console.log(response2.data);
        }
       
    }catch(err){
      console.log(err);
    }
    }
  },[])

  // useEffect(()=>{
  //   createConversationWithAdminAndSales();
  // },[])

  // const isUserAGroupMember = async ()=>{
  //   try{
  //     const response = await ChatService.isUserAGroupMember(currentUser.id);
  //     console.log("IS MEMBER : ",response.data);
  //     setIsGroupMember(response.data.isPresent);
  //   }catch(err){
  //     console.log(err);
  //   }
  // }

  // useEffect(()=>{
  //   isUserAGroupMember();
  // },[]);

  // useEffect(()=>{
  //   if(currentUser && currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES') ){
  //     setIsGroupTabShown(true);
  //   }else if(currentUser && 
  //     (currentUser.roles.includes('ROLE_USER') 
  //     || currentUser.roles.includes('ROLE_EMPLOYEE') 
  //     || currentUser.roles.includes('ROLE_MANAGER'))
  //     && isGroupMember
  //     ){
  //     setIsGroupTabShown(true);
  //   }

  // },[isGroupMember])
 
  
  


    return (
      <Layout style={{ minHeight: '100vh',marginLeft:'0%',marginTop:'4%',minWidth:"101%" }}>
        <Sider  theme="light" collapsible style={{marginLeft:'-2%'}} collapsed={collapsed} onCollapse={onCollapse}>
          <div className="logo" />
          <Menu theme="light" defaultSelectedKeys={['0']} mode="inline">
           
           
           <Menu.Item key="0" icon={<BarChartOutlined />}>
              Home
            <Link to="/profile/codersarts-dashboard-home" />
            </Menu.Item>
           
           

            <Menu.Item key="1" icon={<UserOutlined />}>
              My Profile
            <Link to="/profile" />
            </Menu.Item>
            {/* {
            (currentUser && currentUser.roles.includes('ROLE_USER') && (
              <SubMenu key="2"   icon={<SettingOutlined />} title="My Orders">
                    <Menu.Item key="3"  icon={<BookOutlined />}>View Orders <Link to="/profile/user-orders" /> </Menu.Item>
                    <Menu.Item key="4"  icon={<FormOutlined />}>Create Orders <Link to="/profile/user/create-order" /> </Menu.Item>
                    
                </SubMenu>
            ))
           } */}
           {
            (currentUser && currentUser.roles.includes('ROLE_USER') && (
                    <Menu.Item key="MyOrders"  icon={<BookOutlined />}>My Orders <Link to="/profile/user-orders" /> </Menu.Item>
                    
            ))
           }
           {
            (currentUser && (
              <Menu.Item key="myEvents"  icon={<CalendarOutlined />}>My Events <Link to="/profile/my-events" /> </Menu.Item>
                    
            ))
           }
           

           {
            (currentUser && currentUser.roles.includes('ROLE_ADMIN') && (
                <SubMenu key="sub1"   icon={<ControlOutlined />} title="Manage">
                    <Menu.Item key="3"  icon={<TeamOutlined />}>Users <Link to="/profile/manage-users" /> </Menu.Item>
                    <Menu.Item key="5"  icon={<SnippetsOutlined />}>Orders <Link to="/profile/view-orders" /> </Menu.Item>
                    <Menu.Item key="12" icon={<ShoppingCartOutlined />}>
                    Public Orders
                    <Link to="/profile/public-orders"></Link>
                    </Menu.Item>
                    <Menu.Item key="events"  icon={<CalendarOutlined />}>Events <Link to="/profile/all-events" /> </Menu.Item>
                    <Menu.Item key="devRequest" icon={<UsergroupAddOutlined />}>
                      Developer Request
                    <Link to="/profile/developer-request"></Link>
                    </Menu.Item>
                    <Menu.Item key="bookings"  icon={<CreditCardOutlined />}>Bookings <Link to="/profile/all-bookings" /> </Menu.Item>
                    <Menu.Item key="Subscriptions" icon={<AuditOutlined />}>
                    Subscription Records
                    <Link to="/profile/subscription-records"></Link>
                    </Menu.Item>
                    <Menu.Item key="skillCategory" icon={<EditOutlined />}>
                    Skills Category
                    <Link to="/profile/skills-category"></Link>
                    </Menu.Item>
                    <SubMenu key="subYoutube"   icon={<PlayCircleOutlined />} title="Youtube">
                        <Menu.Item key="10" icon={<PlayCircleOutlined />}>
                        Videos
                        <Link to="/profile/manage-youtube"></Link>
                        </Menu.Item>

                        <Menu.Item key="playlist"  icon={<PlayCircleOutlined />}>Playlist <Link to="/profile/manage-youtube-playlist" /> </Menu.Item> 
                </SubMenu>

                   
                    <Menu.Item key="mobileBanner" icon={<MobileOutlined />}>
                    Mobile Banner
                    <Link to="/profile/manage-mobile-banner"></Link>
                    </Menu.Item>
                    {/* <Menu.Item key="Assignment Request" icon={<AuditOutlined />}>
                    Assignment Requests
                    <Link to="/profile/all-assignment-request"></Link>
                    </Menu.Item> */}
                </SubMenu>
            ))
           }

           {
            (currentUser.roles.includes('ROLE_SALES') && (
                <SubMenu key="subUpload"   icon={<ShareAltOutlined />} title="File Share">
                <Menu.Item key="10" icon={<AmazonOutlined />}>
                 AWS S3
                <Link to="/profile/file-share"></Link>
                </Menu.Item>

                <Menu.Item key="bookings"  icon={<CreditCardOutlined />}>Bookings <Link to="/profile/all-bookings" /> </Menu.Item>

                {currentUser.roles.includes('ROLE_SALES') ? null : 
                <Menu.Item key="11" icon={<GoogleOutlined />}>
                 Drive Files
                <Link to="/profile/drive-files"></Link>
                </Menu.Item>
                }
               
                </SubMenu>
            ))
           }

           {currentUser && currentUser.roles.includes('ROLE_ADMIN') && 
           <Menu.Item key="attendanceDetails" icon={<LoginOutlined />}>
                 Attendance Record
              <Link to="/profile/attendance-record"></Link>
           </Menu.Item>
           }

           {currentUser && !currentUser.roles.includes('ROLE_ADMIN') 
           && !currentUser.roles.includes('ROLE_USER') &&
           
           <SubMenu key="Attendance"   icon={<CalendarOutlined  />} title="Attendance">
                  <Menu.Item key="myAttendance" icon={<BarChartOutlined />}>
                        Attendance Stats
                      <Link to="/profile/attendance-record-chart"></Link>
                  </Menu.Item>
                  <Menu.Item key="userLeave" icon={<ScheduleOutlined />}>
                    Leaves
                  <Link to="/profile/user-leave"></Link>
                  </Menu.Item>
            </SubMenu>
           }

           {
            (currentUser && currentUser.roles.includes('ROLE_ADMIN') && (
               <>
               <SubMenu key="bookingAndServices"   icon={<CreditCardOutlined />} title="Sessions & Services">
                  <Menu.Item key="All Sessions" icon={<CreditCardOutlined />}>
                  All Sessions
                  <Link to="/profile/all-sessions"></Link>
                  </Menu.Item>
                  <Menu.Item key="All Services" icon={<ScheduleOutlined />}>
                  All Services
                  <Link to="/profile/all-services"></Link>
                  </Menu.Item>
              </SubMenu>


               <SubMenu key="subUpload"   icon={<ShareAltOutlined />} title="File Share">
                <Menu.Item key="10" icon={<AmazonOutlined />}>
                 AWS S3
                <Link to="/profile/file-share"></Link>
                </Menu.Item>
                
                <Menu.Item key="11" icon={<GoogleOutlined />}>
                 Drive Files
                <Link to="/profile/drive-files"></Link>
                </Menu.Item>
                
               
                </SubMenu>
               <SubMenu key="subSettings"   icon={<SettingOutlined />} title="Settings">
              


                <Menu.Item key="13" icon={<EditOutlined />}>
                 Edit Home
                <Link to="/profile/manage-landing"></Link>
                </Menu.Item>
                <Menu.Item key="14" icon={<GlobalOutlined />}>
                  Links
                <Link to="/profile/admin-links"></Link>
                </Menu.Item>
                <Menu.Item key="55" icon={<FileOutlined />}>
                  Recent Logs
                <Link to="/profile/all-logs"></Link>
                </Menu.Item>
                <Menu.Item key="121" icon={<UploadOutlined />}>
                  Exported Files
                <Link to="/profile/all-exported-files"></Link>
                </Menu.Item>
               
                </SubMenu>

                <SubMenu key="subFinance"   icon={<DollarOutlined />} title="Finance">
                {/* <Menu.Item key="122" icon={<FileAddOutlined />}>
                  Invoice Creator
                <Link to="/profile/create-invoice"></Link>
                </Menu.Item> */}
                <Menu.Item key="allInvoices" icon={<SnippetsOutlined />}>
                  Invoices
                <Link to="/profile/all-invoices"></Link>
                </Menu.Item>
                <Menu.Item key="subscriptions" icon={<AuditOutlined />}>
                  Subscriptions
                <Link to="/profile/admin-subscription-panel"></Link>
                </Menu.Item>
                </SubMenu>
               </>
            ))
           }


           {
            (currentUser && currentUser.roles.includes('ROLE_MANAGER') && (
                <Menu.Item key="6" icon={<DesktopOutlined />}>
                 My Employees
                <Link to="/profile/employee"></Link>
                </Menu.Item>
            ))
           }
           {
            (currentUser && currentUser.roles.includes('ROLE_EMPLOYEE') && (
               <>
               <Menu.Item key="7" icon={<DesktopOutlined />}>
                 My Tasks
                <Link to="/profile/assignment"></Link>
                </Menu.Item>
                
                <Menu.Item key="myResume" icon={<ContainerOutlined />} >
                 My Resume
                <Link to="/profile/employee-resume"></Link>
                </Menu.Item>
               </>
            ))
           }

           

           {
            (currentUser && !currentUser.roles.includes('ROLE_USER') && (
               <>
               <Menu.Item key="toDoList" icon={<DashboardOutlined />}>
                 To-Do List
                <Link to="/profile/to-do-list"></Link>
                </Menu.Item>
               </>
            ))
           }

           
            {
            (currentUser && !currentUser.roles.includes('ROLE_USER') && !currentUser.roles.includes('ROLE_SALES') && !currentUser.roles.includes('ROLE_ADMIN') ? (
                <Menu.Item key="8" icon={<FileOutlined />}>
                  My Projects
                <Link to="/profile/assigned-orders" />
                </Menu.Item>
            ):null)
                
            }

            {
            (currentUser && currentUser.roles.includes('ROLE_ADMIN') && (
              <>
                <SubMenu key="mailDrops"   icon={<MailOutlined />} title="Communications">
                <Menu.Item key="17" icon={<ReconciliationOutlined />}>
                  Mail Records
                <Link to="/profile/mailRecords"></Link>
                </Menu.Item>
                <Menu.Item key="publicMessage" icon={<RedEnvelopeOutlined />}>
                  Client Query
                <Link to="/profile/public-messages"></Link>
                </Menu.Item>
                <Menu.Item key="allFeedback" icon={<QuestionCircleOutlined />}>
                 All Feedback
                <Link to="/profile/all-feedback"></Link>
                </Menu.Item>
                </SubMenu>
               
                <Menu.Item key="18" icon={<DeleteOutlined />}>
                  Recycle Bin
                <Link to="/profile/recycleBin"></Link>
                </Menu.Item>
              
              </>
            ))
           }

           {
            (currentUser && currentUser.roles.includes('ROLE_ADMIN') && (
                <SubMenu key="paymentSub"   icon={<DollarCircleOutlined />} title="Payments">
                    <Menu.Item key="22"  icon={<DollarOutlined />}>Order Payments <Link to="/profile/all-order-payments" /> </Menu.Item>
                    <Menu.Item key="23"  icon={<DollarOutlined />}>Public Payments <Link to="/profile/all-public-payments" /> </Menu.Item>
                </SubMenu>
            ))
           }
           {
            (currentUser && currentUser.roles.includes('ROLE_USER') && (
             <>
              <Menu.Item key="20" icon={<BookOutlined />}>
              Purchased Orders
              <Link to="/profile/purchased-orders" />
              </Menu.Item>
              
              <Menu.Item key="paymentHistory" icon={<DollarOutlined />}>
              Payment History
              <Link to="/profile/paymentHistory" />
              </Menu.Item>
              <Menu.Item key="MyBookings" icon={<CreditCardOutlined />}>
                  My Bookings
                  <Link to="/profile/my-bookings"></Link>
              </Menu.Item>
             {((currentUser.subscriptionType[0] && currentUser.subscriptionType[0].name)) ?
             null:
             <Menu.Item key="Subscription" icon={<AuditOutlined />}>
                  Subscriptions
                  <Link to="/profile/user-subscription"></Link>
              </Menu.Item>
              }
              
             </>
            ))
           }

           {
            (currentUser && currentUser.roles.includes('ROLE_SALES') && (
             <>
             <SubMenu key="bookingAndServices"   icon={<CreditCardOutlined />} title="Sessions & Services">
                  <Menu.Item key="All Sessions" icon={<CreditCardOutlined />}>
                  All Sessions
                  <Link to="/profile/all-sessions"></Link>
                  </Menu.Item>
                  <Menu.Item key="All Services" icon={<ScheduleOutlined />}>
                  All Services
                  <Link to="/profile/all-services"></Link>
                  </Menu.Item>
                  {/* <Menu.Item key="Assignment Request" icon={<AuditOutlined />}>
                    Assignment Requests
                    <Link to="/profile/all-assignment-request"></Link>
                  </Menu.Item> */}
              </SubMenu>

             <Menu.Item key="21" icon={<UserOutlined />}>
                  Users
              <Link to="/profile/users-for-sales-role"></Link>
              </Menu.Item>
              <Menu.Item key="publicMessage" icon={<RedEnvelopeOutlined />}>
                  Public Messages
                <Link to="/profile/public-messages"></Link>
                </Menu.Item>
              <SubMenu key="salesSub"   icon={<ControlOutlined />} title="Manage">
              <Menu.Item key="22" icon={<SnippetsOutlined />}>
                  All Orders
              <Link to="/profile/orders-for-sales-role"></Link>
              </Menu.Item>
              <Menu.Item key="publicorders"  icon={<ShoppingCartOutlined />}>
              Public Orders
               <Link to="/profile/public-orders" /> 
               </Menu.Item>
        
               <Menu.Item key="skillCategory" icon={<EditOutlined />}>
                    Skills Category
                    <Link to="/profile/skills-category"></Link>
               </Menu.Item>


              </SubMenu>
              <SubMenu key="paymentSubForSales"   icon={<DollarCircleOutlined />} title="Payments">
                    <Menu.Item key="orderP"  icon={<DollarOutlined />}>Order Payments <Link to="/profile/all-order-payments" /> </Menu.Item>
                    <Menu.Item key="PublicP"  icon={<DollarOutlined />}>Public Payments <Link to="/profile/all-public-payments" /> </Menu.Item>
              </SubMenu>
             
             
             </>
            ))
           }

           {
            (currentUser && currentUser.roles.includes('ROLE_ADMIN') && (
            <Menu.Item key="whatsAppChat"  icon={<WhatsAppOutlined />}>WhatsApp Chat <Link to="/whatsapp-chat" /> </Menu.Item>
                  
            ))
           }

          
          {/* {isGroupTabShown && 
           <Menu.Item key="16" icon={<UsergroupAddOutlined />}>
              Groups
            <Link to="/codersarts-group-chat" />
           </Menu.Item>
          } */}
          <Menu.Item key="notifications" icon={<NotificationOutlined />}>
              My Notifications
            <Link to="/profile/my-notifications"/>
            </Menu.Item>

           {/* {currentUser && !currentUser.roles.includes('ROLE_USER') && */}
           <Menu.Item key="17" style={{display:'flex',justifyContent:'space-between'}} icon={<WechatOutlined />}>
              
              <Link to="/chat" target={'_blank'}>Chat</Link>
              
             <sup>
             <Tooltip title={chatInfo} placement="right" color={'#006B82'}>
              <QuestionCircleOutlined style={{marginLeft:'5px',fontSize:'14px'}} />
              </Tooltip>
             </sup>
             
              
             
            
            </Menu.Item>
           {/* } */}

         


          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }} />
          <Content style={{ margin: '0 16px' }}>
         
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/profile/orders" component={Orders} />
          <Route exact path="/profile/employee" component={MyEmployee} />
          <Route exact path="/profile/manage-users" component={ManageUsers} />
          <Route exact path="/profile/manage-users/:slug" component={EditSingleUser} />
          <Route exact path="/profile/create-users" component={CreateUsers} />
          <Route exact path="/profile/create-order" component={CreateOrderForm} />
          <Route exact path="/profile/user/create-order" component={CreateOrderForUser} />
          <Route exact path="/profile/view-orders" component={ViewOrders} />
          <Route exact path="/profile/user-orders" component={UserOrders} />
          <Route exact path="/profile/assignment" component={MyAssignment} />
          <Route exact path="/profile/assigned-orders" component={AssignedOrders} />
          <Route exact path="/profile/edit-order/:slug" component={EditOrder} />
          
          <Route exact path="/profile/assign-order/:slug" component={AssignOrderToEmployee} />
          <Route exact path="/profile/view-order-details/:slug" component={ViewOrderDetails} />
          <Route exact path="/profile/user-feedback/:id" component={FeedbackOrders}/>
          <Route exact path="/profile/payment/:id" component={CheckoutForm}/>
          <Route exact path="/profile/all-feedback" component={AllFeedback}/>
          <Route exact path="/profile/file-share" component={FileShare}/>
          <Route exact path="/profile/developer-request" component={DevRequest}/>
          <Route exact path="/profile/single-developer-request-details/:id" component={SingleDevRequest}/>
          {/* <Route exact path="/profile/user-files" component={UserFileShare}/> */}
          <Route exact path="/profile/public-orders" component={PublicOrders}/>
          <Route exact path="/profile/manage-landing" component={ManageLanding}/>
          <Route exact path="/profile/public-orders/:slug" component={SinglePublicOrder} />
          <Route exact path="/profile/manage-users/view/:slug" component={ViewSingleUser} />
          <Route exact path="/profile/all-order-payments" component={AllPayments}/>
          <Route exact path="/profile/all-public-payments" component={AllPublicPayments}/>
          <Route exact path="/profile/single-payment-details/:id" component={SinglePaymentDetails} />
          <Route exact path="/profile/single-public-payment-details/:id" component={SinglePublicPaymentDetails} />
          <Route exact path="/profile/create-manual-payment/:id" component={CreateManualPayment} />
          <Route exact path="/profile/purchased-orders" component={PurchasedOrders} />
          <Route exact path="/profile/drive-files" component={DriveFiles} />
          <Route exact path="/profile/admin-links" component={AdminLinks} />
          <Route exact path="/profile/SendMailToUser" component={SendMailToUser} />
          <Route exact path="/profile/recycleBin" component={RecycleBin} />
          <Route exact path="/profile/all-logs" component={AllLogs} />
          <Route exact path="/profile/all-exported-files" component={ExportedDataList} />
          <Route exact path="/profile/create-invoice" component={InvoiceMaker} />
          <Route exact path="/profile/users-for-sales-role" component={UsersTableForSales} />
          <Route exact path="/profile/orders-for-sales-role" component={ManageOrdersForSales} />
          <Route exact path="/profile/paymentHistory" component={PurchasedHistory} />
          {/* <Route exact path="/profile/mylinks" component={PublicLinks} /> */}
          <Route exact path="/profile/mailRecords" component={MailRecords} />
          <Route exact path="/profile/codersarts-dashboard-home" component={DashboardHome} />
          <Route exact path="/profile/public-messages" component={ContactUsData} />
          <Route exact path="/profile/create-new-session" component={SessionsForBooking} />
          <Route exact path="/profile/create-new-service" component={CreateNewService} />
          <Route exact path="/profile/create-new-booking" component={CreateManualBooking} />
          <Route exact path="/profile/all-bookings" component={AllBookings} />
          <Route exact path="/profile/all-sessions" component={AllSessions} />
          <Route exact path="/profile/all-services" component={AllServicesForm} />
          <Route exact path="/profile/my-bookings" component={MyBookings} />
          <Route exact path="/profile/attendance-record" component={AllAttendance} />
          <Route exact path="/profile/attendance-record-chart" component={AttendanceChart} />
          <Route exact path="/profile/user-subscription">
          <Elements  stripe={stripePromise}>
            <UserSubscription /> 
          </Elements>
          </Route>
          <Route exact path="/profile/booking-details/:slug" component={SingleBookingDetails} />
          <Route exact path="/profile/service-details/:slug" component={SingleServiceDetails} />
          <Route exact path="/profile/session-details/:slug" component={SessionDetails} />
          <Route exact path="/profile/edit-session/:slug" component={EditSessions} />
          <Route exact path="/profile/edit-service/:slug" component={EditServices} />
          <Route exact path="/profile/admin-subscription-panel" component={AdminSubscription} />
          <Route exact path="/profile/subscription-records" component={SubscriptionRecords} />
          <Route exact path="/profile/view-subscription-details/:slug" component={SingleSubscriptionDetails} />
          <Route exact path="/profile/all-invoices" component={Invoices} />
          <Route exact path="/profile/my-notifications" component={MyNotifications} />
          <Route exact path="/profile/book-a-session" component={BookASession} />
          <Route exact path="/profile/skills-category" component={SkillsCategoryList} />
          <Route exact path="/profile/to-do-list" component={ToDoList} />
          <Route exact path="/profile/user-leave" component={UserLeave}/>
          <Route exact path="/profile/manage-youtube" component={YoutubeInfoPanel}/>
          <Route exact path="/profile/manage-youtube-playlist" component={YoutubePlaylistComponent}/>
          <Route exact path="/profile/manage-mobile-banner" component={MobileBanner}/>
          <Route exact path="/profile/employee-resume" component={EmployeeResume}/>
          <Route exact path="/profile/all-events" component={AdminEvents} />
          <Route exact path="/profile/create-new-event" component={CreateEvent} />
          <Route exact path="/profile/view-event-details/:id" component={ViewEvent} />
          <Route exact path="/profile/update-event/:id" component={UpdateEvent} />
           <Route exact path="/profile/my-events" component={MyEvents} />
          {/* <Route exact path="/profile/outsource-orders" component={OutsourceOrders}/> */}
         
          {/* <Route exact path="/profile/book-assignment-help" component={NewAssignmentHelp} /> */}
          {/* <Route exact path="/profile/all-assignment-request" component={AllAssignmentRequest} />
          <Route exact path="/profile/assignment-request-details/:id" component={AssignmentRequestDetails} /> */}

         
          </Content>
          <Footer style={{ textAlign: 'center' }}>Codersarts &copy; {new Date().getFullYear()}</Footer>
        </Layout>
      </Layout>
    );
  
}

export default SiderDemo;