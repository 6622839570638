import React,{useEffect,useState} from 'react'
import userService from '../../../../services/user.service';
import { Breadcrumb,message } from 'antd';
import { Link } from 'react-router-dom';
import {history} from '../../../../helpers/history';
import {useSelector} from 'react-redux';

function ViewSingleUser({match}) {
    const [userDetails,setUserDetails] = useState(null);
    const [createdAt,setCreatedAt] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            if(currentUser.roles.includes('ROLE_MANAGER')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getUserDetails = async ()=>{
        try{
            const result = await userService.getUserById(match.params.slug);
            // console.log(result.data);
            setUserDetails(result.data);
            let finalDate="N/A"
          if(result.data.createdAt){
              const date = new Date(result.data.createdAt);
              let dd = date.getDate();
              let mm = date.getMonth() + 1;
              let yyyy = date.getFullYear();
              let hh = date.getHours();
              let minutes = date.getMinutes();
              let ss = date.getSeconds();
             finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
          }

          setCreatedAt(finalDate);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : err);
        }
    }
    useEffect(()=>{
        getUserDetails();
    },[])

    return (
        <div>
            <Breadcrumb>
            <Breadcrumb.Item>{currentUser.roles.includes('ROLE_SALES') ? 
            <Link to="/profile/users-for-sales-role">All Users</Link>
            :
            currentUser.roles.includes('ROLE_MANAGER') ? 
            <Link to="/profile/employee">My Employees</Link>
            :
            <Link to="/profile/manage-users">Manage Users</Link>
            }</Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/manage-users/view/${match.params.slug}`}>{userDetails ? userDetails.name : match.params.slug}</Link></Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <h2>{userDetails && userDetails.name} Details</h2>
                <hr />
            </div>
            <table className="table table-borderless table-striped">
        <thead>

        <tr>
        <th scope="col">Created At</th>
        <th scope="col">{createdAt }</th>
        </tr>
        
        <tr>
        <th scope="row">Username</th>
        <td>{userDetails && userDetails.username}</td>
        </tr>
       
        </thead>
        <tbody>
        
        <tr >
        <th scope="col">Name</th>
        <th scope="col">{userDetails && (userDetails.name ? userDetails.name : userDetails.username)}</th>
        </tr>
    
        <tr>
        <th scope="row">Id</th>
        <td>{userDetails && userDetails.id}</td>
        </tr>
        <tr>
        <th scope="row">DOB</th>
        <td>{userDetails && userDetails.dob ? userDetails.dob : "N/A"}</td>
        </tr>
        <tr >
        <th scope="col">Contact No.</th>
        <td scope="col">{userDetails && userDetails.contact ? userDetails.contact : "N/A"}</td>
        </tr>
        <tr>
        <th scope="row">Address</th>
        <td>{userDetails && userDetails.address ? userDetails.address : "N/A"}</td>
        </tr>
        <tr >
        <th scope="col">Gender</th>
        <td scope="col">{userDetails && userDetails.gender ? userDetails.gender : "N/A"}</td>
        </tr>

        <tr>
        <th scope="row">Email</th>
        <td>{userDetails && userDetails.email}</td>
        </tr>

        <tr>
        <th scope="row">Roles</th>
        <td> <span className="badge">{userDetails && userDetails.roles[0]}</span></td>
        </tr>

        
        {userDetails && userDetails.roles.includes('ROLE_USER') ?
        <tr>
        <th scope="row">Interests </th>
        <td> 
        {userDetails && 
        userDetails.interestedIn && userDetails.interestedIn.map((item)=>{
            return(<span className="badge mx-2">
                    {item}
                </span>)
        })} 
       </td>
        </tr>
        :
        <tr>
        <th scope="row">Skills </th>
        <td> 
        {userDetails && 
        userDetails.skills && userDetails.skills.map((item)=>{
            return(<span className="badge mx-2">
                    {item}
                </span>)
        })} 
       </td>
        </tr>
        }

        {userDetails && userDetails.roles.includes('ROLE_EMPLOYEE') ?
        <tr>
        <th scope="row">Manager</th>
        <td> <span className="badge">{userDetails && userDetails.manager && userDetails.manager[0] && userDetails.manager[0].username}</span></td>
        </tr>
        :null
        }

        </tbody>
        </table>
            
        </div>
    )
}

export default ViewSingleUser
