import React, {useState, useEffect} from 'react';
import {message, Card, Modal} from 'antd';
import orderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import {useDispatch, useSelector} from 'react-redux';
import AdminDashboard from './AdminDashboard.jsx';
import SalesDashboard from './SalesDashboard.jsx';
// import { history} from '../../../helpers/history';
import UsersDashboard from './UsersDashboard';
import EmployeeDashboard from './EmployeeDashboard';
import ManagerDashboard from './ManagerDashboard';
import {ClockCircleOutlined, DollarCircleOutlined} from '@ant-design/icons';

function DashboardHome () {
  const [documentCount, setDocumentCount] = useState ({});
  const [userData, setUserData] = useState ([]);
  const [employeeData, setEmployeeData] = useState ([]);
  const [managerData, setManagerData] = useState ([]);
  const [services, setServices] = useState ([]);
  const [loading, setLoading] = useState (false);
  const [sessions, setSessions] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [bookingModal, setBookingModal] = useState (false);
  const [currentBookingSessionId, setCurrentBookingSessionId] = useState ('');
  const [bookingDate, setBookingDate] = useState ('');
  const [bookingTimezone, setBookingTimezone] = useState ('');
  const [bookingDetails, setBookingDetails] = useState ('');

  // useEffect(()=>{
  //     if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
  //         if(currentUser.roles.includes('ROLE_SALES')){
  //             return;
  //         }
  //         history.push('/profile');
  //     }
  // },[currentUser]);
  // console.log("User :",currentUser);

  const getDocumentEstimatedCount = async () => {
    try {
      const response = await orderService.getEstimatedCounts ();
      // console.log("Counts : ",response.data);
      setDocumentCount (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to Load Document Counts'
      );
    }
  };

  const getUsersDocumentCount = async () => {
    try {
      const response = await orderService.getCustomerOrderCount (
        currentUser.id
      );
      // console.log("Users Document Count : ",response.data)

      setUserData ({
        orderCount: response.data.order.length,
        userOrders: response.data.order,
      });
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to Load Document Counts'
      );
    }
  };

  const getEmployeeDocumentCount = async () => {
    try {
      const response = await orderService.getEmployeeOrders (currentUser.id);
      const response2 = await orderService.getSpecificOrder (currentUser.id);
      // console.log("Employee Document Data : ",response.data)
      // console.log("Employee Porjects Data : ",response2.data)
      setEmployeeData ({
        orderCount: response.data.length,
        userOrders: response.data,
        projects: response2.data.length,
      });
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to Load Document Counts'
      );
    }
  };

  const getManagerDocumentCount = async () => {
    try {
      const response = await UserService.getAllEmployees (currentUser.id);
      const response2 = await orderService.getSpecificOrder (currentUser.id);
      // console.log("Manager Document Data : ",response.data)
      // console.log("Manager Projects Data : ",response2.data)
      setManagerData ({
        EmployeeCount: response.data.length,
        projects: response2.data.length,
      });
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to Load Document Counts'
      );
    }
  };

  const getRecommendedSessionsAndServices = async interest => {
    try {
      const response = await orderService.getRecommendedSessionsAndServices (
        interest
      );
      // console.log("data : ",response.data);
      if (response.data) {
        let foundSessions = [];
        let foundServices = [];
        response.data.sessions.forEach (item => {
          item.forEach (obj => {
            foundSessions.push (obj);
          });
        });

        response.data.services.forEach (item => {
          item.forEach (obj => {
            foundServices.push (obj);
          });
        });

        foundSessions = foundSessions.filter (
          (value, index, self) =>
            index ===
            self.findIndex (t => t._id === value._id && t.name === value.name)
        );

        foundServices = foundServices.filter (
          (value, index, self) =>
            index ===
            self.findIndex (t => t._id === value._id && t.name === value.name)
        );

        // console.log("Filtered Services : ",foundServices);
        // console.log("Filtered Sessions : ",foundSessions);

        setServices (foundServices);
        setSessions (foundSessions);
      }
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch user skills!'
      );
    }
  };

  const getInterestById = async () => {
    try {
      const response = await UserService.getUserInterestById (currentUser.id);
      //   console.log("Skills : ",response2.data);
      getRecommendedSessionsAndServices (response.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch user skills!'
      );
    }
  };
  useEffect (() => {
    if (
      (currentUser && currentUser.roles.includes ('ROLE_ADMIN')) ||
      (currentUser && currentUser.roles.includes ('ROLE_SALES'))
    ) {
      getDocumentEstimatedCount ();
    } else if (currentUser && currentUser.roles.includes ('ROLE_USER')) {
      getUsersDocumentCount ();
      getInterestById ();
    } else if (
      currentUser &&
      (currentUser.roles.includes ('ROLE_EMPLOYEE') ||
        currentUser.roles.includes ('ROLE_REMOTEDEVELOPER'))
    ) {
      getEmployeeDocumentCount ();
    } else if (currentUser && currentUser.roles.includes ('ROLE_MANAGER')) {
      getManagerDocumentCount ();
    }
  }, []);

  useEffect (
    () => {
      const getAllToDoSectionAndTask = async () => {
        try {
          const response = await orderService.getAllToDoSectionAndTask (
            currentUser.id
          );
          //   console.log (response);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      if (currentUser && !currentUser.roles.includes ('ROLE_USER')) {
        getAllToDoSectionAndTask ();
      }
    },
    [currentUser]
  );

  const handleBooking = id => {
    setCurrentBookingSessionId (id);
    setBookingModal (true);
  };

  const bookNow = async () => {
    setLoading (true);
    try {
      const response = await orderService.createNewBooking ({
        booking: currentBookingSessionId,
        bookingBy: currentUser.id,
        date: bookingDate,
        details: bookingDetails,
        timezone: bookingTimezone,
      });
      // console.log(response.data);
      if (response) {
        setLoading (false);
        setBookingModal (false);
        message.success ('Booking Confirmed!');
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to book session!');
      setLoading (false);
    }
  };
  return (
    <div className="dashboard__main">
      {currentUser &&
        currentUser.roles.includes ('ROLE_ADMIN') &&
        <AdminDashboard documentCount={documentCount} />}
      {currentUser &&
        currentUser.roles.includes ('ROLE_SALES') &&
        <SalesDashboard documentCount={documentCount} />}
      {currentUser &&
        currentUser.roles.includes ('ROLE_USER') &&
        <UsersDashboard userData={userData} />}
      {currentUser &&
        currentUser.roles.includes ('ROLE_EMPLOYEE') &&
        <EmployeeDashboard userData={employeeData} />}
      {currentUser &&
        currentUser.roles.includes ('ROLE_MANAGER') &&
        <ManagerDashboard userData={managerData} />}

      {/* currentUser && currentUser.roles.includes('ROLE_USER')  */}
      <div className="ourServices" style={{backgroundColor: 'transparent'}}>
        {services.length > 0 &&
          <h2 className="recommendText">Recommended Services For You</h2>}
        <div
          className="service-card-wrapper"
          style={{display: 'flex', flexWrap: 'wrap'}}
        >
          {services.length > 0 &&
            services.map (service => {
              return (
                <Card className="service-card" key={service._id}>
                  <div>
                    <img
                      src={service.image}
                      alt={service.name}
                      className="service-icon"
                    />
                  </div>
                  <div className="service-desc">
                    <h5>{service.name}</h5>
                    {service.details.slice (0, 200)}...
                  </div>
                  <div className="mt-3">
                    {service.readMoreLink &&
                      <a
                        href={service.readMoreLink}
                        rel="noreferrer"
                        target={'_blank'}
                        className="read-more-btn"
                      >
                        Read More
                      </a>}

                  </div>

                </Card>
              );
            })}

        </div>
      </div>

      <div className="ourSessions" style={{backgroundColor: 'transparent'}}>
        {sessions.length > 0 &&
          <h2 className="recommendText">Recommneded Sessions for You</h2>}
        <div style={{display: 'flex', flexWrap: 'wrap'}}>

          {sessions.length > 0 &&
            sessions.map (s => {
              return (
                <Card className="session-card" key={s._id}>
                  <a
                    rel="noreferrer"
                    href={s.readMoreLink ? s.readMoreLink : '/'}
                    target={s.readMoreLink ? '_blank' : '_self'}
                  >
                    <div>
                      <img
                        src={s.image}
                        alt={s.name}
                        className="session-card-icon"
                      />
                    </div>
                  </a>
                  <div className="session-desc">
                    <h5>{s.name}</h5>
                    <div className="sessionPara">
                      {s.details.slice (0, 75) + '...'}
                    </div>
                    {s.readMoreLink
                      ? <a
                          href={s.readMoreLink}
                          rel="noreferrer"
                          target={'_blank'}
                        >
                          <b>Read More</b>
                        </a>
                      : null}
                  </div>
                  <hr />
                  <div className="session-card-bottom">
                    <div className="session-bottom-item">
                      <ClockCircleOutlined />
                      <span>{s.duration}</span>
                    </div>

                    <div className="session-bottom-item">
                      <DollarCircleOutlined />
                      <span>{s.price}</span>
                    </div>

                    <div>
                      <button
                        disabled={loading ? true : false}
                        className="bookBtn"
                        onClick={() => {
                          handleBooking (s._id);
                        }}
                      >
                        Book Now
                      </button>
                    </div>
                  </div>
                </Card>
              );
            })}

        </div>
      </div>

      <Modal
        title={'Book a Session'}
        visible={bookingModal}
        onOk={bookNow}
        onCancel={() => {
          setBookingModal (false);
        }}
        confirmLoading={loading}
      >
        <form>
          <div className="form-group mb-4">
            <label><h6><b>Enter a Date :</b></h6></label>
            <input
              type="Date"
              className="form-control"
              value={bookingDate}
              onChange={e => {
                setBookingDate (e.target.value);
              }}
            />
          </div>

          <div className="form-group mb-4">
            <label><h6><b>Enter a Time with Timezone :</b></h6></label>
            <input
              type="text"
              value={bookingTimezone}
              placeholder="e.g 10 AM GMT+5"
              className="form-control"
              onChange={e => {
                setBookingTimezone (e.target.value);
              }}
            />
          </div>

          <div className="form-group mb-4">
            <label><h6><b>Enter a Description :</b></h6></label>
            <textarea
              className="form-control"
              value={bookingDetails}
              onChange={e => {
                setBookingDetails (e.target.value);
              }}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default DashboardHome;
