import "./messenger.css";
import Conversation from "../../components/Chat/conversations/Conversation";
import Message from "../../components/Chat/message/Message";
import {  useEffect, useState,useRef } from "react";
import { useSelector } from "react-redux";
import chatService from '../../services/chat.service';
import userService from "../../services/user.service";
import orderService from "../../services/order.service";
import { io } from "socket.io-client";
import ChatDetails from "../../components/Chat/ChatDetails/ChatDetails";
import {SearchOutlined,LoadingOutlined,PaperClipOutlined} from '@ant-design/icons';
import {message, Spin, Tooltip,Modal} from 'antd';
import chat from '../../assets/chat.png';
import S3 from 'react-aws-s3';
import {history} from '../../helpers/history';

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

// import { history } from "../../helpers/history";



export default function Messenger() {
  const [conversations, setConversations] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [searchInput,setSearchInput] = useState(null);
  const socket = useRef();
  const { user } = useSelector((state) => state.auth);
  const [currentChatUser,setCurrentChatUser] = useState(null);
  const [allEmployees,setAllEmployees] = useState(null);
  const [allUserConv,setAllUsersConv] = useState(null);
  const [seen,setSeen] = useState(false);
  const [notification,setNotification] = useState(null);
  const [currentChatUserPic,setCurrentChatUserPic] = useState(null);
  const [file,setFile] = useState(null);
  const [loading,setLoading] = useState(false);
  const [awsData,setAwsData] = useState(null);
  const inputFileRef = useRef(null);
  // const { isLoggedIn } = useSelector((state) => state.auth);
  const { user: currentUser } = useSelector((state) => state.auth);
  

//   useEffect(()=>{
//     if(!currentUser || currentUser.roles.includes('ROLE_USER')){
//         history.push('/profile');
//     }
// },[currentUser])
  // const prevNotification = usePrevious(notification);
  const scrollRef = useRef();

  // useEffect(()=>{
  //   if(isLoggedIn==false){
  //    history.push('/auth/signin')
  //   }
  // },[])
  // let socketConnection = "http://3.7.156.63:3000";
  let socketConnection = "http://localhost:3000";
  // let socketConnection = "https://dashboard.codersarts.com"


  // function usePrevious(value) {
  //   const ref = useRef();
  //   useEffect(() => {
  //     ref.current = value;
  //   });
  //   return ref.current;
  // }

  // function showErrorModal() {
  //   let secondsToGo = 5;
  //   const modal = Modal.error({
  //     title: 'Either you are not Logged In or Token Expired !',
  //     content: `You will be redirected to login page in  ${secondsToGo} second.`,
  //   });
  //   const timer = setInterval(() => {
  //     secondsToGo -= 1;
  //     modal.update({
  //       content: `You will be redirected to login page in  ${secondsToGo} second.`,
  //     });
  //   }, 1000);
  //   setTimeout(() => {
  //     clearInterval(timer);
  //     modal.destroy();
  //     history.push('/auth/signin');
  //   }, secondsToGo * 1000);
  // }


  const getAwsInfo = async ()=>{
    try{
     const res = await orderService.getAwsInfo();
    
     setAwsData(res.data);
    }catch(err){
        console.log(err);
    }

 }

  useEffect(()=>{
    getAwsInfo();
  },[]);

  useEffect(async ()=>{
    if(user.roles[0].toLowerCase()==='role_admin' || user.roles[0].toLowerCase()==='role_sales'){
      try{
        const res =  await userService.getAllUsers()
        // console.log("ALL USERS",res.data);
        res.data.map((d)=>{
          if(d._id!==user.id){
            chatService.getConversationOnline(user.id,d._id).then((response)=>{
              // console.log("GOT DATA FOR ADMIN :",response.data);
              if(response.data===null){
                chatService.createConversation(user.id,d._id).then((res=>{
                  // console.log(res.data);
                })).catch(err=>{
                  console.log(err);
                })
              }
            }).catch(err=>{
              console.log(err);
            })
           
          }
            
        })
      }catch(err){
        console.log(err);
      }
      
    }else if(user.roles[0].toLowerCase()==='role_employee'){
      let manager = []
      userService.getUserById(user.id).then(response=>{
        manager = response.data.manager;
        
        manager.map((m)=>{
          chatService.getConversationOnline(user.id,m._id).then((response)=>{
              // console.log(response.data);
              if(response.data===null){
                 chatService.createConversation(user.id,m._id).then((res=>{
                    // console.log("CHAT B/W EMPLOYEE AND MANAGER",res.data);
                  })).catch(err=>{
                    console.log(err);
                  })
              }
            }).catch(err=>{
              console.log(err);
            })
        })
      }).catch(err=>{
        console.log(err);
      })
    }else if(user.roles[0].toLowerCase()==='role_manager'){
      let employees = []
      userService.getAllEmployees(user.id).then(response=>{
        employees = response.data;
        // console.log("EMPLOYEES : ",employees)
        setAllEmployees(employees);
        employees.map(e=>{
          chatService.getConversationOnline(user.id,e._id).then(response=>{
            if(!response.data){
              chatService.createConversation(user.id,e._id).then(response=>{
                // console.log("CREATED CONV : ",response);
              }).catch(err=>{
                console.log(err);
              })
            }
          }).catch(err=>{
            if(err.response.status==404){
              console.log("NOT FOUND");
            }
          })
        })

        }).catch(err=>{
          console.log(err);
        })
    }
    else{
      try{
        const res =  await userService.getAdminAndSalesUsers()
        // console.log("ALL USERS",res.data);
       if(res.data.length>0){
        res.data.map((d)=>{
          // console.log("Mapping : ",d);
          if(d._id!==user.id){
            chatService.getConversationOnline(user.id,d).then((response)=>{
              // console.log("GOT DATA FOR ADMIN :",response.data);
              if(response.data===null){
                chatService.createConversation(user.id,d).then((res=>{
                  // console.log(res.data);
                })).catch(err=>{
                  console.log(err);
                })
              }
            }).catch(err=>{
              console.log(err);
            })
           
          }
            
        })
       }
      }catch(err){
        console.log(err);
      }
    }
    // else{
    //   let developers = [];

    // orderService.getCreatedOrder(user.id).then(res=>{
      
    //   res.data.map((d)=>{
    //     if(d.assignedEmployee.length > 0){
    //       developers.push(d.assignedEmployee[0]);
    //     }
    //   })

    // //  console.log("MSG DEVELOPERS : ",developers);

    // developers.forEach(d=>{

      
    //   chatService.getConversationOnline(d._id,user.id).then(response=>{
    //     if(response.data===null){
    //       chatService.createConversation(user.id,d._id).then((res=>{
    //         // console.log("CHAT B/W USER AND DEV",res.data);
    //       })).catch(err=>{
    //         console.log(err);
    //       })
    //     }
    //   }).catch(err=>{
    //     console.log(err);
    //   })
     
    // })


    // }).catch(err=>{
    //   console.log(err);
    // })
    
    
      
    // }
  },[user])

  

  



  // if(process.env.NODE_ENV==="production"){
  //   socketConnection = "https://codersarts-employee-dashboard.herokuapp.com"
  // }
  useEffect(() => {
    socket.current = io(socketConnection);
    socket.current.on("getMessage", (data) => {
      // console.log("GOT MESSAGE HERE : ",data);
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        msgType:data.msgType,
        fileUrl:data.fileUrl,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    socket.current.emit("addUser", user.id);
    // console.log(user.id);
    
  }, [user]);

 useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);


  

  


  const getAllConv = async ()=>{
   try{
      const response = await chatService.getConversation(user.id)
      setConversations(response.data);
      setAllUsersConv(response.data);
   }catch(err){
     console.log(err);
   }
  }

  useEffect(() => {
    if(user){
      getAllConv()
    }
  }, [user]);

  useEffect(() => {
    
    chatService.getMessages(currentChat?._id).then((response)=>{
      
      setMessages(response.data);
    }).catch(err=>{
      console.log(err);
    });
    
   
  }, [currentChat]);



  const handleSubmit = async (e)=>{
    e.preventDefault();
    var message = {
      sender: user.id,
      text: newMessage,
      msgType:"text",
      fileUrl:null,
      conversationId: currentChat._id,
    };

    console.log("AWS DATA : ",awsData);
    if(file && awsData){
      // console.log("FINAL FILE TO UPLOAD : ",file);
      message = {
        sender: user.id,
        text: newMessage,
        msgType:file && file.type,
        fileUrl:null,
        conversationId: currentChat._id,
      };

      //uploading file to aws

      setLoading(true);
            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
            const ReactS3Client = new S3(config);
            try{
              const data = await ReactS3Client.uploadFile(file,file.name);
              message.fileUrl = data.location
              console.log("UPLOADED  : ",data.location);
              setFile(null);
              setLoading(false);
             }catch(err){
                 console.log(err);
                 setLoading(false);
             }

    }
   


    const receiverId = currentChat.members.find(
      (member) => member !== user.id
    );

    

    socket.current.emit("sendMessage", {
      senderId: user.id,
      receiverId,
      text: newMessage,
      fileUrl:message.fileUrl,
      msgType:message.msgType
    });

    setFile(null);
    try{
      const res = await chatService.sendMessage(message);
      setMessages([...messages,res.data]);
      setNewMessage("");
    }catch(err){
      console.log(err);
    }
  }

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(()=>{
    if(currentChat){
      setCurrentChatUser(currentChat.members.find(
        (member) => member !== user.id
      ));
    }
    
  },[currentChat])

   const searchResult = async ()=>{
    let updatedConversation = [];
    try{
      if(searchInput){
        const res = await userService.getSingleUser(searchInput.toLowerCase());
        // console.log("SEARCH RESULT : ",res.data);
        updatedConversation = conversations.filter(c=>{
          // console.log("DOING FILTER..",res.data.id);
          return c.members[1] === res.data.id;
        })
        
      }
    }catch(err){
      console.log(err);
    }
    setConversations(updatedConversation);
   }

   useEffect(()=>{
     if(!searchInput){
       setConversations(allUserConv);
     }
   },[searchInput])


  useEffect(()=>{
    // console.log("MESSENGER CURRENT CHAT :",currentChat);
    if(currentChat && arrivalMessage){
      if(currentChat.members.includes(arrivalMessage.sender)){
        setSeen(true);
      }
    }
   
  },[currentChat]);

  const setSeenFalse = async ()=>{
    try{
      if(currentChat && arrivalMessage){
        if (!currentChat.members.includes(arrivalMessage.sender)){
          // console.log("CURRENT CHAT IN SET SEEN:",currentChat)
          // console.log("SETTING SEEN FALSE ");
          setSeen(false);
        }
      }
    }catch(err){
      console.log(err);
    }
  }



  useEffect(()=>{
    // console.log("I am running !", seen,currentChat,arrivalMessage);
    setSeenFalse()
    if(currentChat && arrivalMessage && !seen){
      if(!currentChat.members.includes(arrivalMessage.sender)){
        // console.log("NEW MESSAGE : ",arrivalMessage);
        setNotification(arrivalMessage);
        setSeen(false);
      }
      if(currentChat.members.includes(arrivalMessage.sender)){
        setSeen(true);
      }
    }
  },[currentChat,arrivalMessage]);

  const handleCurrentChat = (c)=>{
    setCurrentChat(c);
    setNotification(null);
  }

  const handleFileChange = (e)=>{
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setNewMessage(e.target.files[0].name)
  }

  const handleFileClick = ()=>{
    inputFileRef.current.click();
  }

  return (
    <>
      <div className="messenger">
        <div className="chatMenu">
          <div className="chatMenuWrapper">
          <div className="input-group mb-3">
              <input type="text" className="form-control chatMenuInput"
               placeholder={'Search People '} 
               onChange={(e)=>{setSearchInput(e.target.value)}}
               aria-label="Search People" aria-describedby="basic-addon2" />
              <div className="input-group-append">
              <button onClick={searchResult} style={{borderRadius:0}} className="btn btn-secondary" type="button"><SearchOutlined /></button>
              </div>
            </div>
            <span> </span>
            {conversations && conversations.map((c) => (
              <div onClick={() => handleCurrentChat(c)} key={c._id} >
                <Conversation conversation={c}
                 currentChat={currentChat} 
                 notification={notification && 
                 (c.members.includes(notification.sender) ? notification:null)} 
                 currentUser={user} />
              </div>
            ))}
          </div>
        </div>
        <div className="chatBox">
          <div className="chatBoxWrapper">
            {currentChat ? (
              <>
              
                <div className="chatBoxTop">
                  {messages.map((m,index) => (
                    <div ref={scrollRef} key={index}>
                     <Message message={m} own={m.sender === user.id} 
                     currentChatUserPic={currentChatUserPic} 
                     profile={user.profilePicture} />
                    </div>
                  ))}
                </div>
                <div className="chatBoxBottom">
                  <textarea
                    className="chatMessageInput"
                    placeholder="Type a message"
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                  ></textarea>
                  <Tooltip title="Share Files ? " color={'#f50'}>
                  <span onClick={handleFileClick}>
                  <PaperClipOutlined className="file-upload-icon" />
                  </span>
                  </Tooltip>
                  <input type="file" 
                  style={{display:'none'}} 
                  onChange={handleFileChange}
                  ref={inputFileRef} />
                  {/* <InputEmoji
                    value={newMessage}
                    onChange={setNewMessage}
                    placeholder="Type a message"
                    onEnter={makeHeightBigger}
                    height={80}
                  /> */}
                  <button className="chatSubmitButton" onClick={handleSubmit}>
                   {loading ? <Spin indicator={spinIcon} />:'Send'}
                  </button>
                </div>
              </>
            ) : (
              <div className="noConversationText">
               <img src={chat} />
               <div>
                 <h3>Select a Conversation</h3>
                 <div id="noText">Try Selecting a Conversation or Searching a Specific Person</div>
               </div>  
              </div>
            )}
          </div>
        </div>
        <div className="chatOnline">
          <div className="chatOnlineWrapper">
         <ChatDetails
          currentUser={user}
          currentChatUser={currentChatUser}
          setCurrentChatUserPic={setCurrentChatUserPic}
          />
          </div>
        </div>
      </div>
    </>
  );
}
