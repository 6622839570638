import React,{useState,useEffect} from 'react';
import { Table,Space,Modal,Dropdown,Menu,Tag,Button,message} from 'antd';
import { Link } from 'react-router-dom';
import orderService from '../../../services/order.service';
import { PlusOutlined, MoreOutlined,EditOutlined,EyeOutlined, DeleteOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';






const AllServicesForm = ()=>{
    const [allServices,setAllServices] = useState([]);
    const [filteredArr,setFilteredArr] = useState([]);
    const [servicesFilterArr,setServicesFilterArr] = useState([]);

    
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])



    const getAllServices= async ()=>{
        try{
           const response = await orderService.getAllServicesForHome()
            // console.log("All Services : ",response.data);
            let data = [];
            response.data.map((d)=>{
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
               
                let modifiedData = {
                    id:d._id,
                    date:finalDate,
                    sortDate:d.createdAt,
                    name:d.name && d.name,
                    details:d.details && d.details,
                    fileUrl:d.image
                }

                data.push(modifiedData);
            })
            
            setAllServices(data);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(()=>{
        getAllServices();
    },[]);

    useEffect(()=>{
        let servicesNameFilterArr = [];
        allServices && allServices.map((b)=>{
           if(b.name){
            servicesNameFilterArr.push({text:b.name ,value:b.name })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        servicesNameFilterArr = servicesNameFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setServicesFilterArr(servicesNameFilterArr);
    },[allServices])
    


    
    const deleteService = async (id)=>{
        try{
            const response = await orderService.deleteServiceById(id);
            // console.log(response.data);
            message.success("Deleted Successfully!");
            getAllServices();
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to delete!');
        }
    }

    const confirmDelete = (id)=>{
        Modal.confirm({
            title: 'Confirm Delete',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to Delete this Service?',
            okText: 'Yes',
            cancelText: 'No',
            onOk:()=>{deleteService(id)}
          });
    }



    const columns = [
        {
            title: 'Created At',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Name',
            key: 'name',
            filters:servicesFilterArr,
            onFilter:(value, record) => record.name.indexOf(value) === 0,
            render:(record)=>{
                return(<Link to={`/profile/service-details/${record.id}`}> <b>{record.name}</b></Link>)
            }
        },
        {
            title: 'Details',
            key: 'Details',
            render:(record)=>{
                return(<Tag color={'cyan'}>{record.details.slice(0,50)}...</Tag>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
             <Space size="middle">
                {/* <Link to={`/profile/service-details/${record.id}`}>
                 <Button type={'primary'}>View Details</Button>
                </Link> */}


                <Dropdown overlay={
                  <Menu style={{marginLeft:'-25%'}}>
                   <Menu.Item key="1">
                    <EyeOutlined /> <Link to={`/profile/service-details/${record.id}`} /> View Details
                   </Menu.Item>

                   <Menu.Item key="2">
                   <EditOutlined /> <Link to={`/profile/edit-service/${record.id}`} /> Edit Details
                   </Menu.Item>


                  <Menu.Item key="4" danger onClick={()=>{confirmDelete(record.id)}}>
                 <DeleteOutlined /> Delete
                  </Menu.Item>
                
                </Menu>
                }>
                <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
                </Dropdown>
              </Space>
              )
           
        },
    ]
   


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allServices.length >0){
                filteredData= allServices.filter((service)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return service.name && service.name.toLowerCase().match(item.toLowerCase())
                });
                // console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }


    return(
        <div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Services</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}}
                         /> 
                </div>
               <div>
               <Link to="/profile/create-new-service">
                <Button type={'primary'}>
                <PlusOutlined className='mr-3'/>
                <b>Create Service</b>
                </Button>
                </Link>
               </div>
            </div>

            <Table dataSource={filteredArr.length > 0 ?  filteredArr : allServices} columns={columns} />
        </div>
    )
}


export default AllServicesForm;