import React, {useEffect, useState} from 'react';
import {Tabs, Card, message, Button} from 'antd';
import orderService from '../../../../services/order.service';
import './CheckoutForm.css';
import {ShoppingCartOutlined, CreditCardOutlined} from '@ant-design/icons';
import Checkout from './Checkout/Checkout';
import axios from 'axios';
import {useLocation} from 'react-router';

const {TabPane} = Tabs;

function CheckoutForm({match}) {
  const [orderDetails, setOrderDetails] = useState (null);
  const [orderQuantity, setOrderQuantity] = useState (1);
  const [isReport, setIsReport] = useState (false);
  const [isCodeWalkThrough, setIsCodeWalkThrough] = useState (false);
  const [total, setTotal] = useState (null);
  const [activeKey, setActiveKey] = useState ('1');
  const [reportPages, setReportPages] = useState (0);
  const [reportFee, setReportFee] = useState (15);
  const [codeWalkThrough, setCodeWalkThrough] = useState (50);
  const [orderAmount, setOrderAmount] = useState (0);
  const [isPartial, setIsPartial] = useState (false);
  const [initialPaidAmount, setInitialPaidAmount] = useState (null);
  const [rate, setRate] = useState (null);
  const location = useLocation ();
  const coins = location.coins;
  //   console.log ('location : ', location);
  const getOrderDetails = async () => {
    try {
      const response = await orderService.getSingleOrder (match.params.id);
      //    console.log(response.data);
      setInitialPaidAmount (response.data.paidAmount);

      setOrderDetails (response.data);
      if (response.data.reportFee) {
        setReportFee (response.data.reportFee);
      }
      if (response.data.totalAmount) {
        setOrderAmount (response.data.totalAmount - response.data.paidAmount);
        setTotal (response.data.totalAmount - response.data.paidAmount);
        if (response.data.paidAmount == 'NaN') {
          // console.log("NAN");
          setOrderAmount (response.data.totalAmount);
          setTotal (response.data.totalAmount);
          setInitialPaidAmount (0);
        }
      }
      if (response.data.codeWalkThrough) {
        setCodeWalkThrough (response.data.codeWalkThrough);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message);
    }
  };
  useEffect (() => {
    getOrderDetails ();
    getInrRate ();
  }, []);
  useEffect (
    () => {
      // console.log("Codewalk through : ",isCodeWalkThrough);
      // console.log("Report : ",isReport);
      // console.log("orderQuantity : ",orderQuantity);
      // console.log("order amount",orderAmount);
      let codewalkthrough = isCodeWalkThrough ? Number (codeWalkThrough) : 0;
      let reportfee = isReport
        ? Number (Number (reportFee) * Number (reportPages))
        : 0;
      let finalAmount =
        Number (orderAmount) * orderQuantity + codewalkthrough + reportfee;
      console.log (finalAmount);

      //   console.log ('coins : ', coins);
      setTotal (finalAmount);
    },
    [
      isReport,
      isCodeWalkThrough,
      codeWalkThrough,
      orderAmount,
      reportFee,
      orderQuantity,
      reportPages,
      isPartial,
    ]
  );

  const handlePartial = isChecked => {
    setIsPartial (isChecked);

    if (isChecked) {
      setOrderAmount (orderDetails.partialAmount);
      setTotal (orderDetails.partialAmount);
    } else {
      setOrderAmount (orderDetails.totalAmount);
      setTotal (orderDetails.totalAmount);
    }
  };

  const getInrRate = async () => {
    try {
      const result = await axios.get (
        `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json`
      );
      let usdInfo = result.data['usd'];
      let rateOfInr = usdInfo['inr'];
      // console.log(rateOfInr);
      setRate (rateOfInr);
    } catch (err) {
      console.log (err);
      message.error (err);
    }
  };

  return (
    <div style={{marginTop: '-3%'}}>
      <Tabs
        activeKey={activeKey}
        onChange={Key => {
          setActiveKey (Key);
        }}
      >
        <TabPane
          tab={
            <span>
              <ShoppingCartOutlined style={{fontSize: '1.5rem'}} />
              Order Details
            </span>
          }
          key="1"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '30vw',
            }}
          >
            <div>
              <Card className="order-card">
                <div
                  style={{
                    width: '4vw',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <ShoppingCartOutlined
                    style={{fontSize: '4rem', marginRight: '4%'}}
                  />
                  <div>
                    {orderDetails &&
                      <div style={{width: '30vw', paddingLeft: '8%'}}>
                        <h4 style={{fontWeight: 'bold'}}>
                          {orderDetails.name}
                        </h4>
                        <div style={{marginTop: '-4%'}}>
                          <div>
                            <small>
                              <b>Category:</b> {orderDetails.category}{' '}
                            </small>
                          </div>
                          <div>
                            <small>
                              <b>Due Date: </b>{orderDetails.deadline}{' '}
                            </small>
                          </div>
                          <div>
                            <small>
                              <b>Price: </b>
                              {orderDetails.totalAmount &&
                                orderDetails.totalAmount}
                              {' '}
                            </small>
                          </div>
                          <div>
                            <small>
                              <b>Amount To Pay: </b>
                              {isPartial
                                ? orderDetails.partialAmount
                                : orderDetails.totalAmount &&
                                    Number (orderDetails.totalAmount) -
                                      Number (orderDetails.paidAmount)}
                              {' '}
                            </small>
                          </div>
                        </div>

                      </div>}
                  </div>
                  <div style={{width: '10vw'}} className="qty-input">
                    <b>Qty. </b><input
                      min={1}
                      type="number"
                      className="form-control"
                      style={{width: '80px', height: 'auto'}}
                      value={orderQuantity}
                      onChange={e => {
                        setOrderQuantity (e.target.value);
                      }}
                    />
                  </div>
                </div>
                {orderDetails &&
                  orderDetails.partialAmount &&
                  orderDetails.partialAmount > 0 &&
                  Number (orderDetails.totalAmount) -
                    Number (orderDetails.paidAmount) >
                    Number (orderDetails.partialAmount) &&
                  <div>
                    <div className="partialCheckbox">
                      <input
                        style={{marginRight: '5%', marginTop: '2%'}}
                        type="checkbox"
                        checked={isPartial}
                        onChange={e => {
                          handlePartial (e.target.checked);
                        }}
                      />
                      <label><b>Pay Partial </b></label>
                    </div>
                  </div>}
                <br />
                <br />
                <div>
                  <h5><b>Add Extras</b></h5>
                  <hr />
                  <br />
                  <div className="checkbox mb-3">
                    <label>
                      <input
                        type="checkbox"
                        onChange={e => {
                          setIsCodeWalkThrough (e.target.checked);
                        }}
                      /> <b>Code walkthrough</b>
                    </label>
                    <div className="ml-5">{codeWalkThrough} USD</div>
                  </div>
                  <div className="checkbox mb-3">
                    <label>
                      <input
                        type="checkbox"
                        onChange={e => {
                          setIsReport (e.target.checked);
                        }}
                      /> <b>Plagiarism free report</b>
                    </label>
                    <div className="ml-5">
                      &nbsp; &nbsp; {reportFee} USD / Page
                    </div>
                  </div>
                  {isReport &&
                    <div className="form-group checkbox ">
                      <label><b>Enter No. of Pages For Report:</b> </label>
                      <input
                        min={0}
                        style={{width: '80px', marginLeft: '5%'}}
                        type="number"
                        className="form-control ml-3"
                        value={reportPages}
                        onChange={e => {
                          setReportPages (e.target.value);
                        }}
                      />
                    </div>}
                </div>
              </Card>
            </div>
            <div>
              <Card className="summary-box">
                <h4><b>Summary</b></h4>
                <hr />
                <div className="line-summary">
                  <h6>Subtotal</h6>
                  <h6>{orderAmount * orderQuantity} USD </h6>
                </div>
                <div className="line-summary">
                  <h6>Codewalkthrough</h6>
                  <h6>
                    {isCodeWalkThrough ? orderDetails && codeWalkThrough : '0'}
                    {' '}
                    USD
                  </h6>
                </div>
                <div className="line-summary">
                  <h6>Report Fee </h6>
                  <h6>
                    {isReport
                      ? orderDetails &&
                          `${Number (reportFee) * reportPages} USD`
                      : '0'}
                  </h6>
                </div>
                <div className="line-summary">
                  <h6>Total Pages of Report </h6>
                  <h6>{isReport ? reportPages : '0'}</h6>
                </div>
                <div className="line-summary">
                  <h6>Order Quantity </h6>
                  <h6>{orderQuantity}</h6>
                </div>
                <br />
                <div className="line-summary">
                  <h6>Coins Discount: </h6>
                  <h6>- {coins / 100}</h6>
                </div>
                <br />
                <div className="line-summary">
                  <h5><b>Total</b> </h5>
                  <h5><b>{`${total - coins / 100} USD `}</b></h5>
                </div>
                <button
                  disabled={orderAmount <= 0 && total <= 0}
                  onClick={() => {
                    setActiveKey ('2');
                  }}
                  className="checkout-btn"
                >
                  Checkout
                </button>
              </Card>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={
            <span>
              <CreditCardOutlined style={{fontSize: '1.5rem'}} />
              Checkout
            </span>
          }
          key="2"
        >

          <Checkout
            amount={total}
            rate={rate}
            orderAmount={Number (orderAmount) + Number (initialPaidAmount)}
            order={orderDetails}
            isPartial={isPartial}
            coins={coins}
            qty={orderQuantity}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default CheckoutForm;
