import React,{useState,useEffect} from 'react'
import orderService from '../../../services/order.service';
import {message,Table,Space,Button,Tag} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';

function SubscriptionRecords() {
    const [allRecords,setAllRecords] = useState("");
    const [filteredBookingSubsArr,setFilteredSubsNameArr] = useState("");
    const [filteredBookingCountryArr,setFilteredSubsCountryArr] = useState("");
    const [filteredArr,setFilteredArr] = useState([]);

    const getAllRecords = async ()=>{
        let data = [];
        try{
            const response = await orderService.getAllSubscriptionRecord();
            // console.log(response.data);

            response.data.map((item)=>{
                let finalDate = "";
                if(item.createdAt){
                    const date = new Date(item.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
                let updatedField = {
                    id:item._id,
                    createdAt:finalDate,
                    sortDate:item.createdAt,
                    user:item.user && item.user[0].username ,
                    userId:item.user && item.user[0]._id ,
                    subscriptionType:item.subscription && item.subscription[0].name,
                    paymentId:item.paymentIntent.id,
                    amount:item.paymentIntent.amount/100,
                    city:item.city,
                    state:item.state,
                    currency:item.currency,
                    country:item.country
                }
                data.push(updatedField);
            })
            setAllRecords(data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch subscription records!');
        }
    };


    useEffect(()=>{
        let bookingSubsFilterArr = [];
         allRecords && allRecords.map((b)=>{
           if(b.subscriptionType){
            bookingSubsFilterArr.push({text:b.subscriptionType ,value:b.subscriptionType })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        bookingSubsFilterArr = bookingSubsFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setFilteredSubsNameArr(bookingSubsFilterArr);
    },[allRecords]);


    useEffect(()=>{
        let bookingSubsCountryFilterArr = [];
        allRecords && allRecords.map((b)=>{
           if(b.subscriptionType){
            bookingSubsCountryFilterArr.push({text:b.country ,value:b.country })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        bookingSubsCountryFilterArr = bookingSubsCountryFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setFilteredSubsCountryArr(bookingSubsCountryFilterArr);
    },[allRecords])


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allRecords.length >0){
                filteredData= allRecords.filter((record)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return record.user && record.user.toLowerCase().match(item.toLowerCase())
                });
                // console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }


    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'User',
            key: 'user',
            render:(record)=>{
                return (
                <Link to={`/profile/manage-users/view/${record.userId}`}>
                <b>{record.user}</b>
                </Link>)
            }
        },
        {
            title: 'Country',
            key: 'country',
            filters:filteredBookingCountryArr,
            onFilter:(value, record) => record.country.indexOf(value) === 0,
            render:(record)=>{
                return(<div>{record.country}</div>)
            }
        },

        {
            title: 'Amount',
            key: 'amount',
            render:(record)=>{
                return(<div>{record.amount} {record.currency}</div>)
            }
        },

      
        {
            title: 'Subscription',
            filters:filteredBookingSubsArr,
            onFilter:(value, record) => record.subscriptionType.indexOf(value) === 0,
            key: 'subscriptionType',
            render:(record)=>{
                return(<Tag color={'cyan'}>{record.subscriptionType}</Tag>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
                <Space size="middle">
                      <Link to={`/profile/view-subscription-details/${record.id}`}>
                        <Button type={'primary'}>
                         Details
                        </Button>
                      </Link>
                </Space>
              )
           
        }
       
    ]
    

    
    useEffect(()=>{
        getAllRecords();
    },[])
    return (
        <div>
        <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Subscription Record</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Username" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}}
                         /> 
                </div>
        </div>
        <hr />
            <Table dataSource={filteredArr.length > 0 ?  filteredArr : allRecords} columns={columns} />
        </div>
    )
}

export default SubscriptionRecords
