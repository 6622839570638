import React,{useState,useEffect} from 'react'
import './Invoices.css';
import orderService from '../../../services/order.service';
import {message,Modal,Table,Button,Space} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import S3 from 'react-aws-s3';
import {PlusOutlined,EditOutlined} from '@ant-design/icons';

function Invoices() {
    const [invoiceDetails,setInvoiceDetails] = useState("");
    const [filteredArr,setFilteredArr] = useState("");
    const [invoiceEditModal,setInvoiceEditModal] = useState(false);
    const [company,setCompany] = useState("");
    const [address,setAddress] = useState("");
    const [city,setCity] = useState("");
    const [country,setCountry] = useState("");
    const [zip,setZip] = useState("");
    const [state,setState] = useState("");
    const [loading,setLoading] = useState(false);
    const [invoiceFormatId,setInvoiceFormatId] = useState(null);
    const [fileUrl,setFileUrl] = useState("");
    const [awsData,setAwsData] = useState("");
    

    const getAllInvoices = async ()=>{
        let data = []
        try{
            const result = await orderService.getAllInvoiceDetails();
            // console.log("Invoice Details  :" ,result.data);
            result.data.map((item)=>{
                let finalDate="N/A"
                if(item.createdAt){
                    const date = new Date(item.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
                let modifiedData = {
                    createdAt:finalDate,
                    sortDate:item.createdAt && item.createdAt,
                    invoiceId:item._id,
                    invoiceTitle:item.invoiceTitle,
                    customer:item.customerName,
                    invoiceNumber:item.invoiceNumber,
                    state:item.state,
                    url:item.invoiceUrl && item.invoiceUrl
                }
                data.push(modifiedData);
            })
            setInvoiceDetails(data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch invoice details!');
        }
    }

    const getAwsInfo = async ()=>{
        try{
         const res = await orderService.getAwsInfo();
         
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
 
     }

    const getInvoiceFormatDetails = async ()=>{
        try{
            const response = await orderService.getInvoiceFormatDetails();
            // console.log(response.data);
            if(response.data && response.data.length > 0){
                const {company,city,state,country,_id,address,zip} = response.data[0];
                setInvoiceFormatId(_id)
                setCompany(company);
                setAddress(address);
                setCity(city);
                setState(state);
                setCountry(country);
                setZip(zip);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch invoice format details!');
        }
    }
    useEffect(()=>{
        getAwsInfo();
        getAllInvoices();
        getInvoiceFormatDetails();
    },[]);


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(invoiceDetails.length >0){
                filteredData= invoiceDetails.filter((invoice)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return invoice.invoiceTitle && invoice.invoiceTitle.toLowerCase().match(item.toLowerCase())
                });
                // console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Invoice No.',
            dataIndex:'invoiceNumber',
            key: 'invoiceNumber',
        },
        {
            title: 'Invoice Title',
            dataIndex:'invoiceTitle',
            key: 'invoiceTitle',
        },
    
        {
            title: 'Customer',
            dataIndex: 'customer',
            key: 'customer',
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
             <Space size="middle">
             {record.url ? 
                <a href={record.url} target={'_blank'}>
                    <Button type={'primary'}>View Invoice</Button>
                </a>
                :
                null 
            }
                
              </Space>
              )
           
        },
    ]

    const handleInvoiceFormatUpdate = async ()=>{
        setLoading(true);
       let data = {
           id:invoiceFormatId,
           company:company,
           address:address,
           city:city,
           state:state,
           country:country,
           zip:zip,
           logo:fileUrl
       }

    //    console.log("Data form invoice : ",data);
       try{
           const response = await orderService.addInvoiceFormatDetails(data);
        //    console.log(response.data);
           getInvoiceFormatDetails();
           message.success("Updated Invoice Details Successfully!");
           setLoading(false);
           setInvoiceEditModal(false);
       }catch(err){
           console.log(err);
           setLoading(false);
           message.error(err.message ? err.message : 'Unable to fetch invoice format details!');

       }
    }


    const handleFileChange = async (file)=>{
        if(awsData){
            setLoading(true);
         

            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
              
            let changedName = file.name.replace(/ /g,"_");
            // console.log("Changed Name : ",changedName);
            const ReactS3Client = new S3(config);
            ReactS3Client.uploadFile(file,changedName).then(data=>{
                // console.log(data);
                setFileUrl(data.location);
                setLoading(false);
            }).catch(err=>{
                console.log(err);
                setLoading(false);
            })
        } 
    }


    return (
        <div className='allInvoiceData'>
               <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Invoices</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}}
                         /> 
                </div>
               <div>
               <Link to="/profile/create-invoice">
                <Button type={'primary'}>
                <PlusOutlined className='mr-3'/>
                <b>Create Invoice</b>
                </Button>
                </Link>
               </div>


               <div>
                <Button onClick={()=>{setInvoiceEditModal(true)}} type={'primary'}>
                <EditOutlined className='mr-3'/>
                <b>Edit Invoice</b>
                </Button>
               </div>
            </div>


            <Modal 
            title={"Edit Invoice Details"}
            visible={invoiceEditModal} 
            okText={'Ok'}
            confirmLoading={loading}
            onCancel={()=>{setInvoiceEditModal(false)}}
            onOk={()=>{handleInvoiceFormatUpdate()}}
            >
            <form className='subscription-payment-form'>
             <div className="form-group">
               <input 
               type="text" 
               className="form-control"
               value={company} 
               placeholder='Enter Company Name'
               onChange={(e)=>{setCompany(e.target.value)}}/>
             </div>

             <div className="form-group">
               <textarea  
               className="form-control"
               value={address} 
               placeholder='Company Address...'
               onChange={(e)=>{setAddress(e.target.value)}}/>
             </div>

             <div className="subscription-input-group">
               <input
               type="text"  
               className="form-control"
               value={city} 
               placeholder='City'
               onChange={(e)=>{setCity(e.target.value)}}/>

              <input
               type="text"  
               className="form-control"
               value={state} 
               placeholder='State'
               onChange={(e)=>{setState(e.target.value)}}/>
             </div>

             <div className="subscription-input-group">
               <input
               type="text"  
               className="form-control"
               value={country} 
               placeholder='Country'
               onChange={(e)=>{setCountry(e.target.value)}}/>

              <input
               type="text"  
               className="form-control"
               value={zip} 
               placeholder='Zip/Pin code'
               onChange={(e)=>{setZip(e.target.value)}}/>
             </div>

             <div className="form-group mt-2">
                <label><b> Upload New logo:</b> </label>
                <input type="file" className="form-control file" onChange={(e)=>{handleFileChange(e.target.files[0])}}/>  
            </div>
            </form>
            
        </Modal>

            <Table dataSource={filteredArr.length > 0 ?  filteredArr : invoiceDetails} columns={columns} />
        
        </div>
    )
}

export default Invoices
