import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import './SingleUserForm.css';
import Message from '../../Message/Message';
import UserService from '../../../services/user.service';
import {message, Select} from 'antd';

const {Option} = Select;

const SingleUserForm = ({username, currentUser}) => {
  const [user, setUser] = useState ('');
  const [userName, setUsername] = useState ('');
  const [email, setEmail] = useState ('');
  const [name, setName] = useState ('');
  const [gender, setGender] = useState ('');
  const [dob, setDob] = useState ('');
  const [address, setAddress] = useState ('');
  const [contact, setContact] = useState ('');
  const [roles, setRoles] = useState ('');
  const [successMessage, setSuccessMessage] = useState (false);
  const [isEmployee, setIsEmployee] = useState (false);
  const [managers, setManagers] = useState ([]);
  const [assignedManager, setAssignedManager] = useState ('');
  const [initialManager, setInitialManager] = useState ('');
  const [userNamePlaceholder, setUserNamePlaceholder] = useState ('');
  const [selectedManager, setSelectedManager] = useState ('');
  const [skillsArr, setSkillsArr] = useState ([]);
  const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);

  const genderArray = ['Male', 'Female', 'Other'];

  const findManagerId = username => {
    const foundManager = managers.find (manager => {
      return manager.username === username;
    });
    const Id = foundManager._id;

    return Id;
  };

  const getAllCategory = async () => {
    try {
      const response = await UserService.getAllSkillsList ();
      // console.log(response.data);
      setSkillCategoriesArr (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleManagerChange = managerUsername => {
    if (managerUsername !== 'Select Manager') {
      const managerId = findManagerId (managerUsername);
      setAssignedManager (managerId);
    }
  };

  useEffect (() => {
    //    console.log(username);
    UserService.getSingleUser (username)
      .then (response => {
        if (response.data) {
          //    console.log("SINGLE USER : ", response.data);
          setUser (response.data);
          setSkillsArr (response.data.skills ? response.data.skills : []);
          if (response.data.roles[0] === 'ROLE_EMPLOYEE') {
            setIsEmployee (true);
          }
        }
      })
      .catch (err => {
        console.log (err);
      });

    getAllCategory ();
  }, []);

  useEffect (async () => {
    UserService.getAllManagers ()
      .then (response => {
        // console.log(response.data);
        setManagers (response.data);
        setInitialManager (response.data[0].username);
      })
      .catch (err => {
        console.log (err);
      });
  }, []);

  useEffect (() => {
    if (successMessage) {
      setSuccessMessage (false);
    }
  });

  const setAllFields = async () => {
    Object.keys (user).forEach (key => {
      if (user[key] !== null && key !== 'id') {
        switch (key) {
          case 'username':
            setUsername (user[key]);
            break;
          case 'email':
            setEmail (user[key]);
            break;
          case 'name':
            setName (user[key]);
            break;
          case 'gender':
            setGender (user[key]);
            break;
          case 'address':
            setAddress (user[key]);
            break;
          case 'dob':
            setDob (user[key]);
            break;
          case 'contact':
            setContact (user[key]);
            break;
          case 'roles':
            setRoles (user[key]);
            break;
          case 'manager':
            setSelectedManager (
              user[key] && user[key][0] && user[key][0].username
            );
            break;
        }
      }
    });
  };

  useEffect (
    () => {
      setAllFields ();
    },
    [user]
  );

  const handleUsernameChange = e => {
    const re = /^\S*$/;
    if (re.test (e.target.value)) {
      setUsername (e.target.value);
      setUserNamePlaceholder ('');
    } else {
      setUsername ('');
      setUserNamePlaceholder ("Spaces aren't allowed!");
    }
  };

  const updateUser = e => {
    let updatedManager = null;

    if (isEmployee) {
      if (assignedManager === '' || assignedManager === null) {
        updatedManager = findManagerId (initialManager);
      } else {
        updatedManager = assignedManager;
      }
    }

    e.preventDefault ();
    let updatedData = {
      _id: user.id,
      username: userName,
      name: name,
      email: email,
      contact: contact,
      address: address,
      dob: dob,
      gender: gender === '' ? 'Male' : gender,
      manager: updatedManager,
      skills: skillsArr,
    };

    // console.log("FINAL DATA ",updatedData);

    UserService.updateDetailsByAdmin (updatedData).then (data => {
      //    console.log("Successfully updated");
      //    console.log(data);
      setSuccessMessage (true);
    });
  };

  const handleChange = values => {
    // console.log(values);
    setSkillsArr (values);
  };

  return (
    <div className="singleUser">
      <form>
        <div className="form-inline">
          <div className="form-group">
            <label>Name : </label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={e => {
                setName (e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label>DOB : </label>
            <input
              type="date"
              className="form-control"
              value={dob}
              onChange={e => {
                setDob (e.target.value);
              }}
            />
          </div>
        </div>
        {currentUser.roles.includes ('ROLE_ADMIN')
          ? <div className="form-inline">

              <div className="form-group">
                <label>Username : </label>
                <input
                  type="text"
                  id="username"
                  className="form-control"
                  placeholder={userNamePlaceholder}
                  value={userName}
                  onChange={handleUsernameChange}
                />
              </div>

              <div className="form-group">
                <label>Email : </label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={e => {
                    setEmail (e.target.value);
                  }}
                />
              </div>
            </div>
          : null}

        <div className="form-inline">

          <div className="form-group">
            <label>Gender : </label>
            <select
              className="form-control"
              onChange={e => {
                gender !== e.target.value && setGender (e.target.value);
              }}
            >
              {genderArray.map (item => {
                return <option>{item}</option>;
              })}
            </select>
          </div>

          <div className="form-group">
            <label>Contact : </label>
            <input
              type="text"
              className="form-control"
              value={contact}
              onChange={e => {
                setContact (e.target.value);
              }}
            />
          </div>
        </div>
        {isEmployee
          ? <div className="form-group mb-4">
              <label>Assign Manager : </label>
              <select
                required
                className="form-control"
                onChange={e => {
                  handleManagerChange (e.target.value);
                }}
              >
                <option
                  hidden
                  selected={
                    selectedManager === null ||
                      selectedManager === undefined ||
                      selectedManager === ''
                  }
                >
                  Select Manager
                </option>
                {managers.map (manager => {
                  return (
                    <option
                      selected={manager.username === selectedManager}
                      key={manager._id}
                    >
                      {manager.username}
                    </option>
                  );
                })}
              </select>
            </div>
          : null}

        <div className="form-group">
          <label>Address : </label>
          <textarea
            value={address}
            className="form-control"
            onChange={e => {
              setAddress (e.target.value);
            }}
          />
        </div>
        <Select
          mode="multiple"
          allowClear
          showSearch
          value={skillsArr}
          filterOption={(input, option) =>
            option.children.toLowerCase ().indexOf (input.toLowerCase ()) >= 0}
          style={{width: '100%'}}
          placeholder="Please select"
          onChange={handleChange}
        >
          {skillsCategoryArr.map (item => {
            return (
              <Option value={item.name} key={item._id}>{item.name}</Option>
            );
          })}
        </Select>

        <div className="form-group">
          <button
            style={{width: 'fit-content'}}
            className="pay-btn"
            onClick={e => {
              updateUser (e);
            }}
          >
            Update
          </button>
        </div>

      </form>
      {successMessage
        ? <Message type="success" message="Updated Successfully" />
        : null}
    </div>
  );
};

export default SingleUserForm;
