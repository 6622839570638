import React, { useState,useEffect} from "react";
import { Router, Switch, Route} from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";


import Landing from './components/Landing/Landing';
import ErrorPage from "./components/ErrorPage/ErrorPage";
import BoardUser from "./components/BoardUser/BoardUser";
import BoardManager from "./components/BoardManager/BoardManager";
import BoardAdmin from "./components/BoardAdmin/BoardAdmin";
import BoardEmployee from "./components/BoardEmployee/BoardEmployee";


import { history } from "./helpers/history";
import LoginForm from "./components/LoginForm/LoginForm";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import ProfilePage from "./components/Profile/ProfilePage";
import Messenger from "./containers/messenger/Messenger";
import CodersartsChat from "./containers/CodersartsChat/CodersartsChat";
// import GetHelp from "./components/GetHelp/GetHelp";
import RouteWithNavBar from "./RouteWithNavBar";
import PublicPayment from "./components/PublicPayment/PublicPayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import RouteWithLoggedIn from './RouteWithLoggedIn';
import AdminAuthPage from "./components/AdminAuthApi/Redirect/Redirect.component";
import  FileUpload from './components/DriveUploader/file-upload.component';
import SendMail from './components/sendgmail/sendgmail.component';
import Groups from "./containers/Groups/Groups";
import CreateOrderPublic from "./components/Profile/CreateOrderPublic/CreateOrderPublic.jsx";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import WhatsappCloud from "./components/Profile/WhatsappCloud/WhatsappCloud";
import LoginWithPhone from "./components/LoginForm/LoginWithPhone";
import RegisterEvent from "./components/RegisterEvent/RegisterEvent";
import RegisterEventForm from "./components/RegisterEvent/RegisterEventForm";
import 'antd/dist/antd.css';
import 'antd-country-phone-input/dist/index.css';


const stripePromise = loadStripe("pk_live_51JQbRNSB5a1UKLbSiZ51EixOqSnJdt5vtPdpj39w5JeJVvIKWFZDP9MSPlloPE3K5ONwDz1N4ztTVzcWgsziZrsR00mpCLICR7");
const App = () => {
  const [path,setPath] = useState(history.location.pathname);
  

  history.listen((location)=>{
    setPath(location.pathname);
  });

  return (
    <>
    <Router history={history}>
    {/* {console.log(history)} */}
      <div className={`app ${(path==="/home" || path==="/") ? "back-blue":null }`}>
        <div className="container-fluid">
          <Switch>
            <RouteWithNavBar exact path={["/", "/home"]} component={Landing} />
            <RouteWithNavBar exact path={["/auth/signin","/login"]} component={LoginForm} />
            <RouteWithNavBar exact path="/auth/signin/forget-password" component={ForgetPassword} />
            <RouteWithNavBar exact path="/auth/signin-with-phone-number" component={LoginWithPhone} />
            <RouteWithNavBar exact path="/auth/signup" component={RegisterForm} />
            <RouteWithNavBar path="/profile" component={ProfilePage} />
            {/* <RouteWithLoggedIn path="/messenger" component={Messenger} /> */}

            {/* <RouteWithLoggedIn path="/codersarts-group-chat" component={Groups} /> */}
            <RouteWithNavBar path="/get-help" component={CreateOrderPublic} />
            <Route path="/drive-file-uploader" component={FileUpload}/>
            <Route path="/codersarts-google-api-login" component={AdminAuthPage} />
            <RouteWithNavBar path="/events/register" component={RegisterEvent} />
            <RouteWithNavBar path="/events/register-event/:id" component={RegisterEventForm} />
            <Route path="/chat" component={CodersartsChat} />
            <Route path="/whatsapp-chat" component={WhatsappCloud}/>
            <Route path="/codersarts-payment">
            <Elements  stripe={stripePromise}>
            <PublicPayment /> 
            </Elements>
            </Route>
            <Route><ErrorPage /></Route>

          </Switch>
        </div>
      
      </div>
    </Router>
    </>
  );
};

export default App;
