
import React,{useEffect} from 'react';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import Card from '../Card/Card.component';




const AdminAuthPage = () =>{

    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])

    


        
    
    

return(
    <div>

    <Card />

    </div>
)

    
 
    
}
        

export default AdminAuthPage;