import React,{useState,useEffect} from 'react';
import { Table,Space,Tag,Modal,Menu,Dropdown,Button,message} from 'antd';
import { Link } from 'react-router-dom';
import orderService from '../../../services/order.service';
import { PlusOutlined, EyeOutlined,DeleteOutlined,MoreOutlined,ExclamationCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';






const AllBookings = ()=>{
    const [allBookings,setAllBookings] = useState([]);
    const [filteredArr,setFilteredArr] = useState([]);
    const [filteredBookingNameArr,setFilteredBookingNameArr] = useState([]);
    const [updateBookingModal,setUpdateBookingModal] = useState(false);
    const [meetingLink,setMeetingLink] = useState("");
    const [loading,setLoading] = useState(false);
    const [currentBookingId,setCurrentBookingId] = useState("");


    
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])



    const getAllBookings = async ()=>{
        try{
           const response = await orderService.getAllBookings()
            // console.log("All bookings : ",response.data);
            let data = [];
            response.data.map((d)=>{
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
               
                let modifiedData = {
                    id:d._id,
                    date:finalDate,
                    sortDate:d.createdAt,
                    name:d.isMobileBooking ? d.bookingNameForMobile : d.booking && d.booking[0].name,
                    bookingBy:d.bookingBy && d.bookingBy[0].username,
                    bookingFor:d.date + " " + d.timezone,
                    details:d.details && d.details,
                    userId:d.bookingBy && d.bookingBy[0]._id,
                    meetingUrl:d.meetingUrl ? d.meetingUrl : ""
                }

                data.push(modifiedData);
            })
            
            setAllBookings(data);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(()=>{
        getAllBookings();
    },[]);

    useEffect(()=>{
        let bookingNameFilterArr = [];
         allBookings.map((b)=>{
           if(b.name){
            bookingNameFilterArr.push({text:b.name ,value:b.name })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        bookingNameFilterArr = bookingNameFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setFilteredBookingNameArr(bookingNameFilterArr);
    },[allBookings])
    


    const deleteBooking = async (id)=>{
        try{
            const response = await orderService.deleteBookingById(id);
            // console.log(response.data);
            message.success("Deleted Successfully!");
            getAllBookings();
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to delete!');
        }
    }

    const confirmDelete = (id)=>{
        Modal.confirm({
            title: 'Confirm Delete',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to Delete this Booking?',
            okText: 'Yes',
            cancelText: 'No',
            onOk:()=>{deleteBooking(id)}
          });
    }


    const handleMeetingLink = (id,link)=>{
        setMeetingLink(link);
        setCurrentBookingId(id);
        setUpdateBookingModal(true);
    }

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Name',
            key: 'name',
            filters:filteredBookingNameArr,
            onFilter:(value, record) => record.name.indexOf(value) === 0,
            render:(record)=>{
                return(<Link to={`/profile/booking-details/${record.id}`}> <b>{record.name}</b></Link>)
            }
        },
        {
            title: 'Booking By',
            key: 'bookingBy',
            render:(record)=>{
                return(
                    <Link to={`/profile/manage-users/view/${record.userId}`}><b>{record.bookingBy}</b></Link>
                )
            }
        },
        {
            title: 'Meeting Date',
            key: 'bookingFor',
            render:(record)=>{
                return(<span>{record.bookingFor && record.bookingFor.slice(0,25)}...</span>)
            }
        },
        {
            title: 'Details',
            key: 'Details',
            render:(record)=>{
                return(<Tag color={'cyan'}>{record.details && record.details.slice(0,50)}...</Tag>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
             <Space size="middle">
                {/* <Link to={`/profile/booking-details/${record.id}`}>
                 <Button type={'primary'}>View Details</Button>
                </Link> */}

                <Dropdown overlay={
                  <Menu style={{marginLeft:'-25%'}}>
                   <Menu.Item key="1">
                    <EyeOutlined /> <Link to={`/profile/booking-details/${record.id}`} /> View Details
                   </Menu.Item>
                  <Menu.Item key="2"  onClick={()=>{handleMeetingLink(record.id,record.meetingUrl)}}>
                 <VideoCameraOutlined /> Add Meeting Link
                  </Menu.Item>
                  <Menu.Item key="3" danger onClick={()=>{confirmDelete(record.id)}}>
                 <DeleteOutlined /> Delete
                  </Menu.Item>
                
                </Menu>
                }>
                <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
                </Dropdown>
              </Space>
              )
           
        },
    ]
   


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allBookings.length >0){
                filteredData= allBookings.filter((booking)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return booking.bookingBy && booking.bookingBy.toLowerCase().match(item.toLowerCase())
                });
                // console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }


    const handleUpdateBooking = async ()=>{
        // console.log("Meeting link : ",meetingLink);
        // console.log("booking id : ",currentBookingId);
        setLoading(true);
        try{
            const response = await orderService.updateBooking(currentBookingId,{meetingUrl:meetingLink})
            if(response){
                setLoading(false);
                message.success("Added a meeting link successfully!");
                handleBookingModalCancel();
                getAllBookings();
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message :'something went wrong!');
        }

    }

    const handleBookingModalCancel = ()=>{
        setUpdateBookingModal(false);
       
    }
    return(
        <div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Bookings</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}}
                         /> 
                </div>
               <div>
               <Link to="/profile/create-new-booking">
                <Button type={'primary'}>
                <PlusOutlined className='mr-3'/>
                <b>Create Booking</b>
                </Button>
                </Link>
               </div>
            </div>

            <Modal 
            title={"Add a Meeting link"}
            visible={updateBookingModal} 
            onOk={handleUpdateBooking} 
            onCancel={handleBookingModalCancel}
            confirmLoading={loading}
         >
                    <form>
                    <div className="form-group">
                    <label><h6><b>Meeting Link :</b></h6></label>
                    <input 
                    type="text" 
                    className="form-control"
                    value={meetingLink} 
                    onChange={(e)=>{setMeetingLink(e.target.value)}}/>
                    </div>
                    </form>
                </Modal>

           
            <Table dataSource={filteredArr.length > 0 ?  filteredArr : allBookings} columns={columns} />
        </div>
    )
}


export default AllBookings;