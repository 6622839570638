import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {Link} from 'react-router-dom';

const ProfileDropdown = (props)=>{

  let secondMenu = null;
  if(props.user.roles[0].toLowerCase()==="role_user"){
    secondMenu = <Menu.Item>
              <Link to="/profile/user-orders">
                My Orders
              </Link>
            
            </Menu.Item>
  }else if(props.user.roles[0].toLowerCase()==="role_admin"){
    secondMenu = <Menu.Item>
    <Link to="/profile/view-orders">
      All Orders
    </Link>
  
  </Menu.Item>
  }else if(props.user.roles[0].toLowerCase()==="role_employee"){
    secondMenu = <Menu.Item>
    <Link to="/profile/assignment">
      My Assignments
    </Link>
  
  </Menu.Item>
  }else if(props.user.roles[0].toLowerCase()==="role_manager"){
    secondMenu = <Menu.Item>
    <Link to="/profile/assigned-orders">
     Assigned Orders
    </Link>
  </Menu.Item>
  }


 const menu = (
        <Menu>
        <Menu.Item>
          <Link to="/profile">
            Profile
          </Link>
        </Menu.Item>
        {secondMenu}
        <Menu.Item danger onClick={props.logout}>Logout</Menu.Item>
        </Menu>
        )
  return(
        <Dropdown overlay={menu}>
            <a className="nav-link" style={{display:'flex'}} onClick={e => e.preventDefault()}>
                <div>{props.name}</div>
                <div style={{marginLeft:'8px'}}>
                <DownOutlined />
                </div>
            </a>
        </Dropdown>
  )

}
 
export default ProfileDropdown;