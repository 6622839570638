import React, {useEffect, useState} from 'react';
import Message from '../../../../Message/Message';
import './SingleOrderForm.css';
import {Checkbox, message, Select, Spin} from 'antd';
import OrderService from '../../../../../services/order.service';
import UserService from '../../../../../services/user.service';
import { LoadingOutlined } from '@ant-design/icons';

const {Option} = Select;
const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

const SingleOrderForm = props => {
  const [order, setOrder] = useState ('');
  const [updatedName, setUpdatedName] = useState ('');
  const [updatedDetails, setUpdatedDetails] = useState ('');
  const [updatedDeadline, setUpdatedDeadline] = useState ('');
  const [updatedCategory, setUpdatedCategory] = useState ('');
  const [otherCategory, setOtherCategory] = useState ('');
  const [updatedDocumentation, setUpdatedDocumentation] = useState ('');
  const [updatedBookingSession, setUpdatedBookingSession] = useState ('');
  const [updatedAssignedToId, setUpdatedAssignedToId] = useState ('');
  const [updatedTotalAmount, setTotalAmount] = useState ('');
  const [updatedPaidAmount, setPaidAmount] = useState ('');
  const [successMessage, setSuccessMessage] = useState (false);
  const [codeWalkThrough, setCodeWalkThrough] = useState (null);
  const [reportFee, setReportFee] = useState (null);
  const [categoryArray, setCategoryArray] = useState ([]);
  const [updatedCustomer, setUpdatedCustomer] = useState (null);
  const [allAssignTo, setAllAssignTo] = useState ([]);
  const [allCustomers, setAllCustomers] = useState ([]);
  const [updatedTechnologies,setUpdatedTechnologies] = useState([]);
  const [skills,setSkills] = useState([]);
  const [loading,setLoading] = useState(false);
  const [updatedType,setUpdatedType] = useState("");
  const [updatedTimezone,setUpdatedTimezone] = useState("");
  const [updatedCurrency,setUpdatedCurrency] = useState("");

  var today = new Date ();
  var dd = String (today.getDate ()).padStart (2, '0');
  var mm = String (today.getMonth () + 1).padStart (2, '0'); //January is 0!
  var yyyy = today.getFullYear ();
  today = yyyy + '-' + mm + '-' + dd;

  const getAllCategories = async () => {
    try {
      const response = await OrderService.getAllOrderCategory ();
      let orderCategories = [];
      response.data.map (c => {
        orderCategories.push (c.name);
      });
      setCategoryArray (orderCategories);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : "Couldn't load the categories!"
      );
    }
  };
  useEffect (() => {
    getAllCategories ();
    
    const getSkillsList = async ()=>{
        try{
            const response = await UserService.getAllSkillsList();
            if(response){
                setSkills(response.data);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'something went wrong!');
        }
    }
    getSkillsList();
  }, []);

  const getUsers = async () => {
    let assignToArr = [];
    let customerArr = [];
    await UserService.getAllUsers ().then (
      response => {
        
        response.data.map (user => {
          if(user.isDisabled===false || 
            user.isDisabled===undefined 
            || user.isDisabled==="" || user.isDisabled===null){
                if (
                    user.roles[0].name === 'employee' ||
                    user.roles[0].name === 'manager'
                  ) {
                    assignToArr.push (user);
                  }
                  if (user.roles[0].name === 'user') {
                    customerArr.push (user);
                  }
            }
        }
        );
        setAllAssignTo (assignToArr);
        setAllCustomers (customerArr);
      },
      error => {
        // const _content =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString ();

        // setAllUsers (_content);
        message.error (error.message ? error.message : 'something went wrong!');
      }
    );
  };

  const setAllFields = order => {
    if (order) {
      //   console.log("ORDER",order);
      const {
        name,
        details,
        category,
        deadline,
        documentation,
        bookingSession,
        assignedTo,
        totalAmount,
        paidAmount,
        codeWalkThrough,
        reportFee,
        customer,
        type,
        technologies,
        timezone,
        currency
      } = order;
      setUpdatedName (name);
      setUpdatedDetails (details);
      setUpdatedCategory (category);
      setUpdatedAssignedToId (assignedTo[0]._id);
      setUpdatedDeadline (deadline);
      setUpdatedBookingSession (bookingSession);
      setUpdatedDocumentation (documentation);
      setTotalAmount (totalAmount);
      setPaidAmount (paidAmount);
      setCodeWalkThrough (codeWalkThrough);
      setReportFee (reportFee);
      setUpdatedCustomer (customer && customer[0] ? customer[0]._id : null);
      setUpdatedTechnologies(technologies ? technologies : []);
      setUpdatedType(type ? type : "");
      setUpdatedTimezone(timezone);
      setUpdatedCurrency(currency);
    }
  };

  const getOrder = async () => {
    //   console.log("ID : ",props.id);
    if (props.id) {
      await OrderService.getSingleOrder (props.id)
        .then (response => {
          // console.log(response.data);
          setOrder (response.data);
          setAllFields (response.data);
        })
        .catch (err => {
          console.log (err);
        });
    }
  };

  useEffect (
    () => {
      if (successMessage) {
        setSuccessMessage (false);
      }
    },
    [successMessage]
  );

  useEffect (() => {
    getOrder ();
    getUsers ();
    setAllFields ();
  }, []);

  const handleCustomer = id => {
    setUpdatedCustomer (id);
  };
  const updateOrder = e => {
    e.preventDefault ();
    setLoading(true);
    const data = {
      _id: props.id,
      name: updatedName,
      details: updatedDetails,
      deadline: updatedDeadline,
      category: updatedCategory.toLowerCase () === 'other'
        ? otherCategory
        : updatedCategory,
      documentation: updatedDocumentation,
      bookingSession: updatedBookingSession,
      assignedTo: updatedAssignedToId,
      totalAmount: updatedTotalAmount,
      paidAmount: updatedPaidAmount,
      codeWalkThrough: codeWalkThrough,
      customer: updatedCustomer,
      reportFee: reportFee,
      technologies:updatedTechnologies,
      type:updatedType,
      timezone:updatedTimezone

    };
    OrderService.updateOrder (data)
      .then (response => {
        // console.log("UPDATE REPONSE : " ,response.data );
        if(response){
            setLoading(false);
            setSuccessMessage (true);
        }
       
        
      })
      .catch (err => {
        console.log (err);
        setLoading(false);
      });
  };

  //   const handleAssignedTo = uname => {
  //     var foundUser = '';
  //     if (uname.toLowerCase () !== 'select user') {
  //       foundUser = allUsers.find (user => {
  //         return user.username === uname;
  //       });
  //     }
  //     setUpdatedAssignedToId (foundUser._id);
  //     // console.log(updatedAssignedToId);
  //   };

  const handleAssignedTo = id => {
    setUpdatedAssignedToId (id);
    // console.log(assignedTo);
  };

  const handleCategorySelect = (value)=>{
   
       setUpdatedCategory(value);

    }

const handleTechnologySelect = (values)=>{
    // console.log(values);
    setUpdatedTechnologies(values)
}

const handleProjectTypeChange = (values)=>{
    setUpdatedType(values);
}


  return (
    <div className="createOrderForm">
      <h3>Update Order</h3>
      
      <form>
            <table>
            <tr>
            <th><label>Project Name : </label></th>
            <td colSpan={5}><input type="text" 
             placeholder="e.g. Need help in web development" 
             required className="form-control" value={updatedName} onChange={(e)=>{setUpdatedName(e.target.value)}}/></td>
            </tr>
          
            <tr>
                <th><label>Category : </label></th>
                <td colSpan={2}>
                    {/* <select style={{marginTop:'1.2rem'}} 
                    value={category} required className="form-control"  
                    onChange={(e)=>{setCategory(e.target.value)}}>
                       <option>Select Category</option>
                       {allOrderCategory && allOrderCategory.map((name,index)=>{
                           return <option key={index}>{name}</option>
                       })}
                       <option>Other</option>
                    </select> */}
                    <Select
                        showSearch
                        virtual={true}
                        size={"large"}
                        style={{ width: '100%',fontWeight:'bold',marginTop:'5%' }}
                        onChange={handleCategorySelect}
                        placeholder="Search or Select a category"
                        optionFilterProp="value"
                        value={updatedCategory}
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   categoryArray &&
                            categoryArray.map((name,index)=>{
                                    return(<Option 
                                    key={index} 
                                    value={name} 
                                    >
                                    {name}
                                    </Option>)

                                }
                            )
                        }
                        <Option value="other">Other</Option> 
                    </Select>
                </td>
                {updatedCategory.toLowerCase()==='other' && (
                    <>
                    <th colSpan={2}><label>Specify Other Category : </label>
                    <input type="text"  
                     required value={otherCategory}
                      className="form-control" 
                      onChange={(e)=>{setOtherCategory(e.target.value)}}/>
                    </th>
                   
                    </>
                )}

            </tr>

            <tr>
                <th><label>Technologies Used:</label></th>
                <td colSpan={4}>
                <Select
                        showSearch
                        mode={"multiple"}
                        virtual={true}
                        size={"large"}
                        value={updatedTechnologies}
                        style={{ width: '50%',fontWeight:'bold' }}
                        onChange={handleTechnologySelect}
                        placeholder="Search or Select a technology"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   skills &&
                            skills.map((item,index)=>{
                                    return(<Option 
                                    key={index} 
                                    value={item.name} 
                                    >
                                    {item.name}
                                    </Option>)

                                }
                            )
                        }
                    </Select>
                </td>
            </tr>

            <tr>
            <th>Type of Order:</th>
            <td> 
            <Select 
           value={updatedType} 
           style={{ width: '100%',fontWeight:'bold' }}
           className="formInputs"  
           size={'large'}
           onChange={handleProjectTypeChange} >
          <Option value="New Project">New Project</Option>
          <Option value="Existing Project">Existing Project</Option>
          <Option value="Debugging">Debugging</Option>
          <Option value="Assignment">Assignment</Option>
          </Select>
          </td>
            </tr>

            <tr>
                <th>
                <label>Due Date : </label>
                </th>
                <td colSpan={2}>
                <input type="date" style={{marginTop:'1.2rem'}} required  min={today} className="form-control" value={updatedDeadline} onChange={(e)=>{setUpdatedDeadline(e.target.value)}}/>
                </td>
                <th colSpan={3}>
                <label>Timezone : </label>
                <input type="text" placeholder="e.g. 10 PM EST" required className="form-control" value={updatedTimezone} onChange={(e)=>{setUpdatedTimezone(e.target.value)}}/>
                </th>
               
            </tr>

            <tr>
            <th>
            <label>Total Amount : </label>
            </th>
            <td colSpan={2}>
            <input style={{marginTop:'1.2rem'}} type="number"  min={1} className="form-control" 
            value={updatedTotalAmount} onChange={(e)=>{setTotalAmount(e.target.value)}}/>
            </td>

            <th>
            <label>Paid Amount : </label>
            <input type="number" min={0} className="form-control" value={updatedPaidAmount} 
            onChange={(e)=>{setPaidAmount(e.target.value)}}/>
            </th>

            <th>
            <label>Currency : </label>
            <input type="text" placeholder="e.g. USD,INR,CAD" 
            className="form-control" value={updatedCurrency} onChange={(e)=>{setUpdatedCurrency(e.target.value)}}/>
            </th>
           
            </tr>


            <tr>

                <th>
                <label>Customer : </label>
                </th>
                <td colSpan={2}>
                {/* <select style={{marginTop:'1.2rem'}}  required className="form-control" onChange={(e)=>{handleCustomer(e.target.value)}}>
                        <option>Select Customer</option>   
                        {   allCustomers &&
                            allCustomers.map((user)=>{
                                return <option key={user._id}>{user.username}</option>
                            })
                        } 
                       
                    </select> */}
                    <br />
                    
                    <Select
                        showSearch
                        virtual={true}
                        style={{ width: '100%',fontWeight:'bold' }}
                        onChange={handleCustomer}
                        placeholder="Search or Select a customer"
                        optionFilterProp="value"
                        value={updatedCustomer}
                        filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {   allCustomers &&
                            allCustomers.map((user)=>{
                                    return(<Option 
                                    key={user._id} 
                                    value={user._id} 
                                    >
                                    {user.username}
                                    </Option>)

                                }
                            )
                        } 
                    </Select>

                </td>


                <th colSpan={3}>
                <label>Assign To : </label>
                {/* <select  required className="form-control" onChange={(e)=>{handleAssignedTo(e.target.value)}}>
                        <option>Select Assign To</option>   
                        {   allAssignTo &&
                            allAssignTo.map((user)=>{
                                return <option key={user._id} value={user.username}>{user.username}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                ( {user.roles[0].name} )</option>
                            })
                        } 
                       
                    </select> */}

                    <Select
                        showSearch
                        virtual={true}
                        style={{ width: '100%' }}
                        onChange={handleAssignedTo}
                        placeholder="Search or Select Employee or Manager"
                        optionFilterProp="value"
                        value={updatedAssignedToId}
                        filterOption={(input, option) =>
                        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.title.toLowerCase().localeCompare(optionB.title.toLowerCase())
                        }
                    >
                        {   allAssignTo &&
                            allAssignTo.map((user)=>{
                                    return(<Option 
                                    key={user._id} 
                                    value={user._id} 
                                    title={user.username}
                                    >
                                    {user.username}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    ( {user.roles[0].name} )
                                    </Option>)

                                }
                            )
                        } 
                    </Select>
                </th>
               

               
            </tr>

            <tr>
                <th>
                <label>Description : </label>
                </th>
                <td colSpan={5} >
                <textarea style={{marginTop:'1.2rem'}} 
                placeholder="Write some details of the order here...."
                 required value={updatedDetails} className="form-control"
                 onChange={(e)=>{setUpdatedDetails(e.target.value)}}>
                    </textarea>
                </td>

            </tr>

          
           <tr>
         
         <th>
         </th>
           <th colSpan={2}>
           <Checkbox  checked={updatedBookingSession} onChange={(e)=>{setUpdatedBookingSession(e.target.checked)}}><label> Need Code Walkthrough ? </label> </Checkbox>
           </th>

           <th colSpan={2}>
           <Checkbox checked={updatedDocumentation}  onChange={(e)=>{setUpdatedDocumentation(e.target.checked)}}> <label>Need Write up or report ? </label> </Checkbox>
           </th>

           </tr>
         
           
              
            
            
           <tr>
                <th>
                <label>Codewalkthrough Amount : </label>
                </th>
                <td colSpan={2}>
                <input style={{marginTop:'1.2rem'}} type="number" min={0} className="form-control" 
                value={codeWalkThrough} onChange={(e)=>{setCodeWalkThrough(e.target.value)}}/>
                </td>

                <th colSpan={3}>
                <label>Report Per Page Amount: </label>
                <input type="number"  min={0}  className="form-control" value={reportFee} onChange={(e)=>{setReportFee(e.target.value)}}/>
                </th>
               
           </tr>

           
          
        </table>
            <div className="form-group">
            <button style={{width:'fit-content'}} className="pay-btn"
            disabled = {updatedName === "" || updatedDetails === "" || updatedDeadline ==="" || updatedCategory === ""} 
            onClick={(e)=>{updateOrder(e)}}>{loading ? <Spin indicator={spinIcon} /> : 'Update Order'}</button>
            </div>
        </form>
      {successMessage
        ? <Message type="success" message="Updated Order Successfully" />
        : null}
    </div>
  );
};

export default SingleOrderForm;
