import React, {useState, useEffect} from 'react';
import styles from './AdminEvents.module.css';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import {Breadcrumb, Button, message, Select, DatePicker, Input} from 'antd';
import {Link} from 'react-router-dom';
import EventsServices from '../../../services/events.services';
import OrderService from '../../../services/order.service';
import S3 from 'react-aws-s3';

function CreateEvent () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [title, setTitle] = useState ('');
  const [description, setDescription] = useState ('');
  const [eventDate, setEventDate] = useState ('');
  const [eventLink, setEventLink] = useState ('');
  const [isDisabled, setIsDisabled] = useState (true);
  const [isLoading, setIsLoading] = useState (false);
  const [file, setFile] = useState ('');
  const [uploadingFile, setUploadingFile] = useState (false);
  const [awsData, setAwsData] = useState (false);
  const [bannerImg, setBannerImg] = useState ('');

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  useEffect (() => {
    let isMounted = true;
    const getAwsInfo = async () => {
      try {
        const res = await OrderService.getAwsInfo ();

        setAwsData (res.data);
      } catch (err) {
        console.log (err);
      }
    };
    if (isMounted) {
      getAwsInfo ();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async () => {
    setIsLoading (true);
    let data = {
      title: title,
      description: description,
      eventDate: eventDate,
      isDisabled: isDisabled,
      bannerImg: bannerImg,
      link: eventLink,
    };
    try {
      const response = await EventsServices.createNewEvent (data);
      if (response.data) {
        message.success ('Created new Event Successfully!');
        setTitle ('');
        setDescription ('');
        setEventLink ('');
        setBannerImg ('');
      }
      setIsLoading (false);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setIsLoading (false);
    }
  };

  const handleFileChange = item => {
    if (item.type === 'image/jpeg' || Image.type === 'image/png') {
      setFile (item);
    } else {
      message.warning ('Please Select either a JPG or PNG File only!');
      setFile ('');
    }
  };

  const handleUploadFile = async () => {
    if (awsData && file) {
      setUploadingFile (true);

      const config = {
        bucketName: awsData.bucketName,
        region: 'ap-south-1',
        accessKeyId: awsData.accessKeyId,
        secretAccessKey: awsData.secretKey,
        s3Url: 'https://' + awsData.bucketName + '.s3.amazonaws.com',
      };

      const ReactS3Client = new S3 (config);
      ReactS3Client.uploadFile (file, file.name)
        .then (data => {
          console.log (data);
          message.success ('Uploaded file successfully!');
          setBannerImg (data.location);
          setUploadingFile (false);
        })
        .catch (err => {
          console.log (err);
          setUploadingFile (false);
        });
    }
  };

  return (
    <div className={styles.createEvent__wrapper}>
      <h2>Create New Event</h2>
      <Breadcrumb>
        <Breadcrumb.Item>
          {' '}<Link to="/profile">Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {' '}<Link to="/profile/all-events">All Events</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Create Event
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.createEvent__form}>
        <div className={styles.eventFormGroup}>
          <div className={styles.createEvent__formItem}>
            <label>
              Title
            </label>
            <Input
              value={title}
              onChange={e => setTitle (e.target.value)}
              type="text"
              placeholder="Title of the Event"
            />
          </div>
          <div className={styles.createEvent__formItem}>
            <label>
              Event Date
            </label>
            <DatePicker
              style={{width: '100%'}}
              onChange={(date, dateString) => setEventDate (dateString)}
            />
          </div>
        </div>

        <div className={styles.eventFormGroup}>

          <div className={styles.createEvent__formItem}>
            <label>
              Event Link
            </label>
            <Input
              value={eventLink}
              onChange={e => setEventLink (e.target.value)}
              type="text"
              placeholder="Link of the event"
            />
          </div>

          <div className={styles.createEvent__formItem}>
            <label>
              Disabled Event
            </label>
            <Select
              value={isDisabled}
              style={{
                width: '100%',
              }}
              onChange={value => setIsDisabled (value)}
              options={[
                {
                  value: true,
                  label: 'Yes',
                },
                {
                  value: false,
                  label: 'No',
                },
              ]}
            />
          </div>
        </div>

        <div className={styles.createEvent__formItem}>
          <label> Upload Banner: </label>
          <div style={{display: 'flex'}}>
            <input
              type="file"
              style={{width: '82%', marginRight: '10px'}}
              className="form-control file"
              accept="image/*"
              onChange={e => {
                handleFileChange (e.target.files[0]);
              }}
            />
            <Button
              loading={uploadingFile}
              type="primary"
              disabled={!file}
              onClick={handleUploadFile}
            >
              Upload
            </Button>
          </div>
        </div>

        <div className={styles.createEvent__formItem}>
          <label>
            Description
          </label>
          <Input.TextArea
            style={{width: '90%'}}
            value={description}
            onChange={e => setDescription (e.target.value)}
            type="text"
            placeholder="Description of the Event"
          />
        </div>

        <div>
          <Button
            type="primary"
            disabled={
              !title || !description || !eventDate || isLoading || !bannerImg
            }
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              fontWeight: 'bold',
            }}
            onClick={handleSubmit}
            loading={isLoading}
          >
            Create Event
          </Button>
        </div>

      </div>
    </div>
  );
}

export default CreateEvent;
