import React, {useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import avatar from '../../assets/user.png';
import {Link} from 'react-router-dom';

import './LoginForm.css';
import CountryPhoneInput, {ConfigProvider} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

const LoginWithPhone = props => {
  const form = useRef ();
  const checkBtn = useRef ();

  const [phoneNumber, setPhoneNumber] = useState ('');
  const [loading, setLoading] = useState (false);
  const {isLoggedIn} = useSelector (state => state.auth);
  const [isOtpScreen, setIsOtpScreen] = useState (false);
  const [otp, setOtp] = useState ();
  const dispatch = useDispatch ();

  const handleLogin = async e => {
    e.preventDefault ();
    if (checkBtn.current.context._errors.length === 0) {
      // dispatch (login (username, password))
      //   .then (() => {
      //     props.history.push ('/profile/codersarts-dashboard-home');
      //     window.location.reload ();
      //   })
      //   .catch (() => {
      //     setLoading (false);
      //   });
    } else {
      setLoading (false);
    }
  };

  useEffect (
    () => {
      if (isLoggedIn) {
        return <Redirect to="/profile/codersarts-dashboard-home" />;
      }
    },
    [isLoggedIn]
  );

  const getOTP = () => {
    console.log (phoneNumber);
  };

  return (
    <div className="col-md-6 loginForm">

      <div className="card card-container">
        <img src={avatar} alt="profile-img" className="profile-img-card" />

        <Form onSubmit={getOTP} ref={form}>

          <div className="form-group">

            <ConfigProvider locale={en}>
              <CountryPhoneInput
                onChange={v => {
                  setPhoneNumber (v);
                }}
                defaultValue={{short: 'IN'}}
              />
            </ConfigProvider>
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary btn-block"
              disabled={loading || phoneNumber === ''}
            >
              {loading && <span className="spinner-border spinner-border-sm" />}
              <span>Get OTP</span>
            </button>
            <br />
            <div className="login__footer__link">
              <br />
              <Link to="/auth/signup">Sign Up ?</Link><br />

            </div>
            <small><center>Or</center></small>
            <div className="loginWithMobile">
              <Link to="/auth/signin">
                Login with Email
              </Link>
            </div>

          </div>

        </Form>
      </div>
    </div>
  );
};

export default LoginWithPhone;
