import { message,Table,Menu,Space,Dropdown,Tabs } from 'antd';
import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import {history} from '../../../helpers/history'
import orderService from '../../../services/order.service';
import {ReloadOutlined,MoreOutlined,DeleteOutlined, FileOutlined,UserOutlined} from '@ant-design/icons';
import moment from 'moment';

const {TabPane} = Tabs;

function RecycleBin() {
    const [allItems,setAllItems] = useState([]);
    const [recycledUserArr,setRecycledUserArr] = useState([]);
    const [recycledOrderArr,setRecycledOrderArr] = useState([]);
    const [recycledPublicPaymentArr,setRecycledPublicPaymentArr] = useState([]);
    const [recycledPaymentArr,setRecycledPaymentArr] = useState([]);
    const [recycledPublicOrderArr,setRecycledPublicOrderArr] = useState([]);
    const [recycleItemsResponse,setRecycleItemsResponse] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    const getAllRecycleItems = async ()=>{
        let data = [];
        try{
            const response = await orderService.getAllRecyleData();
            // console.log(response);
            setRecycleItemsResponse(response.data);
            response.data.map((item)=>{
                let finalDate="N/A"
                // console.log("Created At : ",item.createdAt);
                if(item.createdAt){
                    const date = new Date(item.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear();
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();

                   finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                //    console.log("DD : ",dd);
                //    console.log("MM : ",mm);
                //    console.log("yyyy : ",yyyy);
                    
                }
                let temp = {
                    id:item._id,
                    date:finalDate,
                    sortDate:item.createdAt,
                    name:item.data ? item.data.name ? item.data.name : item.data.username ? item.data.username : item.data.fName ? item.data.fName :'Other':'Delete it',
                    type:item.recycleType
                
                }

                data.push(temp);
            })
            setAllItems(data);
            categorizeItems(data);

        }catch(err){
            message.error(err.message ? err.message : "Something went wrong!");
        }
    }

    useEffect(()=>{
        getAllRecycleItems();
    },[])
    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])

    const handleRestore = async (id)=>{
        // console.log(id);
        let data = recycleItemsResponse.filter((item)=>{
            return item._id===id
        })
        // console.log(data);
        try{
            const response = await orderService.restoreRecycleItem(data[0].data,data[0].recycleType);
            if(response.status==200){
                // console.log(response);
                message.success('Item Restored Successfully!');
                deletePermanently(id);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Couldn't Restore Item !");
        }
    }

    const deletePermanently = async (id)=>{
        // console.log(id);
        try{
            const response = await orderService.deleteRecycleItem(id);
            // console.log(response);
            if(response.status==200){
                message.success('Deleted Item Permanently!');
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message:'Unable To Delete Item Permananently!');
        }

        getAllRecycleItems();


     }

     const categorizeItems = (data)=>{
         let ordersArr = data.filter((item)=>{
             return item.type==="Order"
         });
        //  console.log("ORDERS : ",ordersArr);
         setRecycledOrderArr(ordersArr);
         let paymentsArr = data.filter((item)=>{
            return item.type==="Payment"
        });
        // console.log("Payment : ",paymentsArr);
        setRecycledPaymentArr(paymentsArr);
        let publicPaymentsArr = data.filter((item)=>{
            return item.type==="PublicPayment"
        });
        // console.log("Public Payment : ",publicPaymentsArr);

        setRecycledPublicPaymentArr(publicPaymentsArr);
        let publicOrderArr = data.filter((item)=>{
            return item.type==="PublicOrder"
        });
        // console.log("Public Order : ",publicOrderArr);
        setRecycledPublicOrderArr(publicOrderArr);
        let userArr = data.filter((item)=>{
            return item.type==="User"
        });
        // console.log("User : ",userArr);
        setRecycledUserArr(userArr);
     }

    const columns = [
        {
            title: 'Deleted At',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Item',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            
        },
        
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
                <Space size="middle">
                <Dropdown overlay={
                  <Menu style={{marginLeft:'-25%'}}>
                   <Menu.Item key="1" onClick={()=>{handleRestore(record.id) }}>
                   <ReloadOutlined style={{marginRight:'4%'}} />Restore
                   </Menu.Item>
                   <Menu.Item danger key="2" onClick={()=>{deletePermanently(record.id) }}>
                    <DeleteOutlined  style={{marginRight:'3%'}}/> Delete Permanently
                   </Menu.Item>
                </Menu>
                }>
                <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
                </Dropdown>

                
              </Space>
              )
           
        }
    ]
    return (
        <div>
            <h2>All Recycled Items</h2>
            <Tabs defaultActiveKey="1" type="card" >

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                <FileOutlined style={{fontSize:'20px'}} />
                    All Items
                    </span>
                } key="1" onChange={getAllRecycleItems}>
                <Table dataSource={allItems} columns={columns} />
                </TabPane>


                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <UserOutlined style={{fontSize:'20px'}} />
                    Users
                    </span>
                } key="2" onChange={getAllRecycleItems}>
                <Table dataSource={recycledUserArr} columns={columns} />
                </TabPane>

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-usd" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    Payments
                    </span>
                } key="3">
                <Table dataSource={recycledPaymentArr} columns={columns} />
                </TabPane>
                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-money" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    Public Payments
                    </span>
                } key="4">
                <Table dataSource={recycledPublicPaymentArr} columns={columns} />
                </TabPane>

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-shopping-cart" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    Orders
                    </span>
                } key="5">
                <Table dataSource={recycledOrderArr} columns={columns} />
                </TabPane>

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-users" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    Public Orders
                    </span>
                } key="6">
                <Table dataSource={recycledPublicOrderArr} columns={columns} />
                </TabPane>
                </Tabs>
        </div>
    )
}

export default RecycleBin
