import React, {useEffect, useState} from 'react';
import './BookASession.css';
import {Breadcrumb, Spin, message, Input, Select, Button} from 'antd';
import {Link} from 'react-router-dom';
import orderService from '../../../services/order.service';
import {useSelector} from 'react-redux';
import {LoadingOutlined} from '@ant-design/icons';
const {Option} = Select;
const {TextArea} = Input;

const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

function BookASession () {
  const [sessions, setSessions] = useState ([]);
  const [selectedSession, setSelectedSession] = useState ('');
  const [bookingDate, setBookingDate] = useState ('');
  const [bookingTimezone, setBookingTimezone] = useState ('');
  const [bookingDetails, setBookingDetails] = useState ('');
  const [loading, setLoading] = useState (false);
  const [currentItem, setCurrentItem] = useState ('');
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (() => {
    let mounted = true;
    if (mounted) {
      const getAllSessions = async () => {
        try {
          const response = await orderService.getAllSessions ();
          // console.log(response.data);
          if (response) {
            setSessions (response.data);
          }
        } catch (err) {
          console.log (err.message ? err.message : 'something went wrong!');
        }
      };
      getAllSessions ();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleSessionSelect = value => {
    let foundItem = sessions.find (item => {
      return item.name === value;
    });

    // console.log("values : ",foundItem);
    setCurrentItem (foundItem);
    setSelectedSession (value);
  };

  const bookNow = async () => {
    setLoading (true);
    // let currentBookingSession = sessions.find((item)=>{
    //     return item.name===selectedSession;
    // });
    // console.log(currentBookingSession);
    // console.log("final data : ",{
    //     booking:currentBookingSession._id,
    //     bookingBy:currentUser.id,
    //     date:bookingDate,
    //     details:bookingDetails,
    //     timezone:bookingTimezone,
    // });
    try {
      const response = await orderService.createNewBooking ({
        booking: currentItem._id,
        bookingBy: currentUser.id,
        date: bookingDate,
        details: bookingDetails,
        timezone: bookingTimezone,
      });
      // console.log(response.data);
      setLoading (false);
      message.success ('Booking Confirmed!');
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to book session!');
      setLoading (false);
    }
  };

  return (
    <div className="bookASession">
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            {' '}
            <Link to="/profile/codersarts-dashboard-home">Dashboard Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Book A Session</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="bookingHeader">
        <h5>Give us Some Details for the Booking</h5>
        <div className="bookingDesc">
          Let our experts know what you need help with. The more details you provide,
          {' '}
          the more quickly you'll be able to connect with a expert who can help!.
        </div>
      </div>

      <div className="booking__container">
        <div className="form-group">
          <div className="labelGroup">
            <div className="num">1</div>
            <div> <label>Please Select a Session</label></div>
          </div>
          <Select
            showSearch
            virtual={true}
            className="formInputs"
            style={{width: '70%', fontWeight: 'bold', marginTop: '2%'}}
            onChange={handleSessionSelect}
            placeholder="Search or Select a session"
            optionFilterProp="value"
            filterOption={(input, option) =>
              option.value.toLowerCase ().indexOf (input.toLowerCase ()) >= 0}
            filterSort={(optionA, optionB) =>
              optionA.value
                .toLowerCase ()
                .localeCompare (optionB.value.toLowerCase ())}
          >
            {sessions &&
              sessions.map ((item, index) => {
                return (
                  <Option key={index} value={item.name}>
                    {item.name}
                  </Option>
                );
              })}
          </Select>
          <div className="formInputs gray text-bold">
            {currentItem &&
              currentItem.price &&
              `${currentItem.price} USD for ${currentItem.duration}`}
            {' '}
          </div>
        </div>

        <div className="form-group">
          <div className="labelGroup">
            <div className="num">2</div>
            <div> <label>Please Enter a Date for Booking</label></div>
          </div>
          <input
            type="Date"
            className="form-control formInputs"
            value={bookingDate}
            onChange={e => {
              setBookingDate (e.target.value);
            }}
          />

        </div>

        <div className="form-group">
          <div className="labelGroup">
            <div className="num">3</div>
            <div> <label>Please Enter a Timezone for Booking</label></div>
          </div>
          <input
            type="text"
            value={bookingTimezone}
            placeholder="e.g 10 AM GMT+5"
            className="form-control formInputs"
            onChange={e => {
              setBookingTimezone (e.target.value);
            }}
          />

        </div>

        <div className="form-group">
          <div className="labelGroup">
            <div className="num">4</div>
            <div> <label>Please Enter some details</label></div>
          </div>
          <TextArea
            showCount
            allowClear
            maxLength={255}
            style={{height: 160, marginTop: '2%'}}
            className="formInputs w-70"
            onChange={e => {
              setBookingDetails (e.target.value);
            }}
          />

        </div>

        <div className="form-group">
          <Button type={'primary'} onClick={bookNow}>
            {loading ? <Spin indicator={spinIcon} /> : ' Book Now'}

          </Button>
        </div>

      </div>

    </div>
  );
}

export default BookASession;
