import React,{useEffect, useState} from 'react'
import {Input,Tabs,Card,InputNumber,Button,message, Select, Tag, Modal} from 'antd';
import OrderServices from '../../../services/order.service';
import userService from '../../../services/user.service';
import { EditOutlined } from '@ant-design/icons';


const {TabPane} = Tabs;
const { TextArea } = Input;
const {Option} = Select;

function YoutubePlaylistComponent () {
     const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [playlistId,setPlaylistId] = useState("");
    const [totalVideos,setTotalVideos] = useState(1);
    const [playlistSkills,setPlaylistSkills] = useState([]);
    const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);
    const [allPlaylist,setAllPlaylist] = useState([]);
    const [isYoutubeEditModal,setIsYoutubeEditModal] = useState(false);
    const [updatedTitle,setUpdatedTitle] = useState("");
    const [updatedDescription,setUpdatedDescription] = useState("");
    const [updatedPlaylistId,setUpdatedPlaylistId] = useState("");
    const [updatedPlaylistSkills,setUpdatedPlaylistSkills] = useState("");
    const [currentUpdateId,setCurrentUpdateId] = useState("");
    const [updatedTotalVideos,setUpdatedTotalvideos] = useState(1);
    const [loadingYoutubeEdit,setLoadingYoutubeEdit] = useState(false);
    const [thumbnailUrl,setThumbnailUrl] = useState("");
    const [updatedThumbnailUrl,setUpdatedThumbnailUrl] = useState("");
    const [filteredPlaylist,setFilteredPlaylist] = useState([]);

    const getAllPlaylist = async ()=>{
      try{
        const response = await OrderServices.getYoutubePlaylist();
        console.log(response.data);
        setAllPlaylist(response.data);
      }catch(err){
        console.log(err);
        
      }
    }

    useEffect(()=>{
      let isMounted = true;
      const getAllCategory = async () => {
          try {
            const response = await userService.getAllSkillsList ();
            // console.log(response.data);
            setSkillCategoriesArr (response.data);
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'Something went wrong!');
          }
        };

        if(isMounted){
            getAllCategory();
        }

        return ()=>{
            isMounted = false;
        }

  },[]);

  const handleEditYoutubeModal = (prevData)=>{
    setCurrentUpdateId(prevData._id);
    setUpdatedTitle(prevData.title);
    setUpdatedPlaylistId(prevData.playlistId);
    setUpdatedPlaylistSkills(prevData.skills ? prevData.skills : []);
    setUpdatedDescription(prevData.description);
    setUpdatedTotalvideos(prevData.totalVideos);
    setUpdatedThumbnailUrl(prevData.thumbnail ? prevData.thumbnail : "");
    setIsYoutubeEditModal(true);
}

const handleUpdateYoutubePlaylist = async ()=>{
    setLoadingYoutubeEdit(true);
    let PlaylistSkillsInCapital = [];
    for(let i=0;i<updatedPlaylistSkills.length;i++){
        PlaylistSkillsInCapital.push(updatedPlaylistSkills[i].toUpperCase());
    }
    // console.log(PlaylistSkillsInCapital);
    let data = {
        title:updatedTitle,
        playlistId:updatedPlaylistId,
        description:updatedDescription,
        skills:updatedPlaylistSkills,
        totalVideos:updatedTotalVideos,
        skillsInUpper:PlaylistSkillsInCapital,
        thumbnail:updatedThumbnailUrl
    }
    // console.log("id : ",currentUpdateId);
    console.log(data);
    try{
        const response = await OrderServices.updateYoutubePlaylist(currentUpdateId,data);
        if(response){
            console.log(response);
            message.success(`${updatedTitle} updated successfully`);
            setLoadingYoutubeEdit(false);
            setIsYoutubeEditModal(false);
            getAllPlaylist();
        }
    }catch(err){
        console.log(err);
        setLoadingYoutubeEdit(false);
        message.error(err.message ? err.message : 'Something went wrong!');
    }

}


    const handleAddPlaylist = async ()=>{
      let playlistSkillsInCapital = [];
      for(let i=0;i<playlistSkills.length;i++){
        playlistSkillsInCapital.push(playlistSkills[i].toUpperCase());
      }
     


      let finalData = {
        title:title,
        description:description,
        totalVideos:totalVideos,
        playlistId:playlistId,
        skills:playlistSkills,
        skillsInUpper:playlistSkillsInCapital,
        thumbnail:thumbnailUrl
      }
      // console.log(finalData);
      
    
        try{
          const response = await OrderServices.createYoutubePlaylist(finalData);
          if(response){
              console.log(response);
              message.success("Added Youtube Playlist Successfully!");
              setTitle("");
              setDescription("");
              setTotalVideos(1);
              setPlaylistId("");
          }
      }catch(err){
          console.log(err);
          message.error(err.message ? err.message : 'Something went wrong!');
      }
    }


    const handleSearch = (e)=>{
      let query = e.target.value;
      let filteredData = allPlaylist.filter((item)=>{
        return item.title.toLowerCase().match(query.toLowerCase());
      })

      setFilteredPlaylist(filteredData);
    }
  return(
    <div>
        <h1>Youtube Playlist Info</h1>
        <hr />
        <Tabs defaultActiveKey="1" onChange={getAllPlaylist}>
            <TabPane tab="Add Playlist" key="1" >
            <div>
            <h3>Add Your Youtube Playlist Information</h3>
            <div>
            <Input style={{width:'46%',margin:'1rem'}} value={title} onChange={(e)=>setTitle(e.target.value)} placeholder="Enter Playlist Title e.g. Reactjs Tutorial" />
            <Input style={{width:'46%',margin:'1rem'}} value={playlistId} onChange={(e)=>setPlaylistId(e.target.value)} placeholder="Enter playlist Id e.g. ptANwoNWpJM" />
            <Input style={{width:'46%',margin:'1rem'}} value={thumbnailUrl} onChange={(e)=>setThumbnailUrl(e.target.value)} placeholder="Enter playlist Thumbnail URL" />
            <InputNumber style={{width:'46%',margin:'1rem'}} value={totalVideos} onChange={(value)=>setTotalVideos(value)} />
            <Select
              showSearch
                placeholder="Select Skills"
                mode="multiple"
                optionFilterProp="children"
                style={{width:'96%',margin:'1rem'}}
                className="form-control-select"
                onChange={(value)=>setPlaylistSkills(value)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            
            >
                {skillsCategoryArr.map((item)=>{
                    return(
                        <Option value={item.name} key={item._id}>{item.name}</Option>
                    )
                })}
            </Select>
            <TextArea
                style={{width:'96%',margin:'1rem'}}
                showCount
                maxLength={255}
                placeholder="Enter Playlist Description"
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
            />
            <Button type="primary"  style={{margin:'1rem'}} onClick={handleAddPlaylist}>Add</Button>
            </div>
        </div>
            </TabPane>
            <TabPane tab="All Playlist" key="2">

               <>
               <div style={{display:'flex',justifyContent:'space-between'}}>
               <h3>All Playlist</h3>
               <div>
                 <Input onChange={handleSearch} placeholder="Search By Title" />
               </div>
               </div>

               <div className="youtube-card-group">
               {filteredPlaylist.length > 0 ? 
                filteredPlaylist.map((item)=>{
                 return(
                   <Card
                    cover={item.thumbnail &&
                    <img src={item.thumbnail} alt={item.title} />
                  }
                    style={{width:'40%',margin:'3%'}} className='youtube-card' key={item._id}>
                   
                  
                   <div className='d-flex' style={{justifyContent:'space-between'}}>
                      <h3>{item.title}</h3>
                      <EditOutlined onClick={()=>handleEditYoutubeModal(item)}/>
                  </div>
                    
                     <small>{item.description}</small>
                     <hr />
                     <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'2%'}}>
                       <small className="badge">{item.playlistId}</small>
                       <small>Total Videos: {item.totalVideos}</small>
                     </div>
                     <br />
                     <div>
                      {item?.skills?.map((skill,i)=>{
                          return(<Tag color="cyan" key={i}>{skill}</Tag>)
                      })}
                   </div>
                   </Card>
                 )
               })
               :
                 allPlaylist.map((item)=>{
                 return(
                   <Card
                    cover={item.thumbnail &&
                    <img src={item.thumbnail} alt={item.title} />
                  }
                    style={{width:'40%',margin:'3%'}} className='youtube-card' key={item._id}>
                   
                  
                   <div className='d-flex' style={{justifyContent:'space-between'}}>
                      <h3>{item.title}</h3>
                      <EditOutlined onClick={()=>handleEditYoutubeModal(item)}/>
                  </div>
                    
                     <small>{item.description}</small>
                     <hr />
                     <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'2%'}}>
                       <small className="badge">{item.playlistId}</small>
                       <small>Total Videos: {item.totalVideos}</small>
                     </div>
                     <br />
                     <div>
                      {item?.skills?.map((skill,i)=>{
                          return(<Tag color="cyan" key={i}>{skill}</Tag>)
                      })}
                   </div>
                   </Card>
                 )
               })}
               </div>

               <Modal
                    title={'Update Youtube Playlist'}
                    visible={isYoutubeEditModal}
                    onCancel={() => {
                        setIsYoutubeEditModal(false);
                    }}
                    confirmLoading={loadingYoutubeEdit}
                    onOk={handleUpdateYoutubePlaylist}
                    >
                    <div>
                        <Input 
                        value={updatedTitle}
                        className="my-2"
                        onChange={(e)=>{setUpdatedTitle(e.target.value)}} 
                        placeholder="Enter a Updated Playlist Title" />
                         <Input  value={updatedPlaylistId} className="my-2" onChange={(e)=>setUpdatedPlaylistId(e.target.value)} placeholder="Enter Updated Playlist Id e.g. ptANwoNWpJM" />
                         <Input  value={updatedThumbnailUrl} className="my-2" onChange={(e)=>setUpdatedThumbnailUrl(e.target.value)} placeholder="Enter Updated Thumbnail URL" />
                         <InputNumber className="my-2"style={{width:'100%'}} value={updatedTotalVideos} onChange={(value)=>setUpdatedTotalvideos(value)} />
                        <Select
                        showSearch
                            placeholder="Select Updated Skills"
                            mode="multiple"
                            value={updatedPlaylistSkills}
                            optionFilterProp="children"
                            style={{width:'100%'}}
                            className="my-2"
                            onChange={(value)=>setUpdatedPlaylistSkills(value)}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        
                        >
                            {skillsCategoryArr.map((item)=>{
                                return(
                                    <Option value={item.name} key={item._id}>{item.name}</Option>
                                )
                            })}
                        </Select>
                        
                        <TextArea
                            showCount
                            maxLength={255}
                            className="my-2"
                            placeholder="Enter Updated Playlist Description"
                            value={updatedDescription}
                            onChange={(e)=>setUpdatedDescription(e.target.value)}
                        />
                    </div>
                  </Modal>




               </>
            </TabPane>
          
        </Tabs>
       
    </div>
  )
}

export default YoutubePlaylistComponent;
