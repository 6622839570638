import React,{useState,useEffect} from 'react'
import './SendMailToUser.css';
import { Spin,Select,message,Breadcrumb,Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import GoogleServices from '../../services/google.services';
import {history} from '../../helpers/history';
import {useSelector} from 'react-redux';
import orderService from '../../services/order.service';
import {Link} from 'react-router-dom';

const {Option} = Select;


const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

function SendMailToUser() {
    const [toMail,setToMail] = useState("");
    const [subject,setSubject] = useState("");
    const [selectedfile,setselectedfile] = useState("");
    const [isAttachment,setIsAttachment] = useState(false);
    const [write,setWrite] = useState("");
    const [label,setLabel] = useState("");
    const [allLabels,setAllLabels] = useState([]);
    const [profile,setProfile] = useState(false);
    const [loading,setLoading] = useState(false);
    const [attachedfileName,setAttachedFileName] = useState(null);
    const hiddenFileInput = React.useRef(null);

    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])


    useEffect(()=>{
        let isMounted = true;

       const getAllGmailLabels = async ()=>{
        try{
            const response = await orderService.getAllGmailLabels();
            // console.log(response.data);
            let userLabels = response.data?.labels?.filter(item=>{
                return item.type==='user'
            });

            // console.log(userLabels);
            setAllLabels(userLabels);
        }catch(err){
            console.log(err)
        }
       }
       if(isMounted){
        getAllGmailLabels();
       }

       return ()=>{
           isMounted = false;
       }
       
    },[])

    const handleAttachmentClick = (e)=>{
        // console.log(e);
        hiddenFileInput.current.click();
    }

    const handleChange = (event) => {
        //  console.log(event.target.files[0])
          setselectedfile(event.target.files[0]);
          setAttachedFileName(event.target.files[0].name);
          const reader = new FileReader()
          reader.onload = () => {
            if(reader.readyState === 2){
              
              setProfile(reader.result)
            }
          }
          reader.readAsBinaryString(event.target.files[0])
          setIsAttachment(true);
      }


const  createMailRecordInDB = async ()=>{
    try{
        const data = {
            sender:currentUser.email,
            receiver:toMail,
            subject:subject,
            message:write,
            isAttachment:isAttachment
        }

        const response = await orderService.createMailRecord(data);
        if(response){
            message.success(`Mail Record Created Successfully!`)
        }
    }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'Unable to create mail record in DB');
    }
};

const Send = async ()=>{

setLoading(true);
// console.log(selectedfile);
try{
        if(isAttachment){
        const data = {
            To:toMail,
            Subject:subject,
            Write:write,
            attachment:isAttachment,
            name:selectedfile.name,
            size:selectedfile.size,
            type:selectedfile.type,
            lastmodified:selectedfile.lastmodified,
            filedata:profile
        }
        
        const response = await GoogleServices.sendmail(data)
       
           if(response){
            createMailRecordInDB();
            setToMail("");
            setWrite("");
            setSubject("");
            setLoading(false);
            setselectedfile(null);
            setAttachedFileName(null);
            setProfile(null);
            message.success("Successfully Sent the Mail !");
           }
    }
    else{
        const data ={
            To:toMail,
            Subject:subject,
            Write:write,
            attachment:isAttachment,
            labelId:label

        }
        const response = await GoogleServices.sendmail(data)
            if(response){
                createMailRecordInDB();
                setToMail("");
                setWrite("");
                setSubject("");
                setLoading(false);
                message.success("Successfully Sent the Mail !");
            }
       
    }
    }catch(err){
        console.log(err);
        setLoading(false);
        message.error(err.message ? err.message : "Something Went Wrong !");
    }
    
   
}

const removeAttachment = ()=>{
    setselectedfile(null);
    setIsAttachment(false);
    setAttachedFileName(null);
}

const onLabelChange = (value)=>{
    // console.log(value);
    setLabel(value);
}

    return (
        <>
         <Breadcrumb>
        <Breadcrumb.Item> <Link to="/profile/mailRecords">Mail Records </Link></Breadcrumb.Item>
        <Breadcrumb.Item> <Link to={`/profile/SendMailToUser`}>Compose Mail</Link></Breadcrumb.Item>
        </Breadcrumb>
        <h2>Compose Mail</h2>
        <div className="SendMailToUser">
            <div>
                <div className="form-group">
                    <input type="email" placeholder="To : " className="form-control" value={toMail} onChange={(e)=>{setToMail(e.target.value)}} />
                </div>
                <div className="form-group">
                    <input type="text" placeholder="Subject : " className="form-control" value={subject} onChange={(e)=>{setSubject(e.target.value)}} />
                </div>
            </div>
            <div className="form-group" >
            <Select
                showSearch
                placeholder="Select a label"
                optionFilterProp="children"
                className="form-control-select"
                onChange={onLabelChange}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            >
               {allLabels.map((item)=>{
                   return <Option  value={item.id} key={item.id}>{item.name}</Option>
               })}
            </Select>
            </div>

            <div className="write-section">
                <textarea value={write} onChange={(e)=>{setWrite(e.target.value)}} placeholder="Write Something..." className="writeBox">
                </textarea>
                <br />

                    
                <div className="d-flex">
                <button type="button" disabled={toMail==="" || subject===""} onClick={Send} className="btn btn-primary email-send-btn">{loading ? <Spin indicator={spinIcon} /> : 'Send'}</button>
                <Tooltip title="Add Attachment ? " color={'cyan'}>
                <span className="attachment-icon" onClick={handleAttachmentClick}><i className="fa fa-paperclip" aria-hidden="true"></i></span>
                </Tooltip>
               {attachedfileName &&  <span className="attached-file-name"><b>{attachedfileName}</b>
               <Tooltip title="Remove Attachment ? " color={'red'}>
               <span onClick={removeAttachment}> <i className="fa fa-times" aria-hidden="true"></i></span>
               </Tooltip>
               </span>}
                <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(e)=>handleChange(e)}
                    style={{display: 'none'}} 
                />
                </div>
            </div>
           
        </div>
        </>
    )
}

export default SendMailToUser
