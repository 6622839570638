import React from 'react';
import manager from  '../../../assets/manager.png';
import './DashboardHome.css';
import {Card} from 'antd';
import {ShoppingCartOutlined,UserOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';


function ManagerDashboard({userData}) {
  
    return (
        <div className='DashboardHome'>
                <div className='dashboard-card'>
            <h3>Statistics</h3>
            <hr />
            <div className='Stats'>
                    <Link to="/profile/assignment">
                        <Card className="stat-card">
                        <div>
                        <UserOutlined className='stat-icon' />
                        </div>
                        <div className='cart-total'>
                            <div className="total-label">Total Employees</div>
                            <div className='dashboard-card-total'>{userData.EmployeeCount ? userData.EmployeeCount : 0}</div>
                        </div>
                        </Card> 
                    </Link>

                    <Link to="/profile/assigned-orders">
                        <Card className="stat-card">
                        <div>
                        <ShoppingCartOutlined className='stat-icon' />
                        </div>
                        <div className='cart-total'>
                            <div className="total-label">All Projects</div>
                            <div className='dashboard-card-total'>{userData.projects ? userData.projects : 0}</div>
                        </div>
                        </Card> 
                    </Link>
            </div>


            </div>
            <Link to="/profile">
            <div className='user-info-card'>
            <img src={manager} className='dashboard-user-img' alt="icon" />
            <div className='dashboardUserType'>Manager</div>
            </div>
            </Link>
        </div>
    )
}

export default ManagerDashboard
