import React, {useState, useEffect} from 'react';
import {history} from '../../../helpers/history';
import UserService from '../../../services/user.service';
import UsersTable from './UsersTable';
import {Button, Modal, Tabs, message, Dropdown, Menu} from 'antd';
import {
  UserAddOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import Message from '../../Message/Message';
import {useSelector} from 'react-redux';
import GoogleServices from '../../../services/google.services';
import OrderServices from '../../../services/order.service';

const {TabPane} = Tabs;

const ManageUsers = () => {
  const [content, setContent] = useState ([]);
  const [allRoles, setAllRoles] = useState ([]);
  const [isModalVisible, setIsModalVisible] = useState (false);
  const [username, setUsername] = useState ('');
  const [newRole, setNewRole] = useState ('');
  const [disableUserSuccess, setDisableUserSuccess] = useState (false);
  const [newPassword, setNewPassword] = useState ('codersarts@123');
  const [passwordResetMessage, setPasswordResetMessage] = useState (
    'Password Reset to codersarts@123 '
  );
  const [passwordResetSuccess, setPasswordResetSuccessMessage] = useState (
    false
  );
  const [userIdForPasswordReset, setUserIdForPasswordReset] = useState (null);
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState (false);
  const [confirmPasswordLoading, setConfirmPasswordLoading] = useState (false);
  const [roleChangedSuccessMessage, setRoleChangedSuccessMessage] = useState (
    false
  );
  const [filteredArr, setFilteredArr] = useState ([]);
  const [userForPasswordChange, setUserForPasswordChange] = useState (null);
  const [customerArr, setCustomerArr] = useState ([]);
  const [employeesArr, setEmployeesArr] = useState ([]);
  const [remoteDevArr, setRemoteEmployeesArr] = useState ([]);
  const [disabledUsers, setDisabledUsers] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );
  useEffect (() => {
    if (disableUserSuccess) {
      setDisableUserSuccess (false);
    }
    if (passwordResetSuccess) {
      setPasswordResetSuccessMessage (false);
    }
    if (roleChangedSuccessMessage) {
      setRoleChangedSuccessMessage (false);
    }
  });

  //recycling user

  const recycleUser = async id => {
    let recycledUser = content.filter (item => {
      return item._id === id;
    });
    let modifiedRecycleData = recycledUser[0];
    if (recycledUser[0].roles && recycledUser[0].roles[0]) {
      modifiedRecycleData.roles = [recycledUser[0].roles[0]._id];
    }
    // console.log("Recyled User : ",modifiedRecycleData);
    let finalRecycledData = {
      data: modifiedRecycleData,
      recycleType: 'User',
    };

    try {
      const response = await OrderServices.recycleData (finalRecycledData);
      // console.log(response);
      if (response.status == 200) {
        message.success (
          `${recycledUser[0].name ? recycledUser[0].name : recycledUser[0].username} Added to Recyle Bin!`
        );
      }
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : "Couldn't Recycled The Item !"
      );
    }

    getUsers ();
  };

  //sending email

  const sendMailToUser = async password => {
    // console.log("Mail : ",order.userMail);

    //  console.log("Changing Password !", userForPasswordChange);
    try {
      const data = {
        To: userForPasswordChange.email,
        Subject: 'Password Reset By Admin',
        Write: `Dear <b>${userForPasswordChange.name ? userForPasswordChange.name : userForPasswordChange.username}</b>, <br /> Your Password is Reset To <b>${password}</b> By Administrator. <br /> You can now login with this Password without any problem.`,
        attachment: false,
      };
      // console.log(data);
      const response = await GoogleServices.sendmail (data);
      // console.log("Mail Response : ",response);
      if (response) {
        message.success ('Successfully Sent Password Reset Mail !');
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Mail Not Sent!');
    }
  };

  const resetPassword = async (id, password) => {
    let response = null;
    setConfirmPasswordLoading (true);
    try {
      response = await UserService.resetUserPassword (id, password);
      setPasswordResetMessage (`Password Reset to ${response.data}`);
      setConfirmPasswordLoading (false);
      setPasswordResetSuccessMessage (true);
      setIsPasswordModalVisible (false);
      setNewPassword ('codersarts@123');
      // console.log(response);
    } catch (err) {
      console.log (err);
      setConfirmPasswordLoading (false);
    }

    if (response.data) {
      sendMailToUser (response.data);
    }
  };

  const viewDetails = id => {
    history.push (`/profile/manage-users/view/${id}`);
  };
  const editData = username => {
    history.push ({
      pathname: `/profile/manage-users/${username}`,
    });
  };

  const getAllRoles = () => {
    UserService.getAllRoles ()
      .then (
        response => {
          // console.log("ALL ROLES : ",response.data);
          setAllRoles (response.data);
        },
        error => {
          const _err =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString ();

          // setAllRoles(_err);
          message.error (_err);
        }
      )
      .catch (err => {
        console.log (err);
      });
  };

  const categorizeData = user => {
    // console.log("users : ",user)
    let customers = user.filter (item => {
      return (
        (item.isDisabled === undefined ||
          item.isDisabled === false ||
          item.isDisabled === null ||
          item.isDisabled === '') &&
        (item.roles && item.roles[0].name === 'user')
      );
    });

    let employees = user.filter (item => {
      return (
        (item.isDisabled === undefined ||
          item.isDisabled === false ||
          item.isDisabled === null ||
          item.isDisabled === '') &&
        (item.roles && item.roles[0].name === 'employee')
      );
    });
    let remotedevelopers = user.filter (item => {
      return (
        (item.isDisabled === undefined ||
          item.isDisabled === false ||
          item.isDisabled === null ||
          item.isDisabled === '') &&
        (item.roles &&
          ((item.roles[0] && item.roles[0].name === 'remotedeveloper') ||
            (item.roles[1] && item.roles[1].name === 'remotedeveloper')))
      );
    });

    let disabledUsersArr = user.filter (item => {
      return item.isDisabled && item.isDisabled === true;
    });

    setCustomerArr (customers);
    setEmployeesArr (employees);
    setRemoteEmployeesArr (remotedevelopers);
    setDisabledUsers (disabledUsersArr);
    //  console.log("customers : ",customers);
    //  console.log("employees : ",employees);
    //  console.log("remotedevelopers : ",remotedevelopers);
  };

  const getUsers = async () => {
    try {
      const response = await UserService.getAllUsers ();
      categorizeData (response.data);
      setContent (response.data);
    } catch (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();

      // setContent(_content);
      message.error (_content);
    }
  };

  useEffect (() => {
    getUsers ();
    getAllRoles ();
  }, []);

  const disableUser = (username, isSelect) => {
    // console.log("Confirmed")
    const updatedData = {
      username: username,
      isDisabled: !isSelect,
    };
    UserService.updateDetailsByAdmin (updatedData)
      .then (data => {
        // console.log("Disabled " ,username);
        // console.log(data);
        setDisableUserSuccess (true);
        getUsers ();
      })
      .catch (err => {
        console.log (err);
      });
  };

  const roleChangeService = () => {
    UserService.updateRole (username, newRole)
      .then (data => {
        // console.log("ROLE UPDATE RESPONSE: " ,data);
        setRoleChangedSuccessMessage (true);
      })
      .catch (err => {
        console.log (err);
      });
  };

  const handleRoleChange = (e, uname) => {
    setUsername (uname);
    setNewRole (e.target.value);
    setIsModalVisible (true);
  };

  const handleOk = () => {
    roleChangeService ();
    setIsModalVisible (false);
  };

  const handleCancel = () => {
    setIsModalVisible (false);
    getUsers ();
  };

  const openPasswordResetModal = user => {
    setUserIdForPasswordReset (user.id);
    setUserForPasswordChange (user);
    setIsPasswordModalVisible (true);
  };

  const handlePasswordOk = () => {
    resetPassword (userIdForPasswordReset, newPassword);
  };

  const handlePasswordCancel = () => {
    setNewPassword ('codersarts@123');
    setIsPasswordModalVisible (false);
  };

  const exportAllUsers = async () => {
    try {
      const result = await UserService.exportUsersToCsv ();
      // console.log(result);
      if (result.data) {
        message.success ('Exported All Users To CSV file !');
      }
    } catch (err) {
      message.error (err.message ? err.message : 'Unable To Export Users !');
    }
  };

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (content.length > 0) {
        filteredData = content.filter (user => {
          // return user.name && user.name.toLowerCase() === item.toLowerCase();
          return (
            (user.name &&
              user.name.toLowerCase ().match (item.toLowerCase ())) ||
            (user.email &&
              user.email.toLowerCase ().match (item.toLowerCase ()))
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    setFilteredArr (filteredData);
  };

  const confirmExport = () => {
    Modal.confirm ({
      title: 'Confirm Export',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to export all Users Data ? ',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        exportAllUsers ();
      },
    });
  };

  const confirmRecycleUser = id => {
    Modal.confirm ({
      title: 'Confirm Recycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Recycle This Users Data ? ',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        recycleUser (id);
      },
    });
  };

  // console.log("MANAGE USER : ",content);

  return (
    <div className="container">
      <header className="jumbotron">
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div><h3>All Users</h3></div>
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Search By Name,Email"
              style={{width: '30vw', marginBottom: '3%'}}
              onChange={e => {
                handleSearchChange (e.target.value);
              }}
            />

          </div>
          <div>
            {/* <Link to="/profile/create-users" style={{color:'white',fontWeight:'bold'}}>
          <Button style={{
          width:'20vw',
          borderRadius:'2rem',
          marginBottom:'15px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
          }}
          type={'primary'}
          
          >
          <UserAddOutlined style={{fontSize:'20px'}} />
          <span> Create User</span>
          </Button>
          </Link> */}
            {/* <Button style={{
          width:'20vw',
          borderRadius:'2rem',
          marginBottom:'15px',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
          }} onClick={exportAllUsers}>
          Export All Users
          </Button> */}

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to="/profile/create-users">
                      <UserAddOutlined style={{fontSize: '20px'}} /> Create User
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2" onClick={confirmExport}>
                    <UploadOutlined /> Export All Users
                  </Menu.Item>

                </Menu>
              }
            >
              <Button type={'primary'}>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>

          </div>
        </div>

        <Tabs defaultActiveKey="1" type="card">

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                Customers
              </span>
            }
            key="1"
            onChange={getUsers}
          >
            <UsersTable
              users={filteredArr.length > 0 ? filteredArr : customerArr}
              editData={editData}
              allRoles={allRoles}
              disableUser={disableUser}
              roleChange={handleRoleChange}
              setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
              resetPassword={resetPassword}
              viewDetails={viewDetails}
              confirmRecycleUser={confirmRecycleUser}
              openPasswordResetModal={openPasswordResetModal}
            />

          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                Employees
              </span>
            }
            key="2"
            onChange={getUsers}
          >
            <UsersTable
              users={filteredArr.length > 0 ? filteredArr : employeesArr}
              editData={editData}
              allRoles={allRoles}
              disableUser={disableUser}
              roleChange={handleRoleChange}
              setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
              resetPassword={resetPassword}
              viewDetails={viewDetails}
              confirmRecycleUser={confirmRecycleUser}
              openPasswordResetModal={openPasswordResetModal}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                Remote Developers
              </span>
            }
            key="3"
            onChange={getUsers}
          >
            <UsersTable
              users={filteredArr.length > 0 ? filteredArr : remoteDevArr}
              editData={editData}
              allRoles={allRoles}
              disableUser={disableUser}
              roleChange={handleRoleChange}
              setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
              resetPassword={resetPassword}
              viewDetails={viewDetails}
              confirmRecycleUser={confirmRecycleUser}
              openPasswordResetModal={openPasswordResetModal}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                Disabled Users
              </span>
            }
            key="4"
            onChange={getUsers}
          >
            <UsersTable
              users={filteredArr.length > 0 ? filteredArr : disabledUsers}
              editData={editData}
              allRoles={allRoles}
              disableUser={disableUser}
              roleChange={handleRoleChange}
              setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
              resetPassword={resetPassword}
              viewDetails={viewDetails}
              confirmRecycleUser={confirmRecycleUser}
              openPasswordResetModal={openPasswordResetModal}
            />

          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                All Users
              </span>
            }
            key="5"
            onChange={getUsers}
          >
            <UsersTable
              users={filteredArr.length > 0 ? filteredArr : content}
              editData={editData}
              allRoles={allRoles}
              disableUser={disableUser}
              roleChange={handleRoleChange}
              setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
              resetPassword={resetPassword}
              viewDetails={viewDetails}
              confirmRecycleUser={confirmRecycleUser}
              openPasswordResetModal={openPasswordResetModal}
            />
          </TabPane>

        </Tabs>
        {/* {content ? <UsersTable
         users={filteredArr.length > 0 ? filteredArr : content} 
         editData={editData}
         allRoles={allRoles} 
         disableUser={disableUser}
         roleChange={handleRoleChange}
         setPasswordResetSuccessMessage={setPasswordResetSuccessMessage}
         resetPassword={resetPassword}
         viewDetails={viewDetails}
         confirmRecycleUser={confirmRecycleUser}
         openPasswordResetModal={openPasswordResetModal}
          />:null} */}
        <Modal
          title={'Change Role'}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p style={{color: 'red'}}>
            Are You sure to Change Role of This User ?{' '}
          </p>
        </Modal>
        <Modal
          title={'Reset Password'}
          visible={isPasswordModalVisible}
          onOk={handlePasswordOk}
          onCancel={handlePasswordCancel}
          confirmLoading={confirmPasswordLoading}
        >
          <form>
            <label><h5>Enter a Password To Reset :</h5></label>
            <input
              type="text"
              className="form-control"
              value={newPassword}
              onChange={e => {
                setNewPassword (e.target.value);
              }}
            />
          </form>
        </Modal>
      </header>
      {disableUserSuccess &&
        <Message type="success" message="Updated Settings Successfully !" />}
      {passwordResetSuccess &&
        <Message type="success" message={passwordResetMessage} />}
      {roleChangedSuccessMessage &&
        <Message type="success" message="Role Changed Successfully !" />}
    </div>
  );
};

export default ManageUsers;
