import React, { useEffect,useState } from 'react'
import orderService from '../../../services/order.service';
import {message,Button,Tag,Breadcrumb} from 'antd';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import {Link} from 'react-router-dom';


function SingleSubscriptionDetails({match}) {
    const [subscriptionDetails,setSubscriptionDetails] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);


    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getBookingDetails = async ()=>{
        try{
            const response = await orderService.getSubscriptionById(match.params.slug);
            // console.log("Subscription details : ",response.data);
            setSubscriptionDetails(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch Subscription details!');
        }
    }
    useEffect(()=>{
        getBookingDetails();
    },[match.params.slug])
    return (
        <div className='bookingDetails singleSubsciption' style={{width:'100%'}}>
        <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/profile/subscription-records">All Subscriptions</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/view-subscription-details/${match.params.slug}`}>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].subscription ? subscriptionDetails[0].subscription[0].name : match.params.slug}</Link></Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="mt-2">Subscription Details</h1>
        <hr />
            <div className='card' style={{width:'90%'}}>
                <table>
                <tr>
                    <th>Id : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] &&  subscriptionDetails[0]._id}</td>
                </tr>
                <tr>
                    <th>Created At : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].createdAt ?  subscriptionDetails[0].createdAt: "N/A"}</td>
                </tr>
                <tr>
                    <th>Name : </th>
                    <td><b>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].subscription ? subscriptionDetails[0].subscription[0].name : "N/A"}</b></td>
                </tr>
                

                <tr>
                    <th>Amount: </th>
                    <td><b>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].paymentIntent ? subscriptionDetails[0].paymentIntent.amount/100 : "N/A"}</b></td>
                </tr>

                <tr>
                    <th>Payment Id: </th>
                    <td><b>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].paymentIntent ? subscriptionDetails[0].paymentIntent.id : "N/A"}</b></td>
                </tr>


                <tr >
                    <th>User : </th>
                    <td><Link to={`/profile/manage-users/view/${subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].user ? subscriptionDetails[0].user[0]._id : "N/A"}`}> <b>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].user ? subscriptionDetails[0].user[0].username : "N/A"}</b> </Link></td>
                </tr>
    
                <tr>
                    <th>Address : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].address ? subscriptionDetails[0].address : "N/A"}</td>
                </tr>
                <tr>
                    <th>City : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].city ? subscriptionDetails[0].city : "N/A"}</td>
                </tr>
                <tr>
                    <th>State : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].state ? subscriptionDetails[0].state : "N/A"}</td>
                </tr>

                <tr>
                    <th>Country : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].country ? subscriptionDetails[0].country : "N/A"}</td>
                </tr>

                <tr>
                    <th>Pin Code : </th>
                    <td>{subscriptionDetails && subscriptionDetails[0] && subscriptionDetails[0].pinCode ? subscriptionDetails[0].pinCode : "N/A"}</td>
                </tr>

                
               
                </table>
            </div>
        </div>
    )
}

export default SingleSubscriptionDetails
