import {
    UPDATE_SUCCESS,
    UPDATE_FAIL,
   
  } from "../actions/types";
  
  const user = JSON.parse(localStorage.getItem("user"));
  
  const initialState = user
    ? { isLoggedIn: true, user }
    : { isLoggedIn: false, user: null };
  
export default function (state = initialState, action) {
    const { type, payload } = action;
    
    switch (type) {
      case UPDATE_SUCCESS:
        console.log("REDUCER");
        return {
          ...state,
          user:payload,
          "updated":true,
          isLoggedIn: true,
        };
      case UPDATE_FAIL:
        return {
          ...state,
          isLoggedIn: false,
        };
      
      default:
        return state;
    }
  }
  