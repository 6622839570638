import React, {useEffect, useState} from 'react';
import {
  PlusOutlined,
  EllipsisOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  DatePicker,
  Space,
  Drawer,
  Radio,
  Tooltip,
  message,
  Menu,
  Modal,
  Select
} from 'antd';
import OrderService from '../../../services/order.service';
import './ToDoList.css';
import {useSelector} from 'react-redux';
import moment from 'moment';

const {Option} = Select;

const date = new Date ();
  // console.log(date);
  let dd = date.getDate ();
  let mm = date.getMonth () + 1;
  let yyyy = date.getFullYear ();
  let today =
   
    yyyy
    +
    "-"
    +
    mm +
    '-' +
    dd

function ToDoList () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [allSections, setAllSections] = useState ([]);
  const [allTasks, setAllTasks] = useState ([]);
  const [currentAddSectionId, setCurrentAddSectionId] = useState ('');
  const [showAddTaskInSection, setShowAddTaskInSection] = useState (false);
  const [currentAddSectionName, setCurrentAddSectionName] = useState ('');
  const [newTaskTitle, setNewTaskTitle] = useState ('');
  const [deadline, setDeadline] = useState (today);
  const [description, setDescription] = useState ('');
  const [completedTask,setCompletedTask] = useState([]);
  const [isAddingNewSection,setIsAddingNewSection] = useState(false);
  const [newSectionName,setNewSectionName] = useState("");
  const [updatedSectionTitle,setUpdatedSectionTitle] = useState("");
  const [isUpdatingSection,setIsUpdatingSection] = useState(false);
  const [currentUpdatingSection,setCurrentUpdatingSection] = useState("");
  const [isAddedByTop,setIsAddedByTop] = useState(false);
  const [showTaskDetails,setShowTaskDetails] = useState(false);
  const [currentViewTask,setCurrentViewTask] = useState("");
  const [currentToDoListId,setCurrentToDoListId] = useState("");
  const [showToDoListUpdate,setShowToDoListUpdate] = useState(false);
  const [updatedToDoListTitle,setUpdatedToDoListTitle] = useState("");
  const [updatedDeadline,setUpdatedDeadline] = useState("");
  const [updatedDescription,setUpdatedDescription] = useState("");
  const [updatedSection,setUpdatedSection] = useState("");
  const [updateIsCompleted,setIsUpdateIsCompleted] = useState(false);
  const [isAllTask,setIsAllTask] = useState(true);
  const [filteredTask,setFilteredTask] = useState([]);
  const [filterOf,setFilterOf] = useState("");
  
    


  const handleNewTaskTitle = e => {
    // console.log (e.target.value);
    setNewTaskTitle (e.target.value);
  };

 const  handleUpdateTask = async (id,updatedData)=>{
   console.log(id);
   console.log(updatedData);
   try{
      const response = await OrderService.updateToDoList(currentUser.id,id,updatedData);
      console.log(response);
      if(response){
        message.success("Updated Task successfully!");
        getAllToDoSectionAndTask();
      }
   }catch(err){
     console.log(err);
     message.error (err.message ? err.message : 'Something went wrong!');
   }

 }

  const handleDeadline = (date, dateString) => {
    setDeadline (dateString);
    // console.log (date, dateString);
  };

  const getAllToDoSectionAndTask = async () => {
    try {
      const response = await OrderService.getAllToDoSectionAndTask (
        currentUser.id
      );
      console.log (response.data);
      const {sections, tasks} = response.data;
      setAllSections (sections);
      setAllTasks (tasks);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };
  useEffect (
    () => {
      const getAllToDoSectionAndTask = async () => {
        try {
          const response = await OrderService.getAllToDoSectionAndTask (
            currentUser.id
          );
          console.log (response.data);
          const {sections, tasks} = response.data;

          setAllSections (sections);
          setAllTasks (tasks);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      getAllToDoSectionAndTask ();
    },
    [currentUser]
  );

  const getSectionTasks = sectionId => {
    const sectionTasks = allTasks.filter (item => {
      // console.log ('SectionId : ', sectionId);
      // console.log ('Section in Task : ', item.section);
      return item?.section[0] === sectionId;
    });
    // console.log ('Found Tasks: ', sectionTasks);
    return sectionTasks;
  };

  const handleAddTaskFromSection = (sectionId, name) => {
    setCurrentAddSectionId (sectionId);
    setCurrentAddSectionName (name);
    setShowAddTaskInSection (true);
  };

  const closeAddTaskDrawer = () => {
    setShowAddTaskInSection (false);
  };

  const addTaskNow = async () => {
    // console.log ('adding');
    setShowAddTaskInSection (false);
    let data = {
      title: newTaskTitle,
      deadline: deadline,
      isCompleted: false,
      description: description,
      belongsTo: currentUser.id,
      section: currentAddSectionId,
    };

    // console.log (data);
    try {
      const response = await OrderService.createNewToDoTask (data);
      // console.log (response.data);
      if (response) {
        message.success ('Added a new Task Successfully!');
        getAllToDoSectionAndTask ();
        setNewTaskTitle ('');
        setDescription ('');
        setDeadline (today);
        if(isAddedByTop){
          setIsAddedByTop(false)
        }
      }
    } catch (Err) {
      console.log (Err);
      message.error (Err.message ? Err.message : 'something went wrong!');
    }
  };

  const handleDescription = e => {
    console.log (e.target.value);
    setDescription (e.target.value);
  };

  const handleCompletedTask = ()=>{
    let completedTaskList = allTasks.filter((item)=>{
      return item.isCompleted
    });
    console.log(completedTaskList);
    setCompletedTask(completedTaskList);
    setFilteredTask([]);
    setIsAllTask(false);
  }

  const compareDate = (due,isCompleted)=>{
   if(!isCompleted){
    console.log(due);
    let newDue = new Date(due);
    let updatedToday = new Date(today);
    console.log("today : ",updatedToday);
    console.log(newDue.getTime());
    console.log(updatedToday.getTime())
    console.log(newDue.getTime() <=updatedToday.getTime());
    return newDue.getTime() <=updatedToday.getTime();
   }else{
     return false
   }

  }

  const handleSectionDelete = async (id)=>{
      console.log(id);
      try{
        const response = await OrderService.deleteToDoSection({id:id,userId:currentUser.id});
        console.log(response);
        if(response){
          message.success("Deleted Section Successfully!");
          getAllToDoSectionAndTask()
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : "something went wrong!");

      }
  }


  const handleAddNewSection = ()=>{
    setIsAddingNewSection(true);
    
  }

  const submitNewSection = async ()=>{
    try{
      const response = await OrderService.createNewToDoSection({title:newSectionName,belongsTo:currentUser.id})
      console.log(response);
      if(response){
        message.success("Added New Section "+newSectionName+" successfully!");
        getAllToDoSectionAndTask();
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");
    }
  }

  const handleNewSectionChange = (e)=>{
    console.log(e.target.value);
    setNewSectionName(e.target.value);
  }

  const handleEmptySection = async (id)=>{
    console.log(id);
    try{
      const response = await OrderService.emptyToDoSection({id:id});
      console.log(response);
      if(response){
        message.success("Section Cleared Successfully!");
        getAllToDoSectionAndTask()
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");

    }
  }

  const handleRenameSection = async()=>{
    try{
      const response = await OrderService.updateSectionTitle({id:currentUpdatingSection,title:updatedSectionTitle});
      if(response){
        setIsUpdatingSection(false);
        message.success("Updated Section title to "+updatedSectionTitle);
        getAllToDoSectionAndTask()
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");
    }
  }

  const handleSectionUpdating = (title,id)=>{
    setUpdatedSectionTitle(title);
    setCurrentUpdatingSection(id);
    setIsUpdatingSection(true);
  }

  const handleAddNewTask = ()=>{
    setIsAddedByTop(true);
    setShowAddTaskInSection (true);
  }

  const handleSectionChangeForTask = (value,option)=>{
    console.log(value,option);
    setCurrentAddSectionId(value);
    setCurrentAddSectionName(option.children);
  }

  const handleViewTask = (task)=>{
    console.log(task);
    setCurrentViewTask(task);
    setShowTaskDetails(true);

  }

  const handleUpdateToDoListSection = (value)=>{
    setUpdatedSection(value);
  }

  const handleTaskDelete = async (id,name)=>{
    console.log(id);
    try{
      const response = await OrderService.deleteToDoList({id:id,userId:currentUser.id});
      console.log(response);
      if(response){
        message.success(`Deleted ${name} Successfully!`);
        getAllToDoSectionAndTask();
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");

    }

  }

  const handleTaskUpdate = (task)=>{
    console.log(task);
    setCurrentToDoListId(task._id);
    setUpdatedToDoListTitle(task.title);
    setUpdatedDeadline(task.deadline);
    setUpdatedDescription(task.description);
    setIsUpdateIsCompleted(task.isCompleted);
    setUpdatedSection(task.section[0]);
    setShowToDoListUpdate(true);
  }

  const closeViewTaskDetails = ()=>{
    setShowTaskDetails(false);
  }

  const closeUpdateToDoList = ()=>{
    setShowToDoListUpdate(false);
  }

  const updateTaskNow = async ()=>{
    let data = {
      title:updatedToDoListTitle,
      deadline:updatedDeadline,
      isCompleted:updateIsCompleted,
      description:updatedDescription,
      section:updatedSection
    }

    try{
      const response = await OrderService.updateToDoList(currentUser.id,currentToDoListId,data);
      if(response){
        console.log(response);
        message.success(`Updated ${updatedToDoListTitle} successfully!`);
        closeUpdateToDoList();
        getAllToDoSectionAndTask();
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : "something went wrong!");
    }

    console.log({userId:currentUser.id,id:currentToDoListId,data:data});
  }

  const filterToday = ()=>{
    let todayTask = allTasks.filter((item)=>{
      let deadlineDate  = new Date(item.deadline)
      let updatedToday = new Date(today);
      return deadlineDate.getTime()===updatedToday.getTime()
    });
    console.log(todayTask);
    setFilteredTask(todayTask);
    setFilterOf("Today")
  } 

  const filterTomorrow = ()=>{
    let tomorrowTask = allTasks.filter((item)=>{
      let deadlineDate  = new Date(item.deadline)
      let updatedToday = new Date(today);
      let tomorrow = updatedToday.getDate() + 1
      return deadlineDate.getDate()===tomorrow
    });
    console.log(tomorrowTask);
    setFilteredTask(tomorrowTask);
    setFilterOf("Tomorrow")
  }

  const filterThisWeek = ()=>{
    let thisWeek = allTasks.filter((item)=>{
      let deadlineDate  = moment(item.deadline,'YYYY/MM/DD')
      let updatedToday = moment(today,'YYYY/MM/DD');
      return updatedToday.isoWeek()===deadlineDate.isoWeek()
    });
    console.log(thisWeek);
    setFilteredTask(thisWeek);
    setFilterOf("This Week")
  }

  const filterNextWeek = ()=>{
    let nextWeek = allTasks.filter((item)=>{
      let deadlineDate  = moment(item.deadline,'YYYY/MM/DD')
      let updatedToday = moment(today,'YYYY/MM/DD');
      return updatedToday.isoWeek() + 1===deadlineDate.isoWeek()
    });
    console.log(nextWeek);
    setFilteredTask(nextWeek);
    setFilterOf("Next Week")
  }

  const filterThisMonth = ()=>{
    let thisMonth = allTasks.filter((item)=>{
      let deadlineDate  = moment(item.deadline,'YYYY/MM/DD')
      let updatedToday = moment(today,'YYYY/MM/DD');
      return updatedToday.month()===deadlineDate.month()
    });
    console.log(thisMonth);
    setFilteredTask(thisMonth);
    setFilterOf("This Month")
  }


  const filterNextMonth = ()=>{
    let nextMonth = allTasks.filter((item)=>{
      let deadlineDate  = moment(item.deadline,'YYYY/MM/DD')
      let updatedToday = moment(today,'YYYY/MM/DD');
      return updatedToday.month()+1===deadlineDate.month()
    });
    console.log(nextMonth);
    setFilteredTask(nextMonth);
    setFilterOf("Next Month")
  }


  const handleAllTaskClick = ()=>{
    setFilteredTask([])
    setIsAllTask(true);
    
  }

  const handleSearch = (e)=>{
    setFilterOf("");
    let query = e.target.value;

    const filteredData = allTasks.filter((item)=>{
      return query && item.title.toLowerCase().match(query.toLowerCase())
    });

    console.log(filteredData);
    setFilteredTask(filteredData);

  }



  return (
    <div className="toDoTask">
      <div className="toDoList__header">
        <div className="searchArea">
          <h5>To Do List</h5>

          <div className="search">
            <SearchOutlined className="search-icon" />
            <input type="text" onChange={handleSearch} placeholder="Search Task here" />
          </div>
        </div>

        <div>
        <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={handleAddNewSection}>Section</Menu.Item>
                <Menu.Item key="2" onClick={handleAddNewTask}>Task</Menu.Item>
              </Menu>
            }
            arrow
          >
           <Button className="addNewBtn" type={'primary'}>
            <PlusOutlined /> Add
          </Button>
          </Dropdown>
         
        </div>
      </div>
      <div className="controlArea">
        <Space>
          <Button className="btn-control"  onClick={handleAllTaskClick}>
            All Task
            </Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={filterToday}>Today</Menu.Item>
                <Menu.Item key="2" onClick={filterTomorrow}>Tomorrow</Menu.Item>
                <Menu.Item key="3" onClick={filterThisWeek}>This Week</Menu.Item>
                <Menu.Item key="4" onClick={filterNextWeek}>Next Week</Menu.Item>
                <Menu.Item key="5" onClick={filterThisMonth}>This Month</Menu.Item>
                <Menu.Item key="6" onClick={filterNextMonth}>Next Month</Menu.Item>
              </Menu>
            }
            arrow
          >
            <Button className="btn-control">
              Filter
            </Button>
          </Dropdown>
            <Button className="btn-control" onClick={handleCompletedTask}>
            <CheckCircleOutlined /> Completed
            </Button>

        </Space>

      </div>

      


      {
        filteredTask.length > 0 ? 
        <div className="toDoSection__wrapper">
              <div className="toDoSection">
                <div className="toDoSection__header">

                  <b>{filterOf ? `${filterOf} Task `:'Search Result'}</b>
                </div>
                <div className="toDoSection__container">
                  {filteredTask.map ((task) => {
                    return (
                      <div className={`toDoSection__card ${compareDate(task.deadline,task.isCompleted) ? 'redCard':''}`} key={task._id} >
                        <div className="toDoSection__cardHeader">
                        <div>{task.title}</div>
                          <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1" onClick={()=>{handleViewTask(task)}}>View Details</Menu.Item>
                            <Menu.Item key="2" danger onClick={()=>{handleTaskDelete(task._id,task.title)}}>Delete Task</Menu.Item>
                            <Menu.Item key="3"  onClick={()=>{handleTaskUpdate(task)}}>Update Task</Menu.Item>
                          
                          </Menu>
                        }
                        arrow
                      >
                    <EllipsisOutlined className="icon" style={{cursor:'pointer'}} /> 
                    </Dropdown>
                       
                        </div>
                        <div className="card__controls">
                        {task.isCompleted ? 
                          <Tooltip title="Completed">
                          <CheckCircleFilled onClick={()=>{handleUpdateTask(task._id,{isCompleted:false,completedOn:""})}} className="icon cardIconComplete"/>
                          </Tooltip>
                          
                        :
                        <Tooltip title="Mark As Completed?" placement='bottom'>
                        <CheckCircleOutlined className="icon cardIcon" onClick={()=>{handleUpdateTask(task._id,{isCompleted:true,completedOn:today})}}/>
                        </Tooltip>
                        }
                        <div className="dueDate">
                          <span className={compareDate(task.deadline,task.isCompleted) ? 'red':'gray'}>{task.deadline}</span>
                        </div>
                        </div>
                        
                      </div>
                    );
                  })}
                </div>
              </div>
      </div>

        :
        
        isAllTask ? 
        <div className="toDoSection__wrapper">
        {allSections &&
          allSections.map ((item, key) => {
            return (
              <div className="toDoSection" key={key}>
                <div className="toDoSection__header">

                  <b>🐱‍💻{item.title}</b>
                  <div>
                    <Tooltip title="Add Task" placement="bottom">
                      <PlusOutlined
                        className="sectionIcon"
                        onClick={() => {
                          handleAddTaskFromSection (item._id, item.title);
                        }}
                      />
                    </Tooltip>
                    <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" danger onClick={()=>{handleSectionDelete(item._id)}}>Delete Section</Menu.Item>
                <Menu.Item key="2" onClick={()=>{handleEmptySection(item._id)}}>Empty Section</Menu.Item>
                <Menu.Item key="3" onClick={()=>{handleSectionUpdating(item.title,item._id)}}>Rename Section</Menu.Item>
              </Menu>
            }
            arrow
          >
           <EllipsisOutlined className="sectionIcon" />
          </Dropdown>
                    
                  </div>
                </div>
                <div className="toDoSection__container">
                  {getSectionTasks (item._id).map ((task, taskKey) => {
                    return (
                      <div  className={`toDoSection__card ${compareDate(task.deadline,task.isCompleted) ? 'redCard':''}`} key={taskKey}>
                        <div className="toDoSection__cardHeader">
                        <div>{task.title}</div>
                          <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1" onClick={()=>{handleViewTask(task)}}>View Details</Menu.Item>
                            <Menu.Item key="2" danger onClick={()=>{handleTaskDelete(task._id,task.title)}}>Delete Task</Menu.Item>
                            <Menu.Item key="3"  onClick={()=>{handleTaskUpdate(task)}}>Update Task</Menu.Item>
                          
                          </Menu>
                        }
                        arrow
                      >
                    <EllipsisOutlined className="icon" style={{cursor:'pointer'}} /> 
                    </Dropdown>
                       
                        </div>
                        <div className="card__controls">
                        {task.isCompleted ? 
                          <Tooltip title="Completed">
                          <CheckCircleFilled onClick={()=>{handleUpdateTask(task._id,{isCompleted:false,completedOn:""})}} className="icon cardIconComplete"/>
                          </Tooltip>
                          
                        :
                        <Tooltip title="Mark As Completed?" placement='bottom'>
                        <CheckCircleOutlined className="icon cardIcon" onClick={()=>{handleUpdateTask(task._id,{isCompleted:true,completedOn:today})}}/>
                        </Tooltip>
                        }
                        <div className="dueDate">
                          <span className={compareDate(task.deadline,task.isCompleted) ? 'red':'gray'}>{task.deadline}</span>
                        </div>
                        </div>
                        
                      </div>
                    );
                  })}
                  <button
                    className="addTaskBtn"
                    onClick={() => {
                      handleAddTaskFromSection (item._id, item.title);
                    }}
                  >
                    Add Task
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      
      :

      
      <div className="toDoSection__wrapper">
              <div className="toDoSection">
                <div className="toDoSection__header">

                  <b>✔ Completed</b>
                </div>
                <div className="toDoSection__container">
                  {completedTask.map ((task) => {
                    return (
                      <div className="toDoSection__card" key={task._id}>
                        <div className="toDoSection__cardHeader">
                        <div>{task.title}</div>
                          <Dropdown
                        overlay={
                          <Menu>
                            <Menu.Item key="1" onClick={()=>{handleViewTask(task)}}>View Details</Menu.Item>
                            <Menu.Item key="2" danger onClick={()=>{handleTaskDelete(task._id,task.title)}}>Delete Task</Menu.Item>
                            <Menu.Item key="3"  onClick={()=>{handleTaskUpdate(task)}}>Update Task</Menu.Item>
                          
                          </Menu>
                        }
                        arrow
                      >
                    <EllipsisOutlined className="icon" style={{cursor:'pointer'}} /> 
                    </Dropdown>
                       
                        </div>
                        <div className="card__controls">
                        {task.isCompleted ? 
                          <Tooltip title="Completed">
                          <CheckCircleFilled onClick={()=>{handleUpdateTask(task._id,{isCompleted:false,completedOn:""})}} className="icon cardIconComplete"/>
                          </Tooltip>
                          
                        :
                        <Tooltip title="Mark As Completed?" placement='bottom'>
                        <CheckCircleOutlined className="icon cardIcon" onClick={()=>{handleUpdateTask(task._id,{isCompleted:true,completedOn:today})}}/>
                        </Tooltip>
                        }
                        <div className="dueDate">
                          <span className={compareDate(task.deadline,task.isCompleted) ? 'red':'gray'}>{task.deadline}</span>
                        </div>
                        </div>
                        
                      </div>
                    );
                  })}
                </div>
              </div>
      </div>

      }
      


      <Drawer
        title={`Add New Task to ${currentAddSectionName}`}
        placement={'right'}
        width={500}
        onClose={closeAddTaskDrawer}
        visible={showAddTaskInSection}
        extra={
          <Space>
            <Button onClick={closeAddTaskDrawer}>Cancel</Button>

          </Space>
        }
      >
        <div className="toDoList__addNewTask">
          <div className="formGroup">
            <label>Task Title</label>
            <Input
              placeholder="Enter Task Title"
              value={newTaskTitle}
              allowClear
              onChange={handleNewTaskTitle}
            />
          </div>

          <div className="formGroup">
            <label>Deadline</label>
            <DatePicker onChange={handleDeadline} value={moment(deadline,'YYYY/MM/DD')} />
          </div>
          {isAddedByTop && 
            <div className="formGroup">
              <label>Select Section</label>
              <Select
                showSearch
                placeholder="Select a To Do Section"
                optionFilterProp="children"
                onChange={(value,option)=>{handleSectionChangeForTask(value,option)}}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
            {allSections.map((item)=>{
              return <Option value={item._id} key={item._id}>{item.title}</Option>
            })}
          </Select>
            </div>
          }
          <div className="formGroup">
            <label>Description</label>
            <Input.TextArea
              rows={4}
              value={description}
              placeholder="Please enter task details"
              maxLength={255}
              onChange={handleDescription}
              allowClear
            />
          </div>

        </div>
        <Button type="primary" onClick={addTaskNow} disabled={!currentAddSectionId}>
          Add Now
        </Button>
      </Drawer>


      <Drawer
        title={`${currentViewTask.title} Details`}
        placement={'right'}
        width={500}
        onClose={closeViewTaskDetails}
        visible={showTaskDetails}
        extra={
          <Space>
            <Button onClick={closeViewTaskDetails}>Cancel</Button>

          </Space>
        }
      >

     <div className='viewTaskHeader'>
     <div>
     <h4 className='viewTaskHeading'>{currentViewTask.title}</h4>
      <div className={`badge ${currentViewTask.isCompleted ? 'CompletedTaskBadge' : 'PendingTaskBadge'}`}>{currentViewTask.isCompleted ? 'Completed' : 'Pending'}</div>
     </div>
     <div className='viewTaskDeadline'>
        <div className="viewTaskDeadlineHead">
          {currentViewTask.isCompleted ? 'Completed On' : 'Due'}
        </div>
       <div className="badge viewTaskDeadlineBody">
       {currentViewTask.isCompleted ? currentViewTask.completedOn : currentViewTask.deadline}
       </div>

     </div>
     </div>

      <div className="viewTaskDetails">
        {currentViewTask.description}
      </div>
     

      </Drawer>



      <Modal
          title={'Add New Section'}
          visible={isAddingNewSection}
          onCancel={() => {
            setIsAddingNewSection (false);
          }}
          onOk={submitNewSection}
        >
          <div>
            <label>New Section Title</label>
            <Input onChange={handleNewSectionChange} allowClear placeholder="Enter a New Section Title" />
          </div>
        </Modal>

        <Modal
          title={'Updated Section'}
          visible={isUpdatingSection}
          onCancel={() => {
            setIsUpdatingSection(false);
          }}
          onOk={handleRenameSection}
        >
          <div>
            <label>Enter Updated Section Title</label>
            <Input 
            value={updatedSectionTitle}
             onChange={(e)=>{setUpdatedSectionTitle(e.target.value)}} 
              placeholder="Enter a Updated Section Title" />
          </div>
        </Modal>


        <Drawer
        title={`Update ${updatedToDoListTitle}`}
        placement={'right'}
        width={500}
        onClose={closeUpdateToDoList}
        visible={showToDoListUpdate}
        extra={
          <Space>
            <Button onClick={closeUpdateToDoList}>Cancel</Button>

          </Space>
        }
      >
        <div className="toDoList__addNewTask">
          <div className="formGroup">
            <label>Task Title</label>
            <Input
              placeholder="Enter Task Title"
              value={updatedToDoListTitle}
              allowClear
              onChange={(e)=>{setUpdatedToDoListTitle(e.target.value)}}
            />
          </div>

          <div className="formGroup">
            <label>Deadline</label>
            <DatePicker value={moment(updatedDeadline,'YYYY/MM/DD')} onChange={(date,dateString)=>{setUpdatedDeadline(dateString)}} />
          </div>

            <div className="formGroup">
              <label>Select Section</label>
              <Select
                showSearch
                placeholder="Select a To Do Section"
                optionFilterProp="children"
                value={updatedSection}
                onChange={handleUpdateToDoListSection}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
            {allSections.map((item)=>{
              return <Option value={item._id} key={item._id}>{item.title}</Option>
            })}
          </Select>
            </div>
          <div className="formGroup">
            <label>Description</label>
            <Input.TextArea
              rows={4}
              placeholder="Please enter task details"
              maxLength={255}
              value={updatedDescription}
              onChange={(e)=>{setUpdatedDescription(e.target.value)}}
              allowClear
            />
          </div>

          <div>
          <Radio.Group onChange={(e)=>{setIsUpdateIsCompleted(e.target.value)}} value={updateIsCompleted}>
            <Radio value={true}>Completed</Radio>
            <Radio value={false}>Not Completed</Radio>
          </Radio.Group>
          </div>

        </div>
        <Button type="primary" onClick={updateTaskNow} disabled={!updatedSection}>
          Update Now
        </Button>
      </Drawer>



    </div>
  );
}

export default ToDoList;
