import React, {useState, useEffect} from 'react';
import {useParams,Link} from 'react-router-dom';
import {message,Breadcrumb,Tag} from 'antd';
import EventsServices from '../../../services/events.services';
import styles from './AdminEvents.module.css';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
function ViewEvent () {
    const {user: currentUser} = useSelector (state => state.auth);
  const [eventDetails, setEventDetails] = useState ('');
  const params = useParams ();

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  useEffect (
    () => {
      let isMounted = true;

        const getSingleEvent = async ()=>{
            try{
                const response = await EventsServices.getSingleEvent({id:params.id});
                // console.log(response.data);
                if(response.data){
                    setEventDetails(response.data);
                }
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message :'something went wrong!');
            }
        }

      if(isMounted){
        getSingleEvent();
      }

      return ()=>{
          isMounted = false;
      }
    },
    [params]
  );
  return(<div className={styles.eventDetails__card}>
    <h2>Event Details</h2>
    <Breadcrumb>
        <Breadcrumb.Item>
          {' '}<Link to="/profile">Profile</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {' '}<Link to="/profile/all-events">All Events</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Event Details
        </Breadcrumb.Item>
    </Breadcrumb>
      {eventDetails.bannerImg &&
        <div className={styles.eventCardImg}>
          <img src={eventDetails.bannerImg} alt="banner" />
       </div>
      }
       
        <table>
            <tr>
                <th>Created At</th>
                <td>{new Date(eventDetails?.createdAt).toUTCString()}</td>
            </tr>
            <tr>
                <th>Title</th>
                <td>{eventDetails?.title}</td>
            </tr>
            <tr>
                <th>Event Date</th>
                <td>{eventDetails?.eventDate}</td>
            </tr>
            <tr>
                <th>Description</th>
                <td>{eventDetails?.description}</td>
            </tr>
            <tr>
                <th>Event Link</th>
                <td>{eventDetails?.link}</td>
            </tr>
            <tr>
                <th>Disabled</th>
                <th><Tag color={eventDetails?.isDisabled ? 'red' : "cyan"}>{eventDetails?.isDisabled ? 'Yes' :'No'}</Tag></th>
            </tr>
            <tr>
                <th>Attendees</th>
                <th>
                  {eventDetails?.attendees?.map(user=>{
                    return <Link style={{marginRight:'10px'}} to={`/profile/manage-users/view/${user._id}`}><b>{user.username ? user.username : user.email}</b></Link>
                  })}
                </th>
            </tr>
        </table>

  </div>);
}

export default ViewEvent;
