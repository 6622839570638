import { Spin,Modal,message,Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import './PublicPayment.css';
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js';
import paymentService from '../../services/payment.service';
import {history} from '../../helpers/history';
import codersartsLogo from '../../assets/codersartsLogo.png';
import GoogleServices from '../../services/google.services';


const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        marginTop:'2%',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };


  


const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

function PublicPayment() {
    const [name,setName] = useState(null);
    const [email,setEmail] = useState(null);
    const [amount,setAmount] = useState(null);
    const [address,setAddress] = useState(null);
    const [country,setCountry] = useState(null);
    const [state,setState] = useState(null);
    const [city,setCity] = useState(null);
    const [pinCode,setPinCode] = useState(null);
    const [loading,setLoading] = useState(false);
    const [clientSecret,setClientSecret] = useState(null);
    const elements  = useElements();
    const stripe = useStripe();



    // const getCountriesList = async ()=>{
    //   try{
    //    const response = await paymentService.getAllCountries();
    //    console.log(response.data);
       
    //   }catch(err){
    //     console.log(err);
    //     message.error(err.message);
    //   }
    //  }
 
    //  useEffect(()=>{
    //    getCountriesList();
    //  },[])

    const getPaymentIntent = async ()=>{
      setLoading(true);
      let intentData = {
        amount:amount,
        name:name,
        address:address,
        email:email,
        country:country,
        city:city,
        state:state,
        pinCode:pinCode,
      }
        try{
            const result = await paymentService.createPublicPaymentIntent(intentData);
            // console.log(result.data.clientSecret);
            handlePayment(result.data.clientSecret);
            setClientSecret(result.data.clientSecret);
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : "Something went Wrong !");
        }
    }



    // useEffect(()=>{
    //     getPaymentIntent();
    //     console.log(stripe);
    // },[amount])

    const  sendPaymentSuccessMail = async (transactionId)=>{
      try{
        const data ={
          To:email,
          Subject:"Payment Sucessful to Codersarts",
          Write:`Your Payment of <b>${amount} USD </b> has been Received Successfully with the transaction Id <b> ${transactionId}</b> <br /> Thank You For Choosing Codersarts!`,
          attachment:false,
  
      }
      const response = await GoogleServices.sendmail(data);
      if(response){
        message.success("Successfully Sent the Mail !");
      }

      }catch(err){
        message.error(err.message ? err.message : "Couldn't Send You Mail!")
      }
  
    }

    const handlePayment = async (clientSecret)=>{
      let response = null;
      let transactionId = null;
        setLoading(true);

        let finalData = {
           name:name,
           email:email,
           country:country,
           paymentMethod:'Stripe',
           amount:amount,
           paymentIntent:null,
           currency:null,
           status:null
        }


        try{
            if(!clientSecret){
                return;
            }
             const result =  await stripe.confirmCardPayment(
                 clientSecret,{
                 payment_method:{
                   card: elements.getElement(CardElement),
                     billing_details: {
                         name: name,
                         address: {
                          line1: address ? address : 'test',
                          postal_code: pinCode ? pinCode : '111111',
                          city: city ? city : 'test',
                          state: state ? state : 'test',
                          country: country ? country : 'test',
                        }
                       },
                 }}
             )
             if(result){
                 setLoading(false);
             }
             const {paymentIntent} = result;
             const {error} = result;
             if(error){
               message.error(error.message);
             }
         
            //  console.log("RESULT  AFTER PAYMENT : ",result);
            //  console.log("PAYMENT INTENT : ",paymentIntent);
             if(paymentIntent && paymentIntent.status==='succeeded'){
                //  console.log("success!");
                 successModal();
                 finalData.paymentIntent = paymentIntent;
                 finalData.status = paymentIntent.status;
                 finalData.currency = paymentIntent.currency;
                 transactionId = paymentIntent.id;
                  response = await paymentService.createPublicPayment(finalData);
                //  console.log(response.data);
             }
         }catch(err){
             setLoading(false);
             errorModal();
             console.log(err);
         }

         if(response){
          sendPaymentSuccessMail(transactionId);
         }

    }


    function successModal() {
        let secondsToGo = 5;
        const modal = Modal.success({
          title: 'Payment is Successful and Confirmation is sent to your email!',
          content: `You will be Redirected to login page in ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: `You will be Redirected to login page in  ${secondsToGo} second.`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
          history.push('/auth/signin');
        }, secondsToGo * 1000);
      }

      function errorModal() {
        let secondsToGo = 5;
        const modal = Modal.error({
          title: 'Something Went Wrong !',
          content: `Please Try Again in ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: `Please Try Again in  ${secondsToGo} second.`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
        }, secondsToGo * 1000);
      }
      

   


    return (
        <div className="publicPayment">
            <div className="paymentItems">
                <div className="greenColorPallete">
                    <div>
                        <h4 className="green-heading">Pay with Codersarts</h4>
                        <div className="contact-details-section">
                        <div className="contact-item">
                           <div>
                           <h6 style={{color:'white'}}><b>Contact Us:</b></h6>
                            <hr style={{color:'white',width:'100px',height:'3px'}} />
                            <div className="d-flex">
                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                <span style={{marginLeft:'8%',marginTop:'-5%',marginBottom:'5%'}}>contact@codersarts.com</span>
                            </div>
                            <div style={{width:'100%'}}>
                                <i class="fa fa-phone" aria-hidden="true"></i>
                                <span style={{marginLeft:'5%'}}>7292007989</span>
                            </div>
                           </div>

                           <div className="payment-logo-area">
                              <img src={codersartsLogo} alt="codersarts" className="codersartsLogo" />
                            </div>
                        </div>


                        <div style={{color:'white',marginTop:'4%'}} className="termsAndCondition">
                            <h6 style={{color:'white'}}><b>Terms And Condition</b></h6>
                            <hr style={{color:'white',width:'170px',height:'3px'}} />
                            Codersarts is product for Sofstack Solution Pvt Ltd. <br />Read more:<br />
                            <a className="terms-link" href="https://www.codersarts.com/terms-of-service" target="_blank">
                            https://www.codersarts.com/terms-of-service
                            </a>
                        </div>
                        </div>
                      

                    </div>
                </div>

                <div className="form-section">
                    <div>
                    <h4 className="payment-heading">Pay with Codersarts</h4>
                        <form>
                        <div className="form-group ">
                        <label>Card Details</label>
                        <div style={{display: 'flex'}}>
                          <div style={{flexBasis: '100%'}}>
                            <CardElement options={CARD_ELEMENT_OPTIONS}/>
                          </div>
                        </div>
                        </div>

                        <div className="form-group">
                            <label>Amount</label>
                            <input type="number" placeholder="Enter Amount in USD" 
                            className="form-control"  value={amount} 
                            name="name" onChange={(e)=>{setAmount(e.target.value)}}/>
                        </div>
                          
                           <div className="form-group">
                                <label>Name</label>
                                <input  placeholder="Enter Your Full Name" type="text" className="form-control" value={name} name="name" onChange={(e)=>{setName(e.target.value)}}/>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" placeholder="example@codersarts.com" className="form-control" value={email} name="email" onChange={(e)=>{setEmail(e.target.value)}}/>
                            </div>
                         
                            <div className="form-group">
                              <div>
                              <label className="mt-2">Billing Details</label>
                              <textarea type="text" 
                              placeholder="Address" 
                              className="form-control m-2" value={address} onChange={e=>setAddress(e.target.value)}
                              style={{height:'6rem',width:'35vw',resize:'none'}}/>
                            <div className="d-flex">
                            <div >
                              <input type="text" style={{width:'17vw'}} value={city} onChange={e=>setCity(e.target.value)} className="form-control m-2" placeholder="City" />
                              <input type="text" style={{width:'17vw'}}  value={state} onChange={e=>setState(e.target.value)} className="form-control m-2" placeholder="State" />
                            </div>
                              <div>
                              <input type="text" style={{width:'17vw'}} maxLength={10} value={pinCode} onChange={e=>setPinCode(e.target.value)} className="form-control m-2" placeholder="Postal Code" />
                              <Tooltip title="Enter 2 ISO Characters for Countries">
                              <input type="text" style={{width:'17vw'}} value={country} onChange={e=>setCountry(e.target.value)} className="form-control m-2" placeholder="Country Code Like US,AU,GB" />
                              </Tooltip>
                              </div>
                              {country && (country.toLowerCase()==='in' || country.toLowerCase()==="india") && message.info("Indian Payments Not Supported Please Contact Administrator!")}
                            </div>

                              </div>
                            
                            </div>

                            <a href="https://www.nationsonline.org/oneworld/country_code_list.htm" id="country-code-link" target="_blank">Need More Country Codes ? </a>


                            <div>
                                <button type="button" disabled={country===null || 
                                amount===null || name===null || email===null || 
                                 address===null || state===null ||
                                 country===null || city===null || pinCode===null
                                 || (country.toLowerCase()==='in' || country.toLowerCase()==="india")
                                 } 
                                className="pay-btn" style={{width:'35vw'}} onClick={getPaymentIntent}>
                                 {loading ? <Spin indicator={spinIcon} /> : 'Pay Now'}
                                 </button>
                            </div>
                           
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PublicPayment
