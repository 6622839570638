import React, { useEffect,useState } from 'react'
import orderService from '../../services/order.service';
import '../Profile/SingleBookingDetails/SingleBookingDetails.css';
import {message,Button,Breadcrumb} from 'antd';
import {useSelector} from 'react-redux';
import {history} from '../../helpers/history';
import {Link} from 'react-router-dom';


function SingleServiceDetails({match}) {
    const [serviceDetails,setServiceDetails] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);


    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getServiceDetails = async ()=>{
        try{
            const response = await orderService.getServiceById(match.params.slug);
            // console.log("Service details : ",response.data);
            setServiceDetails(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch service details!');
        }
    }
    useEffect(()=>{
        getServiceDetails();
    },[match.params.slug])
    return (
        <div className='bookingDetails'>
        <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="/profile/all-services">All Services</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/service-details/${match.params.slug}`}>{serviceDetails && serviceDetails[0] && serviceDetails[0].name ? serviceDetails[0].name : match.params.slug}</Link></Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="mt-2">Service Details</h1>
        <hr />
            <div className='card'>
                {/* <div className='details-item'>
                    <span className='bookingLabel'>Id : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].booking ? bookingDetails[0]._id : "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Created At : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].createdAt ?  bookingDetails[0].createdAt: "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Name : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].booking ? bookingDetails[0].booking[0].name : "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Details : </span>
                    <span><Tag color={'cyan'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].details ? bookingDetails[0].details : "N/A"}</Tag></span>
                </div>

                <div className='details-item'>
                    <span className='bookingLabel'>Meeting Date : </span>
                    <span><Tag color={'red'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].date ? bookingDetails[0].date : "N/A"}</Tag></span>
                </div>

                <div className='details-item'>
                    <span className='bookingLabel'>Meeting Timezone : </span>
                    <span><Tag color={'blue'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].timezone ? bookingDetails[0].timezone : "N/A"}</Tag></span>
                </div> */}


                <table>
                <tr>
                    <th>Id : </th>
                    <td>{ serviceDetails && serviceDetails[0] && serviceDetails[0]._id ? serviceDetails[0]._id  : "N/A"}</td>
                </tr>
                <tr>
                    <th>Created At : </th>
                    <td>{serviceDetails && serviceDetails[0] && serviceDetails[0].createdAt ? serviceDetails[0].createdAt : "N/A"}</td>
                </tr>
                <tr>
                    <th>Name : </th>
                    <td>{serviceDetails && serviceDetails[0] && serviceDetails[0].name ? serviceDetails[0].name  : "N/A"}</td>
                </tr>

                <tr>
                    <th>Technologies : </th>
                    <td>
                        {serviceDetails && serviceDetails[0] && serviceDetails[0].technologies && 
                            serviceDetails[0].technologies.map((item)=>{
                                return <span className="badge mx-2" >{item}</span>
                            })
                        }
                    </td>
                </tr>

                {serviceDetails && serviceDetails[0] && serviceDetails[0].image  &&
                <tr >
                    <th>Action : </th>
                    <td><Button type={'primary'}><a href={serviceDetails && serviceDetails[0] && serviceDetails[0].image ? serviceDetails[0].image : '/'} target={'_blank'} rel="noreferrer"> <b>View Image</b> </a></Button></td>
                </tr>
                }
                </table>
                <div >
                    <span><b>Details :</b> </span>
                    <span className='detailsWidth'> {serviceDetails && serviceDetails[0] && serviceDetails[0].details ? serviceDetails[0].details : "N/A"}</span>
                </div>

                
                
            </div>
        </div>
    )
}

export default SingleServiceDetails
