import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../services/order.service';
import './AssignOrderToEmployee.css';
import { Breadcrumb} from 'antd';
import { Link } from 'react-router-dom';
import Message from '../../Message/Message';



const AssignOrderToEmployee = ({match})=>{
    const [allAssignedOrders,setAllAssignedOrders] = useState([]);
    const [assignedToEmployee,setAssignedToEmployee] = useState("");
    const [reporting,setReporting] = useState("");
    const [extraNotes,setExtraNotes] = useState("");
    const [successMessage,setSuccessMessage] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    // console.log(today);
    

   const getOrderForUser = async (id)=>{
        // console.log("ID : ",id);
        const data = [];
        await OrderService.getSpecificOrder(id).then((response)=>{
            // console.log("ORDER ASSIGNED : ",response.data);
            if(response.data){
                setAssignedToEmployee(response.data[0].name);
                response.data.forEach((order,index)=>{
                    let updatedField = {
                        key:index,
                        title:order.name,
                        id:order._id,
                        category:order.category,
                        deadline:order.deadline,
                        booking:order.bookingSession ===true ? "Yes" : "No",
                        documentation:order.documentation  ===true ? "Yes" : "No",
                        details:order.details,
                        status:order.status ? order.status: "---",
                        assignedTo:order.assignedTo[0].username,
                    }
    
                    data.push(updatedField);
                })
                setAllAssignedOrders(data);
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    useEffect(()=>{
        if(currentUser){
            getOrderForUser(currentUser.id);
        }
       
    },[])

    useEffect(()=>{
        if(successMessage){
            setSuccessMessage(false);
        }
    })

    const assign = (e)=>{
        e.preventDefault();
        // console.log(assignedToEmployee)
        const order = allAssignedOrders.find((item)=>{
            return item.title===assignedToEmployee 
        });

        let now = new Date();
        // console.log(now);
        
        // console.log("ORDER TO ASSIGN : ",order);
        const data = {
            _id:order.id,
            assignedEmployee:match.params.slug,
            managerNote:extraNotes,
            employeeReporting:reporting,
            assignedAt:now
            
        }
        OrderService.updateOrder(data).then((response)=>{
            if(response){
                setSuccessMessage(true);
            }
        }).catch((err)=>{
            console.log(err);
        })
        
       
    }
    
    return(
        <div className="assignOrderToEmployee">
            <div>
                <Breadcrumb>
                <Breadcrumb.Item> <Link to="/profile/employee">My Employees</Link></Breadcrumb.Item>
                <Breadcrumb.Item> <Link to={`/profile/assign-order/${match.params.slug}`}>Assign Order</Link></Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <br />
            <h3>Assign Order</h3>
            <hr />
            <form>
           <div className="form-inline">
                <div className="form-group">
                        <label>Project To Assign : </label>
                        <select className="form-control" value={assignedToEmployee} onChange={(e)=>setAssignedToEmployee(e.target.value)}>
                            {allAssignedOrders.map(order=>{
                                return <option key={order.id}>{order.title}</option>
                            })}
                        </select>    
                </div>
                <div className="form-group">
                    <label>Reporting Deadline : </label>
                        <input type="date" min={today} value={reporting} onChange={(e)=>{setReporting(e.target.value)}} className="form-control" />
                </div>
            
           </div>
           <div className="form-group">
            <label>Extra Notes : </label>
                <textarea className="form-control" value={extraNotes} onChange={(e)=>{setExtraNotes(e.target.value)}}>
                </textarea>
            </div>

            <div className="form-group">
            <button className="btn btn-outline-primary"
            onClick={(e)=>{assign(e)}}>Assign Project</button>
            </div>
            
            </form>

            {successMessage ? <Message type="success" message="Assigned Order Successfully" /> : null}
            

            
        </div>
    )
}

export default AssignOrderToEmployee;