import React from 'react';
import SiderDemo from './SideNav/SideNav';
import './ProfilePage.css'

const ProfilePage = ()=>{
    return(
    <div className="profile-page">
    <div>
        <SiderDemo />
    </div>
    </div>);
}

export default ProfilePage;