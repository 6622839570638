import React, {useState, useEffect} from 'react';
import {Table, Space, Tag, Modal, Button, Menu, Dropdown, message} from 'antd';
import {Link} from 'react-router-dom';
import paymentService from '../../../services/payment.service';
import orderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import {
  EyeOutlined,
  MoreOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import {history} from '../../../helpers/history';
import {useSelector} from 'react-redux';
import moment from 'moment';

const AllPublicPayments = () => {
  const [allPayments, setAllPayments] = useState ([]);
  const [paymentResponse, setPaymentResponse] = useState (null);
  const {user: currentUser} = useSelector (state => state.auth);
  const [filteredArr, setFilteredArr] = useState ([]);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        if (currentUser.roles.includes ('ROLE_SALES')) {
          return;
        }
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getPayments = async () => {
    try {
      const response = await paymentService.getAllPublicPayments ();
      console.log (response.data);
      setPaymentResponse (response.data);
      let data = [];
      response.data.map (d => {
        let finalDate = 'N/A';
        if (d.createdAt) {
          const date = new Date (d.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }

        let modifiedData = {
          id: d._id,
          date: finalDate,
          sortDate: d.createdAt,
          name: d.name,
          email: d.email,
          country: d.country,
          status: d.status,
          currency: d.currency,
          paymentMethod: d.paymentMethod ? d.paymentMethod : null,
          paymentIntent: d.paymentIntent ? d.paymentIntent : null,
          transactionId: d.paymentIntent ? d.paymentIntent.id : 'N/A',
          amount: d.amount
            ? d.amount
            : d.paymentIntent ? d.paymentIntent.amount / 100 : 'N/A',
        };

        // console.log("PAYMENT INTENT : ",modifiedData);

        data.push (modifiedData);
      });

      setAllPayments (data);
    } catch (err) {
      console.log (err);
      message.error (err.message);
    }
  };

  useEffect (() => {
    getPayments ();
  }, []);

  const recyclePayment = async id => {
    let recycledPayment = paymentResponse.filter (item => {
      return item._id === id;
    });
    // console.log("RECYLED PAYMENT : ",recycledPayment);
    // let modifiedRecycleData = recycledPayment[0];
    //     if(recycledPayment[0].user && recycledPayment[0].user[0]){
    //         modifiedRecycleData.user = [recycledPayment[0].user[0]._id];
    //     }
    // //  console.log("Recyled Payment : ",modifiedRecycleData);
    let finalRecycledData = {
      data: recycledPayment[0],
      recycleType: 'PublicPayment',
    };

    try {
      const response = await orderService.recycleData (finalRecycledData);
      // console.log(response);
      if (response.status == 200) {
        message.success (
          `${recycledPayment[0].name ? recycledPayment[0].name : 'Item'} Added to Recyle Bin!`
        );
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : "Couldn't Recycle The Item !");
    }

    getPayments ();
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Customer',
      dataIndex: 'name',
      key: 'customer',
    },
    {
      title: 'Amount Received',
      key: 'amountReceived',
      render: record => {
        return <b>{record.amount} {record.currency}</b>;
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: text => {
        return <Tag color={'cyan'}>{text}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu style={{marginLeft: '-25%'}}>
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link
                    to={`/profile/single-public-payment-details/${record.id}`}
                    target="_blank"
                  />
                  {' '}
                  View Details
                </Menu.Item>
                {currentUser.roles.includes ('ROLE_SALES')
                  ? null
                  : <Menu.Item
                      key="2"
                      danger
                      onClick={() => {
                        confirmRecyclePayment (record.id);
                      }}
                    >
                      <DeleteOutlined /> Recycle
                    </Menu.Item>}
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>

        </Space>
      ),
    },
  ];
  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allPayments.length > 0) {
        filteredData = allPayments.filter (payment => {
          //return payment.name && payment.name.toLowerCase().trim() === item.toLowerCase().trim();
          return (
            payment.transactionId &&
            (payment.transactionId.toLowerCase ().match (item.toLowerCase ()) ||
              (payment.email &&
                payment.email.toLowerCase ().match (item.toLowerCase ())) ||
              (payment.name &&
                payment.name.toLowerCase ().match (item.toLowerCase ())))
          );
        });
        //   console.log("SEARCH RESULT : ",filteredData);
      }
    }

    setFilteredArr (filteredData);
  };

  const exportAllPayments = async () => {
    try {
      const result = await UserService.exportPublicPaymentsToCsv ();
      // console.log(result);
      if (result.data) {
        message.success ('Exported All Payments To CSV file !');
      }
    } catch (err) {
      message.error (err.message ? err.message : 'Unable To Export Payments !');
    }
  };

  const confirmExport = () => {
    Modal.confirm ({
      title: 'Confirm Export',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to export all Payments Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        exportAllPayments ();
      },
    });
  };

  const confirmRecyclePayment = id => {
    Modal.confirm ({
      title: 'Confirm Recycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Recycle This Payments Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        recyclePayment (id);
      },
    });
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div><h3>All Public Payments</h3></div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Transaction Id,Client name or Email"
            style={{width: '30vw', marginBottom: '3%', marginRight: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>

        {currentUser.roles.includes ('ROLE_SALES')
          ? null
          : <Button type={'primary'} onClick={confirmExport}>
              <UploadOutlined /> Export All Payments
            </Button>}
      </div>

      <Table
        dataSource={filteredArr.length > 0 ? filteredArr : allPayments}
        columns={columns}
      />
    </div>
  );
};

export default AllPublicPayments;
