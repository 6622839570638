import React,{useState,useEffect} from 'react';
import { Table, Modal, Button,Tooltip } from 'antd';
import { CloudUploadOutlined,CopyTwoTone,DeleteOutlined,ExclamationCircleOutlined } from "@ant-design/icons";
import orderService from '../../services/order.service';
import Message from '../Message/Message';
import { Link } from "react-router-dom";
import './FileShare.css';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';

const {confirm} = Modal;


const FileShare = ()=>{

        const [visible, setVisible] = useState(false);
        const [confirmLoading, setConfirmLoading] = useState(false);
        const [allOrders,setAllOrders] = useState(null);
        const [order,setOrder]  = useState(null);
        const [files,setFiles] = useState(null);
        const [allDocs,setAllDocs] = useState(null);
        const [successMessage,setSuccessMessage] = useState(false);
        const [copySuccessMessage,setCopySuccessMessage] = useState(false);
        const [deleteSuccessMessage,setDeleteSuccessMessage] = useState(false);
        const [visibleOrder,setVisibleOrder] = useState(null);
        const [filteredArr,setFilteredArr] = useState([]);

        const { user: currentUser } = useSelector((state) => state.auth);

        useEffect(()=>{
            if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
                if(currentUser.roles.includes('ROLE_SALES')){
                    return;
                }
                history.push('/profile');
            }
        },[currentUser])
        const getAllOrders = async ()=>{
            try{
                const res=await orderService.getAllOrders();
                // console.log(res.data);
                
                setAllOrders(res.data);
            }catch(err){
                console.log(err);
            }
            
        }

        const handleSearchChange = (item)=>{
            let filteredData = []
            if(item.length > 0){
                if(allDocs.length >0){
                    filteredData= allDocs.filter((doc)=>{
                       // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                       return doc.name && doc.name.toLowerCase().match(item.toLowerCase())
                    });
                  //   console.log("SEARCH RESULT : ",filteredData);
                   
                  }
            }
    
            setFilteredArr(filteredData);
        }

        const getAllDocs = async ()=>{
            const fetchedDocs = []
            let finalDate = "N/A";
            try{
                const res=await orderService.getAllDocs();
                // console.log(res.data);
                res.data.map((d,index)=>{
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                   finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                    if(d.assignedOrder.length >0){
                        fetchedDocs.push({
                            sortDate:d.createdAt,key:index+1,
                            id:d._id,
                            fileLink:d.fileLink,
                            category:d.category ? d.category : 'N/A',
                            name:d.name,
                            order:d.assignedOrder[0].name,
                            created:finalDate
                        })
                    }
                })
                // console.log("Fetched Data ",fetchedDocs);
                setAllDocs(fetchedDocs);
            }catch(err){
                console.log(err);
            }
        }

        useEffect(()=>{
            if(successMessage ){
                setSuccessMessage(false);
            }
            if(copySuccessMessage){
                setCopySuccessMessage(false);
            }
            if(deleteSuccessMessage){
                setDeleteSuccessMessage(false);
            }
        })

        useEffect(()=>{
            getAllOrders();
            getAllDocs();
        },[successMessage,deleteSuccessMessage])
        const showModal = () => {
            setVisible(true);
          };
        
          const handleConfirmUpload =  () => {
            setConfirmLoading(true);
            if(order && files){
                
                var formData = new FormData();
                formData.append('category',"sourceCode")
                formData.append('orderId',order);
                formData.append('myfile',files);
                // for (var key of formData.entries()) {
                //     // console.log(key[0] + ', ' + key[1]);
                // }
                orderService.postOrderDoc(formData).then(response=>{
                    setConfirmLoading(false);
                    setVisible(false);
                    setVisibleOrder(null);
                    setSuccessMessage(true);
                    // console.log(response.data);
                }).catch(err=>{
                    setConfirmLoading(false);
                    setVisible(false);
                    console.log(err);
                })
            }else{
                setConfirmLoading(false);
                setVisible(false)
            }
            
          };

        //   const handleGetLink = (link)=>{
        //     window.location.href = link;
        //   }

          const handleCopyLink = (link)=>{
            navigator.clipboard.writeText(link);
            setCopySuccessMessage(true);
        }


        const handleCancel = () => {
            // console.log('Clicked cancel button');
            setVisible(false);
          };

            const columns = [
            {
                title: 'Created At',
                dataIndex: 'created',
                key: 'created',
                sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
                defaultSortOrder: 'descend',
                width:'20%'
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width:'20%'
               
            },
            {
                title: 'Order',
                dataIndex: 'order',
                key: 'order',
                width:'25%'
            },

            {
                title: 'Category',
                dataIndex: 'category',
                key: 'category',
                
            },

            {
                title: 'Action',
                key: 'action',
                width:'40%',
                render: (record) => (
                <>
               <Link to={{ pathname: record.fileLink }} target="_blank">
                    <Button type="primary"  size="middle">
                    View Doc
                    </Button>
                </Link>
                <Button  size="middle" style={{marginLeft:'5%'}} onClick={()=>handleCopyLink(record.fileLink)}>
                   <CopyTwoTone /> Copy Link
                </Button>
                {currentUser.roles.includes('ROLE_SALES') 
                ? null
                :
                <Tooltip title="Delete ?" color='red' key='red'>
                <DeleteOutlined style={{fontSize:'1.5rem',color:'red',marginLeft:'5%',marginTop:'-5%'}} onClick={()=>showDeleteConfirm(record.id)} />
                </Tooltip>
                }
               </>
                ),
            },
            ];

           

    const handleUpload = ()=>{
    
        showModal();
    }

    const selectedOrder = (o)=>{
        // if(e.target.value.toLowerCase ==="please select order"){
        //     return;
        // }
        setVisibleOrder(o.name);
        // console.log("ID : ",o);
        setOrder(o._id);
    }

    const handleFileChange = (e)=>{
        setFiles(e.target.files[0]);
    }
    const deleteDoc = async (id)=>{
        try{
            const res = await orderService.deleteDoc(id);
            // console.log(res);
            setDeleteSuccessMessage(true);
        }catch(err){
            console.log(err);
        }
    }

    function showDeleteConfirm(id) {
        confirm({
          title: 'Are you sure delete this item?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            deleteDoc(id);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

    return(
        <div>
            <div className="file-share-header" style={{display:'flex',justifyContent:'space-between'}}>
                <h2>All Files</h2>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'10%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}} /> 
                </div>
                <div>
                    <Button className="file-upload-btn"
                    onClick={handleUpload}
                    >

                    <CloudUploadOutlined />
                    Upload File
                    </Button>

                    <Modal
                        title="Upload Files"
                        visible={visible}
                        onOk={handleConfirmUpload}
                        confirmLoading={confirmLoading}
                        onCancel={handleCancel}
                        closable={true}
                        okText="Upload"
                        
                    >
                        <div style={{fontStyle:'normal'}}>
                            <form>
                                {/* <select className="form-control mb-5" onChange={selectedOrder}>
                                    <option selected>Please Select Order</option>
                                    {allOrders && allOrders.map(o=>{
                                        return(<div>
                                        <option value={o._id} key={o._id}>{o.name} </option>
                                        <p>( {o._id} )</p>
                                        </div>)
                                    })}
                                </select> */}
                                <div className="dropdown">
                                    <button type="button" style={{width:'100%',marginBottom:'6%'}} className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                        {visibleOrder ? visibleOrder : 'Please Select Order '}
                                    </button>
                                    <div className="dropdown-menu" style={{overflowY:'scroll',width:'100%',height:'40vh'}}>
                                    {allOrders && allOrders.map(o=>{
                                        return(<div className="dropdown-item" onClick={()=>{selectedOrder(o)}}>
                                        <b value={o._id} key={o._id}>{o.name} </b>
                                        <p>( {o._id} )</p>
                                        </div>)
                                    })}
                                    {/* <a className="dropdown-item" href="/">Link 1</a>
                                    <a className="dropdown-item" href="/">Link 2</a>
                                    <a className="dropdown-item" href="/">Link 3</a> */}
                                    </div>
                                </div> 
                                <input type="file" onChange={handleFileChange} className="form-control" />
                            </form>
                        </div>
                    </Modal>

                </div>
            </div>
           <Table columns={columns} dataSource={filteredArr.length > 0 ?  filteredArr : allDocs} />
           {successMessage ? <Message type="success" message="Uploaded Successfully" /> : null} 
           {copySuccessMessage ? <Message type="success" message="Copied To Clipboard !" /> : null}
           {deleteSuccessMessage ? <Message type="success" message="Deleted Successfully !" /> : null} 
        </div>
    )
}


export default FileShare;