import axios from 'axios';
import authHeader from './auth-header';

// var  API_URL = "http://3.7.156.63:3000/api/";

// var  API_URL = "http://3.7.156.63/api/";
// var API_URL = 'http://localhost:3000/api/';
var API_URL = 'https://dashboard.codersarts.com/api/';

// if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70:8080/api/";
// }

const postOrderDoc = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'upload-doc', data, {headers: authHeader ()});
};

const postMultipleDoc = data => {
  // console.log("MULTIPLE UPLOAD DATA RECEIVED : ",data)
  return axios.post (API_URL + 's3-multiple-upload', data, {
    headers: authHeader (),
  });
};

const createPublicOrder = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'create-public-orders', data);
};

const postDocumentation = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'post-documents', data, {
    headers: authHeader (),
  });
};

const getAllDocs = () => {
  return axios.get (API_URL + 'getDocs', {headers: authHeader ()});
};

const getAllPublicOrders = () => {
  return axios.get (API_URL + 'get-public-orders', {headers: authHeader ()});
};

const getAwsInfo = () => {
  return axios.get (API_URL + 'get-aws-info', {headers: authHeader ()});
};

const getAllOrders = () => {
  return axios.get (API_URL + 'getAllOrders', {headers: authHeader ()});
};

const updateOrder = async data => {
  // console.log("sending!")

  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }

    // console.log(data);
    return axios
      .post (API_URL + 'update-order', data, {headers: authHeader ()})
      .then (response => {
        // console.log(response.data)
        return response.data;
      });
  }
};

const createOrder = async data => {
  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }
  }

  // console.log(data);
  return axios
    .post (API_URL + 'create-order', data, {headers: authHeader ()})
    .then (response => {
      // console.log(response.data)
      return response.data;
    })
    .catch (err => {
      console.log (err);
    });
};

const updateStatus = async (id, status) => {
  const data = {
    id: id,
    status: status,
  };

  // console.log(data);
  return axios
    .post (API_URL + 'update-status', data, {headers: authHeader ()})
    .then (response => {
      // console.log(response.data)
      return response.data;
    })
    .catch (err => {
      console.log (err);
    });
};

const getSingleOrder = id => {
  return axios.post (
    API_URL + 'singleOrder',
    {_id: id},
    {headers: authHeader ()}
  );
};

const getSpecificOrder = assignedId => {
  return axios.post (
    API_URL + 'specificOrder',
    {_id: assignedId},
    {headers: authHeader ()}
  );
};

const getCreatedOrder = createdId => {
  return axios.post (
    API_URL + 'createdOrder',
    {_id: createdId},
    {headers: authHeader ()}
  );
};

const getEmployeeOrders = assignedId => {
  return axios.post (
    API_URL + 'employeeOrders',
    {_id: assignedId},
    {headers: authHeader ()}
  );
};

const OrderFeedback = async data => {
  return await axios
    .post (API_URL + 'userFeedback', data, {headers: authHeader ()})
    .then (response => {
      // console.log(response)
      return response.data;
    });
};

const getNewOrders = () => {
  return axios.get (API_URL + 'get-new-orders', {headers: authHeader ()});
};

const getAllFeedback = () => {
  return axios.get (API_URL + 'getAllFeedback', {headers: authHeader ()});
};

const deleteDoc = docId => {
  // console.log("id : ",docId)
  return axios.post (
    API_URL + 'delete-doc',
    {docId: docId},
    {headers: authHeader ()}
  );
};

const getSinglePublicOrder = id => {
  // console.log("id : ",id)
  return axios.post (
    API_URL + 'get-single-public-order',
    {id: id},
    {headers: authHeader ()}
  );
};

const setOrderPayment = data => {
  // console.log("final payment data : ",data)
  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }
    return axios.post (API_URL + 'order-payment', data, {
      headers: authHeader (),
    });
  }
};

const getAllPayments = () => {
  return axios.get (API_URL + 'get-all-payments', {headers: authHeader ()});
};

const getSinglePaymentDetails = id => {
  return axios.post (
    API_URL + 'get-single-payment',
    {id: id},
    {headers: authHeader ()}
  );
};

const getPaymentByOrderId = id => {
  return axios.post (
    API_URL + 'get-payment-by-order-id',
    {orderId: id},
    {headers: authHeader ()}
  );
};

const makeRazorpayPayment = data => {
  return axios.post (API_URL + 'make-razorpay-payment', data, {
    headers: authHeader (),
  });
};

// const captureRazorpayPayment = (data)=>{
//   return axios.post(API_URL+`capture-razorpay-payment/${data.paymentId}`,data,{headers: authHeader() });
// }

const getRazorpayConfig = () => {
  return axios.get (API_URL + 'get-razorpay-config', {headers: authHeader ()});
};

const getAllLogs = () => {
  return axios.get (API_URL + 'get-all-logs', {headers: authHeader ()});
};

const recycleData = data => {
  // console.log("final payment data : ",data);
  return axios.post (API_URL + 'recycle-data', data, {headers: authHeader ()});
};

const getAllRecyleData = () => {
  return axios.get (API_URL + 'all-recycle-data', {headers: authHeader ()});
};

const deleteRecycleItem = id => {
  return axios.post (
    API_URL + 'delete-recycle-item',
    {id: id},
    {headers: authHeader ()}
  );
};

const restoreRecycleItem = (data, recycleType) => {
  // console.log("DATA : ",data);
  // console.log("type: ",recycleType)
  return axios.post (
    API_URL + 'restoreRecycleItem',
    {recycleData: data, recycleType: recycleType},
    {headers: authHeader ()}
  );
};

const getCustomerOrder = id => {
  return axios.post (
    API_URL + 'getCustomerOrder',
    {id: id},
    {headers: authHeader ()}
  );
};

const getAllOrderCategory = () => {
  return axios.get (API_URL + 'allOrderCategory', {headers: authHeader ()});
};

const createOrderCategory = category => {
  return axios.post (
    API_URL + 'addOrderCategory',
    {newCategory: category},
    {headers: authHeader ()}
  );
};

const createOrderInvoice = data => {
  return axios.post (API_URL + 'createOrderInvoice', data, {
    headers: authHeader (),
  });
};

const downloadInvoice = () => {
  return axios.get (API_URL + 'downloadInvoice', {headers: authHeader ()});
};

const getSpecificDoc = id => {
  return axios.post (
    API_URL + 'getSpecificDoc',
    {orderId: id},
    {headers: authHeader ()}
  );
};

const getSingleDocForUser = (userId, orderId) => {
  return axios.post (
    API_URL + 'getSingleDocForUser',
    {orderId: orderId, userId: userId},
    {headers: authHeader ()}
  );
};

const getCustomerPayments = userId => {
  return axios.post (
    API_URL + 'getCustomerPayments',
    {userId: userId},
    {headers: authHeader ()}
  );
};

const uploadFilesToDrive = data => {
  return axios.post (API_URL + 'uploadFilesToDrive', data);
};

const createMailRecord = data => {
  return axios.post (API_URL + 'createMailRecord', data, {
    headers: authHeader (),
  });
};

const getDriveFiles = () => {
  return axios.get (API_URL + 'getAllDriveFiles');
};

const getAllMailRecords = () => {
  return axios.get (API_URL + 'getAllMailRecords', {headers: authHeader ()});
};

const createNewComment = data => {
  return axios.post (API_URL + 'createOrderComment', data, {
    headers: authHeader (),
  });
};

const getSpecificOrderComment = orderId => {
  return axios.post (
    API_URL + 'getOrderComments',
    {orderId: orderId},
    {headers: authHeader ()}
  );
};

const getSpecificOrderReply = orderId => {
  return axios.post (
    API_URL + 'getOrderReply',
    {orderId: orderId},
    {headers: authHeader ()}
  );
};

const createCommentReply = data => {
  return axios.post (API_URL + 'createCommentReply', data, {
    headers: authHeader (),
  });
};

const getEstimatedCounts = () => {
  return axios.get (API_URL + 'getEstimateOfDocuments', {
    headers: authHeader (),
  });
};

const createContactUsFormData = data => {
  return axios.post (API_URL + 'createContactUsData', data);
};

const getContactUsFormData = () => {
  return axios.get (API_URL + 'getContactUsData');
};

const getCustomerOrderCount = id => {
  return axios.post (
    API_URL + 'getCustomerOrderCount',
    {id: id},
    {headers: authHeader ()}
  );
};

const createNewSessions = data => {
  return axios.post (API_URL + 'createSessionsForBooking', data, {
    headers: authHeader (),
  });
};

const getAllSessions = () => {
  return axios.get (API_URL + 'getAllSessionsForBooking');
};

const getRecommendedSessionsAndServices = data => {
  return axios.post (
    API_URL + 'getRecommendedSessionsAndServices',
    {interest: data},
    {headers: authHeader ()}
  );
};

const createNewBooking = data => {
  return axios.post (API_URL + 'createNewBooking', data, {
    headers: authHeader (),
  });
};

const getAllBookings = () => {
  return axios.get (API_URL + 'getAllBookings', {headers: authHeader ()});
};

const getUserBookings = id => {
  return axios.post (
    API_URL + 'getUserBookings',
    {id: id},
    {headers: authHeader ()}
  );
};

const getBookingById = id => {
  return axios.post (
    API_URL + 'getBookingById',
    {id: id},
    {headers: authHeader ()}
  );
};

const createNewService = data => {
  return axios.post (API_URL + 'createNewServiceForHome', data, {
    headers: authHeader (),
  });
};

const getAllServicesForHome = () => {
  return axios.get (API_URL + 'getAllServicesForHome');
};

const getServiceById = id => {
  return axios.post (
    API_URL + 'getServiceById',
    {id: id},
    {headers: authHeader ()}
  );
};

const getSessionById = id => {
  return axios.post (
    API_URL + 'getSessionById',
    {id: id},
    {headers: authHeader ()}
  );
};

const updateSessionById = (id, data) => {
  return axios.post (
    API_URL + 'updateSessionById',
    {id: id, data: data},
    {headers: authHeader ()}
  );
};

const updateServiceById = (id, data) => {
  return axios.post (
    API_URL + 'updateServiceById',
    {id: id, data: data},
    {headers: authHeader ()}
  );
};

const deleteSessionById = id => {
  return axios.post (
    API_URL + 'deleteSessionById',
    {id: id},
    {headers: authHeader ()}
  );
};

const deleteServiceById = id => {
  return axios.post (
    API_URL + 'deleteServiceById',
    {id: id},
    {headers: authHeader ()}
  );
};

const deleteBookingById = id => {
  return axios.post (
    API_URL + 'deleteBookingById',
    {id: id},
    {headers: authHeader ()}
  );
};

const createNewSubscription = data => {
  return axios.post (API_URL + 'createNewSubscriptionType', data, {
    headers: authHeader (),
  });
};

const getAllSubscriptionCategories = () => {
  return axios.get (API_URL + 'getAllSubscriptionCategories');
};

const updateSubscriptionCategory = (id, data) => {
  return axios.post (
    API_URL + 'updateSubscriptionCategory',
    {id: id, data: data},
    {headers: authHeader ()}
  );
};

const deleteSubscriptionCategory = id => {
  return axios.post (
    API_URL + 'deleteSubscriptionCategory',
    {id: id},
    {headers: authHeader ()}
  );
};

const createSubscriptionRecord = data => {
  return axios.post (API_URL + 'createSubscriptionRecord', data, {
    headers: authHeader (),
  });
};

const getAllSubscriptionRecord = () => {
  return axios.get (API_URL + 'getAllSubscriptionRecord', {
    headers: authHeader (),
  });
};

const getSubscriptionById = id => {
  return axios.post (
    API_URL + 'getSubscriptionById',
    {id: id},
    {headers: authHeader ()}
  );
};

const getAllInvoiceDetails = () => {
  return axios.get (API_URL + 'getAllInvoiceDetails', {headers: authHeader ()});
};

const addInvoiceFormatDetails = data => {
  return axios.post (API_URL + 'addInvoiceFormatDetails', data, {
    headers: authHeader (),
  });
};

const getInvoiceFormatDetails = () => {
  return axios.get (API_URL + 'getInvoiceFormatDetails', {
    headers: authHeader (),
  });
};

const getSingleInvoiceDetails = id => {
  return axios.post (
    API_URL + 'getSingleInvoiceDetails',
    {id: id},
    {headers: authHeader ()}
  );
};

const isOrderInvoicePresent = id => {
  return axios.post (
    API_URL + 'isOrderInvoicePresent',
    {id: id},
    {headers: authHeader ()}
  );
};

const deleteDocById = id => {
  return axios.post (
    API_URL + 'deleteDocById',
    {id: id},
    {headers: authHeader ()}
  );
};

const updateBooking = (id, data) => {
  return axios.post (
    API_URL + 'updateBookingDetails',
    {id: id, data: data},
    {headers: authHeader ()}
  );
};

const createNewAssignmentHelp = data => {
  return axios.post (API_URL + 'create-new-assignment-help', data, {
    headers: authHeader (),
  });
};

const getAllAssignmentHelp = () => {
  return axios.get (API_URL + 'get-all-assignment-help', {
    headers: authHeader (),
  });
};

const getAssignmentRequestById = id => {
  return axios.post (
    API_URL + 'get-single-assignment-request',
    {id: id},
    {headers: authHeader ()}
  );
};

const getAllToDoSectionAndTask = userId => {
  return axios.post (
    API_URL + 'get-all-to-do-list-and-section',
    {userId: userId},
    {headers: authHeader ()}
  );
};

const createNewToDoTask = data => {
  return axios.post (API_URL + 'create-to-do-list', data, {
    headers: authHeader (),
  });
};

const createNewToDoSection = data => {
  return axios.post (API_URL + 'create-to-do-list-section', data, {
    headers: authHeader (),
  });
};

const updateToDoList = (userId, Id, data) => {
  return axios.post (
    API_URL + 'update-to-do-list',
    {userId: userId, id: Id, data: data},
    {
      headers: authHeader (),
    }
  );
};

const deleteToDoSection = data => {
  return axios.post (API_URL + 'delete-to-do-section', data, {
    headers: authHeader (),
  });
};

const deleteToDoList = data => {
  return axios.post (API_URL + 'delete-to-do-list', data, {
    headers: authHeader (),
  });
};

const emptyToDoSection = data => {
  return axios.post (API_URL + 'empty-to-do-section', data, {
    headers: authHeader (),
  });
};

const updateSectionTitle = data => {
  return axios.post (API_URL + 'updateSectionTitle', data, {
    headers: authHeader (),
  });
};

const createNewAttendance = data => {
  return axios.post (API_URL + 'createNewAttendance', data, {
    headers: authHeader (),
  });
};

const getUserAttendance = data => {
  return axios.post (API_URL + 'getUserAttendance', data, {
    headers: authHeader (),
  });
};

const getAllAttendance = () => {
  return axios.get (API_URL + 'getAllAttendance', {
    headers: authHeader (),
  });
};

const getTodaysAttendance = data => {
  return axios.post (API_URL + 'getTodayAttendance', data, {
    headers: authHeader (),
  });
};

const updateAttendance = data => {
  return axios.post (API_URL + 'updateAttendance', data, {
    headers: authHeader (),
  });
};

const getAllLeave = () => {
  return axios.get (API_URL + 'getAllLeave', {
    headers: authHeader (),
  });
};

const updateLeave = data => {
  return axios.post (API_URL + 'updateLeave', data, {
    headers: authHeader (),
  });
};

const getUserLeave = data => {
  return axios.post (API_URL + 'getUserLeave', data, {
    headers: authHeader (),
  });
};

const createNewLeave = data => {
  return axios.post (API_URL + 'createNewLeave', data, {
    headers: authHeader (),
  });
};

const createNewLeaveType = data => {
  return axios.post (API_URL + 'createNewLeaveType', data, {
    headers: authHeader (),
  });
};

const deleteLeaveAttendance = data => {
  return axios.post (API_URL + 'deleteLeaveAttendance', data, {
    headers: authHeader (),
  });
};

const getAllLeaveType = () => {
  return axios.get (API_URL + 'getAllLeaveType', {
    headers: authHeader (),
  });
};

// const getDeadlineTask = () => {
//   return axios.get (API_URL + 'get-deadline-task', {
//     headers: authHeader (),
//   });
// };

const createYoutubeVideoInfo = data => {
  return axios.post (API_URL + 'add-youtube-info', data, {
    headers: authHeader (),
  });
};

const getAllYoutubeInfo = () => {
  return axios.get (API_URL + 'get-youtube-videos', {headers: authHeader ()});
};

const getAllGmailLabels = () => {
  return axios.get (API_URL + 'get-gmail-labels', {headers: authHeader ()});
};

const updateYoutubeVideo = (id, data) => {
  return axios.post (
    API_URL + 'update-youtube-video',
    {id: id, data: data},
    {headers: authHeader ()}
  );
};

const getCategorizedOrder = (category, page, limit) => {
  return axios.post (
    API_URL + 'get-orders-by-category',
    {category: category, page: page, limit: limit},
    {headers: authHeader ()}
  );
};

const createMobileBanner = (title, fileLink) => {
  return axios.post (
    API_URL + 'create-mobile-banner',
    {title: title, fileUrl: fileLink},
    {headers: authHeader ()}
  );
};

const getMobileBanner = () => {
  return axios.get (API_URL + 'get-all-banners', {headers: authHeader ()});
};

const deleteMobileBanner = id => {
  return axios.post (
    API_URL + 'delete-mobile-banner',
    {id: id},
    {headers: authHeader ()}
  );
};

const createUserCoins = data => {
  return axios.post (API_URL + 'create-coins', data, {headers: authHeader ()});
};

const updateUserCoins = data => {
  return axios.post (API_URL + 'update-user-coins', data, {
    headers: authHeader (),
  });
};

const getUserCoins = data => {
  return axios.post (API_URL + 'get-user-coins', data, {
    headers: authHeader (),
  });
};

const updateCoinsAfterDeliver = data => {
  console.log ('final coin data : ', data);
  return axios.post (API_URL + 'update-user-coin-after-deliver', data, {
    headers: authHeader (),
  });
};

const createYoutubePlaylist = data => {
  return axios.post (API_URL + 'create-youtube-playlist', data, {
    headers: authHeader (),
  });
};

const getYoutubePlaylist = () => {
  return axios.get (API_URL + 'get-youtube-playlist', {
    headers: authHeader (),
  });
};

const updateYoutubePlaylist = (id, data) => {
  return axios.post (
    API_URL + 'update-youtube-playlist',
    {id: id, data: data},
    {
      headers: authHeader (),
    }
  );
};

const createOutsourceOrder = data => {
  return axios.post (API_URL + 'create-outsource-order', data, {
    headers: authHeader (),
  });
};

const createOutsourceOrderComment = data => {
  return axios.post (API_URL + 'create-outsource-order-comment', data, {
    headers: authHeader (),
  });
};

const updateOutsourceOrderByAdmin = data => {
  return axios.post (API_URL + 'update-outsource-order-by-admin', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderForUser = data => {
  return axios.post (API_URL + 'get-outsource-order-for-user', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderForAdmin = data => {
  return axios.post (API_URL + 'get-outsource-order', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderComment = data => {
  return axios.post (API_URL + 'get-outsource-order-comment', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderCommentForUser = data => {
  return axios.post (API_URL + 'get-outsource-order-comment-for-user', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderCommentReply = data => {
  return axios.post (API_URL + 'get-outsource-order-comment-reply', data, {
    headers: authHeader (),
  });
};

const getOutsourceOrderCommentReplyFromAdmin = data => {
  return axios.post (
    API_URL + 'get-outsource-order-comment-reply-from-admin',
    data,
    {
      headers: authHeader (),
    }
  );
};

export default {
  createOrder,
  deleteDoc,
  getAllOrders,
  updateStatus,
  getSingleOrder,
  updateOrder,
  getSpecificOrder,
  getCreatedOrder,
  getEmployeeOrders,
  OrderFeedback,
  getAllFeedback,
  getAllDocs,
  getAwsInfo,
  postOrderDoc,
  postDocumentation,
  createPublicOrder,
  getAllPublicOrders,
  getSinglePublicOrder,
  setOrderPayment,
  getAllPayments,
  getNewOrders,
  getSinglePaymentDetails,
  getPaymentByOrderId,
  makeRazorpayPayment,
  getRazorpayConfig,
  recycleData,
  getAllRecyleData,
  deleteRecycleItem,
  restoreRecycleItem,
  getAllLogs,
  getCustomerOrder,
  createOrderCategory,
  getAllOrderCategory,
  createOrderInvoice,
  downloadInvoice,
  postMultipleDoc,
  getSpecificDoc,
  getSingleDocForUser,
  uploadFilesToDrive,
  getDriveFiles,
  createMailRecord,
  getAllMailRecords,
  createNewComment,
  getSpecificOrderComment,
  getSpecificOrderReply,
  createCommentReply,
  getCustomerPayments,
  getEstimatedCounts,
  createContactUsFormData,
  getContactUsFormData,
  getCustomerOrderCount,
  createNewSessions,
  getAllSessions,
  createNewBooking,
  getAllBookings,
  getUserBookings,
  getBookingById,
  createNewService,
  getAllServicesForHome,
  getServiceById,
  getSessionById,
  updateSessionById,
  updateServiceById,
  deleteSessionById,
  deleteServiceById,
  deleteBookingById,
  createNewSubscription,
  getAllSubscriptionCategories,
  updateSubscriptionCategory,
  deleteSubscriptionCategory,
  createSubscriptionRecord,
  getAllSubscriptionRecord,
  getSubscriptionById,
  getAllInvoiceDetails,
  addInvoiceFormatDetails,
  getInvoiceFormatDetails,
  getSingleInvoiceDetails,
  isOrderInvoicePresent,
  getRecommendedSessionsAndServices,
  deleteDocById,
  updateBooking,
  createNewAssignmentHelp,
  getAllAssignmentHelp,
  getAssignmentRequestById,
  getAllToDoSectionAndTask,
  createNewToDoTask,
  updateToDoList,
  deleteToDoSection,
  createNewToDoSection,
  emptyToDoSection,
  updateSectionTitle,
  deleteToDoList,
  createNewAttendance,
  updateAttendance,
  getAllAttendance,
  getUserAttendance,
  getTodaysAttendance,
  getAllLeave,
  updateLeave,
  getUserLeave,
  createNewLeave,
  createNewLeaveType,
  getAllLeaveType,
  deleteLeaveAttendance,
  createYoutubeVideoInfo,
  getAllYoutubeInfo,
  getAllGmailLabels,
  updateYoutubeVideo,
  getCategorizedOrder,
  createMobileBanner,
  getMobileBanner,
  deleteMobileBanner,
  createUserCoins,
  updateUserCoins,
  getUserCoins,
  updateCoinsAfterDeliver,
  createYoutubePlaylist,
  getYoutubePlaylist,
  updateYoutubePlaylist,
  createOutsourceOrder,
  createOutsourceOrderComment,
  getOutsourceOrderForUser,
  getOutsourceOrderForAdmin,
  updateOutsourceOrderByAdmin,
  getOutsourceOrderComment,
  getOutsourceOrderCommentForUser,
  getOutsourceOrderCommentReply,
  getOutsourceOrderCommentReplyFromAdmin,
};
