import axios from 'axios';
import authHeader from './auth-header';
// var API_URL  = "http://3.7.156.63:3000/api/auth/";
// // var API_URL  = "http://3.7.156.63/api/auth/";
// var API_URL = 'http://localhost:3000/api/auth/';
var API_URL = 'https://dashboard.codersarts.com/api/auth/';

//  if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/auth/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70/api/auth/";

// }

const createPaymentIntent = data => {
  return axios.post (API_URL + 'create-payment-intent', data, {
    headers: authHeader (),
  });
};

const getStripeKey = () => {
  return axios.get (API_URL + 'stripe-config');
};

const createPublicPaymentIntent = data => {
  return axios.post (API_URL + 'create-public-payment-intent', data);
};

const createPublicPayment = data => {
  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }
  }
  return axios.post (API_URL + 'createPublicPayment', data);
};

const getAllPublicPayments = () => {
  return axios.get (API_URL + 'getAllPublicPayments', {headers: authHeader ()});
};

const getSinglePublicPaymentDetails = id => {
  // console.log("ID : ",id);
  return axios.post (
    API_URL + 'getSinglePublicPaymentDetails',
    {id: id},
    {headers: authHeader ()}
  );
};

const getAllCountries = () => {
  return axios.get (API_URL + 'get-country-list');
};

export default {
  createPaymentIntent,
  getStripeKey,
  createPublicPaymentIntent,
  createPublicPayment,
  getAllPublicPayments,
  getSinglePublicPaymentDetails,
  getAllCountries,
};
