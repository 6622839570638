import React,{useState,useEffect,useRef} from 'react'
import './CommentArea.css';
import {useSelector} from 'react-redux';
import {message,Spin,Tooltip} from 'antd';
import OrderService from '../../services/order.service';
import UserService from '../../services/user.service';
import GoogleServices from '../../services/google.services';
import {DownloadOutlined, LoadingOutlined,PaperClipOutlined} from '@ant-design/icons';
import { format } from "timeago.js";
import { io } from "socket.io-client";
import S3 from 'react-aws-s3';

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 
 let socketConnection = "https://dashboard.codersarts.com";
// let socketConnection =  "http://localhost:3000";

function CommentArea(props) {
    const [allComments,setAllComments] = useState([]);
    const [allReplies,setAllReplies] = useState([]);
    const [newComment,setNewComment] = useState("");
    const [combinedComments,setCombinedComments] = useState([]);
    const [loading,setLoading] = useState(false);
    const [file,setFile] = useState(null);
    const [awsData,setAwsData] = useState(null);
    const inputFileRef = useRef(null);
    const [arrivalMessage,setArrivalMessage] = useState(null);
    const [currentUserDetails,setCurrentUserDetails] = useState(null);
    const scrollRef = useRef();
    const socket = useRef();

    const { user: currentUser } = useSelector((state) => state.auth);
    
   // console.log("customerId : ",props.customerId)
//    console.log("Backend Ids : ",props.backendIds);

// useEffect(()=>{
//     console.log("I am mounted!");
// },[])

    const getAwsInfo = async ()=>{
        try{
         const res = await OrderService.getAwsInfo();
        //  console.log("AWS DATA : ",res.data);
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
    
     }

     const getUserDetailsById = async ()=>{
        try{
            const response = await UserService.getUserById(currentUser.id);
            // console.log("user : ",response.data);
            setCurrentUserDetails(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went wrong!');
        }
     }

     const sendMail = async (mailData)=>{
        //  console.log("Receiever Id : ",mailData.receiverId);
         if(mailData.commentType==="reply"){
          if(mailData.receiverId){
            try{   
                const response = await UserService.getUserById(mailData.receiverId);
                // console.log(response.data);
                const data ={
                    To:response.data.email,
                    Subject:`Message From ${mailData.senderDetails.username} in ${mailData.orderName} Comments`,
                    Write:`<b>Message:</b> ${mailData.text} `,
                    attachment:false,
                }
                const mailResult = await GoogleServices.sendmail(data);
                // console.log(response);
                    if(mailResult){
                        message.success(`Successfully Sent the Mail to ${response.data.email}`);
                    }
               
               
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Mail Not Sent!')
            }

            const notificationRes = await UserService.createNewNotification(
                {notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                user:mailData.receiverId})
                // console.log("Notification : ",notificationRes.data);
                socket.current.emit({notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                user:mailData.receiverId})

                
          }
                
         }else{
            if(mailData.developerId===mailData.managerId){
                try{   
                    const response = await UserService.getUserById(mailData.developerId);
                    // console.log(response.data);
                    const data ={
                        To:response.data.email,
                        Subject:`Message From ${mailData.senderDetails.username} in ${mailData.orderName} Comments`,
                        Write:`<b>Message:</b> ${mailData.text} `,
                        attachment:false,
                    }
                    const mailResult = await GoogleServices.sendmail(data);
                    // console.log(response);
                        if(mailResult){
                            message.success(`Successfully Sent the Mail to ${response.data.email}`);
                           
                            
                            return;
                        }
                   
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Mail Not Sent!');
                    return;
                }

                const notificationRes = await UserService.createNewNotification(
                    {notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.developerId})
                    // console.log("Notification : ",notificationRes.data);
                    socket.current.emit({notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.developerId})


            }
            if(mailData.developerId){
                try{   
                    const response = await UserService.getUserById(mailData.developerId);
                    // console.log(response.data);
                    const data ={
                        To:response.data.email,
                        Subject:`Message From ${mailData.senderDetails.username} in ${mailData.orderName} Comments`,
                        Write:`<b>Message:</b> ${mailData.text} `,
                        attachment:false,
                    }
                    const mailResult = await GoogleServices.sendmail(data);
                    // console.log(response);
                        if(mailResult){
                            message.success(`Successfully Sent the Mail to ${response.data.email}`);
                            
                        }

                   
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Mail Not Sent!')
                }

                const notificationRes = await UserService.createNewNotification(
                    {notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.developerId})
                    // console.log("Notification : ",notificationRes.data);
                    socket.current.emit({notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.developerId})
            }

            if(mailData.managerId){
                try{   
                    const response = await UserService.getUserById(mailData.managerId);
                    // console.log(response.data);
                    const data ={
                        To:response.data.email,
                        Subject:`Message From ${mailData.senderDetails.username} in ${mailData.orderName} Comments`,
                        Write:`<b>Message:</b> ${mailData.text} `,
                        attachment:false,
                    }
                    const mailResult = await GoogleServices.sendmail(data);
                    // console.log(response);
                        if(mailResult){
                            message.success(`Successfully Sent the Mail to ${response.data.email}`);
                        }
                   
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Mail Not Sent!')
                }

                const notificationRes = await UserService.createNewNotification(
                    {notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.managerId})
                    // console.log("Notification : ",notificationRes.data);
                    socket.current.emit({notification:`New Message in ${mailData.orderName}: ${mailData.text}`,
                    user:mailData.managerId})
            }

         }

         
       
     }

     const updateMessages = (data)=>{
        // console.log("GOT MESSAGE HERE : ",data);
       if(data.error && data.senderId===currentUser.id){
            message.info("User is offline sending mail instead!");
            sendMail(data);
       }else{
        // let arrivalMsg = {
        //     senderId:data.senderId,
        //     senderDetails:data.senderDetails,
        //     text:data.text,
        //     fileUrl:data.fileUrl,
        //     order:data.order,
        //     commentType:data.commentType,
        //     receiverId:data.receiverId,
        //     msgType:data.msgType,
        //     createdAt:Date.now()
        // }
        // console.log("New Msg : ",arrivalMsg)
        setArrivalMessage(data);
       }
     }


     useEffect(()=>{
        socket.current = io();
        socket.current.on("getComment", (data) => {
            updateMessages(data)
            
          });

          
     },[])

    

     useEffect(() => {
        socket.current.emit("addCommentUser", currentUser.id);
        // console.log(user.id);
        
      }, [currentUser]);


      useEffect(()=>{
        // console.log("Arrival msg : ",arrivalMessage);
      if(arrivalMessage){
       if(arrivalMessage.commentType==="reply"){
           if(arrivalMessage.receiverId && arrivalMessage.receiverId===currentUser.id){
               let newReplyMsg = {
                   reply:arrivalMessage.text,
                   sender:[arrivalMessage.senderDetails],
                   order:arrivalMessage.order,
                   fileUrl:arrivalMessage.fileUrl,
                   msgType:arrivalMessage.msgType,
                   createdAt:arrivalMessage.createdAt ? arrivalMessage.createdAt : Date.now()
               }
               setAllReplies(prev=>[...prev,newReplyMsg])
           }
       }else{
           if(arrivalMessage.receiverId && arrivalMessage.receiverId.includes(currentUser.id)){
               let newCommentMsg = {
                   sender:[arrivalMessage.senderDetails],
                   message:arrivalMessage.text,
                   order:arrivalMessage.order,
                   fileUrl:arrivalMessage.fileUrl,
                   msgType:arrivalMessage.msgType,
                   createdAt:arrivalMessage.createdAt ? arrivalMessage.createdAt : Date.now()
               }
               setAllComments(prev=>[...prev,newCommentMsg])
           }
       }
      }
    },[arrivalMessage])




     useEffect(()=>{
        getAwsInfo();
        getUserDetailsById();
     },[])

     
    //  const updateCommentCount = ()=>{

    //  }

    const getAllComments = async ()=>{
        try{
            const response = await OrderService.getSpecificOrderComment(props.orderId);
            // console.log(response.data);
            setAllComments(response.data);
            // updateCommentCount(response.data.length);
        }catch(err){
            console.log(err);
            // message.error(err.message ? err.message : 'Unable to load comments!');

        }
    }

    const getAllReplies = async ()=>{
        try{
            const response = await OrderService.getSpecificOrderReply(props.orderId);
            // console.log(response.data);
            setAllReplies(response.data);
        }catch(err){
            console.log(err);
            // message.error(err.message ? err.message : 'Unable to load comments!');

        }
    }

    const sendCommentToDB = async (newCommentData)=>{
        setLoading(true);
        try{
            const response = await OrderService.createNewComment(newCommentData);
            // console.log(response.data);
            setNewComment("");
            getAllComments();
            setLoading(false);
            
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Unable to send comment!');
        }
    }

    const sendReplyToDb = async (newReplyData)=>{
        setLoading(true);
        try{
            const response = await OrderService.createCommentReply(newReplyData);
            // console.log(response.data);
            setNewComment("");
            getAllReplies();

            setLoading(false);
            
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Unable to reply!');
        }
    }



    const sendComment = async ()=>{
       
        // console.log("AWS DATA : ",awsData);
        // console.log("FILE : ",file);
        let newCommentData = {
            sender:currentUser.id,
            message:newComment,
            order:props.orderId,
            fileUrl:null,
            msgType:"text",
        }
        if(file && awsData){
            // console.log("FINAL FILE TO UPLOAD : ",file);
            newCommentData = {
                sender:currentUser.id,
                message:newComment,
                order:props.orderId,
                fileUrl:null,
                msgType:file && file.type,
            }
      
            //uploading file to aws
      
            setLoading(true);
                  const config = {
                      bucketName:awsData.bucketName,
                      region:'ap-south-1',
                      accessKeyId: awsData.accessKeyId,
                      secretAccessKey: awsData.secretKey,
                      s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
                  }
                  const ReactS3Client = new S3(config);
                  try{
                    const data = await ReactS3Client.uploadFile(file,file.name);
                    newCommentData.fileUrl = data.location
                    // console.log("UPLOADED  : ",newCommentData);
                    // console.log("Order Name : ",props.orderName)
                    socket.current.emit("sendComment", {
                        senderId: currentUser.id,
                        senderDetails:currentUserDetails,
                        receiverId : props.backendIds,
                        text: newCommentData.message,
                        fileUrl:newCommentData.fileUrl,
                        orderName:props.orderName,
                        order:newCommentData.order,
                        commentType:'comment',
                        msgType:newCommentData.msgType,
                        developerId:props.developerId,
                        managerId:props.managerId
                        });
                        // console.log("Sending Msg : ",{
                        //     senderId: currentUser.id,
                        //     receiverId : props.customerId,
                        //     text: newCommentData.message,
                        //     fileUrl:newCommentData.fileUrl,
                        //     commentType:'comment',
                        //     msgType:newCommentData.msgType
                        //     });
                    setFile(null);
                    sendCommentToDB(newCommentData);
                    setLoading(false);

                   }catch(err){
                       console.log(err);
                       setLoading(false);
                   }
               
      
          }else{
            // console.log("Order Name : ",props.orderName)
            socket.current.emit("sendComment", {
                senderId: currentUser.id,
                senderDetails:currentUserDetails,
                receiverId :  props.backendIds,
                text: newCommentData.message,
                fileUrl:newCommentData.fileUrl,
                orderName:props.orderName,
                order:newCommentData.order,
                commentType:'comment',
                msgType:newCommentData.msgType,
                developerId:props.developerId,
                managerId:props.managerId
                });
                // console.log("Sending Msg : ",{
                //     senderId: currentUser.id,
                //     receiverId : props.customerId,
                //     text: newCommentData.message,
                //     fileUrl:newCommentData.fileUrl,
                //     commentType:'comment',
                //     msgType:newCommentData.msgType
                //     });
            sendCommentToDB(newCommentData)
          }
         
        // console.log(newCommentData);

    }

  

    const sendReply = async ()=>{
        
        // console.log("AWS DATA : ",awsData);
        // console.log("FILE : ",file);
        
        let newReplyData = {
            reply:newComment,
            sender:currentUser.id,
            order:props.orderId,
            commentId:allComments && allComments[allComments.length-1] && allComments[allComments.length-1]._id && allComments[allComments.length-1]._id,
            fileUrl:null,
            msgType:"text",
        }

        if(file && awsData){

            newReplyData = {
                reply:newComment,
                sender:currentUser.id,
                order:props.orderId,
                commentId:allComments && allComments[allComments.length-1] && allComments[allComments.length-1]._id && allComments[allComments.length-1]._id,
                fileUrl:null,
                msgType:file && file.type,
            }
      
            //uploading file to aws
      
            setLoading(true);
                  const config = {
                      bucketName:awsData.bucketName,
                      region:'ap-south-1',
                      accessKeyId: awsData.accessKeyId,
                      secretAccessKey: awsData.secretKey,
                      s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
                  }
                  const ReactS3Client = new S3(config);
                  try{
                    const data = await ReactS3Client.uploadFile(file,file.name);
                    newReplyData.fileUrl = data.location
                    // console.log("UPLOADED  : ",newReplyData);
                    // console.log("Order Name : ",props.orderName)
                    setFile(null);
                    socket.current.emit("sendComment", {
                        senderId: currentUser.id,
                        senderDetails:currentUserDetails,
                        receiverId : props.customerId,
                        text: newReplyData.reply,
                        fileUrl:newReplyData.fileUrl,
                        order:newReplyData.order,
                        orderName:props.orderName,
                        commentType:'reply',
                        msgType:newReplyData.msgType
                        });
                        // console.log("Sending Msg : ",{
                        //     senderId: currentUser.id,
                        //     receiverId : props.customerId,
                        //     text: newReplyData.reply,
                        //     fileUrl:newReplyData.fileUrl,
                        //     order:newReplyData.order,
                        //     commentType:'reply',
                        //     msgType:newReplyData.msgType
                        //     });


                    sendReplyToDb(newReplyData);
                    setLoading(false);

                   }catch(err){
                       console.log(err);
                       setLoading(false);
                   }
        }else{
            // console.log("Order Name : ",props.orderName)
            socket.current.emit("sendComment", {
                senderId: currentUser.id,
                senderDetails:currentUserDetails,
                receiverId : props.customerId,
                text: newReplyData.reply,
                fileUrl:newReplyData.fileUrl,
                order:newReplyData.order,
                orderName:props.orderName,
                commentType:'reply',
                msgType:newReplyData.msgType
                });
                // console.log("Sending Msg : ",{
                //     senderId: currentUser.id,
                //     receiverId : props.customerId,
                //     text: newReplyData.reply,
                //     fileUrl:newReplyData.fileUrl,
                //     order:newReplyData.order,
                //     commentType:'reply',
                //     msgType:newReplyData.msgType
                //     });
            sendReplyToDb(newReplyData)
        }

        // console.log(newReplyData);
       

       


    }


    useEffect(()=>{
        getAllComments();
        getAllReplies();
    },[]);


    

    useEffect(()=>{
        // console.log("Comments : ",allComments);
        let combinedArr = []
        if(allComments.length || allReplies ){
            combinedArr = allComments.concat(allReplies);
        }

        // console.log("Combined Array : ",combinedArr);
        let finalCombinedArr = combinedArr.sort((a,b)=>{
            return new Date(a.createdAt) -  new Date(b.createdAt)
        })
        // console.log("FINAL ARR : ",finalCombinedArr);
        // console.log(currentUser.roles[0].split('_')[1].toLowerCase());
        setCombinedComments(finalCombinedArr);
    },[allComments,allReplies]);

   


    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [combinedComments]);


      const handleFileChange = (e)=>{
        // console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setNewComment(e.target.files[0].name)
      }
    
      const handleFileClick = ()=>{
        inputFileRef.current.click();
      }


    //   const handleFileDownload = (link)=>{
    //       window.open(link);
    //   }


    return (
        <div className="commentArea">
            <div className="chatContainer">
                <div className="messageArea">
                {
                    combinedComments.map((c)=>{
                        return(
                            <div ref={scrollRef} className={`${c.sender[0].roles[0].name===currentUser.roles[0].split('_')[1].toLowerCase() ? 'order2':'order1'}`}>
                                <div className={`${c.reply ? 'response':'request'} ${c.sender[0].roles[0].name===currentUser.roles[0].split('_')[1].toLowerCase() ? 'commentOwn':' commentOther'}`}>
                                {c.reply ? c.reply:c.message}
                                {c.fileUrl ? 
                                <a href={c.fileUrl} 
                                className="fileDownloadLink"
                                style={{fontSize:'1.5rem',marginLeft:'10px',cursor:'pointer'}} target={'_blank'}><DownloadOutlined  /></a>
                                :
                                null
                                }
                                </div>
                                <small>{format(c.createdAt)}</small>
                            </div>
                        )
                    })
                }
               
                    
                    
                </div>
                <div className="writeArea">
                    <textarea value={newComment} onChange={(e)=>{setNewComment(e.target.value)}}></textarea>
                  {currentUser.roles.includes('ROLE_USER') ?
                  <>
                  <Tooltip title="Share Files ? " color={'#f50'}>
                  <span onClick={handleFileClick}>
                  <PaperClipOutlined className="file-upload-icon" style={{marginTop:'2%'}} />
                  </span>
                  </Tooltip>
                  <input type="file" 
                  style={{display:'none'}} 
                  onChange={handleFileChange}
                  ref={inputFileRef} />
                    <button type="button" className="comment-btn" onClick={sendComment}>
                    {loading ? <Spin indicator={spinIcon} /> : 'Send'}
                    </button>
                  </>
                    :
                    <>
                    
                <Tooltip title="Share Files ? " color={'#f50'}>
                  <span onClick={handleFileClick}>
                  <PaperClipOutlined className="file-upload-icon" />
                  </span>
                  </Tooltip>
                  <input type="file" 
                  style={{display:'none'}} 
                  onChange={handleFileChange}
                  ref={inputFileRef} />

                    <button type="button" className="comment-btn" 
                     onClick={sendReply}>
                    {loading ? <Spin indicator={spinIcon} /> : 'Reply'}
                    </button>
                    </>
                    }
                   
                </div>
            </div>

        </div>
    )
}

export default CommentArea
