import React,{useState,useEffect} from 'react';
import {Breadcrumb,message} from 'antd';
import {Link} from 'react-router-dom';
import userServices from '../../../services/user.service';
import '../../Profile/SingleBookingDetails/SingleBookingDetails.css';

function SingleDevRequest({match}) {
    const [devRequestDetails,setDevRequestDetails] = useState("");

    useEffect(() => {
        let isMount = true
      const getSingleDevRequest = async ()=>{
        try{
            const response = await userServices.getSingleDevRequest(match.params.id);
            // console.log(response.data)
            if(response.data && isMount){
                let d = response.data;
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
                
                let modifiedData = {
                    createdAt:finalDate,
                    requestBy:d.requestBy[0].username,
                    userId:d.requestBy[0]._id ? d.requestBy[0]._id : d.requestBy[0],
                    joiningDate:d.joiningDate,
                    description:d.description,
                    workAs:d.workAs,
                    availableTime:d.availableTime
                }
                setDevRequestDetails(modifiedData);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something  went wrong!');
        }
      }

      getSingleDevRequest();
    
      return () => {
        isMount = false
      }
    }, [match.params.id])
    

  return (
    <div className='bookingDetails'>
     <Breadcrumb>
            <Breadcrumb.Item>
            <Link to="profile/developer-request">All Requests</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/single-developer-request-details/${match.params.id}`}>{devRequestDetails && devRequestDetails.requestBy ? devRequestDetails.requestBy : 'Developer'} Request Details</Link></Breadcrumb.Item>
    </Breadcrumb>
    <h2 className="mt-3">{devRequestDetails && devRequestDetails.requestBy ? devRequestDetails.requestBy : 'Developer'} Request Details</h2>

            <div className='card' style={{'width':'60%'}}>
            <table>
                <tr>
                    <th>Id : </th>
                    <td>{ devRequestDetails && devRequestDetails._id ? devRequestDetails._id : "N/A"}</td>
                </tr>

                <tr>
                    <th>Request By : </th>
                    <th><Link to={`profile/manage-users/view/${devRequestDetails && devRequestDetails.userId ? devRequestDetails.userId:'not-found' }`}>{ devRequestDetails && devRequestDetails.requestBy ? devRequestDetails.requestBy : "N/A"}</Link></th>
                </tr>
                <tr>
                    <th>Work As : </th>
                    <td>{ devRequestDetails && devRequestDetails.workAs ? devRequestDetails.workAs : "N/A"}</td>
                </tr>
                <tr>
                    <th>Available Time: </th>
                    <td>{ devRequestDetails && devRequestDetails.availableTime ? devRequestDetails.availableTime : "N/A"}</td>
                </tr>
                <tr>
                    <th>Joining Date: </th>
                    <td>{ devRequestDetails && devRequestDetails.joiningDate ? devRequestDetails.joiningDate : "N/A"}</td>
                </tr>
                <tr>
                    <th>Description: </th>
                    <td>{ devRequestDetails && devRequestDetails.description ? devRequestDetails.description : "N/A"}</td>
                </tr>

             
            </table>
            </div>
    </div>
  )
}

export default SingleDevRequest