import React, { useState, useRef,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert,Modal,Spin} from 'antd';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import avatar from '../../assets/user.png';
import './RegisterForm.css';
import { register } from "../../actions/auth";
import { Link } from "react-router-dom";
import {EyeInvisibleOutlined,EyeOutlined,LoadingOutlined} from '@ant-design/icons';
import GoogleServices from "../../services/google.services";

var passwordValidator = require('password-validator');
var schema = new passwordValidator();
const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;
//password validation rules

schema.is().min(8)                                    // Minimum length 8
schema.is().max(100)                                  // Maximum length 100
schema.has().uppercase()                              // Must have uppercase letters
schema.has().lowercase()                              // Must have lowercase letters
schema.has().digits(2)                                // Must have at least 2 digits
schema.has().symbols(1)                                //Must have atleast 1 symbol
schema.has().not().spaces()                           // Should not have spaces
// let otp = 569433;

const required = (value) => {
  if (!value) {
    return (
      <Alert
      message="Error"
      description="This Field is Required"
      type="error"
      showIcon
      />
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <Alert
      message="Email Error"
      description="Please Provide a valid Email Address!"
      type="error"
      showIcon
    />
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <Alert
      message="Username Error"
      description="Username Should be Minimum of 3 Characters"
      type="error"
      showIcon
    />
    );
  }
};

const vpassword = (value) => {
  if (value.length < 8 || value.length > 40) {
    return (
      <Alert
      message="Password Error"
      description="Password Should be Minimum of 8 Characters ! "
      type="error"
      showIcon
    />
    );
  }
  else if(!schema.validate(value)){
    return (
      <Alert
      message="Password Error"
      description="Password Must Contain Digits,Symbols,Uppercase and Lowercase Letters! "
      type="error"
      showIcon
    />)
  }
};

const RegisterForm = () => {
  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [isShown,setIsShown] = useState(false);
  const [enteredOtp,setEnteredOtp] = useState(null);
  const [fullName,setFullName] = useState("");
  const [page,setPage] = useState(1);
  const [loading,setLoading] = useState(false);
  const [otp,setOtp] = useState(567890);
 

  const { message } = useSelector(state => state.message);
  
  const dispatch = useDispatch();

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeOtp = (e) => {
    const userOtp = e.target.value;
    // console.log("Setting OTP : ",userOtp);
    setEnteredOtp(Number(userOtp));
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password)
  };

 
  const sendEmailConfirmMail = async ()=>{
   let otpToSend =  Math.floor(100000 + Math.random() * 900000);
    // console.log("EMAIL : ",email);
    // console.log("OTP : ",otpToSend);
    setOtp(otpToSend);
    
     if(email){
      try{
          const data ={
              To:email,
              Subject:"Codersarts Email Verification Code",
              Write:`Dear User, <br /> Your OTP is <h2>${otpToSend}</h2> You can verify Your Email with this otp`,
              attachment:false,
  
          }
          const response = await GoogleServices.sendmail(data);
          // console.log(response);
              if(response){
                  Modal.success(
                 { 
                  title: 'OTP Sent Successfully!',
                  content: 'Please Check Your Mail and Enter the OTP!'
                });
              }
         
      }catch(err){
          console.log(err);
          Modal.error(
            { 
            title: 'Something Went Wrong!',
            content: `${err.message ? err.message : 'OTP Could Not be Sent!'}`
          })
      }
    }

  }

  const handleRegister = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log("OTP : ",otp);
    // console.log("Entered OTP : ",enteredOtp);
    setSuccessful(false);

    // const valid = checkValidPassword();
    form.current.validateAll();

    if(otp===enteredOtp){
      if (checkBtn.current.context._errors.length === 0) {
        const data = {"username":username,name:fullName ? fullName : username,"email":email,"password":password}
        dispatch(register(data))
          .then(() => {
            setSuccessful(true);
            setLoading(false);
          })
          .catch(() => {
            setSuccessful(false);
            setLoading(false);
          });
      }
    }
    if(enteredOtp !==null && otp!==enteredOtp){
      Modal.error({
        title: 'Wrong OTP',
        content: 'You Entered Wrong OTP Please Try Again!',
      });
    }
    setLoading(false);
  };


  const verifyMailData = ()=>{
    setPage(2);
    sendEmailConfirmMail();
  }

  return (
    <div className="col-md-12 registerForm">
      <div className="card card-container">
        <img
          src={avatar}
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              {page===1 ? 
               <>
               <div className="form-group">
                <label htmlFor="fullName">Full Name</label>
                <Input
                  type="text"
                  className="form-control"
                  name="fullName"
                  placeholder="John Doe"
                  value={fullName}
                  onChange={(e)=>setFullName(e.target.value)}
                />
              </div>


               <div className="form-group">
                <label htmlFor="username">Username</label>
                <Input
                  type="text"
                  className="form-control"
                  name="username"
                  value={username}
                  placeholder="johncodersarts"
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <Input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="john@codersarts.com"
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className="form-group">
                <label htmlFor="password">Password</label>
                
                <div style={{display:'flex'}}>
                <Input
                  type={isShown ? "text":"password"}
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
                {isShown ?<span onClick={()=>{setIsShown(prevValue=>!prevValue)}} className="eye-icon"><EyeOutlined style={{fontSize:'20px'}} /> </span> : 
                <span onClick={()=>{setIsShown(prevValue=>!prevValue)}} className="eye-icon"><EyeInvisibleOutlined style={{fontSize:'20px'}} /></span>}
                </div>
              </div>
               </>
               :
               
                
              <div className="form-group">
                <label htmlFor="email">Enter OTP</label>
                <Input
                  type="number"
                  className="form-control"
                  name="otp"
                  value={enteredOtp}
                  onChange={onChangeOtp}
                />
              </div>
              }
              <div className="form-group mt-2">
               {page!==1 ?  
               <button type="submit" disabled={enteredOtp===null || username===null || email === null || 
               password === null} className="btn btn-primary btn-block">
               {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Sign Up</span></button>
               :
               <button type="button" disabled={username==="" || email === "" || password === ""} onClick={verifyMailData} className="btn btn-primary btn-block">Next</button>
               }
                <br />
                <Link style={{marginLeft:'40%'}} to="/auth/signin">Sign In ?</Link>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
            {
              successful ?
              (<Alert message="Success"  description={message} type="success" showIcon />):
              (<Alert
                message="Error"
                description={message}
                type="error"
                showIcon
                />)

            }
            
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
