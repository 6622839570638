import { message, Space } from 'antd';


const Message = (props)=>{
    const success = () => {
        message.success(props.message);
      };
      
      const error = () => {
        message.error(props.message);
      };
      
      return(
          
        <Space>
            {props.type==='success' ? success(): error()}  
        </Space>
      );
}

export default Message;