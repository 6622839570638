import axios from 'axios';

// var API_URL = 'http://localhost:3000/api/drive/';
var API_URL = 'https://dashboard.codersarts.com/api/drive/';

const uploadfile = (data, changedName) => {
  return axios.post (API_URL + 'uploadfile', data, {
    headers: {changedName: changedName},
  });
};

const getlist = async () => {
  return axios
    .get (API_URL + 'get-list')
    .then (response => {
      // console.log(response);
      return response.data;
    })
    .catch (err => console.log (err));
};

const downloadfile = async data => {
  return axios
    .post (API_URL + 'download-file', data)
    .then (response => {
      return response.data;
    })
    .catch (err => {
      console.log (err);
    });
};

const sendmail = async data => {
  return axios
    .post (API_URL + 'send-mail', data)
    .then (response => {
      return response;
    })
    .catch (err => {
      console.log (err);
    });
};

export default {
  downloadfile,
  getlist,
  uploadfile,
  sendmail,
};
