import React,{useState,useEffect} from 'react';
import { Table,Space,Tag,Button,message} from 'antd';
import { Link } from 'react-router-dom';
import orderService from '../../../services/order.service';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';
import './MyBookings.css';
import { CopyTwoTone } from '@ant-design/icons';







const MyBookings = ()=>{
    const [allBookings,setAllBookings] = useState([]);
    const [filteredArr,setFilteredArr] = useState([]);
    const [filteredBookingNameArr,setFilteredBookingNameArr] = useState([]);

    
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_USER')){
            history.push('/profile');
        }
    },[currentUser])



    const getAllBookings = async ()=>{
        try{
           const response = await orderService.getUserBookings(currentUser.id)
            // console.log("All bookings : ",response.data);
            let data = [];
            response.data.map((d)=>{
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
               
                let modifiedData = {
                    id:d._id,
                    date:finalDate,
                    sortDate:d.createdAt,
                    name:d.isMobileBooking ? d.bookingNameForMobile : d.booking && d.booking[0].name,
                    bookingFor:d.date + " " + d.timezone,
                    details:d.details && d.details,
                    userId:d.bookingBy && d.bookingBy[0]._id,
                    meetingUrl:d.meetingUrl ? d.meetingUrl : ''
                }

                data.push(modifiedData);
            })
            
            setAllBookings(data);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(()=>{
        getAllBookings();
    },[]);

    useEffect(()=>{
        let bookingNameFilterArr = [];
         allBookings.map((b)=>{
           if(b.name){
            bookingNameFilterArr.push({text:b.name ,value:b.name })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        bookingNameFilterArr = bookingNameFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setFilteredBookingNameArr(bookingNameFilterArr);
    },[allBookings])
    




    const columns = [
        {
            title: 'Created At',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            filters:filteredBookingNameArr,
            onFilter:(value, record) => record.name.indexOf(value) === 0,
        },
        {
            title: 'Meeting Date',
            dataIndex: 'bookingFor',
            key: 'bookingFor',
        },
        {
            title: 'Details',
            key: 'Details',
            render:(record)=>{
                return(<Tag color={'cyan'}>{record.details && record.details.slice(0,50)}...</Tag>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
             <Space size="middle">
                <Link to={`/profile/booking-details/${record.id}`}>
                 <Button type={'primary'}>View Details</Button>
                </Link>
              </Space>
              )
           
        },
    ]
   


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allBookings.length >0){
                filteredData= allBookings.filter((booking)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return booking.name && booking.name.toLowerCase().match(item.toLowerCase())
                });
                // console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }

    const handleCopyLink = (link)=>{
        navigator.clipboard.writeText(link);
        message.success("Copied Link to Clipboard!");
    }


    return(
        <div className="booking">
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Bookings</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}}
                         /> 
                </div>
            </div>
            <div className="booking__card__group">
            { filteredArr.length > 0 ?
                filteredArr.map((item)=>{
                return(<div className="card">
                   <div className="card-header"> 
                   <h3>{item.name}</h3>
                   <small>created at {item.date}</small>
                   </div>
                   <div className='card-body'>
                   <h4>Description: </h4>
                   <div>
                   {item.details}
                   </div>

                    <div className="my-2">
                    <b>Meeting Date: </b>
                   <div>
                    <Tag color={'blue'}>
                    <b>{item.bookingFor}</b>
                    </Tag>
                    </div>
                    </div>

                    <div className="my-2">
                    <b>Meeting Link:</b><br />
                    {item.meetingUrl ?
                    <div>
                    <a href={`https://${item.meetingUrl}`}
                      target="_blank" 
                      rel="noreferrer">
                      {item.meetingUrl}
                    </a>

                    <Button  size="middle" style={{float:'right'}} 
                    onClick={()=>handleCopyLink(item.meetingUrl)}>
                        <CopyTwoTone /> Copy Link
                    </Button>
                    </div>
                    
                       : 
                     <div>Not Found</div>}
                   
                    </div>
                   </div>
                </div>)
            })
            :
            allBookings.map((item)=>{
                return(<div className="card">
                   <div className="card-header"> 
                   <h3>{item.name}</h3>
                   <small>created at {item.date}</small>
                   </div>
                   <div className='card-body'>
                   <h4>Description: </h4>
                   <div>
                   {item.details}
                   </div>

                    <div className="my-2">
                    <b>Meeting Date: </b>
                   <div>
                    <Tag color={'blue'}>
                    <b>{item.bookingFor}</b>
                    </Tag>
                    </div>
                    </div>

                    <div className="my-2">
                    <b>Meeting Link:</b><br />
                    {item.meetingUrl ?
                    <div>
                    <a href={`https://${item.meetingUrl}`}
                      target="_blank" 
                      rel="noreferrer">
                      {item.meetingUrl}
                    </a>

                    <Button  size="middle" style={{float:'right'}} 
                    onClick={()=>handleCopyLink(item.meetingUrl)}>
                        <CopyTwoTone /> Copy Link
                    </Button>
                    </div>
                    
                       : 
                     <div>Not Found</div>}
                    </div>
                   </div>
                </div>)
            })
            
            }

            </div>


            {/* <Table dataSource={filteredArr.length > 0 ?  filteredArr : allBookings} columns={columns} /> */}
        </div>
    )
}


export default MyBookings;