import {SETCOINS, GETCOINS} from '../actions/types';

const initialState = {
  coinsDetails: null,
};

export default function coinReducer (state = initialState, action) {
  const {type, payload} = action;

  switch (type) {
    case SETCOINS:
      return {...state, coinsDetails: payload};

    case GETCOINS:
      return state;

    default:
      return state;
  }
}
