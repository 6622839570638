import React,{useEffect} from 'react';
import { Redirect, Route } from 'react-router';
import Navbar from './components/Navbar/Navbar';
import { history } from "./helpers/history";
import { useSelector } from 'react-redux';
import LoginForm from './components/LoginForm/LoginForm';


function RouteWithLoggedIn({component:Component, ...rest}){
    const { isLoggedIn } = useSelector((state) => state.auth);

   
    
    return(
        isLoggedIn ?
       <Route {...rest}>
        <Navbar history={history} />
        <Component />
       </Route>:
      <Redirect path="/auth/signin" />
      
       );
}

export default RouteWithLoggedIn;