import {combineReducers} from 'redux';
import auth from './auth';
import message from './message';
import update from './updateForm';
import landingReducer from './landingReducer';
import notificationReducer from './notification';
import coinReducer from './coinsReducer';

export default combineReducers ({
  auth,
  message,
  update,
  landingReducer,
  notificationReducer,
  coinReducer,
});
