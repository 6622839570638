import React, {useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';

import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import avatar from '../../assets/user.png';
import {Link} from 'react-router-dom';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';
import UserService from '../../services/user.service';

import {login, resetInvalidPasswordCount} from '../../actions/auth';
import './LoginForm.css';
import {message, Alert} from 'antd';

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const LoginForm = props => {
  const form = useRef ();
  const checkBtn = useRef ();

  const [username, setUsername] = useState ('');
  const [password, setPassword] = useState ('');
  const [loading, setLoading] = useState (false);
  const [isShown, setIsShown] = useState (false);
  const [isDisabled, setIsDisabled] = useState (false);
  const {isLoggedIn} = useSelector (state => state.auth);
  const {message: CurrentMessage} = useSelector (state => state.message);
  const {invalidPassword} = useSelector (state => state.auth);
  const dispatch = useDispatch ();
  const [currentDisabledUser, setCurrentDisabledUser] = useState (null);

  const onChangeUsername = e => {
    const uname = e.target.value;
    if (uname !== currentDisabledUser) {
      dispatch (resetInvalidPasswordCount ());
    }
    setUsername (uname);
  };
  const onChangePassword = e => {
    const password = e.target.value;
    setPassword (password);
  };

  const handleLogin = async e => {
    e.preventDefault ();

    // console.log('WRONG PASSWORD COUNT : ',invalidPassword);
    setLoading (true);
    try {
      const response = await UserService.getIsUserDisabled (username);
      // console.log(response.data);
      if (response.data === true) {
        setIsDisabled (true);
        message.error ('Your Account is Disabled Please Contact Admin!');
        setLoading (false);
        return;
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
    }

    if (invalidPassword >= 3) {
      makeAccountDisable (username);
      setLoading (false);
      return;
    }

    form.current.validateAll ();

    if (checkBtn.current.context._errors.length === 0) {
      dispatch (login (username, password))
        .then (() => {
          props.history.push ('/profile/codersarts-dashboard-home');
          window.location.reload ();
        })
        .catch (() => {
          setLoading (false);
        });
    } else {
      setLoading (false);
    }
  };

  if (isLoggedIn) {
    return <Redirect to="/profile/codersarts-dashboard-home" />;
  }

  const makeAccountDisable = async username => {
    try {
      const response = UserService.disabledUser (username, 'codersarts');
      // console.log(response);
      setIsDisabled (true);
      setCurrentDisabledUser (username);
      message.error ('Your Account is Disabled Please Contact Admin!');
    } catch (err) {
      console.log (err);
    }
  };

  return (
    <div className="col-md-6 loginForm">

      <div className="card card-container">
        <img src={avatar} alt="profile-img" className="profile-img-card" />

        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group">

            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
              placeholder="Enter Username or Email"
            />
          </div>

          <div className="form-group" style={{display: 'flex'}}>
            <Input
              type={isShown ? 'text' : 'password'}
              className="form-control input-password"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
              placeholder="Password"
            />
            {isShown
              ? <span
                  onClick={() => {
                    setIsShown (prevValue => !prevValue);
                  }}
                  className="eye-icon"
                >
                  <EyeOutlined style={{fontSize: '20px'}} />{' '}
                </span>
              : <span
                  onClick={() => {
                    setIsShown (prevValue => !prevValue);
                  }}
                  className="eye-icon"
                >
                  <EyeInvisibleOutlined style={{fontSize: '20px'}} />
                </span>}
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary btn-block"
              disabled={loading || username === '' || password === ''}
            >
              {loading && <span className="spinner-border spinner-border-sm" />}
              <span>Login</span>
            </button>
            <br />
            <div className="login__footer__link">
              <br />
              <Link to="/auth/signup">Sign Up ?</Link><br />
              <Link to="/auth/signin/forget-password">Forgot Password ?</Link>

            </div>
            <small><center>Or</center></small>
            <div className="loginWithMobile">
              <Link to="/auth/signin-with-phone-number">
                Login with Phone
              </Link>
            </div>

          </div>

          {CurrentMessage &&
            CurrentMessage.toLowerCase () === 'invalid password!'
            ? <Alert
                message={`Wrong Password Attempt ${invalidPassword} of 3`}
                description="After 3 wrong Password Attempts Your 
              Account will Automatically be Disabled !"
                type="error"
                showIcon
              />
            : CurrentMessage &&
                !isDisabled &&
                <Alert message={CurrentMessage} type="error" showIcon />}
          <CheckButton style={{display: 'none'}} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
