import axios from 'axios';
import authHeader from './auth-header';

// var API_URL = "http://3.7.156.63:3000/api/test/";
// var API_URL = "http://3.7.156.63/api/test/";

// var API_URL = 'http://localhost:3000/api/test/';

var API_URL = 'https://dashboard.codersarts.com/api/test/';

//  if (process.env.NODE_ENV === "production") {
//     API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/test/";
// }else if(process.env.NODE_ENV === "development"){
//     API_URL = "http://52.66.212.70:8080/api/test/";
//   }

// const updateDetailsByAdmin = async (data)=>{
//     if(data){
//         for(var propName in data){
//             if(data[propName] === null || data[propName] === undefined || data[propName] === ""){
//                 delete data[propName]
//             }
//         }

//         console.log(data);
//         return axios.post(API_URL + "update",data, { headers: authHeader()}).then(response=>{
//                 console.log(response.data)
//         })
//     }
// }

const updateDetails = async data => {
  // console.log("sending!")

  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }

    // console.log(data);
    return axios
      .post (API_URL + 'update', data, {headers: authHeader ()})
      .then (response => {
        // console.log(response.data)
        localStorage.setItem ('user', JSON.stringify (response.data));
        return response.data;
      });
  }
};

export default updateDetails;
