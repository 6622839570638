import React from 'react';
import adminIcon from  '../../../assets/settings.png';
import './DashboardHome.css';
import {Card} from 'antd';
import {ShoppingCartOutlined,DollarCircleOutlined,UserOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';


function AdminDashboard({documentCount}) {
    return (
        <div className='DashboardHome'>
             <div className='dashboard-card'>
            <h3>Statistics</h3>
            <hr />
            <div className='Stats'>
                    <Link to="/profile/view-orders">
                    <Card className="stat-card">
                    <div>
                    <ShoppingCartOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">Total Orders</div>
                        <div className='dashboard-card-total'>{documentCount.order ? documentCount.order : 0}</div>
                    </div>
                    </Card> 
                    </Link>


                    <Link to="/profile/manage-users">
                    <Card className="stat-card">
                    <div>
                    <UserOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">Total Users</div>
                        <div className='dashboard-card-total'>{documentCount.user ? documentCount.user : 0}</div>
                    </div>
                    </Card>
                    </Link>

                    <Link to="/profile/all-order-payments">
                    <Card className="stat-card">
                    <div>
                    <DollarCircleOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">All Payments</div>
                        <div className='dashboard-card-total'>{documentCount.payments ? documentCount.payments : 0}</div>
                    </div>
                    </Card>
                    </Link>
            </div>
            </div>

            <Link to="/profile">
            <div className='user-info-card'>
            <img src={adminIcon} className='dashboard-user-img' alt="icon" />
            <div className='dashboardUserType'>Administrator</div>
            </div>
            </Link>

        </div>
    )
}

export default AdminDashboard
