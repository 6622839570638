import React from 'react';
import { Button } from './Custom-Input.styles';


const InputButton = props => {
  
  const hiddenFileInput = React.useRef(null);
  
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
 
  const handleChange = event => {
   
    props.handleChange(event);
  };
  return (
    <>
      <Button onClick={handleClick}>
        <i className="fa fa-plus" style={{fontSize:'1.5rem'}} aria-hidden="true"></i>
      </Button>
      <input
        type="file"
        multiple={props.multiple}
        ref={hiddenFileInput}
        onChange={(e)=>handleChange(e)}
        style={{display: 'none'}} 
      />
    </>
  );
}
export default InputButton;