import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {clearMessage} from '../../actions/message';
import ProfileDropdown from './ProfileDropdown';
import './Navbar.css';
import avatar from '../../assets/user.png';
import coinImg from '../../assets/coin.png';
import {
  getHomePageDetails,
  getNotifications,
} from '../../actions/landingAction';
import {Dropdown, message, Modal, Tooltip} from 'antd';
import {BellOutlined, NotificationOutlined} from '@ant-design/icons';
import userService from '../../services/user.service';
import getLiveHelp from '../../assets/get-live-help.svg';
import freelance from '../../assets/small-task.svg';
import {io} from 'socket.io-client';
import {createUserCoins} from '../../actions/coinsAction';
import {getUserCoins} from '../../actions/coinsAction';

let socketConnection = 'https://dashboard.codersarts.com';
// let socketConnection =  "http://localhost:3000";
const Navbar = ({history}) => {
  const [showManagerBoard, setShowManagerBoard] = useState (false);
  const [showEmployeeBoard, setShowEmployeeBoard] = useState (false);
  const [showAdminBoard, setShowAdminBoard] = useState (false);
  const socket = useRef ();

  // const getHomePageDetails = async ()=>{
  //     try{
  //         const response = await userService.getHomeDetails();
  //         setLogoText(response.data[0].logoText);
  //         console.log(response.data);
  //     }catch(err){
  //         console.log(err);
  //     }
  // }

  const {user: currentUser} = useSelector (state => state.auth);
  const {coinsDetails} = useSelector (state => state.coinReducer);
  const {homeDetails} = useSelector (state => state.landingReducer);
  const {notifications} = useSelector (state => state.notificationReducer);
  const {tokenExpired} = useSelector (state => state.auth);
  const [unreadNotifications, setUnreadNotifications] = useState ([]);
  const [postRequestModal, setPostRequestModal] = useState (false);
  const dispatch = useDispatch ();
  // const [notifications,setNotifications] = useState([]);
  // console.log(coinsDetails);

  useEffect (
    () => {
      if (currentUser) {
        dispatch (getUserCoins ({userId: currentUser.id}));
      }
    },
    [currentUser,dispatch]
  );

  useEffect(()=>{
    if(coinsDetails){
     return;
    }else{
      if(currentUser && currentUser.roles.includes('ROLE_USER')){
        dispatch(createUserCoins({coins:0,userId:currentUser.id}));
      }
      
    }
  },[coinsDetails,dispatch,currentUser])

  const [notificationMenu, setNotificationMenu] = useState (
    <div className="notificationMenu">
      <ul>
        <li className="no-notification">No Notification!</li>
      </ul>
    </div>
  );

  const getRealtimeNotification = data => {
    if (data.user === currentUser.id) {
      dispatch (getNotifications (currentUser.id));
      message.success (data.notification);
    }
  };

  useEffect (() => {
    socket.current = io ();
    socket.current.on ('getNotification', data => {
      // console.log (data);
      getRealtimeNotification (data);
    });
  }, []);

  useEffect (
    () => {
      if (currentUser) {
        socket.current.emit ('addNotificationUser', currentUser.id);
      }
    },
    [currentUser]
  );

  useEffect (() => {
    dispatch (getHomePageDetails ());
  }, []);

  useEffect (
    () => {
      if (currentUser) {
        dispatch (getNotifications (currentUser.id));
      }
    },
    [currentUser,dispatch]
  );

  useEffect (
    () => {
      let filteredNotifications = notifications.filter (item => {
        return (
          item.isRead === false ||
          item.isRead === undefined ||
          item.isRead === '' ||
          item.isRead === null
        );
      });

      setUnreadNotifications (filteredNotifications);
    },
    [notifications]
  );

  const markAsRead = async id => {
    try {
      const response = await userService.markNotificationAsRead (id);
      // console.log(response.data);
      dispatch (getNotifications (currentUser.id));
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to mark as read notification!'
      );
    }
  };

  useEffect (
    () => {
      if (unreadNotifications.length > 0) {
        let newMenu = (
          <div className="notificationMenu">
            <ul>
              {unreadNotifications.map ((item, index) => {
                if (index < 2) {
                  return (
                    <li key={index}>
                      <NotificationOutlined className="notify" />
                      <div>
                        <b>{item.notification}</b>
                        <small>{item.date}</small>
                      </div>
                      <div>
                        <Tooltip title="mark as Read?" color={'teal'}>
                          <div
                            onClick={() => {
                              markAsRead (item._id);
                            }}
                          >
                            <i
                              class="fa fa-envelope-open-o deleteNotification"
                              aria-hidden="true"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
            <hr />
            <Link to="/profile/my-notifications" className="notificationLink">
              More
            </Link>
          </div>
        );

        setNotificationMenu (newMenu);
      } else {
        let newMenu = (
          <div className="notificationMenu">
            <ul>
              <li className="no-notification">No Notification!</li>
            </ul>
          </div>
        );

        setNotificationMenu (newMenu);
      }
    },
    [unreadNotifications]
  );

  function countDown () {
    let secondsToGo = 5;
    const modal = Modal.warning ({
      title: 'Session is Expired',
      content: `You will be redirected to login page after ${secondsToGo} second.`,
    });
    const timer = setInterval (() => {
      secondsToGo -= 1;
      modal.update ({
        content: `You will be redirected to login page after ${secondsToGo} second.`,
      });
    }, 1000);
    setTimeout (() => {
      clearInterval (timer);
      modal.destroy ();
      // console.log("logged out !");
      logOut ();
    }, secondsToGo * 1000);
  }

  useEffect (
    () => {
      if (tokenExpired) {
        countDown ();
      }
    },
    [tokenExpired]
  );

  useEffect (
    () => {
      history.listen (location => {
        dispatch (clearMessage ()); // clear message when changing location
      });
    },
    [dispatch]
  );

  useEffect (
    () => {
      if (currentUser) {
        setShowManagerBoard (currentUser.roles.includes ('ROLE_MANAGER'));
        setShowAdminBoard (currentUser.roles.includes ('ROLE_ADMIN'));
        setShowEmployeeBoard (currentUser.roles.includes ('ROLE_EMPLOYEE'));
      }
    },
    [currentUser]
  );

  const logOut = () => {
    dispatch (logout ());
    history.push ('/auth/signin');
    setShowAdminBoard (false);
    setShowEmployeeBoard (false);
    setShowManagerBoard (false);
  };

  const handlePostRequestModal = () => {
    setPostRequestModal (true);
  };

  return (
    <nav className="navbar px-2 navbar-expand navbar-dark back-blue">
      <div className="nav-left-items">
        <Link to={'/'} className="navbar-brand" style={{fontSize: '16px'}}>
          <img
            src={process.env.PUBLIC_URL + '/logo.png'}
            style={{height: '2rem', width: '2rem'}}
          />
          {' '}
          {homeDetails.logoText}
        </Link>
      </div>
      <div className="nav-right-items">
       

        {currentUser
          ? <div className="navbar-nav ml-auto">

              <div className="nav-item profile-items">
                <img
                  src={
                    currentUser.profilePicture
                      ? currentUser.profilePicture
                      : avatar
                  }
                  className="profile-avatar"
                  alt="img"
                />
              </div>
              <ProfileDropdown
                user={currentUser}
                name={currentUser.username ? currentUser.username : currentUser.email}
                logout={logOut}
              />
              <div className="notificationIconWrapper">
                <Dropdown overlay={notificationMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={e => e.preventDefault ()}
                  >
                    <BellOutlined className="notificationIcon" />
                    {unreadNotifications.length > 0 &&
                      <span className="notificationCountBadge">
                        {unreadNotifications.length}
                      </span>}
                  </a>
                </Dropdown>
              </div>
              {currentUser.roles.includes('ROLE_USER') &&
              <div className='coinArea'>
               
                <img src={coinImg} alt="coin" />
                <Tooltip placement='bottom' title="These coins will help you apply discounts on purchasing your orders!">
                <span>{coinsDetails ? coinsDetails?.totalCoins : 0} Coins</span>
                </Tooltip>
              
              </div>
              }
             
            </div>
          : <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={'/auth/signin'} className="nav-link">
                  Login
                </Link>
              </li>

              <li className="nav-item">
                <Link to={'/auth/signup'} className="nav-link">
                  Sign Up
                </Link>
              </li>

          
            
          
            </div>
           
            }
            {currentUser &&
          currentUser.roles[0].toLowerCase () === 'role_user' &&
          <button
            className="primaryBtn"
          
            onClick={handlePostRequestModal}
          >
            Post Request
          </button>}
            <Link to="/events/register" className="primaryBtn" style={{marginTop:'5px'}}>
                  Events
            </Link>
      </div>
      <Modal
        title="Choose Your Request"
        visible={postRequestModal}
        footer={null}
        onCancel={() => {
          setPostRequestModal (false);
        }}
      >
        <div className="bookingCardsUser" style={{boxShadow: 'none'}}>
          <div
            className="singleBookingCard"
            onClick={() => {
              setPostRequestModal (false);
            }}
          >
            <Link to="/profile/book-a-session">
              <div>
                <img src={getLiveHelp} alt="book-session" />
              </div>
              <div>
                <h4>Book Your session</h4>
              </div>
              <div>
                <small>Book live 1:1 Session</small>
              </div>
            </Link>

          </div>
          <div
            className="singleBookingCard"
            onClick={() => {
              setPostRequestModal (false);
            }}
          >
            <Link to="/profile/user/create-order">
              <div>
                <img src={freelance} alt="assignment-help" />
              </div>
              <div>
                <h4>Get Assignment Help</h4>
              </div>
              <div>
                <small>help in Projects,Assignments etc.</small>
              </div>
            </Link>
          </div>

        </div>

      </Modal>
    </nav>
  );
};

export default Navbar;
