import React from 'react';
import './Checkout.css';
import {Tabs} from 'antd';
import StripeForm from './StripeForm/StripeForm';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import Razorpay from '../../../../Razorpay/Razorpay';

const {TabPane} = Tabs;

const stripePromise = loadStripe (
  'pk_live_51JQbRNSB5a1UKLbSiZ51EixOqSnJdt5vtPdpj39w5JeJVvIKWFZDP9MSPlloPE3K5ONwDz1N4ztTVzcWgsziZrsR00mpCLICR7'
);

// const stripePromise = loadStripe (
//   'pk_test_51BTUDGJAJfZb9HEBwDg86TN1KNprHjkfipXmEDMb0gSCassK5T3ZfxsAbcgKVmAIXF7oZ6ItlZZbXO6idTHE67IM007EwQ4uN3'
// );

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
function Checkout({amount, order, qty, isPartial, orderAmount, coins, rate}) {
  return (
    <div>
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Outside India" key="1">
          <Elements stripe={stripePromise}>
            <StripeForm
              amount={amount}
              orderAmount={orderAmount}
              qty={qty}
              isPartial={isPartial}
              order={order}
              coins={coins}
            />
          </Elements>
        </TabPane>
        <TabPane tab="Inside India" key="2">
          <Razorpay
            rate={rate}
            amount={amount}
            orderAmount={orderAmount}
            qty={qty}
            isPartial={isPartial}
            order={order}
            coins={coins}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Checkout;
