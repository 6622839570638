import React, {useEffect, useState} from 'react';
import Message from '../../Message/Message';
import './CreateOrderPublic.css';
import OrderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import GoogleServices from '../../../services/google.services';
import {Select, message, Checkbox, Spin, Modal} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import LoginPopup from './LoginPopup';
import S3 from 'react-aws-s3';
import {LoadingOutlined} from '@ant-design/icons';
import {loginFromCreateOrder} from '../../../actions/auth';
const {Option} = Select;
const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

const CreateOrderPublic = () => {
  const [name, setName] = useState ('');
  const [details, setDetails] = useState ('');
  const [deadline, setDeadline] = useState ('');
  const [category, setCategory] = useState ('');
  const [otherCategory, setOtherCategory] = useState ('');
  const [documentation, setDocumentation] = useState ('');
  const [bookingSession, setBookingSession] = useState ('');
  const [fileUrl, setFileUrl] = useState (null);
  const [uploading, setUploading] = useState (false);
  const [successMessage, setSuccessMessage] = useState (false);
  const [awsData, setAwsData] = useState (null);
  const {user: currentUser} = useSelector (state => state.auth);
  const [timezone, setTimezone] = useState (null);
  const [loading, setLoading] = useState (false);
  const [loginModal, setLoginModal] = useState (false);
  const [allOrderCategory, setAllOrderCategory] = useState ([]);
  const [highlight1, setHighlight1] = useState (false);
  const [highlight2, setHighlight2] = useState (false);
  const [highlight3, setHighlight3] = useState (false);
  const [highlight4, setHighlight4] = useState (false);
  const [highlight5, setHighlight5] = useState (false);
  const [confirmationModal, setConfirmationModal] = useState (false);
  const [technologies, setTechnologies] = useState ([]);
  const [skills, setSkills] = useState ([]);
  const [type, setType] = useState ('');
  const dispatch = useDispatch ();

  // console.log("CURRENT USER : ",currentUser);

  const getSkillsList = async () => {
    try {
      const response = await UserService.getAllSkillsList ();
      if (response) {
        setSkills (response.data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const getAllOrderCategoriesFunc = async () => {
    try {
      const response = await OrderService.getAllOrderCategory ();
      // console.log("Categories : ",response.data);
      let categories = [];
      response.data.map (item => {
        categories.push (item.name);
      });
      setAllOrderCategory (categories);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : ' Unable  to Get all Orders Categories!'
      );
    }
  };

  var today = new Date ();
  var dd = String (today.getDate ()).padStart (2, '0');
  var mm = String (today.getMonth () + 1).padStart (2, '0'); //January is 0!
  var yyyy = today.getFullYear ();
  today = yyyy + '-' + mm + '-' + dd;
  // console.log(today);

  const getAwsInfo = async () => {
    try {
      const res = await OrderService.getAwsInfo ();

      setAwsData (res.data);
    } catch (err) {
      console.log (err);
    }
  };
  useEffect (() => {
    getSkillsList ();
    getAwsInfo ();
    getAllOrderCategoriesFunc ();
  }, []);

  const handleModalCancel = () => {
    setLoginModal (false);
  };
  const handleFileChange = async file => {
    if (awsData) {
      setUploading (true);

      const config = {
        bucketName: awsData.bucketName,
        region: 'ap-south-1',
        accessKeyId: awsData.accessKeyId,
        secretAccessKey: awsData.secretKey,
        s3Url: 'https://' + awsData.bucketName + '.s3.amazonaws.com',
      };

      const ReactS3Client = new S3 (config);
      ReactS3Client.uploadFile (file, file.name)
        .then (data => {
          // console.log(data);
          setFileUrl (data.location);
          setUploading (false);
        })
        .catch (err => {
          console.log (err);
          setUploading (false);
        });
    }
  };

  useEffect (
    () => {
      if (fileUrl) {
        setUploading (false);
        // console.log(fileUrl);
      }
    },
    [fileUrl]
  );

  useEffect (() => {
    if (successMessage) {
      setSuccessMessage (false);
    }
  });

  useEffect (
    () => {
      if (fileUrl) {
        setUploading (false);
        // console.log(fileUrl);
      }
    },
    [fileUrl]
  );

  // const handleSignup = (username,email,password)=>{

  // }

  const handleLogin = values => {
    setLoading (true);

    dispatch (loginFromCreateOrder (values.username, values.password))
      .then (() => {
        setLoginModal (false);
        setLoading (false);
        message.success ('Logged in Successfully!');
      })
      .catch (() => {
        setLoading (false);
        message.error ('Unable to Login !');
      });
  };

  const sendMail = async (email, order) => {
    // console.log("Mail : ",order.userMail);
    if (email) {
      try {
        const data = {
          To: email,
          Subject: `${order.name} #${order._id}: New Order Submission`,
          Write: `
                    <h3><b>Order Details</b></h3>
                    <hr/>
                    <p><b>Order No. : </b>${order._id}</p>
                    <p><b>Order Title : </b>${order.name}</p>
                    <p><b>Order Deadline : </b>${deadline}</p>
                    <p><b>Timezone: : </b>${timezone}</p>
                    <p><b>Client Username : </b>${currentUser.username}</p>
                    <p><b>Client Email : </b>${currentUser.email}</p>
                    <br/>
                    <br/>
                    <p>Order Created successfullly</p>
                    `,
          attachment: false,
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${email} !`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
    }
  };

  // const checkLogin = (e)=>{
  //     e.preventDefault();
  //     if(currentUser){
  //         createOrder();
  //     }else{
  //         setLoginModal(true);
  //     }
  // }

  // const createOrder = (e)=>{
  //     e.preventDefault()
  //     setLoading(true);

  //     // console.log(bookingSession);
  //     // console.log(documentation);
  //     if(otherCategory){
  //         OrderService.createOrderCategory(otherCategory).then((result)=>{
  //             // console.log(result);
  //         }).catch((err)=>{
  //             console.log(err);
  //             message.error(err.message ? err.message : 'Unable to add new order category!');
  //         })
  //     }

  //     // console.log("CURRENT USER IN CREATE ORDER: ",currentUser);
  //     const data = {
  //         name:name,
  //         details:details,
  //         deadline:deadline,
  //         category:otherCategory !=="" ?  otherCategory: (category.toLowerCase() !== "select category" ? category:null),
  //         documentation:documentation === "" ? false:documentation,
  //         bookingSession:bookingSession === ""? false:bookingSession,
  //         assignedTo:currentUser.id,
  //         fileUrl:fileUrl,
  //         createdBy:currentUser.id,
  //         customer:currentUser.id,
  //         timezone:timezone

  //     }
  //     // console.log("Order Data : ",data);
  //     OrderService.createOrder(data).then((response)=>{
  //         // console.log(response.data);
  //         setSuccessMessage(true);
  //         setLoading(false);
  //     }).catch((err)=>{
  //         console.log(err);
  //         setLoading(false);
  //         message.error(err.message ? err.message : "Unable to create order!");
  //     })

  //     sendMail(currentUser.email);
  //     // console.log(data);
  //     setLoading(false);
  // }

  const createAutomaticOutsource = async data => {
    let finalData = {
      orderId: data._id,
      allReviews: [],
      technologies: data.technologies,
    };
    // console.log(finalData);
    try {
      const response = await OrderService.createOutsourceOrder (finalData);
      console.log (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const createOrder = async e => {
    e.preventDefault ();
    setLoading (true);

    // console.log(bookingSession);
    // console.log(documentation);
    if (otherCategory) {
      try {
        const result = await OrderService.createOrderCategory (otherCategory);
      } catch (err) {
        console.log (err);
        message.error (
          err.message ? err.message : 'Unable to add new order category!'
        );
      }
    }

    const data = {
      name: name,
      details: details,
      deadline: deadline,
      category: otherCategory !== ''
        ? otherCategory
        : category.toLowerCase () !== 'select category' ? category : null,
      documentation: documentation === '' ? false : documentation,
      bookingSession: bookingSession === '' ? false : bookingSession,
      assignedTo: currentUser.id,
      fileUrl: fileUrl,
      createdBy: currentUser.id,
      customer: currentUser.id,
      timezone: timezone,
      type: type,
      technologies: technologies,
    };

    try {
      const response = await OrderService.createOrder (data);
      //   console.log ('result : ', response);
      let {order} = response;
      if (order) {
        sendMail (currentUser.email, order);
        createAutomaticOutsource (order);
        //   sendMail ('contact@codersarts.com', order);
        setSuccessMessage (true);
        setLoading (false);
        setTimeout (() => {
          setConfirmationModal (true);
        }, 3000);
      } else {
        let {msg} = response;
        message.info (msg);
        setLoading (false);
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Unable to create order!');
    }

    // console.log(data);
  };

  const checkInputField = (value, name) => {
    // console.log("Value : ",value);
    // console.log("Name : ",name);
    if (value === '' && name === 'projectName') {
      setHighlight1 (true);
    }
    if (value === '' && name === 'deadline') {
      // console.log("setting deadline!");
      setHighlight2 (true);
    }
    if ((value === '' || value === 'Select Category') && name === 'category') {
      setHighlight3 (true);
    }
    if (value === '' && name === 'otherCategory') {
      setHighlight4 (true);
    }
    if (value === '' && name === 'details') {
      setHighlight5 (true);
    }
  };

  const handleProjectTypeChange = values => {
    setType (values);
  };

  const handleTechnologySelect = values => {
    // console.log(values);
    setTechnologies (values);
  };

  return (
    <div className="createOrderPublic">
      <Spin tip="Uploading..." spinning={uploading}>
        <h3><b>Create Order</b></h3>
        <hr />
        <form>
          <div className="form-inline">
            <div className="form-group">
              <label>
                Project Name<sup className="requiredStar">*</sup> :{' '}
              </label>
              <input
                style={{width: '95%'}}
                type="text"
                required
                name="projectName"
                placeholder="e.g. Looking help in MERN stack Project"
                onBlur={e => {
                  checkInputField (e.target.value, e.target.name);
                }}
                className={`form-control ${highlight1 && name === '' && 'errorField'}`}
                value={name}
                onChange={e => {
                  setName (e.target.value);
                }}
              />
              {highlight1 &&
                name === '' &&
                'errorField' &&
                <small className="error">Required!</small>}

            </div>

          </div>
          <div className="form-inline">
            <div className="form-group">
              <label>Timezone : </label>
              <input
                type="text"
                placeholder="e.g. 10 PM EST"
                required
                className="form-control"
                value={timezone}
                onChange={e => {
                  setTimezone (e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label>Deadline<sup className="requiredStar">*</sup> : </label>
              <input
                type="date"
                required
                name="deadline"
                onBlur={e => {
                  checkInputField (e.target.value, e.target.name);
                }}
                className={`form-control ${highlight2 && deadline === '' && 'errorField'}`}
                min={today}
                value={deadline}
                onChange={e => {
                  setDeadline (e.target.value);
                }}
              />
              {highlight2 &&
                deadline === '' &&
                <small className="error">Required!</small>}
            </div>
          </div>

          <div className="form-inline">

            <div className="form-group">
              <label>Category<sup className="requiredStar">*</sup> : </label>
              <select
                value={category}
                required
                className={`form-control ${highlight3 && category === '' && 'errorField'}`}
                name="category"
                onBlur={e => {
                  checkInputField (e.target.value, e.target.name);
                }}
                id="categorySelect"
                onChange={e => {
                  setCategory (e.target.value);
                }}
              >
                <option>Select Category</option>
                {allOrderCategory &&
                  allOrderCategory.map ((name, index) => {
                    return <option key={index}>{name}</option>;
                  })}
                <option>Other</option>
              </select>
              {highlight3 &&
                category === '' &&
                <small className="error">Required!</small>}
            </div>
            {category.toLowerCase () === 'other' &&
              <div className="form-group">
                <label>
                  Specify Other Category
                  <sup className="requiredStar">*</sup>
                  {' '}
                  :
                  {' '}
                </label>
                <input
                  type="text"
                  required
                  value={otherCategory}
                  className={`form-control ${highlight4 && otherCategory === '' && 'errorField'}`}
                  name="otherCategory"
                  onBlur={e => {
                    checkInputField (e.target.value, e.target.name);
                  }}
                  onChange={e => {
                    setOtherCategory (e.target.value);
                  }}
                />
                {highlight4 &&
                  otherCategory === '' &&
                  <small className="error">Required!</small>}

              </div>}
          </div>

          <div className="form-group">
            <div><label>Technologies Used:</label></div>
            <div>
              <Select
                showSearch
                mode={'multiple'}
                virtual={true}
                size={'large'}
                style={{width: '95%', fontWeight: 'bold'}}
                onChange={handleTechnologySelect}
                placeholder="Search or Select a technology"
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase ().indexOf (input.toLowerCase ()) >=
                  0}
                filterSort={(optionA, optionB) =>
                  optionA.value
                    .toLowerCase ()
                    .localeCompare (optionB.value.toLowerCase ())}
              >
                {skills &&
                  skills.map ((item, index) => {
                    return (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="form-group">
            <div><label>Type of Order:</label></div>
            <div>
              <Select
                defaultValue={'New Project'}
                style={{width: '95%', fontWeight: 'bold'}}
                className="formInputs"
                size={'large'}
                onChange={handleProjectTypeChange}
              >
                <Option value="New Project">New Project</Option>
                <Option value="Existing Project">Existing Project</Option>
                <Option value="Debugging">Debugging</Option>
                <Option value="Assignment">Assignment</Option>
              </Select>
            </div>
          </div>

          <div className="form-inline">
            <div className="form-group">
              <label>Details<sup className="requiredStar">*</sup> : </label>
              <textarea
                required
                value={details}
                className={`form-control ${highlight5 && details === '' && 'errorField'}`}
                name="details"
                onBlur={e => {
                  checkInputField (e.target.value, e.target.name);
                }}
                onChange={e => {
                  setDetails (e.target.value);
                }}
              />
              {highlight5 &&
                details === '' &&
                <small className="error">Required!</small>}

            </div>
          </div>
          <div className="form-inline">
            <div className="form-group">

              <Checkbox
                checked={bookingSession}
                onChange={e => {
                  setBookingSession (e.target.checked);
                }}
              >
                <label>Do You Need Code Walkthrough of Solution? </label>{' '}
              </Checkbox>

            </div>
            <div className="form-group">

              <Checkbox
                checked={documentation}
                onChange={e => {
                  setDocumentation (e.target.checked);
                }}
              >
                <label>Need Write up or report ? </label>{' '}
              </Checkbox>

            </div>

          </div>

          <div className="form-group">
            <label> Upload Project Docs: </label>
            <input
              type="file"
              className="form-control file"
              onChange={e => {
                handleFileChange (e.target.files[0]);
              }}
            />
          </div>
          <div className="uploadMultipleMsg">
            <p>
              <b>
                For Uploading Multiple Docs Please Compress your files in a single file e.g. Zip File
              </b>
            </p>
          </div>
          <br />

          <div className="form-group">
            {currentUser
              ? <button
                  style={{width: 'fit-content'}}
                  className="pay-btn"
                  disabled={
                    name === '' ||
                      details === '' ||
                      deadline === '' ||
                      category === ''
                  }
                  onClick={e => {
                    createOrder (e);
                  }}
                >
                  {loading ? <Spin indicator={spinIcon} /> : 'Create Order'}
                </button>
              : <button
                  style={{width: 'fit-content'}}
                  className="pay-btn"
                  type="button"
                  disabled={
                    name === '' ||
                      details === '' ||
                      deadline === '' ||
                      category === ''
                  }
                  onClick={() => {
                    setLoginModal (true);
                  }}
                >
                  {loading ? <Spin indicator={spinIcon} /> : 'Login'}
                </button>}
          </div>

        </form>

        {successMessage
          ? <Message type="success" message="Created Order Successfully" />
          : null}

      </Spin>

      <LoginPopup
        loading={loading}
        visible={loginModal}
        onLogin={handleLogin}
        onCancel={handleModalCancel}
      />

      <Modal
        title="Order Submitted"
        visible={confirmationModal}
        onCancel={() => setConfirmationModal (false)}
        footer={false}
      >
        <div style={{fontStyle: 'normal'}}>

          <h3 style={{color: 'green', marginBottom: '5px'}}>
            Your Order is Submitted Successfully.
          </h3>
          <div style={{fontSize: '14px', color: 'gray', textAlign: 'justify'}}>
            We will send a Confirmation mail regarding your order in just 1-2 hours.
            Make sure to check your email for further order processing.
            You can also contact us at contact@codersarts.com for more information.
          </div>

        </div>
      </Modal>
    </div>
  );
};

export default CreateOrderPublic;
