import React from 'react'
import '../../../components/Chat/message/message.css';
import './GroupMessage.css';
import { format } from "timeago.js";
import Avatar from '../../../assets/user.png';
import {DownloadOutlined} from '@ant-design/icons';


function GroupMessage(props) {
var preview = <p className="messageText">{props.message.message}</p>;
let message = props.message;
  if(message.msgType){
    if(message.msgType.split('/')[0]==='video'){
      // console.log("FILE URL : ",message.fileUrl);
      preview =  (<video width="300" height="200" controls loop >
                  <source src={message.fileUrl} type="video/mp4"/>
                </video>)
    }
    else if(message.msgType.split('/')[0]==='image'){
      // console.log("FILE URL : ",message.fileUrl);
      preview = <a href={message.fileUrl} target="_blank" download={true}>
      <img className="msgImage" src={message.fileUrl} 
      style={{height:'200px',width:'300px',objectFit:'cover'}} 
      alt={message.message} /></a>
    }
    else if(message.msgType.split('/')[0]==='application'){
      // console.log("FILE URL : ",message.fileUrl);
      preview = (<div className={`otherFileType ${props.own ? 'otherOwn' : null}`}>
        <div style={{marginLeft:'10px'}}>
        {message.message}
        </div>
          
         <div>
         <a href={message.fileUrl} target="_blank" className="download-icon">
         <DownloadOutlined style={{fontSize:'1.8rem',marginLeft:'0.8rem'}} />
        </a>
         </div>
       </div>)
    }
  
  }

    return (
        <div className={props.own ? 'message own' : 'message'}>
           <div className="messageTop">
           <img 
            src={props.sender.profilePicture ? props.sender.profilePicture : Avatar} 
            className="groupMsgAvatar" 
            alt="dp" />
                {preview}
           </div>
             <div className="messageBottom">
            {format(props.createdAt)} By {props.sender.username}
            </div>
        </div>
        
    )
}

export default GroupMessage
