
import {
    UPDATE_SUCCESS,
    UPDATE_FAIL,
    SET_MESSAGE,
  } from "./types";
  
import updateDetails from "../services/updateService";



export const update = (data) => (dispatch) => {
return updateDetails(data).then(
    (response) => {
    dispatch({
        type: UPDATE_SUCCESS,
        payload:{user:response}
    });

    dispatch({
        type: SET_MESSAGE,
        payload: response.message,
    });

    return response;
},
(error) => {
    const message =
    (error.response &&
        error.response.data &&
        error.response.data.message) ||
    error.message ||
    error.toString();

    dispatch({
    type: UPDATE_FAIL,
    });

    dispatch({
    type: SET_MESSAGE,
    payload: message,
    });

    return error;
}
);
};