import React, { useEffect,useState } from 'react';
import user from  '../../../assets/userDashboard.png';
import './DashboardHome.css';
import {Card} from 'antd';
import {ShoppingCartOutlined,DollarCircleOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import getLiveHelp from '../../../assets/get-live-help.svg';
import freelance from '../../../assets/small-task.svg';
function UsersDashboard({userData}) {
    const [paymentCount,setPaymentCount] = useState(0);
    const [activeOrdersCount,setActiveOrdersCount] = useState(0);
    const [latestOrder,setLatestOrder] = useState("");
    useEffect(()=>{
        let count = 0;
        let active = 0;
        // console.log("USER ORDERS : ",userData.userOrders)
        if(userData.userOrders && userData.userOrders.length > 0){
            userData.userOrders.map((o)=>{
                 o.status && o.status.toLowerCase()==="delivered" && count++
            }) 
            userData.userOrders.map((o)=>{
                if(o.status && 
                   ( o.status.toLowerCase()==='development'
                || o.status.toLowerCase()==='testing'
                || o.status.toLowerCase()==='deal confirmed')){
                 active++
             }
             }); 
             setLatestOrder(userData.userOrders[0])
        }
            //   console.log("active count :", active);
        setActiveOrdersCount(active);
        
        setPaymentCount(count);
    },[userData])
    return (
      <div>
      <h4><b>Get Started</b></h4>
         <div className='bookingCardsUser'>
                <div className="singleBookingCard">
                <Link to="/profile/book-a-session">
                    <div>
                    <img src={getLiveHelp} alt="book-session" />
                    </div>
                    <div>
                        <h4>Book Your session</h4>
                    </div>
                    <div>
                        <small>Book live 1:1 Session</small>
                    </div>
                 </Link>

                </div>
                <div className="singleBookingCard">
                <Link to="/profile/user/create-order">
                    <div>
                    <img src={freelance} alt="assignment-help" />
                    </div>
                    <div>
                        <h4>Get Assignment Help</h4>
                    </div>
                    <div>
                        <small>help in Projects,Assignments etc.</small>
                    </div>
                </Link>
                </div>
            
        </div>
        <div className='DashboardHome'>
            
         <div className='dashboard-card'>
            <h3>Statistics</h3>
            <hr />
            <div className='Stats'>
                    <Link to="/profile/user-orders">
                    <Card className="stat-card">
                    <div>
                    <ShoppingCartOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">Total Orders</div>
                        <div className='dashboard-card-total'>{userData.orderCount ? userData.orderCount : 0}</div>
                    </div>
                    </Card> 
                    </Link>

                    <Link to="/profile/user-orders">
                    <Card className="stat-card">
                    <div>
                    <ShoppingCartOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">Active Orders</div>
                        <div className='dashboard-card-total'>{activeOrdersCount}</div>
                    </div>
                    </Card> 
                    </Link>


                    <Link to="/profile/purchased-orders">
                    <Card className="stat-card">
                    <div>
                    <DollarCircleOutlined className='stat-icon' />
                    </div>
                    <div className='cart-total'>
                        <div className="total-label">Purchased Orders</div>
                        <div className='dashboard-card-total'>{paymentCount}</div>
                    </div>
                    </Card>
                    </Link>


            </div>
            

                <div className='recentOrder'>
                    <h4>Recent Order</h4>
                    <Link style={{marginLeft:'3%'}} to={`/profile/view-order-details/${latestOrder._id}`}>{latestOrder.name}</Link>
                </div>
            </div>

            <div >
             <Link to="/profile">
                <div class="singleBookingCard" style={{float:'right'}}>
                    <div className='user-info-card'>
                    <img src={user} className='dashboard-user-img' alt="icon" />
                    <div className='dashboardUserType'>User</div>
                    </div>
                </div>
            </Link>
            </div>

            

        </div>
      </div>
    )
}

export default UsersDashboard
