import React,{useEffect} from "react";
import SingleUserForm from "./SingleUserForm";
import {Link} from 'react-router-dom';
import { Breadcrumb } from "antd";
import { history } from "../../../helpers/history";
import { useSelector } from "react-redux";


const EditSingleUser = ({match}) => {

  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(()=>{
    if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
        if(currentUser.roles.includes('ROLE_SALES')){
            return;
        }
        history.push('/profile');
    }
},[currentUser])
  
 
  return (
    <div className="container">
    <div>
      <Breadcrumb>
            <Breadcrumb.Item>{currentUser.roles.includes('ROLE_SALES') ? <Link to="/profile/users-for-sales-role">All Users</Link> : <Link to="/profile/manage-users">All Users</Link>}</Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/manage-users/${match.params.slug}`}>{match.params.slug}</Link></Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <br />
    <h3>Edit {match.params.slug}</h3>
    <hr />
        <SingleUserForm currentUser={currentUser} username={match.params.slug} />
    </div>
  );
};

export default EditSingleUser;
