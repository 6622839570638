import React,{useEffect,useState} from 'react'
import './ManageLanding.css';
import userService from '../../../services/user.service';
import Message from '../../Message/Message';
import {useSelector,useDispatch} from 'react-redux';
import { getHomePageDetails, setHomeDetails } from '../../../actions/landingAction';
import { history } from '../../../helpers/history';


function ManageLanding() {
    const [heading,setHeading] = useState(null);
    const [details,setDetails] = useState(null);
    const [logoText,setLogoText] = useState(null);
    const [successMessage,setSuccessMessage] = useState(false);
    const [errorMessage,setErrorMessage] = useState(false);
    const [id,setId] = useState(null);
    const {homeDetails} = useSelector((state) => state.landingReducer);
    const  dispatch = useDispatch();
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])
   
    
    useEffect(()=>{
        if(successMessage){
            setSuccessMessage(false);
        }
        if(errorMessage){
            setErrorMessage(false);
        }
    })
    useEffect(()=>{
        dispatch(getHomePageDetails());
        setHomePageDetails();
        // console.log("FOUND STATE : ",homeDetails);
    },[]);
    const setHomePageDetails =  ()=>{
            // console.log(homeDetails); 
            setHeading(homeDetails.heading);
            setDetails(homeDetails.details);
            setLogoText(homeDetails.logoText);
            setId(homeDetails.id);
            // console.log("Id : ",homeDetails.id);
            
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const updatedData = {
            id:id,
            heading:heading,
            details:details,
            logoText:logoText
        }

        // console.log("FINAL DATA ",updatedData);
        userService.updateHomeDetails(updatedData).then(result=>{
            // console.log(result);
            setSuccessMessage(true);
            dispatch(setHomeDetails(updatedData));
            dispatch(getHomePageDetails());
        }).catch(err=>{
            console.log(err);
            setErrorMessage(true);
        })
    }
    return (
        <div className="container edit-home">
            <div>
                <h2>Edit Home</h2>
                <hr />
            </div>
            <div>
                <form>
                    <div className="form-group">
                        <label>
                            Enter Text For Logo : 
                        </label>
                        <input  type="text" value={logoText} onChange={(e)=>{setLogoText(e.target.value)}}  className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>
                           Enter Text For Home Page Heading :
                        </label>
                        <input  type="text" value={heading} onChange={(e)=>{setHeading(e.target.value)}}  className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>
                            Enter Text For Home Page Description : 
                        </label>
                       <textarea  value={details} onChange={(e)=>{setDetails(e.target.value)}} className="form-control"></textarea>
                    </div>
                    
                    <button type="submit" onClick={handleSubmit} className="edit-home-btn">Update Changes</button>
                </form>

            </div>
            {successMessage && <Message type="success" message="Updated Home Page Successfully !" />}
            {errorMessage && <Message type="error" message="Something Went Wrong !" />}
        </div>
    )
}

export default ManageLanding
