//login, register, logout actions

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  INVALID_PASSWORD,
  RESET_INVALID_PASSWORD,
  TOKEN_EXPIRED,
} from './types';

import AuthService from '../services/auth.service';

export const register = userData => dispatch => {
  return AuthService.register (userData).then (
    response => {
      dispatch ({
        type: REGISTER_SUCCESS,
      });

      dispatch ({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve ();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();

      dispatch ({
        type: REGISTER_FAIL,
      });

      dispatch ({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject ();
    }
  );
};

export const login = (username, password) => dispatch => {
  return AuthService.login (username, password).then (
    data => {
      dispatch ({
        type: LOGIN_SUCCESS,
        payload: {user: data},
      });

      // runLogOutTimer(dispatch,3000000);
      return Promise.resolve ();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();
      if (message.toLowerCase () === 'invalid password!') {
        dispatch ({
          type: INVALID_PASSWORD,
        });
      }

      dispatch ({
        type: LOGIN_FAIL,
      });
      dispatch ({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject ();
    }
  );
};

export const loginWithPhone = phoneNumber => dispatch => {
  return AuthService.loginWithPhone (phoneNumber).then (
    data => {
      dispatch ({
        type: LOGIN_SUCCESS,
        payload: {user: data},
      });

      // runLogOutTimer(dispatch,3000000);
      return Promise.resolve ();
    },
    error => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString ();
      if (message.toLowerCase () === 'invalid password!') {
        dispatch ({
          type: INVALID_PASSWORD,
        });
      }

      dispatch ({
        type: LOGIN_FAIL,
      });
      dispatch ({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject ();
    }
  );
};

export const loginFromCreateOrder = (username, password) => async dispatch => {
  try {
    const data = await AuthService.login (username, password);
    dispatch ({
      type: LOGIN_SUCCESS,
      payload: {user: data},
    });

    return Promise.resolve ();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString ();
    if (message.toLowerCase () === 'invalid password!') {
      dispatch ({
        type: INVALID_PASSWORD,
      });
    }

    dispatch ({
      type: LOGIN_FAIL,
    });
    dispatch ({
      type: SET_MESSAGE,
      payload: message,
    });

    return Promise.reject ();
  }
};

export const logout = () => dispatch => {
  AuthService.logout ();

  dispatch ({
    type: LOGOUT,
  });
};

export const resetInvalidPasswordCount = () => {
  return {
    type: RESET_INVALID_PASSWORD,
  };
};

// export const showSessionExpire = ()=>{
//   return{
//     type:SESSION_EXPIR
//   }
// }

function runLogOutTimer (dispatch, timer) {
  setTimeout (() => {
    // dispatch(showSessionExpire());
    dispatch ({
      type: TOKEN_EXPIRED,
    });
  }, timer);
}
