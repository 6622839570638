import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import EventsServices from '../../../services/events.services';

import {Link} from 'react-router-dom';
import {history} from '../../../helpers/history';
import {
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {message, Button, Dropdown, Menu, Table} from 'antd';
import styles from './AdminEvents.module.css';

const AdminEvents = () => {
  const {user: currentUser} = useSelector (state => state.auth);
  const [allEvents, setAllEvents] = useState ([]);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllEvents = async () => {
    try {
      const response = await EventsServices.getAllEvents ();
      if (response.data) {
        // console.log (response.data);
        setAllEvents (response.data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  useEffect (() => {
    let isMounted = true;

    const getAllEvents = async () => {
      try {
        const response = await EventsServices.getAllEvents ();
        if (response.data) {
          //   console.log (response.data);
          setAllEvents (response.data);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    if (isMounted) {
      getAllEvents ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleCreateNew = () => {
    history.push ('/profile/create-new-event');
  };

  const handleDelete = async id => {
    try {
      const response = await EventsServices.deleteSingleEvent ({id: id});
      if (response.data) {
        getAllEvents ();
        message.success ('Deleted Event Successfully!');
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const columns = [
    {
      title: 'Created At',
      key: 'createdAt',
      render: record => {
        let date = new Date (record.createdAt).toUTCString ();
        return <div>{date}</div>;
      },
    },
    {
      title: 'Event',
      key: 'title',
      render: record => {
        return (
          <Link to={`/profile/view-event-details/${record._id}`}>
            <b>{record.title}</b>
          </Link>
        );
      },
    },
    {
      title: 'Event Date',
      dataIndex: 'eventDate',
      key: 'title',
    },
    {
      title: 'Description',
      key: 'description',
      render: record => {
        return (
          <div>
            {record.description.slice (0, 100)}
            {record.description.length > 100 &&
              <span>
                ...
                <Link to={`/profile/view-event-details/${record._id}`}>
                  More
                </Link>
              </span>}
          </div>
        );
      },
    },
    {
      title: 'Action',
      render: record => {
        return (
          <Dropdown
            overlay={
              <Menu
                style={{
                  marginRight: '5%',
                  marginTop: '-5%',
                  width: 'fit-content',
                }}
              >
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link to={`/profile/view-event-details/${record._id}`} />
                  {' '}
                  <span style={{marginLeft: '10px'}}>View Details</span>
                </Menu.Item>
                <Menu.Item key="2">
                  <EditOutlined />
                  {' '}
                  <Link to={`/profile/update-event/${record._id}`} />
                  {' '}
                  <span style={{marginLeft: '10px'}}>Update Event</span>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  danger
                  onClick={() => handleDelete (record._id)}
                >
                  <DeleteOutlined />

                  <span style={{marginLeft: '10px'}}>Delete Event</span>
                </Menu.Item>

              </Menu>
            }
          >
            <p>
              <MoreOutlined
                style={{
                  color: 'black',
                  cursor: 'pointer',
                  transform: '90deg',
                  fontSize: '1.5rem',
                }}
              />
            </p>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div>
      <div className={styles.events__header}>
        <h2>All Events</h2>
        <Button type="primary" onClick={handleCreateNew}>
          <PlusOutlined /> Create Event{' '}
        </Button>

      </div>
      <Table columns={columns} dataSource={allEvents} />
    </div>
  );
};

export default AdminEvents;
