
import React from 'react';
import './Rating.css';
import 'antd/dist/antd.css';
import { Rate} from 'antd';
const desc =['Poor','Average','Good','Great','Excellent']

class Rating extends React.Component{
    constructor(props){
        super(props);
    this.state={
        value:3
    };
}

    
  
    
     handleChange = (value) =>{
         
        this.setState({value});
    };
     
    handledChange = (value) =>{
        
        // console.log(value)
        this.setState({value});
        this.props.communication(value)
    };
    render(){
        const { value } = this.state;
        
    return(
        <span style={{marginLeft:'3em'}}> <Rate tooltips={desc}  style={{color:'#F9DA13 '}} onChange={this.handledChange} value={value} />
        {value ? <span className="ant-rate-text rating-text">{ desc[value-1]}</span>: ''}</span>
       
    )
    }
}
export default Rating;