import React,{useState,useEffect,useRef} from 'react';
import {LoadingOutlined,HomeFilled,PaperClipOutlined,MailOutlined,ExclamationCircleOutlined,CaretRightOutlined,SearchOutlined, PlusOutlined} from '@ant-design/icons';
import chatLogo from '../../assets/chat.png';
import logo from '../../assets/codersartsLogo.png';
import notificationSound from '../../assets/notification.mp3';
import avatar from '../../assets/user.png';
import './CodersartsChat.css';
import {Link} from 'react-router-dom';
import {Tooltip,Select,Input,Modal,notification,Collapse,Spin,Button,Breadcrumb, message} from 'antd';
import {useSelector} from 'react-redux';
// import back from '../../assets/back.png';
import '../../components/Chat/message/message.css';
import chatService from '../../services/chat.service';
import userService from '../../services/user.service';
import GoogleServices from '../../services/google.services';
import SingleChatConversation from './SingleChatConversation';
import SingleGroupConversation from './SingleGroupConversation';
import S3 from 'react-aws-s3';
import orderService from '../../services/order.service';
import GroupMessage from '../Groups/GroupMessage/GroupMessage';
import Message from '../../components/Chat/message/Message';
import empty from '../../assets/empty.png';
import { io } from "socket.io-client";
import groupIcon from '../../assets/group.png';
const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

let socketConnection = "https://dashboard.codersarts.com";

// let socketConnection = "http://localhost:3000";

const {Option} = Select;

const {Panel} = Collapse;
function CodersartsChat() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const scrollRef = useRef();
    const [loading,setLoading] = useState(false);
    const inputFileRef = useRef(null);
    const [file,setFile] = useState(null);
    const [newMessage,setNewMessage] = useState("");
    const [allConversations,setAllConversations] = useState([]);
    const [allUsers,setAllUsers] = useState([]);
    const [newChatMember,setNewChatMember] = useState("");
    const [isAddNewChatModal,setIsAddNewChatModal] = useState(false);
    const [confirmAddNewChatModal,setConfirmAddChatLoading] = useState(false);
    const [currentChatConversation,setCurrentChatConversation] = useState(null);
    const [isAddGroupModal,setIsAddGroupModal] = useState(false);
    const [confirmAddGroupLoading,setConfirmAddGroupLoading] = useState(false);
    const [newGroupName,setNewGroupName] = useState("");
    const [newGroupMembers,setNewMembers] = useState([]);
    const [allGroups,setAllGroups] = useState([]);
    const [filteredArr,setFilteredArr] = useState([]);
    const [filteredGroup,setFilteredGroup] = useState([]);
    const [allGroupMessages,setAllGroupMessages] = useState([]);
    const [allChatMessages,setAllChatMessages] = useState([]);
    const [isGroup,setIsGroup] = useState("");
    const [awsData,setAwsData] = useState(null);
    const [currentChatUser,setCurrentChatUser] = useState(null);
    const [currentGroup,setCurrentGroup] = useState(null);
    const [arrivalMessage,setArrivalMessage] = useState("");
    const [allChatConversations,setAllChatConversations] = useState(null);
    const [notificationTo,setNotificationTo] = useState("");
    const [notificationMsg,setNotificationMsg] = useState("");
    const [currentEditGroup,setCurrentEditGroup] = useState("");
    const [currentGroupEditModal,setCurrentGroupEditModal] = useState(false);
    const [updatedGroupName,setUpdatedGroupName] = useState("");
    const [updatedGroupMembers,setUpdatedGroupMembers] = useState([]);
    const [confirmGroupUpdateLoading,setConfirmUpdateGroupLoading] = useState(false);
    const [currentChatUserEmail,setCurrentChatUserEmail] = useState("");
    const [currentMessageSendingType,setCurrentMessageSendingType] = useState("chat");
    // const [filteredUserChatGroup,setFilteredUserChatGroup] = useState([]);
    const [allGroupChats,setAllGroupChats] = useState([]);
    const [allChats,setAllChats] = useState(null);
    // const [latestMessageArr,setLatestMessageArr] = useState(null);
    // const [updatedGroupMembersId,setUpdatedGroupMembersId] = useState([]);
    const socket = useRef();
    let audio = new Audio(notificationSound);

    const openNotification = (title,message,profile) => {
        notification.open({
          message: title,
          description:message+"...",
          icon:profile ? <img alt="Notification" src={profile} style={{width:'50px',marginRight:'50px',marginLeft:'-10px',height:'50px',borderRadius:'100px',objectFit:'cover'}} /> : <MailOutlined style={{ color: '#108ee9' }}/>,
          style: {
            width: 500,
          },
        });
      };


    useEffect(()=>{
        window.localStorage.setItem("currentChatConv",null);
    },[])

    useEffect(() => {
        socket.current = io();
        socket.current.on("getGroupMessages", (data) => {
        //   console.log("GOT MESSAGE HERE : ",data);
          let convId = window.localStorage.getItem("currentChatConv");
          let allChatsData = JSON.parse(window.localStorage.getItem("allChats"));
          let allGroupsData = JSON.parse(window.localStorage.getItem("allGroups"));
            // console.log("All Chats Before Filter : ",allChats);
          if(data.isGroupMessage){
            if(allChatsData){
                let msgItem = allChatsData.find((item)=>{
                    return item._id===data.id
                });
                // console.log("Found Conv in allChats : ",msgItem);
                if(msgItem){
                    allChatsData = allChatsData.filter((item)=>{
                        return item._id!==data.id
                    });
                    // console.log("Chats After Filter : ",allChatsData);
                    allChatsData.unshift(msgItem);
                    // console.log("Chats After Adding item to First!");
                    setAllChats(allChatsData);
                    window.localStorage.setItem("allChats",JSON.stringify(allChatsData));
                }
              
                
            }
            if(allGroupsData){
                let msgItem = allGroupsData.find((item)=>{
                    return item._id===data.id
                });
                // console.log("Found Conv in Group : ",msgItem);
               
                if(msgItem){
                    allGroupsData = allGroupsData.filter((item)=>{
                        return item._id!==data.id
                    });
                    // console.log("Chats After Filter : ",allChatsData);
                    allGroupsData.unshift(msgItem);
                    // console.log("Chats After Adding item to First!");
                    setAllGroupChats(allGroupsData);
                    window.localStorage.setItem("allGroups",JSON.stringify(allGroupsData));
                }
               
                
                
            }
          }else{
            if(allChatsData){
                let msgItem = allChatsData.find((item)=>{
                    return item.conversationId===data.id
                });
                // console.log("Found Conv in Conv : ",msgItem);
               
                if(msgItem){
                    allChatsData = allChatsData.filter((item)=>{
                        return item.conversationId!==data.id
                    });
                    // console.log("Chats After Filter : ",allChatsData);
                    // console.log("Chats After Adding item to First!");
                    setAllChats(allChatsData);
                    allChatsData.unshift(msgItem);
                    window.localStorage.setItem("allChats",JSON.stringify(allChatsData));
                }
                
               
                
            }  
        }

          
        //   message.info(`New Message from ${data.isGroupMessage ? data.}`)
        //   console.log("Current Chat Conv : ",convId);
            // let latestMsgArr = [];
            // if(data.isGroupMessage){
            //     if(allGroups){
            //         let filteredItem = null;
            //     filteredItem = allGroups.filter((item)=>{
            //         return item._id===data.id
            //     })
            //     console.log(filteredItem);
            //     let reducedGroup = allGroups.filter((item)=>{
            //         return item._id !==data.id
            //     })
            //     setAllGroups(reducedGroup);
            //     let data={isGroupMessage:true,item:filteredItem}
            //     latestMsgArr.push(data);
            //     }
            // }else{
            //    if(allConversations){
            //     let filteredItem = null;
            //     filteredItem = allConversations.filter((item)=>{
            //         return item.conversationId===data.id
            //     })
            //     console.log(filteredItem);
            //     let reducedGroup = allConversations.filter((item)=>{
            //         return item.conversationId !==data.id
            //     })
            //     setAllConversations(reducedGroup);
            //     let data={isGroupMessage:false,item:filteredItem}
            //     latestMsgArr.push(data);
            //    }
            // }
            // console.log("Latest Msg Arr  : ",latestMsgArr);
            // setLatestMessageArr(latestMsgArr);

            // console.log("Conversation Id : ",convId)

          if(data.sender.id !==currentUser.id && data.id !==convId){
            //   console.log("condition1");
              audio.play();
            setNotificationTo(data.id);
            setNotificationMsg(data.message);
            openNotification(`New Message from ${data.senderName}`,data.message.slice(0,30),data.senderProfile);
          }
          if(data.sender.id ===currentUser.id){
            // console.log("condition2")
            setNotificationTo("");
            setNotificationMsg(""); 
          }

          if(data.id ===convId){
            // console.log("condition3")
            setNotificationTo("");
            setNotificationMsg(""); 
          }
          setArrivalMessage({
            isGroupMessage:data.isGroupMessage,
            id:data.id && data.id,
            sender: data.sender,
            message: data.message,
            msgType:data.msgType,
            fileUrl:data.fileUrl,
            createdAt: Date.now(),
          });
        });
      }, []);


    useEffect(() => {
        socket.current.emit("addGroupUser", currentUser.id);
        // console.log(currentUser.id);
        
      }, [currentUser]);
      
    //   useEffect(() => {
    //     console.log("Arrival Message : ",arrivalMessage);
    //     console.log("Current Group Chat : ",currentGroupChat)
    //   arrivalMessage &&
    //     currentGroupChat?.members.includes(arrivalMessage.sender.id) &&
    //     setGroupMessages((prev) => [...prev, arrivalMessage]);
    // }, [arrivalMessage, currentGroupChat]);


    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [allChatMessages,allGroupMessages]);

     

      useEffect(() => {
        // console.log("Arrival Message : ",arrivalMessage);
        // console.log("Current  Chat : ",currentChatConversation);
        let membersId = []
        if(arrivalMessage.isGroupMessage && arrivalMessage.id===currentChatConversation){
            let members = [];
            allGroups && allGroups.map((item)=>{
                if(currentChatConversation===item._id){
                members = item.members 
                }
            });
            members.map((item)=>{
                membersId.push(item._id);
            })

            // console.log("Members Id : ",membersId);

            arrivalMessage &&
        membersId?.includes(arrivalMessage.sender.id) &&
        setAllGroupMessages((prev) => [...prev, arrivalMessage]);
        }else{
            let members = [];
            let membersId = [];
            // console.log("All Conversation : ",allChatConversations)
            allChatConversations && allChatConversations.map((item)=>{
                if(currentChatConversation===item._id){
                    // console.log("Fell into!");
                members = item.members 
                }
            });
            // console.log("members : ",membersId);
            members.map((item)=>{
                membersId.push(item._id);
            })

            // console.log("Members Id : ",membersId);
            arrivalMessage && membersId?.includes(arrivalMessage.sender.id) &&
            setAllChatMessages((prev) => [...prev, arrivalMessage]);
        }
     
    }, [arrivalMessage, currentChatConversation]);



    const handleFileChange = (e)=>{
        // console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setNewMessage(e.target.files[0].name)
      }

      const handleNewChatCancel = ()=>{
          setIsAddNewChatModal(false);
          setNewChatMember("");
      }
    
      const handleFileClick = ()=>{
        inputFileRef.current.click();
      }

      

      const getAllSpecificGroups = async ()=>{
        try{
            const response = await chatService.getGroupConversations(currentUser.id);
            // console.log("Groups : ",response.data);
            let data = []
            response.data.map((item)=>{
                let finalDate="N/A";
                let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
            if(item.createdAt){
                const date = new Date(item.createdAt);
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();
                let hh = date.getHours();
                let minutes = date.getMinutes();
                let ss = date.getSeconds();
                let day = date.getDay();
               finalDate =  "Created On " + days[day] + ", " + dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
            }
                let modifiedData = {
                    _id:item._id,
                    members:item.members,
                    groupName:item.groupName,
                    queryGroupName:item.queryGroupName && item.queryGroupName,
                    profilePicture:item.profilePicture && item.profilePicture,
                    createdAt:finalDate
                }
                data.push(modifiedData);
            })
            // console.log("All Groups : ",data);
            setAllGroups(data);
            setAllGroupChats(data);
            window.localStorage.setItem("allGroups",JSON.stringify(data));
        }catch(err){
            // console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch groups!')
        }
    }


   
    const handleGroupUpdateCancel = ()=>{
        setCurrentGroupEditModal(false);
    }

      const getAllSingleConversations = async ()=>{
        try{
            const response = await chatService.getSingleChatConversations(currentUser.id);
            // console.log("Conversations : ",response.data);
            let data = []
            response.data.map((d)=>{
                d.members.map((m)=>{
                    if(m._id !== currentUser.id){
                        let modifiedData = {
                            conversationId:d._id,
                            profilePicture:m.profilePicture,
                            username:m.username,
                            email:m.email,
                            role:m.roles[0].name
                            
                        }
                        data.push(modifiedData);
                    }
                })
            });

            // console.log("Data : ",data);
            setAllChatConversations(response.data);
            setAllConversations(data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went wrong!');
        }
      };

      const handleNewChatMember = (value)=>{
        //   console.log(value);
          setNewChatMember(value)
      }

      const handleAddNewChatModal = async ()=>{
        //   console.log("New Chat Member : ",newChatMember)
        setConfirmAddChatLoading(true);
        try{
            const response = await chatService.createNewChatConversation({members:[currentUser.id,newChatMember]});
            // console.log(response.data);
            setConfirmAddChatLoading(false);
            setIsAddNewChatModal(false);
            setNewChatMember("");
            message.success("Created New Chat!")
            getAllSingleConversations();
        }catch(err){
            console.log(err);
            setConfirmAddChatLoading(false)
        }
      }

      const getAllUsers = async ()=>{
          if(currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES'))){
                try{
                    const response = await userService.getAllUsers();
                    // console.log(response.data);
                
                    setAllUsers(response.data);
        
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : 'Unable to load users!');
                }
          }
      }


      function handleChangeMembers(value) {
        setNewMembers(value)
        
    }

    const handleAddGroupModal = async ()=>{
        setConfirmAddGroupLoading(true);
        // console.log("newGroupMembers : ",newGroupMembers)
        try{
            const response = await chatService.createGroupConversations(newGroupName,newGroupMembers);
            // console.log(response.data);
            message.success(`Created a new group ${newGroupName}!`);
            setNewGroupName("");
            setNewMembers([]);
            setConfirmAddGroupLoading(false);
            setIsAddGroupModal(false);
            getAllSpecificGroups();
        }catch(err){
            console.log(err);
            setConfirmAddGroupLoading(false);
            setIsAddGroupModal(false);
            message.error(err.message ? err.message : 'Unable to create new group!');
        }
    }

    const handleNewGroupCancel = ()=>{
        setIsAddGroupModal(false);
        setNewMembers([]);
        setNewGroupName("");
    }


    
    const getCurrentChatMessages = async (id)=>{
        if(id){
            try{
                const response = await chatService.getMessages(id);
                // console.log("Chat messages : ",response.data);
                setAllChatMessages(response.data);
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : ' Unable to fetch all the messages!');
            }
        }

    }
    const handleCurrentChat = (id,email)=>{
        // console.log("Current Chat Id : ",id);
        // console.log("user mail : ",email);
        if(notificationTo===id){
            setNotificationTo(false);
            setNotificationMsg("");
        }
        let currentChat= null;
        // console.log("AllConv : ",allConversations);
        allConversations.map((item)=>{
            if(item.conversationId===id){
            currentChat = item 
            }
        });

        setCurrentChatUserEmail(email)
        // console.log("Current Chat User : ",currentChatUser);
        // console.log("Current Chat Id : ",id);
        setCurrentChatUser(currentChat);
       
        setIsGroup(false);
        setCurrentChatConversation(id);
        window.localStorage.setItem("currentChatConv", id);
        getCurrentChatMessages(id);
      }

      const getCurrentGroupMessages = async (id)=>{
        if(id){
            try{
                const response = await chatService.getGroupMessages(id);
                // console.log("Group messages : ",response.data);
                setAllGroupMessages(response.data);
                
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : ' Unable to fetch all the messages!');
            }
        }
      }
      
    const handleCurrentGroup = async (id)=>{
        // console.log("group Id : ",id);
        if(notificationTo===id){
            setNotificationTo(false);
            setNotificationMsg("");
        }
        setIsGroup(true);
        // console.log("All Groups : ",allGroups);
        let currentChatGroup = null;
        allGroups.map((item)=>{
            if(item._id===id){
                currentChatGroup = item;
            }
        });

        // console.log("Current Group : ",currentChatGroup);
        // console.log("Current Chat Id : ",id);
        setCurrentGroup(currentChatGroup)
        setCurrentChatConversation(id);
        window.localStorage.setItem("currentChatConv", id);
       getCurrentGroupMessages(id);
    }

    const deleteGroup = async (id,groupName)=>{
        try{
            const response = await chatService.deleteGroup(id);
            // console.log(response);
            message.success(`Deleted ${groupName} Successfully!`);
            getAllSpecificGroups();
            setCurrentChatConversation(null);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to Delete Group')
        }
       
    }

    const confirmDelete = (id,groupName)=>{
        Modal.confirm({
          title: 'Confirm Delete',
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure you want to Delete ${groupName} ? `,
          okText: 'Yes',
          cancelText: 'No',
          onOk:()=>{deleteGroup(id,groupName)}
        });
      }

      const getAwsInfo = async ()=>{
        try{
         const res = await orderService.getAwsInfo();
        
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
    
     }
    

      useEffect(()=>{
        getAwsInfo();
        getAllSingleConversations();
        getAllSpecificGroups();
        getAllUsers();
      },[]);


      useEffect(()=>{
            let allChats = allConversations.concat(allGroups);
            setAllChats(allChats);
            window.localStorage.setItem("allChats",JSON.stringify(allChats));
            // console.log("ALL CHATS : ",allChats);
      },[allConversations,allGroups]);


      const handleMessageSendingType = (value)=>{
        //   console.log("Mail Sending Type : ",value);
          setCurrentMessageSendingType(value)
      }
      
    const handleSearchChange = (item)=>{
        let filteredData = [];
        let filteredQueryName = []
        let filteredGroupData = []
        // let filteredUserGroupData = [];
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allChats.length >0){
                filteredData= allChats.filter((c)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return c.username && c.username.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }

              if(allChats.length >0){
                filteredQueryName= allChats.filter((c)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return c.queryGroupName && c.queryGroupName.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }


              if(allGroups.length >0){
                filteredGroupData= allGroups.filter((c)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return c.groupName && c.groupName.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }
             
            //   if(allGroups.length >0){
            //     filteredUserGroupData= allGroups.filter((c)=>{
            //        // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
            //        return c.queryGroupName && c.queryGroupName.toLowerCase().match(item.toLowerCase())
            //     });
            //   //   console.log("SEARCH RESULT : ",filteredData);
               
            //   }
        }
        

            setFilteredArr(filteredQueryName.concat(filteredData));
            setFilteredGroup(filteredGroupData);
            // setFilteredUserChatGroup()
             
    
    }


    const handleMessageSubmit = async (e)=>{
        e.preventDefault();
        var message = {
          sender: currentUser.id,
          text: newMessage,
          msgType:"text",
          fileUrl:null,
          conversationId: currentChatConversation,
        };

        // console.log("AWS DATA : ",awsData);
        if(file && awsData){
          // console.log("FINAL FILE TO UPLOAD : ",file);
          message = {
            sender: currentUser.id,
            text: newMessage,
            msgType:file && file.type,
            fileUrl:null,
            conversationId: currentChatConversation,
          };
    
          //uploading file to aws
    
          setLoading(true);
                const config = {
                    bucketName:awsData.bucketName,
                    region:'ap-south-1',
                    accessKeyId: awsData.accessKeyId,
                    secretAccessKey: awsData.secretKey,
                    s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
                }
                const ReactS3Client = new S3(config);
                try{
                  const data = await ReactS3Client.uploadFile(file,file.name);
                  message.fileUrl = data.location
                //   console.log("UPLOADED  : ",data.location);
                  setFile(null);
                  setLoading(false);
                 }catch(err){
                     console.log(err);
                     setLoading(false);
                 }
    
        }

        socket.current.emit("sendGroupMessage", {
            isGroupMessage:false,
            sender: currentUser,
            message: newMessage,
            msgType:message.msgType,
            senderName:currentUser.username,
            senderProfile:currentUser.profilePicture,
            fileUrl:message.fileUrl,
            id:message.conversationId,
          });

        try{
          const res = await chatService.sendMessage(message);

          getCurrentChatMessages(currentChatConversation);
        //   setAllChatMessages([...allChatMessages,res.data]);

          setNewMessage("");
        }catch(err){
          console.log(err);
        }

    }

    const handleGroupMessageSubmit = async ()=>{
        let msg = {
            
            sender: currentUser.id,
            message: newMessage,
            msgType:"text"
        }

        if(file && awsData){
            msg = {
                sender: currentUser.id,
                message: newMessage,
                msgType:file && file.type,
                fileUrl:null
              };

              setLoading(true);
            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
            const ReactS3Client = new S3(config);
            try{
              const data = await ReactS3Client.uploadFile(file,file.name);
              msg.fileUrl = data.location
              // console.log("UPLOADED  : ",message);
              setFile(null);
              setLoading(false);
             }catch(err){
                 console.log(err);
                 setLoading(false);
             }
        }

        socket.current.emit("sendGroupMessage", {
            isGroupMessage:true,
            sender: currentUser,
            message: newMessage,
            senderName:currentUser.username,
            senderProfile:currentUser.profilePicture,
            msgType:msg.msgType,
            fileUrl:msg.fileUrl,
            id:currentChatConversation,
          });
       
        try{
            const response = await chatService.createGroupMessage({
                groupId:currentChatConversation,
                sender:currentUser.id,
                message:newMessage,
                type:msg.msgType,
                fileUrl:msg.fileUrl
            });
            // console.log(response.data);
            setNewMessage("");
            getCurrentGroupMessages(currentChatConversation)
            // setAllGroupMessages([...allGroupMessages,response.data]);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something Went wrong in sending message!')
        }

    }


    const editGroupMembers = (id)=>{
        let foundGroup = allGroups.find((item)=>{
            return item._id===id
        });



        setUpdatedGroupName(foundGroup.groupName);
        let groupMembers = [];
      
        foundGroup.members.map((m)=>{
            groupMembers.push(m.username);
        })
       
        setUpdatedGroupMembers(groupMembers);
        // console.log("Found Edit Group : ",foundGroup);
        setCurrentEditGroup(foundGroup);
        setCurrentGroupEditModal(true);

    }

    const handleUpdatedGroupMemberChange = (value)=>{
        // console.log("Value : ",value);
        // // setUpdatedGroupMembers(value);
        // let itemsToDelete = new Set(updatedGroupMembers);
        // let newArrValue = value.filter((item)=>{
        //     return !itemsToDelete.has(item);
        // });

        // // console.log("Array After Removing items : ",newArrValue);
        // let prevValue = updatedGroupMembersId;
        // newArrValue.map((item)=>{
        //     prevValue.push(item);
        // });
        // // console.log("Final Data : ",prevValue);
        // prevValue.push();
        setUpdatedGroupMembers(value);
    }

    const handleGroupUpdate = async ()=>{
        setConfirmUpdateGroupLoading(true);
        // console.log("Data : ",updatedGroupMembersId);
        // console.log("Data2 : ",updatedGroupName);
        let updatedMembers = [];
        allUsers.map((u)=>{
            updatedGroupMembers.map((m)=>{
                if(m===u.username){
                    // console.log("fell into")
                    updatedMembers.push(u._id);
                }
            })
        });

        // console.log("Data : ",updatedGroupMembers);
        // console.log("Final Data : ",updatedMembers);

        let finalData = {
            id:currentEditGroup._id,
            data:{
                groupName:updatedGroupName,
                members:updatedMembers
            }
           
        }
        // console.log("Final Data : ",finalData);

        try{
            const response = await chatService.updateGroupData(finalData);
            // console.log(response.data);
            message.success(`Updated ${updatedGroupName} successfully!`);
            getAllSpecificGroups();
            setConfirmUpdateGroupLoading(false);
            setCurrentGroupEditModal(false);

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Unable to  Update !");
            setConfirmUpdateGroupLoading(false);
        }

    }

    const deleteSingleChat = async (id,username)=>{
        try{
            const response = await chatService.deleteSingleChat(id);
            // console.log(response);
            message.success(`Deleted ${username} Successfully!`);
            getAllSingleConversations();
            setCurrentChatConversation(null);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to Delete Group')
        }
    }

    const confirmSingleChatDelete = (id,username)=>{
        Modal.confirm({
          title: 'Confirm Delete',
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure you want to Delete Chat with ${username} ? `,
          okText: 'Yes',
          cancelText: 'No',
          onOk:()=>{deleteSingleChat(id,username)}
        });
      }

    const handleMailSubmit = async ()=>{
        setLoading(true);
        try{
            const data ={
                To:currentChatUserEmail,
                Subject:`New Message from Codersarts Chat with ${currentUser.username}!`,
                Write:`<b>Message: </b>${newMessage}`,
                attachment:false,
    
            }
            const response = await GoogleServices.sendmail(data)
            var currentMessage = {
                sender: currentUser.id,
                text: newMessage,
                msgType:"text",
                fileUrl:null,
                conversationId: currentChatConversation,
                };

            socket.current.emit("sendGroupMessage", {
                isGroupMessage:false,
                sender: currentUser,
                message: newMessage,
                msgType:currentMessage.msgType,
                senderName:currentUser.username,
                senderProfile:currentUser.profilePicture,
                fileUrl:currentMessage.fileUrl,
                id:currentMessage.conversationId,
                });
            const res = await chatService.sendMessage(currentMessage);
            getCurrentChatMessages(currentChatConversation);

            if(response){
                setLoading(false);
                setNewMessage("");
                message.success("Successfully Sent the Mail !");
            }

           
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Something went wrong in sending mail!');
        }

    }

    return (
        <div className='codersarts-chat'>
           <div className='chatBanner'>
               <div className="chatLogo">
                    <div className="ml-2 mr-2 mt-1">
                    <Link to="/">
                    <HomeFilled className='homeIcon' />
                    </Link>
                    </div>
                  <div style={{marginLeft:'5%'}}>
                  <Link to="/" target={'_blank'}>
                  <img src={logo} alt="codersarts" />
                  </Link>
                  </div>
                  <div className='codersartsChatLogo'>
                  <Link to="/" target={'_blank'}>
                      <h5>Codersarts Chat</h5>
                   </Link>
                  </div>
                
               </div>
               <div className='searchArea'>
                    <div>
                    <SearchOutlined className="searchIcon" />
                    </div>
                   <input type="text" onChange={(e)=>{handleSearchChange(e.target.value)}} placeholder="Search the Chat and Spaces..." className='searchBar'/>
               </div>
               <div className='profileArea'>
                        <div className="chatUserInfo">
                        <h6>{currentUser.username && currentUser.username}</h6>
                        <div className='chatUserBadge'>{currentUser.roles && currentUser.roles[0].split("_")[1]}</div>
                        </div>
                        
                        <Link to="/profile"  target={'_blank'}>
                        <Tooltip color={"blue"} title="Profile">
                        <img className="chatDp" src={currentUser.profilePicture ? currentUser.profilePicture : avatar} alt="img"  />
                        </Tooltip>
                        </Link>
               </div>
           </div>

           <div className="chatArea">

                <div className="chatSideNav">
                <Breadcrumb style={{marginLeft:'5%'}}>
                    <Breadcrumb.Item> <Link to="/profile">Profile</Link></Breadcrumb.Item>
                    <Breadcrumb.Item> <Link to="/chat">Chat</Link></Breadcrumb.Item>
                </Breadcrumb>
                {/* {currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) &&
                <div style={{float:'right',marginRight:'3%'}}>
                    
                    <Tooltip color={"orange"} title="Create Chat">
                        <Button  
                        onClick={()=>{setIsAddNewChatModal(true)}}>
                        <PlusOutlined />
                        </Button>
                    </Tooltip>
                </div>
                } */}
                   
                <Collapse
                        bordered={false}
                        defaultActiveKey={['1', '2']}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="site-collapse-custom-collapse"
                    >
                        <Panel header="Chat" key="1" className="site-collapse-custom-panel">
                        <div className="chatLists">
                        
                        {filteredArr && filteredArr.length >0 ? 
                            filteredArr.map((item)=>{
                            if(item.groupName){
                                if(item.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.queryGroupName ? item.queryGroupName : item.groupName) : item.groupName}
                                notification={notificationTo===item._id} 
                                notificationMsg={notificationMsg}
                                active={item._id===currentChatConversation}
                                handleCurrentGroup={handleCurrentGroup}
                                editGroupMembers={editGroupMembers}
                                queryGroup={true}
                                isUser={currentUser && currentUser.roles.includes('ROLE_USER')}
                                handleGroupDelete={confirmDelete}
                                profilePicture={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.profilePicture ? item.profilePicture : null) : null }
                                isAdmin={true}
                                isSales={false}
                                groupId={item._id} /> )
                                }
                            }else{
                                return( <SingleChatConversation 
                                notification={notificationTo===item.conversationId} 
                                notificationMsg={notificationMsg}
                                profilePicture={item.profilePicture} 
                                username={item.username}
                                email={item.email}
                                handleSingleChatDelete={confirmSingleChatDelete}
                                active={item.conversationId===currentChatConversation}
                                handleCurrentChat={handleCurrentChat}
                                conversationId={item.conversationId}
                                 /> )
                            }
                        })
                        :
                        allChats && allChats.length > 0 && allChats.map((item)=>{
                            if( item && item.groupName){
                                if(item.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.queryGroupName ? item.queryGroupName : item.groupName) : item.groupName}
                                notification={notificationTo===item._id} 
                                notificationMsg={notificationMsg}
                                active={item._id===currentChatConversation}
                                handleCurrentGroup={handleCurrentGroup}
                                editGroupMembers={editGroupMembers}
                                queryGroup={true}
                                isUser={currentUser && currentUser.roles.includes('ROLE_USER')}
                                handleGroupDelete={confirmDelete}
                                profilePicture={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.profilePicture ? item.profilePicture : null) : null }
                                isAdmin={true}
                                isSales={false}
                                groupId={item._id} /> )
                                }
                            }else{
                                return( <SingleChatConversation 
                                notification={notificationTo===item.conversationId} 
                                notificationMsg={notificationMsg}
                                profilePicture={item.profilePicture} 
                                username={item.username}
                                email={item.email}
                                handleSingleChatDelete={confirmSingleChatDelete}
                                active={item.conversationId===currentChatConversation}
                                handleCurrentChat={handleCurrentChat}
                                conversationId={item.conversationId}
                                 /> )
                            }
                        })
                        }
                        {/* {allChats && allChats.map((item)=>{
                            if(item.groupName){
                                if(item.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.queryGroupName ? item.queryGroupName : item.groupName) : item.groupName}
                                notification={notificationTo===item._id} 
                                notificationMsg={notificationMsg}
                                active={item._id===currentChatConversation}
                                handleCurrentGroup={handleCurrentGroup}
                                editGroupMembers={editGroupMembers}
                                queryGroup={true}
                                isUser={currentUser && currentUser.roles.includes('ROLE_USER')}
                                handleGroupDelete={confirmDelete}
                                profilePicture={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (item.profilePicture ? item.profilePicture : null) : null }
                                isAdmin={true}
                                isSales={false}
                                groupId={item._id} /> )
                                }
                            }else{
                                return( <SingleChatConversation 
                                notification={notificationTo===item.conversationId} 
                                notificationMsg={notificationMsg}
                                profilePicture={item.profilePicture} 
                                username={item.username}
                                handleSingleChatDelete={confirmSingleChatDelete}
                                active={item.conversationId===currentChatConversation}
                                handleCurrentChat={handleCurrentChat}
                                conversationId={item.conversationId}
                                 /> )
                            }
                        })} */}
                        {/* {filteredUserChatGroup.length >0 ? filteredUserChatGroup.map((g)=>{
                            if(g.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (g.queryGroupName ? g.queryGroupName : g.groupName) : g.groupName}
                                notification={notificationTo===g._id} 
                                notificationMsg={notificationMsg}
                                active={g._id===currentChatConversation}
                                handleCurrentGroup={handleCurrentGroup}
                                queryGroup={true}
                                editGroupMembers={editGroupMembers}
                                profilePicture={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (g.profilePicture ? g.profilePicture : null) : null }
                                handleGroupDelete={confirmDelete}
                                isAdmin={true}
                                isUser={currentUser && currentUser.roles.includes('ROLE_USER')}
                                isSales={false}
                                groupId={g._id} /> )
                                }
                            }):
                            allGroups && allGroups.map((g)=>{
                                if(g.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (g.queryGroupName ? g.queryGroupName : g.groupName) : g.groupName}
                                notification={notificationTo===g._id} 
                                notificationMsg={notificationMsg}
                                active={g._id===currentChatConversation}
                                handleCurrentGroup={handleCurrentGroup}
                                editGroupMembers={editGroupMembers}
                                queryGroup={true}
                                isUser={currentUser && currentUser.roles.includes('ROLE_USER')}
                                handleGroupDelete={confirmDelete}
                                profilePicture={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (g.profilePicture ? g.profilePicture : null) : null }
                                isAdmin={true}
                                isSales={false}
                                groupId={g._id} /> )
                                }
                            })
                            }

                            {filteredArr.length >0 ? filteredArr.map((c)=>{
                                return( <SingleChatConversation 
                                profilePicture={c.profilePicture}
                                notification={notificationTo===c.conversationId} 
                                notificationMsg={notificationMsg}
                                username={c.username}
                                active={c.conversationId===currentChatConversation}
                                handleSingleChatDelete={confirmSingleChatDelete}
                                handleCurrentChat={handleCurrentChat}
                                conversationId={c.conversationId}
                                 /> )
                            }):
                            allConversations && allConversations.map((c)=>{
                                return( <SingleChatConversation 
                                notification={notificationTo===c.conversationId} 
                                notificationMsg={notificationMsg}
                                profilePicture={c.profilePicture} 
                                username={c.username}
                                handleSingleChatDelete={confirmSingleChatDelete}
                                active={c.conversationId===currentChatConversation}
                                handleCurrentChat={handleCurrentChat}
                                conversationId={c.conversationId}
                                 /> )
                            })
                            } */}
                        </div>
                        </Panel>
                        {currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) &&
                        <div style={{float:'right',marginRight:'3%'}}>
                            <Tooltip color={"orange"} title="Create Group">
                                <Button  
                                onClick={()=>{setIsAddGroupModal(true)}}>
                                <PlusOutlined />
                                </Button>
                            </Tooltip>
                        </div>
                        }
                        
                        <Panel header="Spaces" key="2" className="site-collapse-custom-panel">
                        <div className="chatLists">
                            {filteredGroup.length >0 ? filteredGroup.map((g)=>{
                                if(!g.queryGroupName){
                                return( <SingleGroupConversation 
                                groupName={g.groupName} 
                                active={g._id===currentChatConversation}
                                notification={notificationTo===g._id} 
                                notificationMsg={notificationMsg}
                                handleCurrentGroup={handleCurrentGroup}
                                handleGroupDelete={confirmDelete}
                                editGroupMembers={editGroupMembers}
                                isSales={currentUser && (currentUser.roles.includes('ROLE_ADMIN')) ? false:true}
                                isAdmin={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES'))}
                                groupId={g._id} /> )
                                }
                                
                            }):
                            allGroupChats && allGroupChats.map((g)=>{
                                if(!g.queryGroupName){
                                    return( <SingleGroupConversation 
                                 groupName={g.groupName} 
                                active={g._id===currentChatConversation}
                                notification={notificationTo===g._id} 
                                notificationMsg={notificationMsg}
                                handleCurrentGroup={handleCurrentGroup}
                                handleGroupDelete={confirmDelete}
                                editGroupMembers={editGroupMembers}
                                isSales={currentUser && (currentUser.roles.includes('ROLE_ADMIN')) ? false:true}
                                isAdmin={currentUser && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES'))}
                                groupId={g._id} /> )
                                }
                            })
                            }
                        </div>
                        </Panel>
                    </Collapse>
                    {/* <div className="goBack">
                    <Link to="/profile"> 
                        <Tooltip placement="top" color={'blue'} title="Go Back?">
                            <img src={back} alt="Back" className="return-icon"/>
                        </Tooltip>
                    </Link>
                    </div> */}
                   
                </div>
                {
                    currentChatConversation ? 
                    <div className="chatMessagesWriteArea">
                    {isGroup ?
                    <div className='currentChatBanner'>
                        <div>
                            <img src={currentUser && currentGroup && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? currentGroup.profilePicture ? currentGroup.profilePicture : groupIcon : groupIcon}  alt="GroupIcon"/>
                        </div>
                        <div className="currentGroupInfo">
                            <h6>{currentUser && currentGroup && (currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')) ? (currentGroup.queryGroupName ? currentGroup.queryGroupName : currentGroup.groupName) : currentGroup.groupName}</h6>
                            <div className="groupCreatedAt">{currentGroup && currentGroup.createdAt}</div>
                        </div>
                    </div> 
                    :
                    <div className='currentChatBanner'>
                        <div>
                            <img src={currentChatUser && currentChatUser.profilePicture ? currentChatUser.profilePicture : avatar} alt="profilePic"/>
                        </div>
                        <div className="currentChatInfo">
                            <h6>{currentChatUser && currentChatUser.username}</h6>
                            <div className='currentChatUserBadge'>{currentChatUser && currentChatUser.role}</div>
                        </div>
                    </div> 
                    }
                    <div className="allChatMessages">
                    {isGroup ? 
                    (allGroupMessages.length > 0 ? allGroupMessages.map((msg,key)=>{
                            return(
                                <div ref={scrollRef} className="singleGroupMsg" key={key}>
                                    <GroupMessage 
                                    
                                    createdAt={msg.createdAt} 
                                    sender={msg.sender[0] ? msg.sender[0] : msg.sender } 
                                    own={msg.sender && msg.sender[0] ? msg.sender[0]._id===currentUser.id :  msg.sender.id===currentUser.id }
                                    message={msg} />
                                </div>
                            )
                    }):
                    <div className='noMessages'>
                        <img src={ empty } alt="empty" />
                        <h5>There are No Messages!</h5>
                    </div>
                    )
                    
                    :
                   ( allChatMessages.length > 0 ? 
                        allChatMessages.map((m,index) => (
                        <div ref={scrollRef} key={index}>
                        <Message message={m}  sender={m.sender[0] ? m.sender[0] : m.sender } 
                                    own={m.sender && m.sender[0] ? m.sender[0]._id===currentUser.id :  m.sender.id===currentUser.id }
                         />
                        </div>
                        ))
                        :
                        <div className='noMessages'>
                        <img src={ empty } alt="empty" />
                        <h5>There are No Messages!</h5>
                        </div>

                    )
                    }

                         
                    </div>
                    <div className="groupChatBoxBottom">
                        <div>
                            <textarea className="input-box" value={newMessage} onChange={(e)=>{setNewMessage(e.target.value)}}></textarea>
                        </div>
                        {currentMessageSendingType==="chat" ?
                        <div className="attachment">
                        <Tooltip title="Share Files ? " color={'#f50'}>
                            <span onClick={handleFileClick}>
                            <PaperClipOutlined className="file-upload-icon" />
                            </span>
                        </Tooltip>
                        <input type="file" 
                        style={{display:'none'}} 
                        onChange={handleFileChange}
                        ref={inputFileRef} />
                        </div>
                        :
                        null
                        }
                       
                        <div style={{marginLeft:'-15px',display:'flex'}}>
                            <Button className="msgSubmit" type={'primary'} onClick={currentMessageSendingType==="chat" ? (isGroup ? handleGroupMessageSubmit :  handleMessageSubmit):handleMailSubmit}>
                            {loading ? <Spin indicator={spinIcon} />:'Send'}
                            </Button>
                            {currentUser && (currentUser.roles.includes('ROLE_SALES') || currentUser.roles.includes('ROLE_ADMIN')) &&
                            <Select defaultValue={currentMessageSendingType} onChange={handleMessageSendingType}>
                                <Option value="chat">Chat</Option>
                                <Option value="mail">Mail</Option>
                            </Select>
                            }
                          
                        </div>
                        

                    </div>
                </div>
                    :
                    (
                        <div className="NoConvImage">
                        <img src={chatLogo} />
                        <div>
                            <h3>Select a Conversation</h3>
                            <div id="noText">Try Selecting a Conversation or Searching a Specific Person</div>
                        </div>  
                        </div>
                    )
                }
               
           </div>
           <Modal
                title="Add a Person to Chat"
                visible={isAddNewChatModal}
                onOk={handleAddNewChatModal}
                confirmLoading={confirmAddNewChatModal}
                onCancel={handleNewChatCancel}
                closable={true}
                okText="Create Chat"
                        >
                        <div style={{fontStyle:'normal'}}>
                            <form>
                                <div className="mt-1">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    value={newChatMember}
                                    onChange={handleNewChatMember}
                                    >
                                    {allUsers.length >0 && allUsers.map((u,key)=>{
                                        return <Option key={key} value={u._id}>{u.username}</Option>
                                    })}
                                </Select>

                                </div>
                            </form>
                        </div>
         </Modal>


         <Modal
                title="Create a New Group"
                visible={isAddGroupModal}
                onOk={handleAddGroupModal}
                confirmLoading={confirmAddGroupLoading}
                onCancel={handleNewGroupCancel}
                closable={true}
                okText="Add Group"
                        >
                        <div style={{fontStyle:'normal'}}>
                            <form>
                                <div className="mt-2">
                                <h6><b><label>Enter Group Name</label></b></h6>
                                <Input value={newGroupName} onChange={(e)=>{setNewGroupName(e.target.value)}} className="mt-2" />
                                </div>

                                <div className="mt-1">


                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    value={newGroupMembers}
                                    defaultActiveFirstOption={true}
                                    onChange={handleChangeMembers}
                                    >
                                    {allUsers.length >0 && allUsers.map((u,key)=>{
                                        return <Option key={key} value={u._id}>{u.username}</Option>
                                    })}
                                </Select>

                                </div>
                            </form>
                        </div>
         </Modal>

         <Modal
                title={`Edit ${currentEditGroup.groupName} Members`}
                visible={currentGroupEditModal}
                onOk={handleGroupUpdate}
                confirmLoading={confirmGroupUpdateLoading}
                onCancel={handleGroupUpdateCancel}
                closable={true}
                okText="Update"
                        >
                        <div style={{fontStyle:'normal'}}>
                            <form>
                                <div className="mt-2">
                                <h6><b><label>Enter New Group Name</label></b></h6>
                                <Input value={updatedGroupName} onChange={(e)=>{setUpdatedGroupName(e.target.value)}} className="mt-2" />
                                </div>

                                <div className="mt-1">


                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    defaultValue={updatedGroupMembers}
                                    onChange={handleUpdatedGroupMemberChange}
                                    >
                                    {allUsers.length >0 && allUsers.map((u,key)=>{
                                        return <Option key={key}  value={u.username}>{u.username}</Option>
                                    })}
                                </Select>

                                </div>
                            </form>
                        </div>
         </Modal>
        </div>
    )
}

export default CodersartsChat
