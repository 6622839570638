
import styled from 'styled-components';

export const Layout = styled.div`
display:flex;
height:700px;
width:100%;
align-items:center;
justify-content:center;
border:1px solid black;`;
export const Box = styled.div`
display:flex;
height:500px;
width:700px;
flex-direction:column;
border:1px solid black;`;

export const Header = styled.div`
display:flex;
justify-content:space-between;
border-bottom:1px solid black;
height:38px;
background-color:#006B82`;

export const Text = styled.div`
display:flex;
color:white;
font-weight:600;
margin-left:20px;
font-family:sans-serif;
`;
export const Footer = styled.div`
display:flex;

margin-top:auto;
height:55px;

border-top:1px solid black;
background-color:#006B82`;



export const Button2  = styled.button`
display:flex;
background-color:#F27979;

color:white;
border:0;

padding-right:0.5em;
padding-left:0.5em;
padding-top:0.1em;
padding-bottom:0.1em;

font-weight:500;
border:1px solid #3079ed;
`;

export const Input = styled.input`
display:flex;
border:0px;
height:30px;
border-bottom:1px solid black;

&:focus{
    outline:none;
}
`
;

export const TextArea = styled.textarea`
display:flex;
border:0px;
height:100%;
border-bottom:1px solid black;
resize:none;
outline:none;

`
;