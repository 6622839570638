import {QuestionCircleOutlined} from '@ant-design/icons';
import {Button, message, Tag, Tooltip} from 'antd';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import EventsServices from '../../../services/events.services';
import styles from '../../RegisterEvent/RegisterEvent.module.css';
function MyEvents () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [allEvents, setAllEvents] = useState ([]);

  useEffect (
    () => {
      let isMounted = true;
      const getAllAttendeeEvents = async () => {
        try {
          const response = await EventsServices.getAllAttendeeEvents (
            currentUser.id
          );
          console.log (response.data);
          if (response.data) {
            setAllEvents (response.data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      if (isMounted) {
        getAllAttendeeEvents ();
      }

      return () => {
        isMounted = false;
      };
    },
    [currentUser]
  );

  const handleJoin = event => {
    if (event.link) {
      window.open (event.link, '_blank', 'noreferrer');
    } else {
      message.info ('Event link not available!');
    }
  };
  return (
    <div>
      <h2>My Events</h2>
      <div className={styles.all__eventsCard}>
        {allEvents.map (item => {
          return (
            <div className={styles.event__card} key={item._id}>
              <img src={item.bannerImg} alt={item.title} />
              <div className={styles.event__cardBody}>
                <h3>{item.title}</h3>
                <div className={styles.event__cardBodyText}>
                  {item.description}
                </div>
              </div>
              <div className={styles.event__cardFooter}>
                <div>
                  <b>Start: </b>
                  <Tag
                    color={
                      new Date ().getTime () >
                        new Date (item.eventDate).getTime ()
                        ? 'red'
                        : 'blue'
                    }
                  >
                    {item.eventDate}
                  </Tag>
                  <sup>
                    {new Date ().getTime () >
                      new Date (item.eventDate).getTime () &&
                      <Tooltip
                        title={'Event Expired!'}
                        placement="right"
                        color={'#006B82'}
                      >
                        <QuestionCircleOutlined
                          style={{marginLeft: '5px', fontSize: '14px'}}
                        />
                      </Tooltip>}
                  </sup>
                </div>
                <div>
                  <Button
                    size="large"
                    className={'primaryBtn'}
                    type="primary"
                    disabled={
                      item.isDisabled ||
                        new Date ().getTime () >
                          new Date (item.eventDate).getTime ()
                    }
                    onClick={() => handleJoin (item)}
                  >
                    Join Now
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default MyEvents;
