import React,{useState,useEffect} from 'react'
import {Spin,message,Breadcrumb} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import OrderService from '../../../services/order.service';
import {Link} from 'react-router-dom';
import userService from '../../../services/user.service';


const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;

function CreateManualBooking() {
    const [allSessions,setAllSessions] = useState("");
    const [bookingBy,setBookingBy] = useState("");
    const [date,setDate] = useState("");
    const [timezone,setTimezone] = useState("");
    const [allUsers,setAllUsers] = useState("");
    const [details,setDetails] = useState("");
    const [sessionForBooking,setSessionForBooking] = useState("");
    const [loading,setLoading] = useState(false);
   


    const getAllSessions = async ()=>{
        try{
            const response = await OrderService.getAllSessions();
            // console.log("Sessions : ",response.data);
            setAllSessions(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to load sessions!');
        }
    }

    const getAllUsers = async ()=>{
        let data = []
        try{
            const response = await userService.getAllUsers();
            // console.log("Users : ",response.data);

            response.data.map((u)=>{
                if(u.roles[0].name==='user'){
                    // console.log("fall")
                    data.push(u);
                }
            })
        setAllUsers(data)
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to load users!');
        }
    }
    useEffect(()=>{
        getAllSessions();
        getAllUsers();
    },[])

   
    const handleSessionNameChange = (e)=>{
        if(e.target.value!=="Select Session"){
            setSessionForBooking(e.target.value);
        }
    }


    const handleBookingUserChange = (e)=>{
        if(e.target.value!=="Select User"){
            setBookingBy(e.target.value);
        }
    }


    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoading(true);
        try{
            const response = await OrderService.createNewBooking({
                booking:sessionForBooking,
                bookingBy:bookingBy,
                date:date,
                timezone:timezone,
                details:details})
                // console.log(response.data);
                if(response.data){
                    setBookingBy("");
                    setSessionForBooking("");
                    setDetails("");
                    setDate("");
                    setTimezone("");
                    message.success("Created New Booking");
                }

                setLoading(false);
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Unable Create new Booking!');
        }
    }


    return (
        <div className='sessionsForBooking'>
            <Breadcrumb>
            <Breadcrumb.Item> <Link to="/profile/all-bookings">All Bookings </Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/create-new-booking`}>Create Booking</Link></Breadcrumb.Item>
            </Breadcrumb>
             <div>
                <h2>Create New Booking</h2>
            </div>
            <hr />
            <div>
                <form>
                    <div className="form-group">
                        <label>Select a Session for Booking: </label>
                      <select className='form-control'  onChange={(e)=>{handleSessionNameChange(e)}}>
                        <option hidden selected>Select Session</option>
                          {allSessions && allSessions.map((s)=>{
                              return(<option value={s._id} key={s._id}>{s.name && s.name}</option>)
                          })}
                      </select>
                    </div>

                    <div className="form-group">
                        <label>Select the User for Booking: </label>
                      <select className='form-control'  onChange={(e)=>{handleBookingUserChange(e)}}>
                        <option hidden selected>Select User</option>
                          {allUsers && allUsers.map((u)=>{
                              return(<option  value={u._id} key={u._id}>{u.username && u.username}</option>)
                          })}
                      </select>
                    </div>
                    

                    <div className="form-group">
                        <label>
                           Enter Date For Booking:
                        </label>
                        <input  
                        value={date}
                        type="date" 
                        onChange={(e)=>{setDate(e.target.value)}} 
                         className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Time with Timezone for Booking:
                        </label>
                        <input 
                        value={timezone}  
                        placeholder="e.g 10 AM GMT+5" type="text" 
                        onChange={(e)=>{setTimezone(e.target.value)}}  
                        className="form-control" />
                    </div>
                    <div className="form-group">
                        <label>
                            Enter Booking Details : 
                        </label>
                       <textarea  
                       value={details} 
                       onChange={(e)=>{setDetails(e.target.value)}} 
                       className="form-control"></textarea>
                    </div>

                    <button type="submit" 
                    onClick={(e)=>{handleSubmit(e)}} 
                    disabled = {sessionForBooking === "" ||  bookingBy=== "" || details ==="" || date === "" || timezone===""} 
                    className="sessionsForBooking-btn pay-btn"
                    
                    >
                   {loading ? <Spin indicator={spinIcon} /> : 'Create Booking'}
                    </button>
                    {/* <div className="form-group">
                        <label>
                            Enter Session Description : 
                        </label>
                       <textarea  value={details} onChange={(e)=>{setDetails(e.target.value)}} className="form-control"></textarea>
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Session Duration:
                        </label>
                        <input  type="text" value={duration} onChange={(e)=>{setDuration(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Price:
                        </label>
                        <input  type="text" value={price} onChange={(e)=>{setPrice(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label>
                           Enter Read More Link:
                        </label>
                        <input  type="text" placeholder='Leave Empty if not present' value={readMoreLink} onChange={(e)=>{setReadMoreLink(e.target.value)}}  className="form-control" />
                    </div>

                    <div className="form-group">
                        <label> Upload Image for Session: </label>
                        <input type="file" className="form-control file" onChange={(e)=>{handleFileChange(e.target.files[0])}}/>  
                    </div>
                    
                    <button type="submit" 
                    onClick={(e)=>{handleSubmit(e)}} 
                    disabled = {name === "" || details === "" || duration ==="" || price === "" || fileUrl===""} 
                    className="sessionsForBooking-btn pay-btn"
                    
                    >
                   {loading ? <Spin indicator={spinIcon} /> : 'Create Session'}
                    </button> */}
                </form>

            </div>
        </div>
    )
}

export default CreateManualBooking
