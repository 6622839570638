import React,{useEffect,useState} from 'react';
import orderService from '../../services/order.service';
import {message,Table} from  'antd';
import {useSelector} from 'react-redux';
import {history} from '../../helpers/history';

function AllLogs() {
    const [logs,setLogs] = useState(null);


    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])

    const getAllLogs = async ()=>{
        try{
            const response = await orderService.getAllLogs();
            let data = []
            let finalDate="N/A";
            
            // console.log(response.data);
            response.data.map((d)=>{
                if(d.timestamp){
                    const date = new Date(d.timestamp);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear();
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
        
                   finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }

                let modifiedData = {
                    time:finalDate,
                    sortDate:d.timestamp,
                    message:d.message
                }
                data.push(modifiedData);

            })
            setLogs(data);


        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Couldn't Get the logs!");

        }
    }
    
    useEffect(()=>{
        
        getAllLogs();
        
    },[])

    const columns = [
        {
            title: 'Timing',
            dataIndex: 'time',
            key: 'time',
            sorter:(a)=>new Date(a.sortDate)- new Date(Date.now() - 72*60*60 * 1000)
        },
        {
            title: 'Activity',
            dataIndex: 'message',
            key: 'message',
        }
    ]
    return (
        <div>
            <h2>All Logs</h2>
            <Table dataSource={logs} columns={columns} />
        </div>
    )
}

export default AllLogs
