import React,{useEffect, useState} from 'react';
import Message from '../../../Message/Message';
import './CreateOrderForm.css';
import OrderService from '../../../../services/order.service';
import { Breadcrumb,message,Tag, Select,Checkbox,Spin, Progress } from 'antd';
import UserService from '../../../../services/user.service';
import GoogleServices from '../../../../services/google.services';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import S3 from 'react-aws-s3';
import { history } from '../../../../helpers/history';
import { LoadingOutlined } from '@ant-design/icons';
import userService from '../../../../services/user.service';


const {Option} = Select;




const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

const CreateOrderForm = ()=>{

    const [name, setName] = useState("");
    const [details, setDetails] = useState("");
    const [deadline,setDeadline] = useState("");
    const [category,setCategory] = useState("");
    const [totalAmount,setTotalAmount] = useState("");
    const [paidAmount,setPaidAmount] = useState("");
    const [otherCategory,setOtherCategory] = useState("");
    const [documentation,setDocumentation] = useState("");
    const [bookingSession,setBookingSession] = useState("");
    const [assignedTo,setAssignedTo] = useState("");
    const [allUsers,setAllUsers] = useState([]);
    const [fileUrl,setFileUrl] = useState(null);
    const [uploading,setUploading] = useState(false);
    const [successMessage,setSuccessMessage] = useState(false);
    const [awsData,setAwsData] = useState(null);
    const [codeWalkThrough,setCodeWalkThrough] = useState(null);
    const [reportFee,setReportFee] = useState(null);
    const [timezone,setTimeZone] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [customer,setCustomer] = useState(null);
    const [currency,setCurrency] = useState(null);
    const [loading,setLoading] = useState(false);
    const [allOrderCategory,setAllOrderCategory] = useState([]);
    const [allAssignTo,setAllAssignTo] = useState([]);
    const [allCustomers,setAllCustomers] = useState([]);
    const [customerEmail,setCustomerEmail] = useState([]);
    const [uploadPercent,setUploadPercent] = useState(0);
    const [uploadStatus,setUploadStatus] = useState("");
    const [uploadFile,setUploadFile] = useState(null);
    const [skills,setSkills] = useState([]);
    const [fileKey,setFileKey] = useState("1");
    const [technologies,setTechnologies] = useState([]);
    const [type,setType] = useState("");
    const [newOrderLabel,setNewOrderLabel] = useState("");


  useEffect(()=>{
      if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
          if(currentUser.roles.includes('ROLE_SALES')){
              return;
          }
          history.push('/profile');
      }
  },[currentUser]);


    
  useEffect(()=>{
    let isMounted = true;

   const getAllGmailLabels = async ()=>{
    try{
        const response = await OrderService.getAllGmailLabels();
        // console.log("Gmail labels : ",response.data);
        let label = response.data?.labels?.find(item=>{
            return item.name.toLowerCase()==='new order'
        });

        console.log(label);
        setNewOrderLabel(label);
        
    }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
    }
   }
   if(isMounted){
    getAllGmailLabels();
   }

   return ()=>{
       isMounted = false;
   }
   
},[])

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;
    // console.log(today);

    const getAwsInfo = async ()=>{
       try{
        const res = await OrderService.getAwsInfo();
        
        setAwsData(res.data);
       }catch(err){
           console.log(err);
       }

    }

    const getSkillsList = async ()=>{
        try{
            const response = await userService.getAllSkillsList();
            if(response){
                setSkills(response.data);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'something went wrong!');
        }
    }
    useEffect(()=>{
        getAwsInfo();
        getSkillsList();
        
    },[]);

    
    const  getAllOrderCategoriesFunc = async ()=>{
        try{
            const response = await OrderService.getAllOrderCategory();
            // console.log("Categories : ",response.data);
            let categories = [];
            response.data.map((item)=>{
                categories.push(item.name);
            })
            setAllOrderCategory(categories);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : " Unable  to Get all Orders Categories!");
        }
    }





    const getUsers = async ()=>{
        let assignToArr = []
        let customerArr = [];
        await UserService.getAllUsers().then(
          (response) => {
            
             setAllUsers(response.data);
            //  console.log("All Users : ",response.data);
             response.data.map((user)=>{
                if(user.isDisabled===false || 
                  user.isDisabled===undefined 
                  || user.isDisabled==="" || user.isDisabled===null){
                      if (
                          user.roles[0].name === 'employee' ||
                          user.roles[0].name === 'manager'
                        ) {
                          assignToArr.push (user);
                        }
                        if (user.roles[0].name === 'user') {
                          customerArr.push (user);
                        }
                  }
              })
             setAllAssignTo(assignToArr);
             setAllCustomers(customerArr);
          },
          (error) => {
            const _content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
    
            // setAllUsers(_content);
            message.error(_content);
          }
        );
      }


    useEffect(()=>{
        if(successMessage){
            setSuccessMessage(false);
        }
    })

    useEffect(()=>{
       getUsers();
       getAllOrderCategoriesFunc();
    },[])

    const handleFileChange = (file)=>{
        setUploadFile(file);
    }

    const handleFileUpload = async (file)=>{
        setUploadPercent(40);
        if(awsData){
            setUploading(true);
           
            // AWS.config.update({
            //     accessKeyId: awsData.accessKeyId,
            //     secretAccessKey: awsData.secretKey
            // })

            // const myBucket = new AWS.S3({
            //     region: awsData.region,
            //     params:{Bucket:awsData.bucketName}
            //   })
            //   console.log(awsData.region)

            //   const params = {
            //     ACL: 'public-read',
            //     Key: file.name,
            //     ContentType: file.type,
            //     Body: file,
            //   }

            //   myBucket.putObject(params,function(err,data){
            //     if(err){
            //         console.log(err);
            //         setUploading(false)
            //     }else{
            //         console.log(data);
            //         setUploading(false);
            //         setFileUrl(awsData.uploadedLink + file.name);
            //     }
            //   })

            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
              
            setUploadPercent(78);
            const ReactS3Client = new S3(config);
            ReactS3Client.uploadFile(file,file.name).then(data=>{
                // console.log(data);
                if(data.location){
                    setUploadPercent(100);
                    setUploadStatus("success")
                    setUploading(false);
                    setFileUrl(data.location);
                }
                
            
                
            }).catch(err=>{
                console.log(err);
                message.error(err.message ? err.message : 'Unable to Upload file!');
                setUploadStatus("exception")
;               setUploading(false);
            })
        }

       

        
        

        
    }

    useEffect(()=>{
        if(fileUrl){
            setUploading(false);
            //console.log(fileUrl);
        }
    },[fileUrl]);


    const sendMail = async ( order) => {
        // console.log("Mail : ",order.userMail);
        if (customerEmail) {
          try {
            const data = {
              To: customerEmail,
              Subject: `${order.name} #${order._id}: New Order Submission`,
              Write: `
                    <h3><b>Order Details</b></h3>
                    <hr/>
                    <p><b>Order No. : </b>${order._id}</p>
                    <p><b>Order Title : </b>${order.name}</p>
                    <p><b>Order Deadline : </b>${deadline}</p>
                    <p><b>Timezone: : </b>${timezone}</p>
                    <p><b>Client Username : </b>${customer}</p>
                    <p><b>Client Email : </b>${customerEmail}</p>
                    <br/>
                    <br/>
                    <p>Order Created successfullly</p>
                    `,
              attachment: false,
              labelId:newOrderLabel.id
            };
            const response = await GoogleServices.sendmail (data);
            // console.log(response);
            if (response) {
              message.success (`Successfully Sent the Mail to ${customerEmail} !`);
            }
          } catch (err) {
            console.log (err);
            message.error (err.message ? err.message : 'Mail Not Sent!');
          }
        }
      };




    // const sendMail = async ()=>{
    //     // console.log("Mail : ",order.userMail);
    //    if(customerEmail){
    //     try{    
    //         const data ={
    //             To:customerEmail,
    //             Subject:"Order Created",
    //             Write:`Dear User, <br /> We Have Created Your Order <b>${name}</b>. You can see the details of the product in your dashboard!`,
    //             attachment:false,
    
    //         }
    //         const response = await GoogleServices.sendmail(data);
    //         // console.log(response);
    //             if(response){
    //                 message.success("Successfully Sent the Mail !");
    //             }
           
    //     }catch(err){
    //         console.log(err);
    //         message.error(err.message ? err.message : 'Mail Not Sent!')
    //     }
    //    }
    // }   


    const createAutomaticOutsource = async (data)=>{
        let finalData = {
          orderId:data._id,
          allReviews:[],
          technologies:data.technologies
        }
        // console.log(finalData);
        try{
          const response = await OrderService.createOutsourceOrder(finalData);
          console.log(response.data);
          
        }catch(err){
          console.log(err);
          message.error(err.message ? err.message : 'something went wrong!');
        }
      }
    
    const createOrder = (e)=>{
        setLoading(true);
       
        // console.log(bookingSession);
        // console.log(documentation);
        if(otherCategory){
            OrderService.createOrderCategory(otherCategory).then((result)=>{
                // console.log(result);
            }).catch((err)=>{
                console.log(err);
                message.error(err.message ? err.message : 'Unable to add new order category!');
            })
        }
        e.preventDefault();
        const data = {
            name:name,
            details:details,
            deadline:deadline,
            totalAmount: totalAmount!=="" ? totalAmount:0,
            paidAmount: paidAmount !=="" ? paidAmount : 0,
            partialAmount:totalAmount ? (Number(totalAmount)/2):null,
            category:otherCategory !=="" ?  otherCategory: (category.toLowerCase() !== "select category" ? category:null),
            documentation:documentation === "" ? false:documentation,
            bookingSession:bookingSession === ""? false:bookingSession,
            assignedTo:assignedTo,
            fileUrl:fileUrl,
            createdBy:currentUser.id,
            timezone:timezone,
            customer:customer,
            technologies:technologies,
            type:type,
            currency:currency,
            codeWalkThrough:codeWalkThrough ? codeWalkThrough : "50",
            reportFee:reportFee ? reportFee : "15"

        }


        OrderService.createOrder(data).then((response)=>{
            // console.log(response.data);
            let {order} = response;
            if (order) {
                sendMail(order);
                createAutomaticOutsource(order);
                setLoading(false);
                setSuccessMessage(true);
            } else {
              let {msg} = response;
              message.info (msg);
              setLoading (false);
            }
           
        }).catch((err)=>{
            // console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : "Unable To Create Order!");
            
        })


        // console.log(data);
    }

    const handleAssignedTo = (uname)=>{
        var foundUser = "";
        if(uname.toLowerCase() !=="select assign to"){
            foundUser = allUsers.find((user)=>{
                return user.username === uname;
            })
        }
        setAssignedTo(foundUser._id);
        // console.log(assignedTo);
    }

    const handleCategorySelect = (value)=>{
    //    console.log(value);
       setCategory(value);

    }

    const  handleFileRemove = (e)=>{
        e.preventDefault()
        let randomString = Math.random().toString(36);
        setFileKey(randomString);
        setUploadFile(null);
    }

    const handleCustomer = (uname)=>{
        var foundUser = "";
        if(uname.toLowerCase() !=="select customer"){
            foundUser = allUsers.find((user)=>{
                return user.username === uname;
            })
        }
        setCustomer(foundUser._id);
        setCustomerEmail(foundUser.email);
        // console.log(assignedTo);
    }

    const handleTechnologySelect = (values)=>{
        // console.log(values);
        setTechnologies(values)
    }

    const handleProjectTypeChange = (values)=>{
        setType(values);
    }

    return(
        <div className="createOrderForm">
        <Spin tip="Uploading..." spinning={uploading}>
            <div>
            <Breadcrumb>
            {currentUser.roles.includes('ROLE_SALES') ? 
            <Breadcrumb.Item> <Link to="/profile/orders-for-sales-role">
            All Orders</Link></Breadcrumb.Item>:
            <Breadcrumb.Item> <Link to="/profile/view-orders">All Orders</Link></Breadcrumb.Item>
            }
            <Breadcrumb.Item> <Link to="/profile/create-order">Create Order</Link></Breadcrumb.Item>
            </Breadcrumb>
            </div>
            <h3>Create Order</h3>
            <hr />
            
            <form>
            <table>
            <tr>
            <th><label>Project Name : </label></th>
            <td colSpan={5}><input type="text"  placeholder="e.g. Need help in web development" required className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}}/></td>
            </tr>
          
            <tr>
                <th><label>Category : </label></th>
                <td colSpan={2}>
                    {/* <select style={{marginTop:'1.2rem'}} 
                    value={category} required className="form-control"  
                    onChange={(e)=>{setCategory(e.target.value)}}>
                       <option>Select Category</option>
                       {allOrderCategory && allOrderCategory.map((name,index)=>{
                           return <option key={index}>{name}</option>
                       })}
                       <option>Other</option>
                    </select> */}
                    <Select
                        showSearch
                        virtual={true}
                        size={"large"}
                        style={{ width: '100%',fontWeight:'bold',marginTop:'5%' }}
                        onChange={handleCategorySelect}
                        placeholder="Search or Select a category"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   allOrderCategory &&
                            allOrderCategory.map((name,index)=>{
                                    return(<Option 
                                    key={index} 
                                    value={name} 
                                    >
                                    {name}
                                    </Option>)

                                }
                            )
                        }
                        <Option value="other">Other</Option> 
                    </Select>
                </td>
                {category.toLowerCase()==='other' && (
                    <>
                    <th colSpan={2}><label>Specify Other Category : </label>
                    <input type="text"   required value={otherCategory} className="form-control" onChange={(e)=>{setOtherCategory(e.target.value)}}/>
                    </th>
                   
                    </>
                )}

            </tr>

            <tr>
                <th><label>Technologies Used:</label></th>
                <td colSpan={4}>
                <Select
                        showSearch
                        mode={"multiple"}
                        virtual={true}
                        size={"large"}
                        style={{ width: '50%',fontWeight:'bold' }}
                        onChange={handleTechnologySelect}
                        placeholder="Search or Select a technology"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   skills &&
                            skills.map((item,index)=>{
                                    return(<Option 
                                    key={index} 
                                    value={item.name} 
                                    >
                                    {item.name}
                                    </Option>)

                                }
                            )
                        }
                    </Select>
                </td>
            </tr>

            <tr>
            <th>Type of Order:</th>
            <td> 
            <Select 
           defaultValue={'New Project'} 
           style={{ width: '100%',fontWeight:'bold' }}
           className="formInputs"  
           size={'large'}
           onChange={handleProjectTypeChange} >
          <Option value="New Project">New Project</Option>
          <Option value="Existing Project">Existing Project</Option>
          <Option value="Debugging">Debugging</Option>
          <Option value="Assignment">Assignment</Option>
          </Select>
          </td>
            </tr>

            <tr>
                <th>
                <label>Due Date : </label>
                </th>
                <td colSpan={2}>
                <input type="date" style={{marginTop:'1.2rem'}} required  min={today} className="form-control" value={deadline} onChange={(e)=>{setDeadline(e.target.value)}}/>
                </td>
                <th colSpan={3}>
                <label>Timezone : </label>
                <input type="text" placeholder="e.g. 10 PM EST" required className="form-control" value={timezone} onChange={(e)=>{setTimeZone(e.target.value)}}/>
                </th>
               
            </tr>

            <tr>
            <th>
            <label>Total Amount : </label>
            </th>
            <td colSpan={2}>
            <input style={{marginTop:'1.2rem'}} type="number"  min={1} className="form-control" value={totalAmount} onChange={(e)=>{setTotalAmount(e.target.value)}}/>
            </td>

            <th>
            <label>Paid Amount : </label>
            <input type="number" min={0} className="form-control" value={paidAmount} onChange={(e)=>{setPaidAmount(e.target.value)}}/>
            </th>

            <th>
            <label>Currency : </label>
            <input type="text" placeholder="e.g. USD,INR,CAD" className="form-control" value={currency} onChange={(e)=>{setCurrency(e.target.value)}}/>
            </th>
           
            </tr>


            <tr>

                <th>
                <label>Customer : </label>
                </th>
                <td colSpan={2}>
                {/* <select style={{marginTop:'1.2rem'}}  required className="form-control" onChange={(e)=>{handleCustomer(e.target.value)}}>
                        <option>Select Customer</option>   
                        {   allCustomers &&
                            allCustomers.map((user)=>{
                                return <option key={user._id}>{user.username}</option>
                            })
                        } 
                       
                    </select> */}
                    <br />
                    
                    <Select
                        showSearch
                        virtual={true}
                        style={{ width: '100%',fontWeight:'bold' }}
                        onChange={handleCustomer}
                        placeholder="Search or Select a customer"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   allCustomers &&
                            allCustomers.map((user)=>{
                                    return(<Option 
                                    key={user._id} 
                                    value={user.username} 
                                    >
                                    {user.username}
                                    </Option>)

                                }
                            )
                        } 
                    </Select>

                </td>


                <th colSpan={3}>
                <label>Assign To : </label>
                {/* <select  required className="form-control" onChange={(e)=>{handleAssignedTo(e.target.value)}}>
                        <option>Select Assign To</option>   
                        {   allAssignTo &&
                            allAssignTo.map((user)=>{
                                return <option key={user._id} value={user.username}>{user.username}
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                ( {user.roles[0].name} )</option>
                            })
                        } 
                       
                    </select> */}

                    <Select
                        showSearch
                        virtual={true}
                        style={{ width: '100%' }}
                        onChange={handleAssignedTo}
                        placeholder="Search or Select Employee or Manager"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   allAssignTo &&
                            allAssignTo.map((user)=>{
                                    return(<Option 
                                    key={user._id} 
                                    value={user.username} 
                                    >
                                    {user.username}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    ( {user.roles[0].name} )
                                    </Option>)

                                }
                            )
                        } 
                    </Select>
                </th>
               

               
            </tr>

            <tr>
                <th>
                <label>Description : </label>
                </th>
                <td colSpan={5} >
                <textarea style={{marginTop:'1.2rem'}} placeholder="Write some details of the order here...." required value={details} className="form-control" onChange={(e)=>{setDetails(e.target.value)}}>
                    </textarea>
                </td>

            </tr>

          
           <tr>
         
         <th>
         </th>
           <th colSpan={2}>
           <Checkbox  checked={bookingSession} onChange={(e)=>{setBookingSession(e.target.checked)}}><label> Need Code Walkthrough ? </label> </Checkbox>
           </th>

           <th colSpan={2}>
           <Checkbox checked={documentation}  onChange={(e)=>{setDocumentation(e.target.checked)}}> <label>Need Write up or report ? </label> </Checkbox>
           </th>

           </tr>
         
           
              
            
            
           <tr>
                <th>
                <label>Codewalkthrough Amount : </label>
                </th>
                <td colSpan={2}>
                <input style={{marginTop:'1.2rem'}} type="number" min={0} className="form-control" 
                value={codeWalkThrough} onChange={(e)=>{setCodeWalkThrough(e.target.value)}}/>
                </td>

                <th colSpan={3}>
                <label>Report Per Page Amount: </label>
                <input type="number"  min={0}  className="form-control" value={reportFee} onChange={(e)=>{setReportFee(e.target.value)}}/>
                </th>
               
           </tr>

           <tr>
            <th>
            <label> Upload Project Docs: </label>
            </th>
            <td colSpan={5}>
            <input type="file" 
            className="form-control file" 
            key={fileKey}
            onChange={(e)=>{handleFileChange(e.target.files[0])}}/> 
            <button className="uploadBtn"
            disabled={uploadFile===null} 
            type="button" 
            onClick={()=>{handleFileUpload(uploadFile)}}>Upload</button> 
           {uploadFile &&
            <button onClick={handleFileRemove}  className='uploadBtn'>
                Remove
            </button>
            }
            <br />
            {uploadPercent >0 &&
            <div>
                <Progress percent={uploadPercent} size="small" status={uploadStatus}/>
            </div>
            }
            {uploadPercent===100 ? 
                    <div style={{color:'green'}}>
                        File Uploaded Successfully! 
                    </div>
                    :
                    uploading &&
                    <div style={{color:'blue'}}>
                        uploading...
                    </div>
            }
            {uploadStatus==="exception" &&
            <div style={{color:'red'}}>
                        Failed to upload file! 
            </div>
            }
            </td>
           </tr>
          
        </table>
            <div className="form-group">
            <button style={{width:'fit-content'}} className="pay-btn"
            disabled = {name === "" || details === "" || deadline ==="" || category === ""} 
            onClick={(e)=>{createOrder(e)}}>{loading ? <Spin indicator={spinIcon} /> : 'Create Order'}</button>
            </div>
        </form>
          {successMessage ? <Message type="success" message="Created Order Successfully" /> : null}

          </Spin>
        </div>
    )
}


export default CreateOrderForm;
