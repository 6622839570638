import React, { useEffect,useState } from 'react'
import {Breadcrumb,Spin,message,Input,Select, Button} from 'antd';
import {Link} from 'react-router-dom';
import orderService from '../../../services/order.service';
import {useSelector} from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
const {Option} = Select;
const {TextArea} = Input;

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 


function NewAssignmentHelp() {
  
    const { user: currentUser } = useSelector((state) => state.auth);
    const [allOrderCategory,setAllOrderCategory] = useState([]);
    const [category,setCategory] = useState("");
    const [otherCategory,setOtherCategory] = useState("");
    const [details,setDetails] = useState("");
    const [type,setType] = useState("");
    const [summary,setSummary] = useState("");
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        let isMounted = true;
        if(isMounted){
            const getAllCategories = async ()=>{
                try{
                    const response = await orderService.getAllOrderCategory();
                    // console.log("Categories : ",response.data);
                    let categories = [];
                    response.data.forEach((item)=>{
                        categories.push(item.name);
                    })
                    setAllOrderCategory(categories);
                }catch(err){
                    console.log(err);
                    message.error(err.message ? err.message : " Unable  to Get all Orders Categories!");
                }
            }

            getAllCategories();
        }

        return  ()=>{
            isMounted = false
        }
    },[])

    const handleCategorySelect = (value)=>{
        //    console.log(value);
           setCategory(value);
    }

    const bookAssignmentHelp = async ()=>{
        setLoading(true);
        let data = {
            category:otherCategory ? otherCategory : category,
            type:type,
            summary:summary,
            details:details,
            bookingBy:currentUser.id
        }
        // console.log("values : ",data)
        try{
            const response = await orderService.createNewAssignmentHelp(data);
            if(response){
                message.success("Project request submitted for review!");
                setLoading(false);
            }
        }catch(err){
            console.log(err.message ? err.message : 'something went wrong!');
            setLoading(false);

        }
        
    }

    const handleProjectTypeChange = (values)=>{
        setType(values);
    }
  return (
    <div className="bookASession">
    <div>
    <Breadcrumb>
        <Breadcrumb.Item> <Link to="/profile/codersarts-dashboard-home">Dashboard Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Create Assignment Help</Breadcrumb.Item>
    </Breadcrumb>
    </div>
    <div className='bookingHeader'>
        <h5>Give us Some Details for the Project</h5>
        <div className="bookingDesc">Let our experts know what you need help with. The more details you provide, 
        the more quickly you'll be able to connect with a expert who can help!.</div>
    </div>

    <div className="booking__container">
        <div className="form-group">
           <div className="labelGroup">
           <div className='num'>1</div><div> <label>Please Select a Category of your project</label></div>
           </div>
           <Select
                        showSearch
                        virtual={true}
                        style={{ width: '70%',fontWeight:'bold' }}
                        className="formInputs"
                        onChange={handleCategorySelect}
                        placeholder="Search or Select a category"
                        optionFilterProp="value"
                        filterOption={(input, option) =>
                        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                        optionA.value.toLowerCase().localeCompare(optionB.value.toLowerCase())
                        }
                    >
                        {   allOrderCategory &&
                            allOrderCategory.map((name,index)=>{
                                    return(<Option 
                                    key={index} 
                                    value={name} 
                                    >
                                    {name}
                                    </Option>)

                                }
                            )
                        }
                        <Option value="other">Other</Option> 
                    </Select>
        </div>
        <div className="form-group">
        {category.toLowerCase()==='other' && (
                    <>
                   <label style={{marginLeft:'5%'}}>Specify Other Category : </label>
                    <input type="text"  
                    required value={otherCategory} 
                    className="form-control formInputs" onChange={(e)=>{setOtherCategory(e.target.value)}}/>
                   
                    </>
                )}
        </div>


        <div className="form-group">
           <div className="labelGroup">
           <div className='num'>2</div><div> <label>Please Select the type of project</label></div>
           </div>
          <Select 
           defaultValue={'New Project'} 
           style={{ width: '70%',fontWeight:'bold' }}
           className="formInputs"  
           onChange={handleProjectTypeChange} >
          <Option value="New Project">New Project</Option>
          <Option value="Existing Project">Existing Project</Option>
          <Option value="Debugging">Debugging</Option>
          <Option value="Assignment">Assignment</Option>
          </Select>
        </div>

        <div className="form-group">
           <div className="labelGroup">
           <div className='num'>3</div><div> <label>Please title or summary of your project</label></div>
           </div>
           <input 
               type="text" 
               value={summary}
               placeholder="Short title or summary of your  project"
               className="form-control formInputs"
               onChange={(e)=>{setSummary(e.target.value)}}/>
           
        </div> 

        <div className="form-group">
           <div className="labelGroup">
           <div className='num'>4</div><div> <label>Please Enter some details</label></div>
           </div>
           <TextArea 
           showCount 
           allowClear
           maxLength={255} 
           style={{ height: 160,marginTop:'2%' }} 
           className="formInputs w-70"
           onChange={(e)=>{setDetails(e.target.value)}} />
           <Button 
            type={'primary'} 
            onClick={bookAssignmentHelp}>
            {loading ? <Spin indicator={spinIcon} /> : 'Make Request'}
           
        </Button>
           
        </div>
       

       

    </div>
    

    </div>
  )
}

export default NewAssignmentHelp