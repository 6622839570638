import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './CardSection.css';


const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      marginTop:'2%',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

function CardSection() {

  return (
    <label style={{marginLeft:'2%'}}>
    <b style={{marginLeft:'-3%'}}>Card Details </b>
    <br />
   <CardElement  options={CARD_ELEMENT_OPTIONS}/>
   {/* <CardNumberElement options={CARD_ELEMENT_OPTIONS}/>
   <br />
   <CardExpiryElement  options={CARD_ELEMENT_OPTIONS} />
   <br />
   <CardCvcElement   /> */}
   </label>
  );
};

export default CardSection;
