import axios from 'axios';

// var API_URL  = "http://3.111.82.112:3000/api/auth/";
// var API_URL  = "http://3.7.156.63/api/auth/";
// var API_URL = 'http://localhost:3000/api/auth/';
var API_URL = 'https://dashboard.codersarts.com/api/auth/';

//  if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/auth/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70/api/auth/";

// }

const register = data => {
  return axios.post (API_URL + 'signup', {
    data,
  });
};

const login = async (username, password) => {
  // console.log("Data : ",username);
  return axios
    .post (API_URL + 'signin', {
      username,
      password,
    })
    .then (response => {
      if (response.data.accessToken) {
        localStorage.setItem ('user', JSON.stringify (response.data));
      }
      return response.data;
    });
};

const loginWithPhone = async phoneNumber => {
  // console.log("Data : ",username);
  return axios
    .post (API_URL + 'login-with-phonenumber', {
      phoneNumber,
    })
    .then (response => {
      if (response.data.accessToken) {
        localStorage.setItem ('user', JSON.stringify (response.data));
      }
      return response.data;
    });
};

const sendOtpToPhone = phone => {
  return axios
    .post (API_URL + 'send-otp-sms', phone)
    .then (response => {
      return response;
    })
    .catch (error => {
      console.log (error);
    });
};

const logout = () => {
  localStorage.removeItem ('user');
};

export default {
  register,
  login,
  logout,
  loginWithPhone,
  sendOtpToPhone,
};
