import { message,Spin, Tooltip,Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react'
import orderService from '../../services/order.service';
import UserService from '../../services/user.service';
import './CreateManualPayment.css';
import { LoadingOutlined } from '@ant-design/icons';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';



const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;
function CreateManualPayment({match}) {
    const [name,setName] = useState(null);
    const [amount,setAmount] = useState(null);
    const [email,setEmail] = useState(null);
    const [user,setUser] = useState(null);
    // const [allUsers,setAllUsers] = useState(null);
    // const [allOrders,setAllOrders] = useState(null);
    const [order,setOrder] = useState(null);
    const  [paymentMethod,setPaymentMethod] = useState(null);
    const  [otherMethod,setOtherMethod] = useState(null);
    const [other,setOther] = useState(false);
    const [loading,setLoading] = useState(false);
    const [currency,setCurrency] = useState(null);
    const [address,setAddress] = useState(null);
    const [remainingAmount,setRemainingAmount] = useState("");
    const [initialPaidAmount,setInitialPaidAmount] = useState(0);

    const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(()=>{
      if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
        if(currentUser.roles.includes('ROLE_SALES')){
            return;
        }
          history.push('/profile');
      }
  },[currentUser])

   

    const setAdminAsUser = ()=>{
        const {id,name,email} = currentUser;
            setUser(id);
            setName(name);
            setEmail(email);
    }


    const getUser = async (userId)=>{
        try{
            const response =  await UserService.getUserById(userId);
            // console.log("USER : ",response.data);
            const {id,name,email} = response.data;
            setUser(id);
            setName(name);
            setEmail(email);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
      }

      const getOrder = async ()=>{
        try{
            const response =  await orderService.getSingleOrder(match.params.id);
            // console.log("PAYMENT ORDER:",response.data);
            setOrder(response.data);
            setInitialPaidAmount(response.data.paidAmount !=='NaN' ? response.data.paidAmount:0);
            setRemainingAmount(Number(response.data.totalAmount) - ( 
                response.data.paidAmount !="NaN" ? Number(response.data.paidAmount) : 0))
            if(!response.data.createdBy){
                setAdminAsUser();
            }
            if(response.data.createdBy && response.data.createdBy.length >0){
                getUser(response.data.createdBy[0])
            }
            
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
      }
      useEffect(()=>{
        getOrder();
      },[])

    //   const handleAssignedTo = (uname)=>{
    //     var foundUser = "";
    //     if(uname.toLowerCase() !=="select user"){
    //         foundUser = allUsers.find((user)=>{
    //             return user.username === uname;
    //         })
    //     }
    //     console.log(foundUser);
    //     if(foundUser){
    //         setUser(foundUser._id);
    //     }
        
    // }

    // const handleOrderSelect = (oName)=>{
    //     var foundOrder = "";
    //     if(oName.toLowerCase() !=="select order"){
    //         foundOrder = allOrders.find((order)=>{
    //             return order.name === oName;
    //         })
    //     }
    //     console.log(foundOrder);
    //     setOrder(foundOrder);
    //     console.log(order);
    // }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoading(true);
        let data = {
            fName:name,
            amount:Number(initialPaidAmount) + Number(amount),
            email:email,
            user:user,
            order:order,
            paymentMethod:paymentMethod.toLowerCase() ==="other" ? otherMethod : paymentMethod,
            paymentType:'Manual',
            status:'succeeded',
            address:address,
            remainingAmount:remainingAmount ? remainingAmount:0,
            currency:currency
        }

        try{
            const response = await orderService.setOrderPayment(data);
            // console.log(response);
            const updateOrderResult = await orderService.updateOrder({_id:match.params.id,paidAmount:Number(initialPaidAmount) + Number(amount)});
            // console.log("update order result : ",updateOrderResult);
            setLoading(false);
            message.success("Payment Created Successfully!");
            // if(currentUser.roles.includes('ROLE_SALES')){
            //     history.push('/profile/orders-for-sales-role');
            // }
           
            // if(currentUser.roles.includes('ROLE_ADMIN')){
            //     history.push('/profile/all-order-payments');
            // }
            
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message);
        }

       
        
    }

    const handlePaymentMethod = (method)=>{
        if(method.toLowerCase()!=="select method"){
            setPaymentMethod(method);
            setOther(false);
        }
        if(method.toLowerCase()==="other"){
            setOther(true);
        }
        // console.log(paymentMethod)
    }

    const handlePaidAmount = (a)=>{
        setAmount(a);
        if(order.paidAmount!=='NaN'){
            setRemainingAmount((Number(order.totalAmount)-Number(order.paidAmount))-a);
        }else if(order.paidAmount==='NaN'){
            setRemainingAmount(Number(order.totalAmount)-a);
        }
    }
     
    return (
        <div className="createManualPayment">

            <div>
            <Breadcrumb>
            {currentUser.roles.includes('ROLE_SALES') ? 
            <Breadcrumb.Item> <Link to="/profile/orders-for-sales-role">
            All Orders</Link></Breadcrumb.Item>:
            <Breadcrumb.Item> <Link to="/profile/view-orders">All Orders</Link></Breadcrumb.Item>
            }
            <Breadcrumb.Item> <Link to={`/profile/create-manual-payment/${match.params.id}`}>Manual Payment</Link></Breadcrumb.Item>
            </Breadcrumb>
            </div>
        <div>
            <h2>Create Manual Payment</h2>
            <hr />
        </div>

            <form >

                    <div className="d-flex">
                        <div className="form-group">
                            <label>Total Amount : </label>
                            <Tooltip title="You Can't Edit This! " color={'#f50'}>
                            <input type="number" min={0} value={order && order.totalAmount} className="form-control" readOnly/>
                            </Tooltip>
                        </div>
                        <div className="form-group">
                            <label>Paid Amount : </label>
                            <input type="number" min={0} className="form-control" value={amount} onChange={(e)=>{handlePaidAmount(e.target.value)}}/>
                        </div>
                   </div>
                <div className="form-inline">
                   <div className="d-flex">
                        <div className="form-group">
                            <label>Remaining Amount : </label>
                            <Tooltip title="You Can't Edit This! " color={'#f50'}>
                            <input type="number" min={0} className="form-control"  value={remainingAmount} readOnly/>
                            </Tooltip>
                        </div>
                        <div className="form-group">
                            <label>Currency : </label>
                            <input type="text" className="form-control" value={currency} onChange={(e)=>{setCurrency(e.target.value)}} />
                        </div>
                   </div>
                  
                 
                </div>

                <div className="d-flex" style={{flexWrap:'wrap'}}>
                <div className="form-group">
                    <label>Payment Method : </label>
                    <select  required className="form-control" onChange={(e)=>{handlePaymentMethod(e.target.value)}}>
                        <option>Select Method</option>   
                        <option>Cash</option>
                        <option>Bank Transfer</option>
                        <option>UPI</option>
                        <option>Other</option>
                    </select>
                </div>
                {
                    other && 
                    <div className="form-group">
                    <label>Specify Other Payment Method : </label>
                    <input type="text" className="form-control" value={otherMethod} onChange={(e)=>{setOtherMethod(e.target.value)}} />
                    </div>
                }

                    <div className="form-group">
                        <label>Complete Address: </label>
                        <textarea type="text"
                        style={{width:'35vw',height:'5vh',resize:'vertical'}}
                        className="form-control"
                        value={address} onChange={(e)=>{setAddress(e.target.value)}}>
                        </textarea>

                    </div>
               </div>

               
              

                

                <div className="form-group">
                <button style={{width:'15vw'}} className="pay-btn"
                disabled = { loading || amount === null || currency===null || paymentMethod===null} 
                onClick={handleSubmit}
                >{loading ? <Spin indicator={spinIcon} /> : 'Create Payment'}</button>
                </div>
               

            </form>
          {/* {successMessage ? <Message type="success" message="User Created Successfully" /> : null}  */}
        </div>
    )
}

export default CreateManualPayment
