import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import userService from '../../../services/user.service';
import {
  Button,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Space,
  Table,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MoreOutlined,
  PlusOutlined,
} from '@ant-design/icons';

function SkillsCategoryList () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);
  const [filteredArr, setFilteredArr] = useState ([]);
  const [updatedName, setUpdatedName] = useState ('');
  const [currentSkill, setCurrentSkill] = useState ('');
  const [loading, setLoading] = useState (false);
  const [updateModal, setUpdateModal] = useState (false);
  const [newSkillCategoryModal, setNewSkillCategoryModal] = useState (false);
  const [loadingCategory, setLoadingCategory] = useState (false);
  const [newSkillCategory, setNewSkillCategory] = useState ('');

  const getAllCategory = async () => {
    try {
      const response = await userService.getAllSkillsList ();
      //   console.log (response.data);
      setSkillCategoriesArr (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (() => {
    const getAllCategory = async () => {
      try {
        const response = await userService.getAllSkillsList ();
        // console.log (response.data);
        setSkillCategoriesArr (response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Something went wrong!');
      }
    };
    getAllCategory ();
  }, []);
  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        if (currentUser.roles.includes ('ROLE_SALES')) {
          return;
        }
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const handleUpdateOpenModal = item => {
    setCurrentSkill (item);
    setUpdateModal (true);
  };

  const deleteSkill = async (id, name) => {
    try {
      const response = await userService.deleteSkillCategory (id);
      if (response) {
        message.success (`${name} category deleted successfully`);
        getAllCategory ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };
  const handleSkillDelete = (id, name) => {
    Modal.confirm ({
      title: 'Confirm Delete',
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete ${name} category?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        deleteSkill (id, name);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'title',
      render: record => {
        return <b>{record.name}</b>;
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu
                style={{
                  marginRight: '5%',
                  marginTop: '-5%',
                  width: 'fit-content',
                }}
              >

                <Menu.Item
                  key="1"
                  onClick={() => {
                    handleUpdateOpenModal (record);
                  }}
                >
                  <EditOutlined /> Edit
                </Menu.Item>
                <Menu.Item
                  key="2"
                  danger
                  onClick={() => {
                    handleSkillDelete (record._id, record.name);
                  }}
                >
                  <DeleteOutlined /> Delete
                </Menu.Item>
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      filteredData = skillsCategoryArr.filter (skill => {
        // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
        return (
          skill.name && skill.name.toLowerCase ().match (item.toLowerCase ())
        );
      });
      // console.log("SEARCH RESULT : ",filteredData);
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  const handleSkillNameChange = e => {
    // console.log (e.target.value);

    setUpdatedName (e.target.value);
  };

  const handleUpdateSkillCategory = async () => {
    setLoading (true);
    try {
      const response = await userService.updateSkillsCategory ({
        _id: currentSkill._id,
        name: updatedName,
      });
      if (response) {
        setLoading (false);
        setUpdateModal (false);
        message.success (
          `Updated ${currentSkill.name} to ${updatedName} successfully!`
        );
        getAllCategory ();
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  const handleNewSkillCategory = () => {
    setNewSkillCategoryModal (true);
  };

  const handleAddNewSkillCategory = async () => {
    // console.log("skill category!");
    setLoadingCategory (true);

    try {
      const response = await userService.addNewSkillToList (newSkillCategory);
      if (response) {
        setLoadingCategory (false);
        setNewSkillCategoryModal (false);
        setNewSkillCategory ('');
        message.success ('New skill added to skill category!');
        getAllCategory ();
      }
    } catch (err) {
      console.log (err);
      setLoadingCategory (false);
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString ();
      message.error (msg);
    }
  };

  const handleNewSkillCategoryCancel = () => {
    setNewSkillCategoryModal (false);
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h3>Skills Category</h3>
        </div>

        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By name"
            style={{width: '30vw', marginBottom: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>
        <Button type="primary" onClick={handleNewSkillCategory}>
          <PlusOutlined /> Create Category
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={filteredArr.length > 0 ? filteredArr : skillsCategoryArr}
      />

      <Modal
        title={'Update Skill Category'}
        visible={updateModal}
        onCancel={() => {
          setUpdateModal (false);
        }}
        confirmLoading={loading}
        onOk={handleUpdateSkillCategory}
      >
        <div>
          <label>Enter Updated Skill Category Name : </label>
          <Input
            defaultValue={currentSkill.name}
            onChange={handleSkillNameChange}
          />
        </div>
      </Modal>

      <Modal
        title="Add New Skill to Category"
        visible={newSkillCategoryModal}
        onOk={handleAddNewSkillCategory}
        okText="Add"
        confirmLoading={loadingCategory}
        onCancel={handleNewSkillCategoryCancel}
      >

        <input
          type="text"
          name="skillCategory"
          value={newSkillCategory}
          onChange={e => {
            setNewSkillCategory (e.target.value);
          }}
          className="form-control mb-4"
          placeholder="Enter New Skill Category"
        />
      </Modal>

    </div>
  );
}

export default SkillsCategoryList;
