import axios from 'axios';
import authHeader from './auth-header';

// var  API_URL = "http://3.7.156.63:3000/api/";

// var  API_URL = "http://3.7.156.63/api/";
// var API_URL = 'http://localhost:3000/api/events/';
var API_URL = 'https://dashboard.codersarts.com/api/events/';

// if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70:8080/api/";
// }

const createNewEvent = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'create-new-event', data, {
    headers: authHeader (),
  });
};

const getAllEvents = () => {
  // console.log("DATA RECEIVED : ",data)
  return axios.get (API_URL + 'get-all-events', {headers: authHeader ()});
};

const updateSingleEvent = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'update-single-event', data, {
    headers: authHeader (),
  });
};

const getUpcomingEvents = () => {
  return axios.get (API_URL + 'get-upcoming-events');
};

const getUpcomingSingleEvents = id => {
  return axios.get (API_URL + 'get-upcoming-single-event/' + id);
};

const getSingleEvent = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'get-single-event', data, {
    headers: authHeader (),
  });
};

const deleteSingleEvent = data => {
  // console.log("DATA RECEIVED : ",data)
  return axios.post (API_URL + 'delete-single-event', data, {
    headers: authHeader (),
  });
};

const registerUserInEvent = data => {
  return axios.post (API_URL + 'register-user', data, {
    headers: authHeader (),
  });
};

const updateAttendee = data => {
  return axios.post (API_URL + 'update-attendee', data, {
    headers: authHeader (),
  });
};

const getAllAttendeeEvents = id => {
  return axios.post (
    API_URL + 'get-all-attendee-events',
    {id: id},
    {
      headers: authHeader (),
    }
  );
};

const EventsServices = {
  createNewEvent,
  updateSingleEvent,
  getAllEvents,
  getSingleEvent,
  deleteSingleEvent,
  getUpcomingEvents,
  getUpcomingSingleEvents,
  registerUserInEvent,
  updateAttendee,
  getAllAttendeeEvents,
};

export default EventsServices;
