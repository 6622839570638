import { Table, Space,Button, message } from 'antd';
import { useSelector } from 'react-redux';
import React,{ useState,useEffect } from 'react';
import UserService from '../../../services/user.service';
import { Link } from 'react-router-dom';
import { history } from '../../../helpers/history';
// import orderService from '../../../services/order.service';
// import {ExclamationCircleOutlined} from '@ant-design/icons';


const MyEmployee = ()=>{
  const [employees,setEmployees] = useState("");
  // const [employeeOrders,setEmployeeOrders] = useState([]);
  // const [allOrders,setAllOrders] = useState([]);
  // const [finalData,setFinalData] = useState([]);
  // const [currentEmployeeId,setCurrentEmployeeId] = useState("");
  // const [isEmployeeOrderModal,setIsEmployeeOrderModal] = useState(false);
 
  const { user: currentUser } = useSelector((state) => state.auth);
 
  useEffect(()=>{
      if(!currentUser || !currentUser.roles.includes('ROLE_MANAGER')){
          history.push('/profile');
      }
  },[currentUser])

  const getAllAssignedEmployee = async ()=>{
    try{
      const response = await UserService.getAllEmployees(currentUser.id);
      // console.log(response.data);
      let data = []
      response.data.map((item,index)=>{
       data.push({
        key:index+1,
        name:item.name ? item.name : "Guest",
        id:item._id,
        username:item.username,
        age:item.age ? item.age : "N/A",
        address:item.address ? item.address : "N/A",
        email:item.email,
        isDisabled:item.isDisabled,
       })
      })
        setEmployees(data);
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : 'Something Went Wrong !');
    }
  }

  useEffect(()=>{
    getAllAssignedEmployee();
    // getEmployeeOrders();
  },[])

  // const assignProject = (id)=>{
  //   // console.log("ID : ",id);
  //   // console.log("Orders : ",allOrders);
  //   const orders = []
  //   allOrders.map((o)=>{
  //     if(o.assignedEmployee && o.assignedEmployee.length > 0){
  //       // console.log("employee")
  //       if(o.assignedEmployee[0]===id){
  //         // console.log("fallend");
  //         orders.push(o);
  //       }
  //     }
  //   });

  //   // console.log("Orders : ",orders);
    
    
  //   if(orders.length > 0){
  //   let orderNames = [];
  //   orders.map((o)=>{
  //     orderNames.push(o.name);
  //   });

  //   orderNames = orderNames.join(', ');
  //     showEmployeeOrderModal(id,orderNames);
  //   }else{
  //     proceedToAssign(id);
  //   }
    

  //     // console.log(uname);
  // }

  // const proceedToAssign = (id)=>{
  //   history.push(`/profile/assign-order/${id}`)
  // }
  // const showEmployeeOrderModal = (id,orders)=>{
  //   Modal.confirm({
  //     title: 'Already Assigned Order!',
  //     icon: <ExclamationCircleOutlined />,
  //     content: `This user is already working on the orders : ${orders}`,
  //     okText: 'Assign Order',
  //     cancelText: 'Cancel',
  //     onOk:()=>{proceedToAssign(id)}

  //   });
  // }

  

  const handleViewDetails = (id)=>{
    history.push(`/profile/manage-users/view/${id}`);
  }

  // const getEmployeeOrders = async (id)=>{
  //   try{
  //     const response = await orderService.getSpecificOrder(currentUser.id);
  //     // console.log(response.data);
  //     setAllOrders(response.data);
     
  //     if(response.data){
  //       return response.data;
  //     }
  //   }catch(err){
  //     console.log(err);
  //     message.error(err.message ? err.message : 'Unable to fetch order for employee!');
  //   }
  // }

  // useEffect(()=>{
  //   const  data = []
  //   if(employees){
    
  //   // console.log("allOrders : ",allOrders)
  //     employees.map((item,index)=>{
  //       // console.log("i ran!");
        

  //      if(allOrders.length > 0){
  //       allOrders.map((o)=>{
  //         data.push({
  //           key:index+1,
  //           name:item.name ? item.name : "Guest",
  //           id:item._id,
  //           username:item.username,
  //           assignedOrder:o.assignedEmployee && o.assignedEmployee.length>0 && item._id===o.assignedEmployee[0] ? o.name : "N/A",
  //           age:item.age ? item.age : "N/A",
  //           address:item.address ? item.address : "N/A",
  //           email:item.email,
  //           isDisabled:item.isDisabled,
  //       })
  //     })
  //      }else{
  //       data.push({
  //         key:index+1,
  //         name:item.name ? item.name : "Guest",
  //         id:item._id,
  //         username:item.username,
  //         assignedOrder: "N/A",
  //         age:item.age ? item.age : "N/A",
  //         address:item.address ? item.address : "N/A",
  //         email:item.email,
  //         isDisabled:item.isDisabled,
  //     })
  //      }
        
       
  //     })
  //   }
  //   // console.log("final Data : ",data);
  //   setFinalData(data);
  // },[employees])
  
  
    
    const columns = [
        {
          title: 'Name',
          key: 'name',
          render: (record) => <Link to={`/profile/manage-users/view/${record.id}`}><b>{record.name}</b></Link>,
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: text => <a>{text}</a>,
        },
        {
          title: 'Age',
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
       
        {
          title: 'Action',
          key: 'action',
          render: (record) => (
            <Space size="middle">
              <Button type={'primary'} disabled={record.isDisabled ? true : false}
               danger={record.isDisabled ? true : false} 
               onClick={()=>{handleViewDetails(record.id)}}
              >
              View Details
                </Button>
            
              {/* <Button type={'primary'} disabled={record.isDisabled ? true : false}
               danger={record.isDisabled ? true : false} 
               onClick={()=>{assignProject(record.id)}}>{record.isDisabled ?
                'User Disabled' : 'Assign'}
              </Button> */}
              



              {/* <Dropdown overlay={
                <Menu style={{marginLeft:'-8%',width:'fit-content'}}>
                <Menu.Item key="1" onClick={()=>{handleViewDetails(record.id)}}>
                <EyeOutlined /> View Details
                </Menu.Item>

                <Menu.Item key="2">
                <Link to={`/profile/assign-order/${record.id}`}> 
                <UserOutlined /> Assign Order
                </Link>
                </Menu.Item>

                <Menu.Item key="3" onClick={handleProgressClick}>
                <ReloadOutlined /> Add Progress
                </Menu.Item>
               to={`/profile/assign-order/${record.id}`}
              </Menu>
              }>
              <Button type={"primary"} >More Actions  <CaretDownOutlined /></Button>
            </Dropdown> */}
            </Space>
          ),
        },
      ];

     
     
    
     
      
      return(
          <div>
            <h3>My Employees</h3>
              <Table columns={columns} dataSource={employees} />
            
              {/* <Modal 
            title={"Order Already Assigned"}
            visible={isEmployeeOrderModal} 
            onOk={handleEmployeeModalOk} 
            onCancel={handleCancelEmployeeModal}
            confirmLoading={confirmEmployeeModalLoading}
         >
            
        </Modal> */}
        
            
          </div>
      )
}


export default MyEmployee;
