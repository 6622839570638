import React, {useState, useEffect} from 'react';
import {Table, Space, Tag, Button, Modal, Menu, Dropdown, message} from 'antd';
import {Link} from 'react-router-dom';
import orderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import {
  ShoppingCartOutlined,
  DeleteOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  EyeOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {history} from '../../../helpers/history';
import {useSelector} from 'react-redux';
import moment from 'moment';

const AllPayments = () => {
  const [allPayments, setAllPayments] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [paymentResponse, setPaymentResponse] = useState (null);
  const [filteredMethodArr, setFilteredMethodArr] = useState (null);
  const [filteredArr, setFilteredArr] = useState ([]);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        if (currentUser.roles.includes ('ROLE_SALES')) {
          return;
        }
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getOrders = async () => {
    try {
      const response = await orderService.getAllPayments ();
      // console.log(response.data);
      setPaymentResponse (response.data);
      let data = [];
      response.data.map (d => {
        let finalDate = 'N/A';
        if (d.createdAt) {
          const date = new Date (d.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }

        let modifiedData = {
          id: d._id,
          date: finalDate,
          sortDate: d.createdAt,
          customer: d.fName ? d.fName : d.user[0] ? d.user[0].username : 'N/A',
          userId: d.user[0] && d.user[0]._id,
          order: d.order.name,
          email: d.user && d.user[0] ? d.user[0].email : 'N/A',
          orderId: d.order._id,
          paymentMethod: d.paymentMethod
            ? d.paymentMethod.toUpperCase ()
            : d.paymentIntent
                ? d.paymentIntent.payment_method_types[0]
                    ? d.paymentIntent.payment_method_types[0].toUpperCase ()
                    : 'Not Available'
                : 'Not available',
          status: d.status
            ? d.status
            : d.paymentIntent
                ? d.paymentIntent.status ? d.paymentIntent.status : 'N/A'
                : 'N/A',
          amount: d.paymentIntent ? d.paymentIntent.amount / 100 : d.amount,
          remaining: d.remainingAmount ? d.remainingAmount : 0,
          partial: d.isPartial ? d.isPartial : false,
          currency: d.currency ? d.currency : d.paymentIntent.currency,
          paymentType: d.paymentType ? d.paymentType : 'Not Speicified',
          totalAmount: d.order.totalAmount ? d.order.totalAmount : 'N/A',
          paidAmount: d.order.paidAmount ? d.order.paidAmount : 'N/A',
          transactionId: d.paymentIntent ? d.paymentIntent.id : 'N/A',
        };

        data.push (modifiedData);
      });

      setAllPayments (data);
    } catch (err) {
      console.log (err);
      message.error (err.message);
    }
  };

  useEffect (() => {
    getOrders ();
  }, []);

  useEffect (
    () => {
      let methodFilterArr = [];
      allPayments.map (p => {
        if (p.paymentMethod) {
          methodFilterArr.push ({
            text: p.paymentMethod,
            value: p.paymentMethod,
          });
        }
      });
      // console.log("NAME FILTER : ",assignToArr);
      methodFilterArr = methodFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      setFilteredMethodArr (methodFilterArr);
    },
    [allPayments]
  );

  const recyclePayment = async id => {
    let recycledPayment = paymentResponse.filter (item => {
      return item._id === id;
    });
    // console.log("RECYLED PAYMENT : ",recycledPayment);
    let modifiedRecycleData = recycledPayment[0];
    if (recycledPayment[0].user && recycledPayment[0].user[0]) {
      modifiedRecycleData.user = [recycledPayment[0].user[0]._id];
    }
    //  console.log("Recyled Payment : ",modifiedRecycleData);
    let finalRecycledData = {
      data: modifiedRecycleData,
      recycleType: 'Payment',
    };

    try {
      const response = await orderService.recycleData (finalRecycledData);
      // console.log(response);
      if (response.status == 200) {
        message.success (
          `${recycledPayment[0].fName ? recycledPayment[0].fName : 'Item'} Added to Recyle Bin!`
        );
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : "Couldn't Recycle The Item !");
    }

    getOrders ();
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'date',
      key: 'date',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Transaction Id',
      dataIndex: 'transactionId',
      key: 'transactionId',
    },
    {
      title: 'Paid Amount',
      key: 'paidAmount',
      render: record => {
        return <b>{record.amount} {record.currency}</b>;
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      filters: filteredMethodArr,
      onFilter: (value, record) => record.paymentMethod.indexOf (value) === 0,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: text => {
        return <Tag color={'cyan'}>{text}</Tag>;
      },
    },

    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu style={{marginLeft: '-25%'}}>
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link
                    to={`/profile/single-payment-details/${record.id}`}
                    target="_blank"
                  />
                  {' '}
                  View Details
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to={{
                      pathname: `/profile/view-order-details/${record.orderId}`,
                    }}
                    target="_blank"
                    id="action"
                  >
                    <ShoppingCartOutlined /> View Order{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link
                    to={{
                      pathname: `/profile/manage-users/view/${record.userId}`,
                    }}
                    target="_blank"
                    id="action"
                  >
                    <UserOutlined /> View User{' '}
                  </Link>
                </Menu.Item>
                {currentUser.roles.includes ('ROLE_SALES')
                  ? null
                  : <Menu.Item
                      key="4"
                      danger
                      onClick={() => {
                        confirmRecyclePayment (record.id);
                      }}
                    >
                      <DeleteOutlined /> Recycle
                    </Menu.Item>}
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>

        </Space>
      ),
    },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allPayments.length > 0) {
        filteredData = allPayments.filter (payment => {
          //return payment.name && payment.name.toLowerCase().trim() === item.toLowerCase().trim();
          return (
            payment.transactionId &&
            (payment.transactionId.toLowerCase ().match (item.toLowerCase ()) ||
              (payment.email &&
                payment.email.toLowerCase ().match (item.toLowerCase ())) ||
              (payment.order &&
                payment.order.toLowerCase ().match (item.toLowerCase ())) ||
              (payment.customer &&
                payment.customer.toLowerCase ().match (item.toLowerCase ())))
          );
        });
        //   console.log("SEARCH RESULT : ",filteredData);
      }
    }

    setFilteredArr (filteredData);
  };

  const exportAllPayments = async () => {
    try {
      const result = await UserService.exportPaymentsToCsv ();
      // console.log(result);
      if (result.data) {
        message.success ('Exported All Payments To CSV file !');
      }
    } catch (err) {
      message.error (err.message ? err.message : 'Unable To Export Payments !');
    }
  };

  const confirmExport = () => {
    Modal.confirm ({
      title: 'Confirm Export',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to export all Payments Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        exportAllPayments ();
      },
    });
  };

  const confirmRecyclePayment = id => {
    Modal.confirm ({
      title: 'Confirm Recycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Recycle This Payments Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        recyclePayment (id);
      },
    });
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div><h3>All Payments</h3></div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Transaction Id,Order Name,Client name or Client Email"
            style={{
              width: '35vw',
              marginBottom: '3%',
              marginRight: '3%',
              fontSize: '0.9rem',
            }}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>
        <div>
          {currentUser.roles.includes ('ROLE_SALES')
            ? null
            : <Button type={'primary'} onClick={confirmExport}>
                <UploadOutlined /> Export All Payments
              </Button>}
        </div>
      </div>

      <Table
        dataSource={filteredArr.length > 0 ? filteredArr : allPayments}
        columns={columns}
      />
    </div>
  );
};

export default AllPayments;
