import styled from "styled-components";



export const Button  = styled.button`
display:flex;
border:0;

padding-right:0.8em;
padding-left:0.8em;
padding-top:0.5em;
padding-bottom:0.5em;
margin-top:-3%;
margin-left:12%;
font-weight:500;
box-shadow:0px 1px 4px gray;
`;