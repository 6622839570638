import React,{useState,useEffect} from 'react';
import './InvoiceMaker.css';
import {message,Button,Spin,Modal,Tabs} from 'antd';
import OrderService from '../../../services/order.service';
import {EditOutlined,ExclamationCircleOutlined, LoadingOutlined,PlusOutlined} from '@ant-design/icons';
import {history} from '../../../helpers/history';
import {useSelector} from 'react-redux';


const { TabPane } = Tabs; 

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 
const InvoiceMaker = ()=>{
    // const [allCustomers,setAllCustomers] = useState([]);
    const [customer,setCustomer] = useState("");
    const [email,setEmail] = useState("");
    const [invoiceTitle,setInvoiceTitle] = useState("");
    const [customerAddress,setCustomerAddress] = useState("");
    const [price,setPrice] = useState(0);
    const [quantity,setQuantity] = useState(1);
    const [description,setDescription] = useState("");
    const [orderName,setOrderName] = useState("");
    const [lineTotal,setLineTotal] = useState(0);
    const [orderArr,setOrderArr] = useState([]);
    const [loading,setLoading] = useState(false);
    const [tax,setTax] = useState(0);
    const [currentKey,setCurrentKey] = useState("1");
    const [totalAmount,setTotalAmount] = useState(0);
    const [priceTotal,setPriceTotal] = useState(0);
    const [quantityTotal,setQuantityTotal] = useState(0);
    const [zip,setZip] = useState("");
    const [city,setCity] = useState("");
    const [country,setCountry] = useState("");
    const [state,setState] = useState("");

    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])
 

    useEffect(()=>{
        setLineTotal(Number(price*quantity));
    },[price,quantity])



    const downloadInvoice = async ()=>{
        try{
                const response = await OrderService.downloadInvoice();
             window.history.push(response.data);
        }catch(err){
            console.log(err);
        }
    }

    const handleAddSingleOrder = ()=>{
        let orderItemsArr = orderArr;
        let data = {
            name:orderName,
            description:description,
            price:price,
            quantity:quantity,
            tax:tax
            
        }

        setTotalAmount((prevValue)=>{return(Number(lineTotal)+Number(prevValue))});
        setPriceTotal((prevValue)=>{return(Number(price)+Number(prevValue))});
        setQuantityTotal((prevValue)=>{return(Number(quantity)+Number(prevValue))});

        orderItemsArr.push(data);
        message.success(`${orderName} added to Invoice!`);
        // console.log(orderItemsArr);
        setOrderArr(orderItemsArr);
        setOrderName("");
        setQuantity(1);
        setPrice(0);
        setLineTotal(0);
        setDescription("");
       
    }


    const handleInvoiceSubmit = async ()=>{
        setLoading(true);
        let finalData = {
            invoiceTitle:invoiceTitle,
            customerName:customer,
            invoiceNumber:0,
            customerEmail:email,
            address:customerAddress,
            zip:zip,
            city:city,
            state:state,
            country:country,
            orders:orderArr
        }
        // console.log("Final Invoice Array: ",finalData);
        try{
            const response = await OrderService.createOrderInvoice(finalData);
            // console.log(response.data);
            setLoading(false);
            message.success(`Created Invoice for ${customer} Successfully!`);
            // setTimeout(confirmInvoiceDownload, 3000);
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : "Unable to create Invoice !");
        }

    }


    const confirmInvoiceDownload = ()=>{
        Modal.confirm({
          title: 'Confirm Invoice Download',
          icon: <ExclamationCircleOutlined />,
          content: 'Would you like to download the invoice ?',
          okText: 'Yes',
          cancelText: 'No',
          onOk:()=>{downloadInvoice()}
        });
      }

    return(
        <div className="invoiceMaker">
            <Tabs defaultActiveKey="1" activeKey={currentKey}>
                    <TabPane tab="Customer Details" key="1">
                    <form>
                    <table>
                        <tr>
                        <td colSpan={2}>
                        <div className="form-group">
                        <label>Invoice Title : </label>
                        <input type="email" className="form-control" value={invoiceTitle} onChange={(e)=>{setInvoiceTitle(e.target.value)}} />
                        </div>
                        </td>
                        <td colSpan={2}>
                        <div className="form-group">
                        <label>Customer Name : </label>
                        <input type="text" className="form-control" value={customer} onChange={(e)=>{setCustomer(e.target.value)}} />
                        </div>
                        </td>
                        </tr>
                        <tr>
                        <td colSpan={2}>
                        <div className="form-group">
                        <label> Customer Email : </label>
                        <input type="email" className="form-control" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                        </div>
                        </td>
                        <td colSpan={2}>
                        <div className="form-group">
                        <label> Customer Address : </label>
                        <textarea type="text"  style={{width:'35vw'}} className="form-control" value={customerAddress} onChange={(e)=>{setCustomerAddress(e.target.value)}}></textarea>
                        </div>
                        </td>
                        </tr>
                        <tr>
                            <td>
                            <div className="form-group">
                            <label> City: </label>
                            <input type="text"  style={{width:'15vw'}}  className="form-control" value={city} onChange={(e)=>{setCity(e.target.value)}}></input>
                            </div>
                           
                            </td>
                            <td>
                            <div className="form-group">
                            <label> State: </label>
                            <input type="text"  style={{width:'15vw'}}  className="form-control" value={state} onChange={(e)=>{setState(e.target.value)}}></input>
                            </div>
                           
                            </td>
                            <td>
                            <div className="form-group">
                            <label> Country: </label>
                            <input type="text"  style={{width:'15vw'}} className="form-control" value={country} onChange={(e)=>{setCountry(e.target.value)}}></input>
                            </div>
                            </td>
                            <td>
                            <div className="form-group">
                            <label> Pin / Zip Code: </label>
                            <input type="text"  style={{width:'12vw'}} className="form-control" value={zip} onChange={(e)=>{setZip(e.target.value)}}></input>
                            </div>
                            </td>
                        </tr>
                        
                        
                        </table>
                        <Button type={"primary"} onClick={()=>{setCurrentKey("2")}}>Next</Button>
                    </form>
                    </TabPane>
                    <TabPane tab="Order Details" key="2">
                    <table>
                        <tr>
                            <td>
                            <label>Order or Service</label>
                            <input type="text" placeholder="Product Name" value={orderName} onChange={(e)=>{setOrderName(e.target.value)}} className="form-control" />
                            </td>
                            <td>
                        
                            <label>Price</label>
                            <input type="number" min={0}  className="form-control" value={price} onChange={(e)=>{setPrice(e.target.value)}} placeholder="Price" />
                            </td>
                        
                        </tr>

                            <tr>
                            <td>
                            <label>Tax</label>
                            <input type="number" min={0} className="form-control" value={tax} onChange={(e)=>{setTax(e.target.value)}} placeholder="Tax" />
                            </td>
                            <td>
                            <label>Quantity</label>
                            <input type="number" min={1} className="form-control" value={quantity} onChange={(e)=>{setQuantity(e.target.value)}} placeholder="Quantity" />
                            </td>
                            </tr>
                            <tr>
                            <td>
                            <label>Description</label>
                            <textarea style={{width:'35vw'}} placeholder="Description" value={description} onChange={(e)=>{setDescription(e.target.value)}} className="form-control"></textarea>
                            </td>
                            <td>
                            <label style={{marginBottom:'-5%'}}>Order Total : </label>
                            <b className="order-total" style={{fontSize:'1.5rem',marginLeft:'4%',marginBottom:'-5%'}}>{lineTotal} USD</b>
                            </td>
                            </tr>
                            
                    
                        </table>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{display:'flex'}}>
                            <Button type={"primary"} onClick={()=>{setCurrentKey("1")}}>Prev</Button>
                            <Button type={"primary"} style={{marginLeft:'4%'}} onClick={()=>{setCurrentKey("3")}}>Next</Button>
                            </div>

                            <Button style={{marginRight:'45%'}} type={"primary"} onClick={handleAddSingleOrder}> <PlusOutlined /> Add to Invoice</Button>
                        </div>
                    </TabPane>

                    <TabPane tab="Summary" key="3">
                    
                    <table className="table summaryTable">
                        <tr>
                            <th>Order or Service</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Line Total</th>
                        </tr>
                        {orderArr.map((o)=>{
                        return(
                            <tr>
                            <td>{o.name}</td>
                            <td>{o.description}</td>
                            <td>{o.price}</td>
                            <td>{o.quantity}</td>
                            <td>{Number(o.price * o.quantity)}</td>
                            </tr>)
                        })}

                        <tr>
                            <th colSpan={2}>Subtotal</th>
                            <th>
                                {priceTotal}
                            </th>
                            <th>
                                {quantityTotal}
                            </th>
                            <th>
                                {totalAmount}
                            </th>
                        </tr>
                        <tr>
                        <th colSpan={4}>GST Tax ({tax} %)</th>
                        <th>{(totalAmount/100)*tax}</th>
                        </tr>
                        <tr>
                        <th colSpan={4} style={{fontSize:'1.2rem'}}>Total</th>
                        <th>{((totalAmount/100)*tax) + Number(totalAmount)}</th>
                        </tr>
                
                        </table>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{display:'flex'}}>
                            <Button type={"primary"} onClick={()=>{setCurrentKey("2")}}>Prev</Button>
                            <Button type={"primary"}  style={{marginLeft:'5%',borderRadius:'0px'}} onClick={handleInvoiceSubmit}> <EditOutlined /> {loading ? <Spin indicator={spinIcon} /> : 'Create Invoice'}</Button>
                            </div>
                        </div>
                    </TabPane>
            </Tabs>
        </div>

    )
}


export default InvoiceMaker