import { SET_NOTIFICATIONS }  from "../actions/types";

const initialState = {
    notifications:[],
    
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_NOTIFICATIONS:
      return { ...state,notifications: payload };

    default:
      return state;
  }
}
