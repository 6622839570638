export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const UPDATE_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

export const SETHOMEDETAILS = 'SETHOMEDETAILS';
export const HOMEPAGEERROR = 'HOMEPAGEERROR';
export const SET_MESSAGE = 'SET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';

export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const RESET_INVALID_PASSWORD = 'RESET_INVALID_PASSWORD';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const SETCOINS = 'SETCOINS';
export const GETCOINS = 'GETCOINS';

export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
