import axios from 'axios';
import authHeader from './auth-header';

// var  API_URL = "http://3.7.156.63/api/test/";
// var  API_URL = "http://3.7.156.63:3000/api/test/";
// var API_URL = 'http://localhost:3000/api/test/';

var API_URL = 'https://dashboard.codersarts.com/api/test/';

// if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/test/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70:8080/api/test/";
// }

const getPublicContent = () => {
  return axios.get (API_URL + 'all');
};

const getUserBoard = () => {
  return axios.get (API_URL + 'user', {headers: authHeader ()});
};

const getManagerBoard = () => {
  return axios.get (API_URL + 'man', {headers: authHeader ()});
};

const getEmployeeBoard = () => {
  return axios.get (API_URL + 'employee', {headers: authHeader ()});
};

const getAdminBoard = () => {
  return axios.get (API_URL + 'admin', {headers: authHeader ()});
};

const getAllUsers = () => {
  return axios.get (API_URL + 'getusers', {headers: authHeader ()});
};

const getAllRoles = () => {
  return axios.get (API_URL + 'getroles', {headers: authHeader ()});
};

const getSingleUser = username => {
  return axios.post (
    API_URL + 'singleuser',
    {username: username},
    {headers: authHeader ()}
  );
};

const getUserById = id => {
  return axios.post (
    API_URL + 'getUserById',
    {id: id},
    {headers: authHeader ()}
  );
};

const updateDetailsByAdmin = async data => {
  if (data) {
    for (var propName in data) {
      if (
        data[propName] === null ||
        data[propName] === undefined ||
        data[propName] === ''
      ) {
        delete data[propName];
      }
    }

    // console.log(data);
    return axios
      .post (API_URL + 'update', data, {headers: authHeader ()})
      .then (response => {
        // console.log(response.data)
        return response.data;
      });
  }
};

const updateRole = async (uname, roleName) => {
  let data = '';
  // console.log("ROLE NAME : ",roleName)
  if (roleName === 'employee') {
    data = {
      username: uname,
      role: roleName,
    };
  } else {
    data = {
      username: uname,
      role: roleName,
      manager: null,
    };
  }

  // console.log("FINAL DATA ",data);
  return axios
    .post (API_URL + 'updateroles', data, {headers: authHeader ()})
    .then (response => {
      // console.log(response.data)
      return response.data;
    })
    .catch (err => {
      console.log (err);
    });
};

const getAllManagers = () => {
  return axios.get (API_URL + 'getManagers', {headers: authHeader ()});
};

const getEmployeeAndManagers = () => {
  return axios.get (API_URL + 'getEmployeeAndManagers', {
    headers: authHeader (),
  });
};

const getAllEmployees = managerId => {
  // console.log(managerId);
  const data = {
    manager: managerId,
  };
  return axios.post (API_URL + 'getEmployees', data, {headers: authHeader ()});
};

const updateUserPassword = (id, password) => {
  const data = {
    id: id,
    password: password,
  };

  // console.log(data);
  return axios.post (API_URL + 'update-password', data, {
    headers: authHeader (),
  });
};

const updateProfilePic = data => {
  // console.log(data);
  return axios.post (API_URL + 'upload-profilePic', data, {
    headers: authHeader (),
  });
};

const updateHomeDetails = data => {
  // console.log("New Home Details : ",data);
  return axios.post (API_URL + 'update-home', data, {headers: authHeader ()});
};
const getHomeDetails = () => {
  return axios.get (API_URL + 'get-home-details');
};

const resetUserPassword = (id, password) => {
  // console.log("ID : ",id);
  // console.log("PASSWORD : ",password);
  return axios.post (
    API_URL + 'reset-user-password',
    {id: id, password: password},
    {headers: authHeader ()}
  );
};

const disabledUser = (username, secretKey) => {
  if (secretKey === 'codersarts') {
    // console.log("username : ",username);
    return axios.post (API_URL + 'disable-user', {username: username});
  }
};

const getIsUserDisabled = username => {
  // console.log(" user to check : ",username);
  return axios.post (API_URL + 'check-user-disabled', {username: username});
};

const exportUsersToCsv = () => {
  return axios.get (API_URL + 'exportUsersToCsv', {headers: authHeader ()});
};

const exportOrdersToCsv = () => {
  return axios.get (API_URL + 'exportOrdersToCsv', {headers: authHeader ()});
};

const exportPublicOrdersToCsv = () => {
  return axios.get (API_URL + 'exportPublicOrdersToCsv', {
    headers: authHeader (),
  });
};

const exportPaymentsToCsv = () => {
  return axios.get (API_URL + 'exportPaymentsToCsv', {headers: authHeader ()});
};

const exportPublicPaymentsToCsv = () => {
  return axios.get (API_URL + 'exportPublicPaymentsToCsv', {
    headers: authHeader (),
  });
};

const listAllExportedData = () => {
  return axios.get (API_URL + 'listAllExportedFiles', {headers: authHeader ()});
};

const getAdminAndSalesUsers = () => {
  return axios.get (API_URL + 'getAdminAndSalesUsers', {
    headers: authHeader (),
  });
};

const createNewNotification = data => {
  return axios.post (API_URL + 'create-notification', data, {
    headers: authHeader (),
  });
};

const getSpecificNotification = userId => {
  return axios.post (
    API_URL + 'get-notification-for-user',
    {userId: userId},
    {headers: authHeader ()}
  );
};

const deleteNotificationById = id => {
  return axios.post (
    API_URL + 'delete-notification-by-id',
    {id: id},
    {headers: authHeader ()}
  );
};

const deleteAllNotificationForUser = userId => {
  return axios.post (
    API_URL + 'deleteAllNotifications',
    {userId: userId},
    {headers: authHeader ()}
  );
};

const markNotificationAsRead = id => {
  return axios.post (
    API_URL + 'mark-as-read',
    {id: id, data: {isRead: true}},
    {headers: authHeader ()}
  );
};

const addNewSkillToList = name => {
  return axios.post (
    API_URL + 'add-skills-to-list',
    {name: name},
    {headers: authHeader ()}
  );
};

const updateSkillsCategory = data => {
  return axios.post (API_URL + 'update-skills-category', data, {
    headers: authHeader (),
  });
};

const deleteSkillCategory = id => {
  return axios.post (
    API_URL + 'delete-skills-category',
    {id: id},
    {
      headers: authHeader (),
    }
  );
};

const getAllSkillsList = () => {
  return axios.get (API_URL + 'get-skills-list', {headers: authHeader ()});
};

const getUserSkillsById = id => {
  return axios.post (
    API_URL + 'get-skills-by-id',
    {id: id},
    {headers: authHeader ()}
  );
};

const getUserInterestById = id => {
  return axios.post (
    API_URL + 'get-interest-by-id',
    {id: id},
    {headers: authHeader ()}
  );
};

const makeNewDeveloperRequest = data => {
  return axios.post (API_URL + 'make-new-developer-request', data, {
    headers: authHeader (),
  });
};

const getAllDevRequest = () => {
  return axios.get (API_URL + 'get-all-developer-request', {
    headers: authHeader (),
  });
};

const getSingleDevRequest = id => {
  return axios.post (
    API_URL + 'get-single-developer-request',
    {id: id},
    {headers: authHeader ()}
  );
};

const getOTPForPasswordResetFromEmail = email => {
  return axios.post (
    API_URL + 'get-otp-from-email',
    {email: email},
    {headers: authHeader ()}
  );
};

const passwordResetAfterOtp = data => {
  return axios.post (API_URL + 'reset-password-after-otp', data, {
    headers: authHeader (),
  });
};

const createEmployeeResume = data => {
  return axios.post (API_URL + 'create-employee-resume', data, {
    headers: authHeader (),
  });
};

const updateEmployeeResume = data => {
  return axios.post (API_URL + 'update-employee-resume', data, {
    headers: authHeader (),
  });
};

const getEmployeeResume = data => {
  return axios.post (API_URL + 'get-employee-resume', data, {
    headers: authHeader (),
  });
};

const deleteEmployeeResume = data => {
  return axios.post (API_URL + 'delete-employee-resume', data, {
    headers: authHeader (),
  });
};

export default {
  getPublicContent,
  getUserBoard,
  updateUserPassword,
  getManagerBoard,
  getAdminBoard,
  getEmployeeBoard,
  getAllUsers,
  getSingleUser,
  updateDetailsByAdmin,
  getAllRoles,
  updateRole,
  getAllManagers,
  getAllEmployees,
  getUserById,
  updateHomeDetails,
  getHomeDetails,
  resetUserPassword,
  updateProfilePic,
  disabledUser,
  getIsUserDisabled,
  exportUsersToCsv,
  exportOrdersToCsv,
  exportPaymentsToCsv,
  exportPublicPaymentsToCsv,
  exportPublicOrdersToCsv,
  listAllExportedData,
  getAdminAndSalesUsers,
  createNewNotification,
  getSpecificNotification,
  deleteNotificationById,
  deleteAllNotificationForUser,
  markNotificationAsRead,
  addNewSkillToList,
  getAllSkillsList,
  getUserSkillsById,
  getUserInterestById,
  makeNewDeveloperRequest,
  getAllDevRequest,
  getSingleDevRequest,
  getEmployeeAndManagers,
  getOTPForPasswordResetFromEmail,
  passwordResetAfterOtp,
  updateSkillsCategory,
  deleteSkillCategory,
  createEmployeeResume,
  updateEmployeeResume,
  deleteEmployeeResume,
  getEmployeeResume,
};
