import React,{useEffect,useState} from 'react';
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js'
import paymentService from '../../../../../../services/payment.service';
import CardSection from './CardSection/CardSection';
import { Modal,Spin,message, Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import orderService from '../../../../../../services/order.service';
import GoogleServices from '../../../../../../services/google.services';
import { history } from '../../../../../../helpers/history';
import {updateUserCoins,getUserCoins} from '../../../../../../actions/coinsAction';
// import { useLocation } from 'react-router';




const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;


function StripeForm({amount,order,qty,isPartial,coins,orderAmount}) {
    const elements  = useElements();
    const stripe = useStripe();
    const [succeeded, setSucceeded] = useState(false);
    const [loading, setLoading] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [address,setAddress] = useState("");
    const [country,setCountry] = useState("");
    const [city,setCity] = useState("");
    const [state,setState] = useState("");
    const [pinCode,setPinCode] = useState(null);
    const [clientSecret, setClientSecret] = useState('');
    const { user: currentUser } = useSelector((state) => state.auth);
    const {coinsDetails} = useSelector (state => state.coinReducer);
    const dispatch = useDispatch();
    // const location = useLocation();
    // console.log("location : ",location);

    // console.log("order in checkout : ",order);

    // const getCountriesList = async ()=>{
    //  try{
    //   const response = await paymentService.getAllCountries();
    //   console.log(response.data);
      
    //  }catch(err){
    //    console.log(err);
    //    message.error(err);
    //  }
    // }

    // useEffect(()=>{
    //   getCountriesList();
    // },[])
   
  

    const getPaymentIntent = async ()=>{
      setLoading(true);
      let intentData = {
        userId:currentUser.id,
        amount:amount,
        name:currentUser.name,
        address:address,
        email:currentUser.email,
        country:country,
        city:city,
        state:state,
        pinCode:pinCode,
        order:order,
        coins:coins,
        currency:order.currency ? order.currency : null
      }
        try{
            const result = await paymentService.createPaymentIntent(intentData);
            // console.log(result.data.clientSecret);
            handleSubmit(result.data.clientSecret,result.data.account);
            setClientSecret(result.data.clientSecret);

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went Wrong!');
            setLoading(false);
        }
    }

    // useEffect(()=>{
    //     getPaymentIntent();
    //     console.log(stripe);
    // },[amount,address,country,state,city,state,pinCode])


    const  sendPaymentSuccessMail = async (transactionId)=>{
      try{
        const data ={
          To:currentUser.email,
          Subject:`${order.name} Payment Sucessful!`,
          Write:`Your Payment of <h4>${amount} USD </h4> has 
          been Received Successfully with the transaction Id 
          <h4> ${transactionId}</h4> <br /> Your order details are shown below : <br /> 
          Order Name : <b>${order.name}</b><br />
          Order Amount:<b>${orderAmount}</b><br />
          Deadline:<b>${order.deadline}</b><br />
          Quantity:<b>${qty}</b><br />
          Thank You For Choosing Codersarts!`,
          attachment:false,
  
      }
      const response = await GoogleServices.sendmail(data);
      if(response){
        message.success("Successfully Sent the Mail !");
      }

      }catch(err){
        message.error(err.message ? err.message : "Couldn't Send You Mail!")
      }
  
    }


    const handleSubmit = async (clientSecret)=>{
      let response = null;
      let transactionId = null;
      if(clientSecret ===''){
        getPaymentIntent();
        setLoading(false);
        return;
      }
      
        setLoading(true);
        setDisabled(true);
        if(!elements || !stripe){
            return;
        }
        let finalData = {
            fName:currentUser.name,
            email:currentUser.email,
            address:address,
            city:city,
            state:state,
            country:country,
            pinCode:pinCode,
            order:order,
            user:currentUser.id,
            qty:qty,
            orderAmount:orderAmount,
            isPartial:isPartial,
            paymentIntent:null,
            paymentType:'Stripe',
            amount:null,
            remainingAmount: isPartial && (Number(order.totalAmount)-Number(order.paidAmount))-Number(order.partialAmount),
            discountApplied:coins ? coins : 0
          }
        // console.log(amount);
        // console.log(finalData);
        // console.log("clientSecret : ",clientSecret)
        //creating intent on server
        try{
           if(!clientSecret){
             setLoading(false);
               return;
           }
           
            const result =  await stripe.confirmCardPayment(
                clientSecret,{
                payment_method:{
                  card: elements.getElement(CardElement),
                    billing_details: {
                        name: currentUser.name,
                        address: {
                          line1: address ? address : 'test',
                          postal_code: pinCode ? pinCode : '111111',
                          city: city ? city : 'test',
                          state: state ? state : 'test',
                          country: country ? country : 'test',
                        }
                      },
                    
                }}
            )
            if(result){
                setLoading(false);
                setDisabled(false);
            }
            const {paymentIntent} = result;
            const {error} = result;
        
            // console.log("RESULT  AFTER PAYMENT : ",result);
            // console.log("PAYMENT INTENT : ",paymentIntent);
            if(error){
              message.error(error.message);
            }
            if(paymentIntent && paymentIntent.status==='succeeded'){
                // console.log("success!");
                setSucceeded(true);
                successModal();
                finalData.paymentIntent = paymentIntent;
                finalData.user= currentUser.id;
                transactionId = paymentIntent.id;
                finalData.amount = paymentIntent.amount/100;

                response = await orderService.setOrderPayment(finalData);
                let coinsResponse = await orderService.updateCoinsAfterDeliver({userId:currentUser.id,coins:coins});
                  // console.log(coinsResponse);
                  if(coinsResponse){
                   
                      dispatch(getUserCoins({userId:currentUser.id}));
                    
                  }
               
               
            }
        }catch(err){
            setLoading(false);
            errorModal();
            setDisabled(false);
            console.log(err);
        }

        if(response){
          sendPaymentSuccessMail(transactionId);
          // console.log("After minus : ",coinsDetails.totalCoins - coins);
 
        
       
        
         
         
        }


    }


    function successModal() {
        let secondsToGo = 5;
        const modal = Modal.success({
          title: 'Payment is Successful !',
          content: `You will be Redirected to Orders page in ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: `You will be Redirected to Orders page in  ${secondsToGo} second.`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
         
          history.push('/profile/user-orders');
        }, secondsToGo * 1000);
      }

      function errorModal() {
        let secondsToGo = 5;
        const modal = Modal.error({
          title: 'Something Went Wrong !',
          content: `Please Try Again in ${secondsToGo} second.`,
        });
        const timer = setInterval(() => {
          secondsToGo -= 1;
          modal.update({
            content: `Please Try Again in  ${secondsToGo} second.`,
          });
        }, 1000);
        setTimeout(() => {
          clearInterval(timer);
          modal.destroy();
        }, secondsToGo * 1000);
      }

    
    return (
        <div style={{display:'flex'}}>
          <div className="pay-color-panel">
            <h5 style={{marginLeft:'0%',marginBottom:'2%'}}>Payment for Codersarts Services</h5>
            <hr style={{color:'white',height:'5px'}}/>
              <div style={{color:'white',marginTop:'4%'}}>
              <h6 style={{color:'white'}}>Contact Us:</h6>
              <hr style={{color:'white',width:'100px',height:'3px'}} />
              <div>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span style={{marginLeft:'3%'}}>contact@codersarts.com</span>
              </div>
              <div>
                <i class="fa fa-phone" aria-hidden="true"></i>
                <span style={{marginLeft:'3%'}}>7292007989</span>
              </div>
              </div>
               <div style={{color:'white',marginTop:'4%'}}>
              <h6 style={{color:'white'}}>Terms And Condition</h6>
              <hr style={{color:'white',width:'170px',height:'3px'}} />
              Codersarts is product for Sofstack Solution Pvt Ltd. Read more:
              <a style={{marginLeft:'3%'}} href="https://www.codersarts.com/terms-of-service" target="_blank">
              https://www.codersarts.com/terms-of-service
              </a>
              </div>
              
          </div>
          <div>
          {order && amount && <form>
            <h4><b>Outside India Clients</b></h4>
            <hr style={{height:'5px',width:'20vw',marginBottom:'8%'}} />
             
                <>
                    <div className="form-group ">
                      <CardSection />
                      </div>
                     

                <div className="form-group">
                <div>
                <label className="mt-2"><b>Billing Details</b></label>
                <textarea type="text" 
                placeholder="Address" 
                className="form-control m-2" value={address} onChange={e=>setAddress(e.target.value)}
                style={{height:'6rem',width:'30vw',resize:'none'}}/>
              <div className="d-flex">
               <div >
                <input type="text" style={{width:'14.4vw'}} value={city} onChange={e=>setCity(e.target.value)} className="form-control m-2" placeholder="City" />
                <input type="text" style={{width:'14.4vw'}}  value={state} onChange={e=>setState(e.target.value)} className="form-control m-2" placeholder="State" />
               </div>
                <div>
                <input type="text" style={{width:'14.4vw'}} maxLength={10} value={pinCode} onChange={e=>setPinCode(e.target.value)} className="form-control m-2" placeholder="Postal Code" />
                <Tooltip title="Enter 2 ISO Characters for Countries">
                <input type="text" style={{width:'14.4vw'}} value={country} onChange={e=>setCountry(e.target.value)} className="form-control m-2" placeholder="US,AU,GB" />
                </Tooltip>
                {country && (country.toLowerCase()==='in' || country.toLowerCase()==="india") && message.info("Indian Clients Please Use Inside India Payment Method!")}
                </div>
              </div>

                </div>
                <a href="https://www.nationsonline.org/oneworld/country_code_list.htm" target="_blank">Need More Country Codes ? </a>

               
               </div>
               <div className="form-group d-flex" >
                  <button type="button" onClick={getPaymentIntent} disabled={!stripe || disabled || address==="" || city === "" || state==="" || country=="" || pinCode==="" || (country.toLowerCase()==='in' || country.toLowerCase()==="india")} style={{marginLeft:'2%',width:'100%'}} className="pay-btn">
                  {loading ? <Spin indicator={spinIcon} /> : 'Pay'}
                  </button>
                </div>
                  
                </>
        
         
              
               
           </form>}
          </div>
        </div>
    )
}

export default StripeForm
