import React,{useState,useEffect} from 'react';
import orderService from '../../../services/order.service';
import paymentService from '../../../services/payment.service';
import GoogleServices from '../../../services/google.services';
import {Card,Button,Modal,Tooltip,message} from 'antd';
import './UserSubscription.css';
import check from './checkIcon.png';
import {CardElement,useElements,useStripe} from '@stripe/react-stripe-js';
import {history} from '../../../helpers/history';
import {useSelector} from 'react-redux';


const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        marginTop:'2%',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };



function UserSubscription() {
    const [allSubscriptionCategories,setAllSubscriptionCategories] = useState("");
    const [paymentModal,setPaymentModal] = useState(false);
    const [loading,setLoading] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [address,setAddress] = useState("");
    const [city,setCity] = useState("");
    const [state,setState] = useState("");
    const [country,setCountry] = useState("");
    const [currentItem,setCurrentItem] = useState("");
    const [pinCode,setPinCode] = useState("");
    const [amount,setAmount] = useState("");
    

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_USER')){
            history.push('/profile');
        }
        if((currentUser.subscriptionType[0] && currentUser.subscriptionType[0].name)){
            history.push('/profile');
        }
    },[currentUser])

    const elements  = useElements();
    const stripe = useStripe();


    const getPaymentIntent = async ()=>{
        setLoading(true);
        let intentData = {
          amount:amount,
          name:currentUser.name ? currentUser.name : currentUser.username,
          address:address,
          email:currentUser.email && currentUser.email,
          country:country,
          city:city,
          state:state,
          pinCode:pinCode,
        }
          try{
              const result = await paymentService.createPublicPaymentIntent(intentData);
            //   console.log("CLIENT SECRET : ",result.data.clientSecret);
              handlePayment(result.data.clientSecret);
          }catch(err){
              console.log(err);
              setLoading(false);
              message.error(err.message ? err.message : "Something went Wrong !");
          }
      }


      const  sendPaymentSuccessMail = async (transactionId)=>{
        try{
          const data ={
            To:currentUser.email,
            Subject:"Payment Sucessful to Codersarts",
            Write:`Your Payment of <b>${amount} USD </b> 
            has been Received Successfully with the transaction Id 
            <b> ${transactionId}</b> <br /> Thank You For Choosing Codersarts! <br /> 
            You are now a <b>${currentItem.name}</b> Member. All Features will be activated on your next login!`,
            attachment:false,
    
        }
        const response = await GoogleServices.sendmail(data);
        if(response){
          message.success("Successfully Sent the Mail !");
        }
  
        }catch(err){
            console.log(err);
          message.error(err.message ? err.message : "Couldn't Send You Mail!")
        }
    
      }


      const handlePayment = async (clientSecret)=>{
        //   console.log("client secret in handle payment : ",clientSecret);
          let transactionId = null;
        let response = null;
          setLoading(true);
  
          let finalData = {
             user:currentUser.id,
             country:country,
             state:state,
             subscription:currentItem._id,
             city:city,
             address:address,
             pinCode:pinCode,
             amount:amount,
             paymentIntent:null,
             currency:"USD"
          }
  
  
          try{
              if(!clientSecret){
                  return;
              }
               const result =  await stripe.confirmCardPayment(
                   clientSecret,{
                   payment_method:{
                     card: elements.getElement(CardElement),
                       billing_details: {
                           name: currentUser.name ? currentUser.name: currentUser.username,
                           address: {
                            line1: address ? address : 'N/A',
                            postal_code: pinCode ? pinCode : 'N/A',
                            city: city ? city : 'N/A',
                            state: state ? state : 'N?A',
                            country: country ? country : 'N/A',
                          }
                         },
                   }}
               )
               if(result){
                   setLoading(false);
               }
               const {paymentIntent} = result;
               const {error} = result;
               if(error){
                 message.error(error.message);
                 console.log("Error : ",error);
               }
           
              //  console.log("RESULT  AFTER PAYMENT : ",result);
              //  console.log("PAYMENT INTENT : ",paymentIntent);
               if(paymentIntent && paymentIntent.status==='succeeded'){
                  //  console.log("success!");
                   message.success("Payment Successful!");
                   finalData.paymentIntent = paymentIntent;
                   transactionId = paymentIntent.id;
                    response = await orderService.createSubscriptionRecord(finalData);
                //    console.log(response.data);
                   setLoading(false);
                   handleSubscriptionClose();
                
               }
           }catch(err){
               setLoading(false);
               console.log(err);
               message.error(err.message ? err.message : 'Something went wrong!');
              
           }
  
           if(response){
            sendPaymentSuccessMail(transactionId);
           }
  
      }


    
    const getAllSubscriptionCategories = async ()=>{
        try{
            const response = await orderService.getAllSubscriptionCategories();
            // console.log("All Subsc : ",response.data);
            setAllSubscriptionCategories(response.data);
            
        }catch(err){
            console.log(err.message ? err.message : 'Something Went Wrong!');
        }
    }
    useEffect(()=>{
            getAllSubscriptionCategories();
    },[]);

    const  handleSubscriptionSelect = (item)=>{
        setCurrentItem(item);
        var str = item.price;
        var res = str.replace(/\D/g, "");
        // console.log("Amount : ",res);
        setAmount(res);
        setPaymentModal(true);
    }

    const handleSubscriptionSubmit = ()=>{
        getPaymentIntent();
    }

    const handleSubscriptionClose = ()=>{
        setCity("");
        setCountry("");
        setState("");
        setAmount("");
        setCurrentItem("");
        setPinCode("");
        setAddress("");
        
        setPaymentModal(false);
    }
    return (
        <div className="userSubscription">
        <div className='subs-header'>
            <center><h1>Pick The Best Plan For You</h1></center>
        </div>
            <div className="subsCardGroup">
             {allSubscriptionCategories && allSubscriptionCategories.length > 0 && allSubscriptionCategories.map((item)=>{
                    return(
                        <Card className='subsCard' >
                            <div className="subsCard-header">
                                <h5>{item.name}</h5>
                            </div>
                            <div className='subsCard-price'>
                                <h6>{item.price}</h6>
                                <div className="perMonth">Per Month</div>
                            </div>
                            <div className='subsCard-btn-area'>
                                <Button type={'primary'} onClick={()=>{handleSubscriptionSelect(item)}} className='subsCard-btn'>Select</Button>
                            </div>
                            <div className='subsCard-details'>
                                <div className='card-list-details'>
                                {item.details.split('\n').map((line)=>{
                                    return(<div className='singleList'><div><img src={check} alt="" className="checkIcon"/></div> <div className='listData'>{line}</div></div>)
                                })}
                                </div>
                            </div>
                        </Card>
                    )
                })}


                <Modal 
                    title={"Enter Card Details"}
                    visible={paymentModal} 
                    onOk={handleSubscriptionSubmit} 
                    onCancel={handleSubscriptionClose}
                    confirmLoading={loading}
                >
                    <form className="subscription-payment-form">
                    <div className="form-group">
                    <label><h6>Enter Card Details:</h6></label>
                    <div>
                    <CardElement options={CARD_ELEMENT_OPTIONS}/>
                    </div>
                    </div>

                    <div className="form-group">
                    <label><h6>Enter Billing Details:</h6></label>
                    <div>
                        <textarea  
                        className="form-control"
                        value={address} 
                        placeholder='Enter Address Here...' 
                        onChange={(e)=>{setAddress(e.target.value)}}></textarea>
                    </div>

                    </div>


                   


                    <div className="subscription-input-group">
                        <input  
                        className="form-control"
                        value={city} 
                        placeholder='Enter City Here...' 
                        onChange={(e)=>{setCity(e.target.value)}} />

                        <input  
                        className="form-control"
                        value={state} 
                        placeholder='Enter State Here...' 
                        onChange={(e)=>{setState(e.target.value)}} />

                    </div>

                    <div className="subscription-input-group">
                        {/* <input  
                        className="form-control"
                        value={country} 
                        placeholder='Enter Country Here...' 
                        onChange={(e)=>{setCountry(e.target.value)}} /> */}
                        <Tooltip title="Enter 2 ISO Characters for Countries">
                        <input type="text" value={country} onChange={e=>setCountry(e.target.value)} className="form-control" placeholder="Country Code Like US,AU,GB" />
                        </Tooltip>
                        {country && (country.toLowerCase()==='in' || country.toLowerCase()==="india") && message.info("Indian Payments Not Supported Please Contact Administrator!")}

                        <input  
                        className="form-control"
                        value={pinCode} 
                        placeholder='Enter Pin/Zip Code Here...' 
                        onChange={(e)=>{setPinCode(e.target.value)}} />
                    </div>
                    <div>
                    <a href="https://www.nationsonline.org/oneworld/country_code_list.htm" id="country-code-link" target="_blank">Need More Country Codes ? </a>
                    </div>
                    </form>
                </Modal>
                </div>
        </div>
    )
}

export default UserSubscription
