import {Input, message, Spin, Tag} from 'antd';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import EventsServices from '../../services/events.services';
import styles from './RegisterEvent.module.css';
import CountryPhoneInput, {ConfigProvider} from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

function RegisterEventForm () {
  const {id} = useParams ();
  const [phone, setPhone] = useState ('');
  const [event, setEvent] = useState ('');
  const [email, setEmail] = useState ('');
  const [name, setName] = useState ('');
  const [loading,setLoading] = useState(false);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect(()=>{
    if(currentUser){
      setEmail(currentUser.email);
      setName(currentUser.name);
    }
  },[currentUser])
  useEffect (
    () => {
      let isMounted = true;

      const getUpcomingSingleEvents = async () => {
        try {
          const response = await EventsServices.getUpcomingSingleEvents (id);
          // console.log (response.data);
          setEvent (response.data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      if (isMounted) {
        getUpcomingSingleEvents ();
      }
      return () => {
        isMounted = false;
      };
    },
    [id]
  );

  const handleSubmit = async ()=>{
    setLoading(true)
    if(currentUser){
      let data = {
        id:id,
        attendee:currentUser.id
      }
      try{
        const response = await EventsServices.updateAttendee(data);
        console.log(response.data);
        if(response.data){
          setLoading(false);
          message.success("You are registered for the event see your dashboard for updates!");
        }
      }catch(error){
        console.log(error);
        setLoading(false);
        const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        setLoading(false);
        message.error(msg);
      }
      return;
    }
    const data = {
      name:name,
      email:email,
      contact:phone?.code + phone?.phone,
      password:phone?.phone,
      eventId:id
    }
   
    console.log(data);
    try{
      const response = await EventsServices.registerUserInEvent(data);
      console.log(response.data);
      if(response.data){
        setLoading(false);
        message.success('You are registered at Codersarts. Please Login to your dashboard!');
      }
    }catch(error){
      console.log(error);
      const msg =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();
      setLoading(false);
      message.error(msg);
    }
   
  }

  return (
    <div className={styles.RegisterEventForm}>
      <div
        className={styles.registerFormBg}
        style={{backgroundImage: `url('${event.bannerImg}')`}}
      >
        <form className={styles.eventForm}>
          <h3>Register Here</h3>
          <div className={styles.eventForm__item}>

            <Input
              placeholder="Name e.g. John Doe"
              value={name}
              onChange={e => setName (e.target.value)}
            />
          </div>
          <div className={styles.eventForm__item}>

            <Input
              placeholder="Email e.g. john@codersarts.com"
              value={email}
              onChange={e => setEmail (e.target.value)}
            />
          </div>
          <div className={styles.eventForm__item}>

            <ConfigProvider locale={en}>
              <CountryPhoneInput
                onChange={v => {
                  setPhone (v);
                }}
                defaultValue={{short: 'IN'}}
                className={styles.eventForm__input}
              />
            </ConfigProvider>
          </div>
        
          <button
            type="button"
            className="primaryBtn"
            onClick={handleSubmit}
            disabled={!email || !name || !phone || loading}
            style={{padding: '20px 45px', width: '100%', margin: '0'}}
          >
             {loading ? <Spin indicator={spinIcon} /> : 'Register'}
          </button>
          <div className={styles.eventForm__itemLink}>
            {currentUser ? null : <h4>Already Registered?<Link to="/login">Login</Link></h4>  }
          </div>
        </form>
      </div>
      <div className={styles.eventDetails}>
        <h2>{event.title}</h2>
        <div className={styles.eventDetailsPara}>{event.description}</div>
        <div className={styles.eventDetailsPara} style={{fontSize: '1.2rem'}}>
          <span>
            {' '}
            <b>Date: </b>
            <Tag color="#623DBE" style={{fontSize: '1rem', padding: '10px'}}>
              {event.eventDate}
            </Tag>
          </span>
          <span>
            {' '}
            <b>Venue: </b>
            <Tag style={{fontSize: '1rem', padding: '10px'}}>Online</Tag>
          </span>
        </div>

      </div>

    </div>
  );
}

export default RegisterEventForm;
