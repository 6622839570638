
import React,{useEffect,useState} from 'react';
import { useSelector } from 'react-redux';
import OrderService from '../../../services/order.service';
import Rating from '../../Rating/Rating'
import { useParams } from 'react-router-dom';
import { Breadcrumb,message } from 'antd';
import { Link } from 'react-router-dom';
import Message from '../../Message/Message';

import './Feedback.css'






const FeedbackOrders = ()=>{
    const [successMessage,setSuccessMessage] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    let { id} = useParams();
   
   
    const[communFeed,setcommunFeed]=useState(3);
    const [ServiceFeed,setServiceFeed]=useState(3);
    const [recommendFeed,setrecommendFeed] = useState(3);
   
   
    const [experience,setexperience]=useState("");
   
   

    const SendFeedBack = async ()=>{
        // console.log(currentUser)
        
        const data ={
           
            "communFeed":communFeed,
            "serviceFeed":ServiceFeed,
            "recommendFeed":recommendFeed,
            "experience":experience,
            "orderid":id,
            "userid":currentUser.id
        }

        try{
            const response = await  OrderService.OrderFeedback(data)
            // console.log(response.message) 
            setSuccessMessage(true);
            setexperience("")
            setcommunFeed(3)
            setrecommendFeed(3)
            setServiceFeed(3)
        }catch(err){
            console.log("error",err);
            message.error(err.message ? err.message : 'Unable to post feedback!');
        }
       
      

        // let Newage = Number(age);
        // e.preventDefault();
        // const data = {
        //     "username":userName,
        //     "email":email,
        //     "password":password,
        //     "roles":[role.toLowerCase()],
        //     "name":name,
        //     "age":Newage,
        //     "address":address,
        //     "gender":gender.toLowerCase(),
        //     "contact":contact,
        // }
        // AuthService.register(data).then((response)=>{
        //     console.log(response.data);
        //     setSuccessMessage(true);
            
        // }).catch((err)=>{
        //     console.log(err);
        // })
    }

    useEffect(()=>{
        if(successMessage){
            setSuccessMessage(false);
        }
    })

 
    return(
        <>
        <div>
            <Breadcrumb>
            <Breadcrumb.Item> <Link to="/profile/user-orders">My Orders</Link></Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/user-feeback/${id}`}>Feedback</Link></Breadcrumb.Item>
            </Breadcrumb>
        </div>
        <br />
        <div className="feedback">
            
            <div>
                <h3>Public Feedback</h3>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',width:"85%"}}>
                <p style={{fontSize:'large',fontWeight:400}}>Share Your experience with Coderstarts, to Help them make better decisions.</p>
                <p style={{marginRight:'4em',color:'grey',fontSize:'medium'}}>{new Date().toLocaleDateString()+""}</p>
                </div>
                <hr  color="black" width="70%"/>
                <span id="orderId">ORDER ID : {id}</span>
                
            </div>
            
            <div className="review">
                <div className="review-box"> 
                    <div>
                        <h4>Comunication with CoderStarts</h4>
                        <p>How responsive was coderstarts during process?</p>
                    </div>
                    <div className="rating-background"> <Rating communication={setcommunFeed}/></div>
                  
                   
                </div>
                <div className="review-box"> 
                    <div>
                        <h4>Service as Described</h4>
                        <p>Did the result match with your description?</p>
                    </div>
                    <div className="rating-background"> <Rating communication={setServiceFeed}/></div>
                  
                   
                </div>
                <div className="review-box"> 
                    <div>
                        <h4>Buy again or recommend</h4>
                        <p>Would you recommend buying this service?</p>
                    </div>
                    <div className="rating-background"> <Rating communication={setrecommendFeed}/></div>
                   
                   
                </div>
                <div style={{marginTop:'2em'}}>
                    <h4>What was it like assisting with coderStarts?</h4>
                    <textarea value={experience}  className=" feedback-area form-control"  onChange={e=>setexperience(e.target.value)}  placeholder="How can we do better next time ? Max Character 200" required />
                </div>
                <hr  color="black" width="70%"/>
                <button className="btn btn-outline-primary" onClick={()=>SendFeedBack()} >Send Feedback</button>

            </div>
        </div>
        {successMessage ? <Message type="success" message=" Feedback Submitted Successfully" /> : null}
        </>
    )
}


export default FeedbackOrders;