import { message } from 'antd';
import React,{useState,useEffect} from 'react'
import orderService from '../../services/order.service';
import './SinglePaymentDetails.css';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';



function SinglePaymentDetails({match}) {
    const [paymentDetails,setPaymentDetails] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            if(currentUser.roles.includes('ROLE_USER')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getSinglePaymentDetails = async ()=>{
        try{

            const response = await orderService.getSinglePaymentDetails(match.params.id);
            // console.log(response.data);
            setPaymentDetails(response.data);

        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(() => {
        // console.log(match);
        getSinglePaymentDetails();
    }, [])
    return (
        <div className="single-payment-details">
        <h2><b>{paymentDetails && paymentDetails.order.name} Details {paymentDetails && <span className="successTag">{ paymentDetails.paymentIntent ? paymentDetails.paymentIntent.status : paymentDetails.status ? paymentDetails.status : 'Success'}</span>} </b></h2>
        <hr />
       {
           paymentDetails &&
           <>
            <div className="card">
               <div className="card border-0">
                    <div className="card-header">
                        <h4>Order Details</h4>
                    </div>

                    <div className="card-body">

                    <table>
                        <tr>
                            <th>Title</th>
                            <td>{paymentDetails.order && paymentDetails.order.name}</td>
                        </tr>
                        <tr>
                            <th>Category</th>
                            <td>{paymentDetails.order && paymentDetails.order.category}</td>
                        </tr>
                        <tr>
                            <th>Order Quantity</th>
                            <td>{paymentDetails.qty && paymentDetails.qty}</td>
                        </tr>
                        <tr>
                            <th>Price</th>
                            <td>{paymentDetails.order.totalAmount && paymentDetails.order.totalAmount}</td>
                        </tr>

                        <tr>
                            <th>Deadline</th>
                            <td>{paymentDetails.order.deadline && paymentDetails.order.deadline}</td>
                        </tr>
                        

                    </table>
                    
                    {/* <div>
                        <div className="mr-1 item-container">
                       <div>
                        <b>Title : </b> 
                        </div>
                        <div>{paymentDetails.order && paymentDetails.order.name}</div>
                       </div>
                        <div className="mr-1 item-container" style={{marginLeft:'5%'}}>
                            <div>
                            <b>Category : </b> 
                            </div>
                            <div>{paymentDetails.order && paymentDetails.order.category}</div>
                        </div>
                        <div className="mr-1 item-container" style={{marginLeft:'5%'}}>
                            <div>
                            <b>Order Quantity : </b> 
                            </div>
                            <div>{paymentDetails.qty && paymentDetails.qty}</div>
                        </div>
                        <div className="mr-1 item-container" style={{marginLeft:'5%'}}>
                            <div>
                            <b>Price : </b> 
                            </div>
                            <div>{paymentDetails.order.totalAmount && paymentDetails.order.totalAmount}</div>
                        </div>

                        <div className="mr-1 item-container" style={{marginLeft:'5%'}}>
                            <div>
                            <b>Deadline : </b> 
                            </div>
                            <div>{paymentDetails.order.deadline && paymentDetails.order.deadline}</div>
                        </div>



                    </div> */}

                    
                    </div>
               </div>
    
               

            </div>

            <div className="card mt-3 border-0">
                    <div className="card-header">
                        <h4>Payment Details</h4>
                    </div>

                    <div className="card-body">


                    <table>
                        <tr>
                            <th>Payment Id</th>
                            <td>{paymentDetails._id && paymentDetails._id}</td>
                        </tr>

                        <tr>
                            <th>Paid Amount</th>
                            <td>{paymentDetails.amount ? paymentDetails.amount : paymentDetails.paymentIntent ? Number(paymentDetails.paymentIntent.amount)/100:'n/a'}</td>
                        </tr>
                        <tr>
                            <th>Remaining Amount</th>
                            <td>{paymentDetails.discountApplied ? (Number(paymentDetails.order.totalAmount) - (paymentDetails.discountApplied/100)) - paymentDetails.amount  : Number(paymentDetails.order.totalAmount)-paymentDetails.amount}</td>
                        </tr>
                        <tr>
                            <th>Discount Applied</th>
                            <td>{paymentDetails.discountApplied && paymentDetails.discountApplied/100}</td>
                        </tr>

                        <tr>
                            <th>Payment Method</th>
                            <td>{paymentDetails.paymentMethod ? paymentDetails.paymentMethod.toUpperCase():paymentDetails.paymentIntent ? paymentDetails.paymentIntent.payment_method_types[0] ? paymentDetails.paymentIntent.payment_method_types[0].toUpperCase() : "N/A" : "N/A"}</td>
                        </tr>

                        <tr>
                            <th>Currency</th> 
                            <td>{paymentDetails.currency ? paymentDetails.currency : paymentDetails.paymentIntent && paymentDetails.paymentIntent.currency }</td>
                        </tr>

                        <tr>
                            <th>Payment Through</th> 
                            <td>
                            {paymentDetails.paymentType ? paymentDetails.paymentType : paymentDetails.paymentIntent && paymentDetails.paymentIntent.payment_method_type ? paymentDetails.paymentIntent.payment_method_type[0] : paymentDetails.paymentMethod }
                            </td>
                        </tr>


                    </table>
                    {/* <div className="paymentDetails-flex">
                        <div className="m-2 item-container">
                       <span>
                        <b>Payment Id : </b> 
                        </span><div>{paymentDetails._id && paymentDetails._id}</div>
                       </div>

                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Paid Amount : </b> 
                            </span><div>{paymentDetails.amount ? paymentDetails.amount : paymentDetails.paymentIntent ? Number(paymentDetails.paymentIntent.amount)/100:'n/a'}</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Remaining Amount : </b> 
                            </span><div>{paymentDetails.remainingAmount !='false' ? paymentDetails.remainingAmount : 
                            Number(paymentDetails.order.totalAmount) - Number(paymentDetails.order.paidAmount) < 0?0: Number(paymentDetails.order.totalAmount) - Number(paymentDetails.order.paidAmount)}</div>
                        </div>

                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Payment Method : </b> 
                            </span><div>{paymentDetails.paymentMethod ? paymentDetails.paymentMethod.toUpperCase():paymentDetails.paymentIntent ? paymentDetails.paymentIntent.payment_method_types[0] ? paymentDetails.paymentIntent.payment_method_types[0].toUpperCase() : "N/A" : "N/A"}</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Currency : </b> 
                            </span><div>{paymentDetails.currency ? paymentDetails.currency : paymentDetails.paymentIntent && paymentDetails.paymentIntent.currency }</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Payment Through : </b> 
                            </span><div>{paymentDetails.paymentType ? paymentDetails.paymentType : paymentDetails.paymentIntent && paymentDetails.paymentIntent.payment_method_type ? paymentDetails.paymentIntent.payment_method_type[0] : paymentDetails.paymentMethod }</div>
                        </div>
                    </div> */}

                    
                    </div>
               </div>

            <div className="card border-0 mt-3">
                    <div className="card-header">
                        <h4>Customer Details</h4>
                    </div>

                    <div className="card-body">



                    <table>
                        <tr>
                            <th>Customer Name</th>
                            <td>{paymentDetails.fName && paymentDetails.fName}</td>
                        </tr>

                        <tr>
                            <th>Email</th>
                            <td>{paymentDetails.email && paymentDetails.email}</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>{paymentDetails.address && paymentDetails.address}</td>
                        </tr>

                        <tr>
                            <th>City</th>
                            <td>{paymentDetails.city && paymentDetails.city}</td>
                        </tr>

                        <tr>
                            <th>State</th> 
                            <td>{paymentDetails.state && paymentDetails.state}</td>
                        </tr>

                        <tr>
                            <th>Pin Code</th> 
                            <td>
                            {paymentDetails.pinCode && paymentDetails.pinCode }
                            </td>
                        </tr>

                        <tr>
                            <th>Country</th> 
                            <td>
                            {paymentDetails.country && paymentDetails.country}
                            </td>
                        </tr>


                    </table>


                    {/* <div className="paymentDetails-flex">
                        <div className="m-2 item-container">
                       <span>
                        <b>Customer Name : </b> 
                        </span><div>{paymentDetails.fName && paymentDetails.fName}</div>
                       </div>

                       <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Email : </b> 
                            </span><div>{paymentDetails.email && paymentDetails.email }</div>
                        </div>

                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Address : </b> 
                            </span><div>{paymentDetails.address && paymentDetails.address}</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>City : </b> 
                            </span><div>{paymentDetails.city && paymentDetails.city }</div>
                        </div>

                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>State : </b> 
                            </span><div>{paymentDetails.state && paymentDetails.state}</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Pin Code : </b> 
                            </span><div>{paymentDetails.pinCode && paymentDetails.pinCode }</div>
                        </div>
                        <div className="m-2 item-container" style={{marginLeft:'5%'}}>
                            <span>
                            <b>Country : </b> 
                            </span><div>{paymentDetails.country && paymentDetails.country }</div>
                        </div>
                       
                       



                    </div> */}

                    
                    </div>
               </div>
           </>

       }
        </div>
    )
}

export default SinglePaymentDetails
