import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import OrderService from '../../../services/order.service';
import {
  Tag,
  Button,
  Tabs,
  Dropdown,
  Menu,
  Table,
  Modal,
  Spin,
  Space,
  Breadcrumb,
  message,
  InputNumber,
  Tooltip,
} from 'antd';
import {Link} from 'react-router-dom';
import {history} from '../../../helpers/history';
import {
  EyeOutlined,
  MoreOutlined,
  WechatOutlined,
  LoadingOutlined,
  ArrowDownOutlined,
  BarChartOutlined,
  StopOutlined,
  ShoppingCartOutlined,
  FileExclamationOutlined,
} from '@ant-design/icons';
import invoiceDownload from '../../../assets/invoiceDownload.png';
import moment from 'moment';
const spinIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

const {TabPane} = Tabs;

const UserOrders = () => {
  const [allCreatedOrders, setAllCreatedOrders] = useState ([]);
  const [customerOrders, setCustomerOrders] = useState ([]);
  const [combinedOrders, setCombinedOrders] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const {coinsDetails} = useSelector (state => state.coinReducer);
  const [isSpinner, setSpinner] = useState (false);
  const [invoiceModal, setInvoiceModal] = useState (false);
  const [inReviewOrders, setInReviewOrders] = useState ([]);
  const [activeOrders, setActiveOrders] = useState ([]);
  const [deliveredOrders, setDeliveredOrders] = useState ([]);
  const [cancelledOrders, setCancellledOrders] = useState ([]);
  const [currentInvoiceData, setCurrentInvoiceData] = useState ('');
  const [loadingOrderData, setLoadingOrderData] = useState (false);
  const [coinsToApply,setCoinsToApply] = useState(0);
  const [filteredArr, setFilteredArr] = useState ([]);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_USER')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllOrders = async () => {
    setLoadingOrderData (true);
    try {
      let data = [];
      const response = await OrderService.getCustomerOrder (currentUser.id);
      // console.log("All Customer orders : ",response.data);
      if (response.data) {
        response.data.forEach (async (order, index) => {
          // console.log(Number(order.totalAmount) - Number(order.paidAmount))
          // let isOrderInvoiceResult = await OrderService.isOrderInvoicePresent(order._id);
          // console.log("Is Order Invoice : ",isOrderInvoiceResult.data);
          let finalDate = 'N/A';
          if (order.createdAt) {
            const date = new Date (order.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;
          }

          let updatedField = {
            key: index,
            title: order.name,
            id: order._id,
            createdAt: finalDate,
            sortDate: order.createdAt,
            category: order.category,
            assignedEmployee: order.assignedEmployee.length > 0
              ? order.assignedEmployee[0].username
              : 'Not Assigned',
            deadline: order.deadline,
            booking: order.bookingSession === true ? 'Yes' : 'No',
            documentation: order.documentation === true ? 'Yes' : 'No',
            details: order.details,
            totalAmount: order.totalAmount,
            paidAmount: order.paidAmount,
            remaining: Number (order.totalAmount) - Number (order.paidAmount) <=
              0
              ? false
              : true,
            status: order.status ? order.status : 'Requested',
          };

          data.push (updatedField);
        });
        console.log ('customer orders : ', data);
        setCustomerOrders (data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : "Can't Load All Orders !");
    }
  };

  const categorizeOrders = async data => {
    // console.log("Data in Categorization : ",data);
    // console.log("data length : ",data.length);
    const inReview = data.filter (o => {
      return (
        o.status === undefined ||
        o.status.toLowerCase () === 'requested' ||
        o.status.toLowerCase () === 'reviewing' ||
        o.status.toLowerCase () === ''
      );
    });

    const active = data.filter (o => {
      if (o.status !== undefined) {
        return (
          o.status.toLowerCase () === 'development' ||
          o.status.toLowerCase () === 'testing' ||
          o.status.toLowerCase () === 'deal confirmed'
        );
      }
    });
    // console.log("Active Orders : ",active);

    const delivered = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'delivered';
      }
    });
    const cancelled = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'cancel order';
      }
    });
    // console.log("FILTERED ARRAY : ",newFilteredArr);

    //  console.log("After Duplicate remove FILTERED ARRAY : ",newFilteredArr);
    setActiveOrders (active);
    setDeliveredOrders (delivered);
    setCancellledOrders (cancelled);
    setInReviewOrders (inReview);
    setLoadingOrderData (false);
    // console.log("ACTIVE ORDERS : ",active);
    // console.log("DELIVERED ORDERS : ",delivered);
    // console.log("CANCELLED ORDERS : ",cancelled);
    // console.log("NEW ORDERS : ",newOrders);
  };

  const getOrderForUser = async id => {
    // console.log("ID : ",id);
    setLoadingOrderData (true);
    const data = [];
    try {
      const response = await OrderService.getCreatedOrder (id);
      // console.log("created orders : ",response.data);
      if (response.data) {
        response.data.forEach ((order, index) => {
          //    console.log(Number(order.totalAmount) - Number(order.paidAmount))
          let finalDate = 'N/A';
          if (order.createdAt) {
            const date = new Date (order.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;
          }

          let updatedField = {
            key: index,
            title: order.name,
            id: order._id,
            createdAt: finalDate,
            sortDate: order.createdAt,
            category: order.category,
            assignedEmployee: order.assignedEmployee.length > 0
              ? order.assignedEmployee[0].username
              : 'Not Assigned',
            deadline: order.deadline,
            booking: order.bookingSession === true ? 'Yes' : 'No',
            documentation: order.documentation === true ? 'Yes' : 'No',
            details: order.details,
            totalAmount: order.totalAmount,
            paidAmount: order.paidAmount,
            remaining: Number (order.totalAmount) - Number (order.paidAmount) <=
              0
              ? false
              : true,
            status: order.status ? order.status : 'Requested',
          };

          data.push (updatedField);
        });
        setAllCreatedOrders (data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (() => {
    if (currentUser) {
      getAllOrders ();
      getOrderForUser (currentUser.id);
    }
  }, []);

  useEffect (
    () => {
      let combinedOrderArr = customerOrders.concat (allCreatedOrders);
      let filteredCombinedOrders = combinedOrderArr.filter (
        (item, index, self) =>
          index ===
          self.findIndex (o => o.id === item.id && o.name === item.name)
      );
      // console.log("Customer Orders : ",customerOrders)
      // console.log("Created Orders : ",allCreatedOrders)
      // console.log("final orders : ",filteredCombinedOrders);
      categorizeOrders (filteredCombinedOrders);
      setCombinedOrders (filteredCombinedOrders);
    },
    [customerOrders, allCreatedOrders]
  );

  const getSingleInvoice = async id => {
    setSpinner (true);
    try {
      const response = await OrderService.getSingleInvoiceDetails (id);
      // console.log("Single Invoice : ",response.data);
      if (response.data.length > 0) {
        setSpinner (false);
        setCurrentInvoiceData (response.data[0]);
      } else {
        setSpinner (false);
        message.error ('Invoice Not Found, Please Contact Administrator!');
      }
    } catch (err) {
      console.log (err);
      setSpinner (false);
      message.error (
        err.message
          ? err.message
          : 'Something went wrong when downloading invoice!'
      );
    }
  };

  useEffect (
    () => {
      if (currentInvoiceData) {
        setInvoiceModal (true);
      }
    },
    [currentInvoiceData]
  );

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      key: 'title',
      render: record => {
        return (
          <Link to={`/profile/view-order-details/${record.id}`}>
            <b>{record.title}</b>
          </Link>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },

    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
    },
    {
      title: 'Developer',
      dataIndex: 'assignedEmployee',
      key: 'developer',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => {
        return <Tag color={'geekblue'}>{text}</Tag>;
      },
    },
    {
      title: 'pay',
      key: 'pay',
      render: record => {
        if (
          record.status.toLowerCase () === 'deal confirmed' &&
          record.remaining === true
        ) {
          return (
            <>
            <Button type="primary" block disabled={false}>
              <Link to={{
                      pathname: `/profile/payment/${record.id}`,
                      coins: coinsToApply,
                    }}
               >Pay Now</Link>
            </Button>
            <br />
            <Tooltip title="Enter Coins for Discount" placement="bottom">
            <InputNumber min={0} step={50} defaultValue={0} disabled={coinsDetails.totalCoins ? false : true} max={coinsDetails.totalCoins ? coinsDetails.totalCoins : 100}  onChange={(value)=>setCoinsToApply(value)} />
            </Tooltip>
            
            </>
           
          );
        } else {
          return (
            <Button type="primary" block disabled={true}>
              <Link to={`/profile/payment/${record.id}`}>
                {record.remaining === false &&
                  (record.totalAmount != 0 && record.paidAmount != 0)
                  ? 'Paid'
                  : 'Pay Now'}
              </Link>
            </Button>
          );
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          {/* <Link to={`/profile/view-order-details/${record.id}`}>
                        <Button>
                         Details
                        </Button>
                      </Link> */}
          <Dropdown
            overlay={
              <Menu
                style={{
                  marginRight: '5%',
                  marginTop: '-5%',
                  width: 'fit-content',
                }}
              >

                <Menu.Item key="1">
                  <Link to={`/profile/view-order-details/${record.id}`}>
                    <EyeOutlined /> View Details
                  </Link>
                </Menu.Item>
                {record.status.toLowerCase () === 'delivered'
                  ? <Menu.Item key="2">
                      <Link to={`/profile/user-feedback/${record.id}`}>
                        <WechatOutlined /> Feedback
                      </Link>
                    </Menu.Item>
                  : null}

                <Menu.Item
                  key="4"
                  onClick={() => {
                    getSingleInvoice (record.id);
                  }}
                >
                  <ArrowDownOutlined /> Download Invoice
                </Menu.Item>

              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>
        </Space>
      ),
    },
    // {
    //     title: 'Feedback',
    //     key:'feedback',
    //     render:(record) =>{

    //         if(record.status.toLowerCase() === "delivered"){
    //         return <Button type="primary" block disabled={false} ><Link  to={`/profile/user-feedback/${record.id}`}>Feedback</Link></Button>
    //         }else{
    //             return <Button type="primary" block disabled={true} ><Link  to={`/profile/user-feedback/${record.id}`}>Feedback</Link></Button>
    //         }
    //     }
    // },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (setCombinedOrders.length > 0) {
        filteredData = combinedOrders.filter (order => {
          // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
          return (
            (order.name &&
              order.name.toLowerCase ().match (item.toLowerCase ())) ||
            (order.id && order.id.match (item))
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  return (
    <Spin
      tip="Downloading Invoice Please Wait..."
      indicator={spinIcon}
      spinning={isSpinner}
    >
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            {' '}<Link to="/profile/user/create-order">Create Order</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {' '}<Link to="/profile/user-orders">My Orders</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <h3>My Orders</h3>
          </div>

          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Search By Order Title or Order Id"
              style={{width: '30vw', marginBottom: '3%'}}
              onChange={e => {
                handleSearchChange (e.target.value);
              }}
            />
          </div>
          <Button type="primary">
            <Link to={`/profile/user/create-order`}>Create Order</Link>
          </Button>
        </div>

        {/* <Table dataSource={combinedOrders} columns={columns} /> */}
        <Tabs defaultActiveKey="1" type="card">

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <FileExclamationOutlined style={{fontSize: '20px'}} />
                in Review
              </span>
            }
            key="1"
          >
            <Table
              loading={loadingOrderData}
              dataSource={filteredArr.length > 0 ? filteredArr : inReviewOrders}
              columns={columns}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <BarChartOutlined style={{fontSize: '20px'}} />
                Active Orders
              </span>
            }
            key="2"
          >
            <Table
              loading={loadingOrderData}
              dataSource={filteredArr.length > 0 ? filteredArr : activeOrders}
              columns={columns}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <i
                  className="fa fa-truck"
                  aria-hidden="true"
                  style={{fontSize: '20px', marginRight: '6%'}}
                />
                Delivered Orders
              </span>
            }
            key="3"
          >
            <Table
              loading={loadingOrderData}
              dataSource={
                filteredArr.length > 0 ? filteredArr : deliveredOrders
              }
              columns={columns}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <StopOutlined style={{fontSize: '20px'}} />
                Cancelled Orders
              </span>
            }
            key="4"
          >
            <Table
              loading={loadingOrderData}
              dataSource={
                filteredArr.length > 0 ? filteredArr : cancelledOrders
              }
              columns={columns}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <ShoppingCartOutlined style={{fontSize: '20px'}} />
                All Orders
              </span>
            }
            key="6"
          >
            <Table
              loading={loadingOrderData}
              dataSource={filteredArr.length > 0 ? filteredArr : combinedOrders}
              columns={columns}
            />
          </TabPane>
        </Tabs>

      </div>

      <Modal
        title={'Download Invoice'}
        visible={invoiceModal}
        onCancel={() => {
          setInvoiceModal (false);
        }}
        footer={null}
      >
        <div>
          <center>
            <div style={{marginBottom: '3%'}}>
              <img src={invoiceDownload} alt="download" />
            </div>
            <a href={currentInvoiceData.invoiceUrl} download target={'_blank'}>
              <Button type={'primary'}>Download Invoice</Button>
            </a>
          </center>
        </div>
      </Modal>
    </Spin>
  );
};

export default UserOrders;
