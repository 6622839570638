import React, {useState, useEffect} from 'react';
import OrderService from '../../../services/order.service';
import GoogleServices from '../../../services/google.services';
import {
  Table,
  Space,
  message,
  Tabs,
  Modal,
  Dropdown,
  Menu,
  Button,
  Tag,
} from 'antd';
import moment from 'moment';
// import {EditOutlined,EyeOutlined,MoreOutlined, DollarOutlined,PlusOutlined} from '@ant-design/icons';

import {
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  BarChartOutlined,
  StopOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  PlusOutlined,
  FileExclamationOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

const {TabPane} = Tabs;

const ManageOrdersForSales = () => {
  const [allOrders, setAllOrders] = useState ([]);
  const [filteredArr, setFilteredArr] = useState ([]);
  const [totalAmount, setTotalAmount] = useState (null);
  const [paidAmount, setPaidAmount] = useState (null);
  const [partialAmount, setPartialAmount] = useState (null);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState (false);
  const [confirmOrderLoading, setConfirmOrderLoading] = useState (false);
  const [currentOrderId, setCurrentOrderId] = useState (null);
  const [activeOrders, setActiveOrders] = useState ([]);
  const [deliveredOrders, setDeliveredOrders] = useState ([]);
  const [cancelledOrders, setCancellledOrders] = useState ([]);
  const [completedOrders, setCompletedOrders] = useState ([]);
  const [newOrdersArr, setNewOrdersArr] = useState ([]);
  const [currentCancelOrderId, setCurrentCancelOrderId] = useState (null);
  const [currentCancelOrderDetails, setCurrentCancelOrderDetails] = useState (
    null
  );
  const [cancelOrderModal, showCancelOrderModal] = useState (null);
  const [cancellationReason, setCancellationReason] = useState ('');
  const [confirmCancelOrderModal, setConfirmCancelOrderModal] = useState (
    false
  );
  const [cancelOrderEvent, setCancelOrderEvent] = useState (null);
  let currentNow = new Date ();
  let currentDay = currentNow.getDate ();
  let currentMonth = currentNow.getMonth () + 1;
  let currentYear = currentNow.getFullYear ();
  let todaysDate = currentYear + '-' + currentMonth + '-' + currentDay;
  const [loadingOrderData, setLoadingOrderData] = useState (false);
  const [allLabels,setAllLabels] = useState([]);
  const tableLoading = {
    spinning: loadingOrderData,
    indicator: <LoadingOutlined style={{fontSize: 24}} spin />,
  };



  useEffect(()=>{
    let isMounted = true;

   const getAllGmailLabels = async ()=>{
    try{
        const response = await OrderService.getAllGmailLabels();
        // console.log(response.data);
        let userLabels = response.data?.labels?.filter(item=>{
            return item.type==='user'
        });

        // console.log(userLabels);
        setAllLabels(userLabels);
    }catch(err){
        console.log(err)
    }
   }
   if(isMounted){
    getAllGmailLabels();
   }

   return ()=>{
       isMounted = false;
   }
   
},[])


  const handleEditAmount = (id, t, p, partial) => {
    // console.log("id : ",id)
    // console.log("got total:",t);
    // console.log("got paid : ",p);
    // console.log("got partial : ",partial);
    setTotalAmount (t);
    setPaidAmount (p);
    setCurrentOrderId (id);
    setPartialAmount (partial);
    if (t === undefined) {
      setTotalAmount (0);
    }
    if (p === undefined || p == 'NaN') {
      setPaidAmount (0);
    }
    if (partial === undefined || partial === null) {
      setPartialAmount (0);
    }

    setIsOrderModalVisible (true);
  };

  const sendStatusChangedMail = async (mail, order, status) => {
    // console.log("Mail : ",order.userMail);
    let filteredLabel = allLabels.find((item)=>{
      return item.name.toLowerCase().match(status.toLowerCase())
    });
    if (mail) {
      try {
        const data = {
          To: mail,
          Subject: 'Order Status Changed',
          Write: `Dear User, <br /> Your Request of <b>${order.name}</b> is Now in <b>${status}</b> Phase. <br /> We Will be Keep Notifying you about the Updates on your order. <br /> Stay Connected With us. <br /> <br />Thank You for Choosing <b>Codersarts!</b>`,
          attachment: false,
          labelId:filteredLabel.id
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${mail}`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
    }
  };

  const statusChangeHandler = async (e, id, order) => {
    if (e.target.value.toLowerCase () !== 'change status') {
      try {
        const response = await OrderService.updateStatus (id, e.target.value);
        // console.log(response);
        message.success (`Status Changed to ${e.target.value} Successfully!`);
      } catch (err) {
        message.error (err.message ? err.message : "Couldn't Change Status!");
      }
    }
    // window.location.reload();
    getOrders ();
    sendStatusChangedMail (order.userMail, order, e.target.value);
    sendStatusChangedMail (order.employeeMail, order, e.target.value);
    sendStatusChangedMail (order.assignedUserMail, order, e.target.value);
  };

  const statusChangeModal = (e, orderId, orderDetails) => {
    if (e.target.value.toLowerCase () === 'cancel order') {
      setCurrentCancelOrderId (orderId);
      setCurrentCancelOrderDetails (orderDetails);
      setCancelOrderEvent (e);
      showCancelOrderModal (true);
    } else {
      statusChangeHandler (e, orderId, orderDetails);
    }
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      key: 'name',
      render: record => {
        return (
          <Link
            to={{
              pathname: `/profile/view-order-details/${record.id}`,
              state: {orderIds: record.orderIds},
            }}
          >
            <b>{record.name}</b>
          </Link>
        );
      },
    },

    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      sorter: (a, b) =>
        moment (a.deadline).unix () - moment (b.deadline).unix (),
      width: '10vw',
      render: deadline => {
        return deadline === todaysDate
          ? <Tag color={'red'}><b>{deadline}</b></Tag>
          : <b>{deadline}</b>;
      },
    },

    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: text => {
        return <Tag color={'geekblue'}>{text}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: record => (
        <Space size="middle">
          {/* <Link to={`/profile/view-order-details/${record.id}`}>
                        <Button>
                         Details
                        </Button>
                      </Link>
                      <Link to={{pathname:`/profile/edit-order/${record.title}`,id:record.id}} id="action" >
                      <Button type={'primary'}>
                      <EditOutlined /> 
                         Edit Details
                        </Button>
                      </Link> */}
          <select
            className="form-control"
            onChange={e => statusChangeModal (e, record.id, record)}
          >
            <option>Change Status</option>
            <option>Requested</option>
            <option>Reviewing</option>
            <option>Development</option>
            <option>Testing</option>
            <option>Deal Confirmed</option>
            <option>Delivered</option>
            <option>Completed</option>
            <option>Cancel Order</option>
          </select>

          <Dropdown
            overlay={
              <Menu style={{marginRight: '5%', width: 'fit-content'}}>
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link
                    to={{
                      pathname: `/profile/view-order-details/${record.id}`,
                      state: {orderIds: record.orderIds},
                    }}
                  />

                  View Details
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to={{
                      pathname: `/profile/edit-order/${record.name}`,
                      id: record.id,
                    }}
                    id="action"
                  >
                    <EditOutlined /> Edit Details{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => {
                    handleEditAmount (
                      record.id,
                      record.totalAmount,
                      record.paidAmount,
                      record.partialAmount
                    );
                  }}
                >
                  <DollarOutlined /> Edit Amount{' '}
                </Menu.Item>

                <Menu.Item key="4">
                  <DollarOutlined />
                  {' '}
                  <Link to={`/profile/create-manual-payment/${record.id}`} />
                  {' '}
                  Accept Manual Payment
                </Menu.Item>

              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const getOrders = () => {
    setLoadingOrderData (true);
    OrderService.getAllOrders ()
      .then (response => {
        const data = [];

        // console.log(response.data);
        let finalDate = 'N/A';
        response.data.forEach ((order, index) => {
          if (order.createdAt) {
            const date = new Date (order.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;
          }
          let updatedField = {
            key: index,
            name: order.name,
            createdAt: finalDate,
            sortDate: order.createdAt,
            id: order._id,
            category: order.category,
            deadline: order.deadline,
            totalAmount: order.totalAmount,
            paidAmount: order.paidAmount,
            partialAmount: order.partialAmount && order.partialAmount,
            details: order.details,
            status: order.status ? order.status : '---',
            assignedTo: order.assignedTo &&
              order.assignedTo[0] &&
              order.assignedTo[0].username,
            customer: order.customer
              ? order.customer[0] ? order.customer[0].username : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].username
                  : null,
            userMail: order.customer
              ? order.customer[0] ? order.customer[0].email : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].email
                  : null,
            employeeMail: order.assignedEmployee && order.assignedEmployee[0]
              ? order.assignedEmployee[0].email
              : null,
            assignedUserMail: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0].email
              : null,
          };

          data.push (updatedField);
        });
        setAllOrders (assignAllOrderId (data));
        categorizeOrders (data);
      })
      .catch (err => {
        console.log (err);
        message.error (err.message ? err.message : 'Unable to fetch Orders!');
      });
  };
  useEffect (() => {
    getOrders ();
  }, []);

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allOrders.length > 0) {
        filteredData = allOrders.filter (order => {
          // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
          return (
            (order.name &&
              order.name.toLowerCase ().match (item.toLowerCase ())) ||
            (order.id && order.id.match (item)) ||
            (order.customer &&
              order.customer.toLowerCase ().match (item.toLowerCase ()))
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  const handleOrderOk = async () => {
    setConfirmOrderLoading (true);
    try {
      // console.log("Total Amount : ",totalAmount);
      // console.log("Paid Amount : ",paidAmount);
      // console.log("Partial Amount : ",partialAmount);
      const response = await OrderService.updateOrder ({
        _id: currentOrderId,
        totalAmount,
        paidAmount,
        partialAmount: partialAmount,
      });
      // console.log(response);
      setConfirmOrderLoading (false);
      setIsOrderModalVisible (false);
      message.success ('Successfully Updated Order Amounts !');
    } catch (err) {
      console.log (err);
      setConfirmOrderLoading (false);
      setIsOrderModalVisible (false);
      message.error (err.message);
    }
  };

  const handleOrderCancel = () => {
    setIsOrderModalVisible (false);
  };

  const categorizeOrders = async data => {
    let finalNewOrders = [];
    let newOrders = [];

    const active = data.filter (o => {
      if (o.status !== undefined) {
        return (
          o.status.toLowerCase () === 'development' ||
          o.status.toLowerCase () === 'testing' ||
          o.status.toLowerCase () === 'deal confirmed'
        );
      }
    });

    try {
      const response = await OrderService.getNewOrders ();

      response.data.forEach ((order, index) => {
        let finalDate = 'N/A';
        if (
          order.status
            ? order.status.toLowerCase () !== 'development' &&
                order.status.toLowerCase () !== 'testing' &&
                order.status.toLowerCase () !== 'deal confirmed'
            : true
        ) {
          if (order.createdAt) {
            const date = new Date (order.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;
          }
          let updatedField = {
            key: index,
            id: order._id,
            name: order.name,
            sortDate: order.createdAt,
            createdAt: finalDate,
            category: order.category,
            deadline: order.deadline,
            booking: order.bookingSession === true ? 'Yes' : 'No',
            documentation: order.documentation === true ? 'Yes' : 'No',
            details: order.details,
            status: order.status,
            totalAmount: order.totalAmount,
            paidAmount: order.paidAmount,
            progress: order.progress ? order.progress : 0,
            customer: order.customer
              ? order.customer[0] ? order.customer[0].username : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].username
                  : null,
            partialAmount: order.partialAmount && order.partialAmount,
            assignedTo: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0].username
              : 'N/A',
            userMail: order.customer
              ? order.customer[0] ? order.customer[0].email : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].email
                  : null,
            employeeMail: order.assignedEmployee && order.assignedEmployee[0]
              ? order.assignedEmployee[0].email
              : null,
            assignedUserMail: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0].email
              : null,
          };
          // console.log("ORDER : ",order);
          newOrders.push (updatedField);
        }
      });
    } catch (err) {
      message.error (err.message ? err.message : 'Something Went Wrong !');
    }

    finalNewOrders = newOrders.filter (o => {
      return (
        o.status === undefined ||
        o.status.toLowerCase () === 'requested' ||
        o.status.toLowerCase () === 'reviewing'
      );
    });

    const newOrdersExtra = data.filter (o => {
      return (
        o.status === undefined ||
        o.status.toLowerCase () === 'requested' ||
        o.status.toLowerCase () === 'reviewing'
      );
    });

    let mergedNewOrders = finalNewOrders.concat (newOrdersExtra);
    //  console.log("New Orders : ",newOrders);
    //  console.log(" EXTRA : ",newOrdersExtra);
    //  console.log("merged : ",mergedNewOrders);

    let newFilteredArr = mergedNewOrders.reduce ((acc, current) => {
      const x = acc.find (item => item.id === current.id);
      if (!x) {
        return acc.concat ([current]);
      } else {
        return acc;
      }
    }, []);
    //   console.log("NEW FILTERED ARR : ",newFilteredArr);

    const delivered = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'delivered';
      }
    });
    const completed = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'completed';
      }
    });
    const cancelled = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'cancel order';
      }
    });
    // console.log("FILTERED ARRAY : ",newFilteredArr);

    newFilteredArr = newFilteredArr.filter (
      (item, index, self) =>
        index === self.findIndex (o => o.id === item.id && o.name === item.name)
    );

    //  console.log("After Duplicate remove FILTERED ARRAY : ",newFilteredArr);
    setActiveOrders (assignAllOrderId (active));
    setDeliveredOrders (assignAllOrderId (delivered));
    setCancellledOrders (assignAllOrderId (cancelled));
    setNewOrdersArr (assignAllOrderId (newFilteredArr));
    setCompletedOrders (assignAllOrderId (completed));
    // console.log (assignAllOrderId (active));
    setLoadingOrderData (false);
    // console.log("ACTIVE ORDERS : ",active);
    // console.log("DELIVERED ORDERS : ",delivered);
    // console.log("CANCELLED ORDERS : ",cancelled);
    // console.log("NEW ORDERS : ",newOrders);
  };

  function assignAllOrderId (arr) {
    let orderId = [];
    arr = arr.sort (function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return moment (b.sortDate).unix () - moment (a.sortDate).unix ();
    });
    // console.log (arr);

    arr.forEach (item => {
      orderId.push (item.id);
    });
    let updatedActive = [];

    arr.forEach (item => {
      let updatedData = {
        ...item,
        orderIds: orderId,
      };
      updatedActive.push (updatedData);
    });
    return updatedActive;
  }

  const handleCancelOrderModal = async () => {
    setConfirmCancelOrderModal (true);
    try {
      const response = await OrderService.updateOrder ({
        _id: currentCancelOrderId,
        cancellationReason: cancellationReason,
      });
      console.log (response);
      message.success ('Added Cancellation Reason !');
      statusChangeHandler (
        cancelOrderEvent,
        currentCancelOrderId,
        currentCancelOrderDetails
      );
      setCancellationReason ('');
      setConfirmCancelOrderModal (false);
      showCancelOrderModal (false);
    } catch (err) {
      console.log (err);
      setConfirmCancelOrderModal (false);
      showCancelOrderModal (false);
      message.error (
        err.message ? err.message : 'Unable to Add Order Cancellation Reason'
      );
    }
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h3>All Orders</h3>
        </div>

        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            style={{width: '30vw', marginBottom: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>

        <Button type={'primary'}>
          <Link to="/profile/create-order">
            <PlusOutlined /> Create Order
          </Link>
        </Button>

      </div>
      {/* <Table dataSource={filteredArr.length >0  ? filteredArr : allOrders} columns={columns} />; */}

      <Tabs defaultActiveKey="1" type="card">

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <FileExclamationOutlined style={{fontSize: '20px'}} />
              New Orders
            </span>
          }
          key="1"
          onChange={getOrders}
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : newOrdersArr}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <BarChartOutlined style={{fontSize: '20px'}} />
              Active Orders
            </span>
          }
          key="2"
          onChange={getOrders}
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : activeOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <CheckCircleOutlined style={{fontSize: '20px'}} />
              Completed Orders
            </span>
          }
          key="3"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : completedOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <i
                className="fa fa-truck"
                aria-hidden="true"
                style={{fontSize: '20px', marginRight: '6%'}}
              />
              Delivered Orders
            </span>
          }
          key="4"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : deliveredOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <StopOutlined style={{fontSize: '20px'}} />
              Cancelled Orders
            </span>
          }
          key="5"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : cancelledOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <ShoppingCartOutlined style={{fontSize: '20px'}} />
              All Orders
            </span>
          }
          key="6"
          onChange={getOrders}
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : allOrders}
            columns={columns}
          />
        </TabPane>
      </Tabs>

      <Modal
        title={'Edit Order Amount'}
        visible={isOrderModalVisible}
        onOk={handleOrderOk}
        onCancel={handleOrderCancel}
        confirmLoading={confirmOrderLoading}
      >
        <form>
          <div className="form-group">
            <label><h6>Total Amount :</h6></label>
            <input
              type="number"
              className="form-control"
              value={totalAmount}
              onChange={e => {
                setTotalAmount (e.target.value);
              }}
            />
          </div>

          <div className="form-group">
            <label><h6>Paid Amount :</h6></label>
            <input
              type="number"
              className="form-control"
              value={paidAmount}
              onChange={e => {
                setPaidAmount (e.target.value);
              }}
            />
          </div>
          <div className="form-group">
            <label><h6>Partial Amount :</h6></label>
            <input
              type="number"
              className="form-control"
              value={partialAmount}
              onChange={e => {
                setPartialAmount (e.target.value);
              }}
            />
          </div>
        </form>
      </Modal>

      <Modal
        title={'Cancellation Reason'}
        visible={cancelOrderModal}
        onOk={handleCancelOrderModal}
        onCancel={() => {
          showCancelOrderModal (false);
        }}
        confirmLoading={confirmCancelOrderModal}
      >
        <form>
          <div>
            <label><h6>Enter Cancellation Reason:</h6></label>
            <input
              type="text"
              className="form-control"
              value={cancellationReason}
              onChange={e => {
                setCancellationReason (e.target.value);
              }}
            />
          </div>
        </form>
      </Modal>

    </div>
  );
};

export default ManageOrdersForSales;
