import { Modal, Form, Input } from 'antd';
import {Link} from 'react-router-dom';


const LoginPopup = ({ visible, onLogin, onCancel,loading }) => {
    const [form] = Form.useForm();
    return (
      <Modal
        visible={visible}
        title="Login"
        okText="Login"
        cancelText="Cancel"
        onCancel={onCancel}
        confirmLoading={loading}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onLogin(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: 'public',
          }}
        >
          <Form.Item
            name="username"
            label="Username or Email"
            rules={[
              {
                required: true,
                message: 'Please enter username!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password"
          rules={[
            {
              required: true,
              message: 'Please enter password!',
            },
          ]}
          >
            <Input type="password" />
          </Form.Item>
          
          <Link to="/auth/signup" target="_blank"> <center><b> Sign Up ?</b></center></Link>
        </Form>
      </Modal>
    );
  };


  export default LoginPopup;