import React,{useState,useEffect} from 'react';

import OrderService from '../../../services/order.service';
import './Orders.css';
import { Table,Space,Button,Tag } from 'antd';

import { Link } from 'react-router-dom';


const Orders = ()=>{
    const [allOrders,setAllOrders] = useState([]);
  
    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Deadline',
            dataIndex: 'deadline',
            key: 'deadline',
        },
        {
            title: 'Booking',
            dataIndex: 'booking',
            key: 'booking',
        },
        {
            title: 'Documentation',
            dataIndex: 'documentation',
            key: 'documentation',
        },
        {
            title: 'Assignee',
            dataIndex: 'assignedTo',
            key: 'assignedTo',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render:(text)=>{
                return(<Tag color={'geekblue'}>{text}</Tag>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
                <Space size="middle">
                      <Link to={`/profile/view-order-details/${record.id}`}>
                        <Button>
                         Details
                        </Button>
                      </Link>
                </Space>
              )
           
        },
    ]

    const getOrders = ()=>{
        OrderService.getAllOrders().then((response)=>{
            const data = [];
            console.log(response.data);
            response.data.forEach((order,index)=>{
                let updatedField = {
                    key:index,
                    title:order.name,
                    id:order._id,
                    category:order.category,
                    deadline:order.deadline,
                    booking:order.bookingSession ===true ? "Yes" : "No",
                    documentation:order.documentation  ===true ? "Yes" : "No",
                    details:order.details,
                    status:order.status ? order.status: "---",
                    assignedTo:order.assignedTo[0].username,
                }

                data.push(updatedField);
            })
            setAllOrders(data);
            
        }).catch((err)=>{
            console.log(err);
        })
    }
    useEffect(()=>{
        getOrders();
    },[])
    return(
        <div>
            <Table dataSource={allOrders} columns={columns} />;
        </div>
    )
}


export default Orders;