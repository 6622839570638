import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";


import './UpdateDetailsForm.css';
import { update } from "../../actions/users";
import Message from "../Message/Message";

const UpdateDetailsForm = ({currentUser}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [contact, setContact] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
 
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if(successMessage){
      setSuccessMessage(false);
    }
  })
 


  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeGender = (e) => {
    const gender = e.target.value;
    if(gender.toLowerCase() ==="select gender"){
     return;
    }
    setGender(gender);
    
  };

  const onChangeContact = (e) => {
    const contact = e.target.value;
    setContact(contact);
    if(successMessage){
      setSuccessMessage(false);
    }
  };

  const onChangeDob = (e) => {
    const dob = e.target.value;
    setDob(dob);
    if(successMessage){
      setSuccessMessage(false);
    }
  };

  const onChangeAddress = (e) => {
    const email = e.target.value;
    setAddress(email);
  };


  let updatedData = {
    "_id":currentUser.id,
    "username":currentUser.username,
    "name":name,
    "email":email,
    "contact":contact,
    "address":address,
    "dob":dob,
    "gender":gender
  }

  useEffect(()=>{
    if(successMessage){
      setSuccessMessage(false);
    }
  })
  const handleUpdate = (e) => {
    e.preventDefault();
        // console.log(currentUser.id);
      dispatch(update(updatedData))
        .then((response) => {
          // console.log("GOT RESPONSE: ", response);
            setSuccessMessage(true);
        })
        .catch((err) => {
          console.log(err);
        });
    
        
      
  };

  

 
  return (
    <div className="col-md-12 updateForm">
      <div className="card card-container">
       

        <form>
          
            <div className="form-inline">

            

              <div className="form-group">
                <input
                  type="text"
                  className="form-control "
                  name="name"
                  value={name}
                  onChange={onChangeName}
                  placeholder="Name"
                />
              </div>

              <div className="form-group">
                <select 
                className="form-control "
                 value={gender}
                 name="gender"
                 onChange={onChangeGender}
                 >
                  <option selected>Select Gender</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Other</option>
                </select>
              </div>
              </div>

              <div className="form-inline">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control "
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  placeholder="Email"
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  className="form-control "
                  name="Dob"
                  value={dob}
                  onChange={onChangeDob}
                  
                />
              </div>
              </div>
              <div className="form-inline">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control "
                    id="contact"
                    name="Contact"
                    value={contact}
                    onChange={onChangeContact}
                    placeholder="Contact"
                  />
                </div>
              </div>
              <div className="form-inline">
                <textarea 
                className="textarea"
                 placeholder="Address" 
                  value={address} 
                 onChange={onChangeAddress}>
                </textarea>
              </div>
              
              <div className="form-group">
                <button style={{width:'fit-content'}} className="pay-btn" onClick={(e)=>{handleUpdate(e)}}>Update</button>
              </div>
        </form>
        {successMessage ? <Message type="success" message="Updated Successfully" /> : null}
      </div>
    </div>
  );
};

export default UpdateDetailsForm;
