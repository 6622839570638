import React,{useEffect,useState} from 'react';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import GoogleServices from '../../../services/google.services.js';
import { Table,Tabs, Button,message} from 'antd';
import orderService from '../../../services/order.service';
import moment from 'moment';

const {TabPane} = Tabs

function DriveFiles() {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [imagesArr,setImagesArr] = useState([]);
    const [pdfArr,setPdfArr] = useState([]);
    const [wordFileArr,setWordFileArr] = useState([]);
    const [excelFileArr,setExcelFileArr] = useState([]);
    const [zipFileArr,setZipFileArr] = useState([]);
    const [allFiles,setAllFiles] = useState([]);
    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser]);


    const getAllDriveFiles = async ()=>{
        const images = [];
        const pdfFiles = [];
        const wordFiles = [];
        const excelFiles = [];
        const zipFiles = [];
        const allFilesArr = [];
        try{
            const response = await GoogleServices.getlist();
            const response2 = await orderService.getDriveFiles();
            // console.log(response2.data);

            // console.log(response.data);
            response.data.files.map((file)=>{
                response2.data.map((item)=>{
                    // console.log("Created At : ",item.createdAt)
                    const date = new Date(item.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                    let finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                    // console.log("Final Date  : ",finalDate)
                    if(item.driveId===file.id){
                        if(file.mimeType.split('/')[0]==="image"){
                            // console.log('image');
                            let modifiedData = {
                                ...file,
                                createdAt:finalDate,
                                sortDate:item.createdAt
                            }
                            images.push(modifiedData);
                        }else if(file.mimeType==="application/pdf"){
                            let modifiedData = {
                                ...file,
                                createdAt:finalDate,
                                sortDate:item.createdAt
                            }
                            pdfFiles.push(modifiedData);
                        }else if(file.mimeType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                        {
                            let modifiedData = {
                                ...file,
                                createdAt:finalDate,
                                sortDate:item.createdAt
                            }
                            // console.log("WORD FILES");
                            wordFiles.push(modifiedData);
                        } 
                        else if(file.mimeType === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                        {
                            let modifiedData = {
                                ...file,
                                createdAt:finalDate,
                                sortDate:item.createdAt
                            }
                            excelFiles.push(modifiedData);
                        }else if(file.mimeType==="application/zip"){
                            let modifiedData = {
                                ...file,
                                createdAt:finalDate,
                                sortDate:item.createdAt
                            }
                            zipFiles.push(modifiedData);
                        }
                        let modifiedData = {
                            ...file,
                            createdAt:finalDate,
                            sortDate:item.createdAt
                        }
                        allFilesArr.push(modifiedData);
                       
                    }
                })
            
            });

            // console.log("PDF : ",pdfFiles);
            // console.log("WORD : ",wordFiles);
            // console.log("ALL files arr : ",allFilesArr);
            setImagesArr(images);
            setPdfArr(pdfFiles);
            setWordFileArr(wordFiles);
            setExcelFileArr(excelFiles);
            setZipFileArr(zipFiles); 
            setAllFiles(allFilesArr);           

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Something went wrong !");
        }
    }

    // const getDriveFilesDB = async ()=>{
    //     try{
           
    //     }catch(err){
    //         console.log(err);
    //         message.error(err.message ? err.message : ' Unable to fetch Files from DB !');
    //     }
    // }
    useEffect(()=>{
        getAllDriveFiles();
        // getDriveFilesDB()
    },[]);


    useEffect(()=>{

    },[])

    const Download = async(file) => {
        let ids ={ id: file}
        try{
            const response = await  GoogleServices.downloadfile(ids);
            window.open(response.webContentLink).focus();
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something Went Wrong !');
        }
    }
          
            


    const columns = [
        {
            title:"Created At",
            dataIndex:'createdAt',
            key:'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
            width:'20%'

        },
        {
            title:"Name",
            dataIndex:'name',
            sorter: true,
            render: file => `${file}`,
            width:'60%',
            height:'10em'
        },
        {
            title:"Type",
            dataIndex:'mimeType',
            sorter:true,
            render: file => `${file}`,
            width:'30%'

        },
        {
            title:"Action",
            dataIndex:'id',
            sorter:true,
            render: (file)=> <Button type="primary" onClick={()=>Download(file)}>Download</Button>    
        
        }
    ]

    // console.log("IMAGES ARRAY : ",imagesArr);
    // console.log("PDF ARRAY : ",pdfArr);
    // console.log("WORD ARRAY : ",wordFileArr);
    // console.log("EXCEL ARRAY : ",excelFileArr);
    // console.log("ZIP ARRAY : ",zipFileArr);
    return (
        <div>
            <h2>Drive Files</h2>
            <Tabs defaultActiveKey="1" type="card" >


                 <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-files-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All Files
                    </span>
                } key="1">
                <Table dataSource={allFiles} columns={columns} />
                </TabPane>


                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-picture-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All Images
                    </span>
                } key="2">
                <Table dataSource={imagesArr} columns={columns} />
                </TabPane>

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                   <i className="fa fa-file-pdf-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All PDF
                    </span>
                } key="3" >
                <Table dataSource={pdfArr} columns={columns} />
                </TabPane>
                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
                    <i className="fa fa-file-word-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All Word Docs
                    </span>
                } key="4">
                <Table dataSource={wordFileArr} columns={columns} />
                </TabPane>
                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
               <i class="fa fa-file-excel-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All Excel Docs
                    </span>
                    
                } key="5">
                <Table dataSource={excelFileArr}  columns={columns} />
                </TabPane>

                <TabPane tab={
                    <span style={{fontSize:'15px'}}>
               <i className="fa fa-file-archive-o" aria-hidden="true" style={{fontSize:'20px',marginRight:'6%'}}></i>
                    All Zip Files
                    </span>
                    
                } key="6">
                <Table dataSource={zipFileArr}  columns={columns} />
                </TabPane>
                </Tabs>
        </div>
    )
}

export default DriveFiles
