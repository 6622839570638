import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';
import './Profile.css';
import MyModal from '../MyModal/MyModal';
import UpdateDetailsForm from '../updateDetailsForm/UpdateDetailsForm';
import {
  CloseCircleOutlined,
  UsergroupAddOutlined,
  KeyOutlined,
  LoginOutlined,
  LogoutOutlined,
  QuestionCircleOutlined,
  CalendarOutlined

} from '@ant-design/icons';
import {Button, Input, Modal, Select, message, Tooltip} from 'antd';
import Message from '../Message/Message';
import userService from '../../services/user.service';
// import OrderServices from "../../services/order.service";
import ProfilePicture from './ProfilePicture/ProfilePicture';
import orderService from '../../services/order.service';
import Confetti from 'react-confetti';

const {TextArea} = Input;
const {Option} = Select;
const Profile = () => {
  const [modalToggle, setModalToggle] = useState (false);
  const [passwordConfirmLoading, setPasswordConfirmLoading] = useState (false);
  const [visible, setVisible] = useState (false);
  const [password, setPassword] = useState (null);
  const [confirmPassword, setConfirmPassword] = useState (null);
  const [successMessage, setSuccessMessage] = useState (false);
  const [errorMessage, setErrorMessage] = useState (false);
  const {user: currentUser} = useSelector (state => state.auth);
  const [error, setError] = useState ('');
  const [skillsModal, setSkillsModal] = useState (false);
  const [skillsArr, setSkillsArr] = useState ([]);
  const [skillsLoading, setSkillsLoading] = useState (false);
  const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);
  const [newSkillCategoryModal, setNewSkillCategoryModal] = useState (false);
  const [loadingCategory, setLoadingCategory] = useState (false);
  const [newSkillCategory, setNewSkillCategory] = useState ('');
  const [userSkillsArr, setUserSkillsArr] = useState ([]);
  const [addInterestModal, setAddInterestModal] = useState (false);
  const [loadingInterest, setLoadingInterest] = useState (false);
  const [interestArr, setInterestArr] = useState ([]);
  const [defaultUserInterest, setDefaultUserInterest] = useState ([]);
  const [joinModal, setJoinModal] = useState (false);
  const [loadingJoin, setLoadingJoin] = useState (false);
  const [availableTime, setAvailableTime] = useState ('');
  const [joiningDate, setJoiningDate] = useState ('');
  const [description, setDescription] = useState ('');
  const [workAs, setWorkAs] = useState ('');
  const [enableCheckIn, setEnableCheckIn] = useState (false);
  const [enableCheckOut, setEnableCheckOut] = useState (false);
  const [totalWorkHours, setTotalWorkHours] = useState (0);
  const [isUserCheckedOut, setIsUserCheckedOut] = useState (false);
  const [isAlreadyCheckedOut,setIsAlreadyCheckedOut] = useState(false);
  const [currentCheckinData,setCurrentCheckinData] = useState("");


  let now = new Date ();
  let dd = now.getDate ();
  let mm = now.getMonth () + 1;
  let yyyy = now.getFullYear ();
  let currentDate = yyyy + '-' + mm + '-' + dd;

  const getTodaysAttendance = async () => {
    try {
      const response = await orderService.getTodaysAttendance ({
        checkInDate: currentDate,
        attendanceUser: currentUser.id,
      });
      // console.log (response.data);
      if (response) {
        setCurrentCheckinData(response.data[0])
        if (response.data.length > 0) {
          setEnableCheckIn (false);
          if (!response.data[0].checkout && !response.data[0].isLeave) {
            // console.log ('checkout enable');
            setEnableCheckOut (true);
           
            
          }

          if(response.data[0].checkout && !response.data[0].isLeave){
            setIsAlreadyCheckedOut(true);
            
          }


        } else {
          setEnableCheckIn (true);
        }
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (
    () => {
      const getTodaysAttendance = async () => {
        try {
          const response = await orderService.getTodaysAttendance ({
            checkInDate: currentDate,
            attendanceUser: currentUser.id,
          });
          // console.log (response.data);
          if (response) {
            setCurrentCheckinData(response.data[0])
            if (response.data.length > 0) {
              setEnableCheckIn (false);
              
              if (!response.data[0].checkout && !response.data[0].isLeave) {
                // console.log ('checkout enable');
                setEnableCheckOut (true);
               
                
              }
    
              if(response.data[0].checkout && !response.data[0].isLeave){
                setIsAlreadyCheckedOut(true);
                
              }


            } else {
              setEnableCheckIn (true);
            }
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      getTodaysAttendance ();
    },
    [currentUser, currentDate]
  );

  useEffect (() => {
    if (errorMessage) {
      setErrorMessage (false);
    }
    if (successMessage) {
      setSuccessMessage (false);
    }
  });

  const getAllCategory = async () => {
    try {
      const response = await userService.getAllSkillsList ();
      // console.log(response.data);
      setSkillCategoriesArr (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const getUserSkills = async () => {
    try {
      const response2 = await userService.getUserSkillsById (currentUser.id);
      // console.log("Skills : ",response2.data);
      setUserSkillsArr (response2.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch user skills!'
      );
    }
  };

  const getUserInterests = async () => {
    try {
      const response2 = await userService.getUserInterestById (currentUser.id);
      // console.log("Skills : ",response2.data);
      setDefaultUserInterest (response2.data);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch user skills!'
      );
    }
  };

  useEffect (
    () => {
      let isMount = true;
      const getAllCategory = async () => {
        try {
          const response = await userService.getAllSkillsList ();
          // console.log(response.data);
          if (isMount && response) {
            setSkillCategoriesArr (response.data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      const getUserSkills = async () => {
        try {
          const response2 = await userService.getUserSkillsById (
            currentUser.id
          );
          // console.log("Skills : ",response2.data);
          if (isMount && response2) {
            setUserSkillsArr (response2.data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch user skills!'
          );
        }
      };

      const getUserInterests = async () => {
        try {
          const response2 = await userService.getUserInterestById (
            currentUser.id
          );
          // console.log("Skills : ",response2.data);
          if (isMount && response2) {
            setDefaultUserInterest (response2.data);
          }
        } catch (err) {
          console.log (err);
          message.error (
            err.message ? err.message : 'Unable to fetch user skills!'
          );
        }
      };

      getAllCategory ();

      if (
        currentUser.roles[0] === 'ROLE_ADMIN' ||
        currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
        currentUser.roles[0] === 'ROLE_REMOTEDEVELOPER'
      ) {
        getUserSkills ();
      }

      if (currentUser.roles[0] === 'ROLE_USER') {
        getUserInterests ();
      }

      return () => {
        isMount = false;
      };
    },
    [currentUser]
  );

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  const showPasswordModal = () => {
    setVisible (true);
  };

  const handleChangeInterest = values => {
    // console.log (values);
    setInterestArr (values);
  };

  const handlePasswordOk = async () => {
    setPasswordConfirmLoading (true);
    if (password !== confirmPassword) {
      setPasswordConfirmLoading (false);
      setError ('Password Did Not Match !');
      setErrorMessage (true);
      setVisible (false);
      return;
    }
    if (password.length < 8) {
      setPasswordConfirmLoading (false);
      setError ('Password should be Minimum 8 Characters');
      setErrorMessage (true);
      setVisible (false);
      return;
    }
    try {
      const response = await userService.updateUserPassword (
        currentUser.id,
        password
      );
      // console.log(response.data);
      setPasswordConfirmLoading (false);
      setSuccessMessage (true);
      setVisible (false);
      setPassword ('');
      setConfirmPassword ('');
    } catch (err) {
      setPasswordConfirmLoading (false);
      console.log (err);
      setError (err.message);
      setErrorMessage (true);
      setVisible (false);
      return;
    }
  };

  const handlePasswordCancel = () => {
    // console.log('Clicked cancel button');
    setVisible (false);
  };

  const handleModal = () => {
    setModalToggle (!modalToggle);
  };

  const handleSkillsModal = () => {
    setSkillsModal (true);
  };

  const handleAddSkills = async () => {
    // console.log("skills ",skillsArr);
    setSkillsLoading (true);
    try {
      const response = await userService.updateDetailsByAdmin ({
        _id: currentUser.id,
        skills: skillsArr,
      });
      // console.log (response.data);
      if (response) {
        setSkillsLoading (false);
        message.success ('Updated Skills Successfully!');
        setSkillsModal (false);
        getUserSkills ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleCancelSkillsModal = () => {
    setSkillsModal (false);
  };

  const handleChange = values => {
    // console.log(values);
    setSkillsArr (values);
  };

  const handleNewSkillCategory = () => {
    setNewSkillCategoryModal (true);
  };

  const handleAddNewSkillCategory = async () => {
    // console.log("skill category!");
    setLoadingCategory (true);

    try {
      const response = await userService.addNewSkillToList (newSkillCategory);
      if (response) {
        setLoadingCategory (false);
        setNewSkillCategoryModal (false);
        setNewSkillCategory ('');
        message.success ('New skill added to skill category!');
        getAllCategory ();
      }
    } catch (err) {
      console.log (err);
      setLoadingCategory (false);
      const msg =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString ();
      message.error (msg);
    }
    // setTimeout(()=>{
    //   console.log("added cateogry : ",newSkillCategory);
    //   setLoadingCategory(false);
    //   setNewSkillCategoryModal(false);
    // },3000);
  };

  const handleNewSkillCategoryCancel = () => {
    setNewSkillCategoryModal (false);
  };

  const handleUserInterests = () => {
    setAddInterestModal (true);
  };

  const cancelUserInterestModal = () => {
    setAddInterestModal (false);
  };

  const handleUserInterestSubmit = async () => {
    // console.log("adding interests");

    setLoadingInterest (true);
    try {
      const response = await userService.updateDetailsByAdmin ({
        _id: currentUser.id,
        interestedIn: interestArr,
      });
      // console.log (response.data);
      if (response) {
        setLoadingInterest (false);
        message.success ('Updated your interests Successfully!');
        setAddInterestModal (false);
        getUserInterests ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const showJoinAsDeveloperModal = () => {
    setJoinModal (true);
  };

  const createNewNotification = async post => {
    try {
      const notificationRes = await userService.createNewNotification ({
        notification: `New Developer Request By ${currentUser.username} for ${post}`,
        user: '612c663a0e4bbd453837cf39',
      });
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'unable to send notification!'
      );
    }
  };

  const handleJoinModalSubmit = async () => {
    // console.log ('submitting!');
    const data = {
      requestBy: currentUser.id,
      availableTime: availableTime,
      joiningDate: joiningDate,
      description: description,
      workAs: workAs,
    };

    // console.log("Join Data : ",data);
    setLoadingJoin (true);
    try {
      if (!workAs || !description || !availableTime || !joiningDate) {
        message.error ('Please fill all the fields!');
        setLoadingJoin (false);
        return;
      } else {
        const response = await userService.makeNewDeveloperRequest (data);
        createNewNotification (workAs);
        // console.log(response.data);
        if (response) {
          message.success (
            'We have accepted your joining request. we will get back to you soon!'
          );
          setAvailableTime ('');
          setJoiningDate ('');
          setDescription ('');
          setWorkAs ('');
          setLoadingJoin (false);
          setJoinModal (false);
        }
      }
    } catch (err) {
      console.log (err);
      setLoadingJoin (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleCheckin = async () => {
    let today = new Date ();
    // console.log (today);
    try {
      let data = {
        attendanceUser: currentUser.id,
        checkin: today.toISOString (),
        checkInDate: currentDate,
      };
      // console.log (data);
      const response = await orderService.createNewAttendance (data);
      // console.log (response);
      if (response) {
        message.success ('Checked in Successfully!');
        getTodaysAttendance ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const handleCheckout = async () => {
    // console.log ('checkout');
    let today = new Date ();

    // console.log (today.getHours ());
    let due = new Date (currentCheckinData.checkin);
    // console.log (due.getHours ());
    var diffMs = today - due;

    var diffHrs = Math.floor (diffMs % 86400000 / 3600000);
    // console.log (diffHrs);

    setTotalWorkHours (diffHrs);

    try {
      const response = await orderService.updateAttendance ({
        userId: currentUser.id,
        checkInDate: currentDate,
        updatedData: {
          checkout: today.toISOString (),
          workedHours:diffHrs
        },
      });
      if (response) {
        // console.log (response.data);
        message.success ('Checked Out successfully!');
        getTodaysAttendance ();
        setIsUserCheckedOut (true);
        setEnableCheckOut (false);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };


  return (
    <div className="container profile">
      <div>
        <div className="profileHeaderBadge">
          <div><h2>Profile</h2></div>
          {currentUser &&
            currentUser.roles[0] === 'ROLE_USER' &&
            currentUser.subscriptionType[0] &&
            currentUser.subscriptionType[0].name
            ? <div className="subscriptionTypeBadge">
                {currentUser.subscriptionType[0].name}
              </div>
            : null}
          <div className="profileBtnGroup">
            <Button type={'primary'} onClick={showPasswordModal}>
              <KeyOutlined /> Change Password
            </Button>

            {currentUser &&
              currentUser.roles[0] === 'ROLE_USER' &&
              <Button
                type={'primary'}
                onClick={showJoinAsDeveloperModal}
                style={{marginLeft: '1%'}}
              >
                <UsergroupAddOutlined /> Join as Developer
              </Button>}

            {currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') && 
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              enableCheckIn &&
              <>
              <Button
                type={'primary'}
                onClick={handleCheckin}
                style={{marginLeft: '1%'}}
              >
                <LoginOutlined /> Check-In
              </Button>
              </>

              
              }

            {currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') &&
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              !enableCheckIn &&
              <Button
                type={'primary'}
                onClick={handleCheckout}
                disabled={!enableCheckOut}
                style={{marginLeft: '1%'}}
              >
              <LogoutOutlined />Check-Out
              </Button>
              }
              { currentUser &&
              (!currentUser.roles.includes ('ROLE_USER') &&
                !currentUser.roles.includes ('ROLE_ADMIN')) &&
              !enableCheckIn && 
              <>
             { enableCheckOut ? 
                <>
                </>
                :
                <Tooltip  placement="bottomLeft" title={isAlreadyCheckedOut ? "You have already checked-out!" : "Can't check-out now!"}>
                <QuestionCircleOutlined style={{marginLeft:'10px',cursor:'pointer'}} />
                </Tooltip>}
              </>
              }
                

          </div>
        </div>
        <hr />

      </div>
      <div>
        <ProfilePicture userId={currentUser && currentUser.id} />
      </div>

      <div>
        <table className="table table-borderless table-striped">
          <thead>
            <tr>
              <th scope="row">Username</th>
              <td>{currentUser.username}</td>
            </tr>

          </thead>
          <tbody>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">
                {currentUser.name ? currentUser.name : currentUser.username}
              </th>
            </tr>
            <tr>
              <th scope="row">Id</th>
              <td>{currentUser.id}</td>
            </tr>
            <tr>
              <th scope="row">DOB</th>
              <td>{currentUser.dob ? currentUser.dob : 'N/A'}</td>
            </tr>
            <tr>
              <th scope="col">Contact No.</th>
              <td scope="col">
                {currentUser.contact ? currentUser.contact : 'N/A'}
              </td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td>{currentUser.address ? currentUser.address : 'N/A'}</td>
            </tr>
            <tr>
              <th scope="col">Gender</th>
              <td scope="col">
                {currentUser.gender ? currentUser.gender : 'N/A'}
              </td>
            </tr>

            <tr>
              <th scope="row">Email</th>
              <td>{currentUser.email}</td>
            </tr>

            <tr>
              <th scope="row">Roles</th>
              <td>
                {currentUser.roles.map ((item, key) => {
                  return <span className="badge mx-2" key={key}>{item}</span>;
                })}
              </td>
            </tr>
            
            {currentUser.skills && currentUser.skills.length > 0 &&
              <tr>
              <th scope="row">Skills</th>
              <td>
                {currentUser.skills.map ((item, key) => {
                  return <span className="badge mx-2" key={key}>{item}</span>;
                })}
              </td>
            </tr>
            }
           
          </tbody>
        </table>
        <button className="btn btn-primary" onClick={handleModal}>Edit</button>
        {currentUser.roles[0] === 'ROLE_ADMIN' ||
          currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
          currentUser.roles[0] === 'ROLE_REMOTEDEVELOPER'
          ? <button
              className="btn btn-primary"
              style={{marginLeft: '2%'}}
              onClick={handleSkillsModal}
            >
              Add Skills
            </button>
          : null}
        {currentUser.roles[0] === 'ROLE_ADMIN'
          ? <button
              className="btn btn-primary"
              style={{marginLeft: '2%'}}
              onClick={handleNewSkillCategory}
            >
              Add Skill Category
            </button>
          : null}
        {currentUser.roles[0] === 'ROLE_USER'
          ? <button
              className="btn btn-primary"
              style={{marginLeft: '2%'}}
              onClick={handleUserInterests}
            >
              Add Interests
            </button>
          : null}

      </div>

      <MyModal show={modalToggle} modalClosed={handleModal}>
        <div id="closeIcon" onClick={handleModal}><CloseCircleOutlined /></div>
        <UpdateDetailsForm currentUser={currentUser} />

      </MyModal>

      <Modal
        title="Change Password"
        visible={visible}
        onOk={handlePasswordOk}
        okText="Change"
        confirmLoading={passwordConfirmLoading}
        onCancel={handlePasswordCancel}
      >
        <form>
          <input
            type="password"
            name="password"
            value={password}
            onChange={e => {
              setPassword (e.target.value);
            }}
            className="form-control mb-4"
            placeholder="Enter New Password"
          />
          <input
            type="password"
            name="ConfirmPassword"
            value={confirmPassword}
            onChange={e => {
              setConfirmPassword (e.target.value);
            }}
            className="form-control"
            placeholder="Confirm Your Password"
          />
        </form>
      </Modal>

      <Modal
        title="Add New Skills"
        visible={skillsModal}
        onOk={handleAddSkills}
        okText="Add"
        confirmLoading={skillsLoading}
        onCancel={handleCancelSkillsModal}
      >

        <Select
          mode="multiple"
          allowClear
          showSearch
          defaultValue={userSkillsArr}
          filterOption={(input, option) =>
            option.children.toLowerCase ().indexOf (input.toLowerCase ()) >= 0}
          style={{width: '100%'}}
          placeholder="Please select"
          onChange={handleChange}
        >
          {skillsCategoryArr.map (item => {
            return (
              <Option value={item.name} key={item._id}>{item.name}</Option>
            );
          })}
        </Select>

      </Modal>

      <Modal
        title="Add New Skill to Category"
        visible={newSkillCategoryModal}
        onOk={handleAddNewSkillCategory}
        okText="Add"
        confirmLoading={loadingCategory}
        onCancel={handleNewSkillCategoryCancel}
      >

        <input
          type="text"
          name="skillCategory"
          value={newSkillCategory}
          onChange={e => {
            setNewSkillCategory (e.target.value);
          }}
          className="form-control mb-4"
          placeholder="Enter New Skill Category"
        />
      </Modal>

      <Modal
        title="Add Your Interests"
        visible={addInterestModal}
        onOk={handleUserInterestSubmit}
        okText="Add"
        confirmLoading={loadingInterest}
        onCancel={cancelUserInterestModal}
      >

        <Select
          mode="multiple"
          allowClear
          showSearch
          defaultValue={defaultUserInterest}
          filterOption={(input, option) =>
            option.children.toLowerCase ().indexOf (input.toLowerCase ()) >= 0}
          style={{width: '100%'}}
          placeholder="Please select"
          onChange={handleChangeInterest}
        >
          {skillsCategoryArr.map (item => {
            return (
              <Option value={item.name} key={item._id}>{item.name}</Option>
            );
          })}
        </Select>
      </Modal>

      <Modal
        title="Make your request to Join Us"
        visible={joinModal}
        onOk={handleJoinModalSubmit}
        okText="Request"
        confirmLoading={loadingJoin}
        onCancel={() => {
          setJoinModal (false);
        }}
      >
        <form>
          <div>
            <label>Want to Work as :</label>
            <input
              type="text"
              name="availableTime"
              value={workAs}
              onChange={e => {
                setWorkAs (e.target.value);
              }}
              className="form-control mb-4"
              placeholder="e.g Full Stack Developer"
            />
          </div>

          <div>
            <label>Your Available time:</label>
            <input
              type="text"
              name="availableTime"
              value={availableTime}
              onChange={e => {
                setAvailableTime (e.target.value);
              }}
              className="form-control mb-4"
              placeholder="e.g 9 AM to 6 PM"
            />
          </div>

          <div>
            <label>Joining Date:</label>
            <input
              type="date"
              name="joiningDate"
              value={joiningDate}
              onChange={e => {
                setJoiningDate (e.target.value);
              }}
              className="form-control mb-4"
            />
          </div>

          <div>
            <label>Description:</label>
            <TextArea
              showCount
              maxLength={255}
              style={{height: 120}}
              onChange={e => {
                setDescription (e.target.value);
              }}
            />
          </div>

        </form>

      </Modal>

     


      <Modal
        title="Checkout Summary"
        visible={isUserCheckedOut}
        onCancel={() => setIsUserCheckedOut (false)}
        footer={false}
      >
        <div style={{fontStyle: 'normal'}}>

          <h3 style={{color: 'green', marginBottom: '5px'}}>
            You Spent {totalWorkHours} Hours Today!
          </h3>
          <div style={{fontSize: '14px', color: 'gray', textAlign: 'justify'}}>
            This is the duration from your checkin and checkout timing.
          </div>

        </div>
      </Modal>

      {isUserCheckedOut &&
        <Confetti
          recycle={isUserCheckedOut}
          numberOfPieces={300}
          width={1200}
          height={1200}
        />}

      {errorMessage ? <Message type="error" message={error} /> : null}
      {successMessage
        ? <Message type="success" message="Password Updated Successfully!" />
        : null}
    </div>
  );
};

export default Profile;
