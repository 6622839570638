import React, {  useState } from "react";
import {FileUploadContainer,Layout,Text,UploadButton} from './file-upload.styles';
import Googleservices from '../../services/google.services.js';
import './fileUploadComponent.css';
import InputButton from "../Custom-Input/Input-Button";
import { Spin,message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import fileUpload from '../../assets/fileUpload.png';
import fileUploadIcon from '../../assets/uploadIcon.png';
import orderService from "../../services/order.service";
const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 
const FileUpload = (props) =>{
   
    const [selectedfile,setselectedfile] = useState(null);
    const [result,setresult] = useState({});
    const [resultstate,setresultstate] = useState(false);
    const [selectedFileName,setSelectedFileName] = useState("");
    const [loading,setLoading] = useState(false);
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    var currentdate = new Date();
    var datetime =  currentdate.getDate() + "/" + (Number(currentdate.getMonth())+1 )
    + "/" + currentdate.getFullYear() + " at " 
    + currentdate.getHours() + ":" 
    + currentdate.getMinutes() + ":" + currentdate.getSeconds();


    const handleDrop = (e) =>{
        // console.log(e);
        e.preventDefault();
        e.stopPropagation();
    }

    const upload = async() =>{
        // console.log(size,name,type);
        // const data = {
        //     imageurl:imageurl,
        //     size:size,
        //     type:type,
        //     name:name
        // }

        setLoading(true);
        let changedName = "Uploaded on "+datetime + " "+ name + " "+ email + " "
        // console.log("FINAL NAME : ",changedName);
        changedName = changedName.replace(".","_");
        const formData = new FormData();
        // console.log(selectedfile);
        formData.append("changedName",changedName)

        if(selectedfile){
            for(let i=0;i<selectedfile.length;i++){
                formData.append('multiples',selectedfile[i],selectedfile[i].name);
            }
        }
        // console.log(formData)
       try{
        const response =   await Googleservices.uploadfile(formData).then(response => {return response});
        
        if(response){
            setLoading(false);
            message.success("Successfully Uploaded!");
        }
        setresult(response);
        // console.log(result);
        setresultstate(true);
        setselectedfile(null);
        // setSelectedFileName(null);
       }catch(err){
           console.log(err);
           setLoading(false);
           message.error(err.message ? err.message : "Something Went Wrong !");
       }
    }
    const handleDragOver = (e)=>{
        e.preventDefault();
        e.stopPropagation();
    }

    const handleDropping = (e)=>{
        e.preventDefault();
        e.stopPropagation();
        // console.log(e.dataTransfer.files)
        setselectedfile(e.dataTransfer.files);
        // setSelectedFileName(e.dataTransfer.files[0].name);
    }

    const handlesubmit=(e) =>{
        // var file = e.target.files[0];
        // var formdata = new FormData();
        // let fileName = name + " "+ email + " " + datetime;
    
        // formdata.append("file", file);
        // formdata.append("name", fileName);
        e.preventDefault();
        e.stopPropagation();
        // console.log(e.target.files)

        setselectedfile(e.target.files);
        // setSelectedFileName(e.target.files[0].name);

    }
    return(
       <div className="fileUploadComponent">
           <div>
            <img src={fileUpload} alt="codersarts" className="upload-vector"/>
           </div>
           <div>
           <Layout>
            <h2 className="upload-heading">Upload Files To Codersarts</h2>
            <div>
                <form>
                    <div className="form-group">
                        <label>Name</label>
                        <input  placeholder="Enter Your Full Name" type="text" className="form-control" value={name} name="name" onChange={(e)=>{setName(e.target.value)}}/>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <input type="email" placeholder="example@codersarts.com" className="form-control" value={email} name="email" onChange={(e)=>{setEmail(e.target.value)}}/>
                    </div>
                </form>
            </div>
                <FileUploadContainer  onDragEnter={(e)=> handleDrop(e)} onDragLeave={e=>handleDrop(e)} onDragOver={e=>handleDragOver(e)} onDrop={(e)=> handleDropping(e)}>
                
                    <img src={fileUploadIcon} className="upload-icon" alt="upload" />
                    <InputButton multiple={true} handleChange={(e)=>handlesubmit(e)}/>
                    {/* <Text>{selectedFileName  ? `Selected File : ${ selectedFileName} `  : 'Drag Files Here'} </Text> */}
                </FileUploadContainer>
                
                <button className="pay-btn"  disabled={name==="" || email==="" || selectedfile===null}
                style={{marginLeft:'42%',marginTop:'1%',width:'15vw'}} onClick={()=>upload()}>{loading ? <Spin indicator={spinIcon} /> : 'Upload'}</button>
                </Layout>
           </div>
       </div>
    )
}
export default FileUpload;