import React,{useState,useEffect} from 'react'
import userServices from '../../../services/user.service';
import {message,Table,Select,Modal,Menu,Dropdown,Space} from 'antd';
import {EyeOutlined,MoreOutlined,UsergroupAddOutlined} from '@ant-design/icons';
import {Link } from 'react-router-dom';
import moment from 'moment';

const {Option} = Select;

function DevRequest() {
    const [allDevRequest,setAllDevRequest] = useState([]);
    const [filteredArr,setFilteredArr] = useState([]);
    const [isMakeEmployeeModal,setIsMakeEmployeeModal] = useState(false);
    const [loading,setLoading] = useState(false);
    const [requestRole,setRequestRole] = useState("EMPLOYEE");
    const [currentRequestUser,setCurrentRequestUser] = useState("");
    


    const handleMakeEmployeeModal = (username)=>{
        setIsMakeEmployeeModal(true);
        setCurrentRequestUser(username);

    }

    const handleChange = (value)=>{
        setRequestRole(value);
    }

     
    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Request By',
            key: 'requestBy',
            render:(record)=>{
                return(<b><Link to={`/profile/manage-users/view/${record.userId}`}>{record.requestBy}</Link></b>)
            }
        },
        {
            title: 'Joining Date',
            dataIndex: 'joiningDate',
            key: 'joiningDate',
        },
        {
            title: 'Available Time',
            dataIndex: 'availableTime',
            key: 'availableTime',
        },
        {
            title: 'Work as',
            dataIndex: 'workAs',
            key: 'workAs',
        },

        {
            title: 'Description',
            key: 'description',
            render:(record)=>{
                return(<div>{record.description.slice(0,30)}...</div>)
            }
        },
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
                <Space size="middle">
                <Dropdown overlay={
                  <Menu style={{marginLeft:'-25%'}}>
                   <Menu.Item key="1">
                    <EyeOutlined /> <Link to={`/profile/single-developer-request-details/${record.id}`} target="_blank" /> View Details
                   </Menu.Item>
                   <Menu.Item key="2" onClick={()=>{handleMakeEmployeeModal(record.requestBy)}}>
                    <UsergroupAddOutlined /> Make Developer
                   </Menu.Item>
                </Menu>
                }>
                <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
                </Dropdown>

                
              </Space>
              )
            }
        
       

    ]


    const handleSearchChange = (item)=>{
        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allDevRequest.length >0){
                filteredData= allDevRequest.filter((request)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return request.workAs && request.workAs.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }


    const handleMakeEmployeeCancel = ()=>{
        setIsMakeEmployeeModal(false);
        setRequestRole("");
    }

    const handleMakeEmployeeSubmit = async ()=>{
        // console.log("request user : ",currentRequestUser);
        // console.log("request role : ",requestRole);
        setLoading(true);
        try{
            let response = await userServices.updateRole(currentRequestUser,requestRole);
            console.log(response);
            if(response){
                message.success(`${currentRequestUser} is now a ${requestRole}`);
                setLoading(false);
                setIsMakeEmployeeModal(false);
             }
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message :'Unable to make developer');
        }
    }
    useEffect(()=>{
        let isMount = true;
        const getAllDevRequest = async ()=>{
            try{
                const response = await userServices.getAllDevRequest();
                console.log(response.data);
                let data = []
                if(response.data && isMount){
                    response.data.map((d)=>{
                        let finalDate="N/A"
                        if(d.createdAt){
                            const date = new Date(d.createdAt);
                            let dd = date.getDate();
                            let mm = date.getMonth() + 1;
                            let yyyy = date.getFullYear()
                            let hh = date.getHours();
                            let minutes = date.getMinutes();
                            let ss = date.getSeconds();
                          finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                        }
                        let modifiedData = {
                            id:d._id,
                            createdAt:finalDate,
                            requestBy:d.requestBy[0].username,
                            userId:d.requestBy[0]._id ? d.requestBy[0]._id : d.requestBy[0],
                            sortDate:d.createdAt,
                            joiningDate:d.joiningDate,
                            description:d.description,
                            workAs:d.workAs,
                            availableTime:d.availableTime
                        }

                        data.push(modifiedData);
                        return null;
                
                    } )
                    setAllDevRequest(data);
                }
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Something went wrong!');
            }
        }

        getAllDevRequest();

        return ()=>{
            isMount = false
        }
    },[])
  return (
    <div className='developerRequest'>
     <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>Developer Request</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Post" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}} /> 
                </div>
    </div>
    <Table dataSource={filteredArr.length > 0 ?  filteredArr : allDevRequest} columns={columns} />
    
    <Modal 
            title={"Make Developer"}
            visible={isMakeEmployeeModal} 
            onOk={handleMakeEmployeeSubmit} 
            onCancel={handleMakeEmployeeCancel}
            confirmLoading={loading}
         >
             <form>
             <div className="form-group mb-4">
             <label><h6><b>Choose Developer Type :</b></h6></label>
             <br />
             <Select defaultValue={requestRole} style={{ width: '100%' }} onChange={handleChange}>
                <Option value="EMPLOYEE">EMPLOYEE</Option>
                <Option value="REMOTEDEVELOPER">REMOTEDEVELOPER</Option>
            </Select>
             </div>
             </form>
        </Modal>
    </div>
  )
}

export default DevRequest