import React,{useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import front from './img/front-img.jpg';
import './Landing.css';
import userService from '../../services/user.service';
import OrderService from '../../services/order.service';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Card,Modal,message,Input, Button} from 'antd';
// import programming from '../../assets/coding.png';
// import web from '../../assets/web.png';
// import mobile from '../../assets/mobileApps.png';
// import database from '../../assets/database.png';
// import machine from '../../assets/brain.png';
// import cloud from '../../assets/cloud.png';
import developer from '../../assets/codingMan.jpg';
import classroom from '../../assets/classroom.jpg';
import meeting from  '../../assets/meeting.png';
import booking from  '../../assets/booking.png';
import pay from  '../../assets/pay.png';
import easyContact from  '../../assets/easyContact.png';
import contactForm from '../../assets/contactForm.png';
import logo from '../../assets/mainLogo.png';
import {DollarCircleOutlined,ClockCircleOutlined, UsergroupAddOutlined} from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import LoginPopup from '../Profile/CreateOrderPublic/LoginPopup';
import { loginFromCreateOrder } from "../../actions/auth";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import {history} from '../../helpers/history';
// const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 

const { TextArea } = Input;
function SampleNextArrow(props) {
    
    const { className, style, onClick } = props;

    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "gray",borderRadius:'100px' }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "gray",borderRadius:'100px' }}
        onClick={onClick}
      />
    );
  }

const Landing = ()=>{
    const { user: currentUser } = useSelector((state) => state.auth);
    const [heading,setHeading] = useState(null);
    const [details,setDetails] = useState(null);
    const [visiterName,setVisiterName] = useState("");
    const [visiterEmail,setVisiterEmail] = useState("");
    const [visiterMsg,setVisiterMsg] = useState("");
    const [allSessions,setAllSessions] = useState([]);
    const [loading,setLoading] = useState(false);
    const [loginModal,setLoginModal] = useState(false);
    const [bookingModal,setBookingModal] = useState(false);
    const [currentBookingSessionId,setCurrentBookingSessionId] = useState("");
    const [bookingDate,setBookingDate] = useState("");
    const [bookingTimezone,setBookingTimezone] = useState("");
    const [bookingDetails,setBookingDetails] = useState("");
    const [allServices,setAllServices] = useState([]);
    const [captchaValue,setCaptchaValue] = useState("");
    const [joinModal,setJoinModal] = useState(false);
    const [loadingJoin,setLoadingJoin] = useState(false);
    const [availableTime,setAvailableTime] = useState("");
    const [joiningDate,setJoiningDate] = useState("");
    const [description,setDescription] = useState("");
    const [workAs,setWorkAs] = useState("");

    const dispatch = useDispatch();



    const getAllSessions = async ()=>{
        try{
            const sessions = await OrderService.getAllSessions();
            // console.log("SESSIONS : ",sessions.data);
            setAllSessions(sessions.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : ' Unable to load sessions info!');
        }
    }

    const getAllServices = async ()=>{
        try{
            const services = await OrderService.getAllServicesForHome();
            // console.log("Services : ",services.data);
            setAllServices(services.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : ' Unable to load services info!');
        }
    }
    useEffect(()=>{
        getAllSessions();
        getAllServices();
        loadCaptchaEnginge(6,"white","black");
    },[])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />
      };


    const getHomePageDetails = async ()=>{
        try{
            const response = await userService.getHomeDetails();
            setHeading(response.data[0].heading);
            setDetails(response.data[0].details);
            
        }catch(err){
            console.log(err);
        }
    }
    useEffect(()=>{
        getHomePageDetails();
    },[]);

    const showJoinAsDeveloperModal = ()=>{
        if(currentUser && currentUser.roles[0]==='ROLE_USER'){
            setJoinModal(true)
        }
        if(!currentUser){
            history.push('/login'); 
        }

        if(currentUser && currentUser.roles[0]!=='ROLE_USER'){
            message.error(`Your role ${currentUser.roles[0]} is not eligible as a developer!`);
        }
       
      }


    
  const createNewNotification = async (post)=>{
    try{
      const notificationRes = await userService.createNewNotification(
       {notification:`New Developer Request By ${currentUser.username} for ${post}`,
       user:'612c663a0e4bbd453837cf39'})
        
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : 'unable to send notification!');
    }
   }
 
   const handleJoinModalSubmit = async ()=>{
    //  console.log("submitting!");
     const data = {
       requestBy:currentUser.id,
       availableTime:availableTime,
       joiningDate:joiningDate,
       description:description,
       workAs:workAs
 
     }
 
     // console.log("Join Data : ",data);
     setLoadingJoin(true);
     try{
     if(!workAs|| !description || !availableTime || !joiningDate){
       message.error("Please fill all the fields!");
       setLoadingJoin(false);
       return;
      }else{
       const response = await userService.makeNewDeveloperRequest(data);
       createNewNotification(workAs);
       // console.log(response.data);
      if(response){
       message.success('We have accepted your joining request. we will get back to you soon!');
       setAvailableTime("");
       setJoiningDate("");
       setDescription("");
       setWorkAs("")
       setLoadingJoin(false);
       setJoinModal(false);
      }
     }
     }catch(err){
       console.log(err);
       setLoadingJoin(false);
       message.error(err.message ? err.message : 'Something went wrong!');
       
     }
     
   }

    const handleContactSubmit = async (e)=>{
        // console.log({name:visiterName,email:visiterEmail,message:visiterMsg});
        e.preventDefault();
        if (validateCaptcha(captchaValue,false)==true){
            try{
                const response = await OrderService.createContactUsFormData({name:visiterName,email:visiterEmail,message:visiterMsg})
                // console.log(response.data);
                if(response.data){
                    message.success("Message Submitted, We will contact you soon!");
                    setVisiterName("");
                    setVisiterEmail("");
                    setVisiterMsg("");
                    loadCaptchaEnginge(6,"white","black");
                    setCaptchaValue("");
                }
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : 'Unable to submit contact form!');
            }


        }else{
            message.error("Entered Wrong Captcha!");
        }
        
       
    }


    const handleLogin =  (values)=>{
        setLoading(true);

         dispatch(loginFromCreateOrder(values.username, values.password))
        .then(() => {
          setLoginModal(false);
          setLoading(false);
          message.success("Logged in Successfully!");
          
        })
        .catch(() => {
          setLoading(false);
          message.error("Unable to Login !");
        });
    }

    const handleModalCancel = ()=>{
        setLoginModal(false);
     }

     const handleBooking = (id)=>{
        setCurrentBookingSessionId(id);
         setBookingModal(true);
     }


     const bookNow = async ()=>{
         setLoading(true);
         try{
            const response = await OrderService.createNewBooking({
                booking:currentBookingSessionId,
                bookingBy:currentUser.id,
                date:bookingDate,
                details:bookingDetails,
                timezone:bookingTimezone,
            })
            // console.log(response.data);
            setLoading(false);
            setBookingModal(false);
            message.success("Booking Confirmed!");
            
            
         }catch(err){
             console.log(err);
             message.error(err.message ? err.message : 'Unable to book session!');
             setLoading(false);
         }
     }


    return(
        <div className='landingPage'>
        <div className="container back-blue" style={{height:'100vh'}}>
            <div className="row">
                <div className="col-md-6 col-sm-12 data-section mt-10">
                    <div className="heading">
                       <h1 className="display-4 text-white">{heading}</h1> 
                    </div>
                    <div className="sub">
                    <p>
                    {details}
                    </p> 
                    </div>

                    {currentUser ? null:
                    <div className="btn-section">
                        <Link to="/auth/signin" className="btn-fill">Sign In</Link>
                        <Link to="/auth/signup" className="btn-blue">Sign up</Link>
                       <br /><br /><br />
                    </div>
                    }
                    <div>
                    <Link to="/get-help" id="link-hover" >Get Help Now</Link> <br />
                    <Link to="/codersarts-payment" id="link-hover"> Pay Now</Link>
                    </div>
                    <div>
                    <Button className="joinAsBtn" type={'primary'} onClick={showJoinAsDeveloperModal}><UsergroupAddOutlined /> Join as Developer</Button>
                    </div>
                        
                    
                    
                </div>
                <div className="col-md-6 col-sm-12 img-section mt-10">
                    <div>
                        <img src={front} alt="woman" className="front"/>
                    </div>
                </div>
            </div>
        </div>
        <div className='ourServices'>
            <div>
                <h1 className='service-heading'>Our Services</h1>
            </div>
            <div className='servicePara'>
            We offer solutions for all  whether you’re a student, developer or Company.
            </div>

            {/* slider */}
            {/* <h3>For Students</h3> */}
            <Slider {...settings}>

                    {allServices && allServices.length >0 &&

                    allServices.map((service,i)=>{
                        return(
                    <div className="service-card-wrapper" key={i}>
                        <Card className="service-card">
                        <div>
                        <img src={service.image} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>{service.name}</h5>
                        {service.details.slice(0,200)}...
                        </div>
                        <div className="mt-3">
                        {service.readMoreLink && 
                        <a href={service.readMoreLink} target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        }
                        
                        </div>
                       
                        </Card>
                    </div>
                        )
                    })


                    }



                    {/* <div className="service-card-wrapper">
                        <Card className="service-card">
                        <div>
                        <img src={programming} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Programming</h5>
                        We code from scratch to complex level.
                        Each code we complete here is original.
                        We do all types of programming languages Python, 
                        Java, C,C++, C#, PHP, J2EE and more as per user need.
                        </div>
                        <div className="mt-3">
                        <a href="https://www.codersarts.com/computer-science-assignment-portal" target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                       
                        </Card>
                    </div>
                    <div className="service-card-wrapper">
                        <Card className="service-card">
                        <div>
                        <img src={web} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Web Designing</h5>
                        Form Simple webpage to complex webpage. We  make mobile friendly,
                        full responsive and stunning websites using technology JSP, Servlet,
                        Web Services, PHP, C#, asp.net, VB.net, Angular JS 1.x or 2 or 4,Node js,React js etc.
                        </div>
                        <div className="mt-3">
                        <a href="https://www.codersarts.com/web-development"  target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                       
                        </Card>
                    </div>
                    <div className="service-card-wrapper">
                        <Card className="service-card">
                        <div>
                        <img src={mobile} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Mobile Apps</h5>
                        Simple App to large commercial app that manage you business data ,
                        college final year projects application, Sell and promoting your  app on google store
                        </div>
                        <div className="mt-3">
                        <a href="https://www.codersarts.com/android-development"  target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                        </Card>
                    </div>
                    <div className="service-card-wrapper">
                        <Card className="service-card">
                        <div>
                        <img src={database} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Database</h5>
                        Creating database for your company, SQL fix query, PLSQL, 
                        Advance concepts like performance Related features such as Query Optimiser. 
                        Database like Oracle, MySQl, MongoDB, SQL Server ,Sql Lite etc.
                        </div>
                        <div className="mt-3">
                        <a href="https://www.codersarts.com/database-assignment-help"  target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                       
                        </Card>
                    </div>
                    <div className="service-card-wrapper">
                        <Card className="service-card">
                        <div>
                        <img src={machine} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Machine Learning & AI</h5>
                        Our expert team helps in creating a system that 
                        can assist you with critical business decisions.
                        you will get assistance in Machine Learning, NLP, Deep Learning, 
                        Keras, TensorFlow, OpenCV, Computer Vision, CNN, and in many other 
                        ML & AI Areas.
                        </div>
                        <div className="mt-3">
                        <a href="https://www.sofstack.com/machine-learning-services"  target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                       
                        </Card>
                    </div>
                    <div className="service-card-wrapper">
                    <Card className="service-card">
                        <div>
                        <img src={cloud} className='service-icon' />
                        </div>
                        <div className='service-desc'>
                        <h5>Cloud & DevOps</h5>
                        Improve agility and time to market, drive 
                        efficiency with Cloud & DevOps solutions for your business.
                        Cloud & DevOps to help businesses experience enhanced security 
                        and faster performance in applications and tools.
                        </div>
                        <div className='mt-3'>
                        <a href="https://www.sofstack.com/cloud-solutions"  target={'_blank'} className='read-more-btn'>
                            Read More
                        </a>
                        </div>
                        
                        </Card>
                    </div> */}
            </Slider>

        </div>
        <div className="hireUs">
                <div className='hireUsImg'>
                    <img src={developer} alt="img" className="developerImg" />
                </div>
                <div className='hireUsData'>
                    <h1> Want to Hire Dedicated Developers for Your Project? </h1>
                    
                    <div className='hireUsPara'>
                    Hire Sofstack dedicated development teams to your development work, 
                    Set up your Offshore Development Center for start-ups, mid and large scale businesses.
                   </div>
                    
                    <div className='mt-4'>
                    <a href="https://www.sofstack.com/dedicated-development-team" target={'_blank'} className='read-more-btn'>View More</a>
                    </div>
     
                </div>
                

        </div>

       


        <div className="bookSession">
            <div className='bookSessionData'>
                <h1>Book 1-on-1  Session With Expert</h1>
                <center>
                <div className="bookSessionPara">
                We make it as easy as possible for you to get in touch with a 
                vetted and experienced mentor. Just make a choice, book and get in touch
                </div>
                </center>
            </div>

        </div>

        <div className='mentorship'>
            <div  className='mentorShipData'>
                <h2>Receive Personal Mentorship with Easy Steps</h2>
                <center>
                <div className='mentorShipPara'>
                We make it as easy as possible for you to get in touch with a 
                vetted and experienced mentor. Just make a choice, book and get in touch.
                </div>
                </center>
            </div>

            <div className='mentorShipCardGroup'>
                <Card className="mentorship-card">
                    <div>
                    <img src={meeting} className='mentorship-icon' />
                    </div>
                    <div className='mentorship-desc'>
                    <h5>Browse Session</h5>
                        <div className='mentorshipParaSmall'>
                        Select the available sessions based on you needs.
                        </div>
                    </div>
                </Card>

                <Card className="mentorship-card">
                    <div>
                    <img src={booking} className='mentorship-icon' />
                    </div>
                    <div className='mentorship-desc'>
                    <h5>Quick Booking</h5>
                        <div className='mentorshipParaSmall'>
                        Book a session by clicking on book button of the  interested service.
                        </div>
                    </div>
                </Card>

                <Card className="mentorship-card">
                    <div>
                    <img src={pay} className='mentorship-icon' />
                    </div>
                    <div className='mentorship-desc'>
                    <h5>Pay Safely</h5>
                        <div className='mentorshipParaSmall'>
                        Pay using secure payment options like PayPal and by other payment ways.
                        </div>
                    </div>
                </Card>

                <Card className="mentorship-card">
                    <div>
                    <img src={easyContact} className='mentorship-icon' />
                    </div>
                    <div className='mentorship-desc'>
                    <h5>Easy Contact</h5>
                        <div className='mentorshipParaSmall'>
                        Done! You're receiving all info you'll need for your session confirmation within 1 hour.
                        </div>
                    </div>
                </Card>
            </div>
        </div>

        <div className="ourSessions">
                <div  className='ourSessionHeading'>
                    <center>
                    <h2>Our Helpful Sessions for you</h2>
                    </center>
                </div>
                
                <div className="ourSessionsCards">
                   

                   {allSessions && allSessions.length > 0 && allSessions.map((s,i)=>{
                        return(
                    <Card className="session-card" key={i}>
                        <a href={s.readMoreLink ? s.readMoreLink:'/'} target={s.readMoreLink ? '_blank' : '_self'}>
                        <div>
                        <img src={s.image} className='session-card-icon' />
                        </div>
                        </a>
                        <div className='session-desc'>
                        <h5>{s.name}</h5>
                            <div className='sessionPara'>
                            {s.details.slice(0,75) + "..."}
                            </div>
                           {s.readMoreLink ? 
                            <a href={s.readMoreLink} target={'_blank'}><b>Read More</b></a>
                           :
                           null
                           }
                        </div>
                        <hr />
                        <div className="session-card-bottom">
                            <div className="session-bottom-item">
                            <ClockCircleOutlined />
                            <span>{s.duration}</span>
                            </div>

                            <div className="session-bottom-item">
                            <DollarCircleOutlined />
                            <span>{s.price}</span>
                            </div>

                            <div>
                                {currentUser ? 
                            <button 
                             disabled={loading ? true:false}
                            className="read-more-btn" 
                                onClick={()=>{handleBooking(s._id)}}>
                             Book Now
                             </button>
                                :
                                <button  disabled={loading ? true:false} className="read-more-btn" 
                                onClick={()=>{setLoginModal(true)}}>
                                 Book Now
                                 </button>
                                }
                            </div>
                        </div>
                    </Card>
                        )
                   })}
                   
                    
                </div>
        </div>

        <div className="contactForm">
            <div className="contactFormLeft">
                <h2>Contact Us</h2>
                <div className='contactFormArea'>
                    <form>
                        <input type="text" value={visiterName} onChange={(e)=>{setVisiterName(e.target.value)}} placeholder='Name' />
                        <input type="email" value={visiterEmail} onChange={(e)=>{setVisiterEmail(e.target.value)}} placeholder="Email" />
                        <textarea value={visiterMsg} onChange={(e)=>{setVisiterMsg(e.target.value)}} placeholder='Message'></textarea>
                        <div className="mt-3" style={{display:'flex'}}>
                        <LoadCanvasTemplate reloadColor="white" />
                        <input className="captchaInput" placeholder="Enter the Captcha Value " type="text" value={captchaValue} onChange={(e)=>{setCaptchaValue(e.target.value)}} />
                        </div>
                    
                    
                    </form>
                    <button disabled={visiterName ==="" || visiterEmail==="" || visiterMsg===""} onClick={(e)=>{handleContactSubmit(e)}} className='contactFormBtn'>Submit</button>
                </div>
            </div>
            <div className="contactFormRight">
                <img src={contactForm} alt="contact-us" className="contactFormImg" />
            </div>
        </div>

        <div className='classRoom'>
                <div class="classRoomImg">
                <img src={classroom} alt="classRoom" />
                </div>
            <div className="classRoomData">
                <h1>Codersarts Classroom Training</h1>
                <div className='classRoomPara'>
                Our Classroom Training program has been designed in  
                such a ways that you can easily crack job interview 
                and boosting knowledge at most top level.Learn all 
                the new rising technologies, programming, courses with 
                hands-on project and learning support, designed to help
                 you become an expert.Classroom helps students and 
                teachers organize assignments, boost collaboration, 
                and foster better communication.
                </div>
                <div className='mt-5'>
                <a href='https://www.training.codersarts.com/classroom-courses' target={'_blank'} className='read-more-btn'>View More</a>
                </div>
            </div>
        </div>

        <div className="landingPageFooter">
                    <center>
                    <div>
                        <img src={logo} alt="logo" className="landingFooterLogo"/>
                    </div>
                    </center>

                    <div className='footerContent'>
                        <div className='footerContentArea'>
                            <h3>About</h3>
                            <a href="https://www.codersarts.com/about" target={'_blank'}>About Us</a>
                            <a href="https://www.codersarts.com/how-we-work" target={'_blank'}>How we work</a>
                            <a href="https://www.codersarts.com/python" target={'_blank'}>Tutorial</a>
                            <a href="https://www.codersarts.com/enterprise" target={'_blank'}>Enterprise</a>
                        </div>
                        <div className='footerContentArea'>
                            <h3>Community</h3>
                            <a href="https://www.codersarts.com/blog" target={'_blank'}>Blog</a>
                            <a href="https://www.codersarts.com/forum" target={'_blank'}>Forums</a>
                            <a href="https://www.codersarts.com/news" target={'_blank'}>News</a>
                            <a href="https://www.codersarts.com/reviews" target={'_blank'}>Reviews</a>
                            
                        </div>
                        <div className='footerContentArea'>
                            <h3>Career</h3>
                            <a href="https://www.codersarts.com/jobs" target={'_blank'}>Jobs</a>
                            <a href="https://www.training.codersarts.com/internship"  target={'_blank'}>Internship</a>
                            <a href="https://www.training.codersarts.com" target={'_blank'}>Course Training</a>
                            <a href="https://www.codersarts.com/job-support-services" target={'_blank'}>Job Support</a>
                        </div>

                        <div className='footerContentArea'>
                            <h3>Contact Us</h3>
                            <div className='footerAddress'><b>Tel:</b> (+91) 0120  4118730 </div>
                            <div className='footerAddress'><b>Time :</b>   10 : 00  AM -  08 : 00 PM IST </div>
                            <div className='footerAddress'><b>Email:</b> contact@codersarts.com</div>
                            <div className='footerAddress'><b>Registered address:</b> G-69, Sector 63, Noida - 201301, India</div>
                        </div>
                    </div>


                   
                    <div className='footerSocialArea'>
                        <div className='footerSocialIcon'>
                        <a href="https://www.facebook.com/codersarts2017"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        </div>
                        <div className='footerSocialIcon'>
                        <a href="https://twitter.com/@CodersArts"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                        </div>
                        <div className='footerSocialIcon'>
                        <a href="https://www.youtube.com/channel/UC1nrlkYcj3hI8XnQgz8aK_g"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                        </div>
                        <div className='footerSocialIcon'>
                        <a href="http://linkedin.com/company/codersarts"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                        <div className='footerSocialIcon'>
                        <a href="https://www.instagram.com/codersarts/"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                        </div>
                    </div>

                    
                </div>


                <LoginPopup loading={loading} visible={loginModal} 
                    onLogin={handleLogin} 
                    onCancel={handleModalCancel} />
        <div className='booking-modal'>
        <Modal 
            title={"Book a Session"}
            visible={bookingModal} 
            onOk={bookNow} 
            onCancel={()=>{setBookingModal(false)}}
            confirmLoading={loading}
         >
             <form>
             <div className="form-group mb-4">
             <label><h6><b>Enter a Date :</b></h6></label>
               <input 
               type="Date" 
               className="form-control"
               value={bookingDate}
               onChange={(e)=>{setBookingDate(e.target.value)}}/>
             </div>

             <div className="form-group mb-4">
             <label><h6><b>Enter a Time with Timezone :</b></h6></label>
               <input 
               type="text" 
               value={bookingTimezone}
               placeholder="e.g 10 AM GMT+5"
               className="form-control"
               onChange={(e)=>{setBookingTimezone(e.target.value)}}/>
             </div>

             <div className="form-group mb-4">
             <label><h6><b>Enter a Description :</b></h6></label>
               <textarea
               className="form-control"
               value={bookingDetails}
               onChange={(e)=>{setBookingDetails(e.target.value)}}>
               </textarea>
             </div>
             </form>
        </Modal>

        <Modal
      title="Make your request to Join Us"
      visible={joinModal}
      onOk={handleJoinModalSubmit}
      okText="Request"
      confirmLoading={loadingJoin}
      onCancel={()=>{setJoinModal(false)}}
      
      >
        <form>
        <div>
          <label>Want to Work as :</label>
            <input 
            type="text" 
            name="availableTime" 
            value={workAs} 
            onChange={(e)=>{setWorkAs(e.target.value)}} 
            className="form-control mb-4" 
            placeholder="e.g Full Stack Developer" />
        </div>


        <div>
          <label>Your Available time:</label>
            <input 
            type="text" 
            name="availableTime" 
            value={availableTime} 
            onChange={(e)=>{setAvailableTime(e.target.value)}} 
            className="form-control mb-4" 
            placeholder="e.g 9 AM to 6 PM" />
        </div>
          
        <div>
          <label>Joining Date:</label>
          <input 
          type="date" 
          name="joiningDate" 
          value={joiningDate} 
          onChange={(e)=>{setJoiningDate(e.target.value)}} 
          className="form-control mb-4" 
          />
        </div>

        <div>
        <label>Description:</label>
        <TextArea showCount maxLength={255} style={{ height: 120 }} onChange={(e)=>{setDescription(e.target.value)}} />
        </div>

          
        </form>
 
      </Modal>



        </div>

        </div>
    )
}

export default Landing;