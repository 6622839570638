import React, { useState } from 'react'
import './ForgetPassword.css';
import avatar from '../../assets/user.png';
import emailIcon from '../../assets/emailIcon.png';
import lock from '../../assets/lock.png';
import userService from '../../services/user.service';
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { message, Modal, Spin } from 'antd';
import googleServices from '../../services/google.services';
import {history} from  '../../helpers/history';

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />;
function ForgetPassword() {
    const [email,setEmail] = useState("");
    const [emailError,setEmailError] = useState(false);
    const [emailErrMsg,setEmailErrMsg] = useState("");
    const [otpFromServer,setOtpFromServer] = useState("");
    const [loading,setLoading] = useState(false);
    const [userOTP,setUserOTP] = useState("");
    const [screen,setScreen] = useState(1);
    const [isShown,setIsShown] = useState(false);
    const [password,setPassword] = useState("");
    const [passwordErr,setPasswordErr] = useState(false);
    const [passwordErrMsg,setPasswordErrMsg] = useState("");

    const sendOTP = async (otp)=>{
      try{
        const data ={
            To:email,
            Subject:"OTP for Password Reset",
            Write:`Dear User, <br /> Your OTP is <h2>${otp}</h2> You can reset your password by entering this otp`,
            attachment:false,

        }
        const response = await googleServices.sendmail(data);
        // console.log(response);
            if(response){
                Modal.success(
               { 
                title: 'OTP Sent Successfully!',
                content: 'Please Check Your Mail and Enter the OTP!'
              });
            }
       
          }catch(err){
              console.log(err);
              const msg =
            (err.response &&
              err.response.data &&
              err.response.data.message) ||
              err.message ||
              err.toString();
              Modal.error(
                { 
                title: 'Something Went Wrong!',
                content: `${msg ? msg : 'OTP Could Not be Sent!'}`
              })
          }
        }
    

    const onChangeEmail = (e)=>{
      setEmail(e.target.value);
      if(!e.target.value.match('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$') && e.target.value!==""){
        setEmailError(true);
        setEmailErrMsg("Email is not valid!");
      }else{
        setEmailError(false);
        setEmailErrMsg("");
      }
    }

    const handleOtp = async ()=>{
      setLoading(true);
      try{
        let response = await userService.getOTPForPasswordResetFromEmail(email);

        // console.log(response);
        let otp = response.data.size.split("kb").shift();
        // console.log(otp);
        if(otp){
          sendOTP(otp);
          setLoading(false);
          setOtpFromServer(otp);
          setScreen(2);
        }
      }catch(err){
        console.log(err);
        const msg =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
          err.message ||
          err.toString();
          message.error(msg);
        setLoading(false);
      }
    }


    const verifyOTP = ()=>{
      // console.log(typeof(userOTP));
      // console.log(typeof(otpFromServer));
      // console.log(userOTP);
      // console.log(otpFromServer);

      if(Number(userOTP)===Number(otpFromServer)){
        // console.log("OTP is Correct");
        setScreen(3);
      }else{
        // console.log('otp is not correct' )
        Modal.error(
          { 
          title: 'Wrong OTP Entered!',
          content: `Please check your mail and enter a correct otp!'`
        })
      }
    }

    const handlePasswordChange = (e)=>{

      let value = e.target.value;
      setPassword(value)
      if(!value.match(/^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/gm) && value!==""){
        setPasswordErr(true);
        setPasswordErrMsg("Password must contain an Uppercase and Lowercase letters, Numbers, and a Special Character. ")
      }else{
        setPasswordErr(false);
        setPasswordErrMsg("");
      }

      if(value===""){
        setPasswordErr(false);
        setPasswordErrMsg("");
      }
      
    }


    const changePassword = async ()=>{
      // console.log("changing password : ",password,"Email : ",email);
      setLoading(true);
      if(email || password || userOTP){
        try{
          const response = await userService.passwordResetAfterOtp({password:password,email:email,otp:userOTP});
          if(response){
            // console.log(response.data);
            setLoading(false);
            Modal.success(
              { 
               title: 'Password Changed Successfully!',
               content: 'Now you can login with the updated password!',
               onOk:()=>{
                 history.push('/auth/signin');
               }
             });
            
          }
        }catch(err){
          console.log(err);
          setLoading(false);
          const msg =
          (err.response &&
            err.response.data &&
            err.response.data.message) ||
            err.message ||
            err.toString();
            Modal.error(
              { 
              title: 'Something Went Wrong!',
              content: `${msg ? msg : 'OTP Could Not be Sent!'}`
            })

        }
      }else{
        message.error("something went wrong. Please refresh the page and try again!");
      }
    }
  return (
    <div className='forgetPassword loginForm'>
     <h1>Reset Your Password</h1>
    <div className='card'>
    {screen===1 &&
     <>
     <img
          src={avatar}
          alt="profile-img"
          className="profile-img-card"
        />
       <div className="form-group">
       <label>Enter Registered Email for OTP: </label>
        <input
          type="email"
          className={`form-control ${emailError===true ? 'errorField' : ''}`}
          name="username"
          value={email}
          onChange={onChangeEmail}
          required
          placeholder="e.g. contact@gmail.com"
        />
      </div>
      {emailError ? 
        <div className="error">
          <small>{emailErrMsg}</small>
         </div>
         :
         null
      }
      <div>
      <button type="button" 
      className='btn-primary' 
      onClick={handleOtp} 
      disabled={emailError || email===""}>
       {loading ? <Spin indicator={spinIcon} /> : 'Get OTP'}
      </button>
      </div>
     </>

    }
    {screen===2 &&
    <>
    <img
          src={emailIcon}
          alt="email-icon"
          className="profile-img-card"
        />
    <div className="form-group">
       <label>Enter OTP sent on your Email: </label>
        <input
          type="text"
          className='form-control'
          name="otp"
          value={userOTP}
          onChange={(e)=>{setUserOTP(e.target.value)}}
          required
        />
      </div>
     
      <div>
      <button type="button" 
      className='btn-primary' 
      onClick={verifyOTP} 
      disabled={userOTP===""}>
       {loading ? <Spin indicator={spinIcon} /> : 'Verify OTP'}
      </button>
    </div>
    </>
    }

    {screen===3 &&
    <>
    <img
          src={lock}
          alt="lock-icon"
          className="profile-img-card"
        />
    <div className="form-group" style={{display:'flex',verticalAlign:'middle'}}>
            <input
              type={isShown ? "text":"password"}
              className={`form-control input-password ${passwordErr===true ? 'errorField' : ''}`}
              name="password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Enter New Password"
            />
            {isShown ?<span onClick={()=>{setIsShown(prevValue=>!prevValue)}} className="eye-icon"><EyeOutlined style={{fontSize:'20px'}} /> </span> : 
            <span onClick={()=>{setIsShown(prevValue=>!prevValue)}} className="eye-icon"><EyeInvisibleOutlined style={{fontSize:'20px'}} /></span>}
    </div>

    {passwordErr ? 
        <div className="error">
          <small>{passwordErrMsg}</small>
         </div>
         :
         null
      }
     
      <div>
      <button type="button" 
      className='btn-primary' 
      onClick={changePassword} 
      disabled={password==="" || passwordErr}>
       {loading ? <Spin indicator={spinIcon} /> : 'Change Password'}
      </button>
    </div>
    </>
    }



       </div>
    </div>
  )
}

export default ForgetPassword