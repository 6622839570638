import React,{useEffect, useState} from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import {useSelector} from 'react-redux';
import {history} from '../../../../helpers/history';
import SingleOrderForm from './SingleOrderForm/SingleOrderForm';




const EditOrder = (props)=>{
    const { user: currentUser } = useSelector((state) => state.auth);

   return(
        <div>
            <Breadcrumb>
            {currentUser.roles.includes('ROLE_SALES') ? 
            <Breadcrumb.Item> <Link to="/profile/orders-for-sales-role">All Orders</Link></Breadcrumb.Item>
            :
            <Breadcrumb.Item> <Link to="/profile/view-orders">All Orders</Link></Breadcrumb.Item>
            }
            
            <Breadcrumb.Item> <Link to={`/profile/edit-order/${props.match.params.slug}`}>{props.match.params.slug}</Link></Breadcrumb.Item>
            </Breadcrumb>
            <div>
            <h1>{props.match.params.slug}</h1>
            <hr />
            <SingleOrderForm id={props.location.id} match={props.match} />
            </div>
        </div>
       
   )
}


export default EditOrder;
