import React, {useState, useEffect} from 'react';
import OrderService from '../../../../services/order.service';
import {
  Table,
  Spin,
  Space,
  Tag,
  Select,
  Tabs,
  Button,
  Menu,
  Dropdown,
  Modal,
  message,
} from 'antd';
import {
  EditOutlined,
  EyeOutlined,
  MoreOutlined,
  BarChartOutlined,
  StopOutlined,
  ShoppingCartOutlined,
  DollarOutlined,
  UserOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  CaretDownOutlined,
  ArrowDownOutlined,
  LoadingOutlined,
  UploadOutlined,
  FileExclamationOutlined,
  ReloadOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  FileAddOutlined,
  
} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import UserService from '../../../../services/user.service';
import GoogleServices from '../../../../services/google.services';
import {useDispatch, useSelector} from 'react-redux';
import {history} from '../../../../helpers/history';
import moment from 'moment';
import invoiceDownload from '../../../../assets/invoiceDownload.png';
import {updateUserCoins} from '../../../../actions/coinsAction';

const spinIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

const {Option} = Select;

const {TabPane} = Tabs;

const ViewOrders = () => {
  const [allOrders, setAllOrders] = useState ([]);
  const [totalAmount, setTotalAmount] = useState (null);
  const [currentOrderId, setCurrentOrderId] = useState (null);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState (false);
  const [paidAmount, setPaidAmount] = useState (null);
  const [confirmOrderLoading, setConfirmOrderLoading] = useState (false);
  const [isAssignModalVisible, setIsAssignModalVisible] = useState (false);
  const [allUsers, setAllUsers] = useState ([]);
  const [assignTo, setAssignTo] = useState (null);
  const [finalAssignToId, setFinalAssignToId] = useState (null);
  const [confirmAssignLoading, setConfirmAssignLoading] = useState (false);
  const [activeOrders, setActiveOrders] = useState ([]);
  const [deliveredOrders, setDeliveredOrders] = useState ([]);
  const [cancelledOrders, setCancellledOrders] = useState ([]);
  const [completedOrders, setCompletedOrders] = useState ([]);
  const [filteredNameArr, setFilteredNameArr] = useState ([]);
  const [filteredCategoryArr, setFilteredCategoryArr] = useState ([]);
  const [filteredStatusArr, setFilteredStatusArr] = useState ([]);
  const [filteredAssignToArr, setFilteredAssignToArr] = useState ([]);
  const [partialAmount, setPartialAmount] = useState (null);
  const [filteredArr, setFilteredArr] = useState ([]);
  const [newOrdersArr, setNewOrdersArr] = useState ([]);
  const [orderResponse, setOrderResponse] = useState ([]);
  const [isAddOrderCategory, setIsAddOrderCategory] = useState (false);
  const [confirmNewCategoryLoading, setConfirmNewCategoryLoading] = useState (
    false
  );
  const [newOrderCategory, setNewOrderCategory] = useState ('');
  const [isProgress, setIsProgress] = useState (false);
  const [currentProgressId, setCurrentProgressId] = useState (null);
  const [confirmProgressUpload, setConfirmProgressUpload] = useState (false);
  const [progressPercentage, setProgressPercentage] = useState (0);
  const [currentCancelOrderId, setCurrentCancelOrderId] = useState (null);
  const [currentCancelOrderDetails, setCurrentCancelOrderDetails] = useState (
    null
  );
  const [cancelOrderModal, showCancelOrderModal] = useState (null);
  const [cancellationReason, setCancellationReason] = useState ('');
  const [confirmCancelOrderModal, setConfirmCancelOrderModal] = useState (
    false
  );
  const [cancelOrderEvent, setCancelOrderEvent] = useState (null);
  const [invoiceModal, setInvoiceModal] = useState (false);
  const [currentInvoiceData, setCurrentInvoiceData] = useState ('');
  const [isSpinner, setSpinner] = useState (false);
  const [loadingOrderData, setLoadingOrderData] = useState (false);
  const [currentOrderName, setCurrentOrderName] = useState ('');
  const [allLabels,setAllLabels] = useState([]);
  const [currentTabName,setCurrentTabName] = useState("New Orders");
  const [currentCategoryTotalCount,setCurrentCategoryTotalCount] = useState(0);
  const [currentTabLimit,setCurrentTabLimit] = useState(10);
  const [allOrdersForSearch,setAllOrdersForSearch] = useState([]);

  const tableLoading = {
    spinning: loadingOrderData,
    indicator: <LoadingOutlined style={{fontSize: 24}} spin />,
  };

  let currentNow = new Date ();
  let currentDay = currentNow.getDate ();
  let currentMonth = currentNow.getMonth () + 1;
  let currentYear = currentNow.getFullYear ();
  let todaysDate = currentYear + '-' + currentMonth + '-' + currentDay;

  const {user: currentUser} = useSelector (state => state.auth);
  const {coinDetails} = useSelector (state => state.coinReducer);
  const dispatch = useDispatch();

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllGmailLabels = async ()=>{
    try{
        const response = await OrderService.getAllGmailLabels();
        // console.log(response.data);
        let userLabels = response.data?.labels?.filter(item=>{
            return item.type==='user'
        });

        // console.log(userLabels);
        setAllLabels(userLabels);
        return userLabels
    }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
    }
   }


  
  useEffect(()=>{
    let isMounted = true;

   const getAllGmailLabels = async ()=>{
    try{
        const response = await OrderService.getAllGmailLabels();
        // console.log("Gmail labels : ",response.data);
        let userLabels = response.data?.labels?.filter(item=>{
            return item.type==='user'
        });

        // console.log(userLabels);
        setAllLabels(userLabels);
        return userLabels
    }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
    }
   }
   if(isMounted){
    getAllGmailLabels();
   }

   return ()=>{
       isMounted = false;
   }
   
},[])


useEffect(()=>{
  let isMounted = true;
  const getAllOrdersForSearch =  ()=>{
    OrderService.getAllOrders().then(response=>{
      let data = [];
      response.data.forEach(order=>{
        let finalDate = 'N/A';
        if (order.createdAt) {
          const date = new Date (order.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }
        let updatedField = {
          key: order._id,
          id: order._id,
          name: order.name,
          createdAt: finalDate,
          sortDate: order.createdAt,
          category: order.category,
          deadline: order.deadline,
          booking: order.bookingSession === true ? 'Yes' : 'No',
          documentation: order.documentation === true ? 'Yes' : 'No',
          details: order.details && order.details,
          status: order.status && order.status,
          totalAmount: order.totalAmount && order.totalAmount,
          paidAmount: order.paidAmount && order.paidAmount,
          progress: order.progress ? order.progress : 0,
          partialAmount: order.partialAmount && order.partialAmount,
          customer: order.customer
            ? order.customer[0] ? order.customer[0].username : null
            : order.createdBy && order.createdBy[0]
                ? order.createdBy[0].username
                : null,
          assignedTo: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0].username
            : 'N/A',
          userMail: order.customer
            ? order.customer[0] ? order.customer[0].email : null
            : order.createdBy && order.createdBy[0]
                ? order.createdBy[0].email
                : null,
          employeeMail: order.assignedEmployee && order.assignedEmployee[0]
            ? order.assignedEmployee[0].email
            : null,
          assignedUserMail: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0].email
            : null,
          employeeId: order.assignedEmployee && order.assignedEmployee[0]
            ? order.assignedEmployee[0]._id
            : null,
          assignedToId: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0]._id
            : null,
          isCommentDisabled: order.isCommentDisabled && order.isCommentDisabled,
          isDeadlineMailSent: order.isDeadlineMailSent,
          userDetails: order.customer ? order.customer[0] : null,
        };
        data.push(updatedField);
      });
      setAllOrdersForSearch(data);
    }).catch(err=>{
      console.log(err);
    })
  }

  if(isMounted){
    getAllOrdersForSearch();
  }
  return ()=>{
    isMounted = false
  }
},[]);




useEffect (() => {
  getOrders ("New Orders");
}, []);


  const getUsers = async () => {
    try {
      const response = await UserService.getEmployeeAndManagers ();
      let assignedToUserList = [];

      response.data.forEach (user => {
        if (
          user.isDisabled === false ||
          user.isDisabled === undefined ||
          user.isDisabled === '' ||
          user.isDisabled === null
        ) {
          assignedToUserList.push (user);
        }
      });

      setAllUsers (assignedToUserList);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to get assigned to users!'
      );
    }
  };

  useEffect (() => {
    getUsers ();
  }, []);

  useEffect (
    () => {
      if (currentInvoiceData) {
        setInvoiceModal (true);
      }
    },
    [currentInvoiceData]
  );

  useEffect (
    () => {
      let nameFilterArr = [];
      let categoryFilterArr = [];
      let statusFilterArr = [];
      let assignToArr = [];
      allOrders.map (o => {
        if (o.name) {
          nameFilterArr.push ({text: o.name, value: o.name});
        }
        if (o.category) {
          categoryFilterArr.push ({text: o.category, value: o.category});
        }

        if (o.status) {
          statusFilterArr.push ({text: o.status, value: o.status});
        }
        if (o.assignedTo) {
          //    console.log("fall into",o.assignedTo);
          assignToArr.push ({text: o.assignedTo, value: o.assignedTo});
        }
      });
      // console.log("NAME FILTER : ",assignToArr);
      categoryFilterArr = categoryFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      statusFilterArr = statusFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      assignToArr = assignToArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });
      setFilteredNameArr (nameFilterArr);
      setFilteredCategoryArr (categoryFilterArr);
      setFilteredStatusArr (statusFilterArr);
      setFilteredAssignToArr (assignToArr);
    },
    [allOrders]
  );

  const handleEditAmount = (id, t, p, partial) => {
    // console.log("id : ",id)
    // console.log("got total:",t);
    // console.log("got paid : ",p);
    // console.log("got partial : ",partial);
    setTotalAmount (t);
    setPaidAmount (p);
    setCurrentOrderId (id);
    setPartialAmount (partial);
    if (t === undefined) {
      setTotalAmount (0);
    }
    if (p === undefined || p == 'NaN') {
      setPaidAmount (0);
    }
    if (partial === undefined || partial === null) {
      setPartialAmount (0);
    }

    setIsOrderModalVisible (true);
  };
  const handleOrderOk = async () => {
    setConfirmOrderLoading (true);
    try {
      // console.log("Total Amount : ",totalAmount);
      // console.log("Paid Amount : ",paidAmount);
      // console.log("Partial Amount : ",partialAmount);
      const response = await OrderService.updateOrder ({
        _id: currentOrderId,
        totalAmount,
        paidAmount,
        partialAmount: partialAmount,
      });
      // console.log(response);
      setConfirmOrderLoading (false);
      setIsOrderModalVisible (false);
      message.success ('Successfully Updated Order Amounts !');
    } catch (err) {
      console.log (err);
      setConfirmOrderLoading (false);
      setIsOrderModalVisible (false);
      message.error (err.message);
    }
  };

  const handleOrderCancel = () => {
    setIsOrderModalVisible (false);
  };

  const handleAssignToChange = uname => {
    var foundUser = null;
    if (uname.toLowerCase () !== 'select user') {
      foundUser = allUsers.find (user => {
        return user.username === uname;
      });
      if (foundUser !== null) {
        setFinalAssignToId (foundUser._id);
      }
    }
    setAssignTo (uname);
  };

  //sending email

  const sendStatusChangedMail = async (mail, order, status) => {
    // console.log("Mail : ",order.userMail);
  
    if(allLabels.length === 0 || !allLabels){
      getAllGmailLabels();
    }
    let filteredLabel = allLabels.find((item)=>{
      return item.name.toLowerCase().match(status.toLowerCase())
    });
    // console.log(filteredLabel);
    if (mail) {
      try {
        const data = {
          To: mail,
          Subject: `${order.name} #${order.id}: ${status} for ${order.customer} ${order.userMail}`,
          Write: `Dear User, <br /> Your Request of <b>${order.name}</b> is Now in <b>${status}</b> Phase. <br /> We Will be Keep Notifying you about the Updates on your order. <br /> Stay Connected With us. <br /> <br />Thank You for Choosing <b>Codersarts!</b>`,
          attachment: false,
          labelId:filteredLabel.id
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${mail}`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
    }
  };

  const handleAssignTo = (id, a, name) => {
    // console.log(id,a);
    setCurrentOrderName (name);
    setCurrentOrderId (id);
    setAssignTo (a);
    setIsAssignModalVisible (true);
  };

  const handleAssignOk = async () => {
    setConfirmAssignLoading (true);
    // console.log("final data : ",currentOrderId,finalAssignToId);
    let now = new Date ();
    try {
      const response = await OrderService.updateOrder ({
        _id: currentOrderId,
        assignedTo: finalAssignToId,
        assignedBy: currentUser.id,
        assignedByAdminAt: now,
      });
      // console.log(response);
      const notificationRes = await UserService.createNewNotification ({
        notification: `New Order: ${currentOrderName} is Assigned to you by ${currentUser.username}!`,
        user: finalAssignToId,
      });
      // console.log(notificationRes.data);
      setConfirmAssignLoading (false);
      setIsAssignModalVisible (false);
      message.success (`Successfully Assigned Order to ${assignTo}`);
      getOrders (currentTabName);
    } catch (err) {
      console.log (err);
      setConfirmAssignLoading (false);
      setIsAssignModalVisible (false);
      message.error (err.message);
    }
  };

  const handleAssignCancel = () => {
    setIsAssignModalVisible (false);
  };
  const statusChangeHandler = (e, id, order) => {
    if (e.target.value.toLowerCase () !== 'change status') {

      OrderService.updateStatus (id, e.target.value)
        .then (response => {
          // console.log(response);
          message.success (`Status Changed to ${e.target.value} Successfully!`);
         
        })
        .catch (err => {
          // console.log(err);
          message.error (err.message ? err.message : "Couldn't Change Status!");
        });
    }
    // window.location.reload();
    getOrders (currentTabName);
 
      sendStatusChangedMail (order.userMail, order, e.target.value);
      sendStatusChangedMail (order.employeeMail, order, e.target.value);
      sendStatusChangedMail (order.assignedUserMail, order, e.target.value);
  
    
  };

  const handleProgressClick = (id, progress) => {
    setCurrentProgressId (id);
    setProgressPercentage (progress);
    setIsProgress (true);
  };

  const handleProgressSubmit = async () => {
    setConfirmProgressUpload (true);
    // console.log(progressPercentage);
    // console.log(currentOrderId);

    try {
      const response = await OrderService.updateOrder ({
        _id: currentProgressId,
        progress: progressPercentage,
      });
      // console.log(response);
      setConfirmProgressUpload (false);
      setIsProgress (false);
      setProgressPercentage (0);
      message.success ('Successfully updated the progress!');
    } catch (err) {
      console.log (err);
      setConfirmProgressUpload (false);
      setIsProgress (false);
      setProgressPercentage (0);
      message.error (err.message ? err.message : 'Unable to update progress!');
    }
  };

  const handleProgressClose = () => {
    setIsProgress (false);
  };

  const recycleItem = async id => {
    // console.log(" ITEM ID : ",id);

    let itemToRecyle = orderResponse.filter (item => {
      return item._id === id;
    });

    // console.log("RESTORED : ",itemToRecyle)
    let modifiedRecycleData = itemToRecyle[0];
    if (itemToRecyle[0].assignedTo && itemToRecyle[0].assignedTo[0]) {
      modifiedRecycleData.assignedTo = [itemToRecyle[0].assignedTo[0]._id];
    }

    if (itemToRecyle[0].createdBy && itemToRecyle[0].createdBy[0]) {
      modifiedRecycleData.createdBy = [itemToRecyle[0].createdBy[0]._id];
    }

    let finalRecycledData = {
      data: modifiedRecycleData,
      recycleType: 'Order',
    };
    // console.log("TO BE RECYCLED : ",finalRecycledData);
    try {
      const response = await OrderService.recycleData (finalRecycledData);
      // console.log(response);
      if (response.status == 200) {
        message.success (`${itemToRecyle[0].name} Added to Recyle Bin!`);
      }
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : "Couldn't Recycled The Item !"
      );
    }

    getOrders (currentTabName);
  };

  const statusChangeModal = (e, orderId, orderDetails) => {
    if (e.target.value.toLowerCase () === 'cancel order') {
      setCurrentCancelOrderId (orderId);
      setCurrentCancelOrderDetails (orderDetails);
      setCancelOrderEvent (e);
      showCancelOrderModal (true);
    } else {
      statusChangeHandler (e, orderId, orderDetails);
    }
  };

  const changeCommentFeature = async (id, changedOption) => {
    try {
      const response = await OrderService.updateOrder ({
        _id: id,
        isCommentDisabled: changedOption,
      });
      // console.log(response);
      message.success (
        `Successfully ${changedOption ? 'Disabled' : 'Enabled'} the Comment Feature!`
      );
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to update Comment!');
    }

    getOrders (currentTabName);
  };

  const getSingleInvoice = async id => {
    setSpinner (true);
    try {
      const response = await OrderService.getSingleInvoiceDetails (id);
      // console.log("Single Invoice : ",response.data);
      if (response.data.length > 0) {
        setSpinner (false);
        setCurrentInvoiceData (response.data[0]);
        getOrders (currentTabName);
      } else {
        setSpinner (false);
        message.error (
          'Invoice Not Found, Please Create an Invoice for this Order!'
        );
      }
    } catch (err) {
      console.log (err);
      setSpinner (false);
      message.error (
        err.message
          ? err.message
          : 'Something went wrong when downloading invoice!'
      );
    }
  };

  const createInvoice = async record => {
    // setInvoiceModal(true);
    // console.log("Order Data for invoice : ",record);
    let finalData = {
      _id: record.id,
      invoiceTitle: record.name,
      customerName: record.customer,
      invoiceNumber: 0,
      customerEmail: record.userMail,
      address: record.userDetails.address ? record.userDetails.address : ' ',
      zip: '',
      city: '',
      state: '',
      country: '',
      orders: [
        {
          name: record.name,
          description: record.details,
          price: record.totalAmount === record.paidAmount
            ? record.totalAmount
            : record.paidAmount,
          quantity: 1,
          tax: 0,
        },
      ],
    };
    let response = null;
    try {
      response = await OrderService.createOrderInvoice (finalData);
      // console.log("Invoice Result : ",response.data);
      message.success (`Invoice Created for ${record.name} successfully!`);
      getOrders (currentTabName);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to create invoice!');
    }

    if (response.data) {
      setTimeout (() => {
        getSingleInvoice (response.data._id);
      }, 5000);
    }
  };
  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      filters: filteredNameArr,
      render: record => {
        return (
          <Link
            to={{
              pathname: `/profile/view-order-details/${record.id}`,
              state: {orderIds: record.orderIds},
            }}
          >
            <b>{record.name}</b>
          </Link>
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: filteredCategoryArr,
      onFilter: (value, record) => record.category.indexOf (value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
    },

    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      sorter: (a, b) =>
        moment (a.deadline).unix () - moment (b.deadline).unix (),
      width: '10vw',
      render: deadline => {
        return deadline === todaysDate
          ? <Tag color={'red'}><b>{deadline}</b></Tag>
          : <b>{deadline}</b>;
      },
    },

    {
      title: 'AssignedTo',
      dataIndex: 'assignedTo',
      filters: filteredAssignToArr,
      onFilter: (value, record) => record.assignedTo.indexOf (value) === 0,
      sorter: (a, b) => a.assignedTo.length - b.assignedTo.length,
      sortDirections: ['ascend'],
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: (a, b) => a.customer.length - b.customer.length,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: filteredStatusArr,
      render: text => {
        return <Tag color={'geekblue'}>{text}</Tag>;
      },
      onFilter: (value, record) =>
        record.status && record.status.indexOf (value) === 0,
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      render: (
        record // <Space size="middle"> //   <Link to={{pathname:`/profile/edit-order/${record.title}`,id:record.id}} id="action" ><EditOutlined style={{color:'#1890FF'}}/></Link> //         <select className="form-control" onChange={(e)=>statusChangeHandler(e,record.id)}> //             <option>Change Status</option> //             <option>Requested</option> //             <option>Reviewing</option> //             <option>Development</option> //             <option>Testing</option>
      ) => //             <option>Deal Confirmed</option>
      //             <option>Delivered</option>
      //         </select>

      // </Space>
      (
        <Space size="middle">
          <select
            className="form-control"
            onChange={e => statusChangeModal (e, record.id, record)}
          >
            <option>Change Status</option>
            <option>Requested</option>
            <option>Reviewing</option>
            <option>Development</option>
            <option>Testing</option>
            <option>Deal Confirmed</option>
            <option>Delivered</option>
            <option>Completed</option>
            <option>Cancel Order</option>
          </select>
          <Dropdown
            overlay={
              <Menu
                style={{
                  marginRight: '5%',
                  marginTop: '-5%',
                  width: 'fit-content',
                }}
              >
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link
                    to={{
                      pathname: `/profile/view-order-details/${record.id}`,
                      state: {orderIds: record.orderIds},
                    }}
                  />
                  {' '}
                  View Details
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to={{
                      pathname: `/profile/edit-order/${record.name}`,
                      id: record.id,
                    }}
                    id="action"
                  >
                    <EditOutlined /> Edit Details{' '}
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => {
                    handleEditAmount (
                      record.id,
                      record.totalAmount,
                      record.paidAmount,
                      record.partialAmount
                    );
                  }}
                >
                  <DollarOutlined /> Edit Amount{' '}
                </Menu.Item>
                <Menu.Item
                  key="4"
                  onClick={() => {
                    handleAssignTo (record.id, record.assignedTo, record.name);
                  }}
                >
                  <UserOutlined /> Assign To{' '}
                </Menu.Item>
                <Menu.Item key="5">
                  <DollarOutlined />
                  {' '}
                  <Link to={`/profile/create-manual-payment/${record.id}`} />
                  {' '}
                  Accept Manual Payment
                </Menu.Item>

                <Menu.Item
                  key="6"
                  onClick={() => {
                    handleProgressClick (record.id, record.progress);
                  }}
                >
                  <ReloadOutlined /> Add Progress
                </Menu.Item>

                {record.isOrderInvoice
                  ? null
                  : <Menu.Item
                      key="Create Invoice"
                      onClick={() => {
                        createInvoice (record);
                      }}
                    >
                      <FileAddOutlined /> Create Invoice
                    </Menu.Item>}

                {record.isOrderInvoice
                  ? <Menu.Item
                      key="DownloadInvoice"
                      onClick={() => {
                        getSingleInvoice (record.id);
                      }}
                    >
                      <ArrowDownOutlined /> Download Invoice
                    </Menu.Item>
                  : null}

                {record.isCommentDisabled === true ||
                  record.isCommentDisabled === undefined
                  ? <Menu.Item
                      danger
                      key="7"
                      onClick={() => {
                        changeCommentFeature (record.id, false);
                      }}
                    >
                      <CheckCircleOutlined /> Enable Comment
                    </Menu.Item>
                  : <Menu.Item
                      danger
                      key="7"
                      onClick={() => {
                        changeCommentFeature (record.id, true);
                      }}
                    >
                      <StopOutlined /> Disable Comment
                    </Menu.Item>}

                <Menu.Item
                  danger
                  key="8"
                  onClick={() => {
                    confirmRecycleOrder (record.id);
                  }}
                >
                  <DeleteOutlined /> Recycle
                </Menu.Item>

              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>
        </Space>
      ),
    },
  ];

  

  function assignAllOrderId (arr) {
    let orderId = [];

    arr = arr.sort (function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return moment (b.sortDate).unix () - moment (a.sortDate).unix ();
    });

    arr = arr.filter (
        (item, index, self) =>
          index ===
          self.findIndex (o => o.id === item.id && o.name === item.name)
      );

    arr.forEach (item => {
      orderId.push (item.id);
    });

    let updatedActive = [];

    arr.forEach (item => {
      let updatedData = {
        ...item,
        orderIds: orderId,
      };
      updatedActive.push (updatedData);
    });
    // console.log("orders with id : ",updatedActive);
    return updatedActive;
  }

  const sendDeadlineNotification = order => {
    // console.log("Mail : ",order.userMail);
    // console.log("sending mail for : ",order);
    let query = "Project Deadline";
    
    let filteredLabel = allLabels.find((item)=>{
      return item.name.toLowerCase().match(query.toLowerCase())
    });

    // console.log(filteredLabel)

    if (!order.isDeadlineMailSent) {
      if (order.assignedUserMail) {
        const data = {
          To: order.assignedUserMail,
          Subject: 'Deadline is Today!',
          Write: `Greetings from Codersarts, <br /> Your Deadline of the project <b> ${order.name} </b> with the Project Id #<b>${order.id}</b> is today! <br /> Please Complete the project As soon as Possible`,
          attachment: false,
          labelId:filteredLabel.id
        };
        GoogleServices.sendmail (data)
          .then (result => {
            if (result) {
            }
          })
          .catch (err => {
            console.log (err);
          });

        OrderService.updateOrder ({_id: order.id, isDeadlineMailSent: true})
          .then (result => {
            if (result) {
            }
          })
          .catch (err => {
            console.log (err);
          });
      }

      if (order.employeeMail) {
        const data = {
          To: order.employeeMail,
          Subject: 'Deadline is Today!',
          Write: `Greetings from Codersarts, <br /> Your Deadline of the project <b> ${order.name} </b> with the Project Id #<b>${order.id}</b> is today! <br /> Please Complete the project As soon as Possible`,
          attachment: false,
        };
        GoogleServices.sendmail (data)
          .then (result => {
            if (result) {
            }
          })
          .catch (err => {
            console.log (err);
          });

        OrderService.updateOrder ({_id: order.id, isDeadlineMailSent: true})
          .then (result => {
            if (result) {
            }
          })
          .catch (err => {
            console.log (err);
          });
      }

      if (order.employeeId) {
        UserService.createNewNotification ({
          notification: `DEADLINE IS TODAY: ${order.name}'s Deadline is Today please Complete it as soon As Possible!`,
          user: order.employeeId,
        })
          .then (result => {
            if (result) {
            }
          })
          .catch (Err => {
            console.log (Err);
          });
      }

      if (order.assignedToId) {
        UserService.createNewNotification ({
          notification: `DEADLINE IS TODAY: ${order.name}'s Deadline is Today please Complete it as soon As Possible!`,
          user: order.assignedToId,
        })
          .then (result => {
            if (result) {
            }
          })
          .catch (Err => {
            console.log (Err);
          });
      }
    }
  };
  const getOrders = async (category,page=1,limit=10) => {
    let now = new Date ();
    let dd = now.getDate ();
    let mm = now.getMonth () + 1;
    let yyyy = now.getFullYear ();
    let updatedMM = mm > 9 ? mm : '0' + mm;
    let today = yyyy + '-' + updatedMM + '-' + dd;

    // console.log(category);

    const data = [];
    setLoadingOrderData (true);
    try {
      let response = []
      if(category==="New Orders"){
        response = await OrderService.getNewOrders ();
        // console.log(response);
        setOrderResponse (response.data);
        await response.data.reduce (async (promise, order) => {
          await promise;
  
          let isOrderInvoiceResult = await OrderService.isOrderInvoicePresent (
            order._id
          );
          // console.log("Is Order Invoice : ",isOrderInvoiceResult.data);
          let finalDate = 'N/A';
          if (order.createdAt) {
            const date = new Date (order.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
          }
          let updatedField = {
            key: order._id,
            id: order._id,
            name: order.name,
            createdAt: finalDate,
            isOrderInvoice: isOrderInvoiceResult.data,
            sortDate: order.createdAt,
            category: order.category,
            deadline: order.deadline,
            booking: order.bookingSession === true ? 'Yes' : 'No',
            documentation: order.documentation === true ? 'Yes' : 'No',
            details: order.details && order.details,
            status: order.status && order.status,
            totalAmount: order.totalAmount && order.totalAmount,
            paidAmount: order.paidAmount && order.paidAmount,
            progress: order.progress ? order.progress : 0,
            partialAmount: order.partialAmount && order.partialAmount,
            customer: order.customer
              ? order.customer[0] ? order.customer[0].username : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].username
                  : null,
            assignedTo: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0].username
              : 'N/A',
            userMail: order.customer
              ? order.customer[0] ? order.customer[0].email : null
              : order.createdBy && order.createdBy[0]
                  ? order.createdBy[0].email
                  : null,
            employeeMail: order.assignedEmployee && order.assignedEmployee[0]
              ? order.assignedEmployee[0].email
              : null,
            assignedUserMail: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0].email
              : null,
            employeeId: order.assignedEmployee && order.assignedEmployee[0]
              ? order.assignedEmployee[0]._id
              : null,
            assignedToId: order.assignedTo && order.assignedTo[0]
              ? order.assignedTo[0]._id
              : null,
            isCommentDisabled: order.isCommentDisabled && order.isCommentDisabled,
            isDeadlineMailSent: order.isDeadlineMailSent,
            userDetails: order.customer ? order.customer[0] : null,
          };
          // console.log(updatedField.deadline,"->",today);
          // console.log(typeof(updatedField.deadline)," and ",typeof(today));
          // console.log(updatedField.deadline===today);
          if (
            updatedField.deadline.toString ().trim () ===
            today.toString ().trim ()
          ) {
            // console.log("deadline!");
            if(allLabels.length > 0){
              sendDeadlineNotification (updatedField);
            }else{
              getAllGmailLabels();

            }
           
          }
          data.push (updatedField);
        }, Promise.resolve ());

          // console.log("Data : ",data);
    if (data.length > 0) {
      // console.log("setting")
      setNewOrdersArr (assignAllOrderId (data));
      setCurrentCategoryTotalCount(data.length);
      setLoadingOrderData(false);
    }

      }else{
       response = await OrderService.getCategorizedOrder (category,page,limit);

      

      setOrderResponse (response.data.orders);
      setCurrentCategoryTotalCount(response.data.count);
      // console.log(response.data);
      await response?.data?.orders?.reduce (async (promise, order) => {
        await promise;

        let isOrderInvoiceResult = await OrderService.isOrderInvoicePresent (
          order._id
        );
        // console.log("Is Order Invoice : ",isOrderInvoiceResult.data);
        let finalDate = 'N/A';
        if (order.createdAt) {
          const date = new Date (order.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }
        let updatedField = {
          key: order._id,
          id: order._id,
          name: order.name,
          createdAt: finalDate,
          isOrderInvoice: isOrderInvoiceResult.data,
          sortDate: order.createdAt,
          category: order.category,
          deadline: order.deadline,
          booking: order.bookingSession === true ? 'Yes' : 'No',
          documentation: order.documentation === true ? 'Yes' : 'No',
          details: order.details && order.details,
          status: order.status && order.status,
          totalAmount: order.totalAmount && order.totalAmount,
          paidAmount: order.paidAmount && order.paidAmount,
          progress: order.progress ? order.progress : 0,
          partialAmount: order.partialAmount && order.partialAmount,
          customer: order.customer
            ? order.customer[0] ? order.customer[0].username : null
            : order.createdBy && order.createdBy[0]
                ? order.createdBy[0].username
                : null,
          assignedTo: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0].username
            : 'N/A',
          userMail: order.customer
            ? order.customer[0] ? order.customer[0].email : null
            : order.createdBy && order.createdBy[0]
                ? order.createdBy[0].email
                : null,
          employeeMail: order.assignedEmployee && order.assignedEmployee[0]
            ? order.assignedEmployee[0].email
            : null,
          assignedUserMail: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0].email
            : null,
          employeeId: order.assignedEmployee && order.assignedEmployee[0]
            ? order.assignedEmployee[0]._id
            : null,
          assignedToId: order.assignedTo && order.assignedTo[0]
            ? order.assignedTo[0]._id
            : null,
          isCommentDisabled: order.isCommentDisabled && order.isCommentDisabled,
          isDeadlineMailSent: order.isDeadlineMailSent,
          userDetails: order.customer ? order.customer[0] : null,
        };
        // console.log(updatedField.deadline,"->",today);
        // console.log(typeof(updatedField.deadline)," and ",typeof(today));
        // console.log(updatedField.deadline===today);
        if (
          updatedField.deadline.toString ().trim () ===
          today.toString ().trim ()
        ) {
          // console.log("deadline!");
          sendDeadlineNotification (updatedField);
        }
        data.push (updatedField);
      }, Promise.resolve ());
        // console.log("Data : ",data);
    if (data.length > 0) {
      // console.log("setting")
      if (category === 'Active Orders') {
        setActiveOrders (assignAllOrderId (data));
      } else if (category === 'Completed Orders') {
        setCompletedOrders (assignAllOrderId (data));
      } else if (category === 'Cancelled Orders') {
        setCancellledOrders (assignAllOrderId (data));
      } else if (category === 'Delivered Orders') {
        setDeliveredOrders (assignAllOrderId (data));
      }else if(category==="All Orders"){
        setAllOrders(data);
      }
     
     
    }
    setLoadingOrderData(false);
    }} catch (err) {
      console.log (err);
      setLoadingOrderData(false);
      message.error (err.message);
    }

  
  
  };



  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allOrdersForSearch.length > 0) {
        filteredData = allOrdersForSearch.filter (order => {
          // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
          return (
            (order.name &&
              order.name.toLowerCase ().match (item.toLowerCase ())) ||
            (order.id && order.id.match (item)) ||
            (order.customer &&
              order.customer.toLowerCase ().match (item.toLowerCase ()))
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  const exportAllOrders = async () => {
    try {
      const result = await UserService.exportOrdersToCsv ();
      //  console.log(result);
      if (result.data) {
        message.success ('Exported All Orders To CSV file !');
      }
    } catch (err) {
      message.error (err.message ? err.message : 'Unable To Export Orders !');
    }
  };
  const confirmExport = () => {
    Modal.confirm ({
      title: 'Confirm Export',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to export all Orders Data ? ',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        exportAllOrders ();
      },
    });
  };

  const confirmRecycleOrder = id => {
    Modal.confirm ({
      title: 'Confirm Recycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Recycle This Orders Data ? ',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        recycleItem (id);
      },
    });
  };

  const handleAddOrderCatgory = () => {
    setIsAddOrderCategory (true);
  };

  const handleNewCategoryCancel = () => {
    setIsAddOrderCategory (false);
  };

  const handleNewCategoryOk = async () => {
    if (newOrderCategory) {
      setConfirmNewCategoryLoading (true);
      try {
        const result = await OrderService.createOrderCategory (
          newOrderCategory
        );
        // console.log(result);
        message.success (
          `Created ${newOrderCategory} as a new order category!`
        );

        setNewOrderCategory ('');
        setConfirmNewCategoryLoading (false);
        setIsAddOrderCategory (false);
      } catch (err) {
        console.log (err);
        setConfirmNewCategoryLoading (false);
        setIsAddOrderCategory (false);
        message.error (
          err.message ? err.message : 'Unable to Create New Order Category!'
        );
      }
    }
  };

  const handleCancelOrderModal = async () => {
    setConfirmCancelOrderModal (true);
    try {
      const response = await OrderService.updateOrder ({
        _id: currentCancelOrderId,
        cancellationReason: cancellationReason,
      });
      // console.log(response);
      message.success ('Added Cancellation Reason !');
      statusChangeHandler (
        cancelOrderEvent,
        currentCancelOrderId,
        currentCancelOrderDetails
      );
      setCancellationReason ('');
      setConfirmCancelOrderModal (false);
      showCancelOrderModal (false);
    } catch (err) {
      console.log (err);
      setConfirmCancelOrderModal (false);
      showCancelOrderModal (false);
      message.error (
        err.message ? err.message : 'Unable to Add Order Cancellation Reason'
      );
    }
  };

  const handleRefresh = ()=>{
    getOrders(currentTabName);
  }


  const onPaginationChange = (currentPage) => {
    getOrders(currentTabName,currentPage,currentTabLimit);
  };


  const onShowSizeChange = (currentPage,pageSize)=>{
    getOrders(currentTabName,currentPage,pageSize);
    setCurrentTabLimit(pageSize);
  }

  return (
    <Spin
      tip="Downloading Invoice Please Wait..."
      indicator={spinIcon}
      spinning={isSpinner}
    >
      <div>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div>
            <h3>All Orders</h3>
          </div>

          <div>
            <input
              type="text"
              disabled={!allOrdersForSearch.length > 0}
              className="form-control"
              title={allOrdersForSearch.length > 0 ? "":"All Orders Are being fetched Please wait!"}
              placeholder="Search By Order Title, Order Id or Customer name"
              style={{width: '30vw', marginBottom: '3%'}}
              onChange={e => {
                handleSearchChange (e.target.value);
              }}
            />
          </div>

          <div>

          <Button onClick={handleRefresh} ><ReloadOutlined /> Refresh</Button>

            <Dropdown
              overlay={
                <Menu style={{marginLeft: '-8%', width: 'fit-content'}}>
                  <Menu.Item key="1">
                    <Link to="/profile/create-order">
                      <PlusOutlined /> Create Order
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="2" onClick={handleAddOrderCatgory}>
                    <PlusOutlined /> Add Order Category
                  </Menu.Item>

                  <Menu.Item key="3" onClick={confirmExport}>
                    <UploadOutlined /> Export All Orders
                  </Menu.Item>

                </Menu>
              }
            >
              <Button type={'primary'}>
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
            {/* <Button style={{
            width:'20vw',
            textAlign:'center',
            borderRadius:'2rem',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
            }}
            type={'primary'}
            
            >

          <PlusOutlined />
          <span><Link to="/profile/create-order" style={{color:'white',fontWeight:'bold'}}> Create Order </Link></span>
          
          </Button> */}
          </div>
          
        </div>
        <hr />
        <Tabs defaultActiveKey="New Orders"  onChange={(value)=>setCurrentTabName(value)} type="card">

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <FileExclamationOutlined style={{fontSize: '20px'}} />
                New Orders
              </span>
            }
            key="New Orders"
            
          >
            <Table
              loading={tableLoading}
              dataSource={filteredArr.length > 0 ? filteredArr : newOrdersArr}
              columns={columns}
            
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <BarChartOutlined style={{fontSize: '20px'}} />
                Active Orders
              </span>
            }
            key="Active Orders"
           
          >
            <Table
              loading={tableLoading}
              dataSource={filteredArr.length > 0 ? filteredArr : activeOrders}
              columns={columns}
              pagination={{onChange:onPaginationChange,total:currentCategoryTotalCount,showSizeChanger:true,onShowSizeChange:onShowSizeChange}}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <CheckCircleOutlined style={{fontSize: '20px'}} />
                Completed Orders
              </span>
            }
            key="Completed Orders"
            
          >
            <Table
              loading={tableLoading}
              dataSource={
                filteredArr.length > 0 ? filteredArr : completedOrders
              }
              columns={columns}
              pagination={{onChange:onPaginationChange,total:currentCategoryTotalCount,showSizeChanger:true,onShowSizeChange:onShowSizeChange}}
             
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <i
                  className="fa fa-truck"
                  aria-hidden="true"
                  style={{fontSize: '20px', marginRight: '6%'}}
                />
                Delivered Orders
              </span>
            }
            key="Delivered Orders"
            
          >
            <Table
              loading={tableLoading}
              dataSource={
                filteredArr.length > 0 ? filteredArr : deliveredOrders
              }
              columns={columns}
              pagination={{onChange:onPaginationChange,total:currentCategoryTotalCount,showSizeChanger:true,onShowSizeChange:onShowSizeChange}}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <StopOutlined style={{fontSize: '20px'}} />
                Cancelled Orders
              </span>
            }
            key="Cancelled Orders"
            
          >
            <Table
              loading={tableLoading}
              dataSource={
                filteredArr.length > 0 ? filteredArr : cancelledOrders
              }
              columns={columns}
              pagination={{onChange:onPaginationChange,total:currentCategoryTotalCount,showSizeChanger:true,onShowSizeChange:onShowSizeChange}}
            />
          </TabPane>

          <TabPane
            tab={
              <span style={{fontSize: '15px'}}>
                <ShoppingCartOutlined style={{fontSize: '20px'}} />
                All Orders
              </span>
            }
            key="All Orders"
            
          >
            <Table
              loading={tableLoading}
              dataSource={filteredArr.length > 0 ? filteredArr : allOrders}
              columns={columns}
              pagination={{onChange:onPaginationChange,total:currentCategoryTotalCount,showSizeChanger:true,onShowSizeChange:onShowSizeChange}}
            />
          </TabPane>
        </Tabs>
        

        <Modal
          title={'Edit Order Amount'}
          visible={isOrderModalVisible}
          onOk={handleOrderOk}
          onCancel={handleOrderCancel}
          confirmLoading={confirmOrderLoading}
        >
          <form>
            <div className="form-group">
              <label><h6>Total Amount :</h6></label>
              <input
                type="number"
                className="form-control"
                value={totalAmount}
                onChange={e => {
                  setTotalAmount (e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <label><h6>Paid Amount :</h6></label>
              <input
                type="number"
                className="form-control"
                value={paidAmount}
                onChange={e => {
                  setPaidAmount (e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label><h6>Partial Amount :</h6></label>
              <input
                type="number"
                className="form-control"
                value={partialAmount}
                onChange={e => {
                  setPartialAmount (e.target.value);
                }}
              />
            </div>
          </form>
        </Modal>

        {/* <Modal 
            title={"Invoice Modal"}
            visible={invoiceModal} 
            onOk={confirmInvoiceModal} 
            onCancel={()=>{setInvoiceModal(false)}}
            confirmLoading={invoiceModalLoading}
         >
             <form>
             <div className="form-group">
             <label><h6>Total Amount :</h6></label>
               <input 
               type="number" 
               className="form-control"
               value={totalAmount} 
               onChange={(e)=>{setTotalAmount(e.target.value)}}/>
             </div>

             <div className="form-group">
             <label><h6>Paid Amount :</h6></label>
               <input 
               type="number" 
               className="form-control"
               value={paidAmount} 
               onChange={(e)=>{setPaidAmount(e.target.value)}}/>
             </div>
             <div className="form-group">
             <label><h6>Partial Amount :</h6></label>
               <input 
               type="number" 
               className="form-control"
               value={partialAmount} 
               onChange={(e)=>{setPartialAmount(e.target.value)}}/>
             </div>
             </form>
        </Modal> */}

        <Modal
          title={'Cancellation Reason'}
          visible={cancelOrderModal}
          onOk={handleCancelOrderModal}
          onCancel={() => {
            showCancelOrderModal (false);
          }}
          confirmLoading={confirmCancelOrderModal}
        >
          <form>
            <div>
              <label><h6>Enter Cancellation Reason:</h6></label>
              <input
                type="text"
                className="form-control"
                value={cancellationReason}
                onChange={e => {
                  setCancellationReason (e.target.value);
                }}
              />
            </div>
          </form>
        </Modal>

        <Modal
          title={'Assign Order'}
          visible={isAssignModalVisible}
          onOk={handleAssignOk}
          onCancel={handleAssignCancel}
          confirmLoading={confirmAssignLoading}
        >
          <form>
            <div className="form-group">
              <label><h6>Select User To Assign : </h6></label>
              {/* <select  required className="form-control" onChange={(e)=>{handleAssignToChange(e.target.value)}}>
                        <option>Select User</option>   
                        {   allUsers &&
                            allUsers.map((user)=>{
                                if(user.roles[0].name==='employee' || user.roles[0].name==='manager' ){
                                    return <option key={user._id} value={user.username} selected={user.username===assignTo}>
                                    {user.username}
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    ( {user.roles[0].name} )
                                    </option>

                                }
                            })
                        } 
                    </select> */}

              <Select
                showSearch
                virtual={true}
                style={{width: '100%'}}
                onChange={handleAssignToChange}
                defaultValue={assignTo}
                placeholder="Search or Select Employee or Manager"
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase ().indexOf (input.toLowerCase ()) >=
                  0}
                filterSort={(optionA, optionB) =>
                  optionA.value
                    .toLowerCase ()
                    .localeCompare (optionB.value.toLowerCase ())}
              >
                {allUsers &&
                  allUsers.map (user => {
                    return (
                      <Option key={user._id} value={user.username}>
                        {user.username}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        ( {user.roles[0].name} )
                      </Option>
                    );
                  })}
              </Select>

            </div>
          </form>
        </Modal>

        <Modal
          title={'Add Order Category'}
          visible={isAddOrderCategory}
          onOk={handleNewCategoryOk}
          onCancel={handleNewCategoryCancel}
          confirmLoading={confirmNewCategoryLoading}
        >
          <form>
            <div className="form-group">
              <label><h6>Enter Category :</h6></label>
              <input
                type="text"
                className="form-control"
                value={newOrderCategory}
                onChange={e => {
                  setNewOrderCategory (e.target.value);
                }}
              />
            </div>
          </form>
        </Modal>

        <Modal
          title={'Add Progress'}
          visible={isProgress}
          onOk={handleProgressSubmit}
          onCancel={handleProgressClose}
          confirmLoading={confirmProgressUpload}
        >
          <form>
            <div className="form-group">
              <label><h6>Enter Progress Percentage :</h6></label>
              <input
                type="Number"
                className="form-control"
                value={progressPercentage}
                onChange={e => {
                  setProgressPercentage (e.target.value);
                }}
              />
            </div>
          </form>
        </Modal>

        <Modal
          title={'Download Invoice'}
          visible={invoiceModal}
          onCancel={() => {
            setInvoiceModal (false);
          }}
          footer={null}
        >
          <div>
            <center>
              <div style={{marginBottom: '3%'}}>
                <img src={invoiceDownload} alt="download" />
              </div>
              <a
                href={currentInvoiceData.invoiceUrl}
                download
                target={'_blank'}
              >
                <Button type={'primary'}>Download Invoice</Button>
              </a>
            </center>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default ViewOrders;
