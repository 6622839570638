import React from 'react';
import groupIcon from '../../assets/group.png';
import user from '../../assets/user.png';
import {DeleteOutlined,EyeOutlined,MoreOutlined,EditOutlined} from '@ant-design/icons';
import {Dropdown,Menu} from 'antd';


function SingleGroupConversation({groupName,queryGroup,isSales,profilePicture,isAdmin,isUser,editGroupMembers,notification,notificationMsg,active,handleGroupDelete, groupId,handleCurrentGroup}) {
    return (
        <div className={`singleChatList ${active && 'activeChat'}`} onClick={()=>{handleCurrentGroup(groupId)}}>
            <div>
                <img src={profilePicture ? profilePicture: queryGroup ? isUser ? groupIcon:user:groupIcon} alt="dp" />
               
            </div>
            {active ? null:
                notification &&  
                    <div className="badge chatNotificationBadge">
                        1
                    </div>}
           <div className="singleChatAllInfo">
           <div className='convInfo'>
            <h5>{groupName}</h5>
            {active ? null:
                    notification && 
                    <div className="notification-msg">
                    <div>{notificationMsg.substr(0,15)}..</div>
                    </div>
                    
                }
            </div>
           
           {queryGroup ? null :(
            isAdmin && 
          
                   
          <Dropdown overlay={
                <Menu style={{marginRight:'5%',marginTop:'-5%',width:'fit-content'}}>
               
                <Menu.Item key="1" onClick={()=>{editGroupMembers(groupId)}}>
                 <EditOutlined />Edit Details
                </Menu.Item>

                {isSales ? null:
                  <Menu.Item danger key="2" onClick={()=>{handleGroupDelete(groupId,groupName)}}>
                 <DeleteOutlined /> Delete Group
                </Menu.Item>
                }
                
              </Menu>
              }>
              <p ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
              </Dropdown>
           )}
              
           </div>
        </div>
    )
}

export default SingleGroupConversation
