import userService from "../services/user.service";
import { SETHOMEDETAILS,HOMEPAGEERROR,SET_NOTIFICATIONS} from "./types";


export const setHomeDetails = (payload)=>{
    return{
        type:SETHOMEDETAILS,
        payload:payload
    }
}

export const setError = (err)=>{
    return{
        type:HOMEPAGEERROR,
        payload:err
    }
}

export const setNotifications = (payload)=>{
    return{
        type:SET_NOTIFICATIONS,
        payload:payload
    }
}


export const getHomePageDetails = ()=>{
    
    return(async dispatch=>{
        try{
       const response = await  userService.getHomeDetails()
            console.log("I ran !", response);
            const data = {
                id:response.data[0]._id,
                heading:response.data[0].heading,
                details:response.data[0].details,
                logoText:response.data[0].logoText,
                
            }
            dispatch(setHomeDetails(data));
        }catch(err){
            dispatch(setError(err));
        }
    })
    
}

export const getNotifications = (id)=>{
    return(async dispatch=>{
        try{
       const response = await  userService.getSpecificNotification(id)
       let data = []
       response.data.map((item)=>{
         let finalDate = ""
         if(item.createdAt){
           const date = new Date(item.createdAt);
           let dd = date.getDate();
           let mm = date.getMonth() + 1;
           let yyyy = date.getFullYear();
           let hh = date.getHours();
           let minutes = date.getMinutes();
           let ss = date.getSeconds();
          finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
         }
         let modifiedData = {
           ...item,
           date:finalDate
           
         }
         data.push(modifiedData);
       })
       let sortedData = data.sort((item1,item2)=>{
         return new Date(item2.createdAt)- new Date(item1.createdAt); 
       })
            
        dispatch(setNotifications(sortedData));
        }catch(err){
            console.log(err);
        }
    })
}