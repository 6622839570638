import axios from 'axios';
import authHeader from './auth-header';

// var  API_URL = "http://3.7.156.63/api/test/";
// var  API_URL = "http://3.7.156.63:3000/api/test/";
// var API_URL = 'http://localhost:3000/whatsapp/';

var API_URL = 'https://dashboard.codersarts.com/whatsapp/';

const sendWhatsappMessage = data => {
  return axios.post (API_URL + 'send-whatsapp-message', data, {
    headers: authHeader (),
  });
};

const sendIndidualWhatsappMessage = data => {
  return axios.post (API_URL + 'send-individual-message', data, {
    headers: authHeader (),
  });
};

const createWhatsappUser = data => {
  return axios.post (API_URL + 'create-whatsapp-user', data, {
    headers: authHeader (),
  });
};

const getAllWhatsappUsers = () => {
  return axios.get (API_URL + 'get-all-whatsppUser', {
    headers: authHeader (),
  });
};

const getAllTemplates = () => {
  return axios.get (API_URL + 'get-all-templates', {
    headers: authHeader (),
  });
};

const updateWhatsappUser = data => {
  return axios.post (API_URL + 'update-whatsapp-user', data, {
    headers: authHeader (),
  });
};

const deleteWhatsappUser = data => {
  return axios.post (API_URL + 'delete-whatsapp-user', data, {
    headers: authHeader (),
  });
};

const getWhatsappUserMessage = data => {
  return axios.post (API_URL + 'get-whatsapp-user-messages', data, {
    headers: authHeader (),
  });
};

const WhatsappService = {
  sendWhatsappMessage,
  createWhatsappUser,
  getAllWhatsappUsers,
  getAllTemplates,
  updateWhatsappUser,
  deleteWhatsappUser,
  sendIndidualWhatsappMessage,
  getWhatsappUserMessage,
};

export default WhatsappService;
