import React, {useEffect,useState} from 'react';
import orderService from '../../../services/order.service';
import { message,DatePicker,Select,Tabs,Modal,Input, Button,Table} from 'antd';
import {useSelector} from 'react-redux';
import avatar from '../../../assets/user.png';
import {history} from '../../../helpers/history';
import './AllAttendance.css';
import {Link} from 'react-router-dom';
import { SearchOutlined,PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import userService from '../../../services/user.service';

const {Option} = Select;
const {TabPane} = Tabs;
const dateFormat = 'YYYY/MM/DD';


function AllAttendance () {
  const [data,setData] = useState([]);
  const [filteredData,setFilteredData] = useState([]);
  const [addLeaveTypeModal,setAddLeaveType] = useState(false);
  const [leaveType,setLeaveType] = useState("");
  const [allLeaves,setAllLeaves] = useState([]);
  const [filteredLeave,setFilteredleave] = useState([]);
  const [statusFilterArr,setStatusFilterArr] = useState([]);
  // let now = new Date ();
  // let dd = now.getDate ();
  // let mm = now.getMonth () + 1;
  // let yyyy = now.getFullYear ();
  // let currentDate = yyyy + '-' + mm + '-' + dd;
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  useEffect (
    () => {
      const getAllAttendance = async () => {
        try {
          const response = await orderService.getAllAttendance ();
          // console.log (response.data);
          let updatedData = [];

          response.data.forEach((item)=>{
            if(!item.isLeave){
              let checkinTime = new Date(item.checkin);
            let checkinDD = checkinTime.getDate();
            let checkinMM = checkinTime.getMonth() + 1;
            let checkinYYYY = checkinTime.getFullYear();
            let checkinHR = checkinTime.getHours();
            let checkinMin = checkinTime.getMinutes();
            let checkinSec = checkinTime.getSeconds();
            let finalCheckin = checkinDD + 
            "-"+checkinMM+"-"+checkinYYYY+" at "
             + checkinHR + ":" + checkinMin + ":"+checkinSec

             let finalCheckout = "Not Available"
             if(item.checkout){
               let checkoutTime = new Date(item.checkout)
               let checkoutDD = checkoutTime.getDate();
               let checkoutMM = checkoutTime.getMonth() + 1;
               let checkoutYYYY = checkoutTime.getFullYear();
               let checkoutHR = checkoutTime.getHours();
               let checkoutMin = checkoutTime.getMinutes();
               let checkoutSec = checkoutTime.getSeconds();
               finalCheckout = checkoutDD + 
               "-"+checkoutMM+"-"+checkoutYYYY+" at "
                + checkoutHR + ":" + checkoutMin + ":"+checkoutSec
             }
            
            let modifiedData = {
              id:item._id,
              username:item?.attendanceUser[0]?.username,
              userEmail:item?.attendanceUser[0]?.email,
              userProfilePicture:item?.attendanceUser[0]?.profilePicture,
              userRole:item?.attendanceUser[0]?.roles[0]?.name,
              userId:item?.attendanceUser[0]?._id,
              checkin:finalCheckin,
              checkout:finalCheckout,
              workedHours:item?.workedHours,
              checkInDate:item?.checkInDate,
              createdAt:item.createdAt
            }

            updatedData.push(modifiedData);
            }
          })


          setData(updatedData);

        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

     

      getAllAttendance ();
    },
    [currentUser]
  );

  const getAllLeaves = async () => {
    try {
      const response = await orderService.getAllLeave ();
      // console.log ("leaves : ",response.data);
      let data = []
      response?.data.forEach((item)=>{
        const date = new Date (item.createdAt);
        let dd = date.getDate ();
        let mm = date.getMonth () + 1;
        let yyyy = date.getFullYear ();
        let hh = date.getHours ();
        let minutes = date.getMinutes ();
        let ss = date.getSeconds ();
        let finalDate =
          dd +
          '-' +
          mm +
          '-' +
          yyyy +
          ' at ' +
          hh +
          ':' +
          minutes +
          ':' +
          ss;


        let modifiedData = {
          createdAt:finalDate,
          sortDate:item.createdAt,
          leaveId:item._id,
          username:item?.leaveUser[0]?.username,
          userEmail:item?.leaveUser[0]?.email,
          userProfilePicture:item?.leaveUser[0]?.profilePicture,
          userRole:item?.leaveUser[0]?.roles[0]?.name,
          userId:item?.leaveUser[0]?._id,
          leavePeriod:`${item.leaveDays[0]} to ${item.leaveDays[1]}`,
          leaveType:item.leaveType[0].name,
          numberOfLeaves:item.numberOfLeaves,
          leaveStatus:item.status,
          leaveDescription:item.leaveMessage,
          leaveDates:item.leaveDays
        }
        data.push(modifiedData)
      });
      // console.log("final data : ",data);
      setAllLeaves(data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };



  useEffect (
    () => {
      const getAllLeaves = async () => {
        try {
          const response = await orderService.getAllLeave ();
          // console.log ("leaves : ",response.data);
          let data = []
          response?.data.forEach((item,index)=>{
            const date = new Date (item.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            let finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;


            let modifiedData = {
              key:index+1,
              createdAt:finalDate,
              sortDate:item.createdAt,
              leaveId:item._id,
              username:item?.leaveUser[0]?.username,
              userEmail:item?.leaveUser[0]?.email,
              userProfilePicture:item?.leaveUser[0]?.profilePicture,
              userRole:item?.leaveUser[0]?.roles[0]?.name,
              userId:item?.leaveUser[0]?._id,
              leavePeriod:`${item.leaveDays[0]} to ${item.leaveDays[1]}`,
              leaveType:item.leaveType[0].name,
              numberOfLeaves:item.numberOfLeaves,
              leaveStatus:item.status,
              leaveDescription:item.leaveMessage,
              leaveDates:item.leaveDays
            }
            data.push(modifiedData)
          });
          // console.log("final data : ",data);
          setAllLeaves(data);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

     

      getAllLeaves ();
    },
    []
  );

  useEffect (
    () => {

      let statusFilter = [];
      allLeaves.map (o => {

        if (o.leaveStatus) {
          statusFilter.push ({text: o.leaveStatus, value: o.leaveStatus});
        }
      });
    

      statusFilter = statusFilter.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

    
      setStatusFilterArr (statusFilter);
    
    },
    [allLeaves]
  );


  const handleLeaveStatusChange = async (value,{userId,leaveId,leavePeriod,leaveDates})=>{
    // console.log(value,userId);
    // console.log("leave dates : ",leaveDates)
    try{
      const response = await orderService.updateLeave({id:leaveId,status:value});
      if(response){
        // console.log(response.data);
        message.success(`Leave updated with status ${value}`);
        getAllLeaves();
        userService.createNewNotification ({
          notification: value!=='Pending' ? `YOUR LEAVE IS ${value}: Your Leave for ${leavePeriod} is ${value} by admin` : `YOUR LEAVE IS ${value}: Your Leave for ${leaveDates} is ${value}. Maybe Admin hasn't seen it yet!`,
          user: userId,
        })
          .then (result => {
            if (result) {
            }
          })
          .catch (Err => {
            console.log (Err);
          });
         if(value==='Approved'){
         
          let daylist = getDaysArray(new Date(leaveDates[0]),new Date(leaveDates[1]));
          
          // console.log(daylist);
          for(let item in daylist){
            // console.log(daylist[item])
            handleGetLeave(daylist[item],userId);
            // console.log("finished");
          }

         }else{
          let daylist = getDaysArray(leaveDates[0],leaveDates[1]);
          
          // console.log(daylist);
          for(let item in daylist){
            // console.log(daylist[item])
            deleteLeaveOfUser(userId,daylist[item])
            // console.log("delete finished");
          }

           
         } 
      }
    }catch(err){
      console.log(err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  }

  var getDaysArray = function(start, end) {
    // for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
    //   console.log(dt);
    //     arr.push(new Date(dt));
    // }
    // arr = arr.map((v)=>v.toISOString().slice(0,10));
    // console.log(arr);
    // return arr;
    var dateArray = [];
    var currentDate = moment(start);
    var stopDate = moment(end);
    while (currentDate <= stopDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    // console.log(dateArray);
    return dateArray;
};

const deleteLeaveOfUser = async (userId,date)=>{
  try{
    const response = await orderService.deleteLeaveAttendance({userId:userId,checkInDate:date})
    if(response){
      // console.log(response.data);
    }
  }catch(err){
    console.log(err);
  }
}


  const handleGetLeave = async (date,userId) => {
    let today = new Date ();
    // console.log (today);
    try {
      let data = {
        attendanceUser: userId,
        checkin: today.toISOString (),
        checkInDate: date,
        isLeave:true
      };
      // console.log (data);
      const response = await orderService.createNewAttendance (data);
      // console.log (response);
     
      
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };




  const columns = [
    {
      title: 'User',
      key: 'user',
      render: (record) => {
        return(
          <Link to={`/profile/manage-users/view/${record.userId}`}>
       <div style={{display:'flex'}}>
       <div className='tableProfilePicWrapper'>
       <div>
       <img className='tableProfilePic' src={record.userProfilePicture ? record.userProfilePicture : avatar} alt="" />
       </div>
        <div className='badge'>{record.userRole}</div>
        </div>

        <div style={{marginLeft:'10px'}}>
          <div>
              <b>{record.username}</b>
          </div>

          <div>
            <small style={{color:'gray'}}>{record.userEmail}</small>
          </div>
      </div>
       </div>
        
       

      </Link>
        )
      },
    },
    {
      title: 'Check-In At',
      dataIndex: 'checkin',
      key: 'checkin',
    },
    {
      title: 'Check-Out At',
      dataIndex: 'checkout',
      key: 'checkout',
    },
    {
      title: 'Worked Hours',
      dataIndex: 'workedHours',
      key: 'workedHours',
    },
   
  ] 

  const leaveColumns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: '1',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    
    {
      title: 'User',
      key: '2',
      render: (record) => {
        return(
          <Link to={`/profile/manage-users/view/${record.userId}`}>
       <div style={{display:'flex'}}>
       <div className='tableProfilePicWrapper'>
       <div>
       <img className='tableProfilePic' src={record.userProfilePicture ? record.userProfilePicture : avatar} alt="" />
       </div>
        <div className='badge'>{record.userRole}</div>
        </div>

        <div style={{marginLeft:'10px'}}>
          <div>
              <b>{record.username}</b>
          </div>

          <div>
            <small style={{color:'gray'}}>{record.userEmail}</small>
          </div>
      </div>
       </div>
        
       

      </Link>
        )
      },
    },
    {
      title: 'Leave Period',
      dataIndex: 'leavePeriod',
      key: '3',
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      key: '4',
    },
    {
      title: 'Number of Leaves',
      dataIndex: 'numberOfLeaves',
      key: '5',
    },
    {
      title: 'Leave Status',
      key: '6',
      filters: statusFilterArr,
      onFilter: (value, record) => record.leaveStatus.indexOf (value) === 0,
      render:(record)=>{
        return(<b class={`${record.leaveStatus==='Pending' ? 'yellow' : record.leaveStatus==='Approved' ? 'green' : 'red'}`}>{record.leaveStatus}</b>)
      }
    },
    {
      title: 'Action',
      key: 'action',
      render:(record)=>{
        return(
        <Select
          placeholder="Change Status"
          defaultValue={record.leaveStatus}
          onChange={(value)=>{handleLeaveStatusChange(value,record)}}
        >
          <Option key="1" value={'Approved'}>Approved</Option>
          <Option key="2" value={'Rejected'}>Rejected</Option>
          <Option key="3" value={'Pending'}>Pending</Option>
        </Select>
       )
      }
    }
  ]
 
  const handleSearch = (e)=>{
    
    let query = e.target.value;

    const filteredData = data.filter((item)=>{
      return query && (item.username.toLowerCase().match(query.toLowerCase()) || item.userEmail.toLowerCase().match(query.toLowerCase()))
    });

    // console.log(filteredData);
    setFilteredData(filteredData);

  }

  const handleSearchByDate = (e)=>{
    // console.log(e.target.value)
    let searchDate = new Date(e.target.value);
    const filteredData = data.filter((item)=>{
      let checkInDate = new Date(item.checkInDate)

      // console.log(searchDate);
      // console.log(checkInDate);
      return searchDate && checkInDate.getTime()===searchDate.getTime()
    });
    // console.log(filteredData);
    setFilteredData(filteredData)
  }

  const handleAddLeaveType = ()=>{
    setAddLeaveType(true);
  }

  const handleAddLeaveTypeSubmit = async ()=>{
    // console.log("Leave type : ",leaveType)
    try{
      const response = await orderService.createNewLeaveType({name:leaveType})
      // console.log(response.data);
      message.success(`${leaveType} is Added as New Leave Type!`);
      setLeaveType("");
      setAddLeaveType(false);
    }catch(err){
      console.log(err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  }

  const handleSearchLeaveByDate = (date,dateString)=>{
  if(date){
    // console.log(dateString)
    const filteredData = allLeaves.filter((item)=>{
      var from = Date.parse(item.leaveDates[0]);
      var to   = Date.parse(item.leaveDates[1]);
      var check = Date.parse(dateString );

      return check <= to && check >= from     
    
    });
    // console.log(filteredData);
    setFilteredleave(filteredData)
  }else{
    setFilteredleave([])
  }
  }

  const handleSearchLeaveByUsername = (e)=>{
    let query = e.target.value;

    const filteredData = allLeaves.filter((item)=>{
      return query && (item.username.toLowerCase().match(query.toLowerCase()) || item.userEmail.toLowerCase().match(query.toLowerCase()))
    });
  setFilteredleave(filteredData);
  }


  return(
  <div className='allAttendance'>
    <Tabs
        type="card"
        defaultActiveKey="1"
        size={'large'}
        style={{marginBottom: 32}}
      >
        <TabPane tab="All Attendance" key="1">
     <>
          <div className="searchHeader">
        <div>
        <h2>All Attendance</h2>
        </div>

          <div className="search">
                  <SearchOutlined className="search-icon" />
                  <input type="text" onChange={handleSearch} placeholder="Search By Username or Email" />
          </div>
          <div className="searchByDate">
          <label>Search By Date</label>
          <input type="date" onChange={handleSearchByDate} />
          </div>
          </div>
          <Table dataSource={filteredData.length > 0 ? filteredData : data } columns={columns} />
     </>
  
        </TabPane>
        <TabPane tab="All Leaves" key="2">
        <div className="searchHeader">
        <div>
        <h2>All Leaves</h2>
        </div>

          <div className="search">
            <SearchOutlined className="search-icon" />
            <input type="text" onChange={handleSearchLeaveByUsername} placeholder="Search By Username or Email" />
          </div>
          <div>
          <DatePicker placeholder='Filter By Date' format={dateFormat} onChange={handleSearchLeaveByDate} />
          </div>
          <div>
            <Button type="primary" onClick={handleAddLeaveType}>
              <PlusOutlined />
              Add Leave Type
            </Button>
          </div>
          </div>
          <Table 
          expandable={{
              expandedRowRender: record => <p style={{ margin: 0 }}>{record.leaveDescription}</p>,
            }}
          dataSource={filteredLeave.length > 0 ? filteredLeave : allLeaves } columns={leaveColumns} />
        </TabPane>
    </Tabs>
   

    <Modal
          title={'Updated Section'}
          visible={addLeaveTypeModal}
          onCancel={() => {
            setAddLeaveType(false);
          }}
          onOk={handleAddLeaveTypeSubmit}
        >
          <div>
            <label>Enter New Leave Type</label>
            <Input 
            value={leaveType}
             onChange={(e)=>{setLeaveType(e.target.value)}} 
              placeholder="e.g. Sick Leave" />
          </div>
        </Modal>

  </div>
  );
}

export default AllAttendance;
