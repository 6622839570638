import {Bar} from 'react-chartjs-2';

export const Chart = ({chartData, month}) => {
  return (
    <div>
      <Bar
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: `${month} Work Hours`,
              font: {
                size: 25,
              },
            },
            legend: {
              display: true,
              position: 'bottom',
            },
          },
        }}
      />
    </div>
  );
};
