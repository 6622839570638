import React, {useState, useEffect} from 'react';
import OrderService from '../../../services/order.service';
import {Table,Button, message} from 'antd';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import moment from 'moment';

function ContactUsData () {
  const [allContactUs, setAllContactUs] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        if (currentUser.roles.includes ('ROLE_SALES')) {
          return;
        }
        history.push ('/profile');
      }
    },
    [currentUser]
  );
  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Message',
      key: 'message',
      width: '30vw',
      render:(record)=>{
          return <div>{record?.message?.slice(0,30)}...</div>
      }
    },
  ];

  const getContactUs = () => {
    OrderService.getContactUsFormData ()
      .then (response => {
        const finalData = [];
        // console.log(response.data);

        response.data.forEach ((data, index) => {
          let finalDate = null;
          if (data.createdAt) {
            const date = new Date (data.createdAt);
            let dd = date.getDate ();
            let mm = date.getMonth () + 1;
            let yyyy = date.getFullYear ();
            let hh = date.getHours ();
            let minutes = date.getMinutes ();
            let ss = date.getSeconds ();
            finalDate =
              dd +
              '-' +
              mm +
              '-' +
              yyyy +
              ' at ' +
              hh +
              ':' +
              minutes +
              ':' +
              ss;
          }
          let updatedField = {
            createdAt: finalDate,
            sortDate: data.createdAt,
            key: index,
            name: data.name,
            email: data.email,
            message: data.message,
            phoneNumber: data.phoneNumber ? data.phoneNumber : '...',
          };

          finalData.push (updatedField);
        });
        setAllContactUs (finalData);
      })
      .catch (err => {
        console.log (err);
        message.error (
          err.message ? err.message : 'Unable to fetch contact us form data!'
        );
      });
  };
  useEffect (() => {
    getContactUs ();
  }, []);
  return (
    <div>
        <div className="d-flex justify-content-between">
        <h2>All Public Messages</h2>
      <Button onClick={getContactUs} type="primary">Refresh</Button>
        </div>
      <hr />
      <Table dataSource={allContactUs} expandable={{
      expandedRowRender: (record) => (
        <div
          
        >
          {record.message}
        </div>
      )
    }}
     columns={columns} />;
    </div>
  );
}

export default ContactUsData;
