import React, { useEffect,useState } from 'react'
import orderService from '../../../services/order.service';
import './SingleBookingDetails.css';
import {message,Button,Tag,Breadcrumb} from 'antd';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import {Link} from 'react-router-dom';


function SingleBookingDetails({match}) {
    const [bookingDetails,setBookingDetails] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);


    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            if(currentUser.roles.includes('ROLE_USER')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getBookingDetails = async ()=>{
        try{
            const response = await orderService.getBookingById(match.params.slug);
            // console.log("Booking details : ",response.data);
            setBookingDetails(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch booking details!');
        }
    }
    useEffect(()=>{
        getBookingDetails();
    },[match.params.slug])
    return (
        <div className='bookingDetails'>
        <Breadcrumb>
            <Breadcrumb.Item>{currentUser.roles.includes('ROLE_USER') ? 
            <Link to="/profile/my-bookings">My Bookings</Link>
            :
            <Link to="/profile/all-bookings">All Bookings</Link>
            }</Breadcrumb.Item>
            <Breadcrumb.Item> <Link to={`/profile/booking-details/${match.params.slug}`}>{bookingDetails && bookingDetails[0] && bookingDetails[0].isMobileBooking ? bookingDetails[0].bookingNameForMobile : bookingDetails && bookingDetails[0] && bookingDetails[0].booking && bookingDetails[0].booking[0] ? bookingDetails[0].booking[0].name : match.params.slug}</Link></Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="mt-2">Booking Details</h1>
        <hr />
            <div className='card'>
                {/* <div className='details-item'>
                    <span className='bookingLabel'>Id : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].booking ? bookingDetails[0]._id : "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Created At : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].createdAt ?  bookingDetails[0].createdAt: "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Name : </span>
                    <span>{bookingDetails && bookingDetails[0] && bookingDetails[0].booking ? bookingDetails[0].booking[0].name : "N/A"}</span>
                </div>
                <div className='details-item'>
                    <span className='bookingLabel'>Details : </span>
                    <span><Tag color={'cyan'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].details ? bookingDetails[0].details : "N/A"}</Tag></span>
                </div>

                <div className='details-item'>
                    <span className='bookingLabel'>Meeting Date : </span>
                    <span><Tag color={'red'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].date ? bookingDetails[0].date : "N/A"}</Tag></span>
                </div>

                <div className='details-item'>
                    <span className='bookingLabel'>Meeting Timezone : </span>
                    <span><Tag color={'blue'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].timezone ? bookingDetails[0].timezone : "N/A"}</Tag></span>
                </div> */}


                <table>
                <tr>
                    <th>Id : </th>
                    <td>{bookingDetails && bookingDetails[0] && bookingDetails[0].booking ? bookingDetails[0]._id : "N/A"}</td>
                </tr>
                <tr>
                    <th>Created At : </th>
                    <td>{bookingDetails && bookingDetails[0] && bookingDetails[0].createdAt ?  bookingDetails[0].createdAt: "N/A"}</td>
                </tr>
                <tr>
                    <th>Name : </th>
                    <td>{bookingDetails && bookingDetails[0] && bookingDetails[0].isMobileBooking ? bookingDetails[0].bookingNameForMobile :bookingDetails && bookingDetails[0] && bookingDetails[0].booking && bookingDetails[0].booking[0] ? bookingDetails[0].booking[0].name : "N/A"}</td>
                </tr>
                

                <tr>
                    <th>Meeting Date : </th>
                    <td><Tag color={'red'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].date ? bookingDetails[0].date : "N/A"}</Tag></td>
                </tr>

                <tr >
                    <th>Meeting Timezone : </th>
                    <td><Tag color={'blue'}> {bookingDetails && bookingDetails[0] && bookingDetails[0].timezone ? bookingDetails[0].timezone : "N/A"}</Tag></td>
                </tr>
                {!currentUser.roles.includes('ROLE_USER') &&
                <tr >
                    <th>Booking By : </th>
                    <td><Link to={`/profile/manage-users/view/${bookingDetails && bookingDetails[0] && bookingDetails[0].bookingBy ? bookingDetails[0].bookingBy[0]._id : "/"}`}> <b>{bookingDetails && bookingDetails[0] && bookingDetails[0].bookingBy ? bookingDetails[0].bookingBy[0].username : "N/A"}</b> </Link></td>
                </tr>
                }
                {!currentUser.roles.includes('ROLE_USER') && bookingDetails && bookingDetails[0] && bookingDetails[0].booking && bookingDetails[0].booking[0] && bookingDetails[0].booking[0].image  &&
                <tr >
                    <th>Action : </th>
                    <td><Button type={'primary'}><a href={bookingDetails && bookingDetails[0] && bookingDetails[0].booking && bookingDetails[0].booking[0].image ?  bookingDetails[0].booking[0].image : '/'} target={'_blank'}> <b>View Image</b> </a></Button></td>
                </tr>
                }
                </table>

                <div>
                    <span><b>Details : </b></span>
                    <div className='detailsWidth'> {bookingDetails && bookingDetails[0] && bookingDetails[0].details ? bookingDetails[0].details : "N/A"}</div>
                </div>
            </div>
        </div>
    )
}

export default SingleBookingDetails
