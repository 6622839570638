import axios from 'axios';

// var API_URL  = "http://3.7.156.63:3000/api/";
// var API_URL  = "http://3.7.156.63/api/";
// var API_URL  = "http://3.111.82.112:3000/api/";
var API_URL = 'https://dashboard.codersarts.com/api/';
// var API_URL = 'http://localhost:3000/api/';

//  if (process.env.NODE_ENV === "production") {
//   API_URL = "https://codersarts-employee-dashboard.herokuapp.com/api/";
// }else if(process.env.NODE_ENV === "development"){
//   API_URL = "http://52.66.212.70/api/";
// }

const createConversation = async (senderId, receiverId) => {
  return axios.post (API_URL + 'conversations', {senderId, receiverId});
};

const createGroupConversations = async (groupName, members) => {
  return axios.post (API_URL + 'groupConversations', {
    groupName: groupName,
    members: members,
  });
};

const createGroupMessage = async data => {
  return axios.post (API_URL + 'groupConversations/create-message', data);
};

const getGroupConversations = async memberId => {
  return axios.get (API_URL + 'groupConversations/' + memberId);
};

const getGroupMessages = async groupId => {
  // console.log("groupId : ",groupId);
  return axios.post (API_URL + 'groupConversations/getGroupMessage', {
    groupId: groupId,
  });
};

const deleteGroup = async groupId => {
  // console.log("groupId : ",groupId);
  return axios.post (API_URL + 'groupConversations/deleteGroup', {id: groupId});
};

const isUserAGroupMember = async userId => {
  // console.log("groupId : ",groupId);
  return axios.post (API_URL + 'groupConversations/isUserAGroupMember', {
    id: userId,
  });
};

const getConversation = async conversationId => {
  return axios.get (API_URL + 'conversations/' + conversationId);
};

const getConversationOnline = async (firstUserId, secondUserId) => {
  return axios.get (
    API_URL + 'conversations/find/' + firstUserId + '/' + secondUserId
  );
};
const getMessages = async conversationId => {
  return axios.get (API_URL + 'messages/' + conversationId);
};

const sendMessage = async message => {
  return axios.post (API_URL + 'messages', message);
};

const createUserGroupChat = async userId => {
  return axios.post (API_URL + 'conversations/createUserChatGroup', {
    userId: userId,
  });
};

const getUserChatGroupById = async userId => {
  return axios.post (API_URL + 'conversations/getUserChatGroup', {
    userId: userId,
  });
};

const getAllUserChatGroup = () => {
  return axios.post (API_URL + 'conversations/getAllUserChatGroup');
};

const getAllUserChatMessages = id => {
  return axios.post (API_URL + 'conversations/getAllUserChatMessages', {
    id: id,
  });
};

const createUserChatGroupMessage = data => {
  return axios.post (
    API_URL + 'conversations/createUserChatGroupMessage',
    data
  );
};

const createNewChatConversation = data => {
  return axios.post (
    API_URL + 'conversations/createChatConversationWith',
    data
  );
};

// const getAllChatConversations = ()=>{
//   return axios.get(API_URL+"conversations/getAllChatConversations");
// }

const getSingleChatConversations = id => {
  return axios.post (API_URL + 'conversations/getSingleChatConversations', {
    id: id,
  });
};

const updateGroupData = data => {
  return axios.post (
    API_URL + 'groupConversations/update-members-to-group',
    data
  );
};

const deleteSingleChat = id => {
  return axios.post (API_URL + 'conversations/deleteSingleChat', {id: id});
};

const createBackendGroup = (id, username, profilePicture) => {
  return axios.post (API_URL + 'groupConversations/createBackendGroup', {
    id: id,
    username: username,
    profilePicture: profilePicture,
  });
};

const getQueryGroupName = username => {
  return axios.post (API_URL + 'groupConversations/getQueryGroupName', {
    username: username,
  });
};

export default {
  getConversation,
  getMessages,
  sendMessage,
  getConversationOnline,
  createConversation,
  createGroupConversations,
  getGroupConversations,
  createGroupMessage,
  getGroupMessages,
  deleteGroup,
  isUserAGroupMember,
  createUserGroupChat,
  getUserChatGroupById,
  getAllUserChatGroup,
  getAllUserChatMessages,
  createUserChatGroupMessage,
  createNewChatConversation,
  // getAllChatConversations,
  getSingleChatConversations,
  updateGroupData,
  deleteSingleChat,
  createBackendGroup,
  getQueryGroupName,
  // createUserChatConversationWithBackend
};
