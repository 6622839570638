import React,{useEffect, useState} from 'react'
import {Input,Tabs,Card,Button,message, Select, Tag, Modal} from 'antd';
import OrderServices from '../../../services/order.service';
import userService from '../../../services/user.service';
import { EditOutlined } from '@ant-design/icons';

const {TabPane} = Tabs;
const { TextArea } = Input;
const {Option} = Select;


function YoutubeInfoPanel() {
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [videoId,setVideoId] = useState("");
    const [allYoutubeInfo,setAllYoutubeInfo] = useState([]);
    const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);
    const [videoSkills,setVideoSkills] = useState([]);
    const [isYoutubeEditModal,setIsYoutubeEditModal] = useState(false);
    const [updatedTitle,setUpdatedTitle] = useState("");
    const [updatedDescription,setUpdatedDescription] = useState("");
    const [updatedVideoId,setUpdatedVideoId] = useState("");
    const [updatedVideoSkills,setUpdatedVideoSkills] = useState("");
    const [currentUpdateId,setCurrentUpdateId] = useState("");
    const [loadingYoutubeEdit,setLoadingYoutubeEdit] = useState(false);

    const handleAddVideo = async ()=>{
        let videoSkillsInCapital = [];
        for(let i=0;i<videoSkills.length;i++){
            videoSkillsInCapital.push(videoSkills[i].toUpperCase());
        }
        // console.log(videoSkillsInCapital);
        let data = {
            title:title,
            videoId:videoId,
            description:description,
            skills:videoSkills,
            skillsInUpper:videoSkillsInCapital
        }

        // console.log(data);
        try{
            const response = await OrderServices.createYoutubeVideoInfo(data);
            if(response){
                // console.log(response);
                message.success("Added Youtube Video Successfully!");
                setTitle("");
                setDescription("");
                setVideoId("");
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went wrong!');
        }
    }


    const getAllYoutubeInfo = async ()=>{
        try{
            const response = await OrderServices.getAllYoutubeInfo();
            if(response){
                // console.log(response);
                // message.success("Added Youtube Video Successfully!");
               setAllYoutubeInfo(response.data);
            }
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something went wrong!');
        }
    }

    useEffect(()=>{
        let isMounted = true;
        const getAllCategory = async () => {
            try {
              const response = await userService.getAllSkillsList ();
              // console.log(response.data);
              setSkillCategoriesArr (response.data);
            } catch (err) {
              console.log (err);
              message.error (err.message ? err.message : 'Something went wrong!');
            }
          };

          if(isMounted){
              getAllCategory();
          }

          return ()=>{
              isMounted = false;
          }

    },[]);

    const handleEditYoutubeModal = (prevData)=>{
        setCurrentUpdateId(prevData._id);
        setUpdatedTitle(prevData.title);
        setUpdatedVideoId(prevData.videoId);
        setUpdatedVideoSkills(prevData.skills ? prevData.skills : []);
        setUpdatedDescription(prevData.description);
        setIsYoutubeEditModal(true);
    }

    const handleUpdateYoutubeVideo = async ()=>{
        setLoadingYoutubeEdit(true);
        let videoSkillsInCapital = [];
        for(let i=0;i<updatedVideoSkills.length;i++){
            videoSkillsInCapital.push(updatedVideoSkills[i].toUpperCase());
        }
        // console.log(videoSkillsInCapital);
        let data = {
            title:updatedTitle,
            videoId:updatedVideoId,
            description:updatedDescription,
            skills:updatedVideoSkills,
            skillsInUpper:videoSkillsInCapital
        }
        // console.log("id : ",currentUpdateId);
        // console.log(data);
        try{
            const response = await OrderServices.updateYoutubeVideo(currentUpdateId,data);
            if(response){
                console.log(response);
                message.success(`${updatedTitle} updated successfully`);
                setLoadingYoutubeEdit(false);
                setIsYoutubeEditModal(false);
                getAllYoutubeInfo();
            }
        }catch(err){
            console.log(err);
            setLoadingYoutubeEdit(false);
            message.error(err.message ? err.message : 'Something went wrong!');
        }

    }

  return (
    <div>

        <h1>Youtube Video Info</h1>
        <hr />
        <Tabs defaultActiveKey="1" onChange={getAllYoutubeInfo}>
            <TabPane tab="Add Video" key="1">
            <div>
            <h3>Add Your Youtube Video Information</h3>
            <div>
            <Input style={{width:'30%',margin:'1rem'}} value={title} onChange={(e)=>setTitle(e.target.value)} placeholder="Enter Video Title e.g. Reactjs Introduction" />
            <Input style={{width:'30%',margin:'1rem'}} value={videoId} onChange={(e)=>setVideoId(e.target.value)} placeholder="Enter Video Id e.g. ptANwoNWpJM" />
            <Select
              showSearch
                placeholder="Select Skills"
                mode="multiple"
                optionFilterProp="children"
                style={{width:'30%',margin:'1rem'}}
                className="form-control-select"
                onChange={(value)=>setVideoSkills(value)}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            
            >
                {skillsCategoryArr.map((item)=>{
                    return(
                        <Option value={item.name} key={item._id}>{item.name}</Option>
                    )
                })}
            </Select>
            
            <TextArea
                style={{width:'96%',margin:'1rem'}}
                showCount
                maxLength={255}
                placeholder="Enter Video Description"
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
            />
            <Button type="primary"  style={{margin:'1rem'}} onClick={handleAddVideo}>Add</Button>
            </div>
        </div>
            </TabPane>
            <TabPane tab="All Videos" key="2">
               <>
               <h3>All Videos</h3>
                <div className="youtube-card-group">

                    {allYoutubeInfo.map(item=>{
                        return(
                            <Card className="youtube-card" key={item._id} style={{margin:'1rem'}}>
                                <div className='d-flex'>
                                <h4>{item.title}</h4>
                                <EditOutlined onClick={()=>handleEditYoutubeModal(item)}/>
                                </div>
                                <small className="badge">Video ID: {item.videoId}</small>
                                <p>
                                {item.description}
                                </p>
                                <div>
                                    {item?.skills?.map((skill,i)=>{
                                        return(<Tag color="cyan" key={i}>{skill}</Tag>)
                                    })}
                                </div>
                            </Card>
                        )
                    })}
                </div>

                <Modal
                    title={'Update Youtube Video'}
                    visible={isYoutubeEditModal}
                    onCancel={() => {
                        setIsYoutubeEditModal(false);
                    }}
                    confirmLoading={loadingYoutubeEdit}
                    onOk={handleUpdateYoutubeVideo}
                    >
                    <div>
                        <Input 
                        value={updatedTitle}
                        className="my-2"
                        onChange={(e)=>{setUpdatedTitle(e.target.value)}} 
                        placeholder="Enter a Updated Video Title" />
                         <Input  value={updatedVideoId} className="my-2" onChange={(e)=>setUpdatedVideoId(e.target.value)} placeholder="Enter Updated Video Id e.g. ptANwoNWpJM" />
                        <Select
                        showSearch
                            placeholder="Select Updated Skills"
                            mode="multiple"
                            value={updatedVideoSkills}
                            optionFilterProp="children"
                            style={{width:'100%'}}
                            className="my-2"
                            onChange={(value)=>setUpdatedVideoSkills(value)}
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        
                        >
                            {skillsCategoryArr.map((item)=>{
                                return(
                                    <Option value={item.name} key={item._id}>{item.name}</Option>
                                )
                            })}
                        </Select>
                        
                        <TextArea
                            showCount
                            maxLength={255}
                            className="my-2"
                            placeholder="Enter Updated Video Description"
                            value={updatedDescription}
                            onChange={(e)=>setUpdatedDescription(e.target.value)}
                        />
                    </div>
        </Modal>

               </>
            </TabPane>
          
        </Tabs>
       
    </div>
  )
}

export default YoutubeInfoPanel