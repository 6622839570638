import React,{useEffect} from 'react';
import CreateUserForm from './CreateUserForm/CreateUserForm';
import './CreateUsers.css';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { history } from '../../../helpers/history';

const CreateUsers = ()=>{
    const { user: currentUser } = useSelector((state) => state.auth);

    // useEffect(()=>{
    //     if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
    //         history.push('/profile');
    //     }
    // },[currentUser])
    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN') ){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])
    return(
        <div className="create-user-main">
            <div>
            <Breadcrumb>
            {currentUser.roles.includes('ROLE_SALES') ?
            <Breadcrumb.Item><Link to="/profile/users-for-sales-role">All Users</Link> </Breadcrumb.Item> : 
            <Breadcrumb.Item> <Link to="/profile/manage-users">Manage Users</Link></Breadcrumb.Item>}
            <Breadcrumb.Item> <Link to="/profile/create-users">Create Users</Link></Breadcrumb.Item>
            </Breadcrumb>
            </div>
            <h3>Create Users</h3>
            <hr />
            <CreateUserForm  />
        </div>
    )

}

export default CreateUsers;