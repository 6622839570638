import React,{useState,useEffect} from 'react';
import { Table,Space,Tag, Menu,Dropdown,message} from 'antd';
import { Link } from 'react-router-dom';
import orderService from './../../services/order.service';
import { ShoppingCartOutlined,EyeOutlined,MoreOutlined } from '@ant-design/icons';
import { history } from './../../helpers/history';
import { useSelector } from 'react-redux';
import moment from 'moment';






const PurchasedHistory = ()=>{
    const [allPayments,setAllPayments] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [filteredMethodArr,setFilteredMethodArr] = useState(null);
    const [filteredArr,setFilteredArr] = useState([]);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_USER')){
            history.push('/profile');
        }
    },[currentUser])



    const getPayments = async ()=>{
        try{
           const response = await orderService.getCustomerPayments(currentUser.id);
            // console.log(response.data);
            let data = [];
            response.data.map((d)=>{
                let finalDate="N/A"
                if(d.createdAt){
                    const date = new Date(d.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear()
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                  finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
               
                let modifiedData = {
                    id:d._id,
                    date:finalDate,
                    sortDate:d.createdAt,
                    customer:d.fName ? d.fName : d.user[0] ? d.user[0].username : 'N/A' ,
                    userId:d.user[0] && d.user[0]._id,
                    order:d.order.name,
                    orderId:d.order._id,
                    paymentMethod:d.paymentMethod ? d.paymentMethod.toUpperCase():d.paymentIntent ? d.paymentIntent.payment_method_types[0] ? d.paymentIntent.payment_method_types[0].toUpperCase() : "Not Available" : "Not available",
                    status:d.status ? d.status  : d.paymentIntent ? d.paymentIntent.status ? d.paymentIntent.status : 'N/A' : 'N/A',
                    amount:d.paymentIntent ? d.paymentIntent.amount/100:d.amount,
                    remaining:d.remainingAmount ? d.remainingAmount : 0,
                    partial:d.isPartial ? d.isPartial : false,
                    currency:d.currency ? d.currency : d.paymentIntent.currency,
                    paymentType:d.paymentType ? d.paymentType : 'Not Speicified',
                    totalAmount:d.order.totalAmount ? d.order.totalAmount: 'N/A',
                    paidAmount:d.order.paidAmount ? d.order.paidAmount: 'N/A',
                    transactionId:d.paymentIntent ? d.paymentIntent.id : "N/A"
                }

                data.push(modifiedData);
            })
            
            setAllPayments(data);
        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(()=>{
        getPayments();
    },[]);


    useEffect(()=>{
        let methodFilterArr = [];
         allPayments.map((p)=>{
           if(p.paymentMethod){
            methodFilterArr.push({text:p.paymentMethod ,value:p.paymentMethod })
           }
        })
        // console.log("NAME FILTER : ",assignToArr);
        methodFilterArr = methodFilterArr.filter((item,index,self)=>{
           return index === self.findIndex((t)=>(
               t.text === item.text && t.value === item.value
           ))
       });

        setFilteredMethodArr(methodFilterArr);
    },[allPayments])




    const columns = [
        {
            title: 'Created At',
            dataIndex: 'date',
            key: 'date',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            defaultSortOrder: 'descend',
        },
        {
            title: 'Order',
            dataIndex: 'order',
            key: 'order',
        },
        {
            title: 'Transaction Id',
            dataIndex: 'transactionId',
            key: 'transactionId',
        },
        {
            title: 'Paid Amount',
            key: 'paidAmount',
            render:(record)=>{
                return(<b>{record.amount} {record.currency}</b>)
            }
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            filters:filteredMethodArr,
            onFilter:(value, record) => record.paymentMethod.indexOf(value) === 0,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex:'status',
            render:(text)=>{
                return(<Tag color={'cyan'}>{text}</Tag>)
            }
        },
        
        {
            title: 'Action',
            key: 'action',
            render:(record) => (
                <Space size="middle">
                <Dropdown overlay={
                  <Menu style={{marginLeft:'-25%'}}>
                   <Menu.Item key="1">
                    <EyeOutlined /> <Link to={`/profile/single-payment-details/${record.id}`} target="_blank" /> View Details
                   </Menu.Item>
                  <Menu.Item key="2">
                  <Link to={{pathname:`/profile/view-order-details/${record.orderId}`}}  target="_blank"  id="action" ><ShoppingCartOutlined /> View Order </Link>
                  </Menu.Item>
                </Menu>
                }>
                <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
                </Dropdown>

                
              </Space>
              )
           
        },
    ]
   


    const handleSearchChange = (item)=>{
        let filteredData = []
        if(item.length > 0){
            if(allPayments.length >0){
                filteredData= allPayments.filter((payment)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return payment.order && payment.order.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }
        }

        setFilteredArr(filteredData);
    }






    return(
        <div>
            <div style={{display:'flex',justifyContent:'space-between'}}>
                <div><h3>All Payments</h3></div>
                <div>
                    <input type="text" className="form-control"
                        placeholder="Search By Name" 
                        style={{width:'30vw',marginBottom:'3%',marginRight:'3%'}}
                        onChange={(e)=>{handleSearchChange(e.target.value)}} /> 
                </div>
            </div>

            <Table dataSource={filteredArr.length > 0 ?  filteredArr : allPayments} columns={columns} />
        </div>
    )
}


export default PurchasedHistory;