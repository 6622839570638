import React from 'react';
import {
  Document,
  Page,
  Text,
    Font,
  View,
  StyleSheet,
  Image,
  Link
} from '@react-pdf/renderer';


import mailIcon from '../Icons/mail.png';
import contactIcon from '../Icons/phone.png';
import locationIcon from '../Icons/location.png';
import userIcon from '../Icons/user.png';
import skillIcon from '../Icons/skill.png';
import bagIcon from '../Icons/bag.png';
import capIcon from '../Icons/cap.png';
import certificateIcon from '../Icons/certificate.png';
import projectIcon from '../Icons/project.png';

function Resume1({data}) {
    Font.register ({
      family: 'Open Sans',
      src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
    });

    Font.register ({
      family: 'Lato',
      src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
    });

    Font.register ({
      family: 'Lato Italic',
      src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
    });

    Font.register ({
      family: 'Lato Bold',
      src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
    });
  const {personalDetails, description,education,otherExperiences, employmentHistory,workHistory} = data;
  const styles = StyleSheet.create ({
    page: {
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    resumeHeader: {
      marginTop: 5,
      marginBottom: 2,
      paddingTop: 5,
      paddingBottom: 2,
      flexDirection: 'row',
      justifyContent: 'center',
    },
    resumeHeaderText: {
      color: '#4f4e4e',
      textAlign: 'center',
      fontSize: 24,
      fontFamily:'Lato Bold'
    },
    blueText: {
      color: '#1da1f2',
      textAlign: 'center',
      fontSize: 24,
      fontFamily:'Lato Bold'
    },
    textMd: {
      color: '#888888',
      textAlign: 'center',
      fontSize: 14,
      fontFamily:'Open Sans'
    },
    basicInfoBanner: {
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: '#4f4e4e',
      padding: 8,
      marginTop: 10,
    },
    basicInfoText: {
      color: 'white',
      fontSize: 8,
      fontFamily:'Open Sans'
    },
    basicInfoItem: {
      marginHorizontal: 5,
      flexDirection: 'row',
    },
    basicInfoIcon: {
      width: 9,
      marginRight: 3,
    },
    resumeInfoWrapper: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      marginTop: 10,
      padding: 5,
    },
    resumeInfo1: {
      margin: 10,
      width: 300,
    },
    resumeInfoHeader: {
      backgroundColor: '#E6E6E6',
      borderRadius: 100,
      flexDirection: 'row',
      alignItems: 'center',
    },
    resumeInfoIcon: {
      width: 15,
    },
    resumeInfoIconWrapper: {
      backgroundColor: '#44AFD5',
      borderRadius: 100,
      width: 25,
      height: 25,
      marginRight: 10,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    resumeInfoHeading: {
      fontSize: 15,
      color: 'black',
      margin: 5,
      marginLeft: 0,
      fontFamily:'Lato Bold'
    },
    resumeInfoDetailsWrapper: {
      marginLeft: 20,
      marginVertical: 5,
    },
    resumeInfoDetails: {
      fontSize: 9,
      color: 'gray',
    },
    resumeInfo2: {
      margin: 10,
      width: 230,
    },
    resumeInfoTag: {
      fontSize: 7,
      fontFamily:'Open Sans',
      backgroundColor: '#44afd5',
      color: 'white',
      paddingHorizontal: 7,
      paddingVertical: 5,
      margin: 2,
      borderRadius: 100,
    },
    resumeInfoTagWrapper: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },

    resumeInfoItem: {
      marginBottom: 7,
    },
    resumeJobInfoWrapper: {
      marginVertical: 7,
    },
    resumeJobInfoHeader: {
      fontSize: 13,
      fontFamily:'Lato Bold',
      color: 'black',
      marginBottom: 5,
    },
    resumeJobInfo: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    resumeJobCompany: {
      fontSize: 10,
      color: '#44afd5',
    },
    resumeJobYears: {
      fontSize: 8,
      color: 'gray',
    },
    resumeJobInfoDetails: {
      fontSize: 9,
      color: 'gray',
      marginTop: 3,
      fontFamily:'Open Sans'
    },
    listHeader:{
        marginTop:3,
        fontSize:9,
        fontFamily:'Lato Bold'
    },
    techWrapper:{
        flexDirection:'row',
        flexWrap:'wrap',
        marginTop:3
    },
    techItem:{
        fontSize: 7,
        fontFamily:'Open Sans',
        backgroundColor: '#44afd5',
        color: 'white',
        paddingHorizontal: 4,
        paddingVertical: 3,
        margin: 2,
        borderRadius: 100,
    }
    
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.resumeHeader}>
          {personalDetails.name.split (' ').map ((text, i) => {
            return i % 2 === 0
              ? <Text style={styles.blueText}>
                  {text}{' '}
                </Text>
              : <Text style={styles.resumeHeaderText}>
                  {text}{' '}
                </Text>;
          })}
        </View>
        <View>
          <Text style={styles.textMd}>{personalDetails.jobRole}</Text>
        </View>
        <View style={styles.basicInfoBanner}>
          <View style={styles.basicInfoItem}>
            <Image src={mailIcon} style={styles.basicInfoIcon} />
            <Text style={styles.basicInfoText}>
              {personalDetails.email}
            </Text>
          </View>

          <View style={styles.basicInfoItem}>
            <Image src={contactIcon} style={styles.basicInfoIcon} />
            <Text style={styles.basicInfoText}>
              {personalDetails.contact}
            </Text>
          </View>

          <View style={styles.basicInfoItem}>
            <Image src={locationIcon} style={styles.basicInfoIcon} />
            <Text style={styles.basicInfoText}>
              {personalDetails.address}
            </Text>
          </View>

        </View>

        <View style={styles.resumeInfoWrapper}>

          <View style={styles.resumeInfo1}>
            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={userIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Summary
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                <Text style={styles.resumeInfoDetails}>{description}</Text>
              </View>
            </View>
            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={bagIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Employment History
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                {employmentHistory.map (item => {
                  return (
                    <View style={styles.resumeJobInfoWrapper} key={item.id}>
                      <Text style={styles.resumeJobInfoHeader}>
                        {item.position}
                      </Text>
                      <View style={styles.resumeJobInfo}>
                        <Text style={styles.resumeJobCompany}>
                          {item.company}
                        </Text>
                        <Text style={styles.resumeJobYears}>
                          {item.totalExperience}
                        </Text>
                      </View>
                      <Text style={styles.resumeJobInfoDetails}>
                        {item.employmentDetails}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={projectIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Projects
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                {workHistory.map (item => {
                  return (
                    <View style={styles.resumeJobInfoWrapper} key={item.id}>
                      <Text style={styles.resumeJobInfoHeader}>
                        {item?.projectName}
                      </Text>
                      <View style={styles.resumeJobInfo}>
                        <Link style={styles.resumeJobCompany} href={item.projectLink}>
                          {item.projectLink && 'See Project'}
                        </Link>
                        <Text style={styles.resumeJobYears}>
                          {item?.projectDuration}
                        </Text>
                      </View>
                      <Text style={styles.resumeJobInfoDetails}>
                        {item?.projectDetails}
                      </Text>
                      {item?.projectTech.length > 0 && 
                        <Text style={styles.listHeader}>
                            Technologies Used: 
                        </Text>
                      }

                     <View style={styles.techWrapper}>
                     {item.projectTech.map((tech, i) => (
                            <Text style={styles.techItem} key={i}>{tech}</Text>
                        ))}
                     </View>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
          <View style={styles.resumeInfo2}>
            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={skillIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Skills
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                <View style={styles.resumeInfoTagWrapper}>
                  {personalDetails.skills.map ((skill, i) => {
                    return (
                      <Text style={styles.resumeInfoTag} key={i}>{skill}</Text>
                    );
                  })}
                </View>

              </View>
            </View>

            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={capIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Education
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                {education.map (item => {
                  return (
                    <View style={styles.resumeJobInfoWrapper} key={item.id}>
                      <Text style={styles.resumeJobInfoHeader}>
                        {item?.education}
                      </Text>
                      <View style={styles.resumeJobInfo}>
                        <Text style={styles.resumeJobCompany}>
                          {item?.institution}
                        </Text>
                        <Text style={styles.resumeJobYears}>
                          {item?.educationYears[0]}-{item?.educationYears[1]}
                        </Text>
                      </View>
                      <Text style={styles.resumeJobInfoDetails}>
                        {item?.educationDetails}
                      </Text>
                     
                    </View>
                  );
                })}
              </View>
            </View>
            {otherExperiences && otherExperiences.length > 0 &&
            
            <View style={styles.resumeInfoItem}>
              <View style={styles.resumeInfoHeader}>
                <View style={styles.resumeInfoIconWrapper}>
                  <Image src={certificateIcon} style={styles.resumeInfoIcon} />
                </View>
                <Text style={styles.resumeInfoHeading}>
                  Other Experiences
                </Text>
              </View>
              <View style={styles.resumeInfoDetailsWrapper}>
                {otherExperiences.map (item => {
                  return (
                    <View style={styles.resumeJobInfoWrapper} key={item.id}>
                      <Text style={styles.resumeJobInfoHeader}>
                        {item?.title}
                      </Text>
                     
                      <Text style={styles.resumeJobInfoDetails}>
                        {item?.details}
                      </Text>
                     
                    </View>
                  );
                })}
              </View>
            </View>

            }

          </View>

        </View>

      </Page>
    </Document>
  );
}

export default Resume1;
