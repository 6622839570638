import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import avatar from '../../assets/user.png';
import {Tooltip} from 'antd';

function SingleChatConversation({username,active,handleSingleChatDelete, email,notificationMsg,notification, conversationId,handleCurrentChat,profilePicture}) {
    return (
        <div className={`singleChatList ${active && 'activeChat'}`} onClick={()=>{handleCurrentChat(conversationId,email)}}>
            <div>
                <img src={profilePicture ? profilePicture : avatar} alt="dp" />
                
            </div>
            {active ? null:
                notification &&  
                    <div className="badge chatNotificationBadge">
                        1
                    </div>}
            <div className='singleChatAllInfo'>
            <div className='convInfo'>
                <h5>{username}</h5>
                {active ? null:
                    notification && 
                    <div className="notification-msg">
                    <div>{notificationMsg.substr(0,15)}..</div>
                    </div>
                    
                }
            </div>
{/*             
            <Tooltip color={'red'} title="Delete Chat?">
            <DeleteOutlined className="deleteChatIcon" onClick={()=>{handleSingleChatDelete(conversationId,username)}}/>
            </Tooltip> */}
         
           
            </div>
        </div>
    )
}

export default SingleChatConversation
