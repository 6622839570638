import React, { useEffect,useRef,useState } from 'react';
import userService from '../../../services/user.service';
import './MyNotifications.css';
import {useSelector,useDispatch} from 'react-redux';
import { NotificationOutlined } from '@ant-design/icons';
import {Tooltip,message,Button } from 'antd';
import {getNotifications} from '../../../actions/landingAction';
import emptyBox from '../../../assets/empty.png';
import { io } from "socket.io-client";

let socketConnection = "https://dashboard.codersarts.com";
// let socketConnection =  "http://localhost:3000";

function MyNotifications() {
    const {notifications} = useSelector((state) => state.notificationReducer);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [loading,setLoading] = useState(false);
    const dispatch = useDispatch();
    const socket = useRef();

      const markAsRead = async (id)=>{
        try{
            const response = await userService.markNotificationAsRead(id);
            // console.log(response.data);
            dispatch(getNotifications(currentUser.id))
          }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to mark as read notification!');
          }
    }

    const getRealtimeNotification = (data)=>{
      if(data.user===currentUser.id){
        dispatch(getNotifications(currentUser.id))
        message.success(data.notification);
      }
      
    }

    const clearAllNotification = async ()=>{
      setLoading(true);
      try{
        const response = await userService.deleteAllNotificationForUser(currentUser.id);
        // console.log(response.data);
        
        dispatch(getNotifications(currentUser.id))
        setLoading(false);
        
      }catch(err){
        console.log(err);
        setLoading(false);
        message.error(err.message ? err.message : 'Unable to delete notification!');
      }
    }


    useEffect(()=>{
      socket.current = io(socketConnection);
      socket.current.on("getNotification", (data) => {
        console.log(data);
       getRealtimeNotification(data);
        
      });
    },[])

    useEffect(()=>{
        if(currentUser){
          socket.current.emit("addNotificationUser", currentUser.id);
            dispatch(getNotifications(currentUser.id))
        }
    },[currentUser])

  
  return(
    <div className="mynotifications">
       <div className="notification-top">
       <h1>My Notifications</h1>
       <Button loading={loading} type={'primary'} style={{marginRight:'3%'}}  onClick={clearAllNotification}>Clear All</Button>
       </div>
        
        <div className="allNotifications">
            {notifications.length > 0 ?
            notifications.map((item)=>{
                return(
                    <div className='singleNotification'>
                       <div style={{display:'flex',alignItems:'center'}}>
                       <div>
                        <NotificationOutlined className={`${item.isRead ? 'notifyGray' : 'notify'}`}/>
                        </div>
                        <div className='notificationMsg'>
                            <div className={`${item.isRead ? 'grayText' : 'boldText' }`}>
                            {item.notification}
                            </div>
                            <div className='gray'>
                            {item.date}
                            </div>
                        </div>
                       </div>
                       <div>
                       
                       {item.isRead ? 
                        <div>
                          <i class="fa fa-check blueText" aria-hidden="true"></i>
                        </div>
                       :
                       <Tooltip title="mark as Read?" color={'teal'}>
                       <div onClick={()=>{markAsRead(item._id)}}>
                       <i class="fa fa-envelope-open-o deleteNotification" aria-hidden="true"></i>
                       </div>
                        </Tooltip>
                       }
                      
                       </div>
                </div>)
            })
            :
            <div className="no-notification-wrapper">
                <img src={emptyBox} className="no-notification"/>
                <h5 className="no-notification-text">There are no Notifications!</h5>
            </div>
            }
        </div>
    </div>
  );
}

export default MyNotifications;
