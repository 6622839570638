import { SETHOMEDETAILS,HOMEPAGEERROR }  from "../actions/types";

const initialState = {
    homeDetails:{
      id:"61474b09478f4338aca92481",
      heading:"Join Coders Arts Projects",
      details:"Get help from tech experts/developers to learn programming, project completion, code mentorship and software development work.",
      logoText:"Codersarts"
    },
    error:undefined
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SETHOMEDETAILS:
      return { ...state,homeDetails: payload };

    case HOMEPAGEERROR:
        return{...state,error:payload}

    default:
      return state;
  }
}
