import { Table, Space,Tag,Modal,Menu,Dropdown,Button } from 'antd';
import { EditOutlined,StopOutlined,CheckCircleOutlined,EyeOutlined,DeleteOutlined,UndoOutlined, MoreOutlined } from '@ant-design/icons';
import './UsersTable.css';
import { useEffect, useState } from 'react';
import moment from  'moment';
import {Link} from 'react-router-dom';



// let data = []
const UsersTable = ({users,confirmRecycleUser,editData,disableUser,openPasswordResetModal,viewDetails,allRoles,roleChange})=>{
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUserDisabled, setIsUserDisabled] = useState("");
  const [username,setUsername] = useState("");
  const [nameFilterValues,setNameFilterValues] = useState([]);
  const [emailFilterValues,setEmailFilterValues] = useState([]);
  const [roleFilterValues,setRoleFilterValues] = useState([]);
  
//  useEffect(()=>{
//    let nameFilterArr = [];
//    let emailFilterArr = [];
//    let roleFilterArr = [];
//    console.log("Users : ",users)

//    users.map((u)=>{
     
//       if(u.name){
//         nameFilterArr.push({text:u.name ,value:u.name });
//       }
//       if(u.email){
//         emailFilterArr.push({text:u.email ,value:u.email })
//       }

//       if(u.roles){
//         roleFilterArr.push({text:u.roles[0].name,value:u.roles[0].name});
//       }
//    });

//    roleFilterArr = roleFilterArr.filter((item,index,self)=>{
//     return index === self.findIndex((t)=>(
//         t.text === item.text && t.value === item.value
//     ))
//     });
//   //  console.log("NAME FILTER : ",nameFilterArr);
//    setNameFilterValues(nameFilterArr);
//    setEmailFilterValues(emailFilterArr);
//    setRoleFilterValues(roleFilterArr);
//   }
 
//  );



useEffect(()=>{
  let nameFilterArr = [];
  let emailFilterArr = [];
  let roleFilterArr = [];
  users.map((u)=>{
    
     if(u.name){
       nameFilterArr.push({text:u.name ,value:u.name });
     }
     if(u.email){
       emailFilterArr.push({text:u.email ,value:u.email })
     }

     if(u.roles){
       roleFilterArr.push({text:u.roles[0].name,value:u.roles[0].name});
     }
  });

  roleFilterArr = roleFilterArr.filter((item,index,self)=>{
   return index === self.findIndex((t)=>(
       t.text === item.text && t.value === item.value
   ))
   });
 //  console.log("NAME FILTER : ",nameFilterArr);
  setNameFilterValues(nameFilterArr);
  setEmailFilterValues(emailFilterArr);
  setRoleFilterValues(roleFilterArr);
},[users])


  const showModal = (name,disabled) => {
    setUsername(name);
    setIsUserDisabled(disabled);
    setIsModalVisible(true);
  };
  
  const handleOk = () => {
    // console.log("OK");
    setIsModalVisible(false);
    // console.log("USERNAME : ",username);
    // console.log("DISABLED : ",isUserDisabled)
    disableUser(username,isUserDisabled)
    
    
  };
  
  const handleCancel = () => {
    // console.log("CANCEL");
    setIsModalVisible(false);
    
  };

    
    const columns = [
      {
        title: 'CreatedAt',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
        
        
      },
        {
          title: 'Name',
          filters:nameFilterValues,
          render:(record)=>{return <Link to={`/profile/manage-users/view/${record.id}`}><b>{record.name}</b></Link>},
          onFilter:(value, record) => record.name.indexOf(value) === 0,
          sorter: (a, b) => a.name.length - b.name.length,
          
        },
        {
          title: 'Address',
          dataIndex: 'address',
          key: 'address',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            filters:emailFilterValues,
            onFilter:(value, record) => record.email.indexOf(value) === 0,
            sortDirections: ['ascend'],
        },
        {
          title: 'Roles',
          dataIndex: 'roles',
          filters:roleFilterValues,
          width:'20%',
          onFilter:(value, record) => record.roles[0].name.indexOf(value) === 0,
          render: (roles,record) => (
            <>
              {/* {console.log(roles)}
              {console.log(record.username)} */}
              <select disabled={record.isSuperAdmin} className="form-control" onChange={(e)=>{roleChange(e,record.username)}}>
              {(allRoles && allRoles.length > 0) && allRoles.map(role => {
                return (
                    <option key={role._id} selected={role.name === roles[0].name ? true:false }>{role.name.toUpperCase()}</option> 
                );
              })}
              </select>

            </>
          ),
        },
        {
          title: 'Manager',
          dataIndex: 'manager',
          key: 'manager',
          render:(text)=>{
              return(
              <Tag color={'cyan'}>{text}</Tag>);
          }
      },

        {
          title: 'Action',
          key: 'action',
          render: (record) => (
              
            <Space size="middle">
              <Button type={'primary'}  danger={record.isDisabled ? true : false}
               onClick={()=>{viewDetails(record.id)}}>
                   <EyeOutlined /> View Details
              </Button>
              {!record.isSuperAdmin ? 
              <Dropdown overlay={
                <Menu>
                <Menu.Item key="1" onClick={()=>{editData(record.username)}}>
                   <EditOutlined /> Edit Data
                </Menu.Item>
                <Menu.Item key="2" onClick={()=>{openPasswordResetModal(record)}}>
                <UndoOutlined /> Reset Password
                </Menu.Item>
                <Menu.Item key="3" danger onClick={()=>{confirmRecycleUser(record.id)}}>
                <DeleteOutlined /> Recycle
                </Menu.Item>
                <Menu.Item key="4" danger onClick={()=>{showModal(record.username,record.isDisabled)}}
                 >{record.isDisabled ?<CheckCircleOutlined /> : <StopOutlined /> } {record.isDisabled ? 'Make Active' : 'Disable User'}</Menu.Item>
              </Menu>
              }>
              <p id="action" ><MoreOutlined style={{color:'black',transform:'90deg',fontSize:'1.5rem'}}/></p>
              </Dropdown>
              :
              null
              }
            </Space>
          ),
        },
      ];



      const data = []
      if(users.length > 0 && users){
        // console.log(users);
        users.map((item,index)=>{
          let finalDate="N/A"
          if(item.createdAt){
              const date = new Date(item.createdAt);
              let dd = date.getDate();
              let mm = date.getMonth() + 1;
              let yyyy = date.getFullYear();
              let hh = date.getHours();
              let minutes = date.getMinutes();
              let ss = date.getSeconds();
             finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
          }
          data.push({
              key:index+1,
              createdAt:finalDate,
              sortDate:item.createdAt,
              id:item._id,
              name:item.name ? item.name : "Guest",
              username:item.username,
              dob:item.dob ? item.dob : "N/A",
              address:item.address ? item.address : "N/A",
              email:item.email,
              isDisabled:item.isDisabled,
              manager:item.manager && item.manager[0] && item.manager[0].username,
              isSuperAdmin:item.isSuperAdmin ? item.isSuperAdmin:false,
              roles:item.roles
          })
          return;
        })
      }
      
      return(
          <div>
              <Table columns={columns} dataSource={data}  />
              <Modal title={isUserDisabled ? "Activate User":"Disable User"} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              {isUserDisabled ? (<p style={{color:'red'}}>Are You sure to Activate This User ? </p>):(<p style={{color:'red'}}>Are You sure to Disable This User ? </p>)}
              </Modal>
          </div>
      )
}


export default UsersTable;
