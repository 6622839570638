import React,{useState,useEffect} from 'react'
import {useSelector} from 'react-redux';
import { history } from '../../../helpers/history';
import userService from '../../../services/user.service';
import {message,Table,Button} from 'antd';

function ExportedDataList() {
    const [allExportedData,setAllExportedData] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser]);


    const getAllList = async ()=>{
        try{
            let data = []
            const response = await userService.listAllExportedData();
            // console.log("found Lists : ",response.data);
           response.data.map((item)=>{
            let finalDate="N/A"
            if(item.createdAt){
                const date = new Date(item.createdAt);
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear()
                let hh = date.getHours();
                let minutes = date.getMinutes();
                let ss = date.getSeconds();
              finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
            }
            let modifiedData = {
                id:item._id,
                name:item.name,
                fileUrl:item.fileUrl,
                createdAt:finalDate
            }
            data.push(modifiedData);
           })
            setAllExportedData(data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : "Couldn't Load the Exported List!");
        }
    }
    useEffect(()=>{
        getAllList();
    },[])

    const columns = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
           
        },{
            title:'Action',
            render:(record)=>{
               
                return(<Button  size="middle" type={"primary"}><a href={record.fileUrl} target="_blank">Download</a></Button>)
            }
        }
       
    ]  
    return (
        <div>
            <h3>All Exported Files</h3>
            <hr />
            <Table columns={columns} dataSource={allExportedData} />
        </div>
    )
}

export default ExportedDataList
