import React,{useState,useEffect,useRef} from 'react';
import { Button, Modal,Spin,Tooltip,message,Select,Input } from 'antd';
import './Groups.css';
import group from '../../assets/group.png';
import { PlusOutlined,LoadingOutlined,PaperClipOutlined,DeleteTwoTone,ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import chatService from '../../services/chat.service';
import userService from '../../services/user.service';
import GroupMessage from './GroupMessage/GroupMessage';
import chat from '../../assets/chat.png';
import GroupMembers from './GroupMembers/GroupMembers';
import { io } from "socket.io-client";
import S3 from 'react-aws-s3';
import orderService from '../../services/order.service';

const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 
const {Search} = Input;

const {Option} = Select;

// let socketConnection = "http://localhost:3000";

let socketConnection = "https://dashboard.codersarts.com"



function Groups() {
    const [isAddGroupModal,setIsAddGroupModal] = useState(false);
    const [confirmAddGroupLoading,setConfirmAddGroupLoading] = useState(false);
    const [newGroupName,setNewGroupName] = useState("");
    const [allUsers,setAllUsers] = useState([]);
    const [newGroupMembers,setNewMembers] = useState([]);
    const [allGroups,setAllGroups] = useState([]);
    const [currentGroupChat,setCurrentGroupChat] = useState(null);
    const [newMessage,setNewMessage] = useState("");
    const [groupMessages,setGroupMessages] = useState([]);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [arrivalMessage,setArrivalMessage] = useState("");
    const [newSearchArr,setNewSearchArr] = useState([]);
    const inputFileRef = useRef(null);
    const [file,setFile] = useState(null);
    const [loading,setLoading] = useState(false);
    const [awsData,setAwsData] = useState(null);
    const scrollRef = useRef();
    const socket = useRef();
    // const children = [];
    // for (let i = 10; i < 36; i++) {
    // children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    // }

    const getAllSpecificGroups = async ()=>{
        try{
            const response = await chatService.getGroupConversations(currentUser.id);
            // console.log("Groups : ",response.data);
            setAllGroups(response.data);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to fetch groups!')
        }
    }

    const getAllUsers = async ()=>{
        try{
            const response = await userService.getAllUsers();
            console.log(response.data);
            setAllUsers(response.data);

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to load users!');
        }
    }

    const getAwsInfo = async ()=>{
        try{
         const res = await orderService.getAwsInfo();
         
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
    
     }

     useEffect(()=>{
        getAwsInfo();
      },[]);


    useEffect(()=>{
        if(currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')){
            getAllUsers();
        }
        getAllSpecificGroups();
    },[]);


    useEffect(() => {
        socket.current = io(socketConnection);
        socket.current.on("getGroupMessages", (data) => {
          console.log("GOT MESSAGE HERE : ",data);
          setArrivalMessage({
            sender: data.sender,
            message: data.message,
            msgType:data.msgType,
            fileUrl:data.fileUrl,
            createdAt: Date.now(),
          });
        });
      }, []);

    //  const getMessagesFromSocket = ()=>{
    //     socket.current.on("getGroupMessages", (data) => {
    //         console.log("GOT MESSAGE HERE : ",data);
    //         setArrivalMessage({
    //           sender: data.sender,
    //           message: data.message,
    //           msgType:data.msgType,
    //           createdAt: Date.now(),
    //         });
    //       });
    //  }


      useEffect(() => {
        socket.current.emit("addGroupUser", currentUser.id);
        console.log(currentUser.id);
        
      }, [currentUser]);


      useEffect(() => {
          console.log("Arrival Message : ",arrivalMessage);
          console.log("Current Group Chat : ",currentGroupChat)
        arrivalMessage &&
          currentGroupChat?.members.includes(arrivalMessage.sender.id) &&
          setGroupMessages((prev) => [...prev, arrivalMessage]);
      }, [arrivalMessage, currentGroupChat]);

    const getAllGroupMessages = async ()=>{
        if(currentGroupChat){
            try{
                const response = await chatService.getGroupMessages(currentGroupChat._id);
                console.log("messages : ",response.data);
                setGroupMessages(response.data);
            }catch(err){
                console.log(err);
                message.error(err.message ? err.message : ' Unable to fetch all the messages!');
            }
        }
    }
    useEffect(()=>{
        getAllGroupMessages();
    },[currentGroupChat])
  

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [groupMessages]);


    // const onSearch = (value)=>{
    //     const newGroups = allGroups.filter((item)=>{
    //         return item.groupName.toLowerCase()===value.toLowerCase();
    //     });
    //     if(newGroups.length > 0){
    //         setNewSearchArr(newGroups)
    //     }
       
    // }

    const addGroupModal = ()=>{
        setIsAddGroupModal(true);
    }

    

    function handleChangeMembers(value) {
   
    setNewMembers(value)
    }

    const handleMessageSubmit = async ()=>{
        let msg = {
            sender: currentUser,
            message: newMessage,
            msgType:"text"
        }
        if(file && awsData){
            msg = {
                sender: currentUser,
                message: newMessage,
                msgType:file && file.type,
                fileUrl:null
              };

              setLoading(true);
            const config = {
                bucketName:awsData.bucketName,
                region:'ap-south-1',
                accessKeyId: awsData.accessKeyId,
                secretAccessKey: awsData.secretKey,
                s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            }
            const ReactS3Client = new S3(config);
            try{
              const data = await ReactS3Client.uploadFile(file,file.name);
              msg.fileUrl = data.location
              // console.log("UPLOADED  : ",message);
              setFile(null);
              setLoading(false);
             }catch(err){
                 console.log(err);
                 setLoading(false);
             }
        }
        socket.current.emit("sendGroupMessage", {
            sender: currentUser,
            message: newMessage,
            msgType:msg.msgType,
            fileUrl:msg.fileUrl
          });
        try{
            const response = await chatService.createGroupMessage({
                groupId:currentGroupChat._id,
                sender:currentUser.id,
                message:newMessage,
                type:msg.msgType,
                fileUrl:msg.fileUrl
            });
            console.log(response.data);
            setNewMessage("");
            getAllGroupMessages();

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Something Went wrong in sending message!')
        }


    }

    const handleAddGroupModal = async ()=>{
        setConfirmAddGroupLoading(true);
        try{
            const response = await chatService.createGroupConversations(newGroupName,newGroupMembers);
            console.log(response.data);
            message.success(`Created a new group ${newGroupName}!`);
            setNewGroupName("");
            setNewMembers([]);
            setConfirmAddGroupLoading(false);
            setIsAddGroupModal(false);
            getAllSpecificGroups();
        }catch(err){
            console.log(err);
            setConfirmAddGroupLoading(false);
            setIsAddGroupModal(false);
            message.error(err.message ? err.message : 'Unable to create new group!');
        }
        // console.log("New group Name : ",newGroupName);
        // console.log("Members : ",newGroupMembers);
        // console.log("added!");
    }


    const handleNewGroupCancel = ()=>{
        setIsAddGroupModal(false);
        setNewMembers([]);
        setNewGroupName("");
    }


    const deleteGroup = async (id,groupName)=>{
        try{
            const response = await chatService.deleteGroup(id);
            console.log(response);
            message.success(`Deleted ${groupName} Successfully!`);
            getAllSpecificGroups();
            setCurrentGroupChat(null);
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to Delete Group')
        }
       
    }

    const confirmDelete = (id,groupName)=>{
        Modal.confirm({
          title: 'Confirm Delete',
          icon: <ExclamationCircleOutlined />,
          content: `Are you sure you want to Delete ${groupName} ? `,
          okText: 'Yes',
          cancelText: 'No',
          onOk:()=>{deleteGroup(id,groupName)}
        });
      }

 
      const handleSearchChange = (item)=>{
        //   if(value.length===0){
        //       setNewSearchArr([]);
        //   }

        let filteredData = []
       
        // console.log("SEARCH QUERY : ",item);
        if(item.length > 0){
            if(allGroups.length >0){
                filteredData= allGroups.filter((g)=>{
                   // return payment.customer && payment.customer.toLowerCase().trim() === item.toLowerCase().trim();
                   return g.groupName && g.groupName.toLowerCase().match(item.toLowerCase())
                });
              //   console.log("SEARCH RESULT : ",filteredData);
               
              }
              setNewSearchArr(filteredData)
              
        }else{
            setNewSearchArr([]);
        }
        

      }


      const handleFileChange = (e)=>{
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setNewMessage(e.target.files[0].name)
      }
    
      const handleFileClick = ()=>{
        inputFileRef.current.click();
      }


    return (
        <div className="groupConversation">
            <div className="allGroups">
                <div className="group-header">
                <Search placeholder="Search..." onChange={(e)=>{handleSearchChange(e.target.value)}} enterButton />
                    <div className="groupHeaderControls">
                    <h3>
                        Groups
                    </h3>
                    {currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES')
                     ?
                     <div className="addGroup">
                        <Button type={'primary'} onClick={addGroupModal}> <PlusOutlined /> <b>Add Group</b> </Button>
                     </div>
                    :
                    null
                    
                    }
                    
                    </div>
                </div>
                
                <div className="groupSideBody">
                    {newSearchArr.length > 0 ?
                    newSearchArr.map((g)=>{
                        return(
                            <div className={`singleGroup ${currentGroupChat && currentGroupChat._id===g._id && 'highlight'}`} onClick={()=>{setCurrentGroupChat(g)}}>
                                <img src={group} alt="icon" className="groupIcon" />
                                <span className="groupName">{g.groupName}</span>
                                {currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES') ? 
                                <Tooltip title="Delete Group ?" color={'red'}>
                                <DeleteTwoTone className="deleteGroupIcon" onClick={()=>{confirmDelete(g._id,g.groupName)}} />
                                </Tooltip>
                                
                                :null
                                }
                            </div>
                        )
                    })
                    :
                    allGroups.map((g)=>{
                        return(
                            <div className={`singleGroup ${currentGroupChat && currentGroupChat._id===g._id && 'highlight'}`} onClick={()=>{setCurrentGroupChat(g)}}>
                                <img src={group} alt="icon" className="groupIcon" />
                                <span className="groupName">{g.groupName}</span>
                                {currentUser.roles.includes('ROLE_ADMIN') || currentUser.roles.includes('ROLE_SALES') ? 
                                <Tooltip title="Delete Group ?" color={'red'}>
                                <DeleteTwoTone className="deleteGroupIcon" onClick={()=>{confirmDelete(g._id,g.groupName)}} />
                                </Tooltip>
                                :null
                                }
                            </div>
                        )
                    })
                    
                    }
                    
                </div>
            </div>

                <div className="groupChatBox">
                <div className="groupChatBoxWrapper">
                {currentGroupChat ? 
                    <>
                    <div className="groupChatBoxTop">
                        {/* <GroupMessage own={true} message="this is a test message" />
                        <GroupMessage own={false} message="this is 2nd test message" /> */}
                        {groupMessages.map((msg,key)=>{
                            return(
                                <div ref={scrollRef} className="singleGroupMsg" key={key}>
                                    <GroupMessage 
                                    createdAt={msg.createdAt} 
                                    sender={msg.sender[0] ? msg.sender[0] : msg.sender } 
                                    own={msg.sender && msg.sender[0] ? msg.sender[0]._id===currentUser.id :  msg.sender.id===currentUser.id }
                                     message={msg} />
                                </div>
                            )
                        })}
                    </div>
                    <div className="groupChatBoxBottom">
                        <div>
                            <textarea className="input-box" value={newMessage} onChange={(e)=>{setNewMessage(e.target.value)}}></textarea>
                        </div>
                        <div>
                        <Tooltip title="Share Files ? " color={'#f50'}>
                            <span onClick={handleFileClick}>
                            <PaperClipOutlined className="file-upload-icon" />
                            </span>
                        </Tooltip>
                        <input type="file" 
                        style={{display:'none'}} 
                        onChange={handleFileChange}
                        ref={inputFileRef} />
                        </div>
                        <div>
                            <Button type={'primary'} onClick={handleMessageSubmit}>
                            {loading ? <Spin indicator={spinIcon} />:'Send'}
                            </Button>
                        </div>
                    </div>
                    </>
                :
                <div className="noConversationText">
                    <img src={chat} />
                    <div>
                        <h3>Select a Group</h3>
                        <div id="noText">Try Selecting a Group </div>
                    </div>  
                 </div>
                }
                    
                    

                </div>
                </div>
           


            {currentGroupChat ? 
            <div className="groupMembers">
                <GroupMembers name={currentGroupChat.groupName} members={currentGroupChat.members} />
            </div>
            : null
            }

           
            

            <Modal
                title="Create a New Group"
                visible={isAddGroupModal}
                onOk={handleAddGroupModal}
                confirmLoading={confirmAddGroupLoading}
                onCancel={handleNewGroupCancel}
                closable={true}
                okText="Add Group"
                        >
                        <div style={{fontStyle:'normal'}}>
                            <form>
                                <div className="mt-2">
                                <h6><b><label>Enter Group Name</label></b></h6>
                                <Input value={newGroupName} onChange={(e)=>{setNewGroupName(e.target.value)}} className="mt-2" />
                                </div>

                                <div className="mt-1">


                                <Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    value={newGroupMembers}
                                    onChange={handleChangeMembers}
                                    >
                                    {allUsers.length >0 && allUsers.map((u,key)=>{
                                        return <Option key={key} value={u._id}>{u.username}</Option>
                                    })}
                                </Select>

                                </div>



                                
                            </form>
                        </div>
         </Modal>
        </div>
    )
}

export default Groups
