import React,{useState,useEffect} from 'react';
import './Card.styles.css';
import {message,Spin} from 'antd';
import Authentication from '../../../services/authentication.service';
import { LoadingOutlined } from '@ant-design/icons';


const spinIcon = <LoadingOutlined style={{ fontSize: 24,color:'white' }} spin />; 
const Card = () => {

    
    const [gmaillink,setgmaillink] = useState(null);
    const [drivelink,setdrivelink] = useState(null);
    const [gmailcode,setgmailcode] = useState(null);
    const [drivecode,setdrivecode] = useState(null);
    const [result1,setresult1] = useState(true);
    const [result2,setresult2] = useState(true);
    const [driveLoading,setDriveLoading] = useState(false);
    const [emailLoading,setEmailLoading] = useState(false);
    useEffect(()=>{
        Authentication.gmaillink().then(response => { console.log(response) 
        
            setgmaillink(response.data)
    
    }).catch((err)=>{
        console.log(err);
        message.error(err.message ? err.message : 'Something Went Wrong!');
    });


    Authentication.drivelink().then(response => { console.log(response) 
            setdrivelink(response.data)

    }).catch((err)=>{
        console.log(err);
        message.error(err.message ? err.message : 'Something Went Wrong!');
    });
    } ,[])

    const SendGmailCode = async (e) => {
        setEmailLoading(true);

        e.preventDefault();
        let data = {
            code:gmailcode
        }

        // Authentication.gmailcode(data).then(response => { if(response.data === "success"){
        //     setresult1(false);
        // }});
        try{
            const response = await Authentication.gmailcode(data);
            if(response){
                setEmailLoading(false);
            }
            if(response && response.data==="success"){
                setresult1(false);
                setEmailLoading(false);
            }
        }catch(err){
            setEmailLoading(false);
            message.error(err.message ? err.message : "Something went wrong !");
        }

    }

    const SendDriveCode = async (e) => {
        setDriveLoading(true);

        e.preventDefault();
        let data = {
            code:drivecode
        }
        try{
            const response = await Authentication.drivecode(data);
            if(response){
                setDriveLoading(false);
            }
            if(response && response.data==="success"){
                setresult2(false);
                setDriveLoading(false);
            }
        }catch(err){
            setDriveLoading(false);
            message.error(err.message ? err.message : "Something went wrong !");
        }
        

    }




    return(


<div className="GoogleAdminApi">
<link href="https://fonts.googleapis.com/css2?family=Roboto&display=swap" rel="stylesheet" />
<h1>API's Authentication </h1>
<div className="cards">
  <div className="card">
    <h2 className="card-title">Google Gmail</h2>
    <img src="https://images.indianexpress.com/2020/10/Gmail-new-logo.jpg" alt="" />
    <div className="card-desc"> 
    
   { result1 ?
    <div  style={{display:'flex',flexDirection:'column',alignItems:'center',height:'100%',justifyContent:'space-around'}}>
           
           
                <div style={{width:'60%'}}>
                
                <h4>
                <a  href={gmaillink} target='_blank' >Open the link </a>    
                to get Authoriszation Code for Gmail API
               </h4>
                </div>

        

           <div style={{display:'flex',flexDirection:'column' ,justifyContent:'space-between',height:'30%'}}>

          <h5> Enter the Authorization Code Here : </h5>
           <input  className="form-control" type="text" onChange={e => { setgmailcode(e.target.value)}}/>
           <button  className="bn30" onClick={e=> {SendGmailCode(e)}} style={{margin:'auto'}} > {emailLoading  ? <Spin indicator={spinIcon} />: "Submit"}</button>
           
           </div>


           
        
    </div>: <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column',alignItems:'center',height:'100%'}}>
    
    
    
    
    <div style={{display:'flex',margin:'auto',height:'70%'}}> 
        <img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjPNbBpZeXnXfTuA6AWek-Kj8NYEVbYdG6ayi5bIWarDuryXDrILdKMTd597quLD0PBKM&usqp=CAU" alt=""/>
   </div>
   <div>
   <h4>Successfull</h4>
   </div>
    
    
    </div>

     }
     </div> 
      </div>
  <div className="card">
    <h2 className="card-title">Google Drive</h2>
    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXhyhQAr6EBomynl_7w5p4xrpb3Gs_ZyWI3Y0uM6F_ag6lEzb-T3xrjCm67SjLH5FatB4&usqp=CAU" alt="" />


    <div className="card-desc"> 
    
    { result2? 
    
    <div  style={{display:'flex',flexDirection:'column',alignItems:'center',height:'100%',justifyContent:'space-around'}}>
           
           
                <div style={{width:'60%'}}>
                
                <h4>
                <a  href={drivelink} target='_blank' >Open the link </a>    
                to get Authoriszation Code for Drive API
               </h4>
                </div>

        

           <div style={{display:'flex',flexDirection:'column' ,justifyContent:'space-between',height:'30%'}}>

          <h5> Enter the Authorization Code Here : </h5>
           <input className="form-control" type="text" onChange={e => { setdrivecode(e.target.value)}}/>
           <button  className="bn30" onClick={e=> {SendDriveCode(e)}} style={{margin:'auto'}} > {driveLoading  ? <Spin indicator={spinIcon} />: "Submit"} </button>
           
           </div>


           
        
    </div>
    
    
    : <div style={{display:'flex',justifyContent:'space-evenly',flexDirection:'column',alignItems:'center',height:'100%'}}>
    
    
    
    
    <div style={{display:'flex',margin:'auto',height:'70%'}}> 
        <img  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjPNbBpZeXnXfTuA6AWek-Kj8NYEVbYdG6ayi5bIWarDuryXDrILdKMTd597quLD0PBKM&usqp=CAU" alt=""/>
   </div>
   <div>
   <h4>Successfull</h4>
   </div>
    
    
    </div>
    
    }
    
    
    
    
    </div>

  </div>
  
</div>
</div>
















        )
}

export default Card;