import React,{useEffect} from 'react';
import {Table,Tooltip,message,Button} from  'antd';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import {CopyTwoTone } from "@ant-design/icons";



function AdminLinks() {
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser]);


    const handleCopyLink = (link)=>{
        navigator.clipboard.writeText(link);
        message.success("Copied Link to Clipboard!");
    }


    const linksArr = [
    {
        name:'Google Api Authentication',
        link:'https://dashboard.codersarts.com/codersarts-google-api-login'
    },

    {
        name:'Drive Uploader',
        link:'https://dashboard.codersarts.com/drive-file-uploader'
    },
    {
        name:'Public Order Page',
        link:'https://dashboard.codersarts.com/get-help'
    },
    {
        name:'Codersarts Public Payment',
        link:'https://dashboard.codersarts.com/codersarts-payment'
    }
]


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render:(text,record)=>{return(<Tooltip color={'cyan'} title={` URL : ${record.link}`}><b>{text}</b></Tooltip>)},
        },
        {
            title:"Action",
            render: (record)=> {
           return(
            <>
            <Button type="primary">
            <a href={record.link} style={{color:'white'}} target="_blank">
            Go To</a>
            </Button> 

            <Button  size="middle" style={{marginLeft:'5%'}} 
            onClick={()=>handleCopyLink(record.link)}>
            <CopyTwoTone /> Copy Link
            </Button>
            </>
           )
            }   
        
        }
    ]
    return (
        <div>
            <h2>Important Links </h2>
            <hr />
            <Table dataSource={linksArr} columns={columns} />
        </div>
    )
}

export default AdminLinks
