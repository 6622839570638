import React, {useEffect, useState} from 'react';
import Message from '../../Message/Message';
import './CreateOrderForUser.css';
import OrderService from '../../../services/order.service';
import GoogleServices from '../../../services/google.services';
import {
  Breadcrumb,
  Select,
  Progress,
  message,
  Checkbox,
  Spin,
  Modal,
} from 'antd';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import S3 from 'react-aws-s3';
import {history} from '../../../helpers/history';
import {LoadingOutlined} from '@ant-design/icons';
import userService from '../../../services/user.service';

const {Option} = Select;

const spinIcon = (
  <LoadingOutlined style={{fontSize: 24, color: 'white'}} spin />
);

const CreateOrderForUser = () => {
  const [name, setName] = useState ('');
  const [details, setDetails] = useState ('');
  const [deadline, setDeadline] = useState ('');
  const [category, setCategory] = useState ('');
  const [otherCategory, setOtherCategory] = useState ('');
  const [documentation, setDocumentation] = useState ('');
  const [bookingSession, setBookingSession] = useState ('');
  const [fileUrl, setFileUrl] = useState (null);
  const [uploading, setUploading] = useState (false);
  const [successMessage, setSuccessMessage] = useState (false);
  const [awsData, setAwsData] = useState (null);
  const {user: currentUser} = useSelector (state => state.auth);
  const [timezone, setTimezone] = useState (null);
  const [loading, setLoading] = useState (false);
  const [allOrderCategory, setAllOrderCategory] = useState ([]);
  const [uploadPercent, setUploadPercent] = useState (0);
  const [uploadStatus, setUploadStatus] = useState ('');
  const [uploadFile, setUploadFile] = useState (null);
  const [fileKey, setFileKey] = useState ('1');
  const [technologies, setTechnologies] = useState ([]);
  const [skills, setSkills] = useState ([]);
  const [type, setType] = useState ('');
  const [confirmationModal, setConfirmationModal] = useState (false);
  const [newOrderLabel,setNewOrderLabel] = useState("");

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_USER')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllOrderCategoriesFunc = async () => {
    try {
      const response = await OrderService.getAllOrderCategory ();
      // console.log("Categories : ",response.data);
      let categories = [];
      response.data.map (item => {
        categories.push (item.name);
      });
      setAllOrderCategory (categories);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : ' Unable  to Get all Orders Categories!'
      );
    }
  };

  var today = new Date ();
  var dd = String (today.getDate ()).padStart (2, '0');
  var mm = String (today.getMonth () + 1).padStart (2, '0'); //January is 0!
  var yyyy = today.getFullYear ();
  today = yyyy + '-' + mm + '-' + dd;
  // console.log(today);

  const getAwsInfo = async () => {
    try {
      const res = await OrderService.getAwsInfo ();

      setAwsData (res.data);
    } catch (err) {
      console.log (err);
    }
  };

  const getSkillsList = async () => {
    try {
      const response = await userService.getAllSkillsList ();
      if (response) {
        setSkills (response.data);
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  useEffect (() => {
    getAwsInfo ();
    getSkillsList ();
    getAllOrderCategoriesFunc ();
  }, []);

  const handleFileChange = file => {
    setUploadFile (file);
  };

  const handleCategorySelect = value => {
    //    console.log(value);
    setCategory (value);
  };

  const handleFileUpload = async file => {
    setUploadPercent (40);
    if (awsData) {
      setUploading (true);

      const config = {
        bucketName: awsData.bucketName,
        region: 'ap-south-1',
        accessKeyId: awsData.accessKeyId,
        secretAccessKey: awsData.secretKey,
        s3Url: 'https://' + awsData.bucketName + '.s3.amazonaws.com',
      };

      setUploadPercent (80);
      const ReactS3Client = new S3 (config);
      ReactS3Client.uploadFile (file, file.name)
        .then (data => {
          // console.log(data);
          if (data.location) {
            setUploadPercent (100);
            setUploadStatus ('success');
            setUploading (false);
            setFileUrl (data.location);
          }
        })
        .catch (err => {
          console.log (err);
          message.error (err.message ? err.message : 'Unable to Upload file!');
          setUploadStatus ('exception');
          setUploading (false);
        });
    }
  };

  useEffect (
    () => {
      if (fileUrl) {
        setUploading (false);
        // console.log(fileUrl);
      }
    },
    [fileUrl]
  );

  useEffect (() => {
    if (successMessage) {
      setSuccessMessage (false);
    }
  });

      
  useEffect(()=>{
    let isMounted = true;

   const getAllGmailLabels = async ()=>{
    try{
        const response = await OrderService.getAllGmailLabels();
        // console.log("Gmail labels : ",response.data);
        let label = response.data?.labels?.find(item=>{
            return item.name.toLowerCase()==='new order'
        });

        console.log(label);
        setNewOrderLabel(label);
        
    }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
    }
   }
   if(isMounted){
    getAllGmailLabels();
   }

   return ()=>{
       isMounted = false;
   }
   
},[])

  useEffect (
    () => {
      if (fileUrl) {
        setUploading (false);
        // console.log(fileUrl);
      }
    },
    [fileUrl]
  );

  const sendMail = async (email, order) => {
    // console.log("Mail : ",order.userMail);
    if (email) {
      try {
        const data = {
          To: email,
          Subject: `${order.name} #${order._id}: New Order Submission`,
          Write: `
                <h3><b>Order Details</b></h3>
                <hr/>
                <p><b>Order No. : </b>${order._id}</p>
                <p><b>Order Title : </b>${order.name}</p>
                <p><b>Order Deadline : </b>${deadline}</p>
                <p><b>Timezone: : </b>${timezone}</p>
                <p><b>Client Username : </b>${currentUser.username}</p>
                <p><b>Client Email : </b>${currentUser.email}</p>
                <br/>
                <br/>
                <p>Order Created successfullly</p>
                `,
          attachment: false,
          labelId:newOrderLabel.id
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${email} !`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
    }
  };

  const createAutomaticOutsource = async (data)=>{
    let finalData = {
      orderId:data._id,
      allReviews:[],
      technologies:data.technologies
    }
    // console.log(finalData);
    try{
      const response = await OrderService.createOutsourceOrder(finalData);
      console.log(response.data);
      
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : 'something went wrong!');
    }
  }

  const createOrder = async e => {
    e.preventDefault ();
    setLoading (true);

    // console.log(bookingSession);
    // console.log(documentation);
    if (otherCategory) {
      try {
        const result = await OrderService.createOrderCategory (otherCategory);
      } catch (err) {
        console.log (err);
        message.error (
          err.message ? err.message : 'Unable to add new order category!'
        );
      }
    }

    const data = {
      name: name,
      details: details,
      deadline: deadline,
      category: otherCategory !== ''
        ? otherCategory
        : category.toLowerCase () !== 'select category' ? category : null,
      documentation: documentation === '' ? false : documentation,
      bookingSession: bookingSession === '' ? false : bookingSession,
      assignedTo: currentUser.id,
      fileUrl: fileUrl,
      createdBy: currentUser.id,
      customer: currentUser.id,
      type: type,
      technologies: technologies,
      timezone: timezone,
    };

    try {
      const response = await OrderService.createOrder (data);
      console.log ('result : ', response);
      let {order} = response;
      if (order) {
        sendMail (currentUser.email, order);
        //   sendMail ('contact@codersarts.com', order);
        createAutomaticOutsource(order);
        setSuccessMessage (true);
        setLoading (false);
        setTimeout (() => {
          setConfirmationModal (true);
        }, 3000);
      } else {
        let {msg} = response;
        message.info (msg);
        setLoading (false);
      }
    } catch (err) {
      console.log (err);
      setLoading (false);
      message.error (err.message ? err.message : 'Unable to create order!');
    }

    // console.log(data);
  };

  const handleFileRemove = e => {
    e.preventDefault ();
    let randomString = Math.random ().toString (36);
    setFileKey (randomString);
    setUploadFile (null);
  };

  const handleTechnologySelect = values => {
    // console.log(values);
    setTechnologies (values);
  };

  const handleProjectTypeChange = values => {
    setType (values);
  };

  return (
    <div className="createOrderForUser">
      <Spin tip="Uploading..." spinning={uploading}>
        <div>
          <Breadcrumb>
            <Breadcrumb.Item>
              {' '}<Link to="/profile/user-orders">My Orders</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {' '}<Link to="/profile/user/create-order">Create Order</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h3>Create Order</h3>
        <hr />
        <form>
          <div className="form-inline">
            <div className="form-group">
              <label>Project Name : </label>
              <input
                style={{width: '76vw'}}
                type="text"
                required
                className="form-control"
                value={name}
                onChange={e => {
                  setName (e.target.value);
                }}
              />
            </div>

          </div>
          <div className="form-inline">
            <div className="form-group">
              <label>Timezone : </label>
              <input
                type="text"
                placeholder="e.g. 10 PM EST"
                required
                className="form-control"
                value={timezone}
                onChange={e => {
                  setTimezone (e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label>Deadline : </label>
              <input
                type="date"
                required
                min={today}
                className="form-control"
                value={deadline}
                onChange={e => {
                  setDeadline (e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form-inline">

            <div className="form-group">
              <label>Category : </label>
              {/* <select value={category} required className="form-control" onChange={(e)=>{setCategory(e.target.value)}}>
                    <option>Select Category</option>
                       {allOrderCategory && allOrderCategory.map((name,index)=>{
                           return <option key={index}>{name}</option>
                       })}
                       <option>Other</option>
                    </select> */}
              <br />
              <Select
                showSearch
                virtual={true}
                size={'large'}
                style={{width: '36vw', fontWeight: 'bold'}}
                onChange={handleCategorySelect}
                placeholder="Search or Select a category"
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase ().indexOf (input.toLowerCase ()) >=
                  0}
                filterSort={(optionA, optionB) =>
                  optionA.value
                    .toLowerCase ()
                    .localeCompare (optionB.value.toLowerCase ())}
              >
                {allOrderCategory &&
                  allOrderCategory.map ((name, index) => {
                    return (
                      <Option key={index} value={name}>
                        {name}
                      </Option>
                    );
                  })}
                <Option value="other">Other</Option>
              </Select>

            </div>
            {category.toLowerCase () === 'other' &&
              <div className="form-group ml-2">
                <label>Specify Other Category : </label>
                <input
                  type="text"
                  required
                  value={otherCategory}
                  className="form-control"
                  onChange={e => {
                    setOtherCategory (e.target.value);
                  }}
                />
              </div>}
          </div>

          <div className="form-group">
            <div><label>Technologies Used:</label></div>
            <div>
              <Select
                showSearch
                mode={'multiple'}
                virtual={true}
                size={'large'}
                style={{width: '100%', fontWeight: 'bold'}}
                onChange={handleTechnologySelect}
                placeholder="Search or Select a technology"
                optionFilterProp="value"
                filterOption={(input, option) =>
                  option.value.toLowerCase ().indexOf (input.toLowerCase ()) >=
                  0}
                filterSort={(optionA, optionB) =>
                  optionA.value
                    .toLowerCase ()
                    .localeCompare (optionB.value.toLowerCase ())}
              >
                {skills &&
                  skills.map ((item, index) => {
                    return (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>

          <div className="form-group">
            <div><label>Type of Order:</label></div>
            <div>
              <Select
                defaultValue={'New Project'}
                style={{width: '100%', fontWeight: 'bold'}}
                className="formInputs"
                size={'large'}
                onChange={handleProjectTypeChange}
              >
                <Option value="New Project">New Project</Option>
                <Option value="Existing Project">Existing Project</Option>
                <Option value="Debugging">Debugging</Option>
                <Option value="Assignment">Assignment</Option>
              </Select>
            </div>
          </div>

          <div className="form-inline">
            <div className="form-group">
              <label>Details : </label>
              <textarea
                required
                value={details}
                className="form-control"
                onChange={e => {
                  setDetails (e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-inline">
            <div className="form-group">

              <Checkbox
                checked={bookingSession}
                onChange={e => {
                  setBookingSession (e.target.checked);
                }}
              >
                <label>Do You Need Code Walkthrough of Solution? </label>{' '}
              </Checkbox>

            </div>
            <div className="form-group">

              <Checkbox
                checked={documentation}
                onChange={e => {
                  setDocumentation (e.target.checked);
                }}
              >
                <label>Need Write up or report ? </label>{' '}
              </Checkbox>

            </div>

          </div>

          <div className="form-group">
            <label> Upload Project Docs: </label>
            <input
              type="file"
              className="form-control file"
              key={fileKey}
              onChange={e => {
                handleFileChange (e.target.files[0]);
              }}
            />
            <button
              className="uploadBtn"
              disabled={uploadFile === null}
              type="button"
              onClick={() => {
                handleFileUpload (uploadFile);
              }}
            >
              Upload
            </button>
            {uploadFile &&
              <button onClick={handleFileRemove} className="uploadBtn">
                Remove
              </button>}

            <div>
              {uploadPercent > 0 &&
                <div style={{width: '50%'}}>
                  <Progress
                    percent={uploadPercent}
                    size="small"
                    status={uploadStatus}
                  />
                </div>}
              {uploadPercent === 100
                ? <div style={{color: 'green'}}>
                    File Uploaded Successfully!{' '}
                  </div>
                : uploading &&
                    <div style={{color: 'blue'}}>
                      uploading...
                    </div>}
              {uploadStatus === 'exception' &&
                <div style={{color: 'red'}}>
                  Failed to upload file!{' '}
                </div>}
            </div>
          </div>
          <br />

          <div className="form-group">
            <button
              style={{width: 'fit-content'}}
              className="pay-btn"
              disabled={
                name === '' ||
                  details === '' ||
                  deadline === '' ||
                  category === ''
              }
              onClick={e => {
                createOrder (e);
              }}
            >
              {loading ? <Spin indicator={spinIcon} /> : 'Create Order'}
            </button>
          </div>

        </form>
        {successMessage
          ? <Message type="success" message="Created Order Successfully" />
          : null}

      </Spin>

      <Modal
        title="Order Submitted"
        visible={confirmationModal}
        onCancel={() => setConfirmationModal (false)}
        footer={false}
      >
        <div style={{fontStyle: 'normal'}}>

          <h3 style={{color: 'green', marginBottom: '5px'}}>
            Your Order is Submitted Successfully.
          </h3>
          <div style={{fontSize: '14px', color: 'gray', textAlign: 'justify'}}>
            We will send a Confirmation mail regarding your order in just 1-2 hours.
            Make sure to check your email for further order processing.
            You can also contact us at contact@codersarts.com for more information.
          </div>

        </div>
      </Modal>
    </div>
  );
};

export default CreateOrderForUser;
