import React,{useState,useEffect} from 'react'
import {Button,Modal,Card,message, Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import {history} from '../../../helpers/history';
import orderService from '../../../services/order.service';
import './AdminSubscription.css';
import {EditOutlined, ExclamationCircleOutlined,DeleteOutlined,PlusOutlined} from '@ant-design/icons';


function AdminSubscription() {
    const [subscriptionModal,setSubscriptionModal] = useState(false);
    const [newSubscriptionName,setNewSubscriptionName] = useState("");
    const [newSubscriptionPrice,setNewSubscriptionPrice] = useState("");
    const [newSubscriptionDetails,setNewSubscriptionDetails] = useState("");
    const [loading,setLoading] = useState(false);
    const [allSubscriptionCategories,setAllSubscriptionCategories] = useState("");
    const [currentEditCategory,setCurrentEditCategory] = useState("");
    const [updatedName,setUpdatedName] = useState("");
    const [updatedPrice,setUpdatedPrice] = useState("");
    const [updatedDetails,setUpdatedDetails] = useState("");
    const [editModal,setEditModal] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser]);


    const getAllSubscriptionCategories = async ()=>{
        try{
            const response = await orderService.getAllSubscriptionCategories();
            // console.log("All Subsc : ",response.data);
            setAllSubscriptionCategories(response.data);
            
        }catch(err){
            console.log(err.message ? err.message : 'Something Went Wrong!');
        }
    }
    useEffect(()=>{
            getAllSubscriptionCategories();
    },[])

    const editDetailsModal = (data)=>{
        setCurrentEditCategory(data);
        setUpdatedName(data.name);
        setUpdatedPrice(data.price);
        setUpdatedDetails(data.details);
        setEditModal(true);
    }

    const handleSubscriptionSubmit = async ()=>{
        setLoading(true);
        const data = {
            name:newSubscriptionName,
            price:newSubscriptionPrice,
            details:newSubscriptionDetails
        }
        try{
            const response = await orderService.createNewSubscription(data);
            // console.log(response.data);
            message.success("Added new Subscription Category!");
            getAllSubscriptionCategories();
            setLoading(false);
            setSubscriptionModal(false);
            setNewSubscriptionDetails("");
            setNewSubscriptionName("");
            setNewSubscriptionPrice("");
            
        }catch(err){
            console.log(err);
            setLoading(false);
            message.error(err.message ? err.message : 'Unable to add new Subscription Category!');
        }
        // console.log("data : ",data);
    }

    const handleSubscriptionClose = ()=>{
        setSubscriptionModal(false);
        setNewSubscriptionDetails("");
        setNewSubscriptionName("");
        setNewSubscriptionPrice("");
    }

    const handleEditSubmit = async ()=>{
        let data = {name:updatedName,price:updatedPrice,details:updatedDetails}
        let id = currentEditCategory._id;
      setLoading(true);
      try{
          const response = await orderService.updateSubscriptionCategory(id,data);
        //   console.log(response.data);
          message.success(`Updated ${currentEditCategory.name} Successfully!`);
          getAllSubscriptionCategories();
          setLoading(false);
          setEditModal(false);
          
      }catch(err){
          setLoading(false);
          console.log(err);
          message.error(err.message ? err.message : 'Unable to Update Subscription Category!');

      }



    }

    const handleEditCancel = ()=>{
        setEditModal(false);
    }

    const handleDelete = async (id)=>{
        try{
            const response = await orderService.deleteSubscriptionCategory(id);
            // console.log(response.data);
            getAllSubscriptionCategories();
            message.success("Deleted Category Successfully!");
        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to Delete Subscription Category!');

        
        }
    }


    const confirmDelete = (id)=>{
        Modal.confirm({
            title: 'Confirm Delete',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to Delete this Subscription category?',
            okText: 'Yes',
            cancelText: 'No',
            onOk:()=>{handleDelete(id)}
          });
        
    }
    
    return (
        <div className='subscription'>
           <div className='subscription-header'>
           <h2>Subscriptions Category</h2>
           <Button type={'primary'} 
           className="create-btn" 
           onClick={()=>{setSubscriptionModal(true)}}> 
           <PlusOutlined />
           Create New</Button>
           </div>
           <hr />


           <div className="subsCardGroup">
                {allSubscriptionCategories && allSubscriptionCategories.length > 0 && allSubscriptionCategories.map((item)=>{
                    return(
                        <Card className='subsCard'>
                            <div className="subsCard-header">
                                <h5>{item.name}</h5>
                                <div className='cardActions'>
                                <Tooltip color={'rgb(0, 102, 255)'} title="Edit Details?"><EditOutlined onClick={()=>{editDetailsModal(item)}} className='subsCard-edit'/></Tooltip>
                                <Tooltip color={'red'} title="Delete?"><DeleteOutlined  onClick={()=>{confirmDelete(item._id)}}  className='subsCard-delete'/></Tooltip>

                                </div>
                            </div>
                            <div className='subsCard-price'>
                                <h6>{item.price}</h6>
                            </div>
                            {/* <div className='subsCard-delete'>
                                <Button type={'primary'} className='subsCard-delete-btn'>Delete</Button>
                            </div> */}
                            <div className='subsCard-details'>
                                <ul>
                                {item.details.split('\n').map((line)=>{
                                    return(<li>{line}</li>)
                                })}
                                </ul>
                            </div>
                        </Card>
                    )
                })}
           </div>

           <Modal 
            title={"Create new Subscription"}
            visible={subscriptionModal} 
            onOk={handleSubscriptionSubmit} 
            onCancel={handleSubscriptionClose}
            confirmLoading={loading}
         >
             <form>
             <div className="form-group mb-3">
             <label className="mb-2"><h6><b>Enter Subscription category name :</b></h6></label>
               <input 
               type="text" 
               className="form-control"
               placeholder='e.g. Standard' 
               value={newSubscriptionName} 
               onChange={(e)=>{setNewSubscriptionName(e.target.value)}}/>
             </div>


             <div className="form-group  mb-3">
             <label className="mb-2"><h6><b>Enter Subscription Price:</b></h6></label>
               <input 
               type="text"
               placeholder='e.g. $250' 
               className="form-control"
               value={newSubscriptionPrice} 
               onChange={(e)=>{setNewSubscriptionPrice(e.target.value)}}/>
             </div>

             <div className="form-group  mb-3">
             <label className="mb-2"><h6><b>Enter Subscription Details:</b></h6></label>
               <textarea 
               type="text" 
               className="form-control"
               value={newSubscriptionDetails} 
               onChange={(e)=>{setNewSubscriptionDetails(e.target.value)}}>
               </textarea>
             </div>


             </form>
        </Modal>



        <Modal 
            title={`Update ${currentEditCategory ? currentEditCategory.name : 'Details'}`}
            visible={editModal} 
            onOk={handleEditSubmit} 
            onCancel={handleEditCancel}
            confirmLoading={loading}
         >
             <form>
             <div className="form-group mb-3">
             <label className="mb-2"><h6><b>Enter Subscription category name :</b></h6></label>
               <input 
               type="text" 
               className="form-control"
               placeholder='e.g. Standard' 
               value={updatedName } 
               onChange={(e)=>{setUpdatedName(e.target.value)}}/>
             </div>


             <div className="form-group  mb-3">
             <label className="mb-2"><h6><b>Enter Subscription Price:</b></h6></label>
               <input 
               type="text"
               placeholder='e.g. $250' 
               className="form-control"
               value={updatedPrice} 
               onChange={(e)=>{setUpdatedPrice(e.target.value)}}/>
             </div>

             <div className="form-group  mb-3">
             <label className="mb-2"><h6><b>Enter Subscription Details:</b></h6></label>
               <textarea 
               type="text" 
               className="form-control"
               value={updatedDetails} 
               onChange={(e)=>{setUpdatedDetails(e.target.value)}}>
               </textarea>
             </div>


             </form>
        </Modal>

        </div>
    )
}

export default AdminSubscription
