import "./message.css";
import { format } from "timeago.js";
import avatar from '../../../assets/user.png';
import React from "react";
import {DownloadOutlined} from '@ant-design/icons'



export default function Message({ message, own,sender }) {

  
  var preview = <p className="messageText">{message.text ? message.text : message.message}</p>;

  if(message.msgType){
    if(message.msgType.split('/')[0]==='video'){
      // console.log("FILE URL : ",message.fileUrl);
      preview =  (<video width="300" height="200" controls loop >
                  <source src={message.fileUrl} type="video/mp4"/>
                </video>)
    }
    else if(message.msgType.split('/')[0]==='image'){
      // console.log("FILE URL : ",message.fileUrl);
      preview = <a href={message.fileUrl} target="_blank"><img className="msgImage" src={message.fileUrl} 
      style={{height:'200px',width:'300px',objectFit:'cover'}} 
      alt={message.text ? message.text : message.message} /></a>
    }
    else if(message.msgType.split('/')[0]==='application'){
      // console.log("FILE URL : ",message.fileUrl);
      preview = (<div className={`otherFileType ${own ? 'otherOwn' : null}`}>
        <div style={{marginLeft:'10px'}}>
        {message.text ? message.text : message.message}
        </div>
          
         <div>
         <a href={message.fileUrl} target="_blank" className="download-icon">
         <DownloadOutlined style={{fontSize:'1.8rem',marginLeft:'0.8rem'}} />
        </a>
         </div>
       </div>)
    }
  
  }


  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <img
          className="messageImg"
          src={sender.profilePicture ? sender.profilePicture : avatar}
          alt=""
        />
       {preview}
      </div>
      <div className="messageBottom">{format(message.createdAt)}</div>
    </div>
  );
}
