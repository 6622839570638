import React, {useState, useEffect} from 'react';
import {Tabs, Button, DatePicker, Input, Select, message, Table} from 'antd';
import './UserLeave.css';
import orderService from '../../../services/order.service';
import {useSelector} from 'react-redux';
import moment from 'moment';
import userService from '../../../services/user.service';

const {Option} = Select;

const {TabPane} = Tabs;
const {TextArea} = Input;

const {RangePicker} = DatePicker;
const dateFormat = 'YYYY/MM/DD';

function UserLeave () {
  const [leaveTypes, setLeaveTypes] = useState ([]);
  const [newLeaveType, setNewLeaveType] = useState ('');
  const [newLeaveDate, setNewLeaveDate] = useState ([]);
  const [numberOfLeaves, setNumberOfLeaves] = useState (0);
  const [leaveMessage, setLeaveMessage] = useState ('');
  const [myLeaves,setMyLeaves] = useState([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [filteredLeave,setFilteredleave] = useState([]);
  const [statusFilterArr,setStatusFilterArr] = useState([]);


  const getAllLeaves = async () => {
    try {
      const response = await orderService.getUserLeave ({userId:currentUser.id});
      // console.log ("leaves : ",response.data);
      let data = []
      response?.data.forEach((item)=>{
        const date = new Date (item.createdAt);
        let dd = date.getDate ();
        let mm = date.getMonth () + 1;
        let yyyy = date.getFullYear ();
        let hh = date.getHours ();
        let minutes = date.getMinutes ();
        let ss = date.getSeconds ();
        let finalDate =
          dd +
          '-' +
          mm +
          '-' +
          yyyy +
          ' at ' +
          hh +
          ':' +
          minutes +
          ':' +
          ss;


        let modifiedData = {
          createdAt:finalDate,
          sortDate:item.createdAt,
          leaveId:item._id,
          leavePeriod:`${item.leaveDays[0]} to ${item.leaveDays[1]}`,
          leaveType:item.leaveType[0].name,
          numberOfLeaves:item.numberOfLeaves,
          leaveStatus:item.status,
          leaveDescription:item.leaveMessage,
          leaveDates:item.leaveDays
        }
        data.push(modifiedData)
      });
      // console.log("final data : ",data);
      setMyLeaves(data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };




  useEffect (() => {
    const getAllLeaveTypes = async () => {
      try {
        const response = await orderService.getAllLeaveType ();
        if (response) {
          console.log (response.data);
          setLeaveTypes (response.data);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    const getAllLeaves = async () => {
      try {
        const response = await orderService.getUserLeave ({userId:currentUser.id});
        // console.log ("leaves : ",response.data);
        let data = []
        response?.data.forEach((item)=>{
          const date = new Date (item.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          let finalDate =
            dd +
            '-' +
            mm +
            '-' +
            yyyy +
            ' at ' +
            hh +
            ':' +
            minutes +
            ':' +
            ss;
  
  
          let modifiedData = {
            createdAt:finalDate,
            sortDate:item.createdAt,
            leaveId:item._id,
            leavePeriod:`${item.leaveDays[0]} to ${item.leaveDays[1]}`,
            leaveType:item.leaveType[0].name,
            numberOfLeaves:item.numberOfLeaves,
            leaveStatus:item.status,
            leaveDescription:item.leaveMessage,
            leaveDates:item.leaveDays
          }
          data.push(modifiedData)
        });
        // console.log("final data : ",data);
        setMyLeaves(data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Something went wrong!');
      }
    };

    getAllLeaves();



    getAllLeaveTypes ();
  }, [currentUser]);


  useEffect (
    () => {

      let statusFilter = [];
      myLeaves.map (o => {

        if (o.leaveStatus) {
          statusFilter.push ({text: o.leaveStatus, value: o.leaveStatus});
        }
      });
    

      statusFilter = statusFilter.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

    
      setStatusFilterArr (statusFilter);
    
    },
    [myLeaves]
  );




  const leaveColumns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: '1',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    
    {
      title: 'Leave Period',
      dataIndex: 'leavePeriod',
      key: '2',
    },
    {
      title: 'Leave Type',
      dataIndex: 'leaveType',
      key: '3',
    },
    {
      title: 'Number of Leaves',
      dataIndex: 'numberOfLeaves',
      key: '4',
    },
    {
      title: 'Leave Status',
      key: '5',
      filters: statusFilterArr,
      onFilter: (value, record) => record.leaveStatus.indexOf (value) === 0,
      render:(record)=>{
        return(<b class={`${record.leaveStatus==='Pending' ? 'yellow' : record.leaveStatus==='Approved' ? 'green' : 'red'}`}>{record.leaveStatus}</b>)
      }
    },
   
  ]




  const handleLeaveDate = (dates, dateString) => {
    // console.log (dates);
    setNewLeaveDate (dateString);
    // console.log (dateString);
    const date1 = new Date (dateString[0]).getDate ();
    const date2 = new Date (dateString[1]).getDate ();
    const diff = date2 - date1;
    // console.log(diff);
    setNumberOfLeaves (diff);
  };

  const handleSubmit = async () => {
    let data = {
      leaveUser: currentUser.id,
      leaveType: newLeaveType,
      leaveDays: newLeaveDate,
      leaveMessage: leaveMessage,
      numberOfLeaves: numberOfLeaves,
    };



    // console.log (data);
    try {
      const response = await orderService.createNewLeave (data);
      if (response) {
        // console.log (response.data);
        message.success (
          'Requested For your leave. You will get the notification after the confirmation from admin'
        );

        userService.createNewNotification ({
          notification:  `${currentUser.username} APPLIED FOR LEAVE: ${currentUser.username} applied a leave for ${data.leaveDays[0]} to ${data.leaveDays[1]}!`,
          user: '612c663a0e4bbd453837cf39',
        })
          .then (result => {
            if (result) {
            }
          })
          .catch (Err => {
            console.log (Err);
          });
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  


  const handleSearchLeaveByDate = (date,dateString)=>{
    if(date){
      // console.log(dateString)
      const filteredData = myLeaves.filter((item)=>{
        var from = Date.parse(item.leaveDates[0]);
        var to   = Date.parse(item.leaveDates[1]);
        var check = Date.parse(dateString );
  
        return check <= to && check >= from     
      
      });
      // console.log(filteredData);
      setFilteredleave(filteredData)
    }else{
      setFilteredleave([])
    }
    }


  return (
    <div className="userLeave">
      <Tabs
        type="card"
        defaultActiveKey="1"
        size={'large'}
        onChange={getAllLeaves}
        style={{marginBottom: 32}}
      >
        <TabPane tab="Apply Leave" key="1">
          <div className="applyLeave">
            <h2>Fill the Form for Leave</h2>
            <form>

              <div className="formGroup">
                <label>Select Leave Type: </label>
                <Select
                  showSearch
                  optionFilterProp="children"
                  onChange={value => {
                    setNewLeaveType (value);
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase ()
                      .indexOf (input.toLowerCase ()) >= 0}
                >
                  {leaveTypes.map (item => {
                    return (
                      <Option value={item._id} key={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>

              <div className="formGroup">
                <label>Select Leave Date/Dates: </label>
                <RangePicker format={dateFormat} onChange={handleLeaveDate} />
              </div>

              <div className="formGroup">
                <label>Number of Leaves: </label>
                <Input value={numberOfLeaves} disabled={true} />
              </div>

              <div className="formGroup">
                <label>Leave Description: </label>
                <TextArea
                  value={leaveMessage}
                  showCount
                  onChange={e => {
                    setLeaveMessage (e.target.value);
                  }}
                />
              </div>
              <div>
                <Button type={'primary'} onClick={handleSubmit}>
                  Apply
                </Button>
              </div>
            </form>
          </div>
        </TabPane>
        <TabPane tab="My Leaves" key="2">
        <div className="myLeave">
        <div className="searchHeader">
        <div>
        <h2>My Leaves</h2>
        </div>
          <div>
          <DatePicker placeholder='Filter By Date' format={dateFormat} onChange={handleSearchLeaveByDate} />
          </div>
          </div>
          <Table 
          expandable={{
              expandedRowRender: record => <p style={{ margin: 0 }}>{record.leaveDescription}</p>,
            }}
          dataSource={filteredLeave.length > 0 ? filteredLeave : myLeaves } columns={leaveColumns} />
       
        </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default UserLeave;
