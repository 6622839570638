import {DeleteOutlined} from '@ant-design/icons';
import {Button, message, Input, Modal, Card} from 'antd';
import React, {useEffect, useState} from 'react';
import orderService from '../../../services/order.service';
import userService from '../../../services/user.service';
const {Meta} = Card;

function MobileBanner () {
  const [isBannerUpload, setIsBannerUpload] = useState (false);
  const [title, setTitle] = useState ('');
  const [uploading, setUploading] = useState (false);
  const [fileToUpload, setFileToUpload] = useState (null);
  const [allBanners, setAllBanners] = useState ([]);

  const getAllBanners = async () => {
    try {
      const response = await orderService.getMobileBanner ();
      // console.log (response.data);
      setAllBanners (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
    }
  };

  useEffect (() => {
    let isMounted = true;

    const getAllBanners = async () => {
      try {
        const response = await orderService.getMobileBanner ();
        // console.log (response.data);
        setAllBanners (response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    if (isMounted) {
      getAllBanners ();
    }

    return () => {
      isMounted = false;
    };
  }, []);
  const setFileUrlInDB = async fileUrl => {
    console.log (fileUrl);
    try {
      const response = await orderService.createMobileBanner (title, fileUrl);
      // console.log (response);

      if (response) {
        message.success ('Banner Uploaded Successfully!');
        setUploading (false);
        setIsBannerUpload (false);
        setTitle ('');
        getAllBanners ();
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong!');
      setUploading (false);
    }
  };

  const handleBannerUpload = async e => {
    const file = fileToUpload;
    // console.log (file);
    if (file.size > 1048576) {
      message.warning ('Sorry, But File is Larger than 1 MB !');
      return;
    }

    if (file) {
      setUploading (true);
      try {
        var formData = new FormData ();
        formData.append ('myfile', file);
        // for (var key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }
        // const data = await ReactS3Client.uploadFile(file,file.name);
        const response = await userService.updateProfilePic (formData);
        // console.log (response.data);
        if (response.data) {
          setFileUrlInDB (response.data);
        } else {
          setUploading (false);
        }

        // console.log(response.data);
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong !');
        setUploading (false);
      }
    }
  };

  const handleDelete = async (id, title) => {
    try {
      const response = await orderService.deleteMobileBanner (id);
      // console.log (response.data);
      if(response){
        message.success (`${title} Banner Deleted Successfully!`);
        getAllBanners ();
      }
     
     
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'something went wrong !');
    }
  };

  return (
    <div>
      <Button
        type="primary"
        style={{float: 'right'}}
        onClick={() => setIsBannerUpload (true)}
      >
        Upload
      </Button>
      <br />
      <div style={{marginTop: '2%', display: 'flex', flexWrap: 'wrap'}}>
        {allBanners.map (item => {
          return (
            <Card
              hoverable
              key={item._id}
              style={{
                width: 240,
                textAlign: 'center',
                margin: '15px',
              }}
              cover={<img alt={item.title} src={item.fileUrl} />}
              actions={[
                <DeleteOutlined
                  key="Delete"
                  onClick={() => handleDelete (item._id, item.title)}
                />,
              ]}
            >
              <Meta title={item.title} />
            </Card>
          );
        })}
      </div>

      <Modal
        title="Upload Image for Banner"
        visible={isBannerUpload}
        onOk={handleBannerUpload}
        okText="Upload"
        confirmLoading={uploading}
        onCancel={() => setIsBannerUpload (false)}
      >

        <div>
          <label>Enter Banner Title: </label>
          <Input
            type="text"
            value={title}
            onChange={e => setTitle (e.target.value)}
          />
        </div>

        <div>
          <label>Select File: </label>
          <input
            type="file"
            name="bannerFile"
            onChange={e => {
              setFileToUpload (e.target.files[0]);
            }}
            className="form-control mb-4"
            placeholder="Enter New Skill Category"
          />
        </div>
      </Modal>
    </div>
  );
}

export default MobileBanner;
