import { message } from 'antd';
import React,{useState,useEffect} from 'react'
import paymentService from '../../services/payment.service';
import '../SinglePaymentDetails/SinglePaymentDetails.css';
import { history } from '../../helpers/history';
import { useSelector } from 'react-redux';



function SinglePublicPaymentDetails({match}) {
    const [paymentDetails,setPaymentDetails] = useState(null);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [createdAt,setCreatedAt] = useState(null);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            if(currentUser.roles.includes('ROLE_SALES')){
                return;
            }
            history.push('/profile');
        }
    },[currentUser])

    const getSinglePaymentDetails = async ()=>{
        try{

            const response = await paymentService.getSinglePublicPaymentDetails(match.params.id);
            // console.log(response.data);
            setPaymentDetails(response.data);
            if(response.data.createdAt){
                const date = new Date(response.data.createdAt);
                let dd = date.getDate();
                let mm = date.getMonth() + 1;
                let yyyy = date.getFullYear();
                let hh = date.getHours();
                let minutes = date.getMinutes();
                let ss = date.getSeconds();
                let finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
               setCreatedAt(finalDate);
            }

        }catch(err){
            console.log(err);
            message.error(err.message);
        }
    }

    useEffect(() => {
        // console.log(match);
        getSinglePaymentDetails();
    }, [])
    return (
        <div className="single-payment-details">
        <h2><b>{paymentDetails && paymentDetails.name} Details {paymentDetails && <span className="successTag">{paymentDetails.status ? paymentDetails.status : 'Success'}</span>} </b></h2>
        <hr />
       {
           paymentDetails &&
           <>
            <div className="card">
               <div className="card border-0">
                    <div className="card-header">
                        <h4>All Details</h4>
                    </div>

                    <div className="card-body">

                    <table>
                        <tr>
                            <th>Created At</th>
                            <td>{createdAt}</td>
                        </tr>
                        <tr>
                            <th>Transaction Id</th>
                            <td>{paymentDetails.paymentIntent && paymentDetails.paymentIntent.id }</td>
                        </tr>
                        <tr>
                            <th>Customer</th>
                            <td>{paymentDetails.name && paymentDetails.name}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{paymentDetails.email && paymentDetails.email}</td>
                        </tr>
                        <tr>
                            <th>Country</th>
                            <td>{paymentDetails.country && paymentDetails.country}</td>
                        </tr>
                        <tr>
                            <th>Amount Paid</th>
                            <td>{paymentDetails.amount && paymentDetails.amount}</td>
                        </tr>

                        <tr>
                            <th>Currency</th>
                            <td>{paymentDetails.currency && paymentDetails.currency}</td>
                        </tr>

                        <tr>
                            <th>Payment Method</th>
                            <td>{paymentDetails.paymentMethod && paymentDetails.paymentMethod}</td>
                        </tr>

                        

                    </table>
                    </div>
               </div>
            </div>
           </>

       }
        </div>
    )
}

export default SinglePublicPaymentDetails
