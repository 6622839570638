import {Table, Space, Button, message} from 'antd';
import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import orderService from '../../services/order.service';
import {history} from '../../helpers/history';
import {EyeOutlined, DownloadOutlined} from '@ant-design/icons';
import moment from 'moment';

const PurchasedOrders = () => {
  const [purchasedOrders, setPurchasedOrders] = useState ([]);
  const [allDocs, setAllDocs] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_USER')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllPurchasedOrders = async () => {
    let orderPayments = [];
    try {
      //   const response = await orderService.getCreatedOrder (currentUser.id);
      const customerOrder = await orderService.getCustomerOrder (
        currentUser.id
      );
      //   console.log ('CREATED ORDERS : ', response.data);
      //   console.log ('Customer ORDERS : ', customerOrder.data);
      //   let finalResponse = response.data.concat (customerOrder.data);
      //   console.log ('final combined array : ', finalResponse);
      let filteredData = [];
      customerOrder.data.map (item => {
        if (item.status && item.status.toLowerCase () === 'delivered') {
          //    console.log("fell")
          filteredData.push (item);
        }
      });
      filteredData = filteredData.sort (function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return moment (b.createdAt).unix () - moment (a.createdAt).unix ();
      });
      //   console.log ('FILTERED DATA : ', filteredData);

      //   filteredData = filteredData.filter (
      //     (item, index, self) =>
      //       index ===
      //       self.findIndex (o => o.id === item.id && o.name === item.name)
      //   );

      filteredData.forEach (order => {
        const purchasedOrderDetails = {
          id: order._id,
          order: order.name,
          price: order.totalAmount,
          category: order.category,
        };

        orderPayments.push (purchasedOrderDetails);
      });

      setPurchasedOrders (orderPayments);
      //   console.log ('FINAL ORDER PAYMENT DATA : ', orderPayments);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : err);
    }
  };

  const getAllDocs = async () => {
    const fetchedDocs = [];
    try {
      const res = await orderService.getAllDocs ();
      // console.log(res.data);
      res.data.map ((d, index) => {
        // console.log("CUSTOMER : ",d.assignedOrder)
        if (d.assignedOrder.length > 0) {
          if (d.assignedOrder[0].createdBy[0] === currentUser.id) {
            fetchedDocs.push ({
              key: index + 1,
              id: d._id,
              fileLink: d.fileLink,
              order: [d.assignedOrder[0].name, d.assignedOrder[0]._id],
            });
          } else if (
            d.assignedOrder[0].customer &&
            d.assignedOrder[0].customer[0] &&
            d.assignedOrder[0].customer[0] === currentUser.id
          ) {
            // console.log("got it!")
            fetchedDocs.push ({
              key: index + 1,
              id: d._id,
              fileLink: d.fileLink,
              order: [d.assignedOrder[0].name, d.assignedOrder[0]._id],
            });
          }
        }
      });
      // console.log("Fetched Data ",fetchedDocs);
      setAllDocs (fetchedDocs);
    } catch (err) {
      console.log (err);
    }
  };

  useEffect (() => {
    getAllDocs ();
    getAllPurchasedOrders ();
  }, []);

  const handleDownload = id => {
    // console.log("ID : ",id);

    let foundDoc = allDocs.filter (d => {
      return d.order && d.order[1] === id;
    });
    // console.log("Found Doc : ",foundDoc);
    let linkToDownload = foundDoc[0] && foundDoc[0].fileLink;
    if (linkToDownload) {
      window.open (linkToDownload, '_blank');
    } else {
      message.error ('Document Not Found!');
    }
  };

  const columns = [
    {
      title: 'Order',
      key: 'order',
      render: record => {
        return (
          <Link to={`/profile/view-order-details/${record.id}`} target="_blank">
            <b>{record.order}</b>
          </Link>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },

    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Link to={`/profile/view-order-details/${record.id}`} target="_blank">
            <Button>
              <EyeOutlined /> View Details
            </Button>
          </Link>
          <Button
            type={'primary'}
            onClick={() => {
              handleDownload (record.id);
            }}
          >
            <DownloadOutlined /> Source Code
          </Button>

          {/* <Menu.Item key="1">
                        <Link to={`/profile/view-order-details/${record.id}`} target="_blank"> <EyeOutlined /> View Details </Link>
                        </Menu.Item>
                        <Menu.Item key="2" onClick={()=>{handleDownload(record.id)}}>
                      <EditOutlined /> Download Source
                        </Menu.Item>
                    </Menu>
                    }>
                   */}

        </Space>
      ),
    },
  ];

  return (
    <div>
      <h1>Purchased Orders</h1>
      {purchasedOrders &&
        <Table columns={columns} dataSource={purchasedOrders} />}

    </div>
  );
};

export default PurchasedOrders;
