import OrderServices from '../services/order.service';
import {SETCOINS} from './types';

export const setCoins = payload => {
  return {
    type: SETCOINS,
    payload: payload,
  };
};

export const getUserCoins = userData => {
  //   console.log (userData);
  return async dispatch => {
    try {
      const response = await OrderServices.getUserCoins (userData);
      // console.log ('userCoins !', response.data);

      dispatch (setCoins (response.data));
    } catch (err) {
      console.log (err);
    }
  };
};

export const createUserCoins = coinData => {
  return async dispatch => {
    try {
      const response = await OrderServices.createUserCoins (coinData);
      //   console.log (response);
      dispatch (getUserCoins ({userId: coinData.userId}));
    } catch (err) {
      console.log (err);
    }
  };
};

export const updateUserCoins = newData => {
  return async dispatch => {
    try {
      const response = await OrderServices.updateUserCoins (newData);

      dispatch (setCoins (response.data));
    } catch (err) {
      console.log (err);
    }
  };
};
