import React,{useState,useEffect, useRef} from 'react'
import userService from '../../../services/user.service';
import defaultPic from '../../../assets/user.png';
import './ProfilePicture.css';
import { Alert,Spin, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import OrderService from '../../../services/order.service';
// import S3 from 'react-aws-s3';
import Message from '../../Message/Message';
import { useDispatch } from 'react-redux';
import { update } from '../../../actions/users';

function ProfilePicture({userId}) {
    const [userProfilePic,setUserProfilePic] = useState(null);
    const [showWarning,setShowWarning] = useState(false);
    const [awsData,setAwsData] = useState(null);
    const inputFileRef = useRef(null);
    const [loading,setLoading] = useState(null);
    const [successMessage,setSuccessMessage] = useState(null);
    const [errorMessage,setErrorMessage] = useState(null);
    const dispatch = useDispatch();


    const getUserProfilePic = async (userId)=>{
        try{
            const response = await userService.getUserById(userId);
            // console.log("Got pic: ",response.data)
            if(response.data.profilePicture){
                setUserProfilePic(response.data.profilePicture);
            }
        }catch(err){
            console.log(err);
        }
    }

    useEffect(()=>{
        getUserProfilePic(userId);
    },[userId])
    const getAwsInfo = async ()=>{
        try{
         const res = await OrderService.getAwsInfo();
         setAwsData(res.data);
        }catch(err){
            console.log(err);
        }
 
     }

     useEffect(()=>{
         if(successMessage){
             setSuccessMessage(false);
         }
         if(errorMessage){
            setErrorMessage(false);
        }
     })

     useEffect(()=>{
        getAwsInfo();
     },[]);

     const setProfilePicInDb = async (fileLink)=>{
        const  data = {_id:userId,profilePicture:fileLink}
         try{
            const response = await dispatch(update(data))
            // console.log("Profile In DB : ",response);
         }catch(err){
             console.log(err);
         }
        
     }


 

    const onButtonClick = ()=>{
        setShowWarning(false);
        inputFileRef.current.click();
       
    }

    const uploadFile = async (e)=>{
        const file = e.target.files[0]
        // console.log(e.target.files[0]);
        if(file.size > 1048576){
            setShowWarning(true);
            return;
        }

        if(file){
            setLoading(true);
            // const config = {
            //     bucketName:awsData.bucketName,
            //     region:'ap-south-1',
            //     accessKeyId: awsData.accessKeyId,
            //     secretAccessKey: awsData.secretKey,
            //     s3Url:"https://" + awsData.bucketName + ".s3.amazonaws.com"
            // }
    
            // const ReactS3Client = new S3(config);
           try{
            var formData = new FormData();
            formData.append('userId',userId);
            formData.append('myfile',file);
            // for (var key of formData.entries()) {
            //     console.log(key[0] + ', ' + key[1]);
            // }
            // const data = await ReactS3Client.uploadFile(file,file.name);
            const response = await userService.updateProfilePic(formData)
            setProfilePicInDb(response.data);
            // console.log(response.data);
            setLoading(false);
            setSuccessMessage(true);
            setUserProfilePic(response.data);
           }catch(err){
               console.log(err);
               setErrorMessage(true);
               setLoading(false);
           }
        }


    }

   
    return (
        <div className="profile-pic-container">
            {successMessage && <Message type="success" message="Successfully Uploaded Your Profile Picture!" /> }
            {errorMessage && <Message type="error" message="Something Went Wrong !" />}
            <Spin tip="Uploading..." spinning={loading}>
            <div>
            { showWarning && 
                <Alert
                message="Image Too Big"
                description="Make Sure Your Image Size Should Be Less than 1 MB !"
                type="warning"
                showIcon
                closable
                />
                }
            </div>
            <div>
                <img className="profile-pic" src={userProfilePic ? userProfilePic : defaultPic} />
                <input type="file" accept="image/*" onChange={(e)=>{uploadFile(e)}} ref={inputFileRef} style={{display:'none'}} />
            </div>
            <div>
            <Tooltip  title="Change Profile Picture ?" color={'#f50'}>
            <PlusOutlined className="plusBtn" onClick={onButtonClick}/>
            </Tooltip>
          
            </div>
            </Spin>
        </div>
    )
}

export default ProfilePicture
