import React, {useEffect, useState, useRef} from 'react';
import OrderService from '../../../services/order.service';
import UserService from '../../../services/user.service';
import GoogleServices from '../../../services/google.services';
import { createBrowserHistory } from 'history';
import { format } from "timeago.js";
import {
  Breadcrumb,
  Dropdown,
  Radio,
  Tooltip,
  Tabs,
  Button,
  Modal,
  message,
  Menu,
  Select,
  Input,
  Tag,
} from 'antd';
import {Link} from 'react-router-dom';
import '../Profile.css';
import {useSelector} from 'react-redux';
import {
  PlusOutlined,
  QuestionCircleOutlined,
  ShoppingCartOutlined,
  MessageOutlined,
  DeleteOutlined,
  ExceptionOutlined
} from '@ant-design/icons';
import './ViewOrderDetails.css';
import uploadIcon from '../../../assets/uploadRequirements.png';
import avatarPic from '../../../assets/user.png';
import CommentArea from '../../CommentArea/CommentArea';
import { useLocation } from "react-router";

// let socketConnection =  "http://localhost:3000";
const {TextArea} = Input;

const {TabPane} = Tabs;
const {Option} = Select;

const ViewOrderDetails = ({match}) => {
  const [orderDetails, setOrderDetails] = useState ('');
  const [allDocs, setAllDocs] = useState ([]);
  const [requirementVisible, setRequirementVisible] = useState (false);
  const [requirementFiles, setRequirementFiles] = useState ('');
  const [createdAt, setCreatedAt] = useState ('');
  const [confirmRequirementLoading, setConfirmRequirementLoading] = useState (
    false
  );
  const [deliverableModal, setDeliverableModal] = useState (false);
  const [confirmDeliverableLoading, setConfirmDeliverableLoading] = useState (
    false
  );
  const [deliverableFiles, setDeliverableFiles] = useState ('');
  const [sourceCodeModal, setSourceCodeModal] = useState (false);
  const [sourceCodeFile, setSourceCodeFile] = useState ('');
  const [confirmSourceCodeUpload, setConfirmSourceUpload] = useState (false);
  const [refundStatusModal, setRefundStatusModal] = useState (false);
  const [refundStatus, setRefundStatus] = useState ('');
  const [confirmRefundStatusModal, setConfirmRefundStatusModal] = useState (
    false
  );
  const [refundValueOption, setRefundValueOption] = useState (1);
  const [differenceOfDays, setDifferenceOfDays] = useState (null);
  const [isComment, setIsCommentDisabled] = useState (false);
  const [allBackendIds, setAllBackendIds] = useState ([]);
  const [newMessages, setNewMessages] = useState ([]);
  const [currentActiveKey, setCurrentActiveKey] = useState ('1');
  const [comments, setComments] = useState (0);
  const [replies, setReplies] = useState (0);
  const [notifyCount, setNotifyCount] = useState (0);
  const [notesAndReferenceModal, setNotesAndReferenceModal] = useState (false);
  const [notesAndReferenceLoading, setNotesAndReferenceLoading] = useState (
    false
  );
  const [notesAndReferenceFiles, setNotesAndReferenceFiles] = useState ([]);
  const [reviewNote, setReviewNote] = useState ('');
  const [reviewNoteModal, setReviewNoteModal] = useState (false);
  const [confirmLoadingReview, setConfirmLoadingReview] = useState (false);
  const [statusChangeModal, setStatusChangeModal] = useState (false);
  const [changeStatusLoading, setChangeStatusLoading] = useState (false);
  const [updatedStatus, setUpdatedStatus] = useState ("");
  const [isProgress,setIsProgress] = useState(false);
  const [confirmProgressUpload,setConfirmProgressUpload] = useState(false);
  const [progressPercentage,setProgressPercentage] = useState(0);
  const [currentOrderKey,setCurrentOrderKey] = useState(0);
  const [currentOutsourceOrder,setCurrentOutsourceOrder] = useState(null);
  const [isOutsourceLoading,setIsOutsourceLoading] = useState(false);
  const [proposalText,setProposalText] = useState("");
  const [isCommenting,setIsCommenting] = useState(false);
  const [userOutsourceComment,setUserOutsourceComment] = useState("");
  const [userCommentReplies,setUserCommentReplies] = useState([]);
  const [allUsersOutsourceOrderComment,setAllUsersOutsourceComment] = useState([]);
  const [currentCommentReplies,setCurrentCommentReplies] = useState([]);
  const location = useLocation();
  // console.log(location);
  const {state} = location;
  const orderIds = state && state.orderIds ? state.orderIds : null;
  const history = createBrowserHistory ();
  const currentDate = new Date ();
  const scrollRef = useRef();
  const [currentReplyId,setCurrentReplyId] = useState(null);
  const [currentReplyText,setCurrentReplyText] = useState("");
  const [isReplying,setIsReplying] = useState(false);
  // const socket = useRef ();

  const {user: currentUser} = useSelector (state => state.auth);

  useEffect(() => {
   if(currentOutsourceOrder && currentOutsourceOrder.allReviews){
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
   }
  }, [currentOutsourceOrder]);

  useEffect(()=>{
    let isMounted = true;
    const getUserOutsourceComment  = async ()=>{
      try{
        const response = await OrderService.getOutsourceOrderCommentForUser({userId:currentUser.id,orderId:orderDetails._id})
        console.log(response.data);
        if(response.data){
          setUserOutsourceComment(response.data);
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
      }
    }

    if(isMounted && !currentUser.roles.includes('ROLE_ADMIN')){
      getUserOutsourceComment();
    }

    return ()=>{
      isMounted = false;
    }

  },[currentUser,orderDetails]);

  useEffect(()=>{
    let isMounted = true;
    const getUserOutsourceComment  = async ()=>{
      try{
        const response = await OrderService.getOutsourceOrderComment({orderId:orderDetails._id})
        console.log(response.data);
        if(response.data){
          setAllUsersOutsourceComment(response.data);
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
      }
    }

    if(isMounted && currentUser.roles.includes('ROLE_ADMIN')){
      getUserOutsourceComment();
    }

    return ()=>{
      isMounted = false;
    }

  },[currentUser,orderDetails]);

  useEffect(()=>{
    let isMounted = true;
    
      const getOutsourceCommentReply = async ()=>{
        if(userOutsourceComment){
        const response = await OrderService.getOutsourceOrderCommentForUser({parentId:userOutsourceComment._id,userId:currentUser.id});
        console.log(response.data);
        if(response.data){
          setUserCommentReplies(response.data);
        }
      }
      }

      if(isMounted && !currentUser.roles.includes('ROLE_ADMIN')){
        getOutsourceCommentReply();
      }

      return ()=>{
        isMounted = false;
      }

  },[userOutsourceComment,currentUser])
  // const updateMessages = (data)=>{
  //     // console.log("GOT MESSAGE HERE : ",data);
  //    if(data.error && data.senderId===currentUser.id){
  //       return;
  //    }else{
  //        console.log(currentActiveKey);
  //        if(currentActiveKey=="1"){
  //         setNewMessages(prev=>[...prev,data]);
  //        }

  //    }
  //  }

  // useEffect(()=>{
  //     socket.current = io(socketConnection);
  //     socket.current.on("getNewCommentInfo", (data) => {
  //         updateMessages(data)

  //       });

  //  },[])

  // useEffect(() => {
  //     socket.current.emit("addCommentUser", currentUser.id);

  //   }, [currentUser]);

  const getUserOutsourceComment  = async ()=>{
    try{
      const response = await OrderService.getOutsourceOrderCommentForUser({userId:currentUser.id,orderId:orderDetails._id})
      console.log(response.data);
      if(response.data){
        setUserOutsourceComment(response.data);
      }
    }catch(err){
      console.log(err);
      message.error(err.message ? err.message : 'something went wrong!');
    }
  }


  const getOutsourceOrder = async ()=>{
    try{
      const response = await OrderService.getOutsourceOrderForAdmin({orderId:match.params.slug})
      console.log(response.data);
      if(response.data){
        setCurrentOutsourceOrder(response.data);
      }
    }catch(err){
      console.log(err);
      message.error (
        err.message ? err.message : 'Something went wrong!'
      );
    }
  }

  
  useEffect(()=>{
    let isMounted = true;
    const getOutsourceOrder = async ()=>{
      try{
        const response = await OrderService.getOutsourceOrderForAdmin({orderId:match.params.slug})
        console.log(response.data);
        if(response.data){
          setCurrentOutsourceOrder(response.data);
        }
      }catch(err){
        console.log(err);
        message.error (
          err.message ? err.message : 'Something went wrong!'
        );
      }
    }

    if(isMounted){
      getOutsourceOrder();
    }

    return ()=>{
      isMounted = false;
    }
  },[match.params.slug])

  const getOrderDetails = async id => {
    try {
      const response = await OrderService.getSingleOrder (id);
    //   console.log ('ORDER : ', response.data);
      let deliveredDate =
        response.data.deliveredOn && new Date (response.data.deliveredOn);
      let differenceInDays = null;
      if (deliveredDate) {
        let differenceInTime =
          currentDate.getTime () - deliveredDate.getTime ();
        differenceInDays = Number (differenceInTime) / (1000 * 3600 * 24);
        // console.log("Difference in Days : ",differenceInDays);
        setDifferenceOfDays (differenceInDays);
      }
      if (response.data) {
        setOrderDetails (response.data);
        setUpdatedStatus(response.data?.status)
      }
      setIsCommentValue (response.data, differenceInDays);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to fetch order details!'
      );
    }
  };

  function setIsCommentValue (data, difference) {
    let isCommentDisabled = false;
    // console.log("Status : ",data.status);
    if (data.status && data.status.toLowerCase () === 'delivered') {
      if (difference && difference > 0.0001) {
        isCommentDisabled = true;
        if (
          data.isCommentDisabled !== undefined &&
          data.isCommentDisabled === false
        ) {
          isCommentDisabled = false;
        } else if (
          data.isCommentDisabled !== undefined &&
          data.isCommentDisabled === true
        ) {
          isCommentDisabled = true;
        }
      } else if (
        data.isCommentDisabled !== undefined ||
        data.isCommentDisabled !== null
      ) {
        isCommentDisabled = data.isCommentDisabled;
      }
    } else if (
      data.isCommentDisabled !== undefined ||
      data.isCommentDisabled !== null
    ) {
      isCommentDisabled = data.isCommentDisabled;
    }

    // console.log("is Comment : ",isCommentDisabled);
    // console.log("Order comment : ",data.isCommentDisabled);
    setIsCommentDisabled (isCommentDisabled);
  }

  const getAllDocs = async id => {
    // console.log("ID : ",id);
    try {
      const response = await OrderService.getSingleDocForUser (
        currentUser.id,
        id
      );

      if (response.data.length > 0) {
        setAllDocs (response.data);
      }

      // console.log("Found Docs : ",response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to fetch Docs!');
    }
  };

  const getAllAdminAndSalesUser = async () => {
    try {
      const response = await UserService.getAdminAndSalesUsers ();
      // console.log(response.data);
      setAllBackendIds (response.data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  useEffect (() => {
    getAllAdminAndSalesUser ();
    getOrderDetails (match.params.slug);
    getAllDocs (match.params.slug);
  }, []);


  useEffect(()=>{
    if(orderIds && orderDetails){
      // console.log(location)
     
      // console.log(orderIds);
      const orderIndex = orderIds.findIndex((item)=>{
        return item===orderDetails._id
      });
      
      console.log(orderIndex);
      setCurrentOrderKey(orderIndex+1)

    }
  },[orderIds,orderDetails])


  const getAllReplies = async orderId => {
    try {
      const response = await OrderService.getSpecificOrderReply (orderId);
      setReplies (response.data.length);
    } catch (err) {
      console.log (err);
    }
  };

  const getAllComments = async orderId => {
    try {
      const response = await OrderService.getSpecificOrderComment (orderId);
      // console.log(response.data);
      // return response.data.length
      setComments (response.data.length);
    } catch (err) {
      console.log (err);
      // message.error(err.message ? err.message : 'Unable to load comments!');
    }
  };

  useEffect (
    () => {
      // console.log("Order Details : ",orderDetails)
      if (orderDetails && currentUser) {
        if (currentUser.roles[0] === 'ROLE_USER') {
          getAllReplies (orderDetails._id);
        } else {
          getAllComments (orderDetails._id);
        }
      }
    },
    [orderDetails, currentUser]
  );

  useEffect (
    () => {
      if (comments !== 0) {
        // console.log("Comments Count: ",comments);
        // console.log("Order Details comment Count : ",orderDetails.commentCount);
        if (orderDetails.commentCount) {
          if (comments !== orderDetails.commentCount) {
            setNotifyCount (comments - orderDetails.commentCount);
          }
        } else {
          setNotifyCount (comments);
        }
      }
    },
    [comments]
  );

  useEffect (
    () => {
      if (replies !== 0) {
        // console.log("Replies Count: ",replies);
        // console.log("Order Details reply Count : ",orderDetails.replyCount);
        if (orderDetails.replyCount) {
          if (replies !== orderDetails.replyCount) {
            setNotifyCount (replies - orderDetails.replyCount);
          }
        } else {
          setNotifyCount (replies);
        }
      }
    },
    [replies]
  );

  useEffect (
    () => {
      const date = new Date (orderDetails.createdAt);
      let dd = date.getDate ();
      let mm = date.getMonth () + 1;
      let yyyy = date.getFullYear ();
      let hh = date.getHours ();
      let minutes = date.getMinutes ();
      let ss = date.getSeconds ();
      let finalDate =
        dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
      setCreatedAt (finalDate);
      let refundValue = orderDetails.refundStatus &&
        orderDetails.refundStatus !== 'none'
        ? 2
        : 1;
      setRefundValueOption (refundValue);
    },
    [orderDetails]
  );

  const handleUploadRequirement = async () => {
    setConfirmRequirementLoading (true);
    // setTimeout(()=>{
    //     console.log(requirementFiles);
    //     setConfirmRequirementLoading(false);
    //     handleRequirementCancel();
    // },3000)

    var data = new FormData ();
    data.append ('order', orderDetails._id);
    data.append ('category', 'requirements');

    let selectedFiles = requirementFiles;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append ('multiples', selectedFiles[i], selectedFiles[i].name);
      }
    }

    // console.log(data);
    try {
      const response = await OrderService.postMultipleDoc (data);
      // console.log(response);
      if (response.data) {
        setConfirmRequirementLoading (false);
        handleRequirementCancel ();
        setRequirementFiles ('');

        message.success ('Requirement Files Uploaded Successfully!');
      }
    } catch (err) {
      console.log (err);
      setConfirmRequirementLoading (false);
      handleRequirementCancel ();
      message.error (
        err.message ? err.message : 'Unable to upload order requirement files!!'
      );
    }
  };

  const showRequirementModal = () => {
    setRequirementVisible (true);
  };

  const showNotesAndReferenceModal = () => {
    setNotesAndReferenceModal (true);
  };
  const handleRequirementCancel = () => {
    // console.log('Clicked cancel button');
    setRequirementVisible (false);
  };

  const handleRequirementChange = e => {
    // console.log(e.target.files[0]);
    setRequirementFiles (e.target.files);
  };

  const handleNotesAndReferenceFilesChange = e => {
    // console.log(e.target.files[0]);
    setNotesAndReferenceFiles (e.target.files);
  };

  const showDeliverableModal = () => {
    setDeliverableModal (true);
  };

  const handleDeliverableUpload = async () => {
    var data = new FormData ();
    data.append ('order', orderDetails._id);
    data.append ('category', 'deliverables');
    setConfirmDeliverableLoading (true);
    let selectedFiles = deliverableFiles;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append ('multiples', selectedFiles[i], selectedFiles[i].name);
      }
    }

    try {
      const response = await OrderService.postMultipleDoc (data);
      // console.log(response);
      if (response.data) {
        setConfirmDeliverableLoading (false);
        handleDeliverableCancel ();
        setDeliverableFiles ('');

        message.success ('Deliverable Files Uploaded Successfully!');
      }
    } catch (err) {
      console.log (err);
      setConfirmDeliverableLoading (false);
      handleDeliverableCancel ();
      message.error (
        err.message ? err.message : 'Unable to upload deliverable files!!'
      );
    }
  };

  const handleNotesAndReferenceCancel = () => {
    setNotesAndReferenceModal (false);
  };

  const handleNotesAndReferenceSubmit = async () => {
    var data = new FormData ();
    data.append ('order', orderDetails._id);
    data.append ('category', 'notesAndReference');

    let selectedFiles = notesAndReferenceFiles;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append ('multiples', selectedFiles[i], selectedFiles[i].name);
      }
    }

    setNotesAndReferenceLoading (true);

    try {
      const response = await OrderService.postMultipleDoc (data);
      // console.log(response);
      if (response.data) {
        setNotesAndReferenceLoading (false);
        handleNotesAndReferenceCancel ();
        setNotesAndReferenceFiles ('');

        message.success ('Notes and Reference Files Uploaded Successfully!');
      }
    } catch (err) {
      console.log (err);
      setNotesAndReferenceLoading (false);
      handleNotesAndReferenceCancel ();
      message.error (
        err.message
          ? err.message
          : 'Unable to upload notes and reference files!!'
      );
    }
  };

  const handleDeliverableCancel = () => {
    setDeliverableModal (false);
  };

  const handleDeliverableChange = e => {
    setDeliverableFiles (e.target.files);
  };

  const showSourceCodeModal = () => {
    setSourceCodeModal (true);
  };

  const handleCancelSourceCodeModal = () => {
    setSourceCodeModal (false);
  };

  const handleSourceCodeFileChange = e => {
    setSourceCodeFile (e.target.files);
  };

  const sendStatusChangedMail = async mail => {
    // console.log("Mail : ",order.userMail);
    if (
      mail &&
      orderDetails.paidAmount &&
      orderDetails.paidAmount == orderDetails.totalAmount
    ) {
      try {
        const data = {
          To: mail,
          Subject: 'Order Status Changed',
          Write: `Dear User, <br /> Your Request of <b>${orderDetails.name}</b> is Now in <b>${orderDetails.status}</b> Phase. <br /> We Will be Keep Notifying you about the Updates on your order. <br /> Stay Connected With us. <br /> <br />Thank You for Choosing <b>Codersarts!</b>`,
          attachment: false,
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${mail}`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
    }
  };

  const statusChangeHandler = () => {
    if (orderDetails.status.toLowerCase () !== 'delivered') {
      OrderService.updateStatus (orderDetails._id, 'Delivered')
        .then (response => {
          // console.log(response);
          message.success (`Status Changed to Delivered Successfully!`);
        })
        .catch (err => {
          // console.log(err);
          message.error (err.message ? err.message : "Couldn't Change Status!");
        });

      // getOrderDetails();

      sendStatusChangedMail (
        orderDetails && orderDetails.customer
          ? orderDetails.customer[0] ? orderDetails.customer[0].email : null
          : orderDetails.createdBy && orderDetails.createdBy[0]
              ? orderDetails.createdBy[0].email
              : null
      );
      sendStatusChangedMail (
        orderDetails &&
          orderDetails.assignedEmployee &&
          orderDetails.assignedEmployee[0]
          ? orderDetails.assignedEmployee[0].email
          : null
      );
      sendStatusChangedMail (
        orderDetails && orderDetails.assignedTo && orderDetails.assignedTo[0]
          ? orderDetails.assignedTo[0].email
          : null
      );
    }
  };

  const updateOrderProgress = async () => {
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        progress: 100,
      });
      // console.log(response);
      message.success ('Successfully updated the progress!');
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to update progress!');
    }
  };

  const handleSourceCodeUpload = async () => {
    setConfirmSourceUpload (true);

    var data = new FormData ();
    data.append ('order', orderDetails._id);
    data.append ('category', 'source code');

    let selectedFiles = sourceCodeFile;

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append ('multiples', selectedFiles[i], selectedFiles[i].name);
      }
    }

    try {
      const response = await OrderService.postMultipleDoc (data);
      // console.log(response);
      if (response.data) {
        setConfirmSourceUpload (false);
        handleCancelSourceCodeModal ();
        setSourceCodeFile ('');
        message.success ('Source Code Files Uploaded Successfully!');
        statusChangeHandler ();
        updateOrderProgress ();
      }
    } catch (err) {
      console.log (err);
      setConfirmSourceUpload (false);
      handleCancelSourceCodeModal ();
      message.error (
        err.message ? err.message : 'Unable to upload Source Code files!!'
      );
    }
  };

  const handleRefundStatusModal = async () => {
    setConfirmRefundStatusModal (true);
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        refundStatus: refundStatus,
      });
      // console.log(response);
      message.success ('Added Refund Status !');
      setRefundStatus ('');
      setRefundValueOption (2);
      setConfirmRefundStatusModal (false);
      setRefundStatusModal (false);
      getOrderDetails (match.params.slug);
    } catch (err) {
      console.log (err);
      setConfirmRefundStatusModal (false);
      setRefundStatusModal (false);
      message.error (err.message ? err.message : 'Unable to Add Refund Status');
    }
  };

  const updateRefundStatusToNone = async () => {
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        refundStatus: 'none',
      });
      // console.log(response);
      message.success ('Changed Refund Status to None!');
      setRefundStatus ('');
      setRefundValueOption (1);
      getOrderDetails (match.params.slug);
    } catch (err) {
      console.log (err);
      message.error (
        err.message ? err.message : 'Unable to Change Refund Status'
      );
    }
  };

  const handleRefundStatusChange = e => {
    if (e.target.value === 2) {
      setRefundStatusModal (true);
    } else if (e.target.value === 1) {
      updateRefundStatusToNone ();
    }
  };

  const updateRepliesInOrder = async () => {
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        replyCount: replies,
      });
    } catch (err) {
      console.log (err);
    }
  };

  const updateCommentsInOrder = async () => {
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        commentCount: comments,
      });
    } catch (err) {
      console.log (err);
    }
  };

  const handleTabChange = key => {
    // console.log("Key : ",key);
    setCurrentActiveKey (key);
    if (key == '2') {
      setNewMessages ([]);
      setNotifyCount (0);
      if (currentUser.roles[0] === 'ROLE_USER') {
        updateRepliesInOrder ();
      } else {
        updateCommentsInOrder ();
      }
    }
  };

  const handleDocumentDelete = async id => {
    try {
      const response = await OrderService.deleteDocById (id);
      if (response) {
        message.success ('Deleted file successfully!');
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : 'Unable to delete!');
    }
  };

  const handleReviewNoteModal = () => {
    setReviewNoteModal (true);
  };

  const handleSubmitReview = async () => {
    // console.log("review note : ",reviewNote);
    setConfirmLoadingReview (true);
    try {
      const response = await OrderService.updateOrder ({
        _id: orderDetails._id,
        reviewNote: reviewNote,
      });
      console.log (response.data);
      if (response) {
        setConfirmLoadingReview (false);
        setReviewNoteModal (false);
        message.success ('Added a review note successfully!');
      }
    } catch (err) {
      console.log (err);
      setConfirmLoadingReview (false);
      message.error (err.message ? err.message : 'Something went wrong!');
    }
  };

  const cancelReviewNoteModal = () => {
    setReviewNoteModal (false);
  };

  const handleStatusChangeModal = () => {
    setStatusChangeModal (true);
  };


  const sendStatusUpdateMail = async (mail,status)=>{
    try {
        const data = {
          To: mail,
          Subject: 'Order Status Changed',
          Write: `Dear User, <br /> Your Request of <b>${orderDetails.name}</b> is Now in <b>${status}</b> Phase. <br /> We Will be Keep Notifying you about the Updates on your order. <br /> Stay Connected With us. <br /> <br />Thank You for Choosing <b>Codersarts!</b>`,
          attachment: false,
        };
        const response = await GoogleServices.sendmail (data);
        // console.log(response);
        if (response) {
          message.success (`Successfully Sent the Mail to ${mail}`);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'Mail Not Sent!');
      }
  }
  

  const handleStatusChangeSubmit = () => {
    // console.log (updatedStatus);
    setChangeStatusLoading(true);
    OrderService.updateStatus (orderDetails._id, updatedStatus)
    .then (response => {
    //   console.log(response);
      if(response){
        getOrderDetails (match.params.slug);
        setChangeStatusLoading(false);
        setStatusChangeModal(false);
        message.success (`Status Changed to ${updatedStatus} Successfully!`);
        sendStatusUpdateMail (
            orderDetails && orderDetails.customer
              ? orderDetails.customer[0] ? orderDetails.customer[0].email : null
              : orderDetails.createdBy && orderDetails.createdBy[0]
                  ? orderDetails.createdBy[0].email
                  : null,updatedStatus
          );
          sendStatusUpdateMail (
            orderDetails &&
              orderDetails.assignedEmployee &&
              orderDetails.assignedEmployee[0]
              ? orderDetails.assignedEmployee[0].email
              : null
              ,updatedStatus
          );
          sendStatusUpdateMail (
            orderDetails && orderDetails.assignedTo && orderDetails.assignedTo[0]
              ? orderDetails.assignedTo[0].email
              : null
              ,updatedStatus
          );
         
      }
      
    })
    .catch (err => {
      console.log(err);
      setChangeStatusLoading(false);
      message.error (err.message ? err.message : "Couldn't Change Status!");
    });

  };

  const handleProgressSubmit = async ()=>{
    setConfirmProgressUpload(true);
    // console.log(progressPercentage);
    // console.log(currentOrderId);

    try{
        const response = await OrderService.updateOrder({_id:orderDetails._id,progress:progressPercentage})
        // console.log(response);
        setConfirmProgressUpload(false);
        setIsProgress(false);
        setProgressPercentage(0);
        getOrderDetails (match.params.slug);
        message.success("Successfully updated the progress!");
    }catch(err){
        console.log(err);
        setConfirmProgressUpload(false);
        setIsProgress(false);
        setProgressPercentage(0);
        message.error(err.message ? err.message : 'Unable to update progress!');
    }

  }

  const handleProgressClose = ()=>{
    setIsProgress(false);
  }

  const handleProgressClick = ()=>{
    setProgressPercentage(orderDetails.progress);
    setIsProgress(true);
  }
 

  const goTo = (page)=>{
    console.log(page)
    let index = parseInt(page) - 1;
    console.log("orderIds : ",orderIds);
    let goToOrder = orderIds[index];
    console.log("go to order : ",goToOrder);
   if(goToOrder){
    history.push({
      pathname: `/profile/view-order-details/${goToOrder}`,
      state: {orderIds: orderIds},
    })
   
    document.location.reload()
   }

  }

  const handleOutsourceOrder = async ()=>{
    setIsOutsourceLoading(true);
      if(currentOutsourceOrder){
        message.info("Order is already outsourced!");
        setIsOutsourceLoading(false);
      }else{
        try{
          const response = await OrderService.createOutsourceOrder({orderId:orderDetails._id,technologies:orderDetails.technologies,allReviews:[]})
          if(response.data){
            message.success("Order Outsourced Successfully!");
            getOutsourceOrder();
            setIsOutsourceLoading(false);
          }
        }catch(err){
          console.log(err);
          setIsOutsourceLoading(false);
          message.error(err.message ? err.message : 'something went wrong!');
        }
      }
  }


  const handleProposalSubmit = async ()=>{
    setIsCommenting(true);
    let data = {
      sender:currentUser.id,
      to:'admin',
      orderId:orderDetails._id,
      comment:proposalText,
      parentId:null
    }

    
    // console.log("proposal text: ",allReviews);
    try{
      const response = await OrderService.createOutsourceOrderComment(data);
      console.log(response.data);
      if(response.data){
        setProposalText("");
        getUserOutsourceComment();
        setIsCommenting(false);
      }
    }catch(err){
      console.log(err);
      setIsCommenting(false);
      message.error(err.message ? err.message : 'something went wrong!');
    }

  }


  const handleCurrentReply = (comment)=>{
    setCurrentReplyId(comment);
  }


  const handleViewReplies = async (comment)=>{
    if(currentCommentReplies.length > 0 && currentCommentReplies[0].parentId===comment._id){
      setCurrentCommentReplies([]);
    }else{
      try{
        const response = await OrderService.getOutsourceOrderCommentReply({parentId:comment._id});
        console.log(response.data);
         setCurrentCommentReplies(response.data);

      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : 'something went wrong!');
      }
      // const result = allUsersOutsourceOrderComment.filter((item)=>{
      //   return item.parentId === comment._id;
      // })
      // console.log(result);
      // setCurrentCommentReplies(result);
    }
    // console.log(comment);
    // try{
    //   const response = await OrderService.getOutsourceOrderCommentReply({parentId:comment._id});
    //   console.log(response);
    //   setCurrentCommentReplies(response.data);
    // }catch(err){
    //   console.log(err);
    //   message.error(err.message ? err.message : 'something went wrong!');
    // }
  
  }


  const handleReplySend = async ()=>{
    setIsReplying(true);
    let data = {
      orderId:orderDetails._id,
      sender:currentUser.id,
      to:currentReplyId.sender._id,
      comment:currentReplyText,
      parentId:currentReplyId._id
    }
    console.log(data);
    try{
      const response = await OrderService.createOutsourceOrderComment(data);
      const response2 = await OrderService.getOutsourceOrderCommentReply({parentId:currentReplyId._id});
      // console.log(response2.data);
       setCurrentCommentReplies(response2.data);
      // console.log(response.data);
      if(response.data){
        setCurrentReplyId(null);
        setCurrentReplyText("");
        setIsReplying(false);
      }
    }catch(err){
      console.log(err);
      setIsReplying(false);
      message.error(err.message ? err.message : 'something went wrong!');
    }
  }
  return (
    <div className="mainViewOrderDetails">
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {currentUser.roles[0] === 'ROLE_ADMIN'
          ? <Breadcrumb>
              <Breadcrumb.Item>
                {' '}<Link to="/profile/view-orders"> All Orders</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {' '}
                <Link to={`/profile/view-order-details/${match.params.slug}`}>
                  Order Details
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          : currentUser.roles[0] === 'ROLE_EMPLOYEE'
              ? <Breadcrumb>
                  <Breadcrumb.Item>
                    {' '}<Link to="/profile/assignment">Assignments</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {' '}
                    <Link
                      to={`/profile/view-order-details/${match.params.slug}`}
                    >
                      Order Details
                    </Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              : currentUser.roles[0] === 'ROLE_USER'
                  ? <Breadcrumb>
                      <Breadcrumb.Item>
                        {' '}<Link to="/profile/user-orders">My Orders</Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        {' '}
                        <Link
                          to={`/profile/view-order-details/${match.params.slug}`}
                        >
                          Order Details
                        </Link>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  : currentUser.roles[0] === 'ROLE_SALES'
                      ? <Breadcrumb>
                          <Breadcrumb.Item>
                            {' '}
                            <Link to="/profile/orders-for-sales-role">
                              My Orders
                            </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                            {' '}
                            <Link
                              to={`/profile/view-order-details/${match.params.slug}`}
                            >
                              Order Details
                            </Link>
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      : <Breadcrumb>
                          <Breadcrumb.Item>
                            {' '}
                            <Link to="/profile/assigned-orders">
                              Assigned Orders
                            </Link>
                          </Breadcrumb.Item>
                          <Breadcrumb.Item>
                            {' '}
                            <Link
                              to={`/profile/view-order-details/${match.params.slug}`}
                            >
                              Order Details
                            </Link>
                          </Breadcrumb.Item>
                        </Breadcrumb>}
        {orderDetails &&
          ((orderDetails.assignedTo &&
            orderDetails.assignedTo[0] &&
            orderDetails.assignedTo[0].username &&
            orderDetails.assignedTo[0].username === currentUser.username) ||
            (orderDetails.assignedEmployee &&
              orderDetails.assignedEmployee[0] &&
              orderDetails.assignedEmployee[0].username ===
                currentUser.username))
          ? <Button type="primary" onClick={handleReviewNoteModal}>
              Add Review Notes
            </Button>
          : null}
      </div>
      <br />

      <Tabs activeKey={currentActiveKey} onChange={handleTabChange} type="card">
        <TabPane
          tab={
            <span
              onClick={() => {
                setCurrentActiveKey ('1');
              }}
            >
              <ShoppingCartOutlined />
              Order Details
            </span>
          }
          key="1"
        >
      <>
      <div className="orderDetails__heading__text">
            <h4>Order Details</h4>
            <div className="mr-5">
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={handleStatusChangeModal}>
                      Change Status
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleProgressClick}>Add Progress</Menu.Item>
                  </Menu>
                }
              >
                <Button type={'primary'}>Action</Button>
              </Dropdown>

              {currentUser.roles[0] === 'ROLE_SALES' || currentUser.roles[0] === 'ROLE_ADMIN' ?
            <Button loading={isOutsourceLoading} style={{marginLeft:'10px'}} onClick={handleOutsourceOrder}>Outsource Order</Button>
            :null
             }

            </div>
           
          </div>

          <div className="viewOrderDetails">

            <div className="details-header">
              <div className="title-section">
                <h4>{orderDetails.name && orderDetails.name}</h4>
              </div>

              <div className="progress-section">
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow="75"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{
                      width: orderDetails.progress
                        ? `${orderDetails.progress}%`
                        : '0',
                    }}
                  >
                    <h5>
                      {orderDetails.progress ? orderDetails.progress : 0}%
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <h3
                  className={`${orderDetails.progress && orderDetails.progress == 100 ? 'green' : 'red'}`}
                >
                  {orderDetails.progress && orderDetails.progress == 100
                    ? 'Completed'
                    : 'In-Progress'}
                </h3>
              </div>
            </div>

            <div className="details-subsection">

              <div className="subsection-item">
                <div>
                  <span><b>Deadline</b></span>
                  <span>
                    {orderDetails.deadline && orderDetails.deadline}
                    {' '}
                    {orderDetails.timezone ? orderDetails.timezone : null}
                  </span>
                </div>
                <div>
                  <span><b>Category</b></span>
                  <span>{orderDetails.category && orderDetails.category}</span>
                </div>
                <div>
                  <span><b>Order</b></span>
                  <span>#{orderDetails._id}</span>
                </div>
              </div>

              <div className="subsection-item">
                <div>
                  <span><b>Status</b></span>
                  <span className="badge">
                    {orderDetails.status ? orderDetails.status : '---'}
                  </span>
                </div>
                <div>
                  <span><b>Assigned To</b></span>
                  <span className="badge badge-info">
                    {orderDetails &&
                      orderDetails.assignedTo &&
                      orderDetails.assignedTo[0] &&
                      orderDetails.assignedTo[0].username
                      ? <Link
                          to={`/profile/manage-users/view/${orderDetails.assignedTo[0]._id}`}
                          style={{color: 'white'}}
                        >
                          <b>{orderDetails.assignedTo[0].username}</b>{' '}
                        </Link>
                      : null}

                  </span>
                </div>
                <div>
                  <span><b>Customer</b></span>
                  <span className="badge badge-info">
                    {orderDetails &&
                      orderDetails.customer &&
                      orderDetails.customer[0]
                      ? <Link
                          to={`/profile/manage-users/view/${orderDetails.customer[0]._id}`}
                          style={{color: 'white'}}
                        >
                          <b>{orderDetails.customer[0].username}</b>{' '}
                        </Link>
                      : null}
                  </span>

                </div>
              </div>

              {currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
                currentUser.roles[0] === 'ROLE_MANAGER'
                ? null
                : <div className="subsection-item">
                    <div>
                      <span><b>Total Amount</b></span>
                      <span>{orderDetails.totalAmount}</span>
                    </div>
                    <div>
                      <span><b>Paid Amount</b></span>
                      <span>
                        {orderDetails.paidAmount == 'NaN'
                          ? 0
                          : orderDetails.paidAmount}
                      </span>
                    </div>

                    <div>
                      <span><b>Remaining Amount</b></span>
                      <span>
                        {orderDetails.paidAmount == 'NaN'
                          ? orderDetails.totalAmount
                          : Number (orderDetails.totalAmount) -
                              Number (orderDetails.paidAmount)}
                      </span>
                    </div>

                  </div>}

              <div className="subsection-item">

                <div>
                  <span><b>Currency</b></span>
                  <span>
                    {orderDetails.currency ? orderDetails.currency : null}
                  </span>
                </div>

                <div>
                  <span><b>Created At</b></span>
                  <span>{createdAt}</span>
                </div>

                {currentUser.roles[0] === 'ROLE_USER'
                  ? <div>
                      <span><b>Employee</b></span>
                      {/* <span className="badge badge-info">{orderDetails.assignedEmployee && orderDetails.assignedEmployee[0] ? orderDetails.assignedEmployee[0].username : null}</span> */}
                      <span className="badge badge-info">
                        {orderDetails &&
                          orderDetails.assignedEmployee &&
                          orderDetails.assignedEmployee[0]
                          ? <Link
                              to={`/profile/manage-users/view/${orderDetails.assignedEmployee[0]._id}`}
                              style={{color: 'white'}}
                            >
                              <b>{orderDetails.assignedEmployee[0].username}</b>
                            </Link>
                          : null}
                      </span>
                    </div>
                  : <div>
                      <span><b>Created By</b></span>
                      <span>
                        <span className="badge badge-info">
                          {orderDetails.createdBy && orderDetails.createdBy[0]
                            ? orderDetails.createdBy[0].username
                            : null}
                        </span>
                      </span>
                    </div>}

              </div>

              <div className="subsection-item">

                {currentUser.roles[0] === 'ROLE_USER'
                  ? null
                  : <div>
                      <span><b>Employee</b></span>
                      <span className="badge badge-info">
                        {orderDetails &&
                          orderDetails.assignedEmployee &&
                          orderDetails.assignedEmployee[0]
                          ? <Link
                              to={`/profile/manage-users/view/${orderDetails.assignedEmployee[0]._id}`}
                              style={{color: 'white'}}
                            >
                              <b>{orderDetails.assignedEmployee[0].username}</b>
                            </Link>
                          : null}
                      </span>
                    </div>}

                <div>
                  <span><b>Documentation</b></span>
                  <span>
                    {orderDetails.documentation && orderDetails.documentation
                      ? 'Yes'
                      : 'No'}
                  </span>
                </div>

                <div>
                  <span><b>Booking Session</b></span>
                  <span>
                    {orderDetails.bookingSession && orderDetails.bookingSession
                      ? 'Yes'
                      : 'No'}
                  </span>
                </div>
                {currentUser.roles[0] === 'ROLE_USER' ? <div /> : null}

              </div>

              {currentUser.roles[0] === 'ROLE_USER'
                ? null
                : <div className="subsection-item">
                    <div style={{width: '50%'}}>
                      <span><b>Manager Note</b></span>
                      <span>
                        {orderDetails.managerNote && orderDetails.managerNote}
                      </span>
                    </div>

                    <div id="employeeReporting">
                      <span><b>Reporting Deadline</b></span>
                      <span>
                        {orderDetails.employeeReporting &&
                          orderDetails.employeeReporting}
                      </span>
                    </div>
                  </div>}

              {(orderDetails.assignedTo &&
                orderDetails.assignedTo[0] &&
                orderDetails.assignedTo[0].username === currentUser.username) ||
                (orderDetails.assignedBy &&
                  orderDetails.assignedBy[0] &&
                  orderDetails.assignedBy[0].username ===
                    currentUser.username) ||
                (orderDetails.assignedEmployee &&
                  orderDetails.assignedEmployee[0] &&
                  orderDetails.assignedEmployee[0].username ===
                    currentUser.username) ||
                currentUser.roles[0] === 'ROLE_ADMIN'
                ? <div className="subsection-item">
                    <div style={{width: '100%'}}>
                      <span><b>Review Note</b></span>
                      <span>
                        {orderDetails.reviewNote && orderDetails.reviewNote}
                      </span>
                    </div>
                  </div>
                : null}

            </div>

            <div style={{marginLeft: '3%'}}>
              <div>
                <b>Technologies:</b>
              </div>
              <div>
                {orderDetails &&
                  orderDetails.technologies &&
                  orderDetails.technologies.map (item => {
                    return <span className="badge mx-2" key={item._id}>{item}</span>
                  })}
              </div>

            </div>
            <div style={{marginLeft: '3%', marginTop: '2%'}}>
              <div>
                <b>Order Type:</b>
              </div>
              <div>
                <b className="badge">
                  {orderDetails && orderDetails.type && orderDetails.type}
                </b>
              </div>

            </div>

            <div className="order-description">
              <p>
                <b>Description : </b>
                {orderDetails.details && orderDetails.details}
              </p>
              {orderDetails.status &&
                orderDetails.status.toLowerCase () === 'cancel order' &&
                <p>
                  <b>Cancellation Reason: </b>
                  <span id="cancellationReason">
                    {orderDetails.cancellationReason &&
                      orderDetails.cancellationReason}
                  </span>
                </p>}
            </div>

            {(orderDetails.status &&
              orderDetails.status.toLowerCase () !== 'cancel order') ||
              currentUser.roles[0] === 'ROLE_USER' ||
              currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
              currentUser.roles[0] === 'ROLE_MANAGER'
              ? null
              : <div className="refund">
                  <div><b>Refund Status : </b></div>
                  <Radio.Group
                    onChange={handleRefundStatusChange}
                    value={refundValueOption}
                  >
                    <Radio value={1}>No</Radio>
                    <Radio value={2}>Yes</Radio>
                  </Radio.Group>

                  {orderDetails.refundStatus &&
                    orderDetails.refundStatus !== 'none' &&
                    <p>
                      <b>Refund Status Details: </b>
                      <span id="refundStatus">
                        {orderDetails.refundStatus && orderDetails.refundStatus}
                        {' '}
                      </span>
                    </p>}

                </div>}

            <div className="orderRequirements">

              <h5>
                <a
                  className="collapse-link"
                  data-toggle="collapse"
                  href="#orderRequirements"
                  role="button"
                  aria-expanded="false"
                  aria-controls="orderRequirements"
                >
                  <span>Order Requirements</span>
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>

              </h5>
              <div className="collapse" id="orderRequirements">
                <div className="card card-body">

                  {currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
                    currentUser.roles[0] === 'ROLE_MANAGER'
                    ? null
                    : <div className="uploadArea">
                        <center>
                          <img src={uploadIcon} alt="upload" /> <br />
                          <Button
                            type={'primary'}
                            style={{width: '20%'}}
                            onClick={showRequirementModal}
                          >
                            <PlusOutlined />Upload Requirements
                          </Button>
                        </center>

                      </div>}

                  <ul>
                    {orderDetails.fileUrl &&
                      <li>
                        <h5>
                          <a
                            rel="noreferrer"
                            href={orderDetails.fileUrl}
                            target="_blank"
                          >
                            Initial Order Requirement
                          </a>
                          {' '}
                        </h5>
                      </li>}
                    {allDocs.map (d => {
                      if (d.category && d.category === 'requirements') {
                        return (
                          <li key={d._id}>
                            <h5>
                              <a
                                rel="noreferrer"
                                href={d.fileLink ? d.fileLink : '/'}
                                target="_blank"
                              >
                                {d.name}
                              </a>
                            </h5>
                            <span>
                              <Tooltip color={'red'} title="Delete?">
                                <DeleteOutlined
                                  className="deleteIcon"
                                  onClick={() => {
                                    handleDocumentDelete (d._id);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>

            </div>

            <div className="notesAndReferences">

              <h5>
                <a
                  className="collapse-link"
                  data-toggle="collapse"
                  href="#notesAndReferences"
                  role="button"
                  aria-expanded="false"
                  aria-controls="notesAndReferences"
                >
                  <span>Notes and References</span>
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>

              </h5>
              <div className="collapse" id="notesAndReferences">
                <div className="card card-body">

                  {currentUser.roles[0] === 'ROLE_EMPLOYEE' ||
                    currentUser.roles[0] === 'ROLE_MANAGER'
                    ? null
                    : <div className="uploadArea">
                        <center>
                          <img src={uploadIcon} alt="upload" /> <br />
                          <Button
                            type={'primary'}
                            style={{width: 'fit-content'}}
                            onClick={showNotesAndReferenceModal}
                          >
                            <PlusOutlined />Upload Notes and References
                          </Button>
                        </center>

                      </div>}

                  <ul>
                    {allDocs.map (d => {
                      if (d.category && d.category === 'notesAndReference') {
                        return (
                          <li key={d._id}>
                            <h5>
                              <a
                                rel="noreferrer"
                                href={d.fileLink ? d.fileLink : '/'}
                                target="_blank"
                              >
                                {d.name}
                              </a>
                            </h5>
                            <span>
                              <Tooltip color={'red'} title="Delete?">
                                <DeleteOutlined
                                  className="deleteIcon"
                                  onClick={() => {
                                    handleDocumentDelete (d._id);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>

            </div>

            <div className="deliverables">

              <h5>
                <a
                  className="collapse-link"
                  data-toggle="collapse"
                  href="#deliverables"
                  role="button"
                  aria-expanded="false"
                  aria-controls="deliverables"
                >
                  <span>Screenshots & Output</span>
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>

              </h5>
              <div className="collapse" id="deliverables">
                <div className="card card-body">

                  {currentUser.roles[0] === 'ROLE_USER'
                    ? null
                    : <div className="uploadArea">
                        <center>
                          <img src={uploadIcon} alt="upload" /> <br />
                          <Button
                            type={'primary'}
                            style={{width: '20%'}}
                            onClick={showDeliverableModal}
                          >
                            <PlusOutlined />Upload Deliverables
                          </Button>
                        </center>

                      </div>}

                  <ul>
                    {allDocs.map (d => {
                      if (d.category && d.category === 'deliverables') {
                        return (
                          <li key={d._id}>
                            <h5>
                              <a
                                rel="noreferrer"
                                href={d.fileLink ? d.fileLink : '/'}
                                target="_blank"
                              >
                                {d.name}
                              </a>
                            </h5>
                            <span>
                              <Tooltip color={'red'} title="Delete?">
                                <DeleteOutlined
                                  className="deleteIcon"
                                  onClick={() => {
                                    handleDocumentDelete (d._id);
                                  }}
                                />
                              </Tooltip>
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                </div>
              </div>

            </div>

            <div className="sourceCode">

              <h5>
                <a
                  className="collapse-link"
                  data-toggle="collapse"
                  href="#sourceCode"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sourceCode"
                >
                  <span>Source Code</span>
                  <i className="fa fa-caret-down" aria-hidden="true" />
                </a>

              </h5>
              <div className="collapse" id="sourceCode">
                <div className="card card-body">

                  {currentUser.roles[0] === 'ROLE_USER'
                    ? null
                    : <div className="uploadArea">
                        <center>
                          <img src={uploadIcon} alt="upload" /> <br />
                          <Button
                            type={'primary'}
                            style={{width: '20%'}}
                            onClick={showSourceCodeModal}
                          >
                            <PlusOutlined />Upload Source Code
                          </Button>
                        </center>

                      </div>}

                  {currentUser.roles[0] === 'ROLE_USER'
                    ? (orderDetails &&
                        orderDetails.totalAmount == orderDetails.paidAmount) ||
                        (orderDetails.status &&
                          orderDetails.status.toLowerCase () === 'delivered')
                        ? <ul>
                            {allDocs.map (d => {
                              if (d.category && d.category === 'source code') {
                                return (
                                  <li key={d._id}>
                                    <h5>
                                      <a
                                        rel="noreferrer"
                                        href={d.fileLink ? d.fileLink : '/'}
                                        target="_blank"
                                      >
                                        {d.name}
                                      </a>
                                    </h5>
                                    <span>
                                      <Tooltip color={'red'} title="Delete?">
                                        <DeleteOutlined
                                          className="deleteIcon"
                                          onClick={() => {
                                            handleDocumentDelete (d._id);
                                          }}
                                        />
                                      </Tooltip>
                                    </span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        : null
                    : <ul>
                        {allDocs.map (d => {
                          if (d.category && d.category === 'source code') {
                            return (
                              <li key={d._id}>
                                <h5>
                                  <a
                                    rel="noreferrer"
                                    href={d.fileLink ? d.fileLink : '/'}
                                    target="_blank"
                                  >
                                    {d.name}
                                  </a>
                                </h5>
                                <span>
                                  <Tooltip color={'red'} title="Delete?">
                                    <DeleteOutlined
                                      className="deleteIcon"
                                      onClick={() => {
                                        handleDocumentDelete (d._id);
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              </li>
                            );
                          }
                        })}
                      </ul>}

                </div>
              </div>

            </div>
           

          </div>
          
         {orderIds && currentOrderKey &&
         <div class="paginationAreaInOrderDetails">
           <button onClick={()=>{goTo(currentOrderKey-1)}} type="button" disabled={currentOrderKey===1}>Prev</button>
           <span>{currentOrderKey} / {orderIds.length}</span>
           <button onClick={()=>{goTo(currentOrderKey+1)}} type="button" disabled={currentOrderKey===orderIds.length}>Next</button>
         </div>

         }
      </>
        </TabPane>

        <TabPane
          tab={
            <span
              onClick={() => {
                setCurrentActiveKey ('2');
              }}
            >
              <MessageOutlined />
              Message
              <sup>
                <Tooltip
                  title={`You can send messages and queries for 
          ${orderDetails.name ? orderDetails.name : 'this order'} in this tab`}
                  placement="right"
                  color={'#006B82'}
                >
                  <QuestionCircleOutlined
                    style={{marginLeft: '5px', fontSize: '14px'}}
                  />
                </Tooltip>
              </sup>
              {newMessages.length > 0
                ? <span className="msgBadge">
                    {newMessages.length + notifyCount}
                  </span>
                : notifyCount > 0 &&
                    <span className="msgBadge">{notifyCount}</span>}

            </span>
          }
          disabled={isComment || allBackendIds.length === 0}
          key="2"
        >
          <h4>Message</h4>
          <CommentArea
            orderId={orderDetails._id}
            orderName={orderDetails.name}
            developerId={
              orderDetails.assignedEmployee && orderDetails.assignedEmployee[0]
                ? orderDetails.assignedEmployee[0]._id
                : null
            }
            managerId={
              orderDetails.assignedTo &&
                orderDetails.assignedTo[0] &&
                orderDetails.assignedTo[0]._id
                ? orderDetails.assignedTo[0]._id
                : null
            }
            backendIds={[
              ...allBackendIds,
              orderDetails.assignedEmployee && orderDetails.assignedEmployee[0]
                ? orderDetails.assignedEmployee[0]._id
                : null,
              orderDetails.assignedTo &&
                orderDetails.assignedTo[0] &&
                orderDetails.assignedTo[0].username
                ? orderDetails.assignedTo[0]._id
                : null,
            ]}
            customerId={
              orderDetails.customer && orderDetails.customer[0]
                ? orderDetails.customer[0]._id
                : orderDetails.createdBy && orderDetails.createdBy[0]
                    ? orderDetails.createdBy[0]._id
                    : null
            }
          />
        </TabPane>

        <TabPane
          disabled={!currentOutsourceOrder}
          tab={
            <span
              onClick={() => {
                setCurrentActiveKey ('2');
              }}
            >
              <ExceptionOutlined />
              Proposals
              <sup>
                <Tooltip
                  title={`You can See Developer reviews and Proposals for the order in this tab`}
                  placement="right"
                  color={'#006B82'}
                >
                  <QuestionCircleOutlined
                    style={{marginLeft: '5px', fontSize: '14px'}}
                  />
                </Tooltip>
              </sup>
              

            </span>
          }
          
          key="3"
        >
          <h4>Proposals</h4>
        <div className='proposals__wrapper'>
          <div style={{width:'70%'}}>
          {(!currentUser.roles.includes('ROLE_ADMIN') && !userOutsourceComment) ?
          (<div className="proposal__write">
          <textarea type="text" onChange={(e)=>setProposalText(e.target.value)} placeholder="Enter a Text for proposal"></textarea>
          <Button loading={isCommenting} type="primary" onClick={handleProposalSubmit}>Send Proposal</Button>
           </div>)
           :
          userOutsourceComment &&
          (<div className="outsourceCommentCard">
          <div className="proposal__card">
            <div className="proposal__card__imgWrapper">
            <img src={userOutsourceComment.sender.profilePicture ? userOutsourceComment.sender.profilePicture : avatarPic} alt="pic" />
            <b>{userOutsourceComment.sender.username}</b>
            </div>
            <div className="proposal__cardText">
            {userOutsourceComment.comment}
            </div>
            <div className="replyTextWrapper">
              <span onClick={()=>{handleViewReplies(userOutsourceComment)}}>{(currentCommentReplies.length > 0 
            && currentCommentReplies[0].parentId===userOutsourceComment._id) ? 'Close Replies' : 'View Replies'}</span>
              <span onClick={()=>handleCurrentReply(userOutsourceComment)}> Reply </span>
            </div>
           
            
            </div>
            {currentReplyId && currentReplyId._id === userOutsourceComment._id &&
                    <div className="reply__write__area">
                      <textarea placeholder="Reply here...." onChange={(e)=>setCurrentReplyText(e.target.value)}></textarea>
                      <div className="reply__write__btns">
                      <Button style={{margin:'10px'}} onClick={()=>setCurrentReplyId(null)}>Close</Button>
                      <Button style={{margin:'10px'}} loading={isReplying} type="primary" onClick={handleReplySend}>Reply</Button>
                      </div>
                    </div>
                   }
              {currentCommentReplies.length > 0 
              && currentCommentReplies[0].parentId===userOutsourceComment._id &&
              currentCommentReplies.map((reply)=>{
                return(
              
              <div className="reply__proposal__card" id={reply._id}>
              <div className="reply__proposal__card__imgWrapper">
              <img src={reply.sender.profilePicture ? reply.sender.profilePicture : avatarPic} alt="pic" />
              <b>{reply.sender.username}</b>
              </div>
              <div className="reply__proposal__cardBody">
                <div className="reply__proposal__cardText">
                {reply.comment}
                </div>
                <small>
                  {format(reply.createdAt)}
                </small>
              </div>
              
              </div>  
              
              
                
                )
              })
              }
          </div>)
            

          }

          {!currentUser.roles.includes('ROLE_ADMIN') &&
            userCommentReplies.map((item)=>{
              return(<div key={item._id}>
                {item.comment}
              </div>)
            })
          }
          {currentUser.roles.includes('ROLE_ADMIN') &&
            <div className="outsourceCommentCard">
              {allUsersOutsourceOrderComment.map((item)=>{
                return item.parentId===null ? 
               (<div className="proposal__card__Wrapper">
                  <div className="proposal__card">
                    <div className="proposal__card__imgWrapper">
                    <img src={item.sender.profilePicture ? item.sender.profilePicture : avatarPic} alt="pic" />
                    <b>{item.sender.username}</b>
                    </div>
                    <div className="proposal__cardText">
                    {item.comment}
                    </div>
                    </div>  
                    <div className="replyTextWrapper">
                     <span onClick={()=>{handleViewReplies(item)}}>{(currentCommentReplies.length > 0 
                   && currentCommentReplies[0].parentId===item._id) ? 'Close Replies' : 'View Replies'}</span>
                     <span onClick={()=>handleCurrentReply(item)}> Reply </span>
                    </div>
                   {currentReplyId && currentReplyId._id === item._id &&
                    <div className="reply__write__area">
                      <textarea placeholder="Reply here...." onChange={(e)=>setCurrentReplyText(e.target.value)}></textarea>
                      <div className="reply__write__btns">
                      <Button style={{margin:'10px'}} onClick={()=>setCurrentReplyId(null)}>Close</Button>
                      <Button style={{margin:'10px'}} loading={isReplying} type="primary" onClick={handleReplySend}>Reply</Button>
                      </div>
                    </div>
                   }

                   {currentCommentReplies.length > 0 
                   && currentCommentReplies[0].parentId===item._id &&
                   currentCommentReplies.map((reply)=>{
                     return(
                    
                     <div className="reply__proposal__card" id={reply._id}>
                    <div className="reply__proposal__card__imgWrapper">
                    <img src={reply.sender.profilePicture ? reply.sender.profilePicture : avatarPic} alt="pic" />
                    <b>{reply.sender.username}</b>
                    </div>
                    <div className="reply__proposal__cardBody">
                        <div className="reply__proposal__cardText">
                        {reply.comment}
                        </div>
                        <small>
                          {format(reply.createdAt)}
                        </small>
                      </div>
                    </div>  
                    
                    
                     
                     )
                   })
                   }
                </div>):
                null;
              })}
            </div>
          }
          </div>
         
         {currentUser.roles.includes('ROLE_ADMIN') && 
            (<div className='proposals__users'>
               <h4>All Users</h4>
               {currentOutsourceOrder?.reviewUsers?.map(item=>{
                 return(
                   <div className="proposals__userItem">
                      <Link to={`/profile/manage-users/view/${item._id}`}>
                        <Tooltip title="View User Details">
                          <img src={item.profilePicture ? item.profilePicture : avatarPic} alt={item.username} />
                          <b>{item.username}</b>
                        </Tooltip>
                      </Link>
                      <div>
                      <Tag color={item.roles[0].name === "employee" ? 'blue':'orange'}>{item?.roles[0]?.name}</Tag>
                      </div>
                   </div>
                 )
               })}
            </div>)
          }
               
        </div>
        </TabPane>
      </Tabs>

      <Modal
        title="Upload Order Requirements"
        visible={requirementVisible}
        onOk={handleUploadRequirement}
        confirmLoading={confirmRequirementLoading}
        onCancel={handleRequirementCancel}
        closable={true}
        okText="Upload"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Select Requirement Files</label></b></h6>
            </div>
            <input
              type="file"
              multiple
              onChange={handleRequirementChange}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>

    

      <Modal
        title="Upload Deliverable files"
        visible={deliverableModal}
        onOk={handleDeliverableUpload}
        confirmLoading={confirmDeliverableLoading}
        onCancel={handleDeliverableCancel}
        closable={true}
        okText="Upload"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Select Deliverable Files</label></b></h6>
            </div>
            <input
              type="file"
              multiple
              onChange={handleDeliverableChange}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>

      <Modal
        title="Upload Notes and Reference files"
        visible={notesAndReferenceModal}
        onOk={handleNotesAndReferenceSubmit}
        confirmLoading={notesAndReferenceLoading}
        onCancel={handleNotesAndReferenceCancel}
        closable={true}
        okText="Upload"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Select Notes and Reference Files</label></b></h6>
            </div>
            <input
              type="file"
              multiple
              onChange={handleNotesAndReferenceFilesChange}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>

      <Modal
        title="Upload Source Code files"
        visible={sourceCodeModal}
        onOk={handleSourceCodeUpload}
        confirmLoading={confirmSourceCodeUpload}
        onCancel={handleCancelSourceCodeModal}
        closable={true}
        okText="Upload"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Select Source Code Files</label></b></h6>
            </div>
            <input
              type="file"
              multiple
              onChange={handleSourceCodeFileChange}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>
      <Modal
        title="Refund Status"
        visible={refundStatusModal}
        onOk={handleRefundStatusModal}
        confirmLoading={confirmRefundStatusModal}
        onCancel={() => {
          setRefundStatusModal (false);
        }}
        closable={true}
        okText="Update"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Enter Refund Status</label></b></h6>
            </div>
            <input
              type="text"
              value={refundStatus}
              onChange={e => {
                setRefundStatus (e.target.value);
              }}
              className="form-control mt-2"
            />
          </form>

        </div>
      </Modal>

      <Modal
        title="Add Review Note"
        visible={reviewNoteModal}
        onOk={handleSubmitReview}
        confirmLoading={confirmLoadingReview}
        onCancel={cancelReviewNoteModal}
        closable={true}
        okText="Add"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Enter Review</label></b></h6>
            </div>
            <TextArea
              placeholder="Enter your review"
              rows={4}
              defaultValue={orderDetails.reviewNote && orderDetails.reviewNote}
              value={reviewNote}
              onChange={e => {
                setReviewNote (e.target.value);
              }}
            />
          </form>

        </div>
      </Modal>


      <Modal
        title={`Update ${orderDetails.name} Status`}
        visible={statusChangeModal}
        onOk={handleStatusChangeSubmit}
        confirmLoading={changeStatusLoading}
        onCancel={() => {
          setStatusChangeModal (false);
        }}
        closable={true}
        okText="Change"
      >
        <div style={{fontStyle: 'normal'}}>
          <form>
            <div className="mt-2">
              <h6><b><label>Select Updated Status</label></b></h6>
            </div>
            <Select
              value={updatedStatus}
              style={{width:'100%'}}
              onChange={value => setUpdatedStatus (value)}
            >
              <Option value={'Requested'}>Requested</Option>
              <Option value={'Reviewing'}>Reviewing</Option>
              <Option value={'Development'}>Development</Option>
              <Option value={'Testing'}>Testing</Option>
              <Option value={'Deal Confirmed'}>Deal Confirmed</Option>
              <Option value={'Delivered'}>Delivered</Option>
              <Option value={'Completed'}>Completed</Option>
              <Option value={'Cancel Order'}>Cancel Order</Option>
            </Select>
          </form>

        </div>
      </Modal>

      <Modal 
            title={"Add Progress"}
            visible={isProgress} 
            onOk={handleProgressSubmit} 
            onCancel={handleProgressClose}
            confirmLoading={confirmProgressUpload}
         >
             <form>
             <div className="form-group">
             <label><h6>Enter Progress Percentage :</h6></label>
               <input 
               type="Number" 
               className="form-control"
               value={progressPercentage} 
               onChange={(e)=>{setProgressPercentage(e.target.value)}}/>
             </div>
             </form>
        </Modal>

    </div>
  );
};

export default ViewOrderDetails;
