import React, {useEffect, useState} from 'react';
import employee from '../../../assets/employee.png';
import './DashboardHome.css';
import {Card} from 'antd';
import {ShoppingCartOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import orderService from '../../../services/order.service';
import {message} from 'antd';

function EmployeeDashboard({userData}) {
  const [activeOrdersCount, setActiveOrdersCount] = useState (0);
  const {user: currentUser} = useSelector (state => state.auth);
  useEffect (
    () => {
      let active = 0;
      // console.log("USER ORDERS : ",userData.userOrders)
      if (userData.userOrders && userData.userOrders.length > 0) {
        userData.userOrders.map (o => {
          if (
            o.status &&
            (o.status.toLowerCase () === 'development' ||
              o.status.toLowerCase () === 'testing' ||
              o.status.toLowerCase () === 'deal confirmed')
          ) {
            active++;
          }
        });
      }
      // console.log("active count :", active);
      setActiveOrdersCount (active);
    },
    [userData]
  );

  useEffect (
    () => {
      const getAllToDoSectionAndTask = async () => {
        try {
          const response = await orderService.getAllToDoSectionAndTask (
            currentUser.id
          );
          //   console.log (response);
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      getAllToDoSectionAndTask ();
    },
    [currentUser]
  );
  return (
    <div className="DashboardHome">
      <div className="dashboard-card">
        <h3>Statistics</h3>
        <hr />
        <div className="Stats">
          <Link to="/profile/assignment">
            <Card className="stat-card">
              <div>
                <ShoppingCartOutlined className="stat-icon" />
              </div>
              <div className="cart-total">
                <div className="total-label">Total Tasks</div>
                <div className="dashboard-card-total">
                  {userData.orderCount ? userData.orderCount : 0}
                </div>
              </div>
            </Card>
          </Link>
          <Link to="/profile/assignment">
            <Card className="stat-card">
              <div>
                <ShoppingCartOutlined className="stat-icon" />
              </div>
              <div className="cart-total">
                <div className="total-label">Active Tasks</div>
                <div className="dashboard-card-total">{activeOrdersCount}</div>
              </div>
            </Card>
          </Link>

          <Link to="/profile/assigned-orders">
            <Card className="stat-card">
              <div>
                <ShoppingCartOutlined className="stat-icon" />
              </div>
              <div className="cart-total">
                <div className="total-label">All Projects</div>
                <div className="dashboard-card-total">
                  {userData.projects ? userData.projects : 0}
                </div>
              </div>
            </Card>
          </Link>
        </div>

      </div>
      <Link to="/profile">
        <div className="user-info-card">
          <img src={employee} className="dashboard-user-img" alt="icon" />
          <div className="dashboardUserType">Employee</div>
        </div>
      </Link>
    </div>
  );
}

export default EmployeeDashboard;
