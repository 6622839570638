import React, {useState, useEffect} from 'react';
import orderService from '../../../services/order.service';

function SinglePublicOrder({match}) {
  const [publicOrderDetails, setPublicOrderDetails] = useState (null);

  const getSingleOrderDetails = async () => {
    try {
      const response = await orderService.getSinglePublicOrder (
        match.params.slug
      );
      setPublicOrderDetails (response.data);
    } catch (err) {
      console.log (err);
    }
  };

  useEffect (() => {
    getSingleOrderDetails ();
  }, []);
  return (
    <div>
      <h2>Product by {publicOrderDetails && publicOrderDetails.name}</h2>
      <hr />
      <table className="table table-borderless table-striped">
        <thead>
          <tr>
            <th scope="row">Order By</th>
            <th>{publicOrderDetails && publicOrderDetails.name}</th>
          </tr>

        </thead>
        <tbody>
          <tr>
            <th scope="col">Category</th>
            <td>
              {publicOrderDetails && publicOrderDetails.category}
            </td>
          </tr>
          <tr>
            <th scope="row">Deadline</th>
            <td>{publicOrderDetails && publicOrderDetails.deadline}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{publicOrderDetails && publicOrderDetails.email}</td>
          </tr>
          <tr>
            <th scope="col">Contact No.</th>
            <td>
              {publicOrderDetails && publicOrderDetails.phone}
            </td>
          </tr>
          <tr>
            <th scope="col">Project Type</th>
            <td>{publicOrderDetails && publicOrderDetails.type}</td>
          </tr>

          <tr>
            <th scope="col">Technologies</th>
            <td>
              {publicOrderDetails &&
                publicOrderDetails.technologies &&
                publicOrderDetails.technologies.map (item => {
                  return <span className="badge">{item}</span>;
                })}
            </td>
          </tr>
          <tr>
            <th scope="row">Details</th>
            <td>{publicOrderDetails && publicOrderDetails.details}</td>
          </tr>

        </tbody>
      </table>
      {publicOrderDetails &&
        (publicOrderDetails.fileUrl !== null &&
          <a
            href={publicOrderDetails.fileUrl}
            target="_blank"
            rel={'noreferrer'}
            style={{textDecoration: 'none', color: 'white'}}
          >
            <button
              style={{border: 'none', width: '15vw', borderRadius: '0'}}
              className="btn btn-primary"
            >
              View Doc

            </button>
          </a>)}
    </div>
  );
}

export default SinglePublicOrder;
