import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import OrderService from '../../services/order.service';
import {Tag, Button, Table, message, Tabs, Space} from 'antd';
import {Link} from 'react-router-dom';
import {history} from '../../helpers/history';
import moment from 'moment';
import {
  BarChartOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
  StopOutlined,
} from '@ant-design/icons';

const {TabPane} = Tabs;

const MyAssignment = () => {
  const [allAssignedOrders, setAllAssignedOrders] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);
  const [filteredArr, setFilteredArr] = useState ([]);
  let currentNow = new Date ();
  let currentDay = currentNow.getDate ();
  let currentMonth = currentNow.getMonth () + 1;
  let currentYear = currentNow.getFullYear ();
  let todaysDate = currentYear + '-' + currentMonth + '-' + currentDay;
  const [filteredCategoryArr, setFilteredCategoryArr] = useState ([]);
  const [filteredStatusArr, setFilteredStatusArr] = useState ([]);
  const [activeOrders, setActiveOrders] = useState ([]);
  const [deliveredOrders, setDeliveredOrders] = useState ([]);
  const [cancelledOrders, setCancellledOrders] = useState ([]);
  const [completedOrders, setCompletedOrders] = useState ([]);
  const [tableLoading, setTableLoading] = useState (true);
  const [allOutsourceOrders, setAllOutsourceOrders] = useState ([]);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_EMPLOYEE')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getOrderForUser = id => {
    // console.log("ID : ",id);
    const data = [];
    OrderService.getEmployeeOrders (id)
      .then (response => {
        // console.log("ORDER ASSIGNED : ",response.data);
        if (response.data) {
          response.data.forEach ((order, index) => {
            let finalDate = '';
            if (order.assignedAt) {
              const date = new Date (order.assignedAt);
              let dd = date.getDate ();
              let mm = date.getMonth () + 1;
              let yyyy = date.getFullYear ();
              let hh = date.getHours ();
              let minutes = date.getMinutes ();
              let ss = date.getSeconds ();
              finalDate =
                dd +
                '-' +
                mm +
                '-' +
                yyyy +
                ' at ' +
                hh +
                ':' +
                minutes +
                ':' +
                ss;
            }

            let updatedField = {
              key: index,
              title: order.name,
              assignedAtDate: finalDate,
              assignedAt: order.assignedAt,
              id: order._id,
              category: order.category,
              ReportingDeadline: order.employeeReporting,
              booking: order.bookingSession === true ? 'Yes' : 'No',
              documentation: order.documentation === true ? 'Yes' : 'No',
              details: order.details,
              status: order.status ? order.status : '---',
              assignedBy: order.assignedTo &&
                order.assignedTo[0] &&
                order.assignedTo[0].username,
              managerNote: order.managerNote,
            };

            data.push (updatedField);
          });
          setAllAssignedOrders (data);
          categorizeOrders (data);
        }
      })
      .catch (err => {
        console.log (err);
        message.error (
          err.message ? err.message : 'Unable to fetch order details!'
        );
      });
  };

  useEffect (() => {
    if (currentUser) {
      getOrderForUser (currentUser.id);
    }
  }, []);

  useEffect (
    () => {
      let isMounted = true;
      setTableLoading (true);
      const getOutsourceOrders = async () => {
        try {
          const response = await OrderService.getOutsourceOrderForUser ({
            userId: currentUser.id,
          });
          let finalData = [];

          console.log (response.data);
          if (response.data) {
            response.data.forEach ((item, index) => {
              let finalDate = '';
              let order = item.orderId;
              if (order.assignedAt) {
                const date = new Date (order.assignedAt);
                let dd = date.getDate ();
                let mm = date.getMonth () + 1;
                let yyyy = date.getFullYear ();
                let hh = date.getHours ();
                let minutes = date.getMinutes ();
                let ss = date.getSeconds ();
                finalDate =
                  dd +
                  '-' +
                  mm +
                  '-' +
                  yyyy +
                  ' at ' +
                  hh +
                  ':' +
                  minutes +
                  ':' +
                  ss;
              }

              let updatedField = {
                key: index,
                title: order.name,
                assignedAtDate: finalDate,
                assignedAt: order.assignedAt,
                id: order._id,
                category: order.category,
                ReportingDeadline: order.deadline,
                booking: order.bookingSession === true ? 'Yes' : 'No',
                documentation: order.documentation === true ? 'Yes' : 'No',
                details: order.details,
                status: order.status ? order.status : '---',
                assignedBy: order.assignedTo &&
                  order.assignedTo[0] &&
                  order.assignedTo[0].username,
                managerNote: order.managerNote,
              };

              finalData.push (updatedField);
            });
            setAllOutsourceOrders (finalData);
            setTableLoading (false);
          }
        } catch (err) {
          console.log (err);
          setTableLoading (false);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      if (isMounted) {
        getOutsourceOrders ();
      }

      return () => {
        isMounted = false;
      };
    },
    [currentUser.id]
  );

  useEffect (
    () => {
      let categoryFilterArr = [];
      let statusFilterArr = [];

      allAssignedOrders.map (o => {
        if (o.category) {
          categoryFilterArr.push ({text: o.category, value: o.category});
        }

        if (o.status) {
          statusFilterArr.push ({text: o.status, value: o.status});
        }
      });

      // console.log("NAME FILTER : ",assignToArr);
      categoryFilterArr = categoryFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      statusFilterArr = statusFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      setFilteredCategoryArr (categoryFilterArr);
      setFilteredStatusArr (statusFilterArr);
    },
    [allAssignedOrders]
  );

  const columns = [
    {
      title: 'Assigned At',
      key: 'assignedAt',
      dataIndex: 'assignedAtDate',
      width: '20%',
      sorter: (a, b) =>
        moment (a.assignedAt).unix () - moment (b.assignedAt).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Name',
      key: 'title',
      width: '20%',
      render: record => {
        return (
          <Link to={`/profile/view-order-details/${record.id}`}>
            <b>{record.title}</b>
          </Link>
        );
      },
    },
    {
      title: 'Category',
      dataIndex: 'category',
      filters: filteredCategoryArr,
      onFilter: (value, record) => record.category.indexOf (value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: 'Reporting Deadline',
      dataIndex: 'ReportingDeadline',
      key: 'ReportingDeadline',
      render: reportingDeadline => {
        return reportingDeadline === todaysDate
          ? <Tag color={'red'}><b>{reportingDeadline}</b></Tag>
          : <b>{reportingDeadline}</b>;
      },
    },

    {
      title: 'Assigned By',
      dataIndex: 'assignedBy',
      key: 'assignedBy',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: filteredStatusArr,
      render: text => {
        return <Tag color={'geekblue'}>{text}</Tag>;
      },
      onFilter: (value, record) =>
        record.status && record.status.indexOf (value) === 0,
    },
    {
      title: 'Manager Note',
      dataIndex: 'managerNote',
      key: 'managerNote',
      width: '20%',
      render: text => {
        return <Tag color={'cyan'}>{text && text.slice (0, 30)}...</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Link to={`/profile/view-order-details/${record.id}`}>
            <Button>
              Details
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allAssignedOrders.length > 0) {
        filteredData = allAssignedOrders.filter (order => {
          // return order.name && order.name.toLowerCase().trim().includes(item.toLowerCase().trim());
          return (
            order.title &&
            order.title.toLowerCase ().match (item.toLowerCase ())
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    // console.log("SEARCH RESULT : ",filteredData);
    setFilteredArr (filteredData);
  };

  const categorizeOrders = async data => {
    // console.log("Data in Categorization : ",data);
    // console.log("data length : ",data.length);
    const active = data.filter (o => {
      if (o.status !== undefined) {
        return (
          o.status.toLowerCase () === 'development' ||
          o.status.toLowerCase () === 'testing' ||
          o.status.toLowerCase () === 'deal confirmed'
        );
      }
    });
    // console.log("Active Orders : ",active);

    const delivered = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'delivered';
      }
    });
    const completed = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'completed';
      }
    });
    const cancelled = data.filter (o => {
      if (o.status !== undefined) {
        return o.status.toLowerCase () === 'cancel order';
      }
    });
    // console.log("FILTERED ARRAY : ",newFilteredArr);

    //  console.log("After Duplicate remove FILTERED ARRAY : ",newFilteredArr);
    setActiveOrders (active);
    setDeliveredOrders (delivered);
    setCancellledOrders (cancelled);
    setCompletedOrders (completed);
    // console.log("ACTIVE ORDERS : ",active);
    // console.log("DELIVERED ORDERS : ",delivered);
    // console.log("CANCELLED ORDERS : ",cancelled);
    // console.log("NEW ORDERS : ",newOrders);
    setTableLoading (false);
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h3>Assignments</h3>
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            style={{width: '30vw', marginBottom: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>
      </div>
      {/* <Table dataSource={filteredArr.length > 0 ? filteredArr :allAssignedOrders} columns={columns} /> */}

      <Tabs defaultActiveKey="0" type="card">

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <BarChartOutlined style={{fontSize: '20px'}} />
              New Orders
            </span>
          }
          key="0"
          onChange={() => getOrderForUser (currentUser.id)}
        >
          <Table
            loading={tableLoading}
            dataSource={
              filteredArr.length > 0 ? filteredArr : allOutsourceOrders
            }
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <BarChartOutlined style={{fontSize: '20px'}} />
              Active Orders
            </span>
          }
          key="1"
          onChange={() => getOrderForUser (currentUser.id)}
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : activeOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <CheckCircleOutlined style={{fontSize: '20px'}} />
              Completed Orders
            </span>
          }
          key="2"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : completedOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <i
                className="fa fa-truck"
                aria-hidden="true"
                style={{fontSize: '20px', marginRight: '6%'}}
              />
              Delivered Orders
            </span>
          }
          key="3"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : deliveredOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <StopOutlined style={{fontSize: '20px'}} />
              Cancelled Orders
            </span>
          }
          key="4"
        >
          <Table
            loading={tableLoading}
            dataSource={filteredArr.length > 0 ? filteredArr : cancelledOrders}
            columns={columns}
          />
        </TabPane>

        <TabPane
          tab={
            <span style={{fontSize: '15px'}}>
              <ShoppingCartOutlined style={{fontSize: '20px'}} />
              All Orders
            </span>
          }
          key="6"
          onChange={() => getOrderForUser (currentUser.id)}
        >
          <Table
            loading={tableLoading}
            dataSource={
              filteredArr.length > 0 ? filteredArr : allAssignedOrders
            }
            columns={columns}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MyAssignment;
