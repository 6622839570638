import axios from 'axios';

// const API_URL = "http://3.111.82.112:3000/api/";
var API_URL = 'https://dashboard.codersarts.com/api/';
// var API_URL = 'http://localhost:3000/api/';

const gmaillink = async () => {
  return axios
    .get (API_URL + 'gmaillink')
    .then (response => {
      return response;
    })
    .catch (error => {
      console.log (error);
    });
};

const gmailcode = async code => {
  return axios
    .post (API_URL + 'gmailcode', code)
    .then (response => {
      return response;
    })
    .catch (error => {
      console.log (error);
    });
};

const drivelink = async () => {
  return axios
    .get (API_URL + 'drivelink')
    .then (response => {
      return response;
    })
    .catch (error => {
      console.log (error);
    });
};

const drivecode = async code => {
  return axios
    .post (API_URL + 'drivecode', code)
    .then (response => {
      return response;
    })
    .catch (error => {
      console.log (error);
    });
};

export default {
  gmaillink,
  gmailcode,
  drivelink,
  drivecode,
};
