import React,{useState,useEffect} from 'react';
import OrderService from '../../../services/order.service';
import { message,Modal,Table,Rate } from 'antd';
import { history } from '../../../helpers/history';
import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom';
import {moment} from 'moment';


const AllFeedback = ()=>{
    const [allFeedback,setAllFeedback] = useState([]);
    const [currentViewMoreMsg,setCurrentViewMoreMsg] = useState("");
    const [viewMoreMsgModal,setViewMoreMsgModal] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);

    useEffect(()=>{
        if(!currentUser || !currentUser.roles.includes('ROLE_ADMIN')){
            history.push('/profile');
        }
    },[currentUser])

    const getAllFeedback = async ()=>{
        try{
            const response = await OrderService.getAllFeedback();
            // console.log("FEEDBACKS : ",response.data);
            const data = [];
            response.data.map((feedback)=>{
                let finalDate="N/A"
                if(feedback.createdAt){
                    const date = new Date(feedback.createdAt);
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    let yyyy = date.getFullYear();
                    let hh = date.getHours();
                    let minutes = date.getMinutes();
                    let ss = date.getSeconds();
                    finalDate =  dd + "-" + mm + "-"+yyyy + " at "+hh + ":" +minutes + ":"+ss;
                }
                let updatedData = {
                    createdAt:finalDate,
                    sortDate:feedback.createdAt,
                    orderId:feedback.orderid[0] && feedback.orderid[0]._id,
                    feedbackBy:feedback.user[0]&& feedback.user[0].username,
                    orderName:feedback.orderid[0] && feedback.orderid[0].name,
                    communication:feedback.communicationfeedback,
                    recommendation:feedback.recommendfeedback,
                    service:feedback.servicefeedback,
                    review:feedback.experience,
                    userId:feedback.user[0]&& feedback.user[0]._id,
                }
                data.push(updatedData);
            })
            // console.log("FINAL FEEDBACK : ",data);
            setAllFeedback(data);

        }catch(err){
            console.log(err);
            message.error(err.message ? err.message : 'Unable to load feedbacks!');
        }
        
           
       
    }

    useEffect(()=>{
        getAllFeedback();
    },[]);

    
    const handleViewMoreMsg = (message)=>{
        setCurrentViewMoreMsg(message);
        setViewMoreMsgModal(true);
    }


    const columns = [
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a, b) => moment(a.sortDate).unix() - moment(b.sortDate).unix(),
            
        },
        {
            title: 'Feedback By',
            key: 'category',
            render:(record)=>{return <Link to={`/profile/manage-users/view/${record.userId}`}><b>{record.feedbackBy}</b></Link>},
        },
        {
            title: 'Order Name',
            key: 'orderName',
            render:(record)=>{return <Link to={`/profile/view-order-details/${record.orderId}`} ><b>{record.orderName}</b></Link>},
            width:'10vw'
        },
        
        {
            title: 'Communication Review',
            dataIndex: 'communication',
            key: 'communication',
            render:(text)=>{
                return(<Rate disabled defaultValue={text} />)
            },
            width:'20vw'
        },
        {
            title: 'Recommendation Review',
            dataIndex: 'recommendation',
            key: 'recommendation',
            render:(text)=>{
                return(<Rate disabled defaultValue={text} />)
            },
            width:'20vw'
           
        },
        {
            title: 'Service Review',
            dataIndex: 'service',
            key: 'service',
            render:(text)=>{
                return(<Rate disabled defaultValue={text} />)
            },
            width:'20vw'
        },
        {
            title: 'Experience',
            key: 'experience',
            width:'15vw',
            render:(record)=>{
                return(<div>{record.review.length > 15 ?
                    <>{record.review.slice(0,15)}...<span onClick={()=>{handleViewMoreMsg(record.review)}} className='viewMoreMsg'>View More</span></>
                    :
                record.review
                }</div>)
            }
        }
    ]

    return(
        <div className='allFeedback'>
            <h3>All Feedback</h3>
            <Table dataSource={allFeedback} columns={columns} style={{margin:"1%"}} />
            <Modal 
            title={"Complete Feedback"}
            visible={viewMoreMsgModal} 
            okText={'Ok'}
            cancelButtonProps={{ style: { display: 'none' } }}
            onCancel={()=>{setViewMoreMsgModal(false)}}
            onOk={()=>{setViewMoreMsgModal(false)}}
         >
         <div className='viewMoreMsgText'>
             {currentViewMoreMsg}
         </div>
            
        </Modal>
        </div>
    )
}


export default AllFeedback;