import React, {useState, useEffect} from 'react';
import {Table, Button, Tag, Menu, Dropdown, Modal, Space, message} from 'antd';
import {Link} from 'react-router-dom';
import OrderService from '../../services/order.service';
import UserService from '../../services/user.service';
import {history} from '../../helpers/history';
import {useSelector} from 'react-redux';
import {
  ShareAltOutlined,
  MoreOutlined,
  EyeOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import moment from 'moment';

function PublicOrders () {
  const [allOrders, setAllOrders] = useState ([]);
  const [publicOrderResponse, setPublicOrderResponse] = useState (null);
  const {user: currentUser} = useSelector (state => state.auth);
  const [filteredCategoryArr, setFilteredCategoryArr] = useState (null);
  const [filteredArr, setFilteredArr] = useState ([]);

  useEffect (() => {
    getOrders ();
  }, []);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_ADMIN')) {
        if (currentUser.roles.includes ('ROLE_SALES')) {
          return;
        }
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  useEffect (
    () => {
      let categoryFilterArr = [];
      allOrders.map (o => {
        if (o.category) {
          categoryFilterArr.push ({text: o.category, value: o.category});
        }
      });
      // console.log("NAME FILTER : ",assignToArr);
      categoryFilterArr = categoryFilterArr.filter ((item, index, self) => {
        return (
          index ===
          self.findIndex (t => t.text === item.text && t.value === item.value)
        );
      });

      setFilteredCategoryArr (categoryFilterArr);
    },
    [allOrders]
  );

  const recyclePublicOrder = async id => {
    let recycledOrder = publicOrderResponse.filter (item => {
      return item._id === id;
    });
    // console.log("RECYLED PAYMENT : ",recycledOrder);
    // let modifiedRecycleData = recycledPayment[0];
    //     if(recycledPayment[0].user && recycledPayment[0].user[0]){
    //         modifiedRecycleData.user = [recycledPayment[0].user[0]._id];
    //     }
    // //  console.log("Recyled Payment : ",modifiedRecycleData);
    let finalRecycledData = {
      data: recycledOrder[0],
      recycleType: 'PublicOrder',
    };

    try {
      const response = await OrderService.recycleData (finalRecycledData);
      // console.log(response);
      if (response.status == 200) {
        message.success (
          `${recycledOrder[0].name ? recycledOrder[0].name : 'Item'} Added to Recyle Bin!`
        );
      }
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : "Couldn't Recycle The Item !");
    }

    getOrders ();
  };

  const columns = [
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Order By',
      dataIndex: 'orderBy',
      key: 'orderBy',
      sorter: (a, b) => a.orderBy.length - b.orderBy.length,
    },

    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: filteredCategoryArr,
      onFilter: (value, record) => record.category.indexOf (value) === 0,
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      sorter: (a, b) =>
        moment (a.deadline).unix () - moment (b.deadline).unix (),
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu style={{marginLeft: '-25%'}}>
                <Menu.Item key="1">
                  <EyeOutlined />
                  {' '}
                  <Link
                    to={{pathname: `/profile/public-orders/${record.id}`}}
                    style={{marginRight: '5%'}}
                    target="_blank"
                  />
                  {' '}
                  View Details
                </Menu.Item>
                {record.fileLink !== null &&
                  <Menu.Item key="2">
                    <ShareAltOutlined />
                    {' '}
                    <Link
                      to={{pathname: record.fileLink}}
                      style={{marginRight: '5%'}}
                      target="_blank"
                    />
                    {' '}
                    View Doc
                  </Menu.Item>}
                {currentUser.roles.includes ('ROLE_SALES')
                  ? null
                  : <Menu.Item
                      key="3"
                      danger
                      onClick={() => {
                        confirmRecycleOrder (record.id);
                      }}
                    >
                      <DeleteOutlined /> Recycle
                    </Menu.Item>}
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>

        </Space>
      ),
    },
  ];

  const getOrders = async () => {
    try {
      const response = await OrderService.getAllPublicOrders ();
      const data = [];
      // console.log("Public Orders : ",response.data);
      setPublicOrderResponse (response.data);
      response.data.forEach ((order, index) => {
        let finalDate = 'N/A';
        if (order.createdAt) {
          const date = new Date (order.createdAt);
          let dd = date.getDate ();
          let mm = date.getMonth () + 1;
          let yyyy = date.getFullYear ();
          let hh = date.getHours ();
          let minutes = date.getMinutes ();
          let ss = date.getSeconds ();
          finalDate =
            dd + '-' + mm + '-' + yyyy + ' at ' + hh + ':' + minutes + ':' + ss;
        }
        let updatedField = {
          key: index,
          id: order._id,
          createdAt: finalDate,
          sortDate: order.createdAt,
          orderBy: order.name,
          category: order.category,
          deadline: order.deadline,
          details: order.details.substr (0, 30) + '...',
          fileLink: order.fileUrl,
        };

        data.push (updatedField);
      });
      setAllOrders (data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : err);
    }
  };

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allOrders.length > 0) {
        filteredData = allOrders.filter (order => {
          //return order.orderBy && order.orderBy.toLowerCase().trim() === item.toLowerCase().trim();
          return (
            order.orderBy &&
            order.orderBy.toLowerCase ().match (item.toLowerCase ())
          );
        });
        //   console.log("SEARCH RESULT : ",filteredData);
      }
    }

    setFilteredArr (filteredData);
  };

  const exportAllOrders = async () => {
    try {
      const result = await UserService.exportPublicOrdersToCsv ();
      // console.log(result);
      if (result.data) {
        message.success ('Exported All Public Orders To CSV file !');
      }
    } catch (err) {
      message.error (
        err.message ? err.message : 'Unable To Export Public Orders !'
      );
    }
  };

  const confirmExport = () => {
    Modal.confirm ({
      title: 'Confirm Export',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to export all Public Orders Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        exportAllOrders ();
      },
    });
  };

  const confirmRecycleOrder = id => {
    Modal.confirm ({
      title: 'Confirm Recycle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Recycle This Public Order Data?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        recyclePublicOrder (id);
      },
    });
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <h2>All Public Orders</h2>
        </div>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name"
            style={{width: '30vw', marginBottom: '3%', marginRight: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />
        </div>
        {currentUser.roles.includes ('ROLE_SALES')
          ? null
          : <Button type={'primary'} onClick={confirmExport}>
              <UploadOutlined /> Export All Orders
            </Button>}
      </div>

      <hr />

      <Table
        dataSource={filteredArr.length > 0 ? filteredArr : allOrders}
        columns={columns}
      />
    </div>
  );
}

export default PublicOrders;
