import React, {useState, useEffect} from 'react';
import {
  Tabs,
  Modal,
  Select,
  Tooltip,
  Button,
  Input,
  message,
  DatePicker,
  Tag
} from 'antd';
import styles from './EmployeeResume.module.css';
import {useSelector} from 'react-redux';
import UserServices from '../../../services/user.service.js';
import {PlusOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import Resume1 from './Resume1/Resume1.jsx';
import moment from 'moment';

const {RangePicker} = DatePicker;
const {Option} = Select;

function EmployeeResume () {
  const {user: currentUser} = useSelector (state => state.auth);
  const [skillsCategoryArr, setSkillCategoriesArr] = useState ([]);
  const [userSkills, setUserSkills] = useState ([]);
  const [name, setName] = useState ('');
  const [email, setEmail] = useState ('');
  const [contact, setContact] = useState ('');
  const [otherSkill, setOtherSkill] = useState ('');
  const [address, setAddress] = useState ('');
  const [isAddingEducation, setIsAddingEducation] = useState (false);
  const [education, setEducation] = useState ('');
  const [institution, setInstitution] = useState ('');
  const [educationYears, setEducationYears] = useState ('');
  const [educationYearsValue, setEducationYearsValue] = useState ();
  const [educationDetails,setEducationDetails] = useState("");
  const [allEducationArr, setAllEducationArr] = useState ([]);
  const [currentEditEducation, setCurrentEditEducation] = useState ('');
  const [isEditEducation, setIsEditEducation] = useState (false);
  const [updatedEducation, setUpdatedEducation] = useState ('');
  const [updatedInstitution, setUpdatedInstitution] = useState ('');
  const [updatedEducationYears, setUpdatedEducationYears] = useState ('');
  const [updatedEducationYearsValue, setUpdatedEducationYearsValue] = useState (
    ''
  );
  const [updatedEducationDetails,setUpdatedEducationDetails] = useState("");
  const [jobRole,setJobRole] = useState("");
  const [profileDescription,setProfileDescription] = useState("");
  const [projectName, setProjectName] = useState ('');
  const [projectDetails, setProjectDetails] = useState ('');
  const [projectTech, setProjectTech] = useState ([]);
  const [projectLink, setProjectLink] = useState ('');
  const [projectDuration, setProjectDuration] = useState ('');
  const [isAddingWorkHistory, setIsAddingWorkHistory] = useState (false);
  const [suggestedTechnologies, setSuggestedTechnologies] = useState ([]);
  const [allProjectArr, setAllProjectArr] = useState ([]);
  const [isUpdatingWorkHistory,setIsUpdatingWorkHistory] = useState(false);
  const [currentWorkUpdate,setCurrentWorkUpdate] = useState("");
  const [updatedProjectName, setUpdatedProjectName] = useState ('');
  const [updatedProjectDetails, setUpdatedProjectDetails] = useState ('');
  const [updatedProjectTech, setUpdatedProjectTech] = useState ([]);
  const [updatedProjectLink, setUpdatedProjectLink] = useState ('');
  const [updatedProjectDuration, setUpdatedProjectDuration] = useState ('');


  const [isAddingEmployment,setIsAddingEmployment] = useState(false);
  const [position,setPosition] = useState("");
  const [company,setCompany] = useState("");
  const [totalExperience,setTotalExperience] = useState("");
  const [employmentDetails,setEmploymentDetails] = useState("");
  const [allEmploymentArr,setAllEmploymentArr] = useState([]);
  const [isUpdatingEmployment,setIsUpdatingEmployment] = useState(false);
  const [updatedPosition,setUpdatedPosition] = useState("");
  const [updatedCompany,setUpdatedCompany] = useState("");
  const [updatedTotalExperience,setUpdatedTotalExperience] = useState("");
  const [updatedEmploymentDetails,setUpdatedEmploymentDetails] = useState("");
  const [currentEmployment,setCurrentEmployment] = useState("");


  const [isAddingOtherExperience,setIsAddingOtherExperience] = useState(false);
  const [otherExperienceTitle,setOtherExperienceTitle] = useState("");
  const [otherExperienceDetails,setOtherExperienceDeails] = useState("");
  const [allOtherExperienceDetails,setAllOtherExperienceDetails] = useState([]);
  const [isUpdatingOtherExperience,setIsUpdatingOtherExperience] = useState(false);
  const [updatedOtherExperienceTitle,setUpdatedOtherExperienceTitle] = useState("");
  const [updatedOtherExperienceDetails,setUpdatedOtherExperienceDetails] = useState("");
  const [currentEditExperience,setCurrentEditExperience] = useState("");
  
  const [isSaving,setIsSaving] = useState(false);
  const [isDataAvailable,setIsDataAvailable] = useState("");

  // console.log ('year : ', currentYear);


  useEffect (
    () => {
      let isMounted = true;
      const findResume = async () => {
        try {
          const response = await UserServices.getEmployeeResume ({
            userId: currentUser.id,
          });
          console.log (response.data);
          if(response.data){
            if(response.data._id){
              setIsDataAvailable(response.data._id);
            }
            setName(response.data?.personalDetails[0]?.name)
            setEmail(response.data?.personalDetails[0]?.email)
            setContact(response.data?.personalDetails[0]?.contact);
            setAddress(response.data?.personalDetails[0]?.address);
            setUserSkills(response.data?.personalDetails[0]?.skills);
            setJobRole(response.data?.personalDetails[0]?.jobRole);
            setAllEducationArr(response.data?.education);
            setAllEmploymentArr(response.data?.employmentHistory);
            setAllOtherExperienceDetails(response.data?.otherExperiences);
            setAllProjectArr(response.data?.workHistory);
            setProfileDescription(response.data?.description);
            
          }else{
            if(currentUser){
              setName(currentUser?.name);
              setEmail(currentUser?.email);
              setContact(currentUser?.contact);
              setAddress(currentUser?.address)
            }
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'something went wrong!');
        }
      };

      const getAllCategory = async () => {
        try {
          const response = await UserServices.getAllSkillsList ();
          console.log (response.data);
          if (isMounted && response.data && response.data.length > 0) {
            setSkillCategoriesArr (response.data);
          }
        } catch (err) {
          console.log (err);
          message.error (err.message ? err.message : 'Something went wrong!');
        }
      };

      if (isMounted) {
        findResume ();
        getAllCategory ();
      }

      return () => {
        isMounted = false;
      };
    },
    [currentUser]
  );

  useEffect (
    () => {
      if (skillsCategoryArr.length > 0) {
        let suggestedTech = [];
        skillsCategoryArr.forEach (tech => {
          let temp = {
            value: tech.name,
            label: tech.name,
          };
          suggestedTech.push (temp);
        });
        console.log ('suggested tech : ', suggestedTech);
        setSuggestedTechnologies (suggestedTech);
      }
    },
    [skillsCategoryArr]
  );

  const handleSkillsChange = value => {
    setUserSkills (value);
  };

  const handleAddOtherSkill = () => {
    let isItemAvailable = userSkills.findIndex (item => {
      return item.toLowerCase () === otherSkill.toLowerCase ();
    });
    if (isItemAvailable >= 0) {
      message.warning (`${otherSkill} Already set as your skill!`);
    } else {
      setUserSkills (prev => {
        return [...prev, otherSkill];
      });
    }

    setOtherSkill ('');
  };

  const handleCancelAddEducation = () => {
    setIsAddingEducation (false);
  };

  const handleAddEducation = () => {
    let id =
      new Date ().getTime ().toString () + Math.random () * education.length;
    const finalEducation = {
      id: id,
      education: education,
      institution: institution,
      educationYears: educationYears,
      educationDetails:educationDetails,
      educationYearsValue: educationYearsValue,
    };
    console.log ("finalData: ",finalEducation);
    setAllEducationArr (prev => {
      return [...prev, finalEducation];
    });
    setIsAddingEducation (false);
  };

  const handleDeleteEducation = id => {
    setAllEducationArr (prev => {
      let updatedArr = prev.filter (item => {
        return item.id !== id;
      });
      return updatedArr;
    });
  };

  const handleEditEducation = item => {
    console.log("item edit : ",item);
    setCurrentEditEducation (item.id);
    setUpdatedEducation (item.education);
    setUpdatedInstitution (item.institution);
    setUpdatedEducationYears (item.educationYears);
    if(typeof item.educationYearsValue[0] === "string" && typeof item.educationYearsValue[1] === "string"){
      setUpdatedEducationYearsValue ([moment(item.educationYearsValue[0]),moment(item.educationYearsValue[1])]);
    }else{
      setUpdatedEducationYearsValue(item.educationYearsValue)
    }
   
    setUpdatedEducationDetails(item.educationDetails);
    setIsEditEducation (true);
  };

  const handleEditSubmitEducation = () => {
    let updatedEducationItem = {
      id: currentEditEducation,
      education: updatedEducation,
      institution: updatedInstitution,
      educationDetails:updatedEducationDetails,
      educationYears: updatedEducationYears,
      educationYearsValue: updatedEducationYearsValue,
    };
    console.log("updated data : ",updatedEducationItem);
    let itemToUpdate = allEducationArr.findIndex (item => {
      return item.id === currentEditEducation;
    });
    setAllEducationArr (prev => {
      let copyPrev = prev;
      copyPrev[itemToUpdate] = updatedEducationItem;
      // console.log (copyPrev);
      return copyPrev;
    });

    setIsEditEducation (false);
  };

  const handleChangeEducationYears = (value, dateString) => {
    setEducationYears (dateString);
    console.log("years value : ",value);
    setEducationYearsValue (value);
  };

  const handleUpdatedEducationYearsChange = (value, dateString) => {
    setUpdatedEducationYears (dateString);
    console.log("updated years value : ",value);
    setUpdatedEducationYearsValue (value);
  };

  const handleAddWorkHistory = () => {
    let id =
      new Date ().getTime ().toString () + Math.random () * education.length;

    let data = {
      id: id,
      projectName: projectName,
      projectTech: projectTech,
      projectDuration: projectDuration,
      projectDetails: projectDetails,
      projectLink: projectLink,
    };
    console.log ('added : ', data);
    setAllProjectArr (prev => {
      let prevCopy = [...prev, data];
      return prevCopy;
    });
    setIsAddingWorkHistory (false);
  };

  const handleCancelWorkHistory = () => {
    setIsAddingWorkHistory (false);
  };

  const handleProjectTechnologiesChange = value => {
    // console.log ('final project tech : ', value);
    setProjectTech (value);
  };

  const handleEditProject = (item)=>{
    setCurrentWorkUpdate(item.id);
    setUpdatedProjectName(item.projectName);
    setUpdatedProjectDuration(item.projectDuration);
    setUpdatedProjectLink(item.projectLink);
    setUpdatedProjectDetails(item.projectDetails);
    setUpdatedProjectTech(item.projectTech);
    setIsUpdatingWorkHistory(true);
  };

  const handleDeleteProject = (id)=>{
    setAllProjectArr (prev => {
      let updatedArr = prev.filter (item => {
        return item.id !== id;
      });
      return updatedArr;
    });
  }



  const handleUpdateWorkHistory = ()=>{
    const data = {
      id:currentWorkUpdate,
      projectName: updatedProjectName,
      projectTech: updatedProjectTech,
      projectDuration: updatedProjectDuration,
      projectDetails: updatedProjectDetails,
      projectLink: updatedProjectLink,
    }

    console.log("updated : ",data);

    setAllProjectArr(prev=>{
      let itemToFind = prev.findIndex(item=>{
        return item.id === currentWorkUpdate
      });
      let copiedArr = prev;
      copiedArr[itemToFind] = data;
      return copiedArr;
    })
    setIsUpdatingWorkHistory(false);
  }

  const handleCancelUpdateWorkHistory = ()=>{
    setIsUpdatingWorkHistory(false);
  }


  const handleSubmitEmploymentDetail = ()=>{
    let id = new Date ().getTime ().toString () + Math.random () * position.length;

    const data = {
      id:id,
      position:position,
      company:company,
      totalExperience:totalExperience,
      employmentDetails:employmentDetails
    }

    console.log("added : ",data);

    setAllEmploymentArr (prev => {
      let prevCopy = [...prev, data];
      return prevCopy;
    });
    setIsAddingEmployment (false);
  }

  const handleEditEmployment = (item)=>{
    setCurrentEmployment(item.id);
    setUpdatedPosition(item.position);
    setUpdatedCompany(item.company);
    setUpdatedEmploymentDetails(item.employmentDetails);
    setUpdatedTotalExperience(item.totalExperience);
    setIsUpdatingEmployment(true);
  }

  const handleDeleteEmployment = (id)=>{
    setAllEmploymentArr (prev => {
      let updatedArr = prev.filter (item => {
        return item.id !== id;
      });
      return updatedArr;
    });
  }

  const handleSubmitUpdatedEmployment = ()=>{
    let data = {
      id:currentEmployment,
      position:updatedPosition,
      company:updatedCompany,
      totalExperience:updatedTotalExperience,
      employmentDetails:updatedEmploymentDetails
    }

    setAllEmploymentArr(prev=>{
      let itemToFind = prev.findIndex(item=>{
        return item.id === currentEmployment
      });
      let copiedArr = prev;
      copiedArr[itemToFind] = data;
      return copiedArr;
    })
    setIsUpdatingEmployment(false);
  }

  const handleAddOtherExperience = ()=>{
    let id = new Date ().getTime ().toString () + Math.random () * otherExperienceTitle.length;
    let data = {
      id:id,
      title:otherExperienceTitle,
      details:otherExperienceDetails
    };

    setAllOtherExperienceDetails((prev)=>{
      let temp = [...prev,data];
      return temp
    });

    setIsAddingOtherExperience(false);
  }


  const handleEditOtherExperience = (item)=>{
    setCurrentEditExperience(item.id);
    setUpdatedOtherExperienceTitle(item.title);
    setUpdatedOtherExperienceDetails(item.details);
    setIsUpdatingOtherExperience(true);

  };
  const handleDeleteOtherExperience = (id)=>{

    setAllOtherExperienceDetails((prev)=>{
      const temp = prev.filter(experience=>{
        return experience.id !== id;
      });
      return temp;
    });

  }

  const handleSubmitUpdateOtherExperience = ()=>{
    let data = {
      id:currentEditExperience,
      title:updatedOtherExperienceTitle,
      details:updatedOtherExperienceDetails,
    }

    setAllOtherExperienceDetails(prev=>{
      let itemToFind = prev.findIndex(item=>{
        return item.id === currentEditExperience
      });
      let copiedArr = prev;
      copiedArr[itemToFind] = data;
      return copiedArr;
    })
    setIsUpdatingOtherExperience(false);
  }

  const saveResumeDetails = async ()=>{
    setIsSaving(true);
    let finalData = {
      user:currentUser.id,
      personalDetails:[{
        name:name,
        email:email,
        contact:contact,
        address:address,
        skills:userSkills,
        jobRole:jobRole
      }],
      workHistory:allProjectArr,
      employmentHistory:allEmploymentArr,
      otherExperiences:allOtherExperienceDetails,
      education:allEducationArr,
      description:profileDescription
    }
    console.log("final data : ",finalData);
    if(isDataAvailable){
      try{
        const response = await UserServices.updateEmployeeResume({...finalData,_id:isDataAvailable,});
        console.log(response.data);
        if(response.data){
          setIsSaving(false);
          message.success("Resume Details Saved Successfully!");
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : "something went wrong!");
        setIsSaving(false);
      }
    }else{
      try{
        const response = await UserServices.createEmployeeResume(finalData);
        console.log(response.data);
        if(response.data){
          setIsSaving(false);
          message.success("Resume Details Saved Successfully!");
        }
      }catch(err){
        console.log(err);
        message.error(err.message ? err.message : "something went wrong!");
        setIsSaving(false);
      }
    }
   
  }

  const handleGenerateResume = async () => {
    let finalData = {
      personalDetails:{
        name:name,
        email:email,
        contact:contact,
        address:address,
        skills:userSkills,
        jobRole:jobRole
      },
      workHistory:allProjectArr,
      employmentHistory:allEmploymentArr,
      otherExperiences:allOtherExperienceDetails,
      education:allEducationArr,
      description:profileDescription
    }


    try{
      const doc = <Resume1 data={finalData} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${name}-resume.pdf`);
    }catch(err){
      console.log(err.message ? err.message : 'something went wrong!');
      message.error(err.message ? err.message :  'something went wrong!')
    }
  }
    return (
    <div className={styles.myResumeWrapper}>
      <div className={styles.myResumeHeader}>
      <h1>My Resume</h1>
        <div>
          <Button onClick={saveResumeDetails} style={{marginRight:'10px'}} loading={isSaving} >Save</Button>
          <Button type="primary" onClick={handleGenerateResume}>Generate Resume</Button>
        </div>
      </div>
      <hr />
      <Tabs type="card">
        <Tabs.TabPane tab="Personal Details" key="item-1">
          <div>
            <h3>Personal Details</h3>
            <div className={styles.formGroup}>
              <Input
                value={name}
                onChange={e => setName (e.target.value)}
                className={styles.formItem}
                placeholder="Your Name e.g. John Doe"
              />
              <Input
                value={email}
                onChange={e => setEmail (e.target.value)}
                className={styles.formItem}
                placeholder="Your Email e.g. john@codersarts.com"
              />
              <Input
                value={contact}
                onChange={e => setContact (e.target.value)}
                className={styles.formItem}
                placeholder="Your Contact e.g. +91 9823823332"
              />
            </div>

            <div className={styles.formGroup}>
            <Input
                value={jobRole}
                onChange={e => setJobRole (e.target.value)}
                className={styles.formItem}
                placeholder="Job Role e.g. Web Developer"
              />
              <Select
                mode="multiple"
                allowClear
                className={styles.formItem}
                showSearch
                value={userSkills}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase ()
                    .indexOf (input.toLowerCase ()) >= 0}
                placeholder="Please select Skills"
                onChange={handleSkillsChange}
              >
                {skillsCategoryArr.map (item => {
                  return (
                    <Option value={item.name} key={item._id}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
              <Input.Group compact className={styles.formItem}>
                <Input
                  style={{
                    width: 'calc(100% - 60px)',
                  }}
                  placeholder={'Other Skills'}
                  onChange={e => setOtherSkill (e.target.value)}
                  value={otherSkill}
                />
                <Button type="primary" onClick={handleAddOtherSkill}>
                  Add
                </Button>
              </Input.Group>
            </div>

            <div className={styles.formGroup}>
              <Input.TextArea
                rows={4}
                value={address}
                onChange={e => setAddress (e.target.value)}
                placeholder="Your Address"
                maxLength={255}
                showCount
                style={{width: '90%'}}
              />
            </div>

            <div className={styles.formGroup}>
              <Input.TextArea
                rows={4}
                value={profileDescription}
                onChange={e => setProfileDescription (e.target.value)}
                placeholder="Profile Description"
                maxLength={255}
                showCount
                style={{width: '90%'}}
              />
            </div>

          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Education" key="item-2">
          
          <div className={styles.resumeCollapse}>
              <div className={styles.resumeCollapseHeader}>
                  <h3>Education Details</h3>
                  <div>
                  <Tooltip
                    title="Add Education Detail"
                    color={'rgb(10, 33, 63)'}
                    placement={'right'}
                  >
                <div
                  className={styles.resumeCollapseAddBtn}
                  onClick={() => setIsAddingEducation (true)}
                >
                  <PlusOutlined className={styles.resumeCollapseAddBtnIcon} />
                </div>
                 </Tooltip>
                  </div>
              </div>

              <div className={styles.resumeCollapseDetailsWrapper}>
                  {allEducationArr.map((item)=>{
                    return(
                      <div  className={styles.resumeCollapseDetails}>
                        <div className={styles.resumeCollapseDetails__header}>
                            <h5>{item.education}</h5>
                            <div className={styles.resumeCardIconGroup}>

                      
                      <div className={styles.resumeCollapseAddBtn}>
                      <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                      <EditOutlined
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleEditEducation (item)}
                        />
                         </Tooltip>
                      </div>
                       
                     

                     
                    <div className={styles.resumeCollapseAddBtn}>
                    <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >

                    <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleDeleteEducation (item.id)}
                        />
                      </Tooltip>
                      </div>

                       
                  

                    </div>
                        </div>
                        <div className={styles.resumeCollapseDetails__body}>
                        <small>
                          {item.educationYears[0]} - {item.educationYears[1]}
                        </small>
                        <p><b>{item.institution}</b></p>
                        <p style={{color:'gray'}}>{item.educationDetails}</p>
                       
                        </div>
                      </div>
                    )
                  })}
              </div>
          </div>
                  
          {/* <div>
            <h3>Education Details</h3>

            <div className={styles.resumeCardGroup}>
              <Tooltip
                title="Add Education Detail"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.addBtn}
                  onClick={() => setIsAddingEducation (true)}
                >
                  <PlusOutlined className={styles.addBtn__icon} />
                </div>
              </Tooltip>
              <div className={styles.resumeCardWrapper}>
              {allEducationArr.map (item => {
                return (
                  <div className={styles.resumeCard} key={item.id}>
                    <h4>{item.education}</h4>

                    <small>
                      {item.educationYears[0]} - {item.educationYears[1]}
                    </small>
                    <p>{item.institution}</p>
                    <p style={{color:'gray'}}>{item?.educationDetails}</p>
                    <div className={styles.resumeCardIconGroup}>

                      <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCardIcon}
                          onClick={() => handleEditEducation (item)}
                        />
                      </Tooltip>

                      <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCardIcon}
                          onClick={() => handleDeleteEducation (item.id)}
                        />
                      </Tooltip>

                    </div>
                  </div>
                );
              })}
              </div>
             
            </div>
          </div> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Projects" key="item-3">


        <div className={styles.resumeCollapse}>
              <div className={styles.resumeCollapseHeader}>
                  <h3>Projects</h3>
                
                 
             
                  <div>
                  <Tooltip
                title="Add Projects"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.resumeCollapseAddBtn}
                  onClick={() => setIsAddingWorkHistory (true)}
                >
                  <PlusOutlined className={styles.resumeCollapseAddBtnIcon} />
                </div>
                 </Tooltip>
                  </div>
              </div>

              <div className={styles.resumeCollapseDetailsWrapper}>
                  {allProjectArr.map((item)=>{
                    return(
                      <div  className={styles.resumeCollapseDetails}>
                        <div className={styles.resumeCollapseDetails__header}>
                            <h5>{item.projectName}</h5>
                            <div className={styles.resumeCardIconGroup}>

                      
                      <div className={styles.resumeCollapseAddBtn}>
                      <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleEditProject (item)}
                        />
                      </Tooltip>

                     
                    
                      </div>
                       
                     

                     
                    <div className={styles.resumeCollapseAddBtn}>
                    <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleDeleteProject (item.id)}
                        />
                      </Tooltip>
                
                      </div>

                       
                  

                    </div>
                        </div>
                        <div className={styles.resumeCollapseDetails__body}>
                        <small>
                        {item.projectDuration}
                        </small>
                        <p>{item.projectDetails}</p>
                        <div>
                          {item.projectTech?.map((tech,index)=><Tag style={{marginBottom:'5px'}} key={index} color={"cyan"}>{tech}</Tag>)}
                        </div>

                        {item.projectLink &&
                     
                            <a
                              href={item.projectLink}
                              target="_blank"
                              rel="noreferrer"
                              className={"primaryBtn"}
                              style={{display:'flex',justifyContent:'flex-end'}}
                            >
                              Go to Project
                            </a>
                          }


                        </div>
                      </div>
                    )
                  })}
              </div>
          </div>

          {/* <div>
            <h3>Projects</h3>
            <div className={styles.resumeCardGroup}>
              <Tooltip
                title="Add Projects"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.addBtn}
                  onClick={() => setIsAddingWorkHistory (true)}
                >
                  <PlusOutlined className={styles.addBtn__icon} />
                </div>
              </Tooltip>
              <div className={styles.resumeCardWrapper}>
              {allProjectArr.map (item => {
                return (
                  <div className={styles.resumeCard} key={item.id}>
                    <h4 style={{margin:0}}>{item.projectName}</h4>
                      <br />

                      <div style={{textAlign:'left'}}>
                      <p style={{color:'gray',margin:0}}>{item.projectDetails}</p>
                       <div><b>Duration: </b>{item.projectDuration}</div>
                      </div>

                      <br />
                    <div>
                      {item.projectTech?.map((tech,index)=><Tag style={{marginBottom:'5px'}} key={index} color={"cyan"}>{tech}</Tag>)}
                    </div>
                    <br />
                    <div  style={{display:'flex',justifyContent:'space-between'}}>
                    {item.projectLink &&
                     
                     <a
                       href={item.projectLink}
                       target="_blank"
                       rel="noreferrer"
                     >
                       Go to Project
                     </a>
                   }

                     <div className={styles.resumeCardIconGroup} style={{flex:'1'}}>
                     <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCardIcon}
                          onClick={() => handleEditProject (item)}
                        />
                      </Tooltip>

                      <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCardIcon}
                          onClick={() => handleDeleteProject (item.id)}
                        />
                      </Tooltip>
                     </div>

                    </div>
                  </div>
                );
              })}
              </div>

            </div>
          </div> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Employment History" key="item-4">
        <div className={styles.resumeCollapse}>
              <div className={styles.resumeCollapseHeader}>
                  <h3>Employment History</h3>
                
                 
             
                  <div>
                  <Tooltip
                title="Add Employment History"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.resumeCollapseAddBtn}
                  onClick={() => setIsAddingEmployment (true)}
                >
                  <PlusOutlined className={styles.resumeCollapseAddBtnIcon} />
                </div>
                 </Tooltip>
                  </div>
              </div>

              <div className={styles.resumeCollapseDetailsWrapper}>
                  {allEmploymentArr.map((item)=>{
                    return(
                      <div  className={styles.resumeCollapseDetails}>
                        <div className={styles.resumeCollapseDetails__header}>
                            <h5>{item.position}</h5>
                            <div className={styles.resumeCardIconGroup}>

                      
                      <div className={styles.resumeCollapseAddBtn}>
                      <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleEditEmployment (item)}
                        />
                      </Tooltip>

                     
                    
                      </div>
                       
                     

                     
                    <div className={styles.resumeCollapseAddBtn}>
                    <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleDeleteEmployment (item.id)}
                        />
                      </Tooltip>
                
                      </div>

                       
                  

                    </div>
                        </div>
                        <div className={styles.resumeCollapseDetails__body}>
                        <small style={{fontSize:'12px'}}>
                        {item.totalExperience}
                        </small>
                        <h6 style={{fontSize:'1.1rem',color:'#1da1f2'}}>{item.company}</h6>
                        <p>{item.employmentDetails}</p>
                        

                       

                        </div>
                      </div>
                    )
                  })}
              </div>
          </div>


          {/* <div>
            <h3>Employment History</h3>
            <div className={styles.resumeCardGroup}>
              <Tooltip
                title="Add Employment History"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.addBtn}
                  onClick={() => setIsAddingEmployment (true)}
                >
                  <PlusOutlined className={styles.addBtn__icon} />
                </div>
              </Tooltip>


              <div className={styles.resumeCardWrapper}>
              {allEmploymentArr.map (item => {
                return (
                  <div className={styles.resumeCard} key={item.id}>
                    <h4 style={{margin:0}}>{item.position}</h4>
                      <br />

                      <div style={{textAlign:'left'}}>
                      <p style={{color:'gray',margin:0}}>{item.employmentDetails}</p>
                       <div><b>Experience: </b>{item.totalExperience}</div>
                       <div><b>Company: </b><Tag color={"cyan"}>{item.company}</Tag></div>
                      </div>

                      <br />
                   
                   
                    

                     <div className={styles.resumeCardIconGroup}>
                     <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCardIcon}
                          onClick={() => handleEditEmployment (item)}
                        />
                      </Tooltip>

                      <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCardIcon}
                          onClick={() => handleDeleteEmployment (item.id)}
                        />
                      </Tooltip>
                     </div>

                    
                  </div>
                );
              })}
              </div>
              </div>
          </div> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Other Experiences" key="item-5">
        <div className={styles.resumeCollapse}>
              <div className={styles.resumeCollapseHeader}>
                  <h3>Other Experiences</h3>
                
                 
             
                  <div>
                  <Tooltip
                title="Add Other Experiences"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.resumeCollapseAddBtn}
                  onClick={() => setIsAddingOtherExperience (true)}
                >
                  <PlusOutlined className={styles.resumeCollapseAddBtnIcon} />
                </div>
                 </Tooltip>
                  </div>
              </div>

              <div className={styles.resumeCollapseDetailsWrapper}>
                  {allOtherExperienceDetails.map((item)=>{
                    return(
                      <div  className={styles.resumeCollapseDetails}>
                        <div className={styles.resumeCollapseDetails__header}>
                            <h5>{item.title}</h5>
                            <div className={styles.resumeCardIconGroup}>

                      
                      <div className={styles.resumeCollapseAddBtn}>
                      <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                           className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleEditOtherExperience (item)}
                        />
                      </Tooltip>
                      

                     
                    
                      </div>
                       
                     

                     
                    <div className={styles.resumeCollapseAddBtn}>


                    <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCollapseAddBtnIcon}
                          onClick={() => handleDeleteOtherExperience (item.id)}
                        />
                      </Tooltip>


                  
                
                      </div>

                       
                  

                    </div>
                        </div>
                        <div className={styles.resumeCollapseDetails__body}>
                        <p>{item.details}</p>
                        

                       

                        </div>
                      </div>
                    )
                  })}
              </div>
          </div>
          {/* <div>
            <h3>Other Experiences</h3>

            <div className={styles.resumeCardGroup}>
              <Tooltip
                title="Add Other Experiences"
                color={'rgb(10, 33, 63)'}
                placement={'right'}
              >
                <div
                  className={styles.addBtn}
                  onClick={() => setIsAddingOtherExperience (true)}
                >
                  <PlusOutlined className={styles.addBtn__icon} />
                </div>
              </Tooltip>

              <div className={styles.resumeCardWrapper}>
              {allOtherExperienceDetails.map (item => {
                return (
                  <div className={styles.resumeCard} key={item.id}>
                    <h4 style={{margin:0}}>{item.title}</h4>
                      <br />

                      <div style={{textAlign:'left'}}>
                      <p style={{color:'gray',margin:0}}>{item.details}</p>
                      </div>

                      <br />
                   
                   
                    

                     <div className={styles.resumeCardIconGroup}>
                     <Tooltip
                        title="Edit?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <EditOutlined
                          className={styles.resumeCardIcon}
                          onClick={() => handleEditOtherExperience (item)}
                        />
                      </Tooltip>

                      <Tooltip
                        title="Delete?"
                        color={'rgb(10, 33, 63)'}
                        placement={'right'}
                      >
                        <DeleteOutlined
                          style={{color: 'red'}}
                          className={styles.resumeCardIcon}
                          onClick={() => handleDeleteOtherExperience (item.id)}
                        />
                      </Tooltip>
                     </div>

                    
                  </div>
                );
              })}
              </div>
              </div>
          </div> */}
        </Tabs.TabPane>
      </Tabs>

      <Modal
        title="Add Education Details"
        visible={isAddingEducation}
        onOk={handleAddEducation}
        okText="Add"
        onCancel={handleCancelAddEducation}
      >
        <Input
          value={education}
          onChange={e => setEducation (e.target.value)}
          placeholder="Education e.g. Bachelor of Engineering"
          style={{marginBottom: '20px'}}
        />
        <Input
          value={institution}
          onChange={e => setInstitution (e.target.value)}
          placeholder="Institution e.g. Delhi University"
          style={{marginBottom: '20px'}}
        />
        <div  style={{marginBottom: '20px'}}>
          <span>
            <b>Education Years</b>
          </span>

          <RangePicker
            picker="year"
            style={{marginLeft: '10px'}}
            onChange={handleChangeEducationYears}
          />

        </div>

        <div>
        <Input.TextArea
          rows={3}
          showCount
          value={educationDetails}
          onChange={e => setEducationDetails (e.target.value)}
          placeholder="Education Detalis"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
        </div>

      </Modal>

      <Modal
        title="Update Education Details"
        visible={isEditEducation}
        onOk={handleEditSubmitEducation}
        okText="Update"
        onCancel={() => setIsEditEducation (false)}
      >
        <Input
          value={updatedEducation}
          onChange={e => setUpdatedEducation (e.target.value)}
          placeholder="Updated Education e.g. Bachelor of Engineering"
          style={{marginBottom: '20px'}}
        />
        <Input
          value={updatedInstitution}
          onChange={e => setUpdatedInstitution (e.target.value)}
          placeholder="Updated Institution e.g. Delhi University"
          style={{marginBottom: '20px'}}
        />
        <div  style={{marginBottom: '20px'}}>
          <span>
            <b>Updated Education Years</b>
          </span>

          <RangePicker
            picker="year"
            style={{marginLeft: '10px'}}
            value={updatedEducationYearsValue}
            onChange={handleUpdatedEducationYearsChange}
          />

        </div>
        <div>
        <Input.TextArea
          rows={3}
          showCount
          value={updatedEducationDetails}
          onChange={e => setUpdatedEducationDetails (e.target.value)}
          placeholder="Updated Education Detalis"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
        </div>

      </Modal>

      <Modal
        title="Add Project Details"
        visible={isAddingWorkHistory}
        onOk={handleAddWorkHistory}
        okText="Add"
        onCancel={handleCancelWorkHistory}
      >

        <Input
          style={{marginBottom: '20px'}}
          value={projectName}
          onChange={e => setProjectName (e.target.value)}
          placeholder="Work/Project Name e.g. Ecommerce Dashboard"
        />
        <Select
          mode="tags"
          style={{
            width: '100%',
            marginBottom:'20px'
          }}
          placeholder="Work/Project Technologies e.g. React, Nodejs"
          onChange={handleProjectTechnologiesChange}
          tokenSeparators={[',']}
          options={suggestedTechnologies}
        />
        <Input.TextArea
          rows={3}
          showCount
          value={projectDetails}
          onChange={e => setProjectDetails (e.target.value)}
          placeholder="Work/Project Details"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
        <Input
          style={{marginBottom: '20px'}}
          value={projectDuration}
          onChange={e => setProjectDuration (e.target.value)}
          placeholder="Work/Project Duration e.g. 3 Months"
        />
        <Input
          style={{marginBottom: '20px'}}
          value={projectLink}
          onChange={e => setProjectLink (e.target.value)}
          placeholder="Work/Project Link (Optional)"
        />

      </Modal>


      <Modal
        title="Update Project Details"
        visible={isUpdatingWorkHistory}
        onOk={handleUpdateWorkHistory}
        okText="Update"
        onCancel={handleCancelUpdateWorkHistory}
      >

        <Input
          style={{marginBottom: '20px'}}
          value={updatedProjectName}
          onChange={e => setUpdatedProjectName (e.target.value)}
          placeholder="Updated Work/Project Name e.g. Ecommerce Dashboard"
        />
        <Select
          mode="tags"
          style={{
            width: '100%',
            marginBottom:'20px'
          }}
          value={updatedProjectTech}
          placeholder="Updated Work/Project Technologies e.g. React, Nodejs"
          onChange={(value)=>setUpdatedProjectTech(value)}
          tokenSeparators={[',']}
          options={suggestedTechnologies}
        />
        <Input.TextArea
          rows={3}
          showCount
          value={updatedProjectDetails}
          onChange={e => setUpdatedProjectDetails (e.target.value)}
          placeholder="Updated Work/Project Details"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
        <Input
          style={{marginBottom: '20px'}}
          value={updatedProjectDuration}
          onChange={e => setUpdatedProjectDuration (e.target.value)}
          placeholder="Updated Work/Project Duration e.g. 3 Months"
        />
        <Input
          style={{marginBottom: '20px'}}
          value={updatedProjectLink}
          onChange={e => setUpdatedProjectLink (e.target.value)}
          placeholder="Updated Work/Project Link (Optional)"
        />

      </Modal>


      <Modal
        title="Add Work Details"
        visible={isAddingWorkHistory}
        onOk={handleAddWorkHistory}
        okText="Add"
        onCancel={handleCancelWorkHistory}
      >

        <Input
          style={{marginBottom: '20px'}}
          value={projectName}
          onChange={e => setProjectName (e.target.value)}
          placeholder="Work/Project Name e.g. Ecommerce Dashboard"
        />
        <Select
          mode="tags"
          style={{
            width: '100%',
            marginBottom:'20px'
          }}
          placeholder="Work/Project Technologies e.g. React, Nodejs"
          onChange={handleProjectTechnologiesChange}
          tokenSeparators={[',']}
          options={suggestedTechnologies}
        />
        <Input.TextArea
          rows={3}
          showCount
          value={projectDetails}
          onChange={e => setProjectDetails (e.target.value)}
          placeholder="Work/Project Details"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
        <Input
          style={{marginBottom: '20px'}}
          value={projectDuration}
          onChange={e => setProjectDuration (e.target.value)}
          placeholder="Work/Project Duration e.g. 3 Months"
        />
        <Input
          style={{marginBottom: '20px'}}
          value={projectLink}
          onChange={e => setProjectLink (e.target.value)}
          placeholder="Work/Project Link (Optional)"
        />

      </Modal>


      <Modal
        title="Add Employment Details"
        visible={isAddingEmployment}
        onOk={handleSubmitEmploymentDetail}
        okText="Add"
        onCancel={()=>setIsAddingEmployment(false)}
      >
        <Input
          style={{marginBottom: '20px'}}
          value={position}
          onChange={e => setPosition (e.target.value)}
          placeholder="Your Role e.g. Web Developer"
        />

        <Input
          style={{marginBottom: '20px'}}
          value={company}
          onChange={e => setCompany (e.target.value)}
          placeholder="Your Company e.g. Microsoft"
        />

        <Input
          style={{marginBottom: '20px'}}
          value={totalExperience}
          onChange={e => setTotalExperience (e.target.value)}
          placeholder="Your Total Experience e.g. 2 Years"
        />

        <Input.TextArea
          style={{marginBottom: '20px'}}
          value={employmentDetails}
          onChange={e => setEmploymentDetails (e.target.value)}
          placeholder="Employment Details "
          showCount
          max={255}
          row={3}
        />


      </Modal>

      <Modal
        title="Update Employment Details"
        visible={isUpdatingEmployment}
        onOk={handleSubmitUpdatedEmployment}
        okText="Update"
        onCancel={()=>setIsUpdatingEmployment(false)}
      >
        <Input
          style={{marginBottom: '20px'}}
          value={updatedPosition}
          onChange={e => setUpdatedPosition (e.target.value)}
          placeholder="Updated Your Role e.g. Web Developer"
        />

        <Input
          style={{marginBottom: '20px'}}
          value={updatedCompany}
          onChange={e => setUpdatedCompany (e.target.value)}
          placeholder="Updated Your Company e.g. Microsoft"
        />

        <Input
          style={{marginBottom: '20px'}}
          value={updatedTotalExperience}
          onChange={e => setUpdatedTotalExperience (e.target.value)}
          placeholder="Updated Your Total Experience e.g. 2 Years"
        />

        <Input.TextArea
          style={{marginBottom: '20px'}}
          value={updatedEmploymentDetails}
          onChange={e => setUpdatedEmploymentDetails (e.target.value)}
          placeholder="Updated Employment Details "
          showCount
          max={255}
          row={3}
        />


      </Modal>

      <Modal
        title="Add Other Experience"
        visible={isAddingOtherExperience}
        onOk={handleAddOtherExperience}
        okText="Add"
        onCancel={()=>setIsAddingOtherExperience(false)}
      >

        <Input
          style={{marginBottom: '20px'}}
          value={otherExperienceTitle}
          onChange={e => setOtherExperienceTitle (e.target.value)}
          placeholder="Title e.g. State Coding Hackathon"
        />
     
        <Input.TextArea
          rows={3}
          showCount
          value={otherExperienceDetails}
          onChange={e => setOtherExperienceDeails (e.target.value)}
          placeholder="Description"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
     

      </Modal>

      <Modal
        title="Update Other Experience"
        visible={isUpdatingOtherExperience}
        onOk={handleSubmitUpdateOtherExperience}
        okText="Update"
        onCancel={()=>setIsUpdatingOtherExperience(false)}
      >

        <Input
          style={{marginBottom: '20px'}}
          value={updatedOtherExperienceTitle}
          onChange={e => setUpdatedOtherExperienceTitle (e.target.value)}
          placeholder="Updated Title e.g. State Coding Hackathon"
        />
     
        <Input.TextArea
          rows={3}
          showCount
          value={updatedOtherExperienceDetails}
          onChange={e => setUpdatedOtherExperienceDetails (e.target.value)}
          placeholder="Updated Description"
          maxLength={255}
          style={{marginBottom: '20px'}}
        />
     

      </Modal>
    </div>
  );
}

export default EmployeeResume;
