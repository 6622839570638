import {message, Space, Button, Menu, Dropdown, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {history} from '../../../../helpers/history';
import userService from '../../../../services/user.service';
import moment from 'moment';
import {
  EyeOutlined,
  EditOutlined,
  MoreOutlined,
  UserAddOutlined,
} from '@ant-design/icons';
import {Link} from 'react-router-dom';

function UsersTableForSales () {
  const [allUsers, setAllUsers] = useState ([]);
  const [filteredArr, setFilteredArr] = useState ([]);
  const {user: currentUser} = useSelector (state => state.auth);

  useEffect (
    () => {
      if (!currentUser || !currentUser.roles.includes ('ROLE_SALES')) {
        history.push ('/profile');
      }
    },
    [currentUser]
  );

  const getAllUsers = async () => {
    try {
      const response = await userService.getAllUsers ();
      // console.log(response.data);
      const data = [];
      if (response.data) {
        response.data.map ((item, index) => {
          let finalDate = 'N/A';
          if (item.roles[0].name === 'user') {
            if (item.createdAt) {
              const date = new Date (item.createdAt);
              let dd = date.getDate ();
              let mm = date.getMonth () + 1;
              let yyyy = date.getFullYear ();
              let hh = date.getHours ();
              let minutes = date.getMinutes ();
              let ss = date.getSeconds ();
              finalDate =
                dd +
                '-' +
                mm +
                '-' +
                yyyy +
                ' at ' +
                hh +
                ':' +
                minutes +
                ':' +
                ss;
            }
            // console.log("FINAL DATE : ",finalDate);
            data.push ({
              key: index + 1,
              createdAt: finalDate,
              sortDate: item.createdAt,
              id: item._id,
              name: item.name ? item.name : 'Guest',
              username: item.username,
              dob: item.dob ? item.dob : 'N/A',
              address: item.address ? item.address : 'N/A',
              email: item.email,
              isDisabled: item.isDisabled,
              role: item.roles[0] ? item.roles[0].name : 'N/A',
            });
          }
        });
      }

      // console.log(data);
      setAllUsers (data);
    } catch (err) {
      console.log (err);
      message.error (err.message ? err.message : "Couldn't Load the users !");
    }
  };
  useEffect (() => {
    getAllUsers ();
  }, []);

  const viewDetails = id => {
    history.push (`/profile/manage-users/view/${id}`);
  };
  const editData = username => {
    history.push ({
      pathname: `/profile/manage-users/${username}`,
    });
  };

  const columns = [
    {
      title: 'CreatedAt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) =>
        moment (a.sortDate).unix () - moment (b.sortDate).unix (),
    },
    {
      title: 'Name',
      key: 'name',
      render: record => {
        return (
          <Link to={`/profile/manage-users/view/${record.id}`}>
            {' '}<b>{record.name}</b>
          </Link>
        );
      },
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: role => {
        return <div className="badge badge-primary">{role}</div>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Space size="middle">
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={() => {
                    viewDetails (record.id);
                  }}
                >
                  <EyeOutlined /> View Details
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => {
                    editData (record.username);
                  }}
                >
                  <EditOutlined /> Edit Data
                </Menu.Item>
              </Menu>
            }
          >
            <p id="action">
              <MoreOutlined
                style={{color: 'black', transform: '90deg', fontSize: '1.5rem'}}
              />
            </p>
          </Dropdown>

        </Space>
      ),
    },
  ];

  const handleSearchChange = item => {
    let filteredData = [];
    if (item.length > 0) {
      if (allUsers.length > 0) {
        filteredData = allUsers.filter (user => {
          // return user.name && user.name.toLowerCase() === item.toLowerCase();
          return (
            (user.name &&
              user.name.toLowerCase ().match (item.toLowerCase ())) ||
            (user.email &&
              user.email.toLowerCase ().match (item.toLowerCase ()))
          );
        });
        // console.log("SEARCH RESULT : ",filteredData);
      }
    }
    setFilteredArr (filteredData);
  };

  const createUserForSales = () => {
    history.push ('/profile/create-users');
  };

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div><h3>All Users</h3></div>
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Search By Name or Email"
            style={{width: '30vw', marginBottom: '3%'}}
            onChange={e => {
              handleSearchChange (e.target.value);
            }}
          />

        </div>
        <Button type={'primary'} onClick={createUserForSales}>
          <UserAddOutlined style={{fontSize: '20px'}} /> Create User
        </Button>
      </div>
      <Table
        dataSource={filteredArr.length > 0 ? filteredArr : allUsers}
        columns={columns}
      />

    </div>
  );
}

export default UsersTableForSales;
