import React, {useEffect, useState} from 'react';

import styles from './RegisterEvent.module.css';
import {Button, message} from 'antd';
import EventServices from '../../services/events.services';
import {history} from '../../helpers/history';

function RegisterEvent () {
  const [allEvents, setAllEvents] = useState ([]);
  useEffect (() => {
    let isMounted = true;

    const getUpcomingEvents = async () => {
      try {
        const response = await EventServices.getUpcomingEvents ();
        console.log (response.data);
        if (response.data) {
          setAllEvents (response.data);
        }
      } catch (err) {
        console.log (err);
        message.error (err.message ? err.message : 'something went wrong!');
      }
    };

    if (isMounted) {
      getUpcomingEvents ();
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const handleRegister = event => {
    // console.log (event);
    history.push ('/events/register-event/' + event._id);
  };
  return (
    <div className={styles.all__events}>
      <h2>Upcoming Events</h2>
      <div className={styles.all__eventsCard}>
        {allEvents?.map (item => {
          return (
            <div className={styles.event__card} key={item._id}>
              <img src={item.bannerImg} alt={item.title} />
              <div className={styles.event__cardBody}>
                <h3>{item.title}</h3>
                <div className={styles.event__cardBodyText}>
                  {item.description}
                </div>
              </div>
              <div className={styles.event__cardFooter}>
                <div>
                  <b>Start: </b><span>{item.eventDate}</span>
                </div>
                <div>
                  <Button
                    size="large"
                    className={'primaryBtn'}
                    type="primary"
                    onClick={() => handleRegister (item)}
                  >
                    Register
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

    </div>
  );
}

export default RegisterEvent;
